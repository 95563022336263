import React, { useEffect, useState, useMemo, useRef } from 'react'
import {
  View,Text, ActivityIndicator
} from 'react-native'
import { Button, LoadingSplash, CurveViewer, ContentProp, Brand, FeedSilosList, Datetime_Picker } from '../../Components'
import { CurveViewerPropHandle, DataSet, DataTemplate, PointSet, ViewForms } from '../../Components/GeneralUse/CurveViewer';

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
//import { isApp, navigateAndSimpleReset } from '../../Navigators/Root'
import { useIsFocused } from '@react-navigation/native'

import { useAppSelector } from '../../Store'
import { UnixDayTime, ConsysRefs, isEmpty, TimeSpan, CommentData, Operations as OTypes } from '../../Config/Types'

//import { ConsysApi } from '../../Services';
import { FeedInfo } from '../../Services/Consys/Feed-Slice'
import { WaterInfo, WaterList } from '../../Services/Consys/Water-Slice'
import { FirebaseCaller, Pens as FBPens, Comments } from '../../Services/Firebase'

import { GenerateCSV_Data, getUniqueColor } from '../../utils/formaters';
import { DataCaller } from '../../utils/DataCallers';
import useDetectPrint from 'react-detect-print'

import { useStyletron, withStyle } from 'baseui'
import { Select, TYPE } from 'baseui/select'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import {
  StyledTable,
  StyledHeadCell,
  StyledBodyCell
} from 'baseui/table-grid'
import {
  StatefulDataTable,
  DatetimeColumn,
  CategoricalColumn,
  NumericalColumn,
  StringColumn,
  SORT_DIRECTIONS,
  NUMERICAL_FORMATS
} from "baseui/data-table"
import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic'

const CalculateGrowthSet = (DataSet:DataTemplate) => {
  var TempVal:PointSet|undefined = undefined
  var Growths = DataSet.sort((a,b) => a.x === b.x?0:(a.x > b.x? 1 : -1)).reduce((res, cur:PointSet, index) => {
    if (TempVal) {
      var value = (cur.y - TempVal.y) *1000
      //if (value < 0) value = 0
      var days = (cur.x - TempVal.x) / 86400000
      var resVal = value / days
      if (!isNaN(resVal) && isFinite(resVal)) res.push({...cur, y:resVal})
    }
    TempVal = cur
    return res
  },[] as DataTemplate)
  return Growths
}

export interface Data_Display_Props {
  activeDataCon: string,
  DataCon: any,
  IsDataLink?: string,
  Datas:{
    Feeds:boolean, 
    Scales:boolean, 
    Activities:boolean,
     Temps:boolean, 
     Waters:boolean, 
     AutoPens:string[]
  },
  ActiveDates: any[] | Record<string, any[]> | undefined
}
const Autopig_Data_Display = ({activeDataCon, DataCon, IsDataLink, Datas:{Feeds=false, Scales=false, Activities=false, Temps=false, Waters=false, AutoPens=[]}, ActiveDates=undefined}:Data_Display_Props) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters, Images } = useTheme()

  const activefarm = useAppSelector(state => state.activeData.farm)
  //const LinkListData = useAppSelector(({firebase: {data}}) => IsDataLink&&data.LinkListData?data.LinkListData[IsDataLink]:undefined)
  //const DataLinkData = useAppSelector(({firebase: {data}}) => IsDataLink?data.LinkList[IsDataLink]:undefined)

  const useFullData = useMemo(() => {
    if (!activefarm || activefarm !== "-NGVR-L3QAfmy8CGg8rN"/* INGA FOOD */ ) return false
    else return true
  }, [activefarm])

  const PeriodeOptions = useMemo<{label:string, start:number, end:number, pen?:string}[]>(() => {
    var ResList:{label:string, start:number, end:number, pen?:string}[] = []

    var Reslist = !isEmpty(ActiveDates)?Object.values(ActiveDates).flat(1).map((Values, i) => {
      if (Values.starttime && Values.endtime) {
        var S_Date = new Date(Values.starttime)
        var E_Date = new Date(Values.endtime)
        let Label_S = `${S_Date.getDate()}/${S_Date.getMonth()+1}/${S_Date.getFullYear()}`
        let Label_E = `${E_Date.getDate()}/${E_Date.getMonth()+1}/${E_Date.getFullYear()}`
        return {label: `${Label_S} - ${Label_E}`, start:Values.starttime, end:Values.endtime}
      } else if (Values.starttime) {
        var S_Date = new Date(Values.starttime)
        let now = new Date()
        let Label_S = `${S_Date.getDate()}/${S_Date.getMonth()+1}/${S_Date.getFullYear()}`
        let Label_E = `${now.getDate()}/${now.getMonth()+1}/${now.getFullYear()}`
        return {label: `${Label_S} - ${Label_E}`, start:Values.starttime, end:now.getTime()}
      }
      return null
    }).filter(e => e).reverse():[]

    //??? only latest 10 Reslist.slice(0, 10)
    return Reslist
  }, [ActiveDates])

  //const ActiveDates = useAppSelector(state => FBPens.GetPenPeriodes(state, IsDataLink, AutoPens))
  const [ViewPeriod, updateViewPeriod] = useState<TimeSpan>({
    start: new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() -7), 
    end: new Date(new Date().setHours(23,59,59,0)).setDate(new Date().getDate() -1)
  })
  //TODO: fetchLimiter like pens_display...

  useEffect(() => {
    if (ActiveDates && !isEmpty(ActiveDates) && !isEmpty(AutoPens)) {
      var Time = AutoPens.reduce((res, PenId) => {
        if (ActiveDates[PenId] && !isEmpty(ActiveDates[PenId])) {
          var _Date = null
          for (let index = ActiveDates[PenId].length -1; index>=0 && !_Date; index--) {
            if (ActiveDates[PenId][index].starttime) _Date = ActiveDates[PenId][index].starttime
          }
          if (_Date && (_Date < res.start)) res.start = _Date
        }
        return res
      },{start:ViewPeriod.start, end:ViewPeriod.end}as TimeSpan)

      if (Time.end && (!Time.start || Time.start > Time.end)) {
        Time.start = new Date(new Date(Time.end).setHours(0,0,0,0)).setDate(new Date(Time.end).getDate() -7)
      }
      updateViewPeriod(Time)
    }
  },[ActiveDates])
  
  //#region Firebase Data Transforme
  const {Operation_Notes, Deaths, Added, Sold} = useAppSelector(state => Comments.GetFormatOperations(state, AutoPens, IsDataLink, false, ViewPeriod))
  //#endregion

  const {data: ConScaleList, error: ConScaleListError, isUninitialized: NoConScaleList} = DataCaller(ConsysRefs.SCALE, activefarm, IsDataLink, activeDataCon, ViewPeriod, (!Scales||!activeDataCon), 'all', 'last30d')
  useEffect(() => {
    if (ConScaleListError) console.error({ConScaleListError})
  },[ConScaleList, ConScaleListError])
  const {data: ConTempList, error: ConTempListError, isUninitialized: NoConTempList} = DataCaller(ConsysRefs.TEMPERATURE, activefarm, IsDataLink, activeDataCon, ViewPeriod, (!Temps||!activeDataCon), 'all', 'last30d')
  useEffect(() => {
    if (ConTempListError) console.error({ConTempListError})
  },[ConTempList, ConTempListError])
  const {data: ConWaterList, error: ConWaterListError, isUninitialized: NoConWaterList} = DataCaller(ConsysRefs.WATERCOUNT, activefarm, IsDataLink, activeDataCon, ViewPeriod, (!Waters||!activeDataCon), 'all', 'last30d')
  useEffect(() => {
    if (ConWaterListError) console.error({ConWaterListError})
  },[ConTempList, ConWaterListError])

  const WaterListPrPig = useMemo(() => {
    var WaterListPrPig:Record<string, {Water:Record<string,{
      amount: number; count: number; timestamp: number;
      objArr: WaterInfo[];
    }>, Total:number}> = {}
    if (ConWaterList && !isEmpty(ConWaterList)) {
      const AllOpes = Operation_Notes.filter((e:CommentData) => (e.action === OTypes.SOLD || e.action === OTypes.MOVE || e.action === OTypes.ADD)).sort((a,b) => a.time-b.time)
      if (AllOpes.length) {
        //As This view (Autopig Display) does not know pen datas...
        //Calc count, based on current information from Operations...
        var PenTotal_PigCount = 0
        AllOpes.forEach(OPE => {
          if (OPE && OPE.time) {
            if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) PenTotal_PigCount -= OPE.quantity
            else if (OPE.action === OTypes.ADD) PenTotal_PigCount += OPE.quantity
          }
        })
        Object.values(ConWaterList).forEach((WaterList_Data:WaterList) => {
          if (WaterList_Data && !isEmpty(WaterList_Data)) {
            Object.entries(WaterList_Data).forEach(([Id, dataPoint]) => {
              var TotalWaterPrPig = 0
              if (dataPoint && !isEmpty(dataPoint)) {
                var WaterDatas = Object.entries(dataPoint).reduce((res, cur) => {
                  var e = cur[1]
                  if (e.amount) {
                    var PigCount = (PenTotal_PigCount||1)
                    AllOpes.reverse().forEach(OPE => {
                      if (OPE && e.timestamp < OPE.time) {
                        if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) PigCount += OPE.quantity
                        else if (OPE.action === OTypes.ADD) PigCount -= OPE.quantity
                      }
                    })
                    var amount = e.amount / PigCount
                    if (!isNaN(amount) && isFinite(amount)) {
                      TotalWaterPrPig+=amount
                      res[cur[0]] = {...e, amount:amount }
                    }
                  }
                  return res
                }, {})
                WaterListPrPig[Id] = {Water:WaterDatas, Total:Math.round(TotalWaterPrPig)}
              }
            })
          }
        })
      }
    }
    console.log("res", WaterListPrPig)
    return WaterListPrPig
  }, [Operation_Notes, ConWaterList]) 

  const CurveViewRef = useRef<CurveViewerPropHandle>(null)
  const [ViewDays, AvgWeight, GrowthWeight] = useMemo(() => {
    var days = Math.floor(((ViewPeriod.end?(ViewPeriod.end - ViewPeriod.start):0)/UnixDayTime)) +1
    var avgW = 0, GW = 0
    if (CurveViewRef.current) {
      var weights = CurveViewRef.current.GetValues(ConsysRefs.SCALE)
      if (weights && weights[1] && weights[0]) {
        avgW = Math.round(weights[0]*1000) /1000
        GW = Math.round(((weights[0]-weights[1]) /days) *1000) /1000
      }
    }
    return [days, avgW, GW]
  }, [CurveViewRef.current, ViewPeriod, ConScaleList])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  const DataConstruckt = useMemo(() => {
    var SetLists:DataSet[] = []
    var Growths:PointSet[]|undefined = undefined
    var Weights:PointSet[]|undefined = undefined

    //IS not avaible as this is auto view / Not pen view
    //WeightSet (Manuel weights), Template & PredictSets
    if (Operation_Notes && Operation_Notes.flat(1).length) { //Operation_Notes ?? Record<PenId, CommentData[]>
      SetLists = SetLists.concat([{
        color:'#000',
        name:t('GraphViewer.NoteLabel'),
        form:'Notes', type:ViewForms.SCATTER, 
        data: Operation_Notes.flat(1).filter(e => e && (!e.edited && !e.deleted)).map((val) => ({x:val.time, y:'min', objData:val} as PointSet))
      }as DataSet])
    }

    if (ConScaleList) {
      SetLists = SetLists.concat(
        Object.values(ConScaleList).map((ScaleList) => Object.entries(ScaleList).map(([pen, data], index) => {
          var ScaleDatas:PointSet[]|undefined = undefined
          var Activities:PointSet[]|undefined = undefined
          if (data) {
            var Datas = Object.entries(data).reduce((Res:{ScaleDatas:PointSet[], Activities:PointSet[]}, cur) => {
              var TimeName = cur[0]
              var TimeData = cur[1]
              var Sum = TimeData.sumamount?TimeData.sumamount:TimeData.amount
              var Count = TimeData.count?TimeData.count:0

              Res.ScaleDatas.push({
                x:TimeData.timestamp, y:Sum, 
                objData:TimeData.objArr,
                dotted: TimeData.timestamp>=new Date().setHours(0,0,0,0)
              } as PointSet)
              Res.Activities.push({
                x:TimeData.timestamp, y:Count, 
                objData:TimeData.objArr,
                dotted: TimeData.timestamp>=new Date().setHours(0,0,0,0)
              } as PointSet)

              return Res
            },{ScaleDatas:[], Activities:[]})

            if (Datas.Activities.length) Activities= Datas.Activities
            if (Datas.ScaleDatas.length) ScaleDatas = Datas.ScaleDatas
          }

          var Curves = [{
            color:getUniqueColor(index+2),
            name: //(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+
                  pen + '_' + t('GraphViewer.Scale'),
            form: 'Weight', type:ViewForms.LINE,
            data: ScaleDatas
          } as DataSet,{
            color:getUniqueColor(index+54),
            name: //(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+
                  pen + '_' + t('GraphViewer.Activity'),
            form: 'Activity', type:ViewForms.LINE,
            data: Activities
          } as DataSet]
          if (ScaleDatas) {
            //TODO: what if multiple Scales ???
            Weights = ScaleDatas
            Growths = CalculateGrowthSet(ScaleDatas)
            Curves = Curves.concat([{
              color:getUniqueColor(index+55),
              name: //(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+
                    pen + '_' + t('GraphViewer.Growth'),
              form: 'Growth', type:ViewForms.LINE,
              data: Growths
            }as DataSet])
          }
          return Curves
        }).flat()).flat()
      )
    }
    //Feed Is Seperate view... (Currently Autopig cannot feed)
    if (WaterListPrPig && Object.keys(WaterListPrPig).length) {
      var WaterDatas = Object.entries(WaterListPrPig).map(([pen, data],index) => {
        if (data && data.Water && !isEmpty(data.Water)) {
          var PointDatas = Object.entries(data.Water).map(([TimeName, TimeData]) => ({x:TimeData.timestamp, y:TimeData.amount, objData:TimeData.objArr} as PointSet))
          return ({
            color:getUniqueColor(index+11),
            name: pen + '_' + t('GraphViewer.Water'),
            form: 'WaterPrPig', type:ViewForms.BAR,
            data: PointDatas
          } as DataSet)
        }
        else return null
      }).filter(e => e)
      if (WaterDatas && WaterDatas.length) SetLists = SetLists.concat(WaterDatas)
    }
    if (ConWaterList) {
      SetLists = SetLists.concat(
        Object.values(ConWaterList).map((WaterList) => Object.entries(WaterList).map(([pen, data], index) => {
          return {
            color:getUniqueColor(index+11),
            name: pen + '_' + t('GraphViewer.Water'),
            form: 'Water', type:ViewForms.BAR,
            data: data?Object.entries(data).map(([TimeName, TimeData]) => ({x:TimeData.timestamp, y:TimeData.amount, objData:TimeData.objArr} as PointSet)):undefined
          } as DataSet
        })).flat()
      )
    }
    if (ConTempList) {
      SetLists = SetLists.concat(
        Object.values(ConTempList).map((TempList) => Object.entries(TempList).map(([pen, data], index) => {
          return {
            color:getUniqueColor(index+38),
            name: pen + '_' + t('GraphViewer.Temp'),
            form: 'Temp', type:ViewForms.LINE,
            data: data?Object.entries(data).map(([TimeName, TimeData]) => ({x:TimeData.timestamp, y:TimeData.amount, objData:TimeData.objArr} as PointSet)):undefined
          } as DataSet
        })).flat()
      )
    }
    //console.log("SetLists", SetLists)
    return SetLists
  }, [/*WeightSets, TemplateSets, PredictSets,*/ Operation_Notes, /*FeedsLists,*/ /*FeedsListsPrPig,*/ ConWaterList, /*WaterListPrPig,*/ ConScaleList, ConTempList])

  return(
    <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, Gutters.tinyPadding, {maxWidth:900, paddingBottom:'2px'}]}>
      <View style={[Layout.col, Gutters.tinyV_Margin, {width:'-webkit-fill-available', minWidth:'min-content', marginBottom:10}]}>
        {ConScaleList&&!isEmpty(ConScaleList)?(
          <View style={[Layout.colVCenter, Common.backgroundPrimary, {minHeight:'max-content', width:"-webkit-fill-available"}]}>
            <ContentProp Columns={2} Datas={[[t('Autopigs_Display.DaysLabel'), ViewDays]]}/>
            <ContentProp Columns={2} Datas={[[t('Autopigs_Display.NewestLabel')+" "+t('DataTypes.W'), AvgWeight]]}/>
            <ContentProp Columns={2} Datas={[[t('Pen_Display.Prop5')+' '+t('DataTypes.W_small'), GrowthWeight]]}/>
          </View>
        ):(<></>)}
        
        <View style={[ Layout.fillRow, Layout.fullWidth, {maxWidth:600, marginTop:5, alignSelf:'center', justifyContent:'space-between'}]}>
          <View style={[{maxWidth:280, minWidth:120, width:'40%', alignItems:'center'}]}>
            <Brand width={'120px'}/>
          </View>
          <View style={[Layout.row, {maxWidth:330, minWidth:120, width:'60%', alignItems:'center', justifyContent:'space-around'}]}>
            <View style={{width:'max-content', maxWidth:'220px'}}>
              {/*Space*/}
            </View>
            <View style={{width:'max-content', maxWidth:'220px'}}>
              <Button Contexts={['CSV   Data .']} EndEnhancers={Images.Save} Action={() => {
                var TagScales = CurveViewRef.current?.SelectedTags()
                var Scales = TagScales&&TagScales.length?(ConScaleList?ConScaleList:[]).concat(TagScales):ConScaleList
                GenerateCSV_Data(`${DataCon.name?DataCon.name+'':'SysId'} -#${activeDataCon}`, undefined, Scales, ConTempList, ConWaterList, undefined, Operation_Notes, useFullData)
              }}/>
            </View>
          </View>
        </View>

        <View style={[Layout.row, Gutters.tinyV_Margin, {minWidth:'min-content', maxWidth:'max-content', alignSelf:'center', marginBottom:10}]}>
          <Datetime_Picker MoveArrows //MinMax={Settings.LimitedDate?Settings.LimitedDate:undefined} 
            MarkDates={!isEmpty(ActiveDates)?Object.values(ActiveDates).flat(1):undefined}
            value={ViewPeriod} 
            onValueChange={(time)=>{
              if (time) updateViewPeriod(time) //SetTimes({start:time.start, end:time.end})
            }
          }/>
          <Select clearable={false}
            options={PeriodeOptions}
            value={[]}
            type={TYPE.select}
            placeholder=""
            onChange={(params:any) => {
              var value = params.value[0]
              if (value) updateViewPeriod(value)
            }}
            maxDropdownHeight="150px"
            overrides={{
              Root: { style: ({ $theme }) => ({
                alignSelf:'flex-end',
                width:'40px',
                height:'20',
                outline:'black 1px solid'
              })},
              DropdownContainer: { style: ({ $theme }) => ({
                width:'220px',
              })}
            }}
          />
        </View>
      </View>
      
      <CurveViewer ref={CurveViewRef} yShrink StartTimes={ViewPeriod} DatePicker={false} DateArrows={false} canMultiSplit={false}
        //MarkDates={!isEmpty(ActiveDates)?Object.values(ActiveDates).flat(1):undefined}
        Primary={{
          left:Scales?'Weight':Temps?'Temp':undefined, 
          right:Scales&&Temps?'Temp':undefined
        }}
        //TODO: clean... not working as list mix-match
        //LimitedDate={DataLinkData&&DataLinkData.starttime?{start:new Date(DataLinkData.starttime).setHours(0,0,0,0), end:new Date().setHours(23,59,59,0)}:undefined} 
        /*OnTimesChanged={(start, end) => {
          //updateViewPeriod({start:start,end:end?end:ViewPeriod.end})
        }}*/
        DataView={DataConstruckt}
      />
    </View>
  )
}

const Feed_Data_Display = ({activeDataCon, DataCon, IsDataLink, Datas:{Feeds=false, Scales=false, Activities=false, Temps=false, Waters=false, AutoPens=[]}, ActiveDates=undefined}:Data_Display_Props) => {
  const { t } = useTranslation()
  const { Layout, Gutters } = useTheme()
  const isPrinting  = useDetectPrint()
  const [css] = useStyletron()

  const activefarm = useAppSelector(state => state.activeData.farm)

  const [ViewPeriod, updateViewPeriod] = useState<TimeSpan>({
    start: new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() -7), 
    end: new Date(new Date().setHours(23,59,59,0)).setDate(new Date().getDate() -1)
  })

  useEffect(() => {
    if (ActiveDates && !isEmpty(ActiveDates) && !isEmpty(AutoPens)) {
      var Time = AutoPens.reduce((res, PenId) => {
        if (ActiveDates[PenId] && !isEmpty(ActiveDates[PenId])) {
          var _Date = null
          for (let index = ActiveDates[PenId].length -1; index>=0 && !_Date; index--) {
            if (ActiveDates[PenId][index].starttime) _Date = ActiveDates[PenId][index].starttime
          }
          if (_Date && (_Date < res.start)) res.start = _Date
        }
        return res
      },{start:ViewPeriod.start, end:ViewPeriod.end}as TimeSpan)

      if (Time.end && (!Time.start || Time.start > Time.end)) {
        Time.start = new Date(new Date(Time.end).setHours(0,0,0,0)).setDate(new Date(Time.end).getDate() -7)
      }
      updateViewPeriod(Time)
    }
  },[ActiveDates])

  const {data: ConFeedList, error: ConFeedListError, isUninitialized: NoConFeedList, isFetching:ConFeedFetching} = DataCaller(ConsysRefs.VALVEFEED, activefarm, IsDataLink, activeDataCon, ViewPeriod, (!Feeds||!activeDataCon), 'all', 'last30d')
  useEffect(() => {
    if (ConFeedListError) console.error({ConFeedListError})
  },[ConFeedList, ConFeedListError])

  const FeedsLists = useMemo<{[key:string]: any[]}|undefined>(() => {
    if (ConFeedList && !isEmpty(ConFeedList)) {
      var dataMap:{[key:string]: any[]} = {}
      Object.values(ConFeedList).forEach((FeedList) => {
        Object.entries(FeedList).forEach(([Id, dataPoint]) => {
          if (dataPoint) {
            if (!dataMap[dataPoint.valve]) dataMap[dataPoint.valve] = []
            dataMap[dataPoint.valve].push(dataPoint)
          }
        })
      })
      return dataMap
    }
    return undefined
  }, [ConFeedList])

  const [SortMap, setSortMap] = useState<Record<string, {
    range: Date[], lowerValue: number, upperValue:number, description:string, exclude:boolean, selection:Set<number>, comparisons:{operation: string, value: number}[]
  }>>({})

  const [DisplayFeeds, setDisplayFeeds] = useState(true)
  const [DisplaySilo, setDisplaySilo] = useState(true)
  const [DisplayLog, setDisplayLog] = useState(true)

  const FeedRef = useRef()
  const _FeedSilosList = useMemo(() => {
    var DatasList:{id:string, data:any[]}[] = []
    var _FeedsLists = FeedsLists?Object.values(FeedsLists).flat(1):[]
    if (_FeedsLists && !isEmpty(_FeedsLists)) {
      _FeedsLists.forEach(FeedData => {
        if (FeedData.silolist && !isEmpty(FeedData.silolist)) {
          FeedData.silolist.forEach(Silo => {
            if (Silo.silono && Silo.amount) {
              //if (cData.timestamp >= ViewPeriod.start && (!ViewPeriod.end || dataPoint.timestamp <= ViewPeriod.end)) {
                DatasList.push({id: FeedData.valve +'', data:[FeedData.valve+'', 'Silo ' + Silo.silono, Silo.amount, FeedData.timestamp]})
              //}
            }
          })
        }
      })
    }
    return DatasList
  }, [FeedsLists])

  const LogDatas = useMemo(() => {
    var log:{FullData:number, SiloLog:Record<string,number>, PenLog:Record<string,Record<string,number>>} = {FullData:0, SiloLog:{}, PenLog:{}}
    if (_FeedSilosList && !isEmpty(_FeedSilosList)) {
      log = _FeedSilosList.reduce((pData, cData) => {
        // Sort/Filter check
        var res = true
        if (SortMap) {
          if (SortMap[t('FeedList.Col1')]) { //Sort on valve
            var val = SortMap[t('FeedList.Col1')].selection.has(cData.data[0])
            if (SortMap[t('FeedList.Col1')].exclude) res = !val
            else res = val
          }
          if (res && SortMap[t('FeedList.Col2')]) { //Sort on silo
            var val = SortMap[t('FeedList.Col2')].selection.has(cData.data[1])
            if (SortMap[t('FeedList.Col2')].exclude) res = !val
            else res = val
          }
          if (res && SortMap[t('FeedList.Col3')]) { //Sort on amount
            var lower = SortMap[t('FeedList.Col3')].lowerValue
            var upper = SortMap[t('FeedList.Col3')].upperValue
            var exclude = SortMap[t('FeedList.Col3')].exclude

            var Amount = cData.data[2] / 1000

            if (exclude && (Amount >= lower && Amount <= upper)) res = false
            if (!exclude && (Amount <= lower || Amount >= upper)) res = false
          }
          if (res && SortMap[t('FeedList.Col4')]) { //Sort on Time
            var timerange = SortMap[t('FeedList.Col4')].range
            if (timerange && timerange.length && timerange.length === 2) {
              var lower = timerange[0].getTime()
              var upper = new Date(timerange[1]).setSeconds(59,999)
              var exclude = SortMap[t('FeedList.Col4')].exclude
              if (exclude && (cData.data[3] >= lower && cData.data[3] <= upper)) res = false
              if (!exclude && (cData.data[3] <= lower || cData.data[3] >= upper)) res = false
            }
          }
        }
        //end

        if (res) {
          var Amount = cData.data[2] / 1000
          pData.FullData += Amount
          pData.SiloLog[cData.data[1]] = pData.SiloLog[cData.data[1]]?(pData.SiloLog[cData.data[1]]+=Amount):Amount
          if (!pData.PenLog[cData.data[0]]) pData.PenLog[cData.data[0]] = {}
          pData.PenLog[cData.data[0]][cData.data[1]] = pData.PenLog[cData.data[0]][cData.data[1]]?(pData.PenLog[cData.data[0]][cData.data[1]]+=Amount):Amount
        }
        return pData
      }, {FullData:0, SiloLog:{}, PenLog:{}})
    }
    return log
  }, [SortMap, _FeedSilosList])

  const Consumption_Cols = [
    CategoricalColumn({
      title: t('FeedList.Col1'),
      mapDataToValue: (data:any[]) => data[0],
      fillWidth: false,
      width:40,
    }),
    CategoricalColumn({
      title: t('FeedList.Col2'),
      mapDataToValue: (data:any[]) => data[1],
      fillWidth: false,
      minWidth: 80
    }),
    NumericalColumn({
      title: t('FeedList.Col3'),
      precision: 2,
      mapDataToValue: (data:any[]) => (data[2] / 1000),
      fillWidth: true,
      minWidth: 80
    }),
    DatetimeColumn({
      title: t('FeedList.Col4'),
      formatString: 'dd-MM-yyy HH:mm',
      mapDataToValue:(data: any) => new Date(new Date(data[3]).setSeconds(0,0)),
      fillWidth: false,
      minWidth:200,
    })
  ]

  var Special_feedSiloList:any|undefined = undefined
  if (!isEmpty(_FeedSilosList)) {
    Special_feedSiloList = (
      <View style={[Layout.colVCenter, {marginTop:10, width: '-webkit-fill-available', height:'fit-content', marginHorizontal:10}]}>
        <View style={[Layout.row, Layout.fullWidth, {justifyContent:'center', alignItems:'center', maxWidth:600}]}>
          <ContentProp Columns={1} maxWidth={600-30} Datas={[[{value:t('Pen_Display.SiloLog_Title'), CSV_ABLE:GenerateCSV_Data!==undefined}]]} Backcolor={true} Actions={[() => {
            //if (FeedRef && FeedRef.current) console.log(FeedRef.current.getRows())
            GenerateCSV_Data(`${DataCon.name?DataCon.name+'':'SysId'} -#${activeDataCon}`, ConFeedList)
          }]}/>
        </View>
        <View style={[Layout.col, Layout.fullWidth, {justifyContent:'center', alignItems:'center', maxWidth:800}]}>
          <View style={[Layout.row, Layout.fullWidth, {justifyContent:'flex-end', maxWidth:800}]}>
            {DisplayFeeds?(
              <View style={[Layout.col, Layout.fullWidth, {flex:1, justifyContent:'center', alignItems:'center', maxWidth:770}]}>
                <View style={[Layout.fullWidth, {maxWidth:800, flex:1}, isPrinting?{maxHeight:0}:{minHeight:360}]} >
                  <StatefulDataTable controlRef={FeedRef}
                    columns={Consumption_Cols}
                    initialSortDirection={SORT_DIRECTIONS.DESC}
                    initialSortIndex={3}
                    initialFilters={new Map(Object.entries(SortMap))}
                    onFilterAdd={(te:string, te2:any) => {
                      setSortMap(old => {
                        old[te] = te2
                        return {...old}
                      })
                    }}
                    onFilterRemove={(te:string) => {
                      setSortMap(old => {
                        delete old[te]
                        return {...old}
                      })
                    }}
                    rows={_FeedSilosList}
                  />
                </View>
                <View style={[Layout.fullWidth, {maxWidth:800}, isPrinting?{minHeight:360}:{maxHeight:0}]} >
                  <TableBuilder divider={"grid"} sortOrder={SORT_DIRECTIONS.DESC} sortColumn={"time"}
                    data={FeedRef&&FeedRef.current?FeedRef.current.getRows():[]}
                    overrides={{
                      TableBodyRow: {style: ({$theme, $rowIndex}) => ({
                        backgroundColor:$rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
                        ':hover': { backgroundColor: $theme.colors.backgroundTertiary}
                      })},
                      TableHeadCell: {style: ({ $theme }) => ({
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      })},
                      TableBodyCell: {style: ({ $theme }) => ({
                        paddingTop: "8px",
                        paddingBottom: "8px"
                      })}
                    }}>
                    <TableBuilderColumn header={t('FeedList.Col1')}>
                      {data => data.data[0]}
                    </TableBuilderColumn>
                    <TableBuilderColumn header={t('FeedList.Col2')}>
                      {data => data.data[1]}
                    </TableBuilderColumn>
                    <TableBuilderColumn header={t('FeedList.Col3')}>
                      {data => data.data[2]}
                    </TableBuilderColumn>
                    <TableBuilderColumn sortable id='time' header={t('FeedList.Col4')}>
                      {data => {
                        var time = new Date(data.data[3])
                        return `${time.getDate()}-${time.getMonth()+1}-${time.getFullYear()} ${time.getHours()}:${time.getMinutes()}`
                      }}
                    </TableBuilderColumn>
                  </TableBuilder>
                </View>
              </View>
            ):(<></>)}
            {!isPrinting?(
              <Checkbox checked={DisplayFeeds} onChange={() => setDisplayFeeds(!DisplayFeeds)}
                labelPlacement={LABEL_PLACEMENT.left}
                overrides={{
                  Root: { style: ({ }) => ({
                    alignSelf: "flex-start", justifySelf: "flex-end", alignItems: "center",
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })},
                  Label: { style: ({ }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
              >{!DisplayFeeds?("Feedings:"):("")}</Checkbox>
            ):(<></>)}
          </View>
          <View style={[Layout.row, Layout.fullWidth, {justifyContent:'flex-end', maxWidth:800}]}>
            {DisplaySilo?(
              <View style={[Layout.col, Layout.fullWidth, {flex:1, justifyContent:'center', maxWidth:770}, isPrinting?{}:{maxHeight:200}]}>
                <TableBuilder divider={"grid"}
                  sortOrder={SORT_DIRECTIONS.DESC} sortColumn={"Silo"}
                  data={Object.entries(LogDatas.SiloLog).concat([[t('Report_Display.Total'), LogDatas.FullData]])}
                  overrides={{
                    TableBodyRow: {style: ({$theme, $rowIndex}) => ({
                      backgroundColor:$rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
                      ':hover': { backgroundColor: $theme.colors.backgroundTertiary}
                    })},
                    TableHeadCell: {style: ({ $theme }) => ({ 
                      paddingTop: "10px",
                      paddingBottom: "10px"
                    })},
                    TableBodyCell: {style: ({ $theme }) => ({
                      paddingTop: "8px",
                      paddingBottom: "8px"
                    })}
                  }}>
                  <TableBuilderColumn sortable id='Silo' header={t('FeedList.Col2')}>
                    {row => row[0]}
                  </TableBuilderColumn>
                  <TableBuilderColumn sortable numeric header={t('FeedList.Col3')}>
                    {row => (Math.round(row[1]*1000)/1000).toFixed(3)}
                  </TableBuilderColumn>
                </TableBuilder>
              </View>
            ):(<></>)}
            {!isPrinting?(
              <Checkbox checked={DisplaySilo} onChange={() => setDisplaySilo(!DisplaySilo)}
                labelPlacement={LABEL_PLACEMENT.left}
                overrides={{
                  Root: { style: ({ }) => ({
                    alignSelf: "flex-start", justifySelf: "flex-end", alignItems: "center",
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })},
                  Label: { style: ({ }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
              >{!DisplaySilo?("Silos:"):("")}</Checkbox>
            ):(<></>)}
          </View>
          <View style={[Layout.row, Layout.fullWidth, {justifyContent:'flex-end', maxWidth:800}]}>
            {DisplayLog?(
              <View style={[Layout.col, Layout.fullWidth, {flex:1, marginTop:15, justifyContent:'center', maxWidth:770}, isPrinting?{}:{maxHeight:500}]}>
                <StyledTable role="grid" 
                  $gridTemplateColumns="minmax(100px, 600px) repeat(2, max-content)"
                >
                  <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('FeedList.Col1')}</StyledHeadCell>
                  <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('FeedList.Col2')}</StyledHeadCell>
                  <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('FeedList.Col3')}</StyledHeadCell>
                  { Object.entries(LogDatas.PenLog).map((rowData, rowIndex) => 
                      <React.Fragment key={rowData[0]+"_"+rowIndex}>
                        <StyledBodyCell
                          $gridRow={`span ${Object.keys(rowData[1]).length}`}
                        >
                          {rowData[0]}
                        </StyledBodyCell>
                        {Object.entries(rowData[1]).map((rowSiloData, rowSiloIndex) => {
                          const striped = rowSiloIndex % 2 === 0
                          return (
                            <React.Fragment key={rowData[0]+"_"+rowSiloData[0]+"_"+rowSiloIndex}>
                              <StyledBodyCell $striped={striped} className={((Object.keys(rowData[1]).length-1)===rowSiloIndex)?css({borderBottom:'solid 1px black'}):undefined} >
                                {rowSiloData[0]}
                              </StyledBodyCell>
                              <StyledBodyCell $striped={striped} className={((Object.keys(rowData[1]).length-1)===rowSiloIndex)?css({borderBottom:'solid 1px black'}):undefined} >
                                {(Math.round(rowSiloData[1]*1000)/1000).toFixed(3)}
                              </StyledBodyCell>
                            </React.Fragment>
                          )
                        })}
                        <StyledBodyCell className={css({borderBottom:'solid 1px black'})} $striped={true}>
                          {t('Report_Display.Total')}
                        </StyledBodyCell>
                        <StyledBodyCell className={css({borderBottom:'solid 1px black'})}
                          $gridColumn={`span 2`} $striped={Object.keys(rowData[1]).length%2===0}
                        >
                          {(Math.round(Object.values(rowData[1]).reduce((p,c)=>p+=c,0)*1000)/1000).toFixed(3)}
                        </StyledBodyCell>
                      </React.Fragment>
                  ) }
                </StyledTable>
                
              </View>
            ):(<></>)}
            {!isPrinting?(
              <Checkbox checked={DisplayLog} onChange={() => setDisplayLog(!DisplayLog)}
                labelPlacement={LABEL_PLACEMENT.left}
                overrides={{
                  Root: { style: ({ }) => ({
                    alignSelf: "flex-start", justifySelf: "flex-end", alignItems: "center",
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px', marginTop:'15px'
                  })},
                  Label: { style: ({ }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
              >{!DisplayLog?("Valves:"):("")}</Checkbox>
            ):(<></>)}
          </View>
        </View>
      </View>
    )
  } else {
    Special_feedSiloList = (
      <View style={[Layout.fullWidth, {maxWidth:800, minHeight:25, maxHeight:25}]}>
        {!ConFeedFetching?(
          <Text>{t('Report_Display.NoFeeding')}</Text>
        ):(
          <ActivityIndicator style={[{alignItems:'center', justifyContent:'center', backgroundColor: '#F5FCFF88'}]} size='large' />
        )}
      </View>
    )
  }

  return (
    <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, Gutters.tinyPadding, {maxWidth:900, paddingBottom:'2px'}]}>
      <View style={[Layout.row, Gutters.tinyV_Margin, {minWidth:'min-content', maxWidth:'max-content', marginBottom:10}]}>
        <Datetime_Picker MoveArrows //MinMax={Settings.LimitedDate?Settings.LimitedDate:undefined} 
          MarkDates={!isEmpty(ActiveDates)?Object.values(ActiveDates).flat(1):undefined}
          value={ViewPeriod} 
          onValueChange={(time)=>{
            if (time) updateViewPeriod(time) //SetTimes({start:time.start, end:time.end})
          }
        }/>
        {/* Her should be periode selector... but for feed there is to many pens to know wanted list ?? */}
      </View>
      { Special_feedSiloList
        //<FeedSilosList FeedsList={FeedsLists?Object.values(FeedsLists).flat(1):[]} isFetching={ConFeedFetching} 
        // GenerateCSV_Data={() => GenerateCSV_Data(`${DataCon.name?DataCon.name+'':'SysId'} -#${activeDataCon}`, ConFeedList)} />
      }
    </View>
  )
}

const Autopig_Display = (MainProps:any) => {
  //const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  const MainParams = MainProps.route.params?MainProps.route.params:undefined
  const DataCon = MainParams.SelectedAutopig[1]
  
  const activefarm = useAppSelector(state => state.activeData.farm)
  const activeDataCon = useAppSelector(state => state.activeData.autopig)

  const ActiveId = activeDataCon?(typeof activeDataCon==="object")?activeDataCon.systemId:activeDataCon:undefined
  const IsDataLink = activeDataCon?(typeof activeDataCon==="object")?activeDataCon.FarmId:undefined:undefined

  const {Feeds, Scales, Activities, Temps, Waters, AutoPens} = useMemo(() => {
    var Feeds:boolean = false, Scales:boolean = false, Activities:boolean = false, Temps:boolean = false, Waters:boolean = false, AutoPens:string[] = []
    if (DataCon) {
      if (DataCon.feed) {
        Feeds = DataCon.feed!=""?true:false
        //TODO: ??? to many pens.. will crash app
        AutoPens = AutoPens.concat(DataCon.feed.split(','))
      }
      if (DataCon.scale) {
        Scales = DataCon.scale!=""?true:false
        AutoPens = AutoPens.concat(DataCon.scale.split(',').filter((e: string) => !AutoPens.includes(e)))
      }
      if (DataCon.activity) {
        Activities = DataCon.activity!=""?true:false
        AutoPens = AutoPens.concat(DataCon.activity.split(',').filter((e: string) => !AutoPens.includes(e)))
      }
      if (DataCon.temp) {
        Temps = DataCon.temp!=""?true:false
        AutoPens = AutoPens.concat(DataCon.temp.split(',').filter((e: string) => !AutoPens.includes(e)))
      }
      if (DataCon.water) {
        Waters = DataCon.water!=""?true:false
        AutoPens = AutoPens.concat(DataCon.water.split(',').filter((e: string) => !AutoPens.includes(e)))
      }
    }
    return {Feeds, Scales, Activities, Temps, Waters, AutoPens}
  }, [DataCon])

  const LinkList_Sections = useAppSelector(({firebase: {data}}) => IsDataLink&&data.LinkList&&data.LinkList[IsDataLink]&&data.LinkList[IsDataLink].sections?data.LinkList[IsDataLink].sections:null)
  const allSecData = useAppSelector(({firebase: {data}}) => {
    var SharedData = IsDataLink&&data.LinkListData&&data.LinkListData[IsDataLink]&&data.LinkListData[IsDataLink].sections?data.LinkListData[IsDataLink].sections:null
    if (SharedData) return SharedData
    return data.sections
  })

  FBPens.fetchPenPeriodes(activefarm, true, [], AutoPens, IsDataLink)
  Comments.fetchComments(activefarm, true, [], IsDataLink, undefined, false, IsDataLink?allSecData:undefined, LinkList_Sections)
  const ActiveDates = useAppSelector(state => FBPens.GetPenPeriodes(state, IsDataLink, AutoPens))
  //const ActivePenDatas = useAppSelector(state => FBPens.GetPenData(state, AutoPens, IsDataLink))

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (/*!isLoaded(auth) || !isLoaded(profile) ||*/ !ActiveId || !activeDataCon) return <LoadingSplash/>
  else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[Layout.colVCenter, Common.backgroundPrimary, {minHeight:'max-content', width:"-webkit-fill-available"}]}>
          <Button Contexts={DataCon.name?DataCon.name+' -#'+ActiveId:"SysId -#" + ActiveId} Title/>
        </View>
        <View style={{height:20}}/>

        {DataCon&&DataCon.feed&&DataCon.feed!=""?
          <View style={[Layout.fill, Layout.colVCenter, Common.backgroundPrimary, {minHeight:'max-content', width:"-webkit-fill-available"}]}>
            <Feed_Data_Display activeDataCon={ActiveId} DataCon={DataCon} IsDataLink={IsDataLink} 
              Datas={{Feeds, Scales, Activities, Temps, Waters, AutoPens}} ActiveDates={ActiveDates}
            />
          </View>
        :<></>}
        
        {DataCon&&(DataCon.scale||DataCon.activity||DataCon.temp||DataCon.water)?
          <Autopig_Data_Display activeDataCon={ActiveId} DataCon={DataCon} IsDataLink={IsDataLink} 
            Datas={{Feeds, Scales, Activities, Temps, Waters, AutoPens}} ActiveDates={ActiveDates}
          />
        :<></>}
      </View>
    )
  }
}

export default Autopig_Display