import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  Text,
} from 'react-native'

import { useTheme } from '../../../Theme'
import { useTranslation } from 'react-i18next'
import { isApp, useisAppView } from '../../../Navigators/Root'
import { Periodes, TimeSpan } from '../../../Config/Types'

import { useCurveView_Context } from './CurveViewer_context'

import { Datetime_Picker, Labelled_Input } from '../../../Components'
import { Combobox, SIZE } from 'baseui/combobox'
import { Select, TYPE } from 'baseui/select'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { Slider } from 'baseui/slider'

export interface CurveView_SelectEditor_Props {
  DataViewTimes:TimeSpan|undefined,
  MarkDates?: Periodes[],
  OnTimesChanged?: (start:number,end?:number) => void,
  CalcViewData:any, RFIDTags:string[]
}
const CurveView_SelectEditor = ({DataViewTimes, MarkDates, OnTimesChanged, CalcViewData, RFIDTags }:CurveView_SelectEditor_Props) => {
  const { t } = useTranslation()
  const { Layout } = useTheme()
  const isAppView = useisAppView()

  const { state: {Settings, ActiveOptions, Selected_DataOptions, DeactiveSets, DataTimes }, dispatch } = useCurveView_Context()
  const ViewTime = useMemo<TimeSpan|undefined>(() => (OnTimesChanged&&DataViewTimes)?DataViewTimes:DataTimes, [DataViewTimes, DataTimes])
  const {zoomedXDomain} = CalcViewData(Settings, ActiveOptions, Selected_DataOptions, DeactiveSets, ViewTime)
  const SelectOptions = ActiveOptions.filter(e => e.id!=='Notes')

  const SetTimes = (Dates: TimeSpan) => {
    if (OnTimesChanged /*&& (Dates.start < DataViewTimes.start || Dates.end > DataViewTimes.end)*/) {
      OnTimesChanged(Dates.start, Dates.end)
    }
    else dispatch({type:'set_DataTimes', payload:Dates})
  }

  return (
    <View style={[Layout.row, {flexWrap:'wrap', width:'-webkit-fill-available', alignItems:'flex-end', justifyContent:'space-between', maxWidth:'900px', marginHorizontal:10}]}>
      {Settings.canMultiSplit&&Settings.canMultiSplit.Active?
        <View style={[{order:1, width:'-webkit-fill-available', alignItems:'center'}]}>
          <Checkbox labelPlacement={LABEL_PLACEMENT.left}
            overrides={{
              Root: { style: ({ }) => ({
                minWidth:'min-content', order:5, marginTop:'10px'
              })},
              Label: { style: ({ }) => ({
                justifyContent:'flex-end', paddingBottom:'2px',
                minWidth:'min-content', 
              })}
            }}
            checked={Settings.canMultiSplit.state}
            onChange={(e:any) => {
              dispatch({type:'set_MultiSplit', payload:e.target.checked})
            }}
          >
          <Text style={[{}]}>{"Multi split"}</Text>
        </Checkbox>
        </View>
      :<></>}
      {!Settings.canMultiSplit.state && SelectOptions && SelectOptions.length >1?(
        <View style={[Layout.column, {flexWrap:'wrap', width:'fit-content', alignItems:'flex-end', order:4}]}>
          <Combobox
            size={SIZE.mini}
            value={(SelectOptions.find(e => e.id === Selected_DataOptions.left)?.label || '---')}
            onChange={(nextValue, ele:any) => {
              if (nextValue === "---" || Selected_DataOptions.right !== nextValue) {
                dispatch({type:'set_Selected_DataLeft', payload:(SelectOptions.find(e => e.label === nextValue)?.id||'---')})
              }
            }}
            options={SelectOptions.filter(e => e.id !== "WeightTaged")}
            mapOptionToString={option => option.label}
            overrides={{
              Root: {style: ({ $theme }) => ({
                height:'30px', width:'100px', minWidth:'100px',
                outline:'1px solid black', marginTop:'10px'
              })}
            }}
          />
        </View>
      ):(<></>)}
      {Settings.DatePicker?(
        <View style={[isAppView?{order:2, width:'-webkit-fill-available', alignItems:'center'}:{order:4}]}>
          {Settings.zeroIndex?(
            <View style={[!isAppView?{minWidth:290}:{minWidth:180}]}>
              <View style={[Layout.row, {justifyContent:'space-between'}]}>
                <Labelled_Input maxWidth={70} type={"number"} value={(ViewTime&&ViewTime.start?ViewTime.start:(zoomedXDomain&&zoomedXDomain.min?zoomedXDomain.min:0))+''} ChangeOnBlur onChange={(e, num) => {
                  if ((num!==undefined && !isNaN(num)) && (!ViewTime || (!ViewTime.end || num < ViewTime.end))) {
                    var Dates = {start:num, end:(ViewTime&&ViewTime.end?ViewTime.end:(zoomedXDomain&&zoomedXDomain.max?zoomedXDomain.max:1))}
                    SetTimes(Dates)
                  }
                }}/>
                <Text style={[{marginLeft:10, marginRight:10, alignSelf:'flex-start'}]}>{t('Operations.PeriodeTitle')}</Text>
                <Labelled_Input maxWidth={70} type={"number"} value={(ViewTime&&ViewTime.end?ViewTime.end:(zoomedXDomain&&zoomedXDomain.max?zoomedXDomain.max:1))+''} ChangeOnBlur onChange={(e, num) => {
                  if ((num!==undefined && !isNaN(num)) && (!ViewTime || num > ViewTime.start)) {
                    var Dates = {start:(ViewTime&&ViewTime.start?ViewTime.start:(zoomedXDomain&&zoomedXDomain.min?zoomedXDomain.min:0)), end:num}
                    SetTimes(Dates)
                  }
                }}/>
              </View>
              {!isAppView&&(
                <Slider
                  min={(zoomedXDomain&&zoomedXDomain.min?zoomedXDomain.min:0)}
                  max={(zoomedXDomain&&zoomedXDomain.max?zoomedXDomain.max:1)}
                  value={ViewTime?[ViewTime.start?ViewTime.start:(zoomedXDomain&&zoomedXDomain.min?zoomedXDomain.min:0), 
                    ViewTime.end?ViewTime.end:(zoomedXDomain&&zoomedXDomain.max?zoomedXDomain.max:1)]
                    :[zoomedXDomain&&zoomedXDomain.min?zoomedXDomain.min:0,zoomedXDomain&&zoomedXDomain.max?zoomedXDomain.max:1]
                  }
                  onChange={({value}) => {
                    if (value) {
                      SetTimes({start:value[0], end:value[1]})
                    }
                  }
                }/>)
              }
            </View>
          ):(
            <Datetime_Picker MoveArrows={Settings.DateArrows} MinMax={Settings.LimitedDate?Settings.LimitedDate:undefined} MarkDates={MarkDates}
              value={ViewTime?{start:ViewTime.start, end:ViewTime.end}:undefined} 
              onValueChange={(time)=>{
                if (time) SetTimes({start:time.start, end:time.end})
              }
            }/>
          )}
        </View>
      ):(<></>)}
      <Checkbox labelPlacement={LABEL_PLACEMENT.top}
        overrides={{
          Root: { style: ({ }) => ({
            minWidth:'min-content', order:5, marginTop:'10px'
          })},
          Label: { style: ({ }) => ({
            justifyContent:'flex-end', paddingBottom:'2px',
            minWidth:'min-content', 
          })}
        }}
        checked={!Settings.HideTip}
        onChange={(e:any) => {
          dispatch({type:'set_HideTip', payload:!e.target.checked})
        }}
      ><Text style={[{}]}>{t('GraphViewer.ShowTip')}</Text></Checkbox>
      {!Settings.canMultiSplit.state && SelectOptions.length > 2?(
        <View style={[Layout.column, {flexWrap:'wrap', width:'fit-content', alignItems:'flex-end', order:6}]}>
          <Combobox size={SIZE.mini}
            value={(SelectOptions.find(e => e.id === Selected_DataOptions.right)?.label || '---')}
            onChange={(nextValue, ele:any) => {
              if (nextValue === "---" || Selected_DataOptions.left !== nextValue) {
                dispatch({type:'set_Selected_DataRight', payload:(SelectOptions.find(e => e.label === nextValue)?.id||'---')})
              }
            }}
            options={SelectOptions}
            mapOptionToString={option => option.label}
            overrides={{
              Root: {style: ({ $theme }) => ({
                height:'30px', width:'180px', minWidth:'180px',
                outline: '1px solid black', marginTop:'10px'
              })}
            }}
          />
          {Selected_DataOptions.right && Selected_DataOptions.right === "WeightTaged"?
            <Select size={SIZE.mini}
              options={RFIDTags.map(e => ({label:e, id:e}))}
              value={Selected_DataOptions.args&&Selected_DataOptions.args.tags?Selected_DataOptions.args.tags.map(e => ({label:e, id:e})):[]}
              type={TYPE.search} multi filterOutSelected={false}
              placeholder="Rfid"
              onChange={(params:any) => {
                dispatch({type:'set_Selected_DataRight', payload:{tag:params.value.map(e => e.id)}})
              }}
              maxDropdownHeight="150px"
              overrides={{
                Root: { style: ({ $theme }) => ({
                  height:'30px', width:'180px', minWidth:'180px',
                  outline:'black 1px solid', marginTop:'10px'
                })},
                ControlContainer: {style: ({ $theme }) => ({
                  height: "30px"
                })},
                DropdownContainer: { style: ({ $theme }) => ({
                  width:'220px', direction: "rtl"
                })}
              }}
            />
          :<></>}
        </View>
      ):(<></>)}
    </View>
  )
}

export default CurveView_SelectEditor