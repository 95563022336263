import { useEffect, useMemo, useState } from "react";
import { isDemoFarm, ConsysRefs, isEmpty, TimeSpan } from "../Config/Types";
import { DataCons } from "../Services/Firebase";
import { useAppSelector } from "../Store";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/fetchBaseQuery";
import { ConsysApi } from '../Services';
import { ScaleList } from "../Services/Consys/Scale-Slice";
import { FeedInfo, FeedList } from "../Services/Consys/Feed-Slice";
import { WaterList } from "../Services/Consys/Water-Slice";
import { TemperatureList } from "../Services/Consys/Temperature-Slice";

interface DataCaller_props {
  data?: any[],
  error?: FetchBaseQueryError | SerializedError,
  isUninitialized?: boolean,
  isFetching?: boolean,
  isLoading?: boolean
}

const CheckCaller = (activefarm:string|undefined, IsDataLink:string|string[]|undefined) => (!IsDataLink||(typeof IsDataLink === "object" && isEmpty(IsDataLink)))&&activefarm&&isDemoFarm(activefarm)
export const DataCaller = (DataType:ConsysRefs, activefarm:string|undefined, IsDataLink:string|string[]|undefined, systemIds:string|string[]|undefined, ViewPeriod:TimeSpan|undefined, Skipper:boolean, valve:string|number, timerange?:'last30d'|'currentmonth'|'lastmonth'):DataCaller_props => {
  var Farm:string|undefined = undefined
  var Farms:string[]|undefined = undefined
  if (IsDataLink && Array.isArray(IsDataLink)) {
    Farm = undefined
    Farms = IsDataLink
  } 
  else if (CheckCaller(activefarm, IsDataLink) && systemIds) return useAppSelector(state => DataCons.GetDemoData(state, DataType, systemIds))
  else Farm = IsDataLink

  switch(DataType) {
    case ConsysRefs.VALVEFEED:
      return ConsysApi.Feed.GetFeedList({
        FarmKey:Farm, FarmKeys:Farms, 
        systemids:systemIds, valve:valve, 
        ViewPeriods:ViewPeriod, timerange:timerange
      }, {skip:Skipper})
    case ConsysRefs.SCALE:
      return ConsysApi.Scale.GetScaleList({
        FarmKey:Farm, FarmKeys:Farms, 
        systemids:systemIds, valve:valve,
        ViewPeriods:ViewPeriod, timerange:timerange
      }, {skip:Skipper})
    case ConsysRefs.TEMPERATURE:
      return ConsysApi.Temperature.GetTempList({
        FarmKey:Farm, FarmKeys:Farms, 
        systemids:systemIds, valve:valve, 
        ViewPeriods:ViewPeriod, timerange:timerange
      }, {skip:Skipper})
    case ConsysRefs.WATERCOUNT:
      return ConsysApi.Water.GetWaterList({
        FarmKey:Farm, FarmKeys:Farms, 
        systemids:systemIds, valve:valve, 
        ViewPeriods:ViewPeriod, timerange:timerange
      }, {skip:Skipper})
  }
  return {data:undefined, error:undefined, isUninitialized:undefined, isFetching:undefined}
}

export const DataCallerList = (DataType:ConsysRefs, activefarm:string|undefined, IsDataLink:string[], systemIds:string|string[]|undefined, Xsystemids:string[][] | {id:string,XTern:string}[][], ViewPeriod:TimeSpan[]|TimeSpan|undefined, Skipper:boolean, valve:string|number, timerange?:'last30d'|'currentmonth'|'lastmonth'):DataCaller_props => {
  if (CheckCaller(activefarm, IsDataLink)) return useAppSelector(state => DataCons.GetDemoData(state, DataType, systemIds))
  else {
    switch(DataType) {
      case ConsysRefs.VALVEFEED:
        return ConsysApi.Feed.GetFeeds({
          FarmKey:undefined, FarmKeys:IsDataLink, 
          systemids:systemIds, Xsystemids:Xsystemids, valve:valve, 
          ViewPeriods:ViewPeriod, timerange:timerange
        }, {skip:Skipper})
      case ConsysRefs.SCALE:
        return ConsysApi.Scale.GetScaleList({
          FarmKey:undefined, FarmKeys:IsDataLink, 
          systemids:systemIds, Xsystemids:Xsystemids, valve:valve, 
          ViewPeriods:ViewPeriod, timerange:timerange
        }, {skip:Skipper})
      case ConsysRefs.TEMPERATURE:
        return ConsysApi.Temperature.GetTempList({
          FarmKey:undefined, FarmKeys:IsDataLink, 
          systemids:systemIds, Xsystemids:Xsystemids, valve:valve, 
          ViewPeriods:ViewPeriod, timerange:timerange
        }, {skip:Skipper})
      case ConsysRefs.WATERCOUNT:
        return ConsysApi.Water.GetWaterList({
          FarmKey:undefined, FarmKeys:IsDataLink, 
          systemids:systemIds, Xsystemids:Xsystemids, valve:valve, 
          ViewPeriods:ViewPeriod, timerange:timerange
        }, {skip:Skipper})
    }
  }
  return {data:undefined, error:undefined, isUninitialized:undefined, isFetching:undefined, isLoading:undefined}
}

export const FeedData = (isList, XTern_Keys, AutopigIds, ViewPeriod, activePen, XTernAutos):
            {AutoData:FeedList[]|undefined, DataList:Record<string, ConsysApi.Feed.FeedInfo[]>, isFetching:boolean|undefined} => {
  const activefarm = useAppSelector(state => state.activeData.farm)
  
  const {data: ConDataList, error: ConListError, isUninitialized: NoConList, isLoading:ConLoading, isFetching:ConFetching} = isList?
    DataCallerList(ConsysRefs.VALVEFEED, activefarm, XTern_Keys, AutopigIds, XTernAutos,
      ViewPeriod,
      (isEmpty(AutopigIds)&&isEmpty(XTernAutos)), 'all'
    ):
    DataCaller(ConsysRefs.VALVEFEED, activefarm, XTern_Keys, AutopigIds,
      ViewPeriod, //FetchLimiter,
      false, activePen, 'last30d'
    )
  
  const FeedsLists = useMemo(() => {
    var dataMap:Record<string, FeedInfo[]> = {}
    //var DataFeedList: {id:string|number, data:any[]}[] = []

    if (ConDataList && !isEmpty(ConDataList)) {
      Object.values(ConDataList).forEach((FeedList_Data:FeedList) => {
        if (FeedList_Data && !isEmpty(FeedList_Data)) {
          Object.entries(FeedList_Data).forEach(([Id, dataPoint]) => {
            if (dataPoint && dataPoint.valve) {
              if (!dataMap[dataPoint.valve]) dataMap[dataPoint.valve] = []
              dataMap[dataPoint.valve].push(dataPoint)

              /*if (dataPoint.silolist && !isEmpty(dataPoint.silolist)) {
                dataPoint.silolist.forEach(Silo => {
                  if (Silo.silono && Silo.amount) {
                    if (dataPoint.timestamp >= ViewPeriod.start && (!ViewPeriod.end || dataPoint.timestamp <= ViewPeriod.end)) {
                      DataFeedList.push({id: dataPoint.valve, data:[dataPoint.valve+'', 'Silo ' + Silo.silono, Silo.amount, dataPoint.timestamp]})
                    }
                  }
                })
              }*/
            }
          })
        }
      })
    }
    return dataMap
  }, [ConDataList, ViewPeriod])

  return {AutoData:ConDataList, DataList:FeedsLists, isFetching:ConFetching}
}

export const ScaleData = (isList, XTern_Keys, AutopigIds, ViewPeriod, activePen, XTernAutos):
            {AutoData:ScaleList[]|undefined, error:FetchBaseQueryError|SerializedError|undefined, isFetching:boolean|undefined} => {
  const activefarm = useAppSelector(state => state.activeData.farm)
  
  const {data: ConDataList, error: ConListError, isUninitialized: NoConList, isLoading:ConLoading, isFetching:ConFetching} = isList?
    DataCallerList(ConsysRefs.SCALE, activefarm, XTern_Keys, AutopigIds, XTernAutos,
      ViewPeriod,
      (isEmpty(AutopigIds)&&isEmpty(XTernAutos)), 'all'
    ):
    DataCaller(ConsysRefs.SCALE, activefarm, XTern_Keys, AutopigIds,
      ViewPeriod, //FetchLimiter,
      false, activePen, 'last30d'
    )

  return {AutoData:ConDataList,isFetching:ConFetching, error:ConListError}
}

export const WaterData = (isList, XTern_Keys, AutopigIds, ViewPeriod, activePen, XTernAutos):
            {AutoData:WaterList[]|undefined, error:FetchBaseQueryError|SerializedError|undefined, isFetching:boolean|undefined} => {
  const activefarm = useAppSelector(state => state.activeData.farm)

  const {data: ConDataList, error: ConListError, isUninitialized: NoConList, isLoading:ConLoading, isFetching:ConFetching} = isList?
    DataCallerList(ConsysRefs.WATERCOUNT, activefarm, XTern_Keys, AutopigIds, XTernAutos,
      ViewPeriod,
      (isEmpty(AutopigIds)&&isEmpty(XTernAutos)), 'all'
    ):
    DataCaller(ConsysRefs.WATERCOUNT, activefarm, XTern_Keys, AutopigIds,
      ViewPeriod, //FetchLimiter,
      false, activePen, 'last30d'
    )

  return {AutoData:ConDataList, error:ConListError, isFetching:ConFetching}
}

export const TempData = (isList, XTern_Keys, AutopigIds, ViewPeriod, activePen, XTernAutos):
            {AutoData:TemperatureList[]|undefined,isFetching:boolean|undefined} => {
  const activefarm = useAppSelector(state => state.activeData.farm)

  const {data: ConDataList, error: ConListError, isUninitialized: NoConList, isLoading:ConLoading, isFetching:ConFetching} = isList?
    DataCallerList(ConsysRefs.TEMPERATURE, activefarm, XTern_Keys, AutopigIds, XTernAutos,
      ViewPeriod,
      (isEmpty(AutopigIds)&&isEmpty(XTernAutos)), 'all'
    ):
    DataCaller(ConsysRefs.TEMPERATURE, activefarm, XTern_Keys, AutopigIds,
      ViewPeriod, //FetchLimiter,
      false, activePen, 'last30d'
    )

  return {AutoData:ConDataList, isFetching:ConFetching}
}