import React from 'react'
import PropTypes from 'prop-types'
import { Platform, View } from 'react-native'
import { useTheme } from '../../Theme'
import Brand from './Brand'

import {
  AspectRatioBox,
  AspectRatioBoxBody,
} from 'baseui/aspect-ratio-box'

export interface Frame_Props {
  children?: React.ReactNode,

  logo?: boolean,
  protein?: boolean,
  bottom?: boolean
}

const Frame: React.FC<Frame_Props> = ({children, logo, protein, bottom }) => {
  const { Common, Layout, Images, Gutters } = useTheme()

  let views = []
  if (logo) {
    views.push(
      <View key={0} style={[Layout.fill, {position:'absolute', top:"5%", left:"2%", width:"33%", minWidth:280, maxWidth:550, zIndex:-98}]}>
        <Brand width={'100%'}/>
      </View>
    )
  }
  if (protein){
    views.push(
      <View key={1} style={[Layout.fill, {position:'absolute', top:"10%", right:"0%", width:"30%", height:'50%', justifyContent:'center' , zIndex:-98}]}>
        <AspectRatioBox aspectRatio={500 / 396} width={'100%'}>
          <AspectRatioBoxBody
            as="img"
            src={Images.Login_protein+'?width=500'}
          />
        </AspectRatioBox>
      </View>
    )
  }
  if (children){
    views.push(
      <View key={2} style={[Layout.fill, Layout.fullSize, Layout.colCenter, {minHeight:'max-content', minWidth:300, zIndex:0}]}>
        {children}
      </View>
    )
  }
  if (bottom){
    views.push(
      <View key={3} style={[Layout.fill, {position:'absolute', bottom:0, width:"100%", height:'fit-content', zIndex:-99}]}>
        <AspectRatioBox aspectRatio={500 / 133} width={'100%'}>
          <AspectRatioBoxBody
            as="img"
            src={Images.Login_bottom+'?width=500'}
          />
        </AspectRatioBox>
      </View>
    )
  }
  return views
}

Frame.propTypes = {
  logo: PropTypes.bool,
  protein: PropTypes.bool,
  bottom: PropTypes.bool,
}

Frame.defaultProps = {
  logo:true,
  protein: true,
  bottom: true,
}

export default Frame
