import React, { useEffect } from 'react'
//import { useTranslation } from 'react-i18next'

import { CommentData, DataConsData, Operations, PenData, PenData_Datas } from '../../../Config/Types'
import { useAppSelector } from '../../../Store'
import { /*ModuleForms, Settings_Props,*/ Selections_Type, Selection_List_T, Selection_PenOptions, ModalStates } from './index'
import { Pens as FBPens, FirebaseCaller, Weighings } from '../../../Services/Firebase'
//import { NormSorter } from '../../../utils/formaters'

export interface ModalDataProps {
  ModalState: ModalStates,
  ModalResult: undefined|number|boolean,
  //ModalUse?:{useDate?:number},
  //ScaleId?:{id:number, datas?:DataConsData},
}

interface IOperationsContext {
  Selection_Data: Selections_Type,
  PenData: {SelectedPen:Selection_PenOptions|undefined, penData:PenData_Datas|undefined},

  SectionOptions: Selection_List_T[], //All Options
  //Selection_List: Selection_List_T|undefined,   //Current
  Selection_Pens: Selection_PenOptions[],

  Operations_Data: Partial<CommentData&{isTotal:boolean}>,
  Edit_Operations_Data: Partial<CommentData>|null,
  Modal_Data: ModalDataProps,

  Modal_isDone: boolean,
  Operations_isDone: boolean,
}
export type OprerationsContext_Action =
    { type:'do_StartPen', payload: undefined}
  | { type:'do_ClosePen', payload: undefined, }
  | { type:'set_PenData', payload: {SelectedPen:Selection_PenOptions|undefined, penData:PenData_Datas|undefined}}

  | { type:'set_SectionOptions', payload: Selection_List_T[]}
  | { type:'set_Section', payload: {id:string, isShared?:string, pen?:number} }
  | { type:'set_Selected_Pen', payload?: number}

  | { type:'set_Operations_Data', payload: Partial<CommentData&{isTotal:boolean}>}
  | { type:'set_Selected_Edit', payload?: Partial<CommentData>|null} //payload?: {id:string, isWeight?:true}}

  | { type:'set_Operations_isDone', payload: boolean}
  | { type:'set_ModalForm', payload: ModalStates|{state: ModalStates, res: undefined|number|boolean}}
  | { type:'set_Modal_isDone', payload: boolean}

const OperationsContext = React.createContext<{ state: IOperationsContext, dispatch: React.Dispatch<OprerationsContext_Action> }|null>(null)
function OperationsReducer(state:IOperationsContext, action:OprerationsContext_Action) {
  switch (action.type) {
    case 'do_StartPen':
      break
    case 'do_ClosePen':
      break

    case 'set_PenData':
      var PenData = {
        SelectedPen: action.payload.SelectedPen,
        penData: action.payload.penData
      }
      return Object.assign({}, state, { PenData })
      
    case 'set_SectionOptions':
      var SectionOptions = action.payload
      var Selected:Selection_List_T|undefined = undefined
      if (SectionOptions && SectionOptions[0]) {
        Selected = SectionOptions[0]
        if(state.Selection_Data.section) {
          var index = SectionOptions.findIndex(e => e&&e.id === state.Selection_Data.section && e.isShared === state.Selection_Data.isShared)
          if (index >= 0) Selected = SectionOptions[index]
        }
      }

      var NewData = {
        SectionOptions,
        Selection_Pens: Selected&&Selected.PenOptions?Selected.PenOptions:[],
      }
      if (NewData.SectionOptions !== state.SectionOptions) return Object.assign({}, state, NewData)
      else break
    case 'set_Section':
      var changed = false
      var actionSection = action.payload&&action.payload.id?action.payload.id:undefined
      var actionIsDataLink = action.payload&&action.payload.isShared?action.payload.isShared:undefined
      var actionPen = action.payload&&action.payload.pen?action.payload.pen:undefined

      if ((!state.Selection_Data || !state.Selection_Data.section) && actionSection) changed = true
      else if (state.Selection_Data.section !== actionSection || state.Selection_Data.isShared !== actionIsDataLink) changed = true

      if (changed && actionSection) {
        var PenOptions = state.SectionOptions.find(e => e&&e.id === actionSection && (e.isShared === actionIsDataLink))?.PenOptions
        return Object.assign({}, state, {
          Selection_Data:Object.assign({}, state.Selection_Data, {section:actionSection, isShared:actionIsDataLink, pen:actionPen}),
          Selection_Pens:PenOptions,
          Edit_Operations_Data: undefined
        })
      }
      else break
    case 'set_Selected_Pen':
      if (!state.Selection_Data || !state.Selection_Data.pen || state.Selection_Data.pen !== action.payload)
        return Object.assign({}, state, {
          Selection_Data:Object.assign({}, state.Selection_Data, {pen:action.payload}),
          Edit_Operations_Data: undefined
        })
      else break
    case 'set_Selected_Edit':
      var new_Edit_Data = action.payload?action.payload:{}
      //var new_Edit_Data = Object.assign({}, state.Edit_Operations_Data, data)
      if (Object.keys(new_Edit_Data).length === 0) new_Edit_Data = null
      return Object.assign({}, state, { Edit_Operations_Data: new_Edit_Data })
    case 'set_Operations_Data':
      var data = action.payload?action.payload:{}
      var new_Operations_Data = Object.assign({}, state.Operations_Data, data)
      if (Object.keys(data).length === 0) new_Operations_Data = {}
      return Object.assign({}, state, { Operations_Data: new_Operations_Data })

    case 'set_Operations_isDone':
      return Object.assign({}, state, {Operations_isDone:action.payload})
    case 'set_ModalForm':
      var NewForm = action.payload?(action.payload.state?action.payload.state:action.payload):ModalStates.NONE
      var Result = action.payload&&action.payload.res?action.payload.res:undefined
      return Object.assign({}, state, {Modal_Data:Object.assign({}, state.Modal_Data, {ModalState:NewForm, ModalResult:Result})})
    case 'set_Modal_isDone':
      return Object.assign({}, state, {Modal_isDone:action.payload})

    default:
      console.error(`Unhandled action type: ${action&&action.type?action.type:"NoneDef"}`)
      break
  }
  return state
}

interface Operations_Provider_Props {children:JSX.Element[], 
  InitialData:{
    Selection_Data: Selections_Type,
    penLoaded:boolean
  },
  SectionOptions: Selection_List_T[],
  OnDone:(PenData:PenData_Datas|undefined, Selected_Data:Selections_Type, Data:Partial<CommentData>, Edit:Partial<CommentData>|null, ModalRes:ModalDataProps) => void,
  Edit_Operations_Data?: Partial<CommentData>|null,
}
function Operations_Provider({children, InitialData, SectionOptions, OnDone, Edit_Operations_Data}:Operations_Provider_Props) {
  const auth = useAppSelector(({firebase}) => firebase.auth)

  const [_state, dispatch] = React.useReducer<React.Reducer<IOperationsContext, OprerationsContext_Action>>(OperationsReducer, {
    PenData:{SelectedPen:undefined, penData:undefined},
    Selection_Data: InitialData.Selection_Data,
    SectionOptions,
    Selection_Pens: SectionOptions&&SectionOptions[0]&&SectionOptions[0].PenOptions?SectionOptions[0].PenOptions:[],

    Operations_Data: {user:auth.uid},
    Modal_Data: {
      ModalState:ModalStates.NONE
    },
    Modal_isDone:false,
    Operations_isDone:false
  }as IOperationsContext)

  useEffect(() => {
    if (_state.Operations_isDone && (_state.Modal_isDone && _state.Modal_Data.ModalState === ModalStates.NONE)) {
      if (!_state.Selection_Data.pen || !_state.Operations_Data.action) {
        console.log("Some data properties is wrong", _state)
        return
      }

      var EditData = {..._state.Edit_Operations_Data}
      
      var Time = _state.Operations_Data.time?_state.Operations_Data.time:Date.now()

      var quantity = _state.Operations_Data.quantity?_state.Operations_Data.quantity:null
      var weight = quantity&&_state.Operations_Data.weight?_state.Operations_Data.weight:null
      console.log("BEFORE ??", _state.Operations_Data)
      //if (quantity && weight && !_state.Operations_Data.isTotal) weight = weight * quantity

      var ResultData:CommentData = {
        user:auth.uid, pen:_state.Selection_Data.pen,
        time: Time, penTime:_state.Selection_Data.pen + '#' + Time,
        data: _state.Operations_Data.data?_state.Operations_Data.data:null,
        quantity: quantity,
        weight: weight,
        action: _state.Operations_Data.action,
        code: _state.Operations_Data.code?_state.Operations_Data.code:null,

        isTotal:_state.Operations_Data.isTotal?_state.Operations_Data.isTotal:null,
        deleted:_state.Operations_Data.deleted?_state.Operations_Data.deleted:null,
        edited:_state.Operations_Data.edited?_state.Operations_Data.edited:null, //Dount think this value is possible
        key: _state.Operations_Data.key?_state.Operations_Data.key:null,
      }
      if (!ResultData.quantity && ![Operations.TREATMENT, Operations.NOTE, Operations.CLOSE, Operations.EMPTY].includes(_state.Operations_Data.action)) {
        ResultData.deleted = true
        //EditData = null
      }

      OnDone(_state.PenData.penData?_state.PenData.penData:undefined, _state.Selection_Data, ResultData, EditData, _state.Modal_Data) //Return
      
      dispatch({type:'set_Operations_isDone', payload:false})
      dispatch({type:'set_Modal_isDone', payload:false})
      dispatch({type:'set_ModalForm', payload: {state:ModalStates.NONE, res:false}})
      dispatch({type:'set_Operations_Data', payload:{}})
    }
  }, [_state.Modal_isDone, _state.Operations_isDone])

  useEffect(() => {
    if (SectionOptions) dispatch({type:'set_SectionOptions', payload: SectionOptions})
  }, [SectionOptions])
  useEffect(() => {
    dispatch({type:'set_Selected_Edit', payload: Edit_Operations_Data})
  }, [Edit_Operations_Data])

  const _penData = useAppSelector(state => _state.Selection_Data&&_state.Selection_Data.pen?FBPens.GetPenData(state, _state.Selection_Data.pen, _state.Selection_Data.isShared):undefined)
  //const {SelectedPen, penData} = useMemo(() => {
  useEffect(() => {
    if (_state.Selection_Pens && _state.Selection_Pens[0] !== undefined) {
      //console.log('Pen test',Selection_Pens)
      var SelectedPen = _state.Selection_Pens.find(e => e&&e.id&& e.id === _state.Selection_Data.pen)
      if (SelectedPen) {
        dispatch({type:'set_PenData', payload:{
          SelectedPen,
          penData: _penData&&_penData.datas?{..._penData.datas}:undefined
        }})
        return
      } else {
        dispatch({type:'set_Selected_Pen', payload:_state.Selection_Pens[0].id})
      }
    }
    dispatch({type:'set_PenData', payload:{
      SelectedPen:undefined,
      penData: _penData&&_penData.datas?{..._penData.datas}:undefined
    } })
  },[_penData, _state.Selection_Data.pen, _state.Selection_Pens])

  if (!InitialData.penLoaded) {
    const activefarm = useAppSelector(state => state.activeData.farm)
    const LinkList_Sections = useAppSelector(({firebase: {data}}) => _state.Selection_Data.isShared&&data.LinkList&&data.LinkList[_state.Selection_Data.isShared]&&data.LinkList[_state.Selection_Data.isShared].sections?data.LinkList[_state.Selection_Data.isShared].sections:null)
    const sectionData = useAppSelector(({firebase: {data}}) => {
      var SharedData = _state.Selection_Data.isShared&&data.LinkListData&&data.LinkListData[_state.Selection_Data.isShared]&&data.LinkListData[_state.Selection_Data.isShared].sections?
                        data.LinkListData[_state.Selection_Data.isShared].sections:null
      if (SharedData) return SharedData[_state.Selection_Data.section]
      return _state.Selection_Data.section&&data.sections&&data.sections[_state.Selection_Data.section]?data.sections[_state.Selection_Data.section]:undefined
    })
    FirebaseCaller(
      Weighings.fetchWeighings(activefarm, false, [], _state.Selection_Data.isShared, undefined, false, _state.Selection_Data.isShared?sectionData:undefined, LinkList_Sections)
    ,[_state.Selection_Data, _penData])
  }

  return (
    <OperationsContext.Provider value={{state:_state, dispatch}}>
      {children}
    </OperationsContext.Provider>
  )
}

function useOperations_Context():{state: IOperationsContext, dispatch: React.Dispatch<OprerationsContext_Action>} {
  const context = React.useContext(OperationsContext)
  if (context === undefined || context === null) {
    throw new Error('useOperations_Context must be used within a Operations_Provider')
  }
  return context
}

export {Operations_Provider, useOperations_Context}