import React, { useEffect, useState, useMemo } from 'react'
import {
  View,
  Text,
  TouchableOpacity
} from 'react-native'
import { Button, LoadingSplash, Datetime_Picker } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, useisAppView } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { FirebaseCaller, Sections, Pens as FBPens, Weighings, Graphs, DataCons } from '../../Services/Firebase'

import { setStates } from '../../Store/StateHandlers/Active-slice'
import { WeightsCalc } from '../../utils/DataCalculator'
import { Combobox, SIZE } from 'baseui/combobox'
import { sortAlphaNum, sortNumAlpha, NormSorter } from '../../utils/formaters'
import { UnixDayTime, ConsysRefs, DataConsData, isEmpty, SectionData, SystemData, isDemoFarm, CommentData } from '../../Config/Types'
import { DataCaller, FeedData, ScaleData } from '../../utils/DataCallers'
import { FeedList } from '../../Services/Consys/Feed-Slice'
import { ScaleList } from '../../Services/Consys/Scale-Slice'
import { TemperatureList } from '../../Services/Consys/Temperature-Slice'
import { WaterList } from '../../Services/Consys/Water-Slice'

interface PenComp_Prop {
  SectionId:string, 
  PenId:number, 
  DisplayData:any, 
  IsDataLink:string|undefined, 
  SelectedPeriode:number, 
  DataPeriod:[number, number],
  ConsysData:any[] | FeedList[] | ScaleList[] | TemperatureList[] | WaterList[] | undefined
}
const PenComp = ({SectionId, PenId, DisplayData, IsDataLink, SelectedPeriode, DataPeriod, ConsysData}:PenComp_Prop) => {
  const { t } = useTranslation()
  const { Common, Colors } = useTheme()
  const dispatch = useAppDispatch()

  const DataSelection = [t('Pens.Options2_1'), t('Pens.Options2_7'), t('Pens.Options2_2'), t('Pens.Options2_3'), t('Pens.Options2_4'), t('Pens.Options2_5'), t('Pens.Options2_6')]
  const Settings = useAppSelector<SystemData|undefined>(({firebase: {data}}) => data&&data.system?data.system:undefined)
  
  //const activefarm = useAppSelector(state => state.activeData.farm)
  const penData = useAppSelector(state => FBPens.GetPenData(state, PenId, IsDataLink))
  const Curve = useAppSelector(state => penData&&penData.datas&&penData.datas.curve?Graphs.GetPenWeightGraph(state, IsDataLink, penData.datas.curve):undefined)
  const AutoPig = useAppSelector(state => penData&&penData.datas&&penData.datas.autopigs&&penData.datas.autopigs!==""?DataCons.GetPenDataCon(state, IsDataLink, penData.datas.autopigs):undefined)

  const weighings = useAppSelector(state => Weighings.GetPenWeights(state, IsDataLink, PenId))

  const [Display, setDisplay] = useState<{color:typeof Colors, context:string|undefined}>({color:Colors.gray, context:undefined})

  useEffect(() => {
    let active = true
    load(active)
    return () => { active = false }
  }, [weighings, DisplayData, ConsysData, SelectedPeriode, DataSelection[0], AutoPig])

  async function load(active:boolean) {
    var _Color = Colors.gray
    var _DisplayText:string|undefined = undefined

    var PenActive:number|undefined = (penData?.datas?.activedate||undefined)
    if (PenActive && PenActive < UnixDayTime) PenActive = new Date().setDate(new Date().getDate() - PenActive)
    var Weights:Partial<CommentData>[]|undefined = weighings?[...weighings.filter(e => e&&(!e.edited&&!e.deleted))]:undefined
    var isAuto = false
    if (ConsysData && !isEmpty(ConsysData) && AutoPig) {
      var AutoDatas = ConsysData.reduce((res, AutoData) => {
        var Result = {...res}

        var ret = Object.entries(AutoData).filter(([name, datas]) => 
          Object.entries(AutoPig).some(([auto, autodatas]) => {
            if ((autodatas && autodatas.name && autodatas.name === name) || (name.startsWith('SysId_') && name === 'SysId_' + auto)) return true
            else return false
          })
        )

        ret.forEach(([name, datas]) => {
          var key = Object.entries(AutoPig).findIndex(([auto, autodatas]) => ((autodatas && autodatas.name && autodatas.name === name) || (name.startsWith('SysId_') && name === 'SysId_' + auto)))
          if (key !== undefined && key >= 0) {
            key = Object.keys(AutoPig)[key]
            var Datas = Object.values(AutoData)[0]
            if (Result[key]) Result[key] = {...Result[key], Datas}
            else Result[key] = Datas
          }
        })

        return Result
      }, {})

      if (!isEmpty(AutoDatas)) {
        //TODO: Multiple autopigs ???  Object.values(AutoDatas)[0]
        var AutoWeights = Object.values(AutoDatas).map(data => {
          return Object.values(data).map(e => {
            if (e.timestamp <= new Date().setHours(0,0,0,0)) {
              return {count:1, pen:PenId, time: e.timestamp, penTime:`${PenId}#${e.timestamp}`, weight:e.amount} as Partial<CommentData>
            } else {
              return undefined
            }
          }).filter(e => e)
        })
        if (!isEmpty(AutoWeights) && !isEmpty(AutoWeights[0])) {
          isAuto = true
          Weights = AutoWeights[0]
        }
      }
    }
    if (Weights && (DisplayData === DataSelection[0] || DisplayData === DataSelection[1])) {
      var WeightData = WeightsCalc(PenId+'', Weights, Curve, true, {startdate:PenActive, _now:SelectedPeriode})
      if (!active) { return }
      //if (DisplayData === DataSelection[1]) console.log(WeightData)
      //var penCifer = !isAuto&&penData&&penData.datas&&penData.datas.count?penData.datas.count:1
      var Weight:number|undefined = undefined
      if (WeightData && WeightData.Weight) {
        Weight = WeightData.Weight
        _DisplayText = ''+ Math.round(WeightData.Weight * 1000) / 1000 // Math.round(((WeightData.Weight * WeightData.Count) / penCifer) * 100) / 100
      }
      if (WeightData && WeightData.Predict && Weights.length > 1) {
        if (WeightData.Predict.Now) {
          Weight = WeightData.Predict.Now
          _DisplayText = ''+Math.round(WeightData.Predict.Now * 1000) / 1000 // Math.round(((WeightData.Predict.Now * WeightData.Count) / penCifer) * 100) / 100
        }

        if (WeightData.Predict.TempEx && PenActive) {
          var devianceMin = 3
          var devianceMax = 3

          var ExValue = WeightData.Predict.TempEx
          var devianceValMin = (ExValue/100)* devianceMin
          var devianceValMax = (ExValue/100)* devianceMax
          if (ExValue && (Weight||0) < ExValue && (Weight||0) < ExValue - devianceValMin) _Color = Colors.error
          else if (ExValue && (Weight||0) > ExValue && (Weight||0) > ExValue + devianceValMax) _Color = Colors.overest
          else _Color = Colors.success
        }
      }
    }
    else if (DisplayData === DataSelection[2] && penData&&penData.datas&&penData.datas.count!=undefined) {
      _DisplayText = penData.datas.count + ''
      if (penData&&penData.datas&&penData.datas.activedate) _Color = Colors.success
      else _Color = Colors.BUMBLEBEE
    }
    
    if (DisplayData === DataSelection[3]) {
      _DisplayText = undefined
      if (PenActive) {
        var days = Math.floor(((SelectedPeriode-PenActive)/UnixDayTime))
        _DisplayText =  days +''
      } else {
        _Color = Colors.gray
      }
    }
    if (!active) { return }
    if (_Color !== Display.color || _DisplayText !== Display.context) setDisplay({color:_Color, context:_DisplayText})
  }

  return(
    <TouchableOpacity 
      style={[Common.button.outlineRounded, {marginBottom:3, marginRight:3, borderColor:'#000000', borderWidth:(!isEmpty(AutoPig)&&Object.values((e:DataConsData)=>e).some((e:DataConsData) => e.scale))?5:0, 
        minWidth:70,width:70,maxWidth:70, minHeight:70,height:70,maxHeight:70, alignItems:"center", justifyContent:"center", backgroundColor:Display.color, flexGrow:1, flexDirection:'column'
      }]}
      onPress={() => {
        dispatch(setStates({state:"section", data:SectionId}))
        dispatch(setStates({state:"pen", data:PenId}))
        navigate('User', {screen: 'Pen_Display', params:{IsDataLink:IsDataLink}})
      }}>
      <Text style={{minWidth:60, textAlign:'center'}}>{(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ PenId}</Text>
      {Display.context?(<Text>{Display.context}</Text>):(<></>)}
    </TouchableOpacity>
  )
}

//#region Section Component
interface SectionComp_Prop {
  Sections:Record<string,SectionData>|undefined, 
  LayoutAS?:"Section"|"Batch", 
  DisplayData:any, 
  IsDataLink:string|undefined, 
  ViewPeriod:number,
  ConsysData:any[] | FeedList[] | ScaleList[] | TemperatureList[] | WaterList[] | undefined
}
const SectionComp = ({Sections, LayoutAS="Section", DisplayData, IsDataLink, ViewPeriod, ConsysData}:SectionComp_Prop) => {
  if (!Sections) return (<></>)

  //const { t } = useTranslation()
  const { Common, Gutters, Layout } = useTheme()
  const isAppView = useisAppView()
  
  const _Sections = useMemo(() => {
    if (LayoutAS === "Section") {
      var Res = Object.entries(Sections).map(([Id,Data]) => {
        var Pens = Data&&Data.pens?Data.pens:[]
        return({
          title: Id,
          pens: Object.values(Pens) as number[]
        })
      }).sort((a, b) => NormSorter(a.title, b.title))
      return Res
    }else {
      return []
    }
  }, [Sections, LayoutAS])

  return (
    <View style={[Layout.colVCenter, Gutters.smallHPadding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'min-content'}]}>
      {Object.entries(_Sections).map(([key, {title, pens}]) => {
        return(
          <View key={title} style={[{ marginBottom: 10, width:'-webkit-fill-available', minWidth:200, minHeight:'max-content'}]}>
            <View style={[Layout.midWidth, {marginBottom:4}, isAppView?{width:"65%"}:{width:"100%"}]}>
              <Button Contexts={[title]} Title={true} 
                Action={()=> {

                }}
              />
            </View>
            <View style={{flexWrap: "wrap", flexDirection: "row"}}>
              {Object.entries(pens).map(([key, pen]) => {
                return (
                <PenComp DisplayData={DisplayData} key={key} SectionId={title} PenId={pen} IsDataLink={IsDataLink} SelectedPeriode={ViewPeriod} DataPeriod={[
                  ViewPeriod>new Date().setHours(0,0,0,0)?new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() -3):new Date(new Date(ViewPeriod).setHours(0,0,0,0)).setDate(new Date(ViewPeriod).getDate() -3), 
                  new Date(new Date(ViewPeriod).setHours(23,59,59,0)).setDate(new Date(ViewPeriod).getDate())]
                } ConsysData={ConsysData}/>
              )})}
            </View>
          </View>
        )
      })}
    </View>
  )
}
//#endregion

const Pens = () => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  
  //const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const LinkListData = useAppSelector(({firebase: { data }}) => data.LinkListData)
  const sections = useAppSelector(state => Sections.GetSections(state))

  //Sections, Batch
  const SelectionGroups = [t('Pens.Options1_1'), t('Pens.Options1_2')]
  const [SelectedGrouping, setSelectedGrouping] = useState(SelectionGroups[0])
  useEffect(() => setSelectedGrouping(SelectionGroups[0]), [SelectionGroups[0]])

  //Weights, growth, animal count, age days, water con, feed con, Activity
  const DataSelection = [t('Pens.Options2_1'), /*t('Pens.Options2_7'),*/ t('Pens.Options2_2'), t('Pens.Options2_3'), t('Pens.Options2_4'), t('Pens.Options2_5'), t('Pens.Options2_6')]
  const [SelectedData, setSelectedData] = useState(DataSelection[0])
  useEffect(() => setSelectedData(DataSelection[0]), [DataSelection[0]])

  const [ViewPeriod, updateViewPeriod] = useState(new Date(new Date().setHours(23,59,59,59)).setDate(new Date().getDate() - 1))
  FirebaseCaller(
    Weighings.fetchWeighings(activefarm, false, [], undefined).concat(
      LinkListData&&!isEmpty(LinkListData)?Object.entries(LinkListData).map(([Current_Farm, Data]) => {
        return Weighings.fetchWeighings(activefarm, false, [], Current_Farm)
      }).flat(1):[]
    ).concat(
      Graphs.fetchWeightGraphs(activefarm, false, [], undefined).concat(
        LinkListData&&!isEmpty(LinkListData)?Object.entries(LinkListData).map(([Current_Farm, Data]) => {
          return Graphs.fetchWeightGraphs(activefarm, false, [], Current_Farm)
        }).flat(1):[]
      )
    )
  )

  const {AutoData:ConScaleList, error:ConScaleListError} = ScaleData(false, undefined, 'all', ViewPeriod, 'all', undefined)
  //const {AutoData:XTernConScaleList, error:XTernConScaleListError} = ScaleData(false, LinkListData&&!isEmpty(LinkListData)?Object.keys(LinkListData):undefined, 'all', ViewPeriod, 'all', undefined)
  const {data: XTernConScaleList, error: XTernConScaleListError, isUninitialized: NoXTernConScaleList} = DataCaller(ConsysRefs.SCALE, activefarm, LinkListData&&!isEmpty(LinkListData)?Object.keys(LinkListData):undefined, 'all',
    {
      start:ViewPeriod>new Date().setHours(0,0,0,0)?new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() -3):new Date(new Date(ViewPeriod).setHours(0,0,0,0)).setDate(new Date(ViewPeriod).getDate() -3), 
      end:  new Date(new Date(ViewPeriod).setHours(23,59,59,0)).setDate(new Date(ViewPeriod).getDate())
    },
    (Object.values((e:DataConsData)=>e).some((e:DataConsData) => e.scale))
  , 'all')
  useEffect(() => {
    if (ConScaleListError) console.error({ConScaleListError})
    if (XTernConScaleListError) console.error({XTernConScaleListError})
  },[ConScaleListError, XTernConScaleListError])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  //if (!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms)) return <LoadingSplash/>
  return (
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <View style={[Layout.rowVCenter, {flexWrap:'wrap', marginLeft:10, marginTop:10, marginBottom:15, alignSelf:'flex-start', width:'-webkit-fill-available'}]}>
        <View style={[Layout.row, Layout.havlWidth, {marginTop:5, alignItems:'center'}]}>
          <Text style={{marginRight:10}}>{t('Pens.Options1')}</Text>
          <Combobox disabled={true}
            size={SIZE.mini}
            value={SelectedGrouping}
            onChange={(nextValue, ele:any) => {
              setSelectedGrouping(nextValue)
            }}
            options={SelectionGroups.map(Group => ({label:Group, id:Group}))}
            mapOptionToString={option => option.label}
            overrides={{
              Root: {style: ({ $theme }) => ({
                height: '26px',width: '60px',
                outline: '1px solid black'
              })},
              Input: {props: {overrides: {Input: {style: ({ }) => ({
                paddingLeft: "2px",paddingRight: "2px",paddingTop: "1px", paddingBottom: "1px"
              })}}}}
            }}
          />
        </View>
        <View style={[Layout.row, Layout.havlWidth, {marginLeft:10, marginTop:5, alignItems:'center'}]}>
          <Text style={{marginRight:10}}>{t('Pens.Options2')}</Text>
          <Combobox
            size={SIZE.mini}
            value={SelectedData}
            onChange={(nextValue, ele:any) => {
              setSelectedData(nextValue)
            }}
            options={DataSelection.map(Data => ({label:Data, id:Data}))}
            mapOptionToString={option => option.label}
            overrides={{
              Root: {style: ({ }) => ({
                height: '26px',width: '120px',
                outline: '1px solid black'
              })},
              Input: {props: {overrides: {Input: {style: ({ }) => ({
                paddingLeft: "2px",paddingRight: "2px",paddingTop: "1px", paddingBottom: "1px"
              })}}}}
            }}
          />
        </View>
        <View style={[Layout.row, Layout.havlWidth, {marginLeft:10, marginTop:'-10px'}]}>
          <Datetime_Picker single Title={t('Pens.ViewDate')} MarkDates={[{timestamp:new Date().getTime(), color:'lightgray'}]}
            value={{start:ViewPeriod}}
            onValueChange={(time) => {
              if (time) updateViewPeriod(time.start)
            }}
          />
        </View>
      </View>
      <View style={[Layout.fill, {marginLeft:10, marginTop:10, marginBottom:5, alignSelf:'flex-start', width:'-webkit-fill-available'}]}>
        {!isEmpty(LinkListData)?Object.entries(LinkListData).map(([Current_Farm, Data], ListIndex) => {
          if (Data&&Data.sections) {
            return (
              <SectionComp key={"Sections_" + Current_Farm} Sections={Data.sections} LayoutAS={SelectedGrouping===SelectionGroups[0]?'Section':'Batch'} DisplayData={SelectedData} IsDataLink={Current_Farm} ViewPeriod={ViewPeriod} ConsysData={XTernConScaleList&&XTernConScaleList[ListIndex]?XTernConScaleList[ListIndex]:undefined}/>
            )
          } 
          else return null
        }):(<></>)}
        <SectionComp Sections={sections} LayoutAS={SelectedGrouping===SelectionGroups[0]?'Section':'Batch'} DisplayData={SelectedData} IsDataLink={undefined} ViewPeriod={ViewPeriod} ConsysData={ConScaleList}/>
      </View>
    </View>
  )
}

export default Pens