import React, { useEffect } from 'react'

import { useAppDispatch } from '../Store'
import { doInit } from '../Store/StateHandlers/Init-slice'

import { LoadingSplash } from '../Components'

const IndexStartupContainer = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(doInit())
  }, [])

  return (
    <LoadingSplash />
  );
}

export default IndexStartupContainer