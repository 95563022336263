import React from 'react';
import {
  Platform, LogBox
} from 'react-native'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { store, persistor, rrfProps } from './Store'

import { ApplicationNavigator } from "./Navigators";
import './Translations'

import {Client as Styletron} from 'styletron-engine-atomic'
import {Provider as StyletronProvider} from 'styletron-react'
import {LightTheme, BaseProvider} from 'baseui'
const engine = new Styletron()

LogBox.ignoreLogs(['Animated: `useNativeDriver` is not supported'])
const App: React.FC = () => {
  return(
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <PersistGate loading={null} persistor={persistor}>
          <StyletronProvider value={engine}>
            <BaseProvider theme={LightTheme} zIndex={999}
              overrides={{
                AppContainer:{
                  style: () => ({
                    outline: `0px #000 solid`,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    ...Platform.select({web: {height: '100vh', default: {height: '100%'}}}),
                    ...Platform.select({web: {weight: '100vh', default: {weight: '100%'}}})
                  })
                }
              }}>
               <ApplicationNavigator />
            </BaseProvider>
          </StyletronProvider>
        </PersistGate>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

export default App;
