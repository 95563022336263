/**
 * This file contains the application's variables.
 *
 * Define color, sizes, etc. here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

/**
 * Colors
 */
export const Colors = {
  transparent: 'rgba(0,0,0,0)',
  white: '#ffffff',
  gray: '#a6a6a6',

  text: '#4C4C4E',
  secountText: '#ffffff',

  primary: '#A4B9CF',
  backgray: '#f2f2f2',

  input: '#86939e',
  inputBackground: '#FFFFFF',

  success: '#09A589',
  error: '#BE1622',
  overest: '#4cafea',
  pigpink: '#FFAA95',

  IdleBlue: '#00B2EE',
  BUMBLEBEE: '#FCE205',
  AgriRed: '#BE1622',
  AgriGreen: '#09A589',
  AgriDarkblue: '#4563AC',
  AgriLightblue: '#A4B9CF',
  AgriDark: '#4C4C4E',
}

export const NavigationColors = {
  primary: Colors.primary,
  card: Colors.AgriDarkblue,
}

/**
 * FontSize
 */
export const FontSize = {
  small: 16,
  regular: 20,
  large: 40,
}

/**
 * Metrics Sizes
 */
const tiny = 5 // 5
const small = tiny * 2 // 10
const regular = tiny * 3 // 15
const large = regular * 2 // 30
const huge = large * 2 // 60
export const MetricsSizes = {
  tiny,
  small,
  regular,
  large,
  huge,
}
