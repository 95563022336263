import React, { useEffect, useMemo } from 'react'
import {
  View,
  Text,
  Clipboard,
} from 'react-native'
import { Button, ContentProp, LoadingSplash, Progressbar, Labelled_Input } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../Store'
import { FarmData, PenData, isEmpty, ConsysRefs, CommentData } from '../../Config/Types'
import { WeightsCalc } from '../../utils/DataCalculator'
import { VictoryChart, VictoryTheme, VictoryBar, VictoryTooltip, VictoryVoronoiContainer, VictoryAxis } from 'victory-native'
import { FirebaseCaller, Pens as FBPens, Weighings, Graphs } from '../../Services/Firebase'

//import * as Appdata from '../../Services/Firebase/'
import { DataCaller } from '../../utils/DataCallers'

const DashBoard = () => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout, Colors } = useTheme()
  //const dispatch = useDispatch()
  //const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  const farms = useAppSelector<{[key:string]:FarmData}>(({firebase: { data }}) => data?.farms)
  const activefarm = useAppSelector<string|undefined>(({activeData}) => activeData?.farm)
  const farmData = useMemo<FarmData|undefined>(() => farms&&activefarm&&farms[activefarm]?farms[activefarm]:undefined, [farms,activefarm])
  
  const GraphsDatas = useAppSelector(state => Graphs.GetPenWeightGraph(state, undefined, undefined), undefined)

  FirebaseCaller(
    Weighings.fetchWeighings(activefarm, false, [], undefined)
  )

  const penDatas = useAppSelector<Record<string, PenData>|undefined>(({firebase: {data}}) => data?.pens)
  const Autopigs = useAppSelector(({firebase: {data}}) => data?.DataCons)
  const {data: ConScaleList, error: ConScaleListError, isUninitialized: NoConScaleList} = DataCaller(ConsysRefs.SCALE, activefarm, undefined, 'all',{
    start:new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() -4),
    end:new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate())
  }, (isEmpty(Autopigs)), 'all')

  //TODO: External gets

  useEffect(() => {
    if (ConScaleListError) console.error({ConScaleListError})
  },[ConScaleListError])

  const weighings = useAppSelector(state => Weighings.GetPenWeights(state, undefined, undefined))
  const [MaxCapacity, Capacity, WeightDist, MaxWeight, MinWeight] = useMemo(() => {
    var _MaxCap = 0, Cap = 0

    var min:number|undefined = undefined, max:number|undefined = undefined
    var WeightArr:{count:number, weight:number, isAuto:boolean}[] = []
    var _WeightDrist:number[] = []
    if (penDatas) {
      Object.entries(penDatas).forEach(([PenId, data]:[string,PenData]) => {
        _MaxCap += data.datas&&data.datas.pensize?data.datas.pensize:1
        if (data && data.datas) {
          if (data.datas.count && data.datas.count > 0) Cap += data.datas.count

          var Weights:CommentData[]|undefined = undefined
          var isAuto = false
          if (data.datas.autopigs && ConScaleList && !isEmpty(ConScaleList)) {
            data.datas.autopigs.split(',').forEach(Id => {
              if (Id && ConScaleList[parseInt(Id)-1] && !isEmpty(ConScaleList[parseInt(Id)-1])) {
                var AutoWeights = Object.entries(ConScaleList[parseInt(Id)-1]).map(([AutoKey, AutoWeight_data]) => {
                  return Object.values(AutoWeight_data).map(e => {
                    return {count:1, pen:parseInt(PenId), time: e.timestamp, penTime:`${PenId}#${e.timestamp}`, weight:e.amount} as Partial<CommentData>
                    //return {count:(data.datas.count||1),
                  })
                })
                if (!isEmpty(AutoWeights)) {
                  isAuto = true
                  Weights = AutoWeights[0]
                }
              }
            })
          }
          if (!Weights && weighings && weighings[parseInt(PenId)]) Weights = Object.entries(weighings[parseInt(PenId)]).filter(([key, _data]) => ((_data.pen+''===PenId) && (!_data.edited&&!_data.deleted))).map(([key, _data]) => ({..._data, key:key}))

          const Curve = (GraphsDatas&&data.datas&&data.datas.curve&&GraphsDatas[data.datas.curve])?GraphsDatas[data.datas.curve]: undefined
          if (Weights && Weights.length) {
            var WeightData = WeightsCalc(PenId+'', Weights, Curve, true, {startdate:data.datas.activedate?data.datas.activedate:undefined, _now:Date.now()})
           
            var Weight:number|undefined = WeightData.Weight
            if (WeightData && WeightData.Predict && WeightData.Predict.Now) Weight = WeightData.Predict.Now
            WeightArr.push({count:data.datas.count?data.datas.count:1, weight:Weight, isAuto: isAuto})

            if (!min || Weight < min) min = Math.floor(Weight)
            if (!max || Weight > max) max = Math.ceil(Weight)
          } else {
            //TODO: could count empty boxes and size's?
          }
        }
      })
      if (min !== undefined && max !== undefined) {
        var Spacing = (max - min) <= 100 ? 1 : 10
        WeightArr.forEach(e => {
          if (min !== undefined && e.weight) {
            var Index = min + Math.floor(((e.weight - min) / Spacing))
            if (_WeightDrist[Index]) _WeightDrist[Index] = _WeightDrist[Index] + e.count
            else _WeightDrist[Index] = e.count 
          }
        })
      }
    }
    if (min === undefined || min < 0) min = 0
    if (max === undefined || max < 20) max = 20
    while ((max - min) < 10) {
      if (min > 5) min -=1
      max +=1
    }
    return [_MaxCap, Cap, _WeightDrist, max, min]
  },[penDatas, weighings, ConScaleList])

  //Auth Check Handler
  /*useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])*/

  useEffect(() => {
    //dispatch(Appdata.Sections.fetchSections({Type:'ALL'}))
    return () => {
      // Clean-Up
    }
  },[])

  if (!auth || !auth.uid) return <LoadingSplash/>
  return(
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <View style={{height:10, minWidth:300}}/>
      <Button Contexts={t('DashBoard.Title')} Title={true}/>
      
      <View style={{height:20}}/>

      <ContentProp Columns={2} Datas={[[t('DashBoard.Prop1'), (farmData?.name||"")], [t('DashBoard.Prop2'), (farmData?.manager||"")], [t('DashBoard.Prop3'), (farmData?.email||"")], [t('DashBoard.Prop4'), farmData?.phoneNumber||""]]}/>
      <View style={[Layout.fullWidth, Layout.colVCenter, {justifyContent:'center', flexWrap:'wrap', flexDirection:'row', margin:10}]}>
        <Button Contexts={t("FarmInfo.Copy")} maxWidth={200} Title Action={() => {
          Clipboard.setString(activefarm)
        }}/>
        <Labelled_Input maxWidth={200} minWidth={120}
          value={activefarm?activefarm:""}
          placeholder={"Something went Wrong."}
          onChange={(text) => { }}
        />
      </View>
      <View style={[Layout.midWidth ,{marginTop:20, maxWidth:600}]}>
        <Progressbar Texts={t('DashBoard.CapacityTxt', {current:Capacity, full:MaxCapacity})} Progress={((Capacity/MaxCapacity) * 100)} Color={((Capacity/MaxCapacity) * 100)>100?Colors.error:null}/>
      </View>
      <View style={[Layout.fullWidth, {maxHeight:300}]}>
        <Text style={[{alignSelf:'center', marginBottom:-20}]}>{t('DashBoard.DistriTitle')}</Text>
        <VictoryChart
          theme={VictoryTheme.material}
          domainPadding={{ x: (600/(MaxWeight - MinWeight))/2 }}
          height={300}
          width={600}
          containerComponent={<VictoryVoronoiContainer voronoiDimension="x"/>}
        >
          <VictoryBar key={"Distribution"}
            domain={{x:[MinWeight,MaxWeight]}}
            labels={({ datum }) => {
              return t('GraphViewer.CountToWeight', {count:datum._y, weight:datum._x})
            }}
            labelComponent={<VictoryTooltip />}
            barRatio={ 0.8 }
            barWidth={ ({index}) => (600/(MaxWeight - MinWeight))/1.25 }
            style={{data: { stroke: "black", strokeWidth: '0.5px' } }}
            data={ WeightDist }
          />
          <VictoryAxis crossAxis={false}
            dependentAxis
            tickCount={10}
            label={t('GraphViewer.CountFormat')}
            style={{
              axisLabel: {fontSize: 15, padding: 35},
              grid: {
                stroke: "#818e99",
                strokeWidth: 0.5
              }
            }}
          />
          <VictoryAxis crossAxis={false}
            width={600}
            label={t('GraphViewer.WeightFormat')}
            style={{
              axisLabel: {fontSize: 15, padding: 32},
              grid: {
                stroke: "#818e99",
                strokeWidth: 0.5
              }
            }}
          />
        </VictoryChart>
      </View>

      <View style={{height:20}}/>
      <Text>{t('DashBoard.BatchesTitle')}</Text>
      <View style={{height:30}}/>
    </View>
  )
}

export default DashBoard