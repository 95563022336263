import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Image,
  ScrollView
} from 'react-native'
import { LanguageSelector, Brand, Frame, Button } from '../Components'
import { useTheme } from '../Theme'
import { useTranslation } from 'react-i18next'
import { navigate } from '../Navigators/Root'
import { WebView } from 'react-native-webview'

import axios from 'axios'
//import Facebook from 'facebook-js-sdk'

import {
  AspectRatioBox,
  AspectRatioBoxBody,
} from 'baseui/aspect-ratio-box';
import { useAppDispatch } from '../Store'
import { setIsQuickApp,  } from '../Store/StateHandlers/Init-slice'

const instance = axios.create({
  baseURL: 'https://graph.facebook.com/v17.0/',
  timeout: 1000,
  headers: {'Authorization': 'Bearer ' + 'EAABZC74PVJA4BAFYsmkfQTTHMpzjY5fmhjAtFBZCVnZCrzuaVZCQFijZAPZCKJWAf80aQZC5RZCuuBGZAEkTAMU1n3wfXVYhDi4hDyLJRWuQjZCL3Qkq49ZA7ZC04vIFTIW9UnArMeV4ErfecumIOiO35xd0mP4ak9EjwfLIqV2I9Mrf4YNPec6m8eGiUMbVEBAaFXSKcw8g4fBZAiHyqgnBnKcUo'}
})

const IndexAgrisysContainer = () => {
  const { t } = useTranslation()
  const { Common, Fonts, Gutters, Layout, Images, Colors } = useTheme()
  const dispatch = useAppDispatch()
  
  const [PostData, setPostData] = useState<null|any>(null)

  useEffect(() => {
    /*instance.get('/me/feed?fields=child_attachments,full_picture,message&limit=1').then(response => {
      if (response.data) {
        if (response.data.data && response.data.data[0]) {
          console.log(response.data.data[0])
          //setPostData(response.data.data[0])
        }
      }
    })*/
    return () => {
      // Clean-Up
    }
  },[])

  const MainMenus = [t('AgrisysFront.Menu1'), t('AgrisysMenu.Operations') /*t('AgrisysFront.Menu3')*/] // t('AgrisysFront.Menu2')]
  
  return(
    <View style={[Layout.fullFill, Layout.fullSize, Layout.colCenter, Gutters.tinyPadding, Common.bagroundPrimary, { minWidth:300, backgroundColor:'#f2f2f2', zIndex:-999}]}>
      <LanguageSelector/>
      <ScrollView style={[Layout.fill, Layout.fullSize, {zIndex:2}]} centerContent={true} contentContainerStyle={[Layout.fill, {justifyContent: "center", alignItems: "center", minHeight:'max-content'}]}>
        <Brand />
        {/*<View style={[Layout.colCenter, {height:50,}]}>
          <Text style={Fonts.textRegular}>
            {t('AgrisysFront.Welcome')}
          </Text>
        </View>*/}
        
        {/*<WebView
          source={{
            uri: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7075207445031079938',
          }}
          style={{maxWidth:600,  width:'100%' , height:'100%'}}
          containerStyle={{ width:'100%' , height:'100%' }}
        />*/}
        
        {/*PostData?
        <View style={[Layout.rowCenter, {width:'-webkit-fill-available'}]}>
          {PostData.full_picture?
            <Image
              style={{width:'-webkit-fill-available', maxWidth:500, height:500}}
              source={{uri: PostData.full_picture}}
            />
          :<></>}
          {PostData.message?
            <Text style={[Fonts.textRegular, {marginLeft:20}]}>
              {PostData.message?PostData.message:''}
            </Text>
          :<></>}
        </View>
        :<WebView
          source={{
            uri: 'https://www.linkedin.com/embed/feed/update/urn:li:share:7075207445031079938',
            //uri: 'https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fagrisys.dk%2Fposts%2Fpfbid0HmMgiKYuLvXPGzdVUUbGrb9DRQzMXN5PRJpPL7K73WzXrqWwkNmeoKPs6Dzh1REyl&show_text=true'
          }}
          style={{maxWidth:600, height:'100%'}}
          containerStyle={{ width:'100%' }}
        />*/}
        <View style={{height:'15px'}}/>
        <Button Contexts={MainMenus} EndEnhancers={[Images.Haxa_globe]} maxWidth={600} Action={ele => {
          switch(ele) {
            case 0:
              dispatch(setIsQuickApp({data:false}))
              navigate('Auth', {screen: 'Home'})
              break;
            case 1:
              dispatch(setIsQuickApp({data:true}))
              navigate('Auth', {screen: 'Home'})
              //navigate('Main', {screen: 'Operations'})
              //navigate('Auth', {screen: 'Home',  params:{options:'Mobile'}})
              break;
            case 2:
              //changeTheme({ darkMode: true })
              //navigationRef.current?.navigate('Main', {screen: 'Test'})
              break;
          }
        }} />
      </ScrollView>
      <Frame protein={false} logo={false} />
    </View>
  )
}

export default IndexAgrisysContainer