import React, { useEffect, useState, useMemo } from 'react'
import {
  View,
  Text,
  TouchableOpacity
} from 'react-native'
import { Button, LoadingSplash, System_Module } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, isApp } from '../../Navigators/Root'

import { useFirebase, isLoaded, isEmpty as FBisEmpty, useFirebaseConnect } from 'react-redux-firebase'
import { useAppSelector, useAppDispatch } from '../../Store'
//import { FarmData, SystemStates } from '../../Config/Types'

//import { SystemInfo, SystemList } from '../../Services/Consys/Systems-slice'
import { ConsysApi } from '../../Services/'
import { isEmpty } from '../../Config/Types'

const Systemboard = () => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  
  //const firebase = useFirebase()

  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector(({firebase}) => firebase.profile)
  
  const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  const farmData = useMemo(() => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null, [farms, activefarm])

  const {data: ConSystemList, error: ConSystemListError, isUninitialized: NoConSystemList} = ConsysApi.System.GetSystemList({FarmKey:undefined, getInfos:true}, {skip:(!farmData||!farmData.site||!farmData.refToken), pollingInterval:7000})
  useEffect(() => {
    if (ConSystemListError) {}
    else if (ConSystemList) {
    }
  },[ConSystemListError, ConSystemList])

  const DataLinks = useAppSelector(({firebase: { data }}) => data.DataLinks)
  //const LinkList = useAppSelector(({firebase: { data }}) => data.LinkList)
  const {data: XTernConSystemList, error: XTernConSystemListError, isUninitialized: NoXTernConSystemList} = ConsysApi.System.GetSystemList({FarmKey:undefined, FarmKeys:DataLinks&&!isEmpty(DataLinks)?Object.keys(DataLinks):undefined, getInfos:true}, {skip:(!DataLinks||isEmpty(DataLinks)), pollingInterval:7000})
  useEffect(() => {
    if (XTernConSystemListError) console.error({XTernConSystemListError})
    else if (XTernConSystemList) {
    }
  },[XTernConSystemListError, XTernConSystemList])

  //Auth Check Handler
  useEffect(() => {
    if (FBisEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms) || (NoConSystemList && !(!farmData||!farmData.site||!farmData.refToken))) return <LoadingSplash/>
  else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[Layout.colVCenter, {minHeight:'max-content', minWidth:'-webkit-fill-available'}]}>
          {ConSystemList&&!isEmpty(ConSystemList)?
            Object.values(ConSystemList).map((System, index) => {
              return (
                <System_Module System={System} key={"Module-" + System.SystemIndex}/>
              )
            })
          :(
            <View style={[Common.border, Layout.midWidth, {minHeight:80}]} key={"NoData"}>
              {farmData&&(!farmData.site||!farmData.refToken)?(
                <Text>{'This farm contains no consys connection settings'}</Text>
              ):(
                <Text>{'No Connection or data could be found'}</Text>
              )}
            </View>
          )}
        </View>
        <View style={[Layout.colVCenter, {minHeight:'max-content', minWidth:'-webkit-fill-available'}]}>
          {XTernConSystemList&&!isEmpty(XTernConSystemList)?
            Object.values(XTernConSystemList).map((System, MainIndex) => {
              if (System && !isEmpty(System)) {
                return Object.values(System).map((Module, index) => {
                  return (
                    <System_Module System={Module} key={"Module-XTern" + Module.SystemIndex} IsDataLink={Object.keys(DataLinks)[MainIndex]}/>
                  )
                })
              }
              return []
            })
          :(<></>)}
        </View>
      </View>
    )
  }
}

export default Systemboard