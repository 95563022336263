import React, { useEffect, createContext, useContext } from "react"
import { View, Animated, Easing } from 'react-native'
import LoadingAssets from '../../Assets/Loading'
import { useTheme } from '../../Theme'

import {
  AspectRatioBox,
  AspectRatioBoxBody,
} from 'baseui/aspect-ratio-box'

const LoadingBar = [
  {image: LoadingAssets.Dot0, spinOffset:0, jumpOffset:0},
  {image: LoadingAssets.Dot1, spinOffset:40, jumpOffset:0},
  {image: LoadingAssets.Dot2, spinOffset:80, jumpOffset:0},
  {image: LoadingAssets.Dot3, spinOffset:120, jumpOffset:0},
  {image: LoadingAssets.Dot4, spinOffset:160, jumpOffset:0},
  {image: LoadingAssets.Dot5, spinOffset:200, jumpOffset:0},
  {image: LoadingAssets.Dot6, spinOffset:240, jumpOffset:0},
  {image: LoadingAssets.Dot7, spinOffset:280, jumpOffset:0},
  {image: LoadingAssets.Dot8, spinOffset:320, jumpOffset:0},
  {image: LoadingAssets.Dot9, spinOffset:360, jumpOffset:0}
]

const DataContext = createContext<{spinValue:Animated.Value, startValue: Animated.Value}>({spinValue:new Animated.Value(0), startValue:new Animated.Value(1)})
const DataProvider = ({ children }) => {
  return (
    <DataContext.Provider 
      value={
        {spinValue:new Animated.Value(0), startValue:new Animated.Value(1)}
      }>
      {children}
    </DataContext.Provider>
  )
}

const LoadingSplash = ({ }) => {
  const { Layout, Common } = useTheme()
  const AnimatedData = useContext(DataContext)

  useEffect(() => {
    Animated.loop(
      Animated.parallel([
        Animated.timing(AnimatedData.spinValue, {
          toValue: 1,
          duration: 4000,
          easing: Easing.linear,
          useNativeDriver: false
        }),
        Animated.sequence([
          Animated.timing(AnimatedData.startValue, { 
            toValue: 0.5, duration: 1000, useNativeDriver: true 
          }),
          Animated.timing(AnimatedData.startValue, { 
            toValue: 1.5, duration: 1000, useNativeDriver: true 
          }),
          Animated.timing(AnimatedData.startValue, { 
            toValue: 0.5, duration: 1000, useNativeDriver: true 
          }),
          Animated.timing(AnimatedData.startValue, { 
            toValue: 1, duration: 1000, useNativeDriver: true 
          })
        ])
      ])
    ).start()
  }, [AnimatedData])

  return (
    <DataProvider>
      <View style={[Layout.fillcolumn, Layout.fullSize, Layout.center, Common.backgroundPrimary, {minWidth:300}]}>
        <View style={[Layout.fill, Layout.fullSize, {maxWidth:250, maxHeight:40}]}>
          <AspectRatioBox aspectRatio={209 / 31} width={'100%'}>
            <AspectRatioBoxBody
              as="img"
              src={LoadingAssets.Loading+'?width=209'}
            />
          </AspectRatioBox>
        </View>
        <View style={[Layout.row, {height:25, width:250}]}>
          {LoadingBar.map((e,i)=> {
            return (
              <View key={"dot" + i} style={[{height:25, width:25, transform: [{rotate:e.spinOffset+"deg"}]}]}>
                <Animated.Image
                  source={e.image} resizeMode={'center'}
                  style={[Layout.fullSize, { maxWidth:25,maxHeight:25,
                    transform: [{ scale: AnimatedData.startValue, }, {
                      rotate: AnimatedData.spinValue.interpolate({
                        inputRange: [0, 1],
                        outputRange: ['0deg', '360deg']
                      })
                    }],
                  }]}
                />
              </View>
            )
          })}
        </View>
      </View>
    </DataProvider>
  )
}

export default LoadingSplash