import { actionTypes } from 'redux-firestore'
import { FirebaseReducer } from 'react-redux-firebase'

//FireStore
/*const fbConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "agrisys-app.firebaseapp.com",
  projectId: "agrisys-app",
  storageBucket: "agrisys-app.appspot.com",
  messagingSenderId: "861381425238",
  appId: "1:861381425238:web:25ab2c412e834c150f23f7",
  measurementId: "G-ZWFEFTMTN6",
  databaseURL: "https://agrisys-app.firebaseapp.com"
}*/
//RealtimeDB
export const fbConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: "agrisys-app.firebaseapp.com",
  projectId: "agrisys-app",
  storageBucket: "agrisys-app.appspot.com",
  messagingSenderId: "861381425238",
  appId: "1:861381425238:web:25ab2c412e834c150f23f7",
  measurementId: "G-ZWFEFTMTN6",
  databaseURL: "https://agrisys-app-default-rtdb.europe-west1.firebasedatabase.app",
  onAuthStateChanged: (authData, firebase, dispatch) => {
    // Clear redux-firestore state if auth does not exist (i.e logout)
    if (!authData) {
      debugger
      dispatch({ type: actionTypes.CLEAR_DATA })
    }
  }
}


export const profilePopulates = []//[{ child: 'email', root: 'EmailList' }, ]
export const rrfConfig = {
  enableRedirectHandling: false,
  //useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
  userProfile: 'users',
  autoPopulateProfile:true,
  profileParamsToPopulate: profilePopulates,
}

import { UserData, FarmData, SystemData, DataConsData, GraphData, ReportData, SectionData, PenData, CommentData, WeighingData, SysConfig, Periodes, SystemSet, DataLinks, LinkList, LinkListData, DataLinkList } from '../../Config/Types'

type FB_Profile = UserData

interface WorkerFarms {
  [key:string]: boolean
}
interface Graphs {
  [key:string]: GraphData
}
interface Reports {
  [key:string]: ReportData
}
interface reportData {
  [key:string]:{
    weighings:any,
    closed_weighings:any,
    comments:any,
    closed_comments:any
  }
}

//Memory instance... not DB
interface FB_Schema {
  users: UserData,
  farms: FarmData,
  WorkerFarms: WorkerFarms,
  
  agri_graphs: Graphs,
  local_graphs: Graphs|null,
  system: SystemData,
  DataCons: DataConsData|SysConfig,

  DataLinks: DataLinks,
  LinkList: LinkList,
  LinkListData: LinkListData,
  DataLinkList: DataLinkList,

  WorkersList: any, //List of places allowed for worker---profil
  
  sections: SectionData,
  pens: PenData,

  weighings:WeighingData,
  display_weighings:WeighingData,
  comments:CommentData,
  display_comments:CommentData,
  display_periodes:Periodes,

  reports: Reports,
  reportData: reportData,
}
export interface Firebase_Reducer extends FirebaseReducer.Reducer<FB_Profile, FB_Schema> {}