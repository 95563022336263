import { useState, useEffect } from 'react'
import {
  SafeAreaView, Text
} from 'react-native';
import { useAppSelector, useAppDispatch } from '../Store'

import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { navigate, navigationRef, useisAppView } from '../Navigators/Root'

import { IndexStartupContainer } from '../Containers'

import { setCookieState } from '../Store/StateHandlers/Init-slice'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
} from 'baseui/modal';

const config = {
  screens: {
    Main: {
      screens: {
        Home:''
      }
    },
    Auth: {
      screens: {
        Home:'login',
        Registration:'sign_up'
      }
    },
    Admin: {
      screens: {
        Home:'admin'
      }
    },
  }
  /* configuration for matching screens with paths */
}


const linking = {
  prefixes: [
    'agrisys-app://',
    'https://agrisys-app.web.app',
    'http://localhost:1234/'
    /* your linking prefixes */
  ],
  config
}

const Stack = createNativeStackNavigator()

let MainNavigator:any = null
let AuthNavigator:any = null
let AdminNavigator:any = null
let UserNavigator:any = null

const ApplicationNavigator = () => {
  const isAppView = useisAppView()
  const [isApplicationLoaded, setIsApplicationLoaded] = useState(false)
  const applicationIsLoading = useAppSelector(({startup}) => startup.status)

  const SystemStatus = useAppSelector(({startup}) => startup)
  const dispatch = useAppDispatch()

  const [CookieModal, setCookieModal] = useState((SystemStatus&&SystemStatus.firstRun)||!SystemStatus.CookieLevel?true:false)

  useEffect(() => {
    if (AuthNavigator === null && applicationIsLoading === "idle") {
      AuthNavigator = require('../Navigators/Auth').default
    }
    if (AdminNavigator === null && applicationIsLoading === "idle") {
      AdminNavigator = require('../Navigators/Admin').default
    }
    if (UserNavigator === null && applicationIsLoading === "idle") {
      UserNavigator = require('../Navigators/User').default
    }

    if (MainNavigator && applicationIsLoading === "idle") {
      setIsApplicationLoaded(true)
      navigate('Main', {})
    }
  }, [applicationIsLoading, MainNavigator])

  // on destroy needed to be able to reset when app close in background (Android)
  useEffect(() => {
    setIsApplicationLoaded(false)
    MainNavigator = require('../Navigators/Main').default
    //AuthNavigator = null
    //AdminNavigator = null
    //UserNavigator = null
  },[],)

  return(
    <SafeAreaView style={[{flex:1, minHeight:'max-content'}, isAppView?{height:'100%'}:{height:'100vh'}]}>
      <NavigationContainer /*linking={linking}*/ ref={navigationRef}>
        <Stack.Navigator initialRouteName={"Startup"}
          screenOptions={{
            headerShown:false,
            animation:'none',
            contentStyle:{flex:1}
          }}>
          <Stack.Screen name="Startup" component={IndexStartupContainer} />
          {MainNavigator && (
            <Stack.Screen
              name="Main"
              component={MainNavigator}
              options={{

              }}
            />
          )}
          <Stack.Group navigationKey={isApplicationLoaded?'Auth':undefined}
            screenOptions={({ navigation }) => ({

            })}
          >
            {isApplicationLoaded && AuthNavigator && (
              <Stack.Screen
                name="Auth"
                component={AuthNavigator}
                options={{
                  
                }}
              />
            )}
            <Stack.Group //navigationKey={auth?'signed':'unknown'}
              screenOptions={({ navigation }) => ({
                
              })}
            >
              {isApplicationLoaded && UserNavigator && (
                <Stack.Screen
                  name="User"
                  component={UserNavigator}
                  options={{
                    
                  }}
                />
              )}
              {isApplicationLoaded && AdminNavigator && (
                <Stack.Screen
                  name="Admin"
                  component={AdminNavigator}
                  options={{
                    
                  }}
                />
              )}
            </Stack.Group>
          </Stack.Group>
        </Stack.Navigator>
      </NavigationContainer>
      <Modal onClose={() => {
        dispatch(setCookieState({data:0}))
        setCookieModal(false)
      }} isOpen={CookieModal} unstable_ModalBackdropScroll={true}>
        <FocusOnce>
          <ModalHeader>{"Cookies"}</ModalHeader>
        </FocusOnce>
        <ModalBody>
          <Text>{`We use cookies for the following purposes:\n`}</Text>
          <Text>{`1* To support the website's functionality, for example by remembering your settings regarding cookies, preferred languages, currencies, etc.\n`}</Text>
          <Text>{`2* To perform statistical analysis of your use of the website, for example by measuring what type of content you are looking at and clicking on.\n`}</Text>
          <Text>{`\n`}</Text>
          <Text>{`By pressing “Accept all” you accept our use of cookies.\n`}</Text>
          <Text>{`Else a minimun of cookies will be used required by the service to function, for example to manage login, and data fetching from the consys platfrom.`}</Text>
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={() => {
            dispatch(setCookieState({data:0}))
            setCookieModal(false)
          }}>
            {'Accept necessary'}
          </ModalButton>
          <ModalButton style={{width:190}} autoFocus onClick={() => {
            dispatch(setCookieState({data:1}))
            setCookieModal(false)
          }}>
            {'Accept All'}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </SafeAreaView>
  )
}

export default ApplicationNavigator