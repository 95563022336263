export default {
  SplashText: 'AgrisysApp, protein automations teknologi.',
  AgrisysFront:{
    Welcome: 'Velkommen til Agrisys App',
    Menu1: 'Agrisys Cloud',
    Menu2: 'Forbin til enhed',
    Menu3: 'Mobile version'
  },

  AgrisysConnect: {
    NavTitle: 'Bruger Login',
    label1: 'E-mail:',
    label2: 'Kodeord:',
    submit_btn: 'Login',

    reset: 'Nulstill kodeord',
    create: 'Opret ny bruger'
  },

  AgrisysUser: {
    reVerify:'Gensend vertification',
    Refresh:'Geninlæs',

    label1: 'E-mail:',
    label2: 'Kodeord:',
    submit_btn: 'Opret bruger',

    Logout: 'Log ud'
  },

  AgrisysAdmin: {
    NavTitle: 'Admin Panel',
    Welcome: 'Velkommen: {{name}}',
    Userboard: 'Bruger Panel',
    Systems: 'Systemer',
    Tools: 'Værktøj',
    Logout: 'Log ud'
  },

  AdminSystems:{
    Name:"Navn",
    Manager:"Manager",
    Email:"E-mail",

    Selected:"Gårds-Tag",
    Consys:"Consys",
    Panel:"Gårds Panel"
  },
  
  AgrisysMenu:{
    NavTitle: 'Bruger Panel',
    Welcome: 'Velkommen: {{name}}',
    SystemBoard: 'Systems panel',
    DashBoard: 'Dashboard',
    Pens: 'Bokse',
    Batchs: 'Hold',
    Reports: 'Rapporter',
    Growth: 'Vækstkurver',
    Operations: 'Operationer',
    Autofunctions: 'Data forbindelser',
    FeedingFunctions: 'Fodring',
    Pigs: 'Grise',
    Settings: 'Indstillinger',
    Presentation:'Consys - Demo',
    Logout: 'Log ud'
  },

  SystemBoard: {
    StartTxt: `Den nuværende aktion er et nyt startmærke.\nVil du nulstille autopigs data til en ny gennemsnitsvægt ??\nHvis ikke, kan fremtidige data blive forvrænget, da nulstillingen er forkert.`,
    ResetTxt: `Den aktuelle handling vil nulstille denne autopigs data,\nalle data vil stadig være tilgængelige.\nFremtidige data kan dog blive forvrænget, hvis nulstillingen er forkert.\n Er du sikker på, at du vil foretage denne handling??`,
    
    ResetLabel: 'Nulstil gennemsnitsvægt til ',
    fetchLabel: 'Hent vægt',
    
    ResetErrorTxt: "Kunne ikke indstille",
    ResetSusTxt: "Vægt indstillet"
  },

  DashBoard:{
    Title: 'Gårds beskrivelse',
    Prop1: 'Gårdens navn',
    Prop2: 'Manager',
    Prop3: 'E-Mail',
    
    CapacityTxt: 'Gårdens kapacitet {{current}} ud af {{full}}',
    PerformanceTxt: 'Gårdens præstation',

    DistriTitle: 'Fordeling:',
    BatchesTitle: 'Hold:',
    BatchLabel: '{{name}} Start doto: {{Sdate}} Slut prognose: {{Edate}},\n{{pigs}} grise, vægt: {{weight}}',

    FarmInfo_btn: 'Gårdens Informationer',
    UserInfo_btn: 'Bruger Informationer',
    FarmSettings_btn: 'Gårdens Indstillinger',
    ClearData: 'Ryd lokale datalager'
  },

  DataEditor:{
    Title: 'Editor',
    Options1_1: "Bokse",
    Options2_1: "Tilføj",
    Options2_2: "Slet",
    New_Sec: "Ny Sektion",
    Multiple:'Flere: ',

    Prop1:'Laveste nummer',
    Prop2:'Højeste nummer',
    Prop3:'Pre boks navn',
    Prop4:'Post boks navn',
    Prop5:'Sektions navn',
    Prop6:'Boks navn',
    ValDirection: 'Højeste nummer feltets værdi, skal være højere end det laveste nummer',
    CannotNon: 'Kan ikke fjerne ikke-eksisterende boks',
    Pen_Size: 'Boks Størelse',
    GrowthCurve:'Vækstkurve'
  },

  FarmInfo:{
    Title: 'Gårdens Informationer',
    Prop1:"Gårdens navn",
    Prop2:"Adresse",
    Prop3:"Land",
    Prop4:"Cvr",
    Prop5:"Manager",
    Prop6:"E-mail",
    Prop7:"Boks design størrelse",
    Copy:"Kopier Gårdens-id til udklipsholder",
    Create:"Opret Gården",
    Save:"Gem Infromationer",
  },

  UserInfo:{
    Title: 'Bruger Informationer',
    Prop1:"Fornavn",
    Prop2:"Efternavn",
    Prop3:"E-Mail",
    Prop4:"Telefon",
    Prop5:"Adresse",
    Prop6:"Land",
    Copy:"Kopier Bruger-id til udklipsholder",
    Save:"Gem Infromationer",
  },

  FarmSettings:{
    Title: 'Gårdens Indstillinger',
    Prop1:"Boks design størrelse",
    PensSettingsTitle: "Boks indstillinger",
    PenPrefix: "Box-præfiks:",
    Pendefault: "Box_",
    TreatmentsTitle: "Behandlinger",
    TreatmentProp1:"Kode",
    TreatmentProp2:"Navn",
    TreatmentProp3:"Død",
    TreatmentProp4:"Andvend",
    WorkerTitle: "Arbejdere / Brugere liste",
    WorkerProp1:'Brugere Kode',
    WorkerProp2:'E-mail',
    WorkerProp3:'Indstillinger',
    UserAllowTitle:"Brugertilladelses liste",
    SilonKompTitle:"Siloer og Komponenter",
    NedapTitle:"Nedap Forbindelse",
    NedapAdd:"Tilføj installation",
  },

  Subscription:{
    Plan:"Abonnementsplan",
    Staff:'Gårdpersonale',
    StaffDesc:'Adgang til at tilgå som personale, på en eksisterende gård',
    Owner:'Gårdejer',
    OwnerDesc:'Adgang til at styre egen gård',
    Manager:'Gårdsforvalter',
    ManagerDesc:'Adgang til at administrere egen gård og tildele personale',
    Contact:'Bliv kontaktet',
    ContactDesc:'Vi vil kontakte dig, og fortælle mere om vores tilbud',

    Free:'Gratis',
    PriceManager:'9999.99€',

    submit_btn: 'Accepter',

    //Errors
    NoType:'Vælg venligst en abonnementstype.',
    NotAvaible: 'Denne mulighed er ikke tilgængelig endnu.',
  },

  Pens:{
    Options1:'Framvis som: ',
    Options1_1:'Sektioner',
    Options1_2:'Hold',
    ViewDate:'Fremvist Dag',

    Options2:'Fremvis:',
    Options2_1:'Vægt',
    Options2_7:'Vækst',
    Options2_2:'Dyre antal',
    Options2_3:'Alder i dage',
    Options2_4:'Vandforbrug',
    Options2_5:'Foderforbrug',
    Options2_6:'Aktivitet',
  },

  Pen_Display:{
    Title: 'Boks panel',
    Prop1: 'Antal grise',
    Prop2: 'Hold',
    Prop3: 'Holdets alder i dage',
    Prop3_2: 'Boks opstarts dag',
    Prop4: 'Gennemsnitsvægt',
    Prop5: 'Gennemsnitlig vækst pr gris om dagen',
    Prop6: 'Vækstkurve',
    Prop7: 'Alarm Min-Max',
    Prop7Data: '{{min}}% - {{max}}%',
    Size: 'Boks størrelse',
    
    Prop8: 'Sidste fodertid',
    Prop8Time: '{{date, DD-MM-YYYY HH:mm:ss}}',
    Prop9: 'Portion i kg.',

    Updateview_btn:'Opdater visning',

    SiloLog_Title:'Silo Log',
    DailyFeedLog_Title:'Daglig foderlog',
    ControlWeights_Title: 'Kontrol vejninger',
    WeightTime: '{{date, DD-MM-YYYY HH:mm:ss}}',
    WeightTimeAction: 'Operation: {{date, DD-MM-YYYY}}',
    WeightData: '{{weight}} kg : {{count}} gris',

    OperationLog_Title: 'Operationslog',
    OperationTime: '{{date, DD-MM-YYYY HH:mm:ss}}',
    NoteData: 'Boks note: \n{{note}}',
    OperationNote: '{{note}}',
    OperationData: '{{count}} * {{action}} af {{weight}} kg \n{{note}}',
    OperationDataCode: '#{{code}} - {{codename}}\n{{count}} * {{action}} af {{weight}} kg\n{{note}}',
  },

  Autopigs:{
    Title:'Autopigs',
    New:'Ny AutoPig',
    AutopigId:'Autopig SysId',
    SelectPens:'Tilgængelig - Boks',
    SelectSections:'Tilgængelig - Sektioner',
    
    CorTitle:'Gangvægte',
    CorridorId:'Gangvægte Id\'er (Adskilt af komma)',
    ConIdError:'Consys gendkender ikke {{ConsysId}} som et valid Id.',
    AutoPigName: 'Autopig navn',

    FeedTitle:'Foderforbindelser',
    NewFeedCon:'Ny Foderforbindelse',
    FeedId:'Foder SysId',

    DataTitle:'Gårdens datadeling',
    NewDataCon:'Ny datadeling',
    FarmId:'Gårds id',
    From:'Data fra',
    To:'Data til',

    Edit:'Redigere indstillinger',
    Prop1:'Autopig navn',
    Prop2:'Consys-Id',
    Prop3:'Vægt',
    Prop4:'Operation',
    Prop5:'Temp',
    Prop6:'Vand',
    Prop7:'Foder',
    Prop8:'Tewe-Feed \n(Ventil Id)',

    NewConnection:'Ny forbindelse',
    ConnectionName:'Forbindelses navn',

    listcol1:'ID',
    listcol2:'Navn',
    listcol3:'Type',
    listcol4:'Delt/Lokal',
    listcol5:'Handlinger'
  },

  Autopigs_Display:{
    Title: "Autopig Panel",
    DaysLabel:'Valgte dage',
    NewestLabel: "Nyeste vægt",
  },

  Reports: {
    New:'Ny rapport',
    Edit:'Redigere rapporter',

    Combined:'Vis i kombineret billede',
    listcol1: 'Navn',
    listcol2: 'Start-Dato',
    listcol3: 'Slut-Dato',
    listcol4: 'Arkiveret',
    listcol5: '- Handlinger -',
  },

  Reports_Editor: {
    Title: 'Rapport Editor',

    Deletelabel: 'Slet rapport',
    Copylabel: 'Kopiér rapport',
    ReportName: 'Raport navn',
    GrowthCurve:'Vækstkurve:',
    SelectPens:'Tilgængelig - Bokse',
    SelectAutos:'Tilgængelig - Autopigs',
    SelectReps:'Tilgængelig - Rapporter',
  },

  Report_Display: {
    Title: "Repport panel",

    Added: 'Tilføjede grise',
    Deaths: 'Dødsfald',
    Sold: 'Solgte grise',

    NoWeight: 'Ingen kontrolvejningsdata er fundet.',
    NoFeeding: 'Der er ikke fundet fodringsdata.',
    Total: 'I alt',

    StartD:'Start dato:',
  },

  Graphs:{
    New: 'Ny kurve',

    CalcLabel: 'Beregn ud fra:',
    Name: 'Kurvens navn',
    Day: 'Dage:',
    Weight: 'Vægt:',
    Growth:'Vækst',
    NewPoint: 'Nyt punkt',
    EValue: 'Værdi fejl',
    NameError: 'Kurveskabelon skal have et navn.',
    IncrementError: 'Kurveskabelon skal være inkrementel: fejl på dagen: {{I1}} - {{I2}}'
  },

  Operations:{
    Weight: 'Manuel vejning',
    AddPen: 'Tilføj Boks',
    RemovePen: 'Fjern Boks',
    EditPens: 'Tilføj eller fjern boks',

    Section:'Sektion:',
    Pen:'Boks:',
    Data:'Boks data',
    Pigs:'Grise:',

    Note: 'Notat',
    AddPig: 'Tilføj gris',
    MovePig: 'Flyt gris',
    Treatment: 'Behandling',
    SoldPig: 'Solgt gris',
    ClosePen: 'Afslut Boksen',

    NoteTitle: 'Boks notat:',
    OperationTitle: 'Operations kommentar:',
    Quantity: "Antal:",
    AvgWeight: "Gennemsnits vægt:",
    CorridorBtn: "Gangvægt",
    CorridorRes: "Gangvægts resultat",
    Internal: 'Intern notat',
    PeriodeTitle: 'Visnings periode:',
    day:'dag',

    ModalConf:'Bekræft venligst handling',
    CloseDate:'Afslutnings dato',
    ClearWarn:'Den aktuelle handling vil rydde denne boks data til historik,\nalle data vil stadig være tilgængelige i rapport panelet.\nEr du sikker på, at du vil gøre dette ??',
    RepCreate: `Opret rapport fra pen`,
  },

  CommentModule:{
    NotePlaceholder: 'Notats indhold..',
  },

  GraphViewer:{
    X_DateFormat_Time: '{{date, DD-MM-YYYY HH:mm}}',
    X_DateFormat: '{{date, DD-MM-YYYY}}',

    CountFormat:'Antal grise',
    WeightFormat:'Vægt Kg.',
    CountToWeight:'Antal: {{count}}\nVægt: {{weight}}kg',

    InfoTitle:'CurveView Detaljeret  info',
    InfoName:'Data navn',
    InfoValue:'Værdi: y-aksen',
    InfoAdd:'Ekstra',
    ShowTip:'Vis Tooltip',

    PredictionTxt: 'Prediction',
    NoteLabel:'Note/Operation',
    
    Weight:'Vægt',
    ByTag:'VedMærke',
    Growth:'Vækst',
    Scale:'Scale',
    Feed:'Foder',
    Activity:'Aktivitet',
    Water:'Vand',
    Temp:'Temp'
  },

  Weighings:{
    Title: 'Vejning',
    EditWeights: 'Redigere vejning:',
    DeleteWeights: 'Slet vejning:',
    WeightData: "Vejnings data:",
    Quantity: "Antal dyr",
    Weight: "Totalvægt",
    NoReply:'Consys svarede ikke med en vægt',
    CorridorScale: "Gangvægte",
    Fetch: "Hent vægt",
  },

  FeedList:{
    Col1:'Ventil nr.',
    Col2:'Silo',
    Col3:'Mængde Kg',
    Col4:'Tidspunkt',
  },

  OperationList:{
    Col1:'Tidspunkt',
    Col2:'Ventil nr.',
    Col3:'Hovedaktivitet',
    Col4:'Underaktivitet',
    Col5:'Data',
    Action1:'Redigere'
  },

  WeightList:{
    Col1_1:'Boks',
    Col1_2:'Dage i alt',
    Col1_3:'Indgående vægt',
    Col1_4:'Udgående vægt',
    Col1_5:'Vækst pr dag',

    Col2_1:'Dato',
    Col2_2:'Antal grise',
    Col2_3:'Vægt Kg',
    Col2_4:'Kg pr. gris',
    Col2_5:'Vækst pr. gris, pr. kontrolvægt',
    Col2_6:'Gennemsnitlig vækst pr. gris, om dag',
  },

  DataTypes: {
    Degrees:"°C",
    W:"Kg.",
    W_T:"Kilo",
    W_small:"g.",
    W_small_T:"gram",
    F:"L.",
    F_T:"Liter",
    F_small:"ml.",
    F_small_T:'Milliliter',
  },

  ActionButtons:{
    Add:'Tilføj',
    Remove:'Fjern',
    Back:'Tilbage',
    Save:'Gem',
    SaveAll:'Gem alt',
    Done:'Bekræft',
    Cancel:'Annuller',
    Logout: 'Log ud',
    Submit_btn: 'Bekræft',
    OK: 'OK'
  },

  Errors: {
    PropMissing:'{{Prop}} er nødvendig',
    PropInvalid:'{{Prop}} indeholder ugyldige tegn',

    PropExists:'{{Prop}} eksisterer allerede',
    PropNon:'{{Prop}} eksisterer ikke',

    PropNan:'{{Prop}} kan ikke være eller begynde med et tal',
    PropNum:'{{Prop}} skal være et gyldigt nummer',

    PassMissing:'Angiv venligst en adgangskode',
    EmailMissing:'Angiv venligst en e-mail',
  },

  Development: {
    NotDone:'Element ikke færdigt',
  }
}
