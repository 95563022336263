import { StyleSheet } from 'react-native'

export default function ({ Colors, Gutters, Layout }:{Colors?:any, Gutters?:any, Layout?:any}) {
  const base = {
    ...Layout.center,
    ...Gutters.largeHPadding,
    height: 40,
    backgroundColor: Colors.primary,
    boxShadow: "0px 0px 4px 2px rgba(0,0,0,.2)"
  }
  const rounded = {
    ...base,
    borderRadius: 20,
  }
  const outline = {
    ...base,
    backgroundColor: Colors.transparent,
    borderWidth: 2,
    borderColor: Colors.primary,
  }
  const filled = {
    ...base,
    backgroundColor: Colors.primary,
    borderWidth: 2,
    borderColor: Colors.primary,
  }

  return StyleSheet.create({
    base,
    rounded,
    outline,
    filled,
    outlineRounded: {
      ...rounded,
      ...outline,
    },
    filledRounded:{
      ...rounded,
      ...filled,
    }
  })
}
