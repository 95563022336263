// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery, BaseQueryEnhancer } from '@reduxjs/toolkit/query/react'
import { RootState, useAppSelector } from '../../Store'
import { ConsysRefs, DataConsData, isEmpty, TimeSpan } from '../../Config/Types'
import { Config_Pri } from './Config'
import { ConsysApi, ConsysArgs, GetAutos, GetTimeSets, MultiLoop} from './Consys'

export interface WaterInfo {
  dataid: number,
  timestamp: number,
  valve?: number,
  amount?: number,
  sumamount?: number,
  watercounter?: number,
  count?: number
}
export type WaterList = Record<string, Record<string, {amount:number, count:number, timestamp:number, objArr:WaterInfo[]}>>
export interface WaterProp extends ConsysArgs {
  FarmKey:string|undefined,
  FarmKeys?:string|string[],
  systemids?:string|string[]|{id:string,XTern:string}|{id:string,XTern:string}[],
  Xsystemids?:string[][]|{id:string,XTern:string}[][],
  valve:string|number,
  ViewPeriods:TimeSpan[]|TimeSpan|undefined,
  interval?:number,
  timerange?:'last30d'|'currentmonth'|'lastmonth'
}

const _GetError = (dataText:string):any => {
  return {error: {
    status: 500,
    statusText: 'Token retrieve error',
    data: dataText,
  }}
}

const DoqueryFn = async (args:WaterProp, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (args.FarmKeys && typeof args.FarmKeys==="object" && !isEmpty(args.FarmKeys)) {
    var res = await MultiLoop(DoqueryFn, args, queryApi, extraOptions, fetchWithBQ)
    if (res && (res.data || !isEmpty(res.error))) return res
  }
  else {
    //const state = (queryApi.getState() as RootState)
    var Errors = null, Waterres:(WaterList | undefined)[] = []
    let valveId:string|null = args.valve!==undefined&&args.valve!==""?args.valve+'':null
    var getAutos = GetAutos(args, queryApi)
    
    //debugger
    Waterres = await Promise.all(getAutos.map(async ([key, AutoPig], index) => {
      if (AutoPig && AutoPig.water && AutoPig.water !== "") {
        var Waters = AutoPig.water.split(',').reduce((a:object, v:string) => ({ ...a, [v]: (AutoPig.water.includes('all,')&&v!=='all')?false:true}), {})
        if ((!valveId || valveId==="all") || (Waters['all']?!Waters[valveId]:Waters[valveId])) {
          //debugger
          var Times = GetTimeSets(args, queryApi, index)
          let Result = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.WATERCOUNT, key, /*valveId?valveId:*/'all', {starttime:Times.end, stoptime:Times.start}), params:args})
          
          if (Result) {
            if (Result.error || (!Result.data || !Result.data.consumptiondata)) {
              Errors = _GetError(Result.error?Result.error.error:"")
            } else if (Result.data && Result.data.consumptiondata) {
              var ResWaters = Result.data.consumptiondata as WaterInfo[]

              var dataMap:WaterList = {}
              /*if (!isEmpty(ResScales) && ResScales[0].sumamount) {
                return dataMap[name] = ResScales
              }
              else {*/
                ResWaters.forEach(dataPoint => {
                  if (dataPoint) {
                    const Time = new Date(dataPoint.timestamp * 1000)
                    const TimeName:string = (Time.getMonth()+1) + "_"+ Time.getDate()
                    var _Water = dataPoint.sumamount?dataPoint.sumamount:dataPoint.amount?dataPoint.amount:(dataPoint.watercounter||0) 
                    //_Water = (_Water / 1000)
                    _Water = (_Water / (AutoPig.flow?parseInt(AutoPig.flow):22000))

                    if (_Water !== 0) {
                      var name = dataPoint.valve?dataPoint.valve:AutoPig.name?AutoPig.name:"SysId_" + key
                      if (!dataMap[name]) dataMap[name] = {}
                      if (!dataMap[name][TimeName]) dataMap[name][TimeName] = { amount: _Water, count: 1, timestamp: (Time.setHours(23,59,59)), objArr:[] }
                      else {
                        //Is not average, is full consumption
                        dataMap[name][TimeName].amount += _Water
                        dataMap[name][TimeName].count += 1
                      }
                      dataMap[name][TimeName].objArr.push(dataPoint)
                    }
                  }
                })
              //}
              return dataMap
            }
          } else Errors = _GetError("No Data Return")
        }
      }
    }))

    if (!Errors) {
      return {data: Waterres.filter(e => e)}
    } else {
      return Errors
    }
  }
  return _GetError("No Site")
}

// Define a service using a base URL and expected endpoints
const ConsysApi_WithTag = ConsysApi.enhanceEndpoints({
  addTagTypes: ['Water']
})
export const WaterSlice = ConsysApi_WithTag.injectEndpoints({
  overrideExisting:false,
  endpoints: (builder) => ({
    getWaterList: builder.query<WaterList[], WaterProp|void>({
      async queryFn(args:WaterProp, queryApi, extraOptions, fetchWithBQ) {
        var Data = await DoqueryFn(args, queryApi, extraOptions, fetchWithBQ)
        return Data
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Water', Key})
        })
      },
    }),
    getWaters: builder.query<WaterList[], WaterProp|void>({
      async queryFn(args:WaterProp, queryApi, extraOptions, fetchWithBQ) {
        var Data:any[] = []

        if (!isEmpty(args.systemids)) {
          var Intern = await DoqueryFn({FarmKey:undefined, systemids:args.systemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          Data = Data.concat(Intern.data)
        }
        else if (!isEmpty(args.Xsystemids)) {
          var XTern = {}
          if (typeof args.FarmKeys==="object") XTern = await DoqueryFn({FarmKey:undefined, FarmKeys:args.FarmKeys, Xsystemids:args.Xsystemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          else XTern = await DoqueryFn({FarmKey:args.FarmKeys, Xsystemids:args.Xsystemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          if (XTern.data) Data = Data.concat(XTern.data)
        }

        //TODO: ERROR
        return {data:Data}
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Water', Key})
        })
      },
    }),
  }),
})
export const GetWaterList = WaterSlice.endpoints.getWaterList.useQuery
export const GetWaters = WaterSlice.endpoints.getWaters.useQuery