import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  Image
} from 'react-native'
import { Button } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded, useFirebaseConnect } from 'react-redux-firebase'

import { setStates } from '../../Store/StateHandlers/Active-slice'
import ShowIcon from 'baseui/icon/show'
import { UnixDayTime, isEmpty, ReportData } from '../../Config/Types'

import {
  StatefulDataTable,
  DatetimeColumn,
  BooleanColumn,
  StringColumn,
  CustomColumn,
  SORT_DIRECTIONS
} from "baseui/data-table"

//#region Front List Selector
const TimeTransForm = (Time:number, days:number|undefined):number => {
  //TODO: mabye demo farm check... insted of 0 && < 9999 transform
  let _Time:number = Time?Time:0
  if (_Time === 0) {
    _Time = Date.now()
  }
  if (_Time > 0 && _Time < 9999) {
    _Time = new Date().setDate(new Date().getDate() - Time)
  }
  //if (days) _Time = _Time + (UnixDayTime * days)
  return _Time
}

const Reports = () => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters, Images } = useTheme()
  const dispatch = useAppDispatch()

  //const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  /* Get Farm report */
  useFirebaseConnect(() => {
    let ReportsCall: any[] = []
    if (activefarm && activefarm !== "") {
      const path = `farms_data/${activefarm}/`
      ReportsCall.push({path:path + 'reports', queryParams: ['orderByChild=time'], storeAs: 'reports'})
      //ReportsCall.push({path:path + 'AutoPigs', queryParams: ['orderByKey'], storeAs: 'AutoPigs'})
      ReportsCall.push({path:path + 'DataCons', queryParams: ['orderByKey'], storeAs: 'DataCons'})
    }
    return ReportsCall
  })

  const Reports = useAppSelector<Record<string,ReportData>|null>(({firebase: { data }}) => data.reports?Object.fromEntries(Object.entries(data.reports).filter(([key,val])=> (key !=="update_time"))):null)
  const ReportRows = useMemo<{id:string, data:ReportData}[]>(() => Reports&&!isEmpty(Reports)?Object.entries(Reports).filter(([key,val]) => val.name).map(([key,val], index) => ({id:key, data:{...val, key:key}})).flat()
  :[], [Reports])
  const [SortMap, setSortMap] = useState<Record<string, {description:string, exclude:boolean, selection:Set<boolean>}>>({[t('Reports.listcol4')]:{selection: new Set([true]), exclude: true, description: "true"}})
  
  const batchActions = [{
    label: t('Reports.Combined'),
    onClick: ({clearSelection, selection}) => {
      dispatch(setStates({state:"report", data:{id:'Combined-View', datas:selection.map(e => e.id)}}))
      navigate('User', {screen: 'Report_Display'})
      clearSelection()
    },
    renderIcon: ({size}) => {
      //const { t } = useTranslation()
      const { Layout, Common, Fonts } = useTheme()
      return (
        <View style={[Layout.fill, Common.button.filledRounded]}>
          <Text style={[Layout.fill, Fonts.textSmall, Fonts.textReverse, Fonts.textCenter, {maxHeight:20, textAlign:'center'}]}>{'Open in Combined view'}</Text>
        </View>
      )
    },
  }]
  
  //Auth Check Handler
  /*useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])*/

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  /*if (!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms)) return <LoadingSplash/>
  else {*/
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[Layout.colVCenter, Layout.fullWidth]}>
          <View style={[Common.border, Layout.fullWidth, Layout.colVCenter, {marginVertical:20, maxWidth:602}]}>
            <Button Contexts={[t('Reports.New')]} Action={(ele) => {
              navigate('User', {screen: 'Reports_Editor', params:{New:true}})
            }}/>
            {Reports&&Object.entries(Reports).some(([key,val]) => !val.name)?(
              <Button Contexts={[t('Reports.Edit')]} Action={(ele) => {
                navigate('User', {screen: 'Reports_Editor', params:{New:false}})
              }}/>
            ):(<></>)}
          </View>
          <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, {maxWidth:800, minHeight:360, flex:1}]} >
            <View style={[{width:'99%', height:'99%'}]}>
              <StatefulDataTable 
                batchActions={batchActions}
                columns={[
                  StringColumn({
                    title: t('Reports.listcol1'),
                    mapDataToValue: (data:ReportData) => {
                      return data.name
                    },
                    fillWidth: true,
                  }),
                  DatetimeColumn({
                    title: t('Reports.listcol2'),
                    formatString: 'dd-MM-yyy',
                    mapDataToValue:(data: ReportData) => {
                      let Newest = 9999999999999
                      if (data.pens) {
                        let res = Object.values(data.pens).find(e => TimeTransForm(e.startdate, e.startday) < Newest)
                        if (res) Newest = TimeTransForm(res.startdate, res.startday)
                      }
                      if (data.autopigs) {
                        let res = Object.values(data.autopigs).find(e => TimeTransForm(e.startdate, e.startday) < Newest)
                        if (res) Newest = TimeTransForm(res.startdate, res.startday)
                      }
                      if (data.images) {
                        let res = Object.values(data.images).find(e => TimeTransForm(e.startdate, e.startday) < Newest)
                        if (res) Newest = TimeTransForm(res.startdate, res.startday)
                      }
                      return new Date(Newest)
                    },
                  }),
                  DatetimeColumn({
                    title: t('Reports.listcol3'),
                    formatString: 'dd-MM-yyy',
                    mapDataToValue:(data: ReportData) => {
                      let Oldest = 0
                      if (data.pens) {
                        let res = Object.values(data.pens).find(e => TimeTransForm(e.enddate, e.startday) > Oldest)
                        if (res) Oldest = TimeTransForm(res.enddate, res.startday)
                      }
                      if (data.autopigs) {
                        let res = Object.values(data.autopigs).find(e => TimeTransForm(e.enddate, e.startday) > Oldest)
                        if (res) Oldest = TimeTransForm(res.enddate, res.startday)
                      }
                      if (data.images) {
                        let res = Object.values(data.images).find(e => TimeTransForm(e.enddate, e.startday) > Oldest)
                        if (res) Oldest = TimeTransForm(res.enddate, res.startday)
                      }
                      return new Date(Oldest)
                    },
                  }),
                  BooleanColumn({
                    title: t('Reports.listcol4'),
                    mapDataToValue: (data: ReportData) => data.Archived,
                  }),
                  CustomColumn({
                    title: t('Reports.listcol5'),
                    mapDataToValue: (data: ReportData) => data,
                    renderCell: function Cell(props) {
                      return (
                        <View style={[Layout.row, {width:'-webkit-fill-available', justifyContent:'flex-end'}]}>
                          <TouchableOpacity style={[Layout.fill,{width:28, height:28, maxWidth:28}]} onPress={() => {
                            dispatch(setStates({state:"report", data:props.value.key}))
                            navigate('User', {screen: 'Report_Display'})
                          }}>
                            <ShowIcon size={28} color={"black"} title={""}/>
                          </TouchableOpacity>
                          <View style={{width:10}}/>
                          <TouchableOpacity style={[Layout.fill,{width:28, height:28, maxWidth:28}]} onPress={() => {
                            navigate('User', {screen: 'Reports_Editor', params:{New:false, Selected:props.value.key}})
                          }}>
                            <Image style={[Layout.fill, Layout.fullSize]} source={Images.Simple_Edit} resizeMode={'contain'} />
                          </TouchableOpacity>
                        </View>
                      )
                    },
                  })
                ]}
                initialSortDirection={SORT_DIRECTIONS.DESC}
                initialSortIndex={0}
                rows={ReportRows}
                initialFilters={new Map(Object.entries(SortMap))}
              />
            </View>
          </View>
          {/* Old Report list */}
          <View style={[Layout.fill, Layout.fullSize, Layout.colVCenter, {marginVertical:20}]}>
            {Reports?Object.entries(Reports).filter(e => e).map(([key,val], index) => {
              if (val) {
                if (val.name) {
                  
                }
                else {
                  return (
                    <Button Contexts={key} key={index} Action={(ele) => {
                      dispatch(setStates({state:"report", data:key}))
                      navigate('User', {screen: 'Report_Display'})
                    }}/>
                  )
                }
              } else console.log("??",key)
            }):(<></>)}
          </View>
        </View>
      </View>
    )
  //}
}

export default Reports