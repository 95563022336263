import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  View,
  Linking
} from 'react-native'
import { LoadingSplash } from '../../Components'
import {
  StatefulDataTable,
  CategoricalColumn,
  StringColumn,
} from 'baseui/data-table'
import { Check, Upload as ConsysIcon } from "baseui/icon";

import { useTheme } from '../../Theme'
//import { useTranslation } from 'react-i18next'
import { navigationRef } from '../../Navigators/Root'

import { resetState, setStates } from '../../Store/StateHandlers/Active-slice'
import { useAppSelector, ClearData } from '../../Store'
import { isLoaded, isEmpty, useFirebaseConnect } from 'react-redux-firebase'
//import { actionTypes } from 'redux-firestore'
import { ConsysApi } from '../../Services/Consys/Consys'
import { FarmData } from '../../Config/Types'

interface Datatype {id: string,data: (string | FarmData)[]}
const DataTableColumns = [
  StringColumn({
    title: 'Id',
    mapDataToValue: (data:any) => data[0],
  }),
  CategoricalColumn({
    title: 'Name',
    mapDataToValue: (data:any) => data[1]
  }),
  CategoricalColumn({
    title: 'Tag',
    mapDataToValue: (data:any) => data[2]?data[2]:""
  }),
  StringColumn({
    title: 'Manager',
    mapDataToValue: (data:any) => data[3]?data[3]:""
  }),
  StringColumn({
    title: 'E-Mail',
    mapDataToValue: (data:any) => data[4]?data[4]:""
  }),
]

const Systemboard = () => {
  //const { t } = useTranslation()
  const { Common, Gutters, Layout } = useTheme()
  const dispatch = useDispatch()
  const IsQuickApp = useAppSelector(({startup}) => startup.IsQuickApp)

  useFirebaseConnect(() => {
    return {path:'farms/', storeAs: 'farms'}
  })

  const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const [DataRows, setDataRows] = useState<Datatype[]>([])
  //const activefarm = useAppSelector(({activeData}) => activeData.farm)

  useEffect(() => {
    if (farms) {
      var Data = Object.entries(farms).map(([Id, Data]:[string,any]) => {
        return {id: Id, data: [Id,Data.name,Data.CatTag,Data.manager,Data.email]}
      })
      setDataRows(Data)
    }
  }, [farms])
  
  /*useEffect(() => {
    if (activefarm) {
      //navigationRef.current?.navigate('User', {screen: 'Home'})
    }
  }, [activefarm])*/

  useEffect(() => {
    dispatch(resetState({state:"all"}))
    dispatch(ConsysApi.util.resetApiState())
    ClearData(dispatch)
  },[])

  if (isLoaded(farms) && !isEmpty(farms)) {
    return(
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[{width:'90%', height:'90%'}]}>
          <StatefulDataTable columns={DataTableColumns}
            rows={DataRows}
            emptyMessage="No Data"
            rowActions={[{
              label: 'Consys',
              onClick: ({row}:{row:Datatype}) => {
                if (row.id !== "" && farms[row.id]) {
                  if (farms[row.id].site) {
                    var Cloud = "http://" + farms[row.id].site + ".agrisys.cloud/"
                    Linking.openURL(Cloud)
                  }
                }
              },
              renderIcon: ({size}:{size:string|number|undefined}) => <ConsysIcon title='Consys-Page' size={size} />,
            },{
              label: 'Farm Panel',
              onClick: ({row}:{row:Datatype}) => {
                if (row.id !== "") {
                  dispatch(setStates({state:"farm", data:row.id}))
                  if (IsQuickApp) navigationRef.current?.navigate('User', {screen: 'Operations'})
                  else navigationRef.current?.navigate('User', {screen: 'Home'})
                }
              },
              renderIcon: ({size}:{size:string|number|undefined}) => <Check title='View' size={size} />,
            }]}
          />
        </View>
      </View>
    )
  } else {
    return (<LoadingSplash />) 
  }
}

export default Systemboard
