import { RootState } from '../../Store'
import { ConsysRefs, SystemSet } from '../../Config/Types'

const HTTPS_START = 'https://';
export const Config_Pri = {
  GetToken_URL: (Refresh_Token: string):string => '?redirect_uri=' + Config.CONSYS_REDIRECT + '&client_id='+Config.CONSYS_CLIENT_ID+'&client_secret='+Config.CONSYS_CLIENT_SECRET+'&refresh_token=' + Refresh_Token,
  GetCheckToken_URL: ():string => 'checkaccesstoken',

  GetConsumtionData_Var: (Consumptiontype:ConsysRefs, SystemId:string, ...args:any):string => GetConsumtionData_Var(Consumptiontype, SystemId, args),

  GetSystem_URL: (SysId?:number) => (SysId?`systeminfo?systemindex=${SysId}`:'systemlist'),
  GetSite_URL: (State: RootState, IsDataLink?:string|null):SystemSet|null => GetSite(State, IsDataLink),

  CONSYS_API: '.agrisys.cloud/consys-api-v1',
  CONSYS_AUTH: '.agrisys.cloud/oauthserver/getaccesstoken',
}
///?redirect_uri=urn:ietf:wg:oauth:2.0:oob

const Config = {
  CONSYS_CLIENT_ID: process.env.CONSYS_ID,
  CONSYS_CLIENT_SECRET: process.env.CONSYS_SECRET,
  CONSYS_REDIRECT: 'urn:ietf:wg:oauth:2.0:oob',
}

const GetSite = (State:RootState, IsDataLink?:string|null):SystemSet|null => {
  var farmData:SystemSet|null = null
  const res:SystemSet = {}
  if (IsDataLink) {
    farmData = State.firebase.data.LinkList&&State.firebase.data.LinkList[IsDataLink]&&State.firebase.data.LinkList[IsDataLink].System?State.firebase.data.LinkList[IsDataLink].System as SystemSet:{}
  }
  else {
    const farms = State.firebase.data.farms
    const activefarm = State.activeData.farm
    farmData = (farms&&activefarm&&farms[activefarm])?{site:farms[activefarm].site,refToken:farms[activefarm].refToken}:null
  }
  if (farmData && farmData.site && farmData.site != "") res.site = farmData.site
  if (farmData?.refToken && farmData.refToken != "") res.refToken = farmData.refToken
  console.log("token", res, IsDataLink, farmData)
  return (res.site||res.refToken)?res:null
}

const GetConsumtionData_Var = (Consumptiontype:ConsysRefs, SystemId:string, ...args:any):string => {
  var res = 'getconsumptiondata?consumptiontype=' + Consumptiontype+ '&systemindex='+ SystemId
  if (Consumptiontype === ConsysRefs.VALVEFEED) {
    //res = res.replace('systemindex', 'systemid')

    var valve = args[0][0] //valve
    if (valve !== "all") res += "&valve=" + valve
    
    var _args = args[0][1]
    //res += '&interval=' + (_args.interval?_args.interval:86400)
    if (_args.timerange) res += "&timerange=" + _args.timerange //time 
    else {
      if (_args.stoptime) res += "&stoptime=" + (_args.stoptime / 1000)
      if (_args.starttime) res += "&starttime=" + (_args.starttime / 1000)
    }
  }
  else if (Consumptiontype === ConsysRefs.SCALE) {
    var valve = args[0][0] //valve
    //if (valve !== "all") res += "&valve=" + valve
    var _args = args[0][1]

    if (_args.Offset) res += '&offset=' + _args.Offset;
    if (_args.Limit) res +=  '&limit=' + _args.Limit;

    //res += '&interval=' + (_args.interval?_args.interval:86400)
    //if (_args.timerange) res += "&timerange=" + _args.timerange //time
    if (_args.stoptime) res += "&stoptime=" + (_args.stoptime / 1000)
    if (_args.starttime) res += "&starttime=" + (_args.starttime / 1000)
    
    if (_args.Lastid) res += '&lastid=' + _args.Lastid;
  }
  else if (Consumptiontype === ConsysRefs.CORRIDOR) {
    var valve = args[0][0] //valve
    //if (valve !== "all") res += "&valve=" + valve
    var _args = args[0][1]
    res = "scalenow?" + 'systemindex='+ SystemId + (valve?'&pen='+valve:'')
  }
  else if (Consumptiontype === ConsysRefs.RESETWEIGHT) {
    var valve = args[0][0] //valve
    //if (valve !== "all") res += "&valve=" + valve
    var _args = args[0][1]
    res = "resetpigscaleweight?" + 'systemindex='+ SystemId + '&weightingram=' + _args.weight
  }
  else {
    var _args = args[0][1]
    
    if (_args.Offset) res += '&offset=' + _args.Offset;
    if (_args.Limit) res +=  '&limit=' + _args.Limit;
    if (_args.stoptime) res += "&stoptime=" + (_args.stoptime / 1000)
    if (_args.starttime) res += "&starttime=" + (_args.starttime / 1000)

    if (_args.Lastid) res += '&lastid=' + _args.Lastid;
  }
  return res
}