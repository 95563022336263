import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as resources from './resources'
import moment from 'moment';

i18n.use(initReactI18next).init({
  resources: {
    ...Object.entries(resources).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          translation: value,
        },
      }),
      {},
    ),
  },
  interpolation: {
    format: function(value, format, lng) {
      if (format === 'uppercase') return value.toUpperCase();
      if(value instanceof Date) {
        var formated = moment(value).format(format);
        return formated
      }
      return value;
    }
  },
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  lng: 'en',
})

export default i18n
