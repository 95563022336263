import React, { useEffect, useState, useMemo } from 'react'
import {
  View,
  Text,
  Clipboard,
  FlatList
} from 'react-native'
import { Button, LoadingSplash, Labelled_Input } from '../../Components'
//import Clipboard from '@react-native-clipboard/clipboard'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded, useFirebaseConnect } from 'react-redux-firebase'

import { SystemData, FarmData, Treatment, OperationType, OperationTypes, MainOperationTypes, UserStates, isEmpty } from '../../Config/Types'
import { Comments } from '../../Services/Firebase'
import { DataStringCheck } from '../../utils/formaters'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { setStates, resetState } from '../../Store/StateHandlers/Active-slice'

import {
  StatefulDataTable,
  BooleanColumn,
  StringColumn,
} from "baseui/data-table";

import { Check } from 'baseui/icon'

interface TreatmentsList_Props {
  Illnesses: OperationTypes,
  setIllnesses: (value: React.SetStateAction<OperationTypes>) => void,
  NewIllnesses: OperationTypes,
  setNewIllnesses:(value: React.SetStateAction<OperationTypes>) => void
}
const TreatmentsList = ({Illnesses, setIllnesses, NewIllnesses, setNewIllnesses}:TreatmentsList_Props) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  
  //const firebase = useFirebase()

  const [NewIllCode, setNewIllCode] = useState<number|"">("")
  const [NewIllName, setNewIllName] = useState("")

  return (
    <View style={[Layout.col, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', minWidth:'fit-content'}]}>
      <Button Contexts={t("FarmSettings.TreatmentsTitle")} Title/>
      <View style={[Layout.fill, Layout.column, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', maxHeight:'fit-content'}]}>
        <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'fit-content', flexWrap: "wrap", flexDirection: "row", minHeight:'fit-content', alignItems:'center'}]}>
          <Labelled_Input
            value={NewIllCode+''}
            label={t('FarmSettings.TreatmentProp1')}
            placeholder={""}
            type="number"
            onChange={(text, num) => {
              setNewIllCode(num)
            }}
          />
          <Labelled_Input
            value={NewIllName}
            label={t('FarmSettings.TreatmentProp2')}
            placeholder={""}
            onChange={(text) => {
              if (text){
                if (DataStringCheck({data:text})) {
                  setNewIllName(text)
                }
              }else {
                setNewIllName(text)
              }
            }}
          />
          <Button Contexts={t('ActionButtons.Add')} maxWidth={100} Action={() => {
            if (NewIllCode && !isNaN(NewIllCode)) {
              if (NewIllnesses.concat(Illnesses).some(e => e.code === ''+NewIllCode && !e.isAgri)) {

              } else {
                setNewIllnesses(old => {
                  var _new = [...old]
                  _new.push({code: NewIllCode+'', description:NewIllName, death:false, used:true, _new:true})
                  return _new
                })
              }
            }
          }}/>
        </View>
        <FlatList style={[Layout.fullWidth, {width:'-webkit-fill-available', minHeight:100, maxHeight:200, marginBottom:20, marginRight:5}]}
          data={NewIllnesses.concat(Illnesses)}
          renderItem={({item, index}:{item:Treatment, index:number}) => {
            return(
              <View style={[Common.border, Layout.row, {margin:1, height:40, justifyContent:'space-between'}]}>
                <View style={[Layout.row, {justifyContent:'flex-start'}]}>
                  <Text style={[{marginLeft: 5, alignSelf:'center', width:70, justifyContent:'flex-start'}]}>{`${item.isAgri?'*':''}#${item.code}`}</Text>
                  <Text style={[{marginLeft: 5, alignSelf:'center', width:100}]}>{item.description}</Text>
                </View>
                <View style={[Layout.row, {justifyContent:'flex-end'}]}>
                  <Checkbox
                    labelPlacement={LABEL_PLACEMENT.left}
                    overrides={{
                      Root: { style: ({ }) => ({
                        alignSelf: "center", alignItems: "center",
                        minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                      })},
                      Label: { style: ({ }) => ({
                        justifyContent:'flex-end', paddingBottom:'5px',
                        minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                      })}
                    }}
                    checked={item.death}
                    onChange={(e:any) => {
                      if (item._new){
                        setNewIllnesses(old => {
                          var NewD = [...old]
                          var _Index = NewD.findIndex(e => e.code === item.code)
                          NewD[_Index].death = !item.death
                          NewD[_Index].isAgri = false
                          return NewD
                        })
                      } else {
                        setIllnesses(old => {
                          var NewD = [...old]
                          var _Index = NewD.findIndex(e => e.code === item.code)
                          NewD[_Index].death = !item.death
                          NewD[_Index].isAgri = false
                          return NewD
                        })
                      }
                    }}
                  ><Text style={[{}]}>{t('FarmSettings.TreatmentProp3')}</Text></Checkbox>
                  <Checkbox
                    labelPlacement={LABEL_PLACEMENT.left}
                    overrides={{
                      Root: { style: ({ }) => ({
                        alignSelf: "center", alignItems: "center",
                        minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                      })},
                      Label: { style: ({ }) => ({
                        justifyContent:'flex-end', paddingBottom:'5px',
                        minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                      })}
                    }}
                    checked={item.used}
                    onChange={(e:any) => {
                      if (item._new){
                        setNewIllnesses(old => {
                          var NewD = [...old]
                          var _Index = NewD.findIndex(e => e.code === item.code)
                          NewD[_Index].used = !item.used
                          NewD[_Index].isAgri = false
                          return NewD
                        })
                      } else {
                        setIllnesses(old => {
                          var NewD = [...old]
                          var _Index = NewD.findIndex(e => e.code === item.code)
                          NewD[_Index].used = !item.used
                          NewD[_Index].isAgri = false
                          return NewD
                        })
                      }
                    }}
                  ><Text style={[{}]}>{t('FarmSettings.TreatmentProp4')}</Text></Checkbox>
                </View>
              </View>
            )
          }}
          numColumns={1}
          keyExtractor={(item, index) => String(index)}
        />
      </View>
    </View>
  )
}

interface WorkersList_Props {
  Workers: { key: string, value: boolean }[]
}
const WorkersList = ({Workers}:WorkersList_Props) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()

  //const profile = useAppSelector(({firebase}) => firebase.profile)
  const firebase = useFirebase()

  const activefarm = useAppSelector(state => state.activeData.farm)
  useFirebaseConnect(() => {
    let Calls: any[] = []
    if (!isEmpty(Workers)) {
      Workers.forEach(user => {
        const path = `users/`
        Calls.push({type: 'once', path:path + user.key + "/email", queryParams: [ 'orderByValue' ], storeAs: `UserList/${user.key}`})
      })
    }
    return Calls
  })

  const UserDataList = useAppSelector<{ key: string, value: boolean }[]>(({firebase: {data}}) => data.UserList)
  const UsersList = useMemo(() => {
    if (isEmpty(Workers)) return []
    return Workers.map(Worker => {
      var userdata = {code: Worker.key, email: "", settings:false}
      if (UserDataList && UserDataList[Worker.key]) {
        userdata.email = UserDataList[Worker.key]
      }
      return {data:userdata}
    })
  },[Workers, UserDataList])

  const [NewUserCode, setNewUserCode] = useState("")

  return (
    <View style={[Layout.col, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', minWidth:'300px'}]}>
      <Button Contexts={t("FarmSettings.WorkerTitle")} Title/>
      <View style={[Layout.fill, Layout.column, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', maxHeight:'fit-content'}]}>
        <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'fit-content', flexWrap: "wrap", flexDirection: "row", minHeight:'fit-content', alignItems:'center'}]}>
          <View style={[Layout.fill, Layout.row, {minWidth:'100px'}]}>
            <Labelled_Input
              value={NewUserCode}
              label={t('FarmSettings.WorkerProp1')}
              placeholder={""}
              onChange={(text, num) => {
                setNewUserCode(text)
              }}
            />
          </View>
          <View style={[Layout.row, { marginHorizontal:5, minWidth:210}]}>
            <Button Contexts={t('ActionButtons.Add')} maxWidth={100} Action={() => {
              if (NewUserCode && NewUserCode !== "" && (isEmpty(Workers) || !Workers.some(e => e.key === NewUserCode))) {
                /*firebase.promiseEvents([
                  { path: 'users', queryParams: [ 'orderByChild=/email', `equalTo=${NewUserCode}}` ], storeAs:'Check' },
                ])
                .then((e) => {
                  debugger
                  console.log('data is loaded into redux store')
                })*/
                firebase.set(`users/${NewUserCode}/works/${activefarm}`, true)
                firebase.set(`LinkList/${activefarm}/Workers/${NewUserCode}`, true)
                firebase.set(`WorkersList/${NewUserCode}/${activefarm}`, true)
              }
            }}/>
            <View style={{width:10}}/>
            <Button Contexts={t('ActionButtons.Remove')} maxWidth={100} Action={() => {
              if (NewUserCode && NewUserCode !== "" && !isEmpty(Workers) && Workers.some(e => e.key === NewUserCode)) {
                firebase.set(`users/${NewUserCode}/works/${activefarm}`, null)
                firebase.set(`LinkList/${activefarm}/Workers/${NewUserCode}`, null)
                firebase.set(`WorkersList/${NewUserCode}/${activefarm}`, null)
              }
            }}/>
          </View>
        </View>
        <View style={[Layout.fullWidth, {maxWidth:800, minHeight:200, maxHeight:320, minWidth:270}]} >
          <StatefulDataTable columns={[
              StringColumn({
                title: t('FarmSettings.WorkerProp1'),
                mapDataToValue: (data:any) => data.code
              }),
              StringColumn({
                title: t('FarmSettings.WorkerProp2'),
                mapDataToValue: (data:any) => data.email
              }),
              BooleanColumn({
                title: t('FarmSettings.WorkerProp3'),
                mapDataToValue: (data:any) => data.settings
              }),
            ]}
            rowActions={[{
              label: 'Farm Panel',
              onClick: ({row}) => {
                setNewUserCode(row.data.code)
              },
              renderIcon: ({size}) => <Check title='View' size={size} />,
            }]}
            rows={UsersList}
          />
        </View>
      </View>
    </View>
  )
}

const FarmSettings = () => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  
  const dispatch = useAppDispatch()
  const firebase = useFirebase()

  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector(({firebase}) => firebase.profile)
  
  const farms = useAppSelector<{[key:string]:FarmData}>(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector<string|undefined>(state => state.activeData.farm)
  const farmData = useAppSelector<FarmData|null>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)
  const Settings = useAppSelector<SystemData|undefined>(({firebase: {data}}) => data&&data.system?data.system:undefined)

  const [Site, setSite] = useState(farmData?farmData.site:null)
  const [refToken, setrefToken] = useState(farmData?farmData.refToken:null)
  const [penSurplus, setpenSurplus] = useState(farmData?farmData.penSurplus:0)

  const [penPrefix, setpenPrefix] = useState(Settings&&Settings.penPrefix?Settings.penPrefix:'')

  useFirebaseConnect(() => {
    let Calls: any[] = []
    if (activefarm && activefarm !== "") {
      const path = `LinkList/${activefarm}/`
      Calls.push({path:path + 'Workers', queryParams: ['orderByKey'], storeAs: 'Workers'})
    }
    return Calls
  })

  const Treatments = useAppSelector(state => Comments.GetTreatments(state, undefined))
  const [Illnesses, setIllnesses] = useState<OperationTypes>([])
  const [NewIllnesses, setNewIllnesses] = useState<OperationTypes>([])
  const Workers = useAppSelector<{ key: string, value: boolean }[]>(({firebase: {ordered}}) => ordered.Workers)

  useEffect(() => {
    var _Illnesses:OperationTypes = [...Illnesses]
    Treatments?.forEach(illness => {
      if (!_Illnesses.some(e => e.code === illness.code)){
        _Illnesses.push({...illness})
      }
    })
    if (_Illnesses.length !== Illnesses.length) setIllnesses(_Illnesses)
  }, [Treatments])

  const Save_Settings = () => {
    if (profile.state === UserStates.ADMIN || profile.state === UserStates.TECH) {
      if (farmData && (Site !== farmData.site || refToken !== farmData.refToken|| penSurplus !== farmData.penSurplus)){
        firebase.update(`farms/${activefarm}`, {
          refToken:refToken?refToken:null,
          site:Site?Site:null,
          penSurplus:penSurplus?penSurplus:null
        }, () => {
          firebase.update(`LinkList/${activefarm}/System`, {
            refToken:refToken?refToken:null,
            site:Site?Site:null
          })
        })
      }
    }

    if (penPrefix!==t('FarmSettings.Pendefault')&&(!Settings||!Settings.penPrefix||Settings.penPrefix!==penPrefix)) {
      if (penPrefix === "") firebase.remove(`farms_data/${activefarm}/system/penPrefix`)
      else { 
        firebase.update(`farms_data/${activefarm}/system/`, {
          penPrefix:penPrefix.replace('/','_')
        })
      }
    }

    var Update_Illnesses:{[key:string]:any} = { }
    NewIllnesses.concat(Illnesses.filter(e => !e.isAgri)).forEach((data) => {
      if (!Treatments || !Treatments.some(e => (e.code === data.code))) {
        Update_Illnesses[data.code] = {description:data.description, death: data.death, used:data.used}
      } else if (Treatments) {
        var Illness = Treatments.find(e => e.code === data.code)
        if (Illness && (Illness.death !== data.death || Illness.used !== data.used)) {
          Update_Illnesses[data.code] = {description:data.description, death: data.death, used:data.used}
        }
      }
    })
    if (Object.keys(Update_Illnesses).length) firebase.update(`farms_data/${activefarm}/system/treatments/`, Update_Illnesses)
    navigate('User', {screen:'Home'})
  }

  //Auth Check Handler
  useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms) || !activefarm) return <LoadingSplash/>
  else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[Layout.colVCenter, Layout.fullWidth]}>
          <Button Contexts={t('FarmSettings.Title') + ": " + activefarm} Title Action={() => {
            Clipboard.setString(activefarm)
          }}/>
          <View style={[Layout.col, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', minWidth:'fit-content'}]}>
            {(profile.state === UserStates.ADMIN || profile.state === UserStates.TECH) && (
              <View style={[Layout.fullWidth]}>
                <Labelled_Input disabled={false}
                  value={Site?Site:""}
                  label={'Farm Site'}
                  onChange={(text) => {
                    if (text && text != "") {
                      var _text = text.replaceAll('.', '').replaceAll('-', '')
                      if (DataStringCheck({data:_text.trim()})) {
                        setSite(text)
                      }
                    }else {
                      setSite(text)
                    }
                  }}
                />
                <Labelled_Input disabled={false}
                  value={refToken?refToken:""}
                  label={"Farm refToken"}
                  onChange={(text) => {
                    if (text && text != "") {
                      setrefToken(text)
                    } else {
                      setrefToken(text)
                    }
                  }}
                />
                <Labelled_Input disabled={false}
                  value={penSurplus?penSurplus+'':'0'}
                  label={"Pen Surplus"}
                  onChange={(text, num) => {
                    setpenSurplus(num)
                  }}
                />
              </View>
            )}
          </View>
          
          <View style={{height:10}}/>
          <View style={[Layout.col, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', minWidth:'fit-content'}]}>
            <Button Contexts={t("FarmSettings.PensSettingsTitle")} Title/>
            <Labelled_Input disabled={false}
              value={penPrefix}
              placeholder={t("FarmSettings.Pendefault")}
              label={t("FarmSettings.PenPrefix")}
              onChange={(text) => {
                var res = penPrefix
                if ((text.includes(' ') || text.endsWith(' ')) && text.split(' ').length <= 3) {
                  if (text.split(' ')[text.split(' ').length-1].length <= 8) res = text
                } 
                else if (text.length <= 8) res = text
                setpenPrefix(res)
              }}
            />
            <Button Contexts={t('Operations.EditPens')} Action={() => {
              dispatch(resetState({state:'pen'}))
              navigate('User', {screen:'DataEditor'})
            }}/>
          </View>
          <View style={{height:10}}/>
          <TreatmentsList Illnesses={Illnesses} setIllnesses={setIllnesses} NewIllnesses={NewIllnesses} setNewIllnesses={setNewIllnesses}/>
          {profile.state !== UserStates.WORKER && (
            <WorkersList Workers={Workers}/>
          )}
        </View>

        <Button Contexts={[t('ActionButtons.Save'),t('ActionButtons.Cancel')]} Frame Action={(e)=>!e?Save_Settings():navigate('User', {screen:'Home'})}/>
      </View>
    )
  }
}

export default FarmSettings