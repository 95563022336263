import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { View, Text } from 'react-native'

import { useTheme } from '../../../Theme'
//import { ConsysApi } from '../../../Services'
import { CommentData, Operations, PenData, PenData_Datas, isEmpty } from '../../../Config/Types'

import { ModalDataProps, Operations_Provider } from './Operations_context'
import Operations_View from './Operations'
import Operations_Component from './Operations_Component'
import ModalRequester from './ModalRequester'
import { NormSorter } from '../../../utils/formaters'
import { useAppSelector } from '../../../Store'
import { useFirebase, actionTypes } from 'react-redux-firebase'
import { FirebaseCaller, Sections, Weighings } from '../../../Services/Firebase'
import { SectionListProp } from '../../../Services/Firebase/Sections-Slice'

export enum ModalStates {
  NONE='None',
  STARTPEN='Start',
  CLOSEPEN='Close',
  FETCHWEIGHT='Fetch',
  RESETWEIGHTS='Reset',
}

export interface Selection_PenOptions {
  label: string; id: number;
}
export interface Selection_List_T {
  label: string; id: string;
  isShared: string | undefined;
  PenOptions?: Selection_PenOptions[]
}
const GetSelectionsLists = (sectionDatas:SectionListProp[], activefarm):Selection_List_T[] => {
  const Section_Options = sectionDatas?sectionDatas.map((SectionData) => {
    if (!SectionData||!SectionData.id) return null
    return ({
      label: SectionData.id + (SectionData.data.isDataLink?'@':''), 
      id: SectionData.id,
      isShared:SectionData.data.isDataLink&&activefarm!==SectionData.data.isDataLink?SectionData.data.isDataLink:undefined,
      PenOptions:SectionData.data.pens?SectionData.data.pens.map(penKey => ({label:''+penKey, id:penKey} as Selection_PenOptions)):[]
    }as Selection_List_T)
  }).filter(e=>e):[]
  return Section_Options.sort((a, b) => NormSorter(a.label, b.label))
}

export interface Selections_Type {
  pen?:number,
  section?:string,
  isShared?:string,
}

export interface Operations_Props {
  OnDone?:(PenData:PenData_Datas|undefined, Selected_Data:Selections_Type, Data:Partial<CommentData>, Edit:Partial<CommentData>|null, ModalRes:ModalDataProps) => void,
  Section?:string,
  Pen?:number,
  isShared?:string
}
export type OperationsPropHandle = {
  Reset: () => void,
  setEdit: (data:Partial<CommentData>|null) => void
}
//({ ViewMode=ModuleForms.PAGE, isEditor, SystemIds, Pen, Section, isDataLink, EditItem, FirebaseSave=true }, ref)
const OperationsViewer:React.ForwardRefRenderFunction<OperationsPropHandle, Operations_Props> = ({ Section, OnDone, Pen, isShared }, ref) => { 
  //const { t } = useTranslation()
  const { Colors, Layout, Fonts } = useTheme()

  const firebase = useFirebase()
  const activefarm = useAppSelector(state => state.activeData.farm)
  
  const _sectionDatas = useAppSelector(state => Sections.GetSectionsList(state))
  const [sectionDatas, setSectionDatas] = useState<Selection_List_T[]>([])
  useEffect(() => {
    if (_sectionDatas) {
      setSectionDatas(GetSelectionsLists(_sectionDatas, activefarm))
    }
  }, [_sectionDatas])

  /*if (!Section&&!Pen) {
    const LinkListData = useAppSelector(({firebase: { data }}) => data.LinkListData)
    FirebaseCaller(
      Weighings.fetchWeighings(activefarm, false, [], undefined).concat(
        LinkListData&&!isEmpty(LinkListData)?Object.entries(LinkListData).map(([Current_Farm, Data]) => {
          return Weighings.fetchWeighings(activefarm, false, [], Current_Farm)
        }).flat(1):[]
      )
    )
  }*/

  const [EditItem, setEditItem] = useState<Partial<CommentData>|null>(null)
  useImperativeHandle(ref, ()=>({
    Reset() {
      console.log("Reseted - from view")
    },
    setEdit(data) {
      setEditItem(data)
    }
  }))

  const [OperationsText, setOperationsText] = useState<{text:string,color:string}|null>(null)
  const OperationsResults = (CommitErrors:string[]) => {
    var Text = ""
    var Color = ""
    if (CommitErrors.length) {
      console.log('Db Errors', CommitErrors)
      setOperationsText({text:'Errors', color:Colors.error})
    }
    else {
      console.log('SUCCESFUL DB INSERT')
      setOperationsText({text:'All is Good',color:Colors.success})
    }
    setTimeout(() => {
      setOperationsText(null)
    }, 5000)
  }

  const OnOperationsDone = async (PenData:PenData_Datas|undefined, Selected_Data:Selections_Type, Data:Partial<CommentData&{isTotal:boolean}>, Edit:Partial<CommentData>|null, ModalRes:ModalDataProps) => {
    console.log('Test input', Selected_Data, Data, Edit, ModalRes)
    
    var CommitErrors:string[] = []

    if (Edit && Edit.key && Data.deleted) {
      var path = undefined
      if (Data.action === Operations.WEIGHNING) path = "weighings"
      else path = "comments"
      if (path) {
        firebase.update(`farms_data/${Selected_Data.isShared?Selected_Data.isShared:activefarm}/${path}/${Edit.key}`, {deleted:true}).then((e) => {
        }, (e) => {})
      }
    }
    else {
      var StartOrClose:"Start"|"Close"|undefined = undefined
      //Load Current
      var newQuantity = PenData&&PenData.count?PenData.count:0
      //Remove Change - from previous
      if (Edit && Edit.action && Edit.quantity) {
        if ([Operations.ADD, Operations.START].includes(Edit.action)) newQuantity -= Edit.quantity
        else if ([Operations.MOVE, Operations.CLOSE].includes(Edit.action)) newQuantity += Edit.quantity
      }

      //#region Something firebase - actions
      var DoNotChangeFirebase = false
      const path = `farms_data/${Selected_Data.isShared?Selected_Data.isShared:activefarm}/`
      const CommentCommit = async (_Data:Partial<CommentData&{isTotal:boolean}>, _Edit:Partial<CommentData>|null, UpdatePenCount:boolean=true) => {
        var COPYDATA = {..._Data}
        if (!DoNotChangeFirebase) {
          if (COPYDATA.hasOwnProperty('key')) delete COPYDATA.key
          console.log("Here 2", COPYDATA, COPYDATA.isTotal, COPYDATA.weight, COPYDATA.quantity)
          if (COPYDATA.isTotal) {
            if (COPYDATA.weight) COPYDATA.weight = COPYDATA.weight / (COPYDATA.quantity?COPYDATA.quantity:1)
          }
          if (COPYDATA.hasOwnProperty('isTotal')) delete COPYDATA.isTotal
          await firebase.push(path + `comments`, COPYDATA).then(async (e) => {
            if (_Edit && _Edit.key) {
              await firebase.update(path + `comments/${_Edit.key}`, {edited:e.key})
              .catch(e => CommitErrors.push("Could not edit old comment"))
            }
          }).catch(e => CommitErrors.push("Could not add comment"))
          if (UpdatePenCount) {
            await firebase.update(path+`pens/${Selected_Data.pen}/datas`, {count:newQuantity})
            .catch(e => CommitErrors.push("Could not edit pen quantity"))
          }
        } else console.log(`Add comment ${path}`, COPYDATA, "Update", _Edit, UpdatePenCount?`Set Pen ${Selected_Data.pen} = ${newQuantity}`:'')
      }
      const WeightCommit = async (_Data:Partial<CommentData&{isTotal:boolean}>, _Edit:Partial<CommentData>|null, UpdatePenCount:boolean=false) => {
        var COPYDATA = {..._Data}
        if (!DoNotChangeFirebase) {
          //if (COPYDATA.action) COPYDATA.action = true
          if (COPYDATA.hasOwnProperty('code')) delete COPYDATA.code
          if (COPYDATA.hasOwnProperty('data')) delete COPYDATA.data
          if (COPYDATA.hasOwnProperty('key')) delete COPYDATA.key
          console.log("Here", _Data, COPYDATA, COPYDATA.isTotal, COPYDATA.weight, COPYDATA.quantity)
          if (!COPYDATA.isTotal) {
            COPYDATA.weight = (COPYDATA.weight?COPYDATA.weight:0) * (COPYDATA.quantity?COPYDATA.quantity:1)
          }
          else delete COPYDATA.isTotal
          //FIX new to old transform
          if (COPYDATA.hasOwnProperty('quantity')) {
            COPYDATA.count = COPYDATA.quantity
            delete COPYDATA.quantity
          }
          await firebase.push(path + `weighings`, COPYDATA).then(async (e) => {
            if (_Edit && _Edit.key) {
              await firebase.update(path + `weighings/${_Edit.key}`, {edited:e.key})
              .catch(e => CommitErrors.push("Could not edit old weight"))
            }
          }).catch(e => CommitErrors.push("Could not add weight"))
          if (UpdatePenCount) {
            firebase.update(path+`pens/${Selected_Data.pen}/datas`, {count:newQuantity})
            .catch(e => CommitErrors.push("Could not edit pen quantity"))
          }
        } else console.log(`Add weight ${path}`, COPYDATA, "Update", _Edit, UpdatePenCount?`Set Pen ${Selected_Data.pen} = ${newQuantity}`:'')
      }
      //#endregion

      //Save Comment
      if (Data.action === Operations.MOVE || Data.action === Operations.CLOSE) {
        newQuantity -= Data.quantity?Data.quantity:0
        console.log("Remove ", Data.quantity, Data.pen)
        //Remove count from pen - ref: Data.pen 
        await CommentCommit({...Data}, Edit)
        if (Data.action === Operations.CLOSE) {
          //WeightCommit({...Data, action: true}, {})
          StartOrClose = "Close"
        }
      }
      else if (Data.action === Operations.ADD || Data.action === Operations.START) {
        newQuantity += Data.quantity?Data.quantity:0
        console.log("Add ", Data.quantity, Data.pen)
        //Add count to pen - ref: Data.pen
        await CommentCommit({...Data}, Edit)
        if (Data.action === Operations.START) {
          await WeightCommit({...Data, action: true}, {})
          StartOrClose = "Start"
        }
      }
      //Save Weight or Data
      else if (Data.action === Operations.WEIGHNING) {
        await WeightCommit({...Data}, Edit)
        if (PenData && (!PenData.count || PenData.count === 0)) {
          newQuantity += Data.quantity?Data.quantity:1
          await CommentCommit({...Data, action: Operations.START,
            weight: Data.weight&&newQuantity?(Data.weight/newQuantity):null
          }, {})
          StartOrClose = "Start"
        }
      }
      else if (Data.action === Operations.TREATMENT) {
        console.log("Add treatment", Data.pen)
        newQuantity = PenData&&PenData.count?PenData.count:0
        await CommentCommit({...Data}, Edit, false)
      }
      else {
        console.log("??? What is This", Data.action)
      }
      
      //pen and data clean on start or close
      if (StartOrClose) {
        //Should move comments in batch to closed data ?? X -> 0
        //Then do that in firebase & update pen activedate
        if (StartOrClose === "Close") { //(Data.action === Operations.CLOSE)
          let StartTime = PenData&&PenData.activedate?PenData.activedate:null
          let ClosedTime = ModalRes&&ModalRes.ModalResult?ModalRes.ModalResult:(Data.time?Data.time:Date.now())
          console.log("close pen ", Data.pen, `${StartTime}->${ClosedTime}`)

          if (!DoNotChangeFirebase) {
            firebase.push(path + `datas/periodeList/${Selected_Data.pen}`, {endtime:ClosedTime, starttime:StartTime})
            .then(e => {
              firebase.update(path + `pens/${Selected_Data.pen}/datas`, {count: 0, activedate: null})
              .then(e => {
              })
            })
          } else console.log(`Create periode ${StartTime}->${ClosedTime}`, "and set pen = 0 active = null")

          let Data_Types = ['comments', 'weighings']
          let res = await Promise.all(Data_Types.map(async (Data_element) => {
            var Calls:any[] = []
            Calls.push({path:path + Data_element, queryParams: ['orderByChild=penTime', `startAt=${Selected_Data.pen}#${/* TODO: add LinkBlock - LinkTest?LinkList_Section.starttime:*/0}`, `endAt=${Selected_Data.pen}#${ClosedTime}`], type: 'once', storeAs: 'ClosedData'})
            if (!DoNotChangeFirebase) {
              return await firebase.promiseEvents(Calls, {}).then((data) => {
                if (data) {
                  data.forEach((element:any) => {
                    if (element && element.data) {
                      Object.entries(element.data).forEach(([Key, datas]) => {
                        firebase.set(path + `datas/closed_${Data_element}/${Key}`, datas)
                        firebase.remove(path + `${Data_element}/${Key}`)
                      })
                    }
                  })
                }
                return true
              })
            } else console.log("Firebase Fetch and move to datas/closed", Calls)
          }))
        }
        //else Should set activedate in pen ?? 0 -> X
        else if (StartOrClose === "Start") { //(Data.action === Operations.START)
          let StartTime = Data.time?new Date(Data.time).getTime():Date.now()//).setHours(0,0,0,0)
          console.log("Start pen ", Data.pen, StartTime)
          
          if (!DoNotChangeFirebase) {
            firebase.update(path + `pens/${Selected_Data.pen}/datas`, {activedate: StartTime})
            .then(e => {
            })
          }else console.log(`Set pen activeDate = ${StartTime}`)
        }
      }
    }
    
    OperationsResults(CommitErrors)
    if (OnDone !== undefined) {
      console.log('Return For view opdate')
      OnDone(PenData, Selected_Data, Data, Edit, ModalRes)
    }
  }

  return (
    <Operations_Provider 
      InitialData={{
        Selection_Data: {pen:Pen, section:Section, isShared:isShared },
        //ViewSettings: {ModuleForm:ViewMode, isEditor:isEditor, AvaibleSysIds:SystemIds },
        //Modal_Data: {ModalState:ModalStates.NONE, ModalResult:undefined},
        penLoaded:!(!Section&&!Pen)
      }} 
      SectionOptions={sectionDatas} 
      OnDone={(PenData, Selected_Data, Data, Edit, ModalRes) => OnOperationsDone({...PenData}, {...Selected_Data}, {...Data}, {...Edit}, {...ModalRes})}
      Edit_Operations_Data={EditItem}
    >
      {OperationsText&&OperationsText.text&&OperationsText.text!==""?
        <Text style={[Fonts.textRegular, {height: 26, color:OperationsText.color}]}>{
          OperationsText.text
        }</Text>
      :<></>}

      <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, {minHeight:'max-content', minWidth:'300px', maxWidth:'600px'}]}>
        {!Section&&!Pen?
        <Operations_View/>:
        <></>}
        <Operations_Component/>
      </View>
      <ModalRequester />
    </Operations_Provider>
  )
}

export default forwardRef(OperationsViewer)