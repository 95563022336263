/**
 * Used to navigating without the navigation prop
 * @see https://reactnavigation.org/docs/navigating-without-navigation-prop/
 *
 * You can add other navigation functions that you need and export them
 */
import * as React from 'react'
import { createNavigationContainerRef, CommonActions } from '@react-navigation/native'
import { Platform } from 'react-native'

const getOnLineStatus = () => typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine: true;
export const useNavigatorOnLine = (onlyApp=true) => {
  const [status, setStatus] = React.useState(getOnLineStatus())
  //const isAppView = useisAppView()

  const SetStateWithCheck = (state) => {
    //if (!onlyApp || isAppView) 
    setStatus(state)
  }

  React.useEffect(() => {
    window.addEventListener('online', () => SetStateWithCheck(true))
    window.addEventListener('offline', () => SetStateWithCheck(false))

    return () => {
      window.removeEventListener('online', () => SetStateWithCheck(true))
      window.removeEventListener('offline', () => SetStateWithCheck(false))
    }
  }, [])
  return status
}

const getWindowState = (width) => Platform.select({web: !(width>980?true:false), default: true })
export function useisAppView() {
  const [WindowSize, setWindowSize] = React.useState({width: 0, height: 0})
  const [isApp, setIsApp] = React.useState(false)
  
  const updateWindowDimensions = () => {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    var check = getWindowState(window.innerWidth)
    if (check !== isApp) setIsApp(check)
  }

  React.useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', () => updateWindowDimensions())
    return () => {
      window.removeEventListener('resize', () => updateWindowDimensions())
    }
  }, [])

  return getWindowState(WindowSize.width)
}

export const isApp = () => true

export const navigationRef = createNavigationContainerRef()

export function navigateBack() {
  if (navigationRef.current?.isReady) {
    navigationRef.current?.dispatch(
      CommonActions.goBack()
    )
  }
}

export function navigate(name:string, params:object) {
  if (navigationRef.current?.isReady) {
    navigationRef.current?.dispatch(
      CommonActions.navigate(name, params)
    )
  }
}

export function navigateAndReset(routes = [], index = 0) {
  if (navigationRef.current?.isReady) {
    navigationRef.current?.dispatch(
      CommonActions.reset({
        index,
        routes,
      }),
    )
  }
}

export function navigateAndSimpleReset(name:string, params:object, index = 0) {
  if (navigationRef.current?.isReady) {
    navigationRef.current.dispatch(
      CommonActions.reset({
        index,
        routes: [{ name, params}],
      }),
    )
  }
  
}
