import React, { ReactElement, useEffect, useMemo, useState } from 'react'
//import { useDispatch, useSelector } from 'react-redux'
import {
  ScrollView,
  View,
  Text,
} from 'react-native'
import { Frame, Labelled_Input, Button as DButton, Subscription } from '../../Components'

import {ProgressSteps, Step} from 'baseui/progress-steps'
import {Button, SHAPE, KIND, SIZE} from 'baseui/button'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate } from '../../Navigators/Root'
import { setStates } from '../../Store/StateHandlers/Active-slice'

import { UserData, FarmData, UserStates, isEmpty } from '../../Config/Types'

import { useAppDispatch, useAppSelector } from '../../Store'
import { isLoaded, useFirebase } from 'react-redux-firebase'
import _ from 'lodash'

//#region Button prittyfier for ProgressSteps
function SpacedButton(props:any) {
  return (
    <Button
      {...props}
      shape={SHAPE.pill}
      kind={KIND.secondary}
      size={SIZE.compact}
      overrides={{
        BaseButton: {
          style: ({$theme}) => ({
            marginLeft: $theme.sizing.scale200,
            marginRight: $theme.sizing.scale200,
            marginTop: $theme.sizing.scale800,
          }),
        },
      }}
    />
  );
}
//#endregion

//#region Prev/Next Buttons
const PrevNextButtons = (CurrentMax:number, Current:number, setCurrent:Function, NextAvaible:boolean) => {
  //const { t } = useTranslation()
  const { Layout } = useTheme()

  return (
    <View style={[Layout.fullWidth]}>
      {Current>0&&(
        <SpacedButton onClick={() => setCurrent(Current>0?Current-1:Current)}>
          Previous
        </SpacedButton>
      )}
      {Current<CurrentMax&&(
        <SpacedButton onClick={() => setCurrent(Current<CurrentMax?Current+1:Current)} disabled={!NextAvaible}>
          Next
        </SpacedButton>
      )}
    </View>
  )
}
//#endregion

const RegChecks = (Current:number, profile:UserData, farmData:FarmData):boolean => {
  switch(Current){
    case 0:
      
      return ((profile.name && profile.name != "") && 
              (profile.lastN && profile.lastN != "") &&
              (profile.email && profile.email != ""))?
              true:false
      
    case 1:
      return (profile.state && profile.state != UserStates.STARTER && 
              profile.state != UserStates.WORKER && profile.state != UserStates.CONTACT)?
              true:false
    case 2:
      return (!isEmpty(farmData) && (farmData.name && farmData.name != ""))?
              true:false
  }
  return false;
}

//#region View Handler
const RegistrationViews = (Current:number, setNextAvaible:Function, 
                           profile:UserData, setProfile:React.Dispatch<React.SetStateAction<UserData>>,
                           farmData:FarmData, setFarmData:React.Dispatch<React.SetStateAction<FarmData>>):ReactElement => {
  const { t } = useTranslation()

  const { Gutters, Layout } = useTheme()

  RegChecks(Current, profile, farmData)?setNextAvaible(true):setNextAvaible(false)
  switch(Current) {
    case 0:
    return (
      <View style={[Layout.fill, Gutters.smallV_Margin, {paddingHorizontal:7, maxWidth:550, minWidth:140}]}>
        <Labelled_Input
          value={(profile.name||"")}
          maxWidth={150}
          label={t('UserInfo.Prop1')}
          endEnhancer={"*"}
          onChange={(text) => {
            setProfile((old:UserData) => {return {...old,name:text}})
            //RegChecks(Current, profile, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={(profile.lastN||"")}
          maxWidth={150}
          label={t('UserInfo.Prop2')}
          endEnhancer={"*"}
          onChange={(text) => {
            setProfile((old:UserData) => {return {...old,lastN:text}})
            //fireBase.updateProfile({lastN:text})
            //RegChecks(Current, profile, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={(profile.email||"")}
          maxWidth={250}
          label={t('UserInfo.Prop3')}
          endEnhancer={"*"}
          onChange={(text) => {
            setProfile((old:UserData) => {return {...old,email:text}})
            //RegChecks(Current, profile, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={(profile.phoneNumber||"")}
          maxWidth={175}
          label={t('UserInfo.Prop4')}
          onChange={(text) => {
            setProfile((old:UserData) => {return {...old,phoneNumber:text}})
          }}
          mask={"+999999999999999999999"}
        />
        <Labelled_Input
          value={(profile.address||"")}
          maxWidth={250}
          label={t('UserInfo.Prop5')}
          onChange={(text) => {
            setProfile((old:UserData) => {return {...old,address:text}})
          }}
        />
        <Labelled_Input
          value={(profile.country||"")}
          maxWidth={200}
          label={t('UserInfo.Prop6')}
          onChange={(text) => {
            setProfile((old:UserData) => {return {...old,country:text}})
          }}
        />
      </View>
    )
    case 1:
    return (
      <View style={[Layout.fill, Gutters.tinyV_Margin, {paddingLeft:5, minWidth: 'min-content'}]}>
        <Subscription subscription={(profile.state||'starter')}
          onChange={(text) => {
            setProfile((old:UserData) => {return {...old,state:text as UserStates}})
          }}
        />
      </View>
    )
    case 2:
    return (
      <View style={[Layout.fill, Gutters.smallV_Margin, {paddingLeft:15, maxWidth:550, minWidth:140}]}>
        <Labelled_Input
          value={farmData&&farmData.name?farmData.name:""}
          maxWidth={170}
          label={t('FarmInfo.Prop1')}
          endEnhancer={"*"}
          onChange={(text) => {
            setFarmData((old:FarmData) => {return {...old,name:text}})
            //RegChecks(Current, profile, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={farmData&&farmData.address?farmData.address:""}
          maxWidth={170}
          label={t('FarmInfo.Prop2')}
          onChange={(text) => {
            setFarmData((old:FarmData) => {return {...old,address:text}})
            //RegChecks(Current, farmData, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={farmData&&farmData.country?farmData.country:""}
          maxWidth={170}
          label={t('FarmInfo.Prop3')}
          onChange={(text) => {
            setFarmData((old:FarmData) => {return {...old,country:text}})
            //RegChecks(Current, profile, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={farmData&&farmData.cvr?farmData.cvr:""}
          maxWidth={170}
          label={t('FarmInfo.Prop4')}
          onChange={(text) => {
            setFarmData((old:FarmData) => {return {...old,cvr:text}})
            //RegChecks(Current, profile, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={farmData&&farmData.manager?farmData.manager:""}
          maxWidth={170}
          label={t('FarmInfo.Prop5')}
          onChange={(text) => {
            setFarmData((old:FarmData) => {return {...old,manager:text}})
            //RegChecks(Current, farmData, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
        <Labelled_Input
          value={farmData&&farmData.email?farmData.email:""}
          maxWidth={170}
          label={t('FarmInfo.Prop6')}
          onChange={(text) => {
            setFarmData((old:FarmData) => {return {...old,email:text}})
            //RegChecks(Current, farmData, farmData)?setNextAvaible(true):setNextAvaible(false)
          }}
        />
      </View>
    )
    default:
    return (
      <View style={[
        Layout.fill, Layout.fullWidth, Gutters.smallV_Margin, {maxWidth:600}
      ]}>
        <Text>Something went wrong please Reload the webpage or contact support.</Text>
      </View>
    )
  }
}
//#endregion

const RegistrationContainer = () => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout } = useTheme()

  const dispatch = useAppDispatch()
  const fireBase = useFirebase()

  const auth = useAppSelector(({firebase}) => firebase.auth)
  const rawProfile = useAppSelector<UserData>(({firebase}) => firebase.profile)
  const [profile, setProfile] = useState<UserData>({} as UserData)
  
  const farms = useAppSelector<{[key:string]:FarmData}>(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector<string|undefined>(state => state.activeData.farm)
  const rawFarmData = useMemo<FarmData|null>(() => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null, [farms, activefarm])
  const [farmData, setFarmData] = useState<FarmData>({} as FarmData)

  const logout_Handle = () => fireBase.logout()

  //Auth Check Handler
  useEffect(() => {
    if (isLoaded(auth) && (!auth || !auth.uid)) {
      navigate('Auth', {screen: 'Home'})
    }
  }, [auth])

  //View States
  const CurrentMax = 2
  const [Current, setCurrent] = useState(0)
  const [NextAvaible, setNextAvaible] = useState(false)

  //State check Handler
  useEffect(() => {
    if (!isEmpty(rawProfile)) {
      let newProfile = {...rawProfile} as UserData
      var current = 0
      var test = true
      while (test && current < CurrentMax) {
        test = RegChecks(current, newProfile, farmData)
        if (test) current++
      }
      if (current != Current) {
        setCurrent(current)
      }
      setProfile(newProfile)
    }
  }, [rawProfile])

  useEffect(() => {
    if (!isEmpty(rawFarmData)) {
      setFarmData({...rawFarmData})
    }
  }, [rawFarmData])

  return (
    <View style={[Layout.fullFill, Layout.fullSize, Layout.colCenter, Gutters.tinyPadding, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'min-content', backgroundColor:'#f2f2f2', zIndex:-999}]}>
      <Frame>
        <ScrollView style={[Layout.fill, Layout.fullSize, {zIndex:2}]} centerContent={true} contentContainerStyle={[Layout.fill, {justifyContent: "center", alignItems: "center", minHeight:'max-content'}]}>
          <View style={[Common.border, Layout.midWidth, Layout.rowCenter, Gutters.regularBMargin, Gutters.smallVPadding, {minWidth:'min-content', backgroundColor:"white"}] }>
            <View style={[{flex:1, minWidth:160, minHeight:430, maxWidth:245}]}>
              <ProgressSteps current={Current}
                overrides={{
                  IconContainer: {style: ({ $theme }) => ({
                    marginLeft:'1px',
                    marginRight:'6px',
                  })},
                  Tail: {style: ({}) => ({
                    left: '5px'
                  })},
                  Content: {style: ({}) => ({
                    marginLeft: '32px'
                  })},
                  Title: {style: ({}) => ({
                    paddingBottom: '2px'
                  })}
                }}
              >
                <Step title="Create Account">
                  <View style={[
                    Layout.fill, Layout.fullWidth, Gutters.smallVMargin, {maxWidth:600}
                  ]}>
                    <Text>User information</Text>
                  </View>
                  {PrevNextButtons(CurrentMax, Current, setCurrent, NextAvaible)}
                </Step>
                <Step title="Choose subscription">
                  <View style={[
                    Layout.fill, Layout.fullWidth, Gutters.smallVMargin, {maxWidth:600}
                  ]}>
                    <Text>Account type</Text>
                  </View>
                  {PrevNextButtons(CurrentMax, Current, setCurrent, NextAvaible)}
                </Step>
                <Step title="Setup data">
                  <View style={[
                    Layout.fill, Layout.fullWidth, Gutters.smallVMargin, {maxWidth:600}
                  ]}>
                    <Text>Farm information</Text>
                  </View>
                  {PrevNextButtons(CurrentMax, Current, setCurrent, NextAvaible)}
                </Step>
              </ProgressSteps>
            </View>
            <View style={{width:1, height:"100%", backgroundColor:"black"}}/>
            <View style={[{flex:2, minWidth:'min-content'}]}>
              {RegistrationViews(Current, (e:boolean) => NextAvaible!==e?setNextAvaible(e):{}, profile, setProfile, farmData, setFarmData)}
            </View>
          </View>
          <View style={[Layout.midWidth, Layout.colCenter, {maxWidth:650}]}>
            <DButton Contexts={[t('ActionButtons.Save'),t('ActionButtons.Logout')]}
              Action={ele => {
                switch(ele) {
                  case 0:
                    var _profile:UserData = _.pick(profile, ['name', 'lastN', 'phoneNumber', 'address', 'country', 'email', 'farms', 'state'])
                    if (_profile.name && _profile.lastN && _profile.email) {
                      var state = _profile.state&&_profile.state===UserStates.WORKER?UserStates.WORKER:UserStates.STARTER
                      fireBase.updateProfile({..._profile, state:state}).then((docRef:any) => {
                        if (farmData.name) {
                          fireBase.push(`farms`, farmData).then((docRef:any) => {
                            var farms:{[key:string]:boolean} = rawProfile.farms?rawProfile.farms:{}
                            farms[docRef.key] = true
                            fireBase.updateProfile({farms:farms, state:_profile.state}).then(() => {
                              dispatch(setStates({state:"farm", data:docRef.key}))
                              navigate('Home', {screen: 'Home'})
                            }, reason => {
                              
                            })
                          })
                        } else if (_profile.state && _profile.state === UserStates.WORKER) {
                          navigate('Home', {screen: 'Home'})
                        }
                      })
                    }
                  break
                  case 1:
                    logout_Handle()
                  break
                }
              }} 
            />
          </View>
        </ScrollView>
      </Frame>
    </View>
  )
}

export default RegistrationContainer
