// Need to use the React-specific entry point to import createApi
//import { createApi, fetchBaseQuery, BaseQueryEnhancer } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../Store'
import { ConsysRefs, DataConsData, isEmpty } from '../../Config/Types'
import { Config_Pri } from './Config'
import { ConsysApi, ConsysArgs} from './Consys'

export interface ActivityInfo {
  dataid: number,
  amount?: number,
  sumamount?: number,
  weight?: number,
  timestamp: number,
}
export type ActivityList = Record<string, Record<string, {amount:number, count:number, timestamp:number, objArr:ActivityInfo[]}>>
export interface ActivityProp extends ConsysArgs {
  FarmKeys?:string[],
  systemids?:string|string[]|{id:string,XTern:string}|{id:string,XTern:string}[],
  valve:string|number,
  stoptime:number,
  starttime:number
}
export interface ActivityProp2 extends ConsysArgs {
  FarmKeys:string[],
  systemids:string[] | { id:string, XTern:string}[],
  Xsystemids:string[] | {id:string,XTern:string}[],
  valve:string|number,
  stoptime:number[],
  starttime:number[]
}

const _GetError = (dataText:string):any => {
  return {error: {
    status: 500,
    statusText: 'Token retrieve error',
    data: dataText,
  }}
}

const DoqueryFn = async (args:ActivityProp|ActivityProp2, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (!args.FarmKey && args.FarmKeys) {
    var resData = await Promise.all(args.FarmKeys.map(DataLink => DoqueryFn({...args, FarmKey:DataLink}, queryApi, extraOptions, fetchWithBQ)))
    var res:{data:any[], error:any[]} = {data:[], error:[]}
    resData.forEach((val, index) => {
      if (val && val.data) {
        res.data = res.data.concat(val.data)
      }
      if (val && val.error) {
        res.error.push(val.error)
      }
    })
    res.data = res.data.filter(e => e)
    res.error = res.error.filter(e => e)
    if (!isEmpty(res.data)) return {data: res.data}
    else if (!isEmpty(res.error)) {
      return {error: res.error[0]}
    }
  }
  else {
    const state = (queryApi.getState() as RootState)
    var Autopigs:DataConsData|undefined = undefined 
    var STime = args.starttime, ETime = args.stoptime
    if (args.FarmKey) {
      Autopigs = state.firebase.data.LinkList[args.FarmKey].DataCons
      if (state.firebase.data.LinkList[args.FarmKey].starttime) {
        if (STime < state.firebase.data.LinkList[args.FarmKey].starttime) STime = state.firebase.data.LinkList[args.FarmKey].starttime
      }
      if (state.firebase.data.LinkList[args.FarmKey].endtime) {
        if (ETime > state.firebase.data.LinkList[args.FarmKey].endtime) ETime = state.firebase.data.LinkList[args.FarmKey].endtime
      }
    } else Autopigs = state.firebase.data.DataCons

    var Errors = null, Activityres = null
    let valveId:string|null = args.valve!==undefined&&args.valve!==""?args.valve+'':null
    var getAutos = Autopigs?Object.entries(Autopigs).filter(([key, data]) => (key!=="Sys_Config"&&key!=="update_time"&&(!args.systemids||(
      typeof args.systemids==="string"?key===args.systemids:
      typeof args.systemids==='object'?Object.entries(args.systemids).some(([keys, data]) => (data&&typeof data==="string"&&data===key)||(data&&data.id===key))
      :false
    )))):[]

    Activityres = await Promise.all(getAutos.map(async ([key, AutoPig]) => {
      if (AutoPig && AutoPig.activity && AutoPig.activity !== "") {
        var Activitys = AutoPig.activity.split(',').reduce((a:object, v:string) => ({ ...a, [v]: (AutoPig.activity.includes('all,')&&v!=='all')?false:true}), {})
        if ((!valveId || valveId==="all") || (Activitys['all']?!Activitys[valveId]:Activitys[valveId])) {
          let Result = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.ACTIVITY, key, valveId?valveId:'all', {starttime:ETime, stoptime:STime}), params:args})
          
          if (Result) {
            if (Result.error || (!Result.data || !Result.data.consumptiondata)) {
              Errors = _GetError(Result.error?Result.error.error:"")
            } else if (Result.data && Result.data.consumptiondata) {
              var ResActivitys = Result.data.consumptiondata as ActivityInfo[]

              var dataMap:ActivityList = {}
              if (ResActivitys) {
                ResActivitys.forEach(dataPoint => {
                  if (dataPoint) {
                    const Time = new Date(dataPoint.timestamp * 1000)
                    const TimeName:string = Time.getFullYear() + "_" + (Time.getMonth()+1) + "_"+ Time.getDate()
                    
                    const weight = (dataPoint.sumamount?dataPoint.sumamount:dataPoint.amount?dataPoint.amount:(dataPoint.weight||0)) /1000

                    var name = AutoPig.name?AutoPig.name:"SysId_" + key
                    if (!dataMap[name]) dataMap[name] = {}
                    if (!dataMap[name][TimeName]) dataMap[name][TimeName] = { amount: weight, count: 1, timestamp: (Time.setHours(23,59,59)), objArr:[] }
                    else {
                      //Navg = avg0 + (( NVal - acg0) / Ncount)
                      dataMap[name][TimeName].amount = (dataMap[name][TimeName].amount + ( (weight - dataMap[name][TimeName].amount) / (dataMap[name][TimeName].count +1) ) )
                      dataMap[name][TimeName].count += 1
                    }
                    dataMap[name][TimeName].objArr.push(dataPoint)
                  }
                })
              }
              return dataMap
            }
          } else Errors = _GetError("No Data Return")
        }
      }
    }))

    if (!Errors) {
      return {data: Activityres.filter(e => e)}
    } else {
      return Errors
    }
  }
  return _GetError("No Site")
}

// Define a service using a base URL and expected endpoints
const ConsysApi_WithTag = ConsysApi.enhanceEndpoints({
  addTagTypes: ['Activity']
})
export const ActivitySlice = ConsysApi_WithTag.injectEndpoints({
  overrideExisting:false,
  endpoints: (builder) => ({
    getActivityList: builder.query<ActivityList[], ActivityProp|void>({
      async queryFn(args:ActivityProp, queryApi, extraOptions, fetchWithBQ) {
        return await DoqueryFn(args, queryApi, extraOptions, fetchWithBQ)
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Activity', Key})
        })
      },
    }),
    getActivities: builder.query<ActivityList[], ActivityProp2|void>({
      async queryFn(args:ActivityProp2, queryApi, extraOptions, fetchWithBQ) {
        var Data:any[] = []
        if (!isEmpty(args.systemids) || !isEmpty(args.Xsystemids)) {
          for (let index = -1; index < args.FarmKeys.length; index++) {
            if (index < 0) {
              var Intern = await DoqueryFn({FarmKey:null, systemids:args.systemids, valve: args.valve, starttime: args.starttime[0], stoptime: args.stoptime[0]}, queryApi, extraOptions, fetchWithBQ)
              Data = Data.concat(Intern.data)
            }
            else {
              var XTern = await DoqueryFn({FarmKey:null, FarmKeys:args.FarmKeys, systemids:args.Xsystemids, valve: args.valve, starttime: args.starttime[index+1], stoptime: args.stoptime[index+1]}, queryApi, extraOptions, fetchWithBQ)
              Data = Data.concat(XTern.data)
            }
          }
        }
        //TODO: ERROR
        return {data:Data}
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Activity', Key})
        })
      },
    }),
  }),
})
export const GetActivityList = ActivitySlice.endpoints.getActivityList.useQuery
export const GetActivities = ActivitySlice.endpoints.getActivities.useQuery