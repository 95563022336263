import React, { useEffect, useMemo } from 'react'
import {
  View, Text,
} from 'react-native'
import { ContentProp } from '../../../Components'
import { Select, TYPE } from "baseui/select"

import { useTheme } from '../../../Theme'
import { useTranslation } from 'react-i18next'
//import { navigate, isApp } from '../../Navigators/Root'

import { useAppSelector } from '../../../Store'
//import { useFirebase, actionTypes } from 'react-redux-firebase'

//import { UnixDayTime, Operations, WeighingData, CommentData } from '../../../Config/Types'
import { DataCons } from '../../../Services/Firebase'
import { ConsysApi } from '../../../Services/'
import { ScaleData } from '../../../utils/DataCallers'

import { OprerationsContext_Action, useOperations_Context } from './Operations_context'
import { CommentData, TimeSpan, isEmpty } from '../../../Config/Types'
import { ScaleList } from '../../../Services/Consys/Scale-Slice'

//import OperationsFrame from './OperationFrame' // === The single state components 
//import { ModalStates, ModuleForms } from '.'
//import { NormSorter } from '../../../utils/formaters'

const Operations_View = (MainProps:any) => {
  const { t } = useTranslation()
  const { Layout, Gutters, Colors } = useTheme()
  //const MainParams = MainProps&&MainProps.route&&MainProps.route.params?MainProps.route.params:undefined
  //const Appdispatch = useAppDispatch()
  const activefarm = useAppSelector(state => state.activeData.farm)
  const IsQuickApp = useAppSelector(({startup}) => startup.IsQuickApp)
  const { state: { Selection_Data, SectionOptions, Selection_Pens, PenData:{SelectedPen, penData} }, dispatch } = useOperations_Context()
  
  const DataConsData = useAppSelector(state => Selection_Data&&Selection_Data.pen?DataCons.GetDataCons_Pen(state, Selection_Data.pen, Selection_Data.isShared):undefined)
  //const _DataConsData = useAppSelector((state) => penData&&penData.autopigs?DataCons.GetPenDataCon(state, Selection_Data.isShared, penData.autopigs):undefined)
  //let ConScaleList:any[]|undefined = undefined
  const ConScaleList = ScaleData(false, Selection_Data.isShared, Selection_Data.pen+'', {start:new Date().setHours(0,0,0,0), end:new Date().setHours(23,59,59,59)}, Selection_Data.pen, undefined).AutoData

  //TODO: cannot sort/filter as this changes hook order.. (Error)
  //if (penData&&penData.autopigs && DataConsData) {
    //if (Object.values(DataConsData).some(e => e.scale)) {
      //ConScaleList = ScaleData(false, Selection_Data.isShared, penData&&penData.autopigs?penData.autopigs:undefined, {start:new Date().setHours(0,0,0,0), end:new Date().setHours(23,59,59,59)} as TimeSpan, Selection_Data.pen, undefined).AutoData
    //}
  //}

  const {data: ConSystemList, error: ConSystemListError, isUninitialized: NoConSystemList} = ConsysApi.System.GetSystemList({FarmKey:activefarm, FarmKeys:Selection_Data.isShared?[Selection_Data.isShared]:undefined, getInfos:true, limitIds:DataConsData?Object.keys(DataConsData):undefined}, {skip:(!penData||!penData.autopigs||penData.autopigs===""), pollingInterval:14000})
  useEffect(() => {
    if (ConSystemListError) { console.log(ConSystemListError) }
    else if (ConSystemList) {
      //console.log(ConSystemList)
    }
  },[ConSystemListError, ConSystemList])

  const {/*DisplayCount, DisplayWeight, GrowthDisplay, WeightSets, PredictSets, TemplateSets, */IsAutoData} = useMemo(() => {
    var IsAutoData:{amount:number,count:number, latest:number}|null = null
    
    if (ConScaleList && !isEmpty(ConScaleList)) {
      //TODO: Multiple Autopigs calc ??
      var AutoWeights = Object.entries(ConScaleList[0]).map(([AutoKey, data]) => {
        return Object.values((data as ScaleList)).map((e:any) => {
          if (e.timestamp <= new Date().setHours(0,0,0,0)) {
            return {count:1, pen:Selection_Data.pen, time: e.timestamp, penTime:`${Selection_Data.pen}#${e.timestamp}`, weight:e.amount} as CommentData
          } else {
            var latest = Math.max(...e.objArr.map(o => o.timestamp))
            IsAutoData = {amount: e.amount, count: e.count, latest:latest}
            return undefined
          }
        }).filter(e => e)
      })
      /*if (!isEmpty(AutoWeights)) {
        const _Weights = AutoWeights[0].filter(e => e)
        if (!isEmpty(_Weights)) Weights = _Weights as CommentData[]
      }*/
    }

    return {/*DisplayCount, DisplayWeight, GrowthDisplay, WeightSets, PredictSets, TemplateSets,*/ IsAutoData}
  },[penData, ConScaleList])

  const SelectedSection = useMemo(() => {
    if (SectionOptions && SectionOptions[0]) {
      var Section = SectionOptions.find(e => e&&e.id === Selection_Data.section && e.isShared === Selection_Data.isShared)
      if (Section) return Section
      else if (SectionOptions[0]) dispatch({type:'set_Section', payload:{id:SectionOptions[0].id, isShared:SectionOptions[0].isShared}})
    }
  },[Selection_Data.section, Selection_Data.isShared, SectionOptions])

  /*const Periodes = useAppSelector(state => Selection_Data.pen?FBPens.GetPenPeriodes(state, Selection_Data.isShared, Selection_Data.pen):[])
  const [age, startdate] = useMemo(() => {
    var startdate:number|undefined = undefined
    if (penData&&penData.datas&&penData.datas.activedate) {
      startdate = penData.datas.activedate
    }
    if (activefarm?.startsWith('-Demo') && Periodes && Periodes.length && Periodes['0'] && Periodes['0'].starttime) {
      startdate = Periodes['0'].starttime
    }

    var age = '-'
    if (startdate) {
      age = Math.floor(((Date.now()-startdate)/UnixDayTime)) + ''
    }
    return [age, startdate]
  }, [penData, Periodes])*/

  //const weighings_Store = useAppSelector(state => Weighings.GetPenWeights(state, Selection_Data.isShared, Selection_Data.pen, false))
  //const Operations_Store = useAppSelector(state => Comments.GetPenComments(state, Selection_Data.isShared, Selection_Data.pen, false))

  return (
    <View style={[Layout.colVCenter, Gutters.smallV_Padding, Gutters.tinyH_Padding, {width:'-webkit-fill-available', minHeight:'fit-content', justifyContent:'center'}]} >
      <View style={[Layout.row, {width:'-webkit-fill-available', minHeight:'fit-content', justifyContent:'center'}]}>
        {/* Selectors */}
        <View style={[Layout.fill, {maxWidth:'max-content', minWidth:'min-content', maxHeight:'fit-content'}]}>
          <Text style={[{}]}>{t('Operations.Section')}</Text>
          <Select clearable={false}
            options={SectionOptions.map(e => e&&({...e, id:e.isShared?e.id+'@'+e.isShared:e.id}))}
            value={SelectedSection?[{...SelectedSection, id:SelectedSection.isShared?SelectedSection.id+'@'+SelectedSection.isShared:SelectedSection.id}]:[]}
            type={TYPE.select}
            placeholder="---"
            onChange={(params:any) => {
              var value = params.value[0]
              var id = value.isShared ? value.id.slice(0, -value.isShared.length -1) : value.id
              dispatch({type:'set_Section', payload:{id, isShared:value.isShared}})
            }}
            maxDropdownHeight="300px"
            overrides={{
              Root: { style: ({ $theme }) => ({
                maxWidth:'200px', minWidth:'150px', 
                outline:'black 1px solid'
              })}
            }}
          />
        </View>
        <View style={{width:10}}/>
        <View style={[Layout.fill, {maxWidth:'max-content', minWidth:'min-content', maxHeight:'fit-content'}]}>
          <Text style={[{}]}>{t('Operations.Pen')}</Text>
          <Select clearable={false}
            options={Selection_Pens}
            value={SelectedPen?[SelectedPen]:[]}
            type={TYPE.select}
            placeholder="---"
            onChange={(params:any) => {
              var value = params.value[0]
              dispatch({type:'set_Selected_Pen', payload:value.id})
            }}
            maxDropdownHeight="300px"
            overrides={{
              Root: { style: ({ $theme }) => ({
                maxWidth:'200px', minWidth:'150px', 
                outline:'black 1px solid'
              })}
            }}
          />
        </View>

        {/* If Weight and edit is possible */}
      </View>

      {/* Pen Data Info's */}
      <View style={[Layout.colVCenter, {width:'-webkit-fill-available', marginVertical:10, minHeight:'fit-content', justifyContent:'center'}]}>
        <ContentProp Columns={1} Datas={[[t('Operations.Data')]]} Backcolor/>
        <ContentProp Columns={2} Datas={[[t('Operations.Pigs'), ''+(penData&&penData.count?penData.count:0)]]}/>
        {/*<ContentProp Columns={2} Datas={[[t('Pen_Display.Prop4')+' '+t('DataTypes.W'), '---']]}/>*/}
        <ContentProp Columns={2} Datas={[[t('Pen_Display.Size'), ''+(penData&&penData.pensize?penData.pensize:1)]]}/>
        
        {DataConsData?(
        <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, {minHeight:'max-content', minWidth:'300px', marginTop:15}]}>
          {ConSystemList&&ConSystemList.length?(ConSystemList[0].SystemIndex?ConSystemList:ConSystemList[0]).map(Con => {
            var DataCon = Object.entries(DataConsData).find(([key, values]) => key+'' === (Con.SystemIndex?Con.SystemIndex:0)+'')
            if (DataCon) {
              var ConDatas = DataCon[1]
              var SysId = Con.SystemIndex?Con.SystemIndex : 0
              var Name = ConDatas&&ConDatas.name?ConDatas.name : Con.SystemName

              var _Datas = [`System ${SysId}#: ${Name}`, Con.Online?'Online':'Error']
              var _Colors = [null, Con.Online?Colors.AgriGreen:Colors.AgriRed]
              
              if (ConDatas.scale) {
                if(!(IsQuickApp&&Selection_Data.isShared))
                  _Datas.push(`Registred Weights: ${IsAutoData&&IsAutoData.count?IsAutoData.count:'---'}`)
                if (IsAutoData&&IsAutoData.count) {
                  if (IsAutoData.latest && IsAutoData.lastest>(Date.now()-345.600)) {
                    _Colors.push(null)
                  }
                  else _Colors.push(Colors.BUMBLEBEE)
                }
                else _Colors.push(Colors.BUMBLEBEE)
              }

              return (<ContentProp key={`ConSys_${SysId}`} 
                  Columns={_Datas.length} Datas={[_Datas]} colorStamp={_Colors}
              />)
            }
            return (<></>)
          }):(<></>)}
        </View>
        ):(<></>)}
      </View>
    </View>
  )
}
export default Operations_View