import React, { useContext, useEffect, useMemo, useRef, useState, createContext, useCallback, useImperativeHandle, forwardRef } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native'
import { Button, ContentProp, CurveViewer, Labelled_Input, Brand, Datetime_Picker, OperationsList, FeedSilosList } from '../../Components'

import { useTheme, PrintContainer } from '../../Theme'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from '../../Store'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { Comments, DataCons } from '../../Services/Firebase'

import { UnixDayTime, MainOperationTypes, Treatment, CommentData, isEmpty, ConsysRefs, isDemoFarm, FormatTimes, SystemData, ReportData, TimeSpan, Periodes, Operations as OTypes } from '../../Config/Types'
import { CurveViewerPropHandle, PointSet, DataSet, DataSets, ViewForms, DataTemplate } from '../../Components/GeneralUse/CurveViewer'

import { Calc_Weights_Tabel, GenerateCSV_Data, getUniqueColor } from '../../utils/formaters'
import { AccumulateTime } from '../../utils/DataCalculator'
import { DataCallerList, FeedData, ScaleData } from '../../utils/DataCallers'

import { Combobox, SIZE } from 'baseui/combobox'
import { Button as BaseButton } from "baseui/button";
import { ChevronDown, ChevronLeft, ChevronRight, CheckIndeterminate } from "baseui/icon"
import { useStyletron, withStyle } from 'baseui'
import useDetectPrint from 'react-detect-print'

import {
  StyledTable,
  StyledHeadCell,
  StyledBodyCell
} from 'baseui/table-grid'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce
} from 'baseui/modal'
import { Checkbox } from 'baseui/checkbox'
import { useisAppView } from '../../Navigators/Root'
import { ScaleList } from '../../Services/Consys/Scale-Slice'
import { FeedInfo, SiloInfo } from '../../Services/Consys/Feed-Slice'
import { WaterInfo } from '../../Services/Consys/Water-Slice'

const CenteredBodyCell = withStyle(StyledBodyCell, {
  display: 'flex',
  alignItems: 'center',
})

function Row({Pen, striped, row, printing}) {
  const [css] = useStyletron()
  const { Layout } = useTheme()
  const [expanded, setExpanded] = React.useState(false)
  
  return (
    <div role="row" className={css({ display: "contents" })}>
      <CenteredBodyCell className={css({ maxHeight: "20px", outline:'1px solid black' })} $striped={striped}>
        <BaseButton
          size="compact"
          kind="tertiary"
          onClick={() => setExpanded(!expanded)}
          shape="square"
        >
          {row.data&&!isEmpty(row.data)?
            (expanded||printing) ?<ChevronDown title='hide' size={16} />:<ChevronRight title='show' size={16} />
          :<CheckIndeterminate title='noData' size={16}/>}
        </BaseButton>
        {Pen}
      </CenteredBodyCell>
      <CenteredBodyCell className={css({ maxHeight: "20px", outline:'1px solid black' })} $striped={striped}>
        {row.days}
      </CenteredBodyCell>
      <CenteredBodyCell className={css({ maxHeight: "20px", outline:'1px solid black' })} $striped={striped}>
        {row.ind}
      </CenteredBodyCell>
      <CenteredBodyCell className={css({ maxHeight: "20px", outline:'1px solid black' })} $striped={striped}>
        {row.out}
      </CenteredBodyCell>
      <CenteredBodyCell className={css({ maxHeight: "20px", outline:'1px solid black' })} $striped={striped}>
        {row.growth}
      </CenteredBodyCell>

      {row.data&&!isEmpty(row.data)&&(expanded||printing)?<RowMore _key={Pen} tasks={row.data} striped={striped}/>:(<></>)}
    </div>
  )
}

function RowMore(props) {
  const [css] = useStyletron()
  const { Layout } = useTheme()
  const { t } = useTranslation()

  return (
    <div
      className={css({
        gridColumn: "span 5",
        padding: "0px 0px 10px 10px"
      })}
    >
      <StyledTable key={(props._key?props._key:'') + "_Ex"} role="grid" $gridTemplateColumns="auto max-content max-content max-content minmax(120px, max-content) minmax(120px, max-content)">
        <div role="row" className={css({ display: "contents" })}>
          <StyledHeadCell $sticky={false} className={css({ maxHeight: "35px", alignItems: 'center' })}>{t('WeightList.Col2_1')}</StyledHeadCell>
          <StyledHeadCell $sticky={false} className={css({ maxHeight: "35px", alignItems: 'center' })}>{t('WeightList.Col2_2')}</StyledHeadCell>
          <StyledHeadCell $sticky={false} className={css({ maxHeight: "35px", alignItems: 'center' })}>{t('WeightList.Col2_3')}</StyledHeadCell>
          <StyledHeadCell $sticky={false} className={css({ maxHeight: "35px", alignItems: 'center' })}>{t('WeightList.Col2_4')}</StyledHeadCell>
          <StyledHeadCell $sticky={false} className={css({ maxHeight: "35px" })}>{t('WeightList.Col2_5')}</StyledHeadCell>
          <StyledHeadCell $sticky={false} className={css({ maxHeight: "35px" })}>{t('WeightList.Col2_6')}</StyledHeadCell>
        </div>
        {props.tasks?.map((task, index) => {
          var _WeightDate = new Date(task[0])
          var WeightDate = _WeightDate.getDate() + "-" + (_WeightDate.getMonth()+1)
          return (
            <div role="row" key={`${props._key?props._key:''}_task${index}`} className={css({ display: "contents" })}>
              <StyledBodyCell className={css({ maxHeight: "35px", outline:'1px solid black' })} $striped={props.striped}>{WeightDate}</StyledBodyCell>
              <StyledBodyCell className={css({ maxHeight: "35px", outline:'1px solid black' })} $striped={props.striped}>{task[1]}</StyledBodyCell>
              <StyledBodyCell className={css({ maxHeight: "35px", outline:'1px solid black' })} $striped={props.striped}>{task[2]}</StyledBodyCell>
              <StyledBodyCell className={css({ maxHeight: "35px", outline:'1px solid black' })} $striped={props.striped}>{task[3]}</StyledBodyCell>
              <StyledBodyCell className={css({ maxHeight: "35px", outline:'1px solid black' })} $striped={props.striped}>{task[4]}</StyledBodyCell>
              <StyledBodyCell className={css({ maxHeight: "35px", outline:'1px solid black' })} $striped={props.striped}>{task[5]}</StyledBodyCell>
            </div>
          )
        })}
      </StyledTable>
    </div>
  )
}

interface PensData_Prop {Id:string, startday?:number, startdate:number, enddate?:number, XTern?:string}
interface AutosData_Prop {Id:string, name:string, startday?:number, startdate:number, enddate?:number, XTern?:string}
interface ImagesData_Prop {Id:string, name:string, startday?:number, startdate?:number, enddate?:number}
interface Operations_Prop {List: any[], Deaths: number, Added: number, Sold: number}
interface ReportView_Data_Prop {
  ReportId:string,
  ReportName:string,
  ReportStart:number,
  ReportEnd:number,

  Graph: any,
  Pens: PensData_Prop[],
  Autos: AutosData_Prop[],
  Images: ImagesData_Prop[],

  Operations: Operations_Prop,
  Weights: any[],

  Feeds: Record<string, {time:number, amount:number, datas:FeedInfo[]}>,
  FCR: Record<string, {time:number, amount:number, datas:FeedInfo[]}>,
  Scales: any[],
  Activities: any[],
  Temps: any[],
  Waters: any[],
  WatersPrPig: any[],
  Fetchings?: any[],
}

const GetReportsComments = createSelector(
  [
    (state, ReportIds, Report_Datas) => get(state.activeData, 'farm', undefined),
    (state, ReportIds, Report_Datas) => get(state.firebase.ordered, `reportData`, undefined),
    (state, ReportIds, Report_Datas) => Comments.GetTreatments(state, undefined),
    (state, ReportIds, Report_Datas) => ReportIds,
    (state, ReportIds, Report_Datas) => Report_Datas
  ],
  (FarmId:string|undefined, ReportsDatas, Treatments:Treatment[], ReportIds:string[], Report_Datas:Report_MemData_Prop[]) => {
    var Added = 0, Sold = 0, Deaths = 0
    var Datas:any[] = []
    if (ReportsDatas) {
      Datas = Report_Datas.map((ReportDatas, index) => {
        var ReportStart = ReportDatas.Start, ReportEnd = ReportDatas.End
        var ReportPens = ReportDatas.Pens
        var Comments:CommentData[] = []
        if (ReportIds[index] && ReportsDatas[ReportIds[index]] && !isEmpty(ReportPens)) {
          var CurrentComments:Record<number, {key:string, value:CommentData}[]> = ReportsDatas[ReportIds[index]].comments
          var ClosedComments:Record<number, {key:string, value:CommentData}[]> = ReportsDatas[ReportIds[index]].closed_comments

          Comments = ReportPens.map((PenData, Index) => {
            var CurrentComment = CurrentComments&&CurrentComments[Index]?CurrentComments[Index]:[]
            var ClosedComment = ClosedComments&&ClosedComments[Index]?ClosedComments[Index]:[]
            var test = ClosedComment.concat(CurrentComment)
            var Combined:CommentData[] = test.reduce((res, Operation) => {
              if (Operation && !Operation.value.edited && !Operation.value.deleted) {
                var _data:CommentData|undefined = Operation&&Operation.value?Operation.value:undefined
                if (_data && _data.pen+''=== PenData.Id+'') {
                  if (FarmId && isDemoFarm(FarmId)) {
                    if (_data.penTime && _data.penTime.includes('$')) {
                      var TimeDatas = _data.penTime.split('#')[1].split('$')
                      var time = new Date()
          
                      time.setDate(time.getDate()-parseInt(TimeDatas[0]))
                      time.setHours(parseInt(TimeDatas[1]))
                      time.setMinutes(parseInt(TimeDatas[2]))
          
                      var data = {..._data}
                      data.time =  time.getTime()
                      data.penTime = `${_data.pen}#${data.time}`
                      _data = data
                    }
                  }
                  var Times = FormatTimes([(PenData.startdate || ReportStart), (PenData.enddate || ReportEnd)], FarmId)
                  if (Times && (Times[0] && Times[0] <= _data.time) && (Times[1] && Times[1] >=_data.time)) {
                    res.push({..._data, key:Operation.key, penIndex:Index })
                  }
                }
              }
              return res  
            }, [] as CommentData[])

            if (!isEmpty(Combined)) {
              Added += Combined.reduce((res, Operation) => (Operation.action === "Add pig"&&Operation.quantity)?(res+Operation.quantity):res, 0)
              Sold += Combined.reduce((res, Operation) => (Operation.action === "Sold pig"&&Operation.quantity)?(res+Operation.quantity):res, 0)
              Deaths +=  Combined.reduce((res, Operation) => {
                var adds = 0
                var CODE = Operation.code
                if (CODE && CODE.includes("_")) {
                  var _code = CODE.split("_")
                  if (MainOperationTypes[_code[0]] && MainOperationTypes[_code[0]].death) adds += (Operation.quantity||0)
                } else if (CODE && Treatments?.find(e => e.code === CODE)?.death) adds += (Operation.quantity||0)
                return res + adds
              },0)
            }
            return Combined
          }).flat(1)
        }
        return Comments
      })
    }
    return {Operations:Datas, Added:Added, Sold:Sold, Deaths:Deaths}
  }
)

const GetReportsWeights = createSelector(
  [
    (state, ReportIds, Report_Datas) => get(state.activeData, 'farm', undefined),
    (state, ReportIds, Report_Datas) => get(state.firebase.ordered, `reportData`, undefined),
    (state, ReportIds, Report_Datas) => ReportIds,
    (state, ReportIds, Report_Datas) => Report_Datas
  ],
  (FarmId:string|undefined, ReportsDatas, ReportIds:string[], Report_Datas:Report_MemData_Prop[]) => {
    var Datas:any[] = []
    if (ReportsDatas) {
      Datas = Report_Datas.map((ReportDatas, index) => {
        var ReportStart = ReportDatas.Start, ReportEnd = ReportDatas.End
        var ReportPens = ReportDatas.Pens
        var Weights:CommentData[][] = []
        if (ReportsDatas && ReportsDatas[ReportIds[index]]) {
          var CurrentWeights:Record<number, {key:string, value:CommentData}[]> = ReportsDatas[ReportIds[index]].weighings
          var ClosedWeights:Record<number, {key:string, value:CommentData}[]> = ReportsDatas[ReportIds[index]].closed_weighings
  
          Weights = ReportPens.map((PenData, Index) => {
            var ClosedWeight = ClosedWeights&&ClosedWeights[Index]?ClosedWeights[Index]:[]
            var CurrentWeight = CurrentWeights&&CurrentWeights[Index]?CurrentWeights[Index]:[]
            return ClosedWeight.concat(CurrentWeight).reduce((res:CommentData[], Weight) => {
              if (Weight && !Weight.value.edited && !Weight.value.deleted) {
                var _data:CommentData|undefined = Weight&&Weight.value?Weight.value:undefined
                if (_data && _data.pen+''=== PenData.Id+'') {
                  if (FarmId && isDemoFarm(FarmId)) {
                    if (_data.penTime && _data.penTime.includes('$')) {
                      var TimeDatas = _data.penTime.split('#')[1].split('$')
                      var time = new Date()
          
                      time.setDate(time.getDate()-parseInt(TimeDatas[0]))
                      time.setHours(parseInt(TimeDatas[1]))
                      time.setMinutes(parseInt(TimeDatas[2]))

                      var data = {..._data}
                      data.time =  time.getTime()
                      data.penTime = `${_data.pen}#${data.time}`
                      _data = data
                    }
                  }
                  if ((PenData.startdate || ReportStart) <= _data.time &&
                      (PenData.enddate || ReportEnd) >=_data.time) {
                    res.push({..._data, key:Weight.key})
                  }
                }
              }
              return res.sort((a,b) => a.time-b.time)
            }, [] as CommentData[])
          })
        }
        return Weights
      })
    }
    return Datas
  }
)

const GetReportImages = createSelector(
  (state:any, ReportId:string) => (ReportId==="Combined-View"&&state.activeData.report.datas)?state.activeData.report:undefined,
  (state:any, ReportId:string) => (ReportId==="Combined-View"&&state.activeData.report.datas)?state.activeData.report.datas
                                  :get(state.firebase.data, `reports.${ReportId}.images`, undefined),
  (StateData:any|undefined, ImageData:Record<string,ImagesData_Prop>|undefined) => {
    var Images:{name:string,id:string}[] = []
    if (StateData) {
      Images = StateData.datas.map((e,i) => ({name:e, id:''+i}))
    }
    else if (ImageData) {
      Images = Object.values(ImageData).map((e,i) => ({name:e.Id, id:''+i}))
    }
    return Images
  }
)

interface Report_MemData_Prop {
  Name:string,
  Start:number,
  End:number,
  HasData:boolean,

  Graph: any,
  Pens: PensData_Prop[], //{id:string, startday?:number, startdate:number, enddate?:number, XTern?:string}[],
  Autos: AutosData_Prop[], //{name:string,id:string, startday?:number, XTern?:string, startdate:number, enddate?:number}[],
  Images: ImagesData_Prop[], //{name:string,id:string, startday?:number,}[],

  Operations?: Operations_Prop, //{List: any[],Deaths: number, Added: number, Sold: number},
  Weights?: any[],

  Feeds?: Record<string, {time:number, amount:number, datas:FeedInfo[]}>,
  FCR: Record<string, {time:number, amount:number, datas:FeedInfo[]}>,
  Scales?: any[],
  Temps?: any[],
  Waters: any[],
  WatersPrPig: any[],
  Fetchings?: any[],
}
const GetReportsDatas = createSelector(
  [
    (state, ReportIds, Report_Datas) => get(state.activeData, 'farm', undefined),
    (state, ReportIds) => get(state.firebase.data, `reports`, undefined),
    (state, ReportIds, MainReport) => ReportIds,
    (state, ReportIds, MainReport) => MainReport,
  ],
  // Output selector gets (`items, category)` as args
  (FarmId:string|undefined,ReportsDatas, ReportIds:string[], MainReport:string):Report_MemData_Prop[] => {
    var Datas:Report_MemData_Prop[] = []
    if (ReportsDatas) {
      var MainIndex:number|undefined = undefined
      Datas = ReportIds.map((RepId, index) => {
        if (RepId === MainReport) MainIndex = index
        var Has = false
        var Pens:PensData_Prop[] = []
        var Autos:AutosData_Prop[] = []
        //var Images:{name:string,id:string}[] = []
        var Start = 9999999999999
        var End = 0
        var Extra = 0
        if (ReportsDatas[RepId]) {
          var ReData = ReportsDatas[RepId]
          if (ReData.time) Start = ReData.time
          if (ReData.closedTime) End = ReData.closedTime
          var Graph = ReData.gcurve
          var PenData = ReData.pens
          var AutoData = ReData.autopigs
          //var ImageData = ReData.images

          if (PenData) {
            Has = true
            Pens = Object.entries(PenData).map(([Index, data]:[string,any]) => {
              var Times = FormatTimes([data.startdate, data.enddate], FarmId)
              if (Times) {
                if (Times[0] && Times[0] < Start) Start = Times[0]
                if (Times[1] && Times[1] > End) End = Times[1]
              }
              if (data.startday && data.startday > Extra) Extra = data.startday
              return ({Id:data.Id, startday:data.startday, XTern:data.XTern, startdate:Times?Times[0]:undefined, enddate:Times?Times[1]:undefined})
            })
          }
          if (AutoData) {
            Has = true
            Autos = Object.entries(AutoData).map(([Index, data]:[string,any]) => {
              var Times = FormatTimes([data.startdate, data.enddate], FarmId)
              if (Times) {
                if (Times[0] && Times[0] < Start) Start = Times[0]
                if (Times[1] && Times[1] > End) End = Times[1]
              }
              if (data.startday && data.startday > Extra) Extra = data.startday
              return ({Id:data.Id, name:data.name?data.name:data.Id, startday:data.startday, XTern:data.XTern, startdate:Times?Times[0]:undefined, enddate:Times?Times[1]:undefined})
            })
          }
          /*if (ImageData) {
            Has = true
            Images = Object.entries(ImageData).map(([Index, data]:[string,any], index) => {
              if (data.startdate && FormatTimes(FarmId,data.startdate) < Start) Start = FormatTimes(FarmId,data.startdate)
              if (data.enddate && FormatTimes(FarmId,data.enddate) > End) End = FormatTimes(FarmId,data.enddate)
              return ({Id:''+index, name:data.Id, startday:data.startday?data.startday:0})
            })
          }*/
        }
        if (Start === 9999999999999) Start = Date.now() - (UnixDayTime * 7)
        if (End === 0) End = Date.now()
        if (Extra > 0) End = End + (UnixDayTime * Extra)
        return {HasData:Has, Pens:Pens, Autos:Autos, AutoDatas:AutoData, Images:[], ImageDatas:ImageData, Start:Start, End:End, Graph:Graph, Name:ReData&&ReData.name?ReData.name:RepId}
      })
      if (MainIndex !== undefined && Datas.length > 1) {
        var Start = Datas[MainIndex].Start
        var End = Datas[MainIndex].End
        Datas.forEach(element => {
          if (element.Start < Start) Start = element.Start
          if (element.End > End) End = element.End
        })
        Datas[MainIndex].Start = Start
        Datas[MainIndex].End = End
      }
    }
    return Datas
  }
)

const GetAutos = createSelector(
  [
    (state) => get(state.firebase.data, `DataCons`, undefined),
  ],
  // Output selector gets (`items, category)` as args
  (AutoDatas):AutosData_Prop[] => {
    var Data:any|undefined = undefined
    if (AutoDatas) Data = Object.fromEntries(Object.entries(AutoDatas).filter(([key,val]) => (key !=="update_time" && key!=="Sys_Config")))
    return Data
  }
)

export const DataContext = createContext<ReportView_Data_Prop[]>([])
export const DataProvider = ({ children }) => {
  const activefarm = useAppSelector(state => state.activeData.farm)
  const activeReport_Datas = useAppSelector<string>(({activeData: {report}}) => report?report:"")
  const {activeReport, datas} = useMemo(() => {
    if (activeReport_Datas) {
      if (activeReport_Datas.id) return {activeReport: activeReport_Datas.id, datas: activeReport_Datas.datas}
      else return {activeReport:activeReport_Datas, datas:null}
    }
    return {activeReport:"", datas:null}
  },[activeReport_Datas])
  const ReportImages = useAppSelector(state => GetReportImages(state, activeReport))

  const Reports = useMemo(() => [activeReport].concat(ReportImages.map((Report) => Report.name)), [activeReport, ReportImages])
  const Report_Datas = useAppSelector<Report_MemData_Prop[]>(state => GetReportsDatas(state, Reports, activeReport_Datas))

  const XTern_Data = useAppSelector(state => DataCons.GetExternDataCons(state, undefined, undefined, true))
  const XTern_Keys = useMemo<string[]>(() => XTern_Data?Object.keys(XTern_Data):[], [XTern_Data])
  
  const PensData = useAppSelector(({firebase: {data}}) => data.pens)
  const XTern_pens = useMemo(() => XTern_Data?Object.entries(XTern_Data).filter(([key,val]) => val&&val.pens).map(([key,val]) => val.pens):null, [XTern_Data])
  
  const XTern_Autopigs = useMemo(() => XTern_Data?Object.entries(XTern_Data).filter(([key,val]) => val&&val.DataCons).map(([key,val]) => Object.fromEntries(Object.entries(val.DataCons).filter(([key,val])=> (key !=="update_time" && key!=="Sys_Config")))):null, [XTern_Data])
  const Autopigs = useAppSelector(state => GetAutos(state))

  const FB_PenGet = useMemo(() => {
    let ReportCall: any[] = []
    if (activefarm && activefarm !== "") {
      Report_Datas.forEach((ReportDatas, index) => {
        var ReportId = Reports[index]
        if (ReportDatas.HasData && !isEmpty(ReportDatas.Pens)) {
          ReportDatas.Pens.forEach((Pen, PenIndex) => {
            const path = !Pen.XTern?`${isDemoFarm(activefarm)?'DemoData':'farms_data'}/${activefarm}/`:(
              XTern_Data&&Object.keys(XTern_Data).includes(Pen.XTern)?`farms_data/${Pen.XTern}/`:null
            )
            if (path) {
              ReportCall.push({path:path + 'weighings', queryParams: !isDemoFarm(activefarm)?['orderByChild=penTime', `startAt=${Pen.Id}#${(Pen.startdate || ReportDatas.Start)}`, `endAt=${Pen.Id}#${(Pen.enddate || ReportDatas.End)}`]:['orderByChild=time'], 
                              storeAs: `reportData/${ReportId}/weighings/${PenIndex}`})
              ReportCall.push({path:path + 'datas/closed_weighings', queryParams: !isDemoFarm(activefarm)?['orderByChild=penTime', `startAt=${Pen.Id}#${(Pen.startdate || ReportDatas.Start)}`, `endAt=${Pen.Id}#${(Pen.enddate || ReportDatas.End)}`]:['orderByChild=time'], 
                              storeAs: `reportData/${ReportId}/closed_weighings/${PenIndex}`})
    
              ReportCall.push({path:path + 'comments', queryParams: !isDemoFarm(activefarm)?['orderByChild=penTime', `startAt=${Pen.Id}#${(Pen.startdate || ReportDatas.Start)}`, `endAt=${Pen.Id}#${(Pen.enddate || ReportDatas.End)}`]:['orderByChild=time'], 
                              storeAs: `reportData/${ReportId}/comments/${PenIndex}`})
              ReportCall.push({path:path + 'datas/closed_comments', queryParams: !isDemoFarm(activefarm)?['orderByChild=penTime', `startAt=${Pen.Id}#${(Pen.startdate || ReportDatas.Start)}`, `endAt=${Pen.Id}#${(Pen.enddate || ReportDatas.End)}`]:['orderByChild=time'], 
                              storeAs: `reportData/${ReportId}/closed_comments/${PenIndex}`})
            }
          })
        }
      })
    }
    return ReportCall
  }, [activefarm, Report_Datas])
  useFirebaseConnect(() => FB_PenGet)

  const ReportsWeights = useAppSelector(state => GetReportsWeights(state, Reports, Report_Datas))
  const ReportsOperations = useAppSelector(state => GetReportsComments(state, Reports, Report_Datas))

  const {Feeds, XTernFeeds} = useMemo(() => {
    var Feeds:string[] =[], XTernFeeds:string[][] = []
    Report_Datas.forEach((ReportDatas, index) => {
      if (!isEmpty(ReportDatas.Pens)) {
        ReportDatas.Pens.forEach(Pen => {
          var Pendata:any = null, Auto:string[]|undefined=undefined
          if (!Pen.XTern) {
            if (PensData&&Object.keys(PensData).includes(Pen.Id)) {
              Pendata = PensData[Pen.Id]
              Auto = Autopigs?Object.entries(Autopigs).filter(([key,Adata]) => Adata.feed&&Adata.feed!=="" &&((Adata.feed==="All")||(Pendata.datas&&Pendata.datas.autopigs&&Pendata.datas.autopigs.split(',').includes(key)))&&!Feeds.includes(key)).map(([key, val]) => key):null
              if (Auto && Auto.length) Feeds = Feeds.concat(Auto)
            }
          } else {
            if (XTern_Data && Object.keys(XTern_Data).includes(Pen.XTern)) {
              var ConIndex = Object.keys(XTern_Data).indexOf(Pen.XTern)
              if (XTern_pens&&XTern_pens[ConIndex]&&Object.keys(XTern_pens[ConIndex]).includes(Pen.Id)) {
                Pendata = XTern_pens[ConIndex][Pen.Id]
                Auto = XTern_Autopigs&&XTern_Autopigs[ConIndex]?Object.entries(XTern_Autopigs[ConIndex]).filter(([key,Adata]) => Adata.feed&&Adata.feed!=="" && Pendata.datas.autopigs.split(',').includes(key) && !XTernFeeds.includes(key)).map(([key, val]) => key):null
                if (Auto && Auto.length) XTernFeeds[index] = Auto
              }
            }
          }
        })
      }
    })
    return {Feeds:Feeds, XTernFeeds:XTernFeeds}
  }, [Report_Datas])

  const {Scales, XTernScales, Temps, XTernTemps, Waters, XTernWaters} = useMemo(() => {
    var Scales:string[] =[], XTernScales:{id:string, XTern:string}[][] = [], Temps:string[] = [], XTernTemps:{id:string, XTern:string}[][] =[], Waters:string[] =[], XTernWaters:{id:string, XTern:string}[][] =[]
    Report_Datas.forEach((ReportDatas, index) => {
      if (ReportDatas && !isEmpty(ReportDatas.AutoDatas)) {
        Scales = Scales.concat(Object.values(ReportDatas.AutoDatas).filter(e => !e.XTern && e.scale && !Scales.some(R => R === e.Id)).map(data => data.Id))
        XTernScales[index] = Object.values(ReportDatas.AutoDatas).filter(e => e.XTern && e.scale && !XTernScales.some(R => R.Id === e.Id && R.XTern === e.XTern)).map(data => ({id:data.Id, XTern:data.XTern}))

        Temps = Temps.concat(Object.values(ReportDatas.AutoDatas).filter(e => !e.XTern && e.temp && !Temps.some(R => R === e.Id)).map(data => data.Id))
        XTernTemps[index] = Object.values(ReportDatas.AutoDatas).filter(e => e.XTern && e.temp && !XTernTemps.some(R => R.Id === e.Id && R.XTern === e.XTern)).map(data => ({id:data.Id, XTern:data.XTern}))

        Waters = Waters.concat(Object.values(ReportDatas.AutoDatas).filter(e => !e.XTern && e.water && !Waters.some(R => R === e.Id)).map(data => data.Id))
        XTernWaters[index] = Object.values(ReportDatas.AutoDatas).filter(e => e.XTern && e.water && !XTernWaters.some(R => R.Id === e.Id && R.XTern === e.XTern)).map(data => ({id:data.Id, XTern:data.XTern}))
      }
    })
    return {Scales:Scales, XTernScales:XTernScales, Temps:Temps, XTernTemps:XTernTemps, Waters:Waters, XTernWaters:XTernWaters}
  }, [Report_Datas])

  //#region Con Data Fetchers
  const {AutoData: ConFeedList, DataList:ConFeedsList, isFetching: ConFeedFetching} = FeedData(true, [undefined].concat(XTern_Keys), Feeds, Report_Datas.map((Datas) => ({start: Datas.Start, end: Datas.End} as TimeSpan)), undefined, XTernFeeds)
  const FeedsLists = useMemo(() => {
    var RepFeedDatas:Record<string, {time:number, amount:number, datas:FeedInfo[]}>[][] = []
    if (!isEmpty(Report_Datas) && ConFeedsList && !isEmpty(ConFeedsList)) {
      RepFeedDatas = Report_Datas.map((ReportDatas, RepIndex) => {
        var dataMap:Record<string, {time:number, amount:number, datas:FeedInfo[]}> = {}
        var prDataMap:Record<string, {time:number, amount:number, datas:FeedInfo[]}> = {}
        
        var AllOpes = ReportsOperations.Operations[RepIndex]

        Object.entries(ConFeedsList).forEach(([ValveId, dataPoints]) => {
          if (dataPoints && !isEmpty(dataPoints) && ReportDatas.Pens.some(e => e&&e.Id===ValveId+'')) {
            var PenTotal_PigCount:number|undefined = undefined
            if (AllOpes && AllOpes.length) { 
              PenTotal_PigCount = 0
              AllOpes.forEach(OPE => {
                if (OPE && OPE.time && OPE.pen+'' === ValveId) {
                  if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) PenTotal_PigCount -= OPE.quantity
                  else if (OPE.action === OTypes.ADD) PenTotal_PigCount += OPE.quantity
                }
              })
            }
            var PenSettings = ReportDatas.Pens.find(e => e&&e.Id===ValveId+'')
            if (ReportDatas.HasData && PenSettings) {
              dataPoints.forEach(dataPoint => {
                var Time = dataPoint.timestamp
                if ((/*PenSettings.startdate ||*/ ReportDatas.Start) <= Time && (/*PenSettings.enddate ||*/ ReportDatas.End) >= Time) {
                  var _Date = new Date(new Date(Time).setHours(23,59,59,0))

                  if (AllOpes && AllOpes.length && PenTotal_PigCount !== undefined) {
                    var PigCount = (PenTotal_PigCount||1)
                    AllOpes.forEach(OPE => {
                      if (OPE && OPE.pen+'' === ValveId && dataPoint.timestamp < OPE.time) {
                        if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) PigCount += OPE.quantity
                        else if (OPE.action === OTypes.ADD) PigCount -= OPE.quantity
                      }
                    })
                    var amount = (dataPoint.sumamount?dataPoint.sumamount:0) / PigCount
                    if (isNaN(amount) || !isFinite(amount)) amount = 0
                    if (!prDataMap[ValveId]) prDataMap[ValveId] = {time:_Date.getTime(), amount: amount, datas:[dataPoint]}
                    else {
                      prDataMap[ValveId].amount += amount
                      prDataMap[ValveId].datas.push(dataPoint)
                    }
                  }

                  if (!dataMap[ValveId]) dataMap[ValveId] = {time:_Date.getTime(), amount: dataPoint.sumamount?dataPoint.sumamount:0, datas:[dataPoint]}
                  else {
                    dataMap[ValveId].amount += dataPoint.sumamount?dataPoint.sumamount:0
                    dataMap[ValveId].datas.push(dataPoint)
                  }
                }
              })
            }
          }
        })
        return [dataMap, prDataMap]
      })
    }
    return RepFeedDatas
  },[ConFeedsList, ReportsOperations.Operations])
  
  const {AutoData: ConScaleList} = ScaleData(true, undefined, Scales, Report_Datas.map((Datas) => ({start: Datas.Start, end: Datas.End} as TimeSpan)), undefined, [])
  const {AutoData: XTernConScaleList} = ScaleData(true, XTern_Keys, [], Report_Datas.map((Datas) => ({start: Datas.Start, end: Datas.End} as TimeSpan)), undefined, XTernScales)
  
  const Scales_ActivityList = useMemo(() => {
    var RepScale_ActivityDatas:PointSet[][][][] = [[],[]]
    if (!isEmpty(Report_Datas)) {
      RepScale_ActivityDatas = Report_Datas.map((ReportDatas, RepIndex) => {
        var ScaleDatas:PointSet[][] = []
        var ActivityDatas:PointSet[][] = []
        ReportDatas.Autos.forEach((Auto, index) => {
          var ReportAutoData = ReportDatas.Autos[index]
          var ConData:null|ScaleList = null
          if (ReportAutoData) {
            if (!ReportAutoData.XTern && ConScaleList && !isEmpty(ConScaleList)) ConData = ConScaleList
            else if (XTernConScaleList && !isEmpty(XTernConScaleList)) {
              var ConIndex = XTern_Keys.findIndex(e => e === ReportAutoData.XTern)
              //var ConIndex = XTernScales.findIndex(e => e && e[0] && e[0].XTern && e[0].XTern === ReportAutoData.XTern)
              if (ConIndex >= 0) ConData = XTernConScaleList[ConIndex]
            }
          }

          var ScaleData:ScaleList|undefined = undefined
          if (ConData) {
            for (let i2 = 0; i2<=ConData.length&&!ScaleData; i2++) {
              const E = ConData[i2];
              if (E) {
                var Key = Object.keys(E).find((name:string) => name===Auto.name || name==="SysId_"+Auto.Id)
                if (Key) ScaleData = {[Key]:E[Key]}
              }
            }
          }
          
          if (ScaleData) {
            Object.entries(ScaleData).map(([AutoId, AutoDatas]) => {
              if (AutoDatas) {
                var Datas = Object.entries(AutoDatas).reduce((pData:{scale:PointSet[],activity:PointSet[]},cData) => {
                  const [TimeName, TimeData] = cData
                  var Time = TimeData.timestamp
                  if (ReportDatas.HasData) {
                    if ((/*AutoData.startdate ||*/ ReportDatas.Start) <= Time && (/*AutoData.enddate ||*/ ReportDatas.End) >= Time) {
                      //if (AutoData.startday) Time += parseInt(AutoData.startday) * UnixDayTime
                      var data = pData
                      data.scale = data.scale.concat([{x:Time, y:TimeData.amount, objData:TimeData.objArr}])
                      data.activity = data.activity.concat([{x:Time, y:TimeData.count, objData:TimeData.objArr}])
                      return data
                    }
                  }
                  return pData
                }, {scale:[], activity:[]})
                if (Datas && Datas.scale && !isEmpty(Datas.scale)) {
                  ScaleDatas[index] = Datas.scale
                  if (Datas.activity && !isEmpty(Datas.activity)) ActivityDatas[index] = Datas.activity
                }
              }
            })
          }
        })
        return [ScaleDatas, ActivityDatas]
      })
    }
    return RepScale_ActivityDatas
  }, [ConScaleList, XTernConScaleList])
  
  //const {data: ConTempList, error: ConTempListError, isUninitialized: NoConTempList} = {data:null, error:null}
  const {data: ConTempList, error: ConTempListError, isUninitialized: NoConTempList} = DataCallerList(ConsysRefs.TEMPERATURE, activefarm, XTern_Keys, Temps, XTernTemps,
    Report_Datas.map((Datas) => ({start: Datas.Start, end: Datas.End} as TimeSpan)),
    (isEmpty(Temps)&&isEmpty(XTernTemps)), 'all'
  )
  useEffect(() => {
    if (ConTempListError) console.error({ConTempListError})
  },[ConTempListError])
  const TempsList = useMemo(() => {
    var RepTempDatas:any[] = []
    if (!isEmpty(Report_Datas) && ConTempList && !isEmpty(ConTempList)) {
      RepTempDatas = Report_Datas.map((ReportDatas, RepIndex) => {
        var TempDatas:any[] = []
        ReportDatas.Autos.forEach((Auto, index) => {
          var AutoData = ReportDatas.Autos[index]
          var TempData = ConTempList.find(E => (Object.keys(E)[0]===Auto.name || Object.keys(E)[0]==="SysId_"+Auto.Id))
          
          if (TempData) {
            Object.entries(TempData).map(([AutoId, AutoDatas]) => {
              if (AutoDatas) {
                TempDatas[index] = Object.entries(AutoDatas).map(([TimeName, TimeData]) => {
                  var Time = TimeData.timestamp
                  
                  if (ReportDatas.HasData && (AutoData.startdate || ReportDatas.Start) <= Time &&
                      (AutoData.enddate || ReportDatas.End) >= Time) {
                    if (AutoData.startday) Time += parseInt(AutoData.startday) * UnixDayTime
                    return ({x:Time, y:TimeData.amount, objData:TimeData.objArr} as PointSet)
                  }
                  return null
                }).filter(e => e)
              }
            })
          }
        })
        return TempDatas
      })
    }
    return RepTempDatas
  }, [ConTempList])

  //const {data: ConWaterList, error: ConWaterListError, isUninitialized: NoConWaterList} = {data:null, error:null}
  const {data: ConWaterList, error: ConWaterListError, isUninitialized: NoConWaterList} = DataCallerList(ConsysRefs.WATERCOUNT, activefarm, XTern_Keys, Waters, XTernWaters,
    Report_Datas.map((Datas) => ({start: Datas.Start, end: Datas.End} as TimeSpan)),
    (isEmpty(Waters)&&isEmpty(XTernWaters)), 'all'
  )
  useEffect(() => {
    if (ConWaterListError) console.error({ConWaterListError})
  },[ConWaterListError])
  const WatersList = useMemo(() => {
    var RepScaleDatas:any[] = []
    if (!isEmpty(Report_Datas) && ConWaterList && !isEmpty(ConWaterList)) {
      RepScaleDatas = Report_Datas.map((ReportDatas, RepIndex) => {
        var ScaleDatas:any[] = []
        var prPigDatas:any[] = []
        ReportDatas.Autos.forEach((Auto, index) => {
          var AutoData = ReportDatas.Autos[index]
          var ScaleData = ConWaterList.find(E => (Object.keys(E)[0]===Auto.name || Object.keys(E)[0]==="SysId_"+Auto.Id))
          if (ScaleData) {
            var AllOpes = ReportsOperations.Operations[index]
            var PenTotal_PigCount:number|undefined = undefined
            if (AllOpes && AllOpes.length) { 
              PenTotal_PigCount = 0
              AllOpes.forEach(OPE => {
                if (OPE && OPE.time) {
                  if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) PenTotal_PigCount -= OPE.quantity
                  else if (OPE.action === OTypes.ADD) PenTotal_PigCount += OPE.quantity
                }
              })
            }
            Object.entries(ScaleData).map(([AutoId, AutoDatas]) => {
              if (AutoDatas) {
                var TotalWaterPrPig = 0 // ??? is possible
                ScaleDatas[index] = Object.entries(AutoDatas).map(([TimeName, TimeData], _index) => {
                  var Time = TimeData.timestamp
                  if (ReportDatas.HasData && (AutoData.startdate || ReportDatas.Start) <= Time &&
                      (AutoData.enddate || ReportDatas.End) >= Time) {
                    if (AutoData.startday) Time += parseInt(AutoData.startday) * UnixDayTime

                    if (AllOpes && AllOpes.length && PenTotal_PigCount) {
                      var PigCount = (PenTotal_PigCount||1)
                      AllOpes.reverse().forEach(OPE => {
                        if (OPE && TimeData.timestamp < OPE.time) {
                          if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) PigCount += OPE.quantity
                          else if (OPE.action === OTypes.ADD) PigCount -= OPE.quantity
                        }
                      })
                      var amount = TimeData.amount / PigCount
                      if (isNaN(amount) || !isFinite(amount)) amount = 0
                      TotalWaterPrPig+=amount
                      if (!prPigDatas[index]) prPigDatas[index] = []
                      prPigDatas[index][_index-1] = ({x:Time, y:amount, objData:TimeData.objArr} as PointSet) // {...TimeData, amount:amount }
                    }
                    return ({x:Time, y:TimeData.amount, objData:TimeData.objArr} as PointSet)
                  }
                  return null
                }).filter(e => e)
              }
            })
          }
        })
        return [ScaleDatas, prPigDatas]
      })
    }
    return RepScaleDatas
  }, [ConWaterList, ReportsOperations.Operations])
  //#endregion

  return (
    <DataContext.Provider 
      value={
        Report_Datas.map((ReportDatas, index) => ({
          ReportId: Reports[index],
          ReportName: ReportDatas.Name,
      
          ReportStart: ReportDatas.Start,
          ReportEnd: ReportDatas.End,
          Graph: ReportDatas.Graph,
          Pens: ReportDatas.Pens,
          Autos: ReportDatas.Autos,
          Images: ReportDatas.Images,
      
          Operations: {
            List: !isEmpty(ReportsOperations.Operations[index])?ReportsOperations.Operations[index]:[],
            Deaths: ReportsOperations.Deaths,
            Added: ReportsOperations.Added,
            Sold: ReportsOperations.Sold
          },
      
          Weights: !isEmpty(ReportsWeights[index])?ReportsWeights[index]:[],
          
          Feeds: FeedsLists&&FeedsLists[index]?FeedsLists[index][0]:[],
          FCR: FeedsLists&&FeedsLists[index]?FeedsLists[index][1]:[],
          Scales: Scales_ActivityList?Scales_ActivityList[index][0]:[],
          Activities: Scales_ActivityList?Scales_ActivityList[index][1]:[],
          Temps: TempsList[index],
          Waters: WatersList&&WatersList[index]?WatersList[index][0]:[],
          WatersPrPig: WatersList&&WatersList[index]?WatersList[index][1]:[],
          Fetchings: index===0?[ConFeedFetching]:undefined
        })
      )
    }
    >
      {children}
    </DataContext.Provider>
  )
}

export type DataPropHandle = {
  GetValues: () => any[]
}
interface Feed_Element_Props {ActiveReport_Data:any, Feeds_Data?:Record<string, {time: number;amount: number;datas: FeedInfo[];ExtraTime: number;}>|undefined, Images_Data?:any}
const Feed_Element:React.ForwardRefRenderFunction<DataPropHandle, Feed_Element_Props> = ({ActiveReport_Data, Feeds_Data, Images_Data}, ref) => {
  const DataRows = useMemo(() =>{
    var Rep_Feeds  = Feeds_Data?Feeds_Data:{}
    var Feeds = Object.values(Rep_Feeds).reduce((previousValue:any[], currentValue) => {
      if (currentValue && currentValue.datas && Array.isArray(currentValue.datas) && currentValue.datas.length) {
        return previousValue.concat(currentValue.datas)
      } else return previousValue
    },[])
    if (!isEmpty(Images_Data)) {
      Feeds = Feeds.concat(
        Object.values(Images_Data).reduce((previousValue:any[], currentValue) => {
          if (currentValue && currentValue.datas && Array.isArray(currentValue.datas) && currentValue.datas.length) {
            return previousValue.concat(currentValue.datas)
          } else return previousValue
        },[])
      )
    }
    return Feeds
  }, [Feeds_Data, Images_Data])

  const OutputCSV_Data = useCallback(() => GenerateCSV_Data(ActiveReport_Data&&ActiveReport_Data.ReportName?ActiveReport_Data.ReportName:"FeedsReport", [DataRows]), [DataRows])
  useImperativeHandle(ref, ()=>({
    GetValues() { return [DataRows] }
  }))
  return <FeedSilosList FeedsList={DataRows?DataRows:[]} isFetching={ActiveReport_Data&&ActiveReport_Data.Fetchings?.some(e => e)} GenerateCSV_Data={() => OutputCSV_Data()}/>
}
const Feed_View = forwardRef(Feed_Element)

interface Operation_Element_Props {ActiveReport_Data:any, Images_Data?:any}
const Operation_Element:React.ForwardRefRenderFunction<DataPropHandle, Operation_Element_Props> = ({ActiveReport_Data, Images_Data}, ref) => {
  const DataRows = useMemo(() =>
    (ActiveReport_Data&&ActiveReport_Data.Operations&&ActiveReport_Data.Operations.List?ActiveReport_Data.Operations.List.flat(1).filter(Operation => Operation && (!Operation.edited && !Operation.deleted)).flat(1):[])
    .concat(
      Images_Data?Images_Data.map((Report, index) => {
        return Report.Operations.List?.flat(1).filter(Operation => Operation && (!Operation.edited && !Operation.deleted))
      }).flat(1):[]
    )
  ,[ActiveReport_Data?.Operations, Images_Data])
  
  const OutputCSV_Data = useCallback(() => GenerateCSV_Data(ActiveReport_Data&&ActiveReport_Data.ReportName?ActiveReport_Data.ReportName:"FeedsReport", undefined, undefined, undefined, undefined, undefined, DataRows), [DataRows])
  useImperativeHandle(ref, ()=>({
    GetValues() { return DataRows }
  }))
  return <OperationsList OperationsList={DataRows?DataRows:[]} GenerateCSV_Data={() => OutputCSV_Data()}/>
  //GenerateCSV_Data={() => GenerateCSV_Data(ActiveReport_Data.ReportName, undefined, undefined, undefined, undefined, undefined, Datas)}/>
}
const Operation_View = forwardRef(Operation_Element) 

interface Weight_Element_Props {ActiveReport_Data:any, Images_Data?:any}
const Weight_Element:React.ForwardRefRenderFunction<DataPropHandle, Weight_Element_Props> = ({ActiveReport_Data, Images_Data}, ref) => {
  const { t } = useTranslation()
  const [css] = useStyletron()
  const { Layout } = useTheme()
  const isPrinting  = useDetectPrint()
  //const firebase = useFirebase()

  const [DisplayList, setDisplayList] = useState(true)
  
  const DataRows:{pen:any,data:any, striped:boolean}[] = useMemo(() => {
    var Datas = (ActiveReport_Data&&ActiveReport_Data.Weights?ActiveReport_Data.Weights.map((Data, index) => {
        var PenId = ActiveReport_Data.Pens[index].Id
        var Operations = ActiveReport_Data.Operations?ActiveReport_Data.Operations.List.filter(e => e.pen+'' === PenId+''):[]
        var ocr = ActiveReport_Data.Pens.slice(0,index +1).filter(e2 => e2.Id === PenId).length
        var res = Calc_Weights_Tabel({Weights:Data, Comments:Operations})
        const striped = index % 2 === 0
        return {pen:PenId + (ocr>1?'_'+(ocr-1):""), data:res, striped:striped}
      }):[])
      .concat(
        Images_Data?Images_Data.map((Report, index) => {
          return Report.Weights?.map((Data, index) => {
            var PenId = `${Report.Pens[index].Id}`
            var ocr = Report.Pens.slice(0,index +1).filter(e2 => e2.Id+'' === PenId).length
            var res = Calc_Weights_Tabel({Weights:Data, Comments:Report.Operations.List.filter(e => e.pen+'' === PenId+'')})
            const striped = index % 2 === 0
            var PenName = `${PenId}${(ocr>1?'_'+(ocr-1):"")} : ${Report.ReportName?Report.ReportName:Report.ReportId}`
            return {pen:PenName, data:res, striped:striped}
          })
        }).flat(1):[]
      )
    return Datas
  }, [ActiveReport_Data?.Weights, Images_Data])

  const OutputCSV_Data = useCallback(() => GenerateCSV_Data(ActiveReport_Data&&ActiveReport_Data.ReportName?ActiveReport_Data.ReportName:"WeightReport", undefined, undefined, undefined, undefined, DataRows), [DataRows])
  useImperativeHandle(ref, ()=>({
    GetValues() { return DataRows }
  }))
  //TODO: Make list object like feed and activity
  return (
    <View style={[Layout.fullWidth, Layout.colVCenter, {marginBottom:10, marginTop:20}]}>
      <View style={[Layout.row, Layout.fillRow, Layout.fullWidth, {height:70, minHeight:'70px', justifyContent:'center', alignItems:'center', maxWidth:600}]}>
        <ContentProp Columns={1} maxWidth={600-30} Datas={[[{value:t('Pen_Display.ControlWeights_Title'), CSV_ABLE:OutputCSV_Data!==undefined}]]} Backcolor={true}Actions={[() => {
          //if (FeedRef && FeedRef.current) console.log(FeedRef.current.getRows())
          if (OutputCSV_Data) OutputCSV_Data()
        }]}/>
        {!isPrinting&&(
          <Checkbox checked={DisplayList} onChange={() => setDisplayList(!DisplayList)}
            overrides={{
              Root: { style: ({ }) => ({
                alignSelf: "center", alignItems: "center",
                minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
              })},
              Label: { style: ({ }) => ({
                justifyContent:'flex-end', paddingBottom:'5px',
                minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
              })}
            }}
          />
        )}
      </View>
      {DisplayList?DataRows.length?(
        <View style={[Layout.fullWidth, {maxWidth:800, height:'fit-content'}, !isPrinting?{maxHeight:400}:{}]}>
          <StyledTable role="grid" $gridTemplateColumns="auto 15% 20% 20% 20%">
            <div role="row" className={css({ display: "contents" })}>
              <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('WeightList.Col1_1')}</StyledHeadCell>
              <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('WeightList.Col1_2')}</StyledHeadCell>
              <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('WeightList.Col1_3')}</StyledHeadCell>
              <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('WeightList.Col1_4')}</StyledHeadCell>
              <StyledHeadCell className={css({ maxHeight: "35px" })}>{t('WeightList.Col1_5')}</StyledHeadCell>
            </div>
            { DataRows.map(e => <Row key={e.pen} Pen={e.pen} row={e.data} striped={e.striped} printing={isPrinting}/>) }
          </StyledTable>
        </View>
      ):(
        <View style={[Layout.fullWidth, {maxWidth:800, minHeight:25, maxHeight:25}]} >
          <Text>{t('Report_Display.NoWeight')}</Text>
        </View>
      ):(<></>)}
    </View>
  )
}
const Weight_View = forwardRef(Weight_Element) 

const Settings_Editor = ({ActiveReport_Data, Images_Data, extraDaySettings, updateExtraDaySettings, changeDaySettings, updateChangeDaySettings}:
  {ActiveReport_Data:any, Images_Data:any[], extraDaySettings:{[key:string]:number}, updateExtraDaySettings:React.Dispatch<React.SetStateAction<{[key:string]:number}>>,changeDaySettings:{[key:string]:TimeSpan}, updateChangeDaySettings:React.Dispatch<React.SetStateAction<{[key:string]:TimeSpan}>>}) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters, Colors } = useTheme()
  
  const firebase = useFirebase()
  const activefarm = useAppSelector(state => state.activeData.farm)
  const Settings = useAppSelector<SystemData|undefined>(({firebase: {data}}) => data&&data.system?data.system:undefined)

  const activeReport = useAppSelector<string>(({activeData: {report}}) => report?(report.id?report.id:report):"")
  const [SelectedSetter, setSelectedSetter] = useState({label:'---',id:'---'})
  const Selectable = useMemo<{label:string,id:string,auto?:boolean, image?:boolean}[]>(() => {
    var Sa = [{label:'---',id:'---'}]
    if (ActiveReport_Data) Sa = Sa.concat(ActiveReport_Data.Pens.map((e,i) => {
      var ocr = ActiveReport_Data.Pens.slice(0,i +1).filter(e2 => e2.Id === e.Id).length
      return({
        label:(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+e.Id + (ocr>1?'_'+(ocr-1):""), 
        id:'Pen_'+i
      })
    }))
    .concat(ActiveReport_Data.Autos.map((e,i) => {
      var ocr = ActiveReport_Data?.Autos.slice(0,i +1).filter(e2 => e2.name === e.name).length
      return ({label:e.name + (ocr>1?'_'+(ocr-1):""), id:'Auto_' + i, auto:true})
    }))
    //.concat(ActiveReport_Data.Images.map((e,i) => ({label:e.name, id:'Image_' + i, image:true})))
    .concat(Images_Data.map((e,i) => ({label:e.ReportName, id:'Image_' + i, image:true})))
    .concat(ActiveReport_Data.Graph?[{label:ActiveReport_Data.Graph.name, id:'Template_0'}]:[])
    return Sa
  }, [ActiveReport_Data?.Pens, ActiveReport_Data?.Autos, /*ActiveReport_Data?.Images,*/ Images_Data])

  const SelectedData = useMemo(() => {
    var ID = SelectedSetter.id.split('_')
    if (ID && ID.length === 2){
      var Pen = ID[0]==="Template"?{}:
                ID[0]==="Pen"&&ActiveReport_Data?ActiveReport_Data.Pens[parseInt(ID[1])]:
                ID[0]==="Auto"&&ActiveReport_Data?ActiveReport_Data.Autos[parseInt(ID[1])]:
                //ID[0]==="Image"&&ActiveReport_Data?ActiveReport_Data.Images[parseInt(ID[1])]:
                ID[0]==="Image"&&Images_Data?Images_Data[parseInt(ID[1])]:
                null
      if (Pen) return Pen
    }
    return undefined
  }, [SelectedSetter])

  const [ViewPeriod, updateViewPeriod] = useState<TimeSpan>({start:(ActiveReport_Data?.ReportStart||0), end:(ActiveReport_Data?.ReportEnd||0)})
  const [ModalText, setModalText] = React.useState<null|{header:string, text:string}>(null)

  const SaveSettings = () => {
    if (!isDemoFarm(activefarm) && SelectedData) {
      var path = 'farms_data/' + activefarm + '/reports/' + activeReport + '/'
      var ID = SelectedSetter.id.split('_')
      var indexId = parseInt(ID[1])
      path += ID[0]==="Pen"? 'pens/' +  indexId:
              ID[0]==="Auto"? 'autopigs/' + indexId:
              //ID[0]==="Image"? 'images/' + indexId:
              ''
      
      var PenSetting = SelectedData.startday?SelectedData.startday:0
      firebase.update(path, {
        startdate: (changeDaySettings&&changeDaySettings[SelectedSetter.id])?changeDaySettings[SelectedSetter.id].start:ViewPeriod.start,
        enddate: (changeDaySettings&&changeDaySettings[SelectedSetter.id])?changeDaySettings[SelectedSetter.id].end:ViewPeriod.end,
        startday: (PenSetting + (extraDaySettings&&extraDaySettings[SelectedSetter.id]?extraDaySettings[SelectedSetter.id]:0))
      }).then(e => {
        updateExtraDaySettings(old => {
          var _new = {...old}
          _new[SelectedSetter.id] = 0
          return _new
        })
      }, e2=>{})
    }
  }

  return (
    <View style={[Layout.row, {alignItems:'flex-start', minWidth:'fit-content'}]}>
      <View style={[Layout.fill, Layout.row, Common.border, {flexWrap:'wrap', maxWidth:'max-content'}]}>
        <Combobox
          size={SIZE.mini}
          value={SelectedSetter.label}
          onChange={(nextValue, ele:any) => {
            setSelectedSetter(ele)
            var start = ActiveReport_Data.ReportStart
            var end = ActiveReport_Data.ReportEnd
            if (changeDaySettings && changeDaySettings[ele.id]) {
              start = changeDaySettings[ele.id].start
              end = changeDaySettings[ele.id].end
            } else {
              var ID = ele.id.split('_')
              var Pen = ID[0]==="Template"?{}:
                        ID[0]==="Pen"&&ActiveReport_Data?ActiveReport_Data.Pens[parseInt(ID[1])]:
                        ID[0]==="Auto"&&ActiveReport_Data?ActiveReport_Data.Autos[parseInt(ID[1])]:
                        //ID[0]==="Image"&&ActiveReport_Data?ActiveReport_Data.Images[parseInt(ID[1])]:
                        ID[0]==="Image"&&Images_Data?Images_Data[parseInt(ID[1])]:
                        undefined
              if (Pen && Pen.startdate) start = Pen.startdate
              if (Pen && Pen.enddate) end = Pen.enddate
            }
            updateViewPeriod({
              start:start, 
              end:end
            })
          }}
          options={
            Selectable
          }
          mapOptionToString={option => option.label}
          overrides={{
            Root: {style: ({ $theme }) => ({
              height: '30px', width: '100px', minWidth:'100px',
              outline:'1px solid black',
              alignSelf:'center', marginRight:10
            })}
          }}
        />
        <View style={[Layout.fill, Layout.row, {minWidth:'fit-content', height:85, alignSelf:'center', marginTop:'-10px'}]}>
          <View style={[Layout.colCenter, {marginTop:10, justifyContent:'center', minWidth:40 }]}> 
            <TouchableOpacity style={[{}]} onPress={() => {
              var ID = SelectedSetter.id.split('_')
              if (ID && ID.length === 2){
                var Pen = ID[0]==="Template"?{}:
                          ID[0]==="Pen"&&ActiveReport_Data?ActiveReport_Data.Pens[parseInt(ID[1])]:
                          ID[0]==="Auto"&&ActiveReport_Data?ActiveReport_Data.Autos[parseInt(ID[1])]:
                          //ID[0]==="Image"&&ActiveReport_Data?ActiveReport_Data.Images[parseInt(ID[1])]:
                          ID[0]==="Image"&&Images_Data?Images_Data[parseInt(ID[1])]:
                          null
                if (Pen) {
                  var PenSetting = Pen.startday?Pen.startday:0
                  updateExtraDaySettings(old => {
                    var _new = {...old}
                    if (_new[SelectedSetter.id]) _new[SelectedSetter.id] -= 7
                    else _new[SelectedSetter.id] = PenSetting - 7
                    return _new
                  })
                }
              }
            }}>
              <ChevronLeft title='-7 days' size={30}/>
            </TouchableOpacity>
          </View>
          <Labelled_Input disabled={!SelectedSetter||SelectedSetter.id==="---"}
            value={
              (SelectedData&&SelectedData.startday?SelectedData.startday:0) + (extraDaySettings&&extraDaySettings[SelectedSetter.id]?extraDaySettings[SelectedSetter.id]:0)
            }
            label={t('Report_Display.StartD')}
            placeholder={""} type={"number"} 
            maxWidth={120} minWidth={100}
            onChange={(text, num) => {
              if (SelectedData) {
                var PenSetting = SelectedData.startday?SelectedData.startday:0
                if (text === "") updateExtraDaySettings(old => {
                  var _new = {...old}
                  _new[SelectedSetter.id] = -PenSetting
                  return _new
                })
                else if (!isNaN(num)) updateExtraDaySettings(old => {
                  var _new = {...old}
                  _new[SelectedSetter.id] = num - PenSetting
                  return _new
                })
              }
            }}
          />
          <View style={[Layout.colCenter, {marginTop:10, justifyContent:'center', minWidth:40 }]}> 
            <TouchableOpacity style={[{}]} onPress={() => {
              var ID = SelectedSetter.id.split('_')
              if (ID && ID.length === 2){
                var Pen = ID[0]==="Template"?{}:
                          ID[0]==="Pen"&&ActiveReport_Data?ActiveReport_Data.Pens[parseInt(ID[1])]:
                          ID[0]==="Auto"&&ActiveReport_Data?ActiveReport_Data.Autos[parseInt(ID[1])]:
                          //ID[0]==="Image"&&ActiveReport_Data?ActiveReport_Data.Images[parseInt(ID[1])]:
                          ID[0]==="Image"&&Images_Data?Images_Data[parseInt(ID[1])]:
                          null
                if (Pen) {
                  var PenSetting = Pen.startday?Pen.startday:0
                  updateExtraDaySettings(old => {
                    var _new = {...old}
                    if (_new[SelectedSetter.id]) _new[SelectedSetter.id] += 7
                    else _new[SelectedSetter.id] = PenSetting + 7
                    return _new
                  })
                }
              }
            }}>
              <ChevronRight title='+7 days' size={30}/>
            </TouchableOpacity>
          </View>
        </View>
        {ActiveReport_Data.ReportName!=="Combined-View" && SelectedSetter&&SelectedSetter.id&&["Pen","Auto"].some(e => e === SelectedSetter.id.split('_')[0]) && (
          <View style={[Layout.fill, Layout.column, Gutters.tinyV_Margin, {maxWidth:'fit-content', minWidth:'min-content', alignItems:'flex-end', marginBottom:5}]}>
            <Datetime_Picker MoveArrows={false} value={ViewPeriod} MarkDates={SelectedData&&SelectedData.startdate?[{starttime:SelectedData.startdate, endtime:SelectedData.enddate, color:Colors.AgriLightblue}as Periodes]:[]}
              onValueChange={(time) => {
                if (SelectedData) {
                  updateChangeDaySettings(old => {
                    var _new = {...old}
                    if (time) _new[SelectedSetter.id] = time
                    else delete _new[SelectedSetter.id]
                    return _new
                  })
                }
                if (time) updateViewPeriod(time)
              }}
            />
          </View>
        )}
        {ActiveReport_Data.ReportName!=="Combined-View" && SelectedSetter&&SelectedSetter.id&&["Pen","Auto","Image"].some(e => e === SelectedSetter.id.split('_')[0]) && (
          <View style={[Layout.fill,{width:100, alignSelf:'center', marginLeft:10}]}>
            <Button Contexts={[t('ActionButtons.Save')]} maxWidth={100} Action={()=>{
              if (!isDemoFarm(activefarm)) setModalText({text:'You are about to save current changed setting to the report,\n this will overwrite the reports current settings,\n are you sure?', header:'Change report settings ?'})
            }}/>
          </View>
        )}
      </View>
      <Modal onClose={() => setModalText(null)} isOpen={ModalText?true:false} unstable_ModalBackdropScroll={true}>
        <FocusOnce>
          <ModalHeader>{ModalText?.header}</ModalHeader>
        </FocusOnce>
        <ModalBody>
          {ModalText?.text}
        </ModalBody>
        <ModalFooter>
          <ModalButton autoFocus onClick={() => {
            SaveSettings()
            setModalText(null)}
          }>
            {t('ActionButtons.Submit_btn')}
          </ModalButton>
          <ModalButton autoFocus onClick={() => setModalText(null)}>
            {t('ActionButtons.Cancel')}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </View>
  )
}

const CalculateGrowthSet = (DataSet:DataTemplate) => {
  var TempVal:PointSet|undefined = undefined
  var Growths = DataSet.sort((a,b) => a.x === b.x?0:(a.x > b.x? 1 : -1)).reduce((res, cur:PointSet, index) => {
    if (TempVal) {
      var value = (cur.y - TempVal.y) *1000
      //if (value < 0) value = 0
      var days = (cur.x - TempVal.x) / 86400000
      var resVal = value / days
      if (!isNaN(resVal) && isFinite(resVal)) res.push({...cur, y:resVal})
      else console.log("??", DataSet, cur, TempVal, resVal)
    }
    TempVal = cur
    return res
  },[] as DataTemplate)
  return Growths
}

const Report_Display = () => {
  const { t } = useTranslation()
  //const [css] = useStyletron()
  const { Common, Layout, Gutters, Images } = useTheme()
  
  const isAppView = useisAppView()
  
  //const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  //const activefarm = useAppSelector(state => state.activeData.farm)
  const Settings = useAppSelector<SystemData|undefined>(({firebase: {data}}) => data&&data.system?data.system:undefined)
  const activeReport = useAppSelector<string>(({activeData: {report}}) => report?(report.id?report.id:report):"")
  
  const ReportDatas = useContext(DataContext)
  const ActiveReport_Data = !isEmpty(ReportDatas)?ReportDatas[0]:null //useContext(DataContext)
  const Report_Images = !isEmpty(ReportDatas)?ReportDatas.slice(1):[] //useContext(DataContext2)

  //States
  const [extraDaySettings, updateExtraDaySettings] = useState<Record<string,number>>({})
  const [changeDaySettings, updateChangeDaySettings] = useState<Record<string,TimeSpan>>({})
  const CurveViewRef = useRef<CurveViewerPropHandle>(null)
  const FeedEle_ViewRef = useRef<DataPropHandle>(null)
  const WeightEle_ViewRef = useRef<DataPropHandle>(null)
  const OperationEle_ViewRef = useRef<DataPropHandle>(null)
  
  const [startString, age] = useMemo(() => {
    if (ActiveReport_Data?.ReportStart) {
      var start = new Date(ActiveReport_Data?.ReportStart)
      var text = start.getDate() + "-" + (start.getMonth()+1) + "-" + start.getFullYear()
      var days = ''+ Math.floor((((ActiveReport_Data?.ReportEnd||ActiveReport_Data?.ReportStart)-ActiveReport_Data?.ReportStart)/UnixDayTime)) 
      return [text, days]
    } else return ["", ""]
  }, [ActiveReport_Data?.ReportStart, ActiveReport_Data?.ReportEnd])

  //#region CurveGenerators
  const GetFilterVals = (ActiveReport_Data, _changeDaySettings, _extraDaySettings, SearchList, SearchVal, occurrence=undefined) => {
    var startTime = 0
    var endTime = 0
    var ExtraTime = 0
    if (ActiveReport_Data) {
      startTime = ActiveReport_Data.ReportStart
      endTime = ActiveReport_Data.ReportEnd

      console.log("test", SearchList)
      var ReportPenData = SearchList?SearchList.findIndex((e, i) => {
        if (e.Id + '' === SearchVal && (occurrence === undefined || i+1 ===occurrence)) return true
      }):undefined
      if (ReportPenData !== undefined) {
        if (SearchList[ReportPenData] && SearchList[ReportPenData].startdate) startTime = SearchList[ReportPenData].startdate
        if (SearchList[ReportPenData] && SearchList[ReportPenData].enddate) endTime = SearchList[ReportPenData].enddate

        var FullName = (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ReportPenData
        if (_changeDaySettings && _changeDaySettings[FullName]) {
          if (_changeDaySettings[FullName].start) startTime = _changeDaySettings[FullName].start
          if (_changeDaySettings[FullName].end) endTime = _changeDaySettings[FullName].end
        }
        if (SearchList[ReportPenData] && SearchList[ReportPenData].startday) ExtraTime = (SearchList[ReportPenData].startday||0) * UnixDayTime
        if (_extraDaySettings && _extraDaySettings[FullName]) ExtraTime += _extraDaySettings[FullName] * UnixDayTime
      }
    }
    return {startTime, endTime, ExtraTime}
  }

  const WeightCurves = useMemo<(DataSet|null)[]>(() => 
    (ActiveReport_Data&&ActiveReport_Data.Weights)?ActiveReport_Data.Weights.map((Data, PenIndex) => {
      var ReportPenData = ActiveReport_Data?.Pens[PenIndex]
      if (Data && ReportPenData) {
        var ocr = ActiveReport_Data?.Pens.slice(0,PenIndex +1).filter(e => e.Id === ReportPenData.Id).length
        var {startTime, endTime, ExtraTime} = GetFilterVals(ActiveReport_Data, changeDaySettings, extraDaySettings, ActiveReport_Data.Pens, ReportPenData.Id, ocr)
        
        var SetData = (Data.filter(Weight => Weight && (!Weight.edited && !Weight.deleted) && (Weight.time >= startTime && Weight.time <= endTime)).map((Weight:any) => {
          return ({x:Weight.time, y:Weight.count?Weight.weight/Weight.count:Weight.weight} as PointSet)
        }) as DataTemplate)

        var prename = (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ ReportPenData.Id + (ocr>1?'_'+(ocr-1):"")
        
        console.log(startTime, endTime, ExtraTime, ocr, ReportPenData.Id, prename)
        var WeightData = [({
          //color: getUniqueColor(PenIndex+2),
          name: prename ,
          form:'Weight',
          type:ViewForms.LINE,
          startPoint: ExtraTime,
          data: SetData
        } as DataSet)]
        if (SetData && SetData.length) {
          var GrowthData = CalculateGrowthSet(SetData)
          if (GrowthData && GrowthData.length) {
            var StartJump = GrowthData[0].x - SetData.sort((a,b) => a.x === b.x?0:(a.x > b.x? 1 : -1))[0].x
            WeightData.push({...WeightData[0],
              name: prename + '_' + t('GraphViewer.Growth'),
              startPoint: ExtraTime + StartJump,
              form:'Growth', type:ViewForms.LINE,
              data: GrowthData
            })
          }
        }
        return WeightData
      } else return null
    }).flat(1).filter(e => e):[]
  , [ActiveReport_Data?.Weights, extraDaySettings])
  const OperationsCurve = useMemo<DataSets>(() => {
    if (ActiveReport_Data&&ActiveReport_Data.Operations&&ActiveReport_Data.Operations.List) {
      var res = ActiveReport_Data.Operations.List.flat(1).filter(e => e && (!e.edited && !e.deleted)).reduce((previousValue, currentValue) => {
        //var PenIndex = ActiveReport_Data?.Pens.findIndex(e => e.Id === currentValue.pen+'')
        if (currentValue.penIndex !== undefined && currentValue.penIndex >= 0) {
          var PenIndex = currentValue.penIndex
          var ReportPenData = ActiveReport_Data.Pens[PenIndex]
          
          var ocr = ActiveReport_Data?.Pens.slice(0,PenIndex +1).filter(e => e.Id === ReportPenData.Id).length
          var {startTime, endTime, ExtraTime} = GetFilterVals(ActiveReport_Data, changeDaySettings, extraDaySettings, ActiveReport_Data.Pens, ReportPenData.Id, ocr)
          
          
          console.log(startTime, endTime, ExtraTime, ocr, ReportPenData.Id)

          if (currentValue.time >= startTime && currentValue.time <= endTime) {
            if (previousValue[PenIndex] && previousValue[PenIndex].data) {
              var Data = previousValue[PenIndex].data
              Data.push(({x:currentValue.time, y:'min', objData:currentValue}))
              previousValue[PenIndex].data = Data
            }
            else {
              return [...previousValue.slice(0, PenIndex), {
                color:'#000',
                name:t('GraphViewer.NoteLabel'),
                form:'Notes', type:ViewForms.SCATTER,
                active:true, startPoint: ExtraTime,
                data: [{x:currentValue.time, y:'min', objData:currentValue}]
              },
              ...previousValue.slice(PenIndex)]
            }
          }
        }
        return previousValue
      }, [])
      return res
    } else return []
  }, [ActiveReport_Data?.Operations.List, extraDaySettings])
  const TemplateCurve = useMemo<DataSets>(() => {
    var TemPlate:DataSets = []
    if (ActiveReport_Data && ActiveReport_Data.Graph) {
      var TempDatas:any = Object.entries(ActiveReport_Data?.Graph.data).map(([_index,Data]) => {
        if (Data && ActiveReport_Data&&(!ActiveReport_Data.ReportEnd || ActiveReport_Data.ReportStart + UnixDayTime <= ActiveReport_Data.ReportEnd)) {
          var index = parseInt(_index)
          var time = new Date(AccumulateTime(new Date(ActiveReport_Data.ReportStart), index)).getTime()
          if (extraDaySettings['Template_0']) time += (extraDaySettings['Template_0'] * UnixDayTime)
          if (time >= ActiveReport_Data.ReportStart && time <= ActiveReport_Data.ReportEnd + UnixDayTime) return({ x:time, y:Data } as PointSet)
          return null
        }
      }).filter(e => e)

      if (TempDatas && TempDatas.length) {
        TemPlate.push({
          color:'#000',
          name:ActiveReport_Data?.Graph.name,
          form:'Weight',
          type:ViewForms.LINE,
          data:TempDatas
        } as DataSet)

        var Datas = TempDatas.sort((a,b) => a.x === b.x?0:(a.x > b.x? 1 : -1))
        TemPlate.push({
          color:'#000',
          name:ActiveReport_Data?.Graph.name + '_' + t('GraphViewer.Growth'),
          startPoint: (Datas[1].x - Datas[0].x),
          form:'Growth', type:ViewForms.LINE,
          data:CalculateGrowthSet(TempDatas)
        } as DataSet)
      }
    }
    return TemPlate
  }, [ActiveReport_Data?.Graph, extraDaySettings])

  const FilterFeedData = (AC_Report_Data, DataObj: Record<string,{time:number,amount:number,datas:FeedInfo[]}>) => {
    var FeedDataRes:Record<string, {time: number;amount: number;datas: FeedInfo[]; ExtraTime:number;}> = {}
    Object.entries(DataObj).forEach(([PenTag, data],index) => {
      var {startTime, endTime, ExtraTime} = GetFilterVals(AC_Report_Data, changeDaySettings, extraDaySettings, AC_Report_Data.Pens, PenTag)
      var PenSumAmount = 0
      var datas = (data&&data.datas)?
        data.datas.map((Feed) => {
          var sumamount = 0
          var siloList:SiloInfo[] = []
          if (Feed.silolist && !isEmpty(Feed.silolist)) {
            Feed.silolist.forEach(Silo => {
              if (Silo.silono && Silo.amount) {
                if (Feed.timestamp >= startTime && Feed.timestamp <= endTime) {
                  sumamount += Silo.amount
                  siloList.push(Silo)
                }
              }
            })
          }
          if (siloList && !isEmpty(siloList)) {
            PenSumAmount += sumamount
            return {
              ...Feed,
              sumamount:sumamount,
              silolist:siloList
            } as FeedInfo
          }
          return undefined
        }).filter(e => e):undefined
      if (datas && !isEmpty(datas)) {
        FeedDataRes[PenTag] = ({ time:data.time,
          amount: PenSumAmount, datas: datas,
          ExtraTime: ExtraTime
        })
      }
    })
    if (FeedDataRes && !isEmpty(FeedDataRes)) return FeedDataRes
  }
  const {FeedFiltered, FCRFiltered, FeedCurve, FCRCurve} = useMemo(() => {
    var FeedFiltered:Record<string,{time:number,amount:number,datas:FeedInfo[],ExtraTime:number}>|undefined = undefined
    var FCRFiltered:Record<string,{time:number,amount:number,datas:FeedInfo[],ExtraTime:number}>|undefined = undefined
    if (ActiveReport_Data) {
      if (ActiveReport_Data.Feeds) FeedFiltered = FilterFeedData(ActiveReport_Data, ActiveReport_Data.Feeds)
      if (ActiveReport_Data.FCR) FCRFiltered = FilterFeedData(ActiveReport_Data, ActiveReport_Data.FCR)
    }

    var FeedCurve:DataSets = []
    var FCRCurve:DataSets = []
    if (FeedFiltered) {
      FeedCurve = Object.entries(FeedFiltered).map(([PenTag, data],index) => {
        return ({
          color: getUniqueColor(index+11),
          name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+PenTag+'_'+t('GraphViewer.Feed'),
          form: 'Feed',
          type:ViewForms.BAR,
          startPoint: data&&data.ExtraTime?data.ExtraTime:0,
          data: (data&&data.datas?
            data.datas.map((Feed) => ({
              x:(Feed.timestamp), y:((Feed.sumamount?Feed.sumamount/1000:0)), objData:Feed
            } as PointSet))
          :undefined)
        } as DataSet)
      })
    }
    if (FCRFiltered) {
      FCRCurve = Object.entries(FCRFiltered).map(([PenTag, data],index) => {
        return ({
          color: getUniqueColor(index+12),
          name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+PenTag+'_'+t('GraphViewer.FCR'),
          form: 'FCR',
          type:ViewForms.LINE,
          startPoint: data&&data.ExtraTime?data.ExtraTime:0,
          data: (data&&data.datas?
            data.datas.map((Feed) => ({
              x:(Feed.timestamp), y:((Feed.sumamount?Feed.sumamount/1000:0)), objData:Feed
            } as PointSet))
          :undefined)
        } as DataSet)
      })
    }
    return {FeedFiltered, FCRFiltered, FeedCurve, FCRCurve}
  }, [ActiveReport_Data?.Feeds, ActiveReport_Data?.FCR, extraDaySettings, changeDaySettings])

  const CurveGeneration = (Report_Data, _changeDaySettings, _extraDaySettings, List_Data:any[], preDataSet:DataSet):DataSets => {
    var SetDatas:DataSets = [] = []
    if (Report_Data&&List_Data) {
      List_Data.forEach((Datas, AutoIndex) => {
        var Auto = Report_Data.Autos[AutoIndex]
        var startTime = Auto&&Auto.startdate?Auto.startdate:Report_Data.ReportStart
        var endTime = Auto&&Auto.enddate?Auto.enddate:Report_Data.ReportEnd
        if (_changeDaySettings && _changeDaySettings['Auto_'+AutoIndex]) {
          startTime = _changeDaySettings['Auto_'+AutoIndex].start
          endTime = _changeDaySettings['Auto_'+AutoIndex].end
        }
        var ExtraTime = Auto.startday?(Auto.startday * UnixDayTime):0
        if (_extraDaySettings && _extraDaySettings['Auto_'+AutoIndex]) ExtraTime += _extraDaySettings['Auto_'+AutoIndex] * UnixDayTime
        
        var ocr = Report_Data?.Autos.slice(0,AutoIndex +1).filter(e => e.name === Auto.name).length
        var DataName = (preDataSet.prename?preDataSet.prename+'_':'') + (preDataSet.name?Auto.name+'_'+preDataSet.name:Auto.name) + (ocr>1?'_'+(ocr-1):"")
        if (preDataSet.color) preDataSet.color = getUniqueColor(AutoIndex+preDataSet.color)

        var SetData = (Datas.filter((Data: { x: any; y: any; objData: any }) => Data.x>=startTime && Data.x<=endTime).map((Data: { x: any; y: any; objData: any }) => { 
          return ({x:Data.x, y:Data.y, objData:Data.objData} as PointSet)
        }) as DataTemplate)

        SetDatas.push({...preDataSet,
          name: DataName,
          startPoint: (preDataSet.startPoint?preDataSet.startPoint:0) + ExtraTime,
          data: SetData
        })
        if (preDataSet.form === 'Weight' && SetData && SetData.length) {
          var PreDataName = t('GraphViewer.Growth')
          var SortedSet = SetData.sort((a,b) => a.x === b.x?0:(a.x > b.x? 1 : -1))
          SetDatas.push({...preDataSet,
            name: (preDataSet.prename?preDataSet.prename+'_':'') + (preDataSet.name?Auto.name+'_'+PreDataName:Auto.name) + (ocr>1?'_'+(ocr-1):""),
            startPoint: (preDataSet.startPoint?preDataSet.startPoint:0) + ExtraTime + (SortedSet[1].x - SortedSet[0].x),
            form:'Growth', type:ViewForms.LINE,
            data:CalculateGrowthSet(SetData)
          })
        }
      })
    }
    return SetDatas
  }
  const ScaleCurve = useMemo<any[]>(() => {
    return ActiveReport_Data?CurveGeneration(ActiveReport_Data, changeDaySettings, extraDaySettings, ActiveReport_Data.Scales, ({
      name: t('GraphViewer.Scale'),
      form: 'Weight', type:ViewForms.LINE
    } as DataSet)):[]
  }, [ActiveReport_Data?.Scales, extraDaySettings, changeDaySettings])
  const ActivityCurve = useMemo<any[]>(() => 
    ActiveReport_Data?CurveGeneration(ActiveReport_Data, changeDaySettings, extraDaySettings, ActiveReport_Data.Activities, ({
      name: t('GraphViewer.Activity'),
      form: 'Activity', type:ViewForms.LINE
    } as DataSet)):[]
  , [ActiveReport_Data?.Scales, extraDaySettings, changeDaySettings])
  const TempCurve = useMemo<any[]>(() => 
    ActiveReport_Data?CurveGeneration(ActiveReport_Data, changeDaySettings, extraDaySettings, ActiveReport_Data.Temps, ({
      color: 38, name: t('GraphViewer.Temp'),
      form: 'Temp', type:ViewForms.LINE
    } as DataSet)):[]
  , [ActiveReport_Data?.Temps, extraDaySettings, changeDaySettings])
  const WaterCurve = useMemo<any[]>(() => 
    ActiveReport_Data?CurveGeneration(ActiveReport_Data, changeDaySettings, extraDaySettings, ActiveReport_Data.Waters, ({
      color: 11, name: t('GraphViewer.Water'),
      form: 'Water', type:ViewForms.BAR
    } as DataSet)):[]
  , [ActiveReport_Data?.Waters, extraDaySettings, changeDaySettings])
  const WaterPrPigCurve = useMemo<any[]>(() => 
    ActiveReport_Data?CurveGeneration(ActiveReport_Data, changeDaySettings, extraDaySettings, ActiveReport_Data.WatersPrPig, ({
      color: 11, name: t('GraphViewer.WaterPrPig'),
      form: 'WaterPrPig', type:ViewForms.BAR
    } as DataSet)):[]
  , [ActiveReport_Data?.WatersPrPig, extraDaySettings, changeDaySettings])
  const ImageCurves = useMemo(() => 
    Report_Images?Report_Images.map((Report, index) => {
      var Report_ExtraTime = 0
      if (extraDaySettings && extraDaySettings['Image_'+index]) {
        Report_ExtraTime += extraDaySettings['Image_'+index] * UnixDayTime
      }
      var Re_Weights = Report.Weights?Report.Weights.map((Data, PenIndex) => {
        var ReportPenData = Report.Pens[PenIndex]
        var {startTime, endTime, ExtraTime} = GetFilterVals(Report, undefined, undefined, Report.Pens, ReportPenData.Id)
        var ocr = Report.Pens.slice(0,PenIndex +1).filter(e => e.Id === ReportPenData.Id).length
        return ({
          //color: getUniqueColor(PenIndex+2),
          name: (Report.ReportName?Report.ReportName+'_':'')+(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ ReportPenData.Id + (ocr>1?'_'+(ocr-1):""),
          form:'Weight', type:ViewForms.LINE,
          startPoint: Report_ExtraTime+ExtraTime,
          data: Data?Data.filter(Weight => Weight && (!Weight.edited && !Weight.deleted) && (Weight.time >= startTime && Weight.time <= endTime)).map((Weight:any) => {
            return ({x:Weight.time, y:Weight.count?Weight.weight/Weight.count:Weight.weight} as PointSet)
          }):[]
        } as DataSet)
      }):[]
      var Re_Operation = Report.Operations&&Report.Operations.List?Report.Operations.List.flat(1).filter(e => e && (!e.edited && !e.deleted)).reduce((previousValue, currentValue) => {
        if (currentValue.penIndex !== undefined && currentValue.penIndex >= 0) {
          var PenIndex = currentValue.penIndex
          var ReportPenData = Report.Pens[PenIndex]
          var {startTime, endTime, ExtraTime} = GetFilterVals(Report, undefined, undefined, Report.Pens, ReportPenData.Id)
          if (currentValue.time >= startTime && currentValue.time <= endTime) {
            if (previousValue[PenIndex] && previousValue[PenIndex].data) {
              var Data = previousValue[PenIndex].data
              Data.push(({x:currentValue.time, y:'min', objData:currentValue}))
              previousValue[PenIndex].data = Data
            }
            else {
              var ocr = Report.Pens.slice(0,PenIndex +1).filter(e => e.Id === ReportPenData.Id).length
              return [...previousValue.slice(0, PenIndex), {
                color: getUniqueColor(index+3), //color:'#000',
                //name:t('GraphViewer.NoteLabel'),
                name: (Report.ReportName?Report.ReportName+'_':'')+t('GraphViewer.NoteLabel'),
                form:'Notes',
                type:ViewForms.SCATTER,
                active:true,
                startPoint: Report_ExtraTime+ExtraTime,
                data: [{x:currentValue.time, y:'min', objData:currentValue}]
              },
              ...previousValue.slice(PenIndex)]
            }
          }
        }
        return previousValue
      }, []):[]
      var Re_Scales = Report.Scales?CurveGeneration(Report, undefined, undefined, Report.Scales, ({
        startPoint: Report_ExtraTime,
        prename: Report.ReportName?Report.ReportName:'',
        name: t('GraphViewer.Scale'),
        form: 'Weight', type:ViewForms.LINE
      } as DataSet)):[]
      var Re_Temps = Report.Temps?CurveGeneration(Report, undefined, extraDaySettings, Report.Temps, ({
        color: 2, startPoint: Report_ExtraTime,
        prename: Report.ReportName?Report.ReportName+'_':'',
        name: t('GraphViewer.Temp'),
        form: 'Temp', type:ViewForms.LINE
      } as DataSet)):[]
      var Re_Waters = Report.Waters?CurveGeneration(Report, undefined, extraDaySettings, Report.Waters, ({
        color: 11, startPoint: Report_ExtraTime,
        prename: Report.ReportName?Report.ReportName+'_':'',
        name: t('GraphViewer.Water'),
        form: 'Water', type:ViewForms.BAR
      } as DataSet)):[]
      var Re_Feeds = Report.Feeds?Object.entries(Report.Feeds).map(([PenTag, data],index) => {
        var ExtraTime_Pen = 0
        var ocr = 0
        var PenIndex = Report.Pens.findIndex(e => e.Id+'' === PenTag)
        if (PenIndex>=0) {
          var ReportPenData = Report.Pens[PenIndex]
          ExtraTime_Pen = ReportPenData.startday?(ReportPenData.startday * UnixDayTime):0
          ocr = Report.Pens.slice(0,PenIndex +1).filter(e => e.Id === ReportPenData.Id).length
        }
        return ({
          color: getUniqueColor(index+11),
          name: PenTag+'_Feed' + (ocr>1?'_'+(ocr-1):""),
          form: 'Feed', type:ViewForms.BAR,
          startPoint: Report_ExtraTime + ExtraTime_Pen,
          data: (data&&data.datas?
            data.datas.map((Feed) => ({
              x:(Feed.timestamp), y:((Feed.sumamount?Feed.sumamount/1000:0)), objData:Feed
            } as PointSet))
          :undefined)
        } as DataSet)
      }):[]
      return [Re_Weights,Re_Operation,Re_Scales,Re_Temps,Re_Waters,Re_Feeds]
    }).flat(2):[]
  ,[Report_Images, extraDaySettings])
  
  const CurveDataView = useMemo<DataSets>(() => {
    var Datas = WeightCurves
            .concat(OperationsCurve)
            .concat(TemplateCurve)
            .concat(ScaleCurve)
            .concat(ActivityCurve)
            .concat(FeedCurve)
            .concat(FCRCurve)
            .concat(TempCurve)
            .concat(WaterCurve)
            .concat(WaterPrPigCurve)
            .concat(ImageCurves).filter(e => e)
    //console.log("viewer data: ", Datas)
    return(Datas)
  }, [WeightCurves,OperationsCurve,TemplateCurve,ScaleCurve,ActivityCurve,FeedCurve,TempCurve,WaterCurve,ImageCurves])
  //#endregion

  console.log('ActiveReport_Data', ActiveReport_Data)

  //if (!isLoaded(auth) || !isLoaded(profile)) return <LoadingSplash/>
  //else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[{ position:'absolute', top:"70px", right:"2%", width:"300px", minHeight:'max-content'}, isAppView?{display:'none'}:{display:'block'}]}>
        {/*</View><View style={[{ position:'absolute', top:"70px", right:"2%", width:"300px", minHeight:'max-content'}]}>*/}
          <Brand width={'100%'}/>
        </View>
        <View style={{height:20}}/>
        <Button Contexts={ActiveReport_Data&&ActiveReport_Data.ReportName?ActiveReport_Data.ReportName:activeReport} Title/>
        <View style={{height:20}}/>
        <ContentProp Columns={2} Datas={[[t('Batchs.StartD'), startString] ]}/>
        <ContentProp Columns={2} Datas={[[t('Pen_Display.Prop3'), '' + age] ]}/>
        <ContentProp Columns={2} Datas={[[t('Report_Display.Added'), ActiveReport_Data?.Operations.Added], [t('Report_Display.Deaths'), '' + ActiveReport_Data?.Operations.Deaths], [t('Report_Display.Sold'), ActiveReport_Data?.Operations.Sold] ]}/>

        <View style={[ Layout.fillRow, Layout.fullWidth, {maxWidth:600, marginTop:5, justifyContent:'space-between'}]}>
          <View style={[{maxWidth:300, minWidth:120, width:'50%', alignItems:'center'}]}>
            {isAppView&&(<Brand width={'120px'}/>)}
          </View>
          <View style={{width: "fit-content"}}>
            <Button Contexts={['CSV   Data .']} maxWidth={'200px'} EndEnhancers={Images.Save} Action={() => {

              var Scales:any|undefined = undefined
              if (ActiveReport_Data&&ActiveReport_Data.Scales) {
                ActiveReport_Data.Scales.forEach((Datas, index) => {
                  var AutoData = ActiveReport_Data.Autos[index]
                  if (AutoData) {
                    if (!Scales) Scales = []
                    Scales[index] = {}
                    Scales[index][AutoData.name] = {}

                    Datas.forEach((dataSpot) => {
                      var dateT = new Date(dataSpot.x)
                      var date = dateT.getFullYear()+'_'+(dateT.getMonth()+1)+'_'+dateT.getDate()
                      Scales[index][AutoData.name][date] = {
                        amount: dataSpot.y,
                        count: dataSpot.objData&&dataSpot.objData.length?dataSpot.objData.length:1,
                        timestamp: dataSpot.x,
                        objData: dataSpot.objData?dataSpot.objData:[]
                      }
                    })
                  }
                })
              }
              GenerateCSV_Data(ActiveReport_Data&&ActiveReport_Data.ReportName?ActiveReport_Data.ReportName:activeReport, 
                FeedEle_ViewRef&&FeedEle_ViewRef.current?FeedEle_ViewRef.current.GetValues():undefined,
                Scales,
                undefined,//ConTempList, 
                undefined,//ConWaterList, 
                WeightEle_ViewRef&&WeightEle_ViewRef.current?WeightEle_ViewRef.current.GetValues():undefined, 
                OperationEle_ViewRef&&OperationEle_ViewRef.current?OperationEle_ViewRef.current.GetValues():undefined, 
              )
            }}/>
          </View>
        </View>

        <Feed_View ActiveReport_Data={ActiveReport_Data} ref={FeedEle_ViewRef}
          Feeds_Data={FeedFiltered?FeedFiltered:undefined} 
          Images_Data={Report_Images?Report_Images.map((Report, index) => {return Report.Feeds?Report.Feeds:[]}).flat(1):{}}
        />
        <Operation_View ActiveReport_Data={ActiveReport_Data} Images_Data={Report_Images} ref={OperationEle_ViewRef}/>
        <Weight_View ActiveReport_Data={ActiveReport_Data} Images_Data={Report_Images} ref={WeightEle_ViewRef}/>
        <View style={{height:30}}/>
        
        {//activeReport!=="Combined-View"?(
          <PrintContainer $NoPrint>
          <View style={[Layout.fill, Layout.row, Layout.fullWidth, {minHeight:90, maxWidth:900, justifyContent:'space-between'}]}>
            <Settings_Editor ActiveReport_Data={ActiveReport_Data} Images_Data={Report_Images}
              extraDaySettings={extraDaySettings} updateExtraDaySettings={updateExtraDaySettings} 
              changeDaySettings={changeDaySettings} updateChangeDaySettings={updateChangeDaySettings}
            />
          </View>
          </PrintContainer>
        //):(<></>)
        }

        <CurveViewer ref={CurveViewRef} yShrink DatePicker DateArrows canMultiSplit={false} zeroIndex={true}
          //StartTimes={{start:ActiveReport_Data.ReportStart, end:ActiveReport_Data.ReportEnd}as TimeSpan}
          Primary={{left:'Weight', right:'---'}}
          DataView={CurveDataView}
        />
      </View>
    )
  //}
}

const _View = () => (<DataProvider><Report_Display/></DataProvider>)
export default _View