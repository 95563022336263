import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import { navigationRef } from '../../Navigators/Root'

export interface InitStates {
  status: string,
  firstRun: boolean,
  CookieLevel: number,
  IsQuickApp: boolean
}

const InitAdapter = createEntityAdapter()
const initialState = InitAdapter.getInitialState<InitStates>({
  status: 'none',
  firstRun: true,
  CookieLevel: 0,
  IsQuickApp: false
})

// Thunk functions
export const doInit = createAsyncThunk<boolean,void>('startup/init', async () => {
  //Firebase
  //await dispatch(FirebaseUser.action())

  //await dispatch(DefaultTheme.action({ theme: 'default', darkMode: null }))
  while (!navigationRef.current?.isReady) {}
  return true
})

const InitSlice = createSlice({
  name: 'startup',
  initialState,
  reducers: {
    setCookieState: (state, action) => {
      if (action.payload && action.payload.data !== undefined && action.payload.data !== null) {
        return Object.assign({}, state, {firstRun: false, CookieLevel:action.payload.data})
      }
      return state
    },
    setIsQuickApp: (state, action) => {
      if (action.payload && action.payload.data !== undefined && action.payload.data !== null) {
        return Object.assign({}, state, {IsQuickApp:action.payload.data})
      }
      return state
    }
  },
  extraReducers: builder => {
    builder
      .addCase(doInit.pending, (state, action) => {
        state.status = 'pending'
      })
      .addCase(doInit.rejected, (state, action) => {
        state.status = 'error'
      })
      .addCase(doInit.fulfilled, (state, action) => {
        state.status = 'idle'
      })
  }
})

const { actions, reducer } = InitSlice
export const { setCookieState, setIsQuickApp } = actions
export default reducer