// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery, BaseQueryEnhancer } from '@reduxjs/toolkit/query/react'
import { isEmpty } from '../../Config/Types'
import { RootState, useAppSelector } from '../../Store'
import { Config_Pri } from './Config'
import { ConsysApi, ConsysArgs, GetAutos, MultiLoop } from './Consys'

export interface SystemInfoArgs extends ConsysArgs {
  FarmKeys?: string[],
  getInfos?: boolean,
  limitIds?: string[]
}

export interface SystemInfo {
  SystemId?: number,
  SystemIndex: number,
  SystemName?: string,
  SystemType: string,
  SystemMainState?: number,
  Online?: boolean
}
export type SystemList = SystemInfo[]

const _GetError = (dataText:string):any => {
  return {error: {
    status: 500,
    statusText: 'Token retrieve error',
    data: dataText,
  }}
}

const DoqueryFn = async (args:SystemInfoArgs, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (args.FarmKeys && typeof args.FarmKeys==="object" && !isEmpty(args.FarmKeys)) {
    var res = await MultiLoop(DoqueryFn, args, queryApi, extraOptions, fetchWithBQ)
    if (res && (!isEmpty(res.data) || !isEmpty(res.error))) return res
  }
  else {
    var Errors = null, InfoRes = null
    let getAutos = GetAutos(args, queryApi)
    
    let Result = await fetchWithBQ({url:Config_Pri.GetSystem_URL(), params:args})
    if (Result) {
      if (Result.error || (!Result.data || isEmpty(Result.data))) {
        Errors = _GetError(Result.error?Result.error.error:"")
      } else if (Result.data && !isEmpty(Result.data)) {
        var System:SystemList = [...Result.data as SystemList].filter(e => {
          //TODO: rem... old api coding .. id not index
          var Data = {...e}
          if (e && e.SystemId && !e.SystemIndex) {
            Data.SystemIndex = e.SystemId
          }
          delete Data.SystemId
          return Data.SystemIndex && getAutos.some(([key, AutoPig]) => key === Data.SystemIndex + '')
        })
        if (args.getInfos) {
          System = await Promise.all(System.map(async (Info:SystemInfo, index:number) => {
            if (!args.limitIds || args.limitIds.length === 0 || args.limitIds.includes(Info.SystemIndex+'')) {
              var Result2 = await fetchWithBQ({url:Config_Pri.GetSystem_URL(Info.SystemIndex), params:args})
              if (Result2 && Result2.data) {
                return {...Info, ...Result2.data as SystemInfo}
              }
              return Info
            }
            return System[index]
          }))
        }
        InfoRes = System
      }
    }

    if (!Errors) {
      return {data: InfoRes} //Infores.filter(e => e)}
    } else {
      return Errors
    }
  }
  return _GetError("No Site")
}

// Define a service using a base URL and expected endpoints
const ConsysApi_WithTag = ConsysApi.enhanceEndpoints({
  addTagTypes: ['System']
})
export const SystemSlice = ConsysApi_WithTag.injectEndpoints({
  overrideExisting:false,
  endpoints: (builder) => ({
    getSystemList: builder.query<SystemList, SystemInfoArgs>({
      async queryFn(args:SystemInfoArgs, queryApi, extraOptions, fetchWithBQ) {
        var Data = await DoqueryFn(args, queryApi, extraOptions, fetchWithBQ)
        //console.log("fetch res", Data)
        return Data

        /*let Result = await fetchWithBQ({url:Config_Pri.GetSystem_URL(), params:args})
        if (Result.error || !Result.data) {
          console.error("SystemList", Result.error)
          return _GetError(Result.error?Result.error.error:"")
        } 
        else if (Result.data) {
          let System:SystemList = [...Result.data as SystemList]
          if (args.getInfos) {
            System = await Promise.all(System.map(async (Info:SystemInfo, index:number) => {
              let Result2 = await fetchWithBQ({url:Config_Pri.GetSystem_URL(Info.SystemIndex), params:args})
              if (Result2.data) {
                return {...Info, ...Result2.data as SystemInfo}
              }
              return Info
            }))
          }
          return {data: System}
        }
        return _GetError("No Site")*/
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'System', Key})
        })
      },
      //providesTags: (result, error, id) => [{ type: 'System' }],
    }),
    getSystemInfo: builder.query<SystemInfo, {id:number, index?:number}>({
      async queryFn(args, queryApi, extraOptions, fetchWithBQ) {

        let Result = await fetchWithBQ({url:Config_Pri.GetSystem_URL(args.id), params:args})
        if (Result.data) return Result.data as SystemInfo

        return _GetError("No Site")
      }
    })
  }),
})

export const GetSystemList = SystemSlice.endpoints.getSystemList.useQuery
export const GetSystemInfo = SystemSlice.endpoints.getSystemInfo.useLazyQuery