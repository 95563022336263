import React, { useState, useCallback, useEffect, useRef, useMemo } from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'

import { Datepicker, DatePicker } from "baseui/datepicker"
import { ChevronLeft, ChevronRight, TriangleLeft, TriangleRight } from 'baseui/icon'
import { UnixDayTime, Periodes, TimeSpan } from '../../Config/Types'

export interface Datetime_Picker_Props {
  Title?:string,
  Period?:number,

  single?: boolean
  value?:TimeSpan,
  
  MoveArrows?:boolean,
  MarkDates?:Periodes[],
  MinMax?:TimeSpan,
  ClearAble?:boolean

  onValueChange: (time:TimeSpan|undefined) => void,
  ActiveWatch?: (isActive:boolean) => void,
  noIco?:boolean
}

const Datetime_Picker = ({Title, Period=6, single, value, MoveArrows=false, MarkDates, MinMax, onValueChange, ActiveWatch,  ClearAble=false, noIco=false}:Datetime_Picker_Props) => {
  const { t } = useTranslation()
  const { Layout, Images } = useTheme()
  const datePicker = useRef<Datepicker>(null)
  
  const IsSingle = useMemo<boolean>(() => single!==undefined?single:(value&&value.end===undefined)?true:false, [single, value])
  const [TimeSelector, setTimeSelector] = useState<{start?:Date,end?:Date}>({})
  const [lastVal, setLastVal] = useState<TimeSpan|undefined>(undefined)

  const [isActive, _setIsActive] = useState(false)
  const setIsActive = (isActive:boolean) => {
    if (ActiveWatch) ActiveWatch(isActive)
    _setIsActive(isActive)
  }

  const setTimes_Transfomer = (value:TimeSpan|undefined):TimeSpan|undefined => {
    var start:number|undefined = value&&value.start?value.start:undefined
    if (!start && !ClearAble) start = IsSingle?Date.now():new Date().setHours(0,0,0,0) - (UnixDayTime*Period)
    //if (typeof start === "number") start = new Date(start)

    var end:number|undefined = value&&value.end?value.end:undefined
    if (!end && !ClearAble) end = IsSingle?Date.now():undefined
    //if (typeof end === "number") end = new Date(end)

    return start?{start:start,end:IsSingle?undefined:end}:undefined
  }

  const Update = useCallback(() => {
    if (value && (!lastVal || ((value.start !== lastVal.start)||(value.end && value.end !== lastVal.end)))) {
      setLastVal(value)

      var val = setTimes_Transfomer(value)
      setTimeSelector(val&&val.start?{start:new Date(val.start),end:val.end?new Date(val.end):undefined}:{})
      if (!value && onValueChange) onValueChange(val)
    }
  }, [value])

  useEffect(() => {
    if (!isActive) Update()
  }, [value])

  const CalcSetTimes = (days:number) => {
    if (TimeSelector) {
      var start = TimeSelector.start!==undefined?TimeSelector.start.getTime() + (days * UnixDayTime):undefined
      var end = TimeSelector.end!==undefined?TimeSelector.end.getTime() + (days * UnixDayTime):undefined
      setTimeSelector({start:start?new Date(start):undefined,end:end?new Date(end):undefined})
      onValueChange(start?{start:start,end:end}:undefined)
    }
  }

  const DateValue = useMemo(() => {
    if (IsSingle) {
      return TimeSelector.start?TimeSelector.start:null
    }
    else {
      var val1 = TimeSelector.start?[TimeSelector.start]:[]
      var val2 = TimeSelector.end?[TimeSelector.end]:[]
      return ([] as Date[]).concat(val1).concat(val2)
    }
  }, [TimeSelector])

  const {timeMin, timeMax} = useMemo(() => {
    var timeMin:Date|undefined = undefined
    var timeMax:Date|undefined = undefined
    if (MinMax) {
      //Old support -Error catch-???
      if (Array.isArray(MinMax)) {
        if (MinMax[0]) {
          if (typeof MinMax[0] === "number") {
            timeMin = new Date(MinMax[0])
          } 
          else timeMin = MinMax[0] //Error ??
        }
        if (MinMax[1]) {
          if (typeof MinMax[1] === "number") {
            timeMin = new Date(MinMax[1])
          } 
          else timeMin = MinMax[1] //Error ??
        }
      }
      //as it should be
      else if (typeof MinMax === 'object') {
        //Object.hasOwn(MinMax,'start')
        if (MinMax.start) {
          if (typeof MinMax.start === "number") {
            timeMin = new Date(MinMax.start)
          }
          else timeMin = MinMax.start //Error ??
        }
        if (MinMax.end) {
          if (typeof MinMax.end === "number") {
            timeMax = new Date(MinMax.end)
          }
          else timeMax = MinMax.end //Error ??
        }
      }
    }
    return {timeMin, timeMax}
  }, [MinMax])

  return (
    <View style={[Layout.row, {justifyContent:'flex-start', alignItems:'flex-end'}] }>
      {MoveArrows&&(
        <View style={[Layout.rowCenter, {marginTop:10, marginRight:10, justifyContent:'center'}, single?{minWidth:50}:{minWidth:60}]}> 
          <TouchableOpacity style={[{}]} onPress={() => CalcSetTimes(-7)}>
            <ChevronLeft title={`-7 ${t('Operations.day')}s`} size={30}/>
          </TouchableOpacity>
          <TouchableOpacity style={[{}]} onPress={() => CalcSetTimes(-1)}>
            <TriangleLeft title={`-1 ${t('Operations.day')}`} size={30}/>
          </TouchableOpacity>
        </View>
      )}
      <View style={[Layout.colCenter, {marginTop:10, justifyContent:'center'}, single?{minWidth:130}:{minWidth:190}]}> 
        <Text style={[{marginLeft:10, alignSelf:'flex-start'}]}>{Title?Title:t('Operations.PeriodeTitle')}</Text>
        <DatePicker ref={datePicker} 
          minDate={timeMin?timeMin:undefined} 
          maxDate={timeMax?timeMax:undefined}
          value={
            DateValue
          }
          onChange={({ date }) => {
            var oldTime:Date[]= TimeSelector.start?TimeSelector.end?[TimeSelector.start, TimeSelector.end]:[TimeSelector.start]:[]
            var newTime:Date[] = Array.isArray(date)?date:[date]
            if (ClearAble && !newTime[0]) {
              setTimeSelector({})
              onValueChange(undefined)
            }
            else {
              if (oldTime[0] && newTime[0] && !newTime[0].getHours() && !newTime[0].getMinutes()) {
                newTime[0].setHours(oldTime[0].getHours())
                newTime[0].setMinutes(oldTime[0].getMinutes())
              }
              if (Array.isArray(date)) {
                if (date.length === 2 && !IsSingle) {
                  var STime = date[0].setHours(0,0,0,0)
                  var ETime = date[1].setHours(23,59,59,0)
                  setTimeSelector({start:date[0],end:date[1]})
                  onValueChange({start:STime,end:ETime})
                } else if (IsSingle) {
                  setTimeSelector({start:newTime[0],end:undefined})
                  onValueChange({start:newTime[0].getTime(),end:undefined})
                }else {
                  setTimeSelector({start:date[0],end:date[1]})
                }
              } else if (IsSingle) {
                setTimeSelector({start:newTime[0], end:undefined})
                onValueChange({start:newTime[0].getTime(), end:undefined})
              }
            }
          }}
          size={"compact"}
          clearable={ClearAble}
          range={!IsSingle}
          timeSelectStart={IsSingle}
          peekNextMonth={!IsSingle}
          formatString="dd/MM/yyyy"
          
          onOpen={() => setIsActive(true)}
          onClose={() => setIsActive(false)}
          
          overrides={{
            Input: {props: { overrides: {
              Root: {style: ({ }) => ({
                outline: `1px #000 solid`,
                width: (IsSingle?140:190),
                maxWidth: 200
              })}
            }}},
            InputWrapper: {style: ({ }) => ({
              width: (IsSingle?'140px':'190px')
            })},
            Day: {
              style:({$date, $theme, $selected, $isHovered, $isHighlighted}) => {
                var styleObj = {}
                if ((new Date().getDate()===$date.getDate()) && (new Date().getMonth()=== $date.getMonth()) && (new Date().getFullYear()=== $date.getFullYear())) styleObj = {...styleObj, backgroundColor: 'lightblue'}
                if (MarkDates) {
                  var startdata = MarkDates.find(e => e.starttime&&(new Date(e.starttime).getDate()===$date.getDate()) && (new Date(e.starttime).getMonth()=== $date.getMonth()) && (new Date(e.starttime).getFullYear()=== $date.getFullYear()))
                  var enddata = MarkDates.find(e => e.endtime&&(new Date(e.endtime).getDate()===$date.getDate()) && (new Date(e.endtime).getMonth()=== $date.getMonth()) && (new Date(e.endtime).getFullYear()=== $date.getFullYear()))
                  if (startdata || enddata) {
                    styleObj = {...styleObj,
                      //borderRadius: '25px',
                      backgroundImage: startdata&&enddata?"linear-gradient(-45deg, lightgreen 50%, lightcoral 0%)":undefined,
                      backgroundColor: startdata&&enddata?undefined:
                                       startdata?(startdata.color?startdata.color:'lightgreen'):
                                       enddata?(enddata.color?enddata.color:'lightcoral'):
                                       'lightgray'
                    }
                  }
                }
                return styleObj
              }
            }
          }}
        />
      </View>
      {!noIco?(
        <TouchableOpacity style={[{height:36, width:30, justifyContent:'center'}]} onPress={() => {
          datePicker.current?.open()
        }}>
          <Image style={[{height:30, width:30}]} resizeMode={'contain'} source={{uri:Images.Calendar}} />
        </TouchableOpacity>
      ):(<></>)}
      {MoveArrows&&(
        <View style={[Layout.rowCenter, {marginTop:10, marginLeft:10, justifyContent:'center'}, IsSingle?{minWidth:50}:{minWidth:60}]}> 
          <TouchableOpacity style={[{}]} onPress={() => CalcSetTimes(1)}>
            <TriangleRight title={`+1 ${t('Operations.day')}`} size={30}/>
          </TouchableOpacity>
          <TouchableOpacity style={[{}]} onPress={() => CalcSetTimes(7)}>
            <ChevronRight title={`+7 ${t('Operations.day')}s`} size={30}/>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}

export default Datetime_Picker