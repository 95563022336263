import React, { useCallback, useEffect } from 'react'
import { SafeAreaView, ScrollView, View, Text, TouchableOpacity } from 'react-native'
import { useTheme } from '../Theme'
import { FarmData, UserStates, isEmpty } from '../Config/Types';
import { ContentPages, UserPanel } from '../Containers'
import MenuView from '../Containers/UserPanel/MenuView'

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next'
import { ArrowLeft, DeleteAlt } from "baseui/icon";

import { navigateAndSimpleReset, useisAppView, useNavigatorOnLine } from '../Navigators/Root'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'

import { useAppSelector, useAppDispatch, ClearData } from '../Store'
import { setStates, resetState } from '../Store/StateHandlers/Active-slice'
import { FirebaseCaller, Sections, Pens as FBPens, Graphs, DataCons, Comments, Weighings } from '../Services/Firebase'
import { ConsysApi } from '../Services/Consys/Consys'
import { LanguageSelector, LoadingSplash } from '../Components'
//import { ModuleForms } from '../Containers/UserPanel/Operations/';

const UserStack = createNativeStackNavigator()

// @refresh reset
const UserNavigator = () => {
  const { t } = useTranslation()
  const { Layout, Common, Colors, NavigationColors } = useTheme()
  const isAppView = useisAppView()
  const isOnline = useNavigatorOnLine()
  const dispatch = useAppDispatch()
  const IsQuickApp = useAppSelector(({startup}) => startup.IsQuickApp)

  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector(({firebase}) => firebase.profile)

  //const IsAppV = useAppSelector(({activeData}) => activeData.IsAppVersion)
  //const farms = useAppSelector<{[key:string]:FarmData}>(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector<string|undefined>(({activeData}) => activeData.farm)

  const DataLinks = useAppSelector(({firebase: { data }}) => data?.DataLinks)
  const LinkListData = useAppSelector(({firebase: { data }}) => data?.LinkListData)
  const LinkList = useAppSelector(({firebase: { data }}) => data?.LinkList)
  useFirebaseConnect(() => {
    let Calls: any[] = []
    if ((!isEmpty(auth) && auth.uid)&&(activefarm && activefarm !== "")) {
      //Get Farm DataLinks
      if (!isEmpty(DataLinks)) {
        //console.log("list", activefarm, DataLinks)
        Object.entries(DataLinks).forEach(([Current_Farm, Data]) => {
          //console.log("cur", Current_Farm)
          //Calls.push({path:`LinkList/${Current_Farm}/DataLinks/${activefarm}`, queryParams: ['orderByKey'], storeAs: `LinkListData/${Current_Farm}`})
          //Calls.push({path:`LinkList/${Current_Farm}/System/`, storeAs: `LinkList/${Current_Farm}/Systems/`})
          Calls.push({path:`LinkList/${Current_Farm}/DataLinks/${activefarm}`, queryParams: ['orderByKey'], storeAs: `LinkList/${Current_Farm}`})
          Calls.push({path:`LinkList/${Current_Farm}/System/`, storeAs: `LinkList/${Current_Farm}/System/`})
          const DataPath = `farms_data/${Current_Farm}/`
          if (LinkList && LinkList[Current_Farm]) {
            const LinkData = LinkList[Current_Farm]
            if (LinkData && LinkData.sections) {
              Calls = Calls.concat(Object.keys(LinkData.sections).map(element => {
                //return {path:DataPath + `sections/${element}`, storeAs: `LinkList/${Current_Farm}/sections/${element}`}
                return {path:DataPath + `sections/${element}`, storeAs: `LinkListData/${Current_Farm}/sections/${element}`}
              }))
            }
          }
          if (LinkListData && LinkListData[Current_Farm]) {
            const LinkData = LinkListData[Current_Farm]
            if (LinkData && LinkData.sections) {
              Object.entries(LinkData.sections).forEach(([section, data]) => {
                if (data && data.pens && !isEmpty(data.pens)) {
                  data.pens.forEach(Pen => {
                    //Calls.push({path:DataPath + `pens/${Pen}`, storeAs: `LinkList/${Current_Farm}/pens/${Pen}`})
                    Calls.push({path:DataPath + `pens/${Pen}`, storeAs: `LinkListData/${Current_Farm}/pens/${Pen}`})
                  })
                }
              })
            }
            if (LinkData && LinkData.pens) {
              var Autopigs:number[] = []
              Object.entries(LinkData.pens).forEach(([Pen, data]) => {
                if (data && data.datas && data.datas.autopigs) {
                  data.datas.autopigs.split(',').forEach(Auto => {
                    if (!Autopigs.includes(parseInt(Auto))) Autopigs.push(parseInt(Auto))
                  })
                }
              })
              Calls = Calls.concat(Autopigs.map(element => {
                //return {path:DataPath + `DataCons/${element}`, storeAs: `LinkList/${Current_Farm}/DataCons/${element}`}
                return {path:DataPath + `DataCons/${element}`, storeAs: `LinkListData/${Current_Farm}/DataCons/${element}`}
              }))
            }
          }
        })
      }
      Calls.push({path:`farms/${activefarm}`})

      Calls.push(...Graphs.fetchWeightGraphs(activefarm, false, [], undefined))
      Calls.push(...Sections.fetchSections(activefarm, false, []))
      Calls.push(...FBPens.fetchPens(activefarm, false, []))
      Calls.push(...DataCons.fetchDataCons(activefarm, false, [], undefined))

      const path = `farms_data/${activefarm}/`
      Calls.push({path:path + 'system', queryParams: ['notParsed', 'orderByKey'], storeAs: 'system'})
      Calls.push({path:path + 'DataLinks', queryParams: ['orderByKey'], storeAs: 'DataLinks'})

      Calls.push(...Comments.fetchTreatments(activefarm, false, []))

      /*Calls.push(...
        Weighings.fetchWeighings(activefarm, false, [], undefined).concat(
          LinkListData&&!isEmpty(LinkListData)?Object.entries(LinkListData).map(([Current_Farm, Data]) => {
            return Weighings.fetchWeighings(activefarm, false, [], Current_Farm)
          }).flat(1):[]
        )
      )
      Calls.push(...
        Comments.fetchComments(activefarm, false, [], undefined).concat(
          LinkListData&&!isEmpty(LinkListData)?Object.entries(LinkListData).map(([Current_Farm, Data]) => {
            return Comments.fetchComments(activefarm, false, [], Current_Farm)
          }).flat(1):[]
        )
      )*/
    }
    return Calls
  })

  //Auth Check Handler
  useEffect(() => {
    //console.log("Cought change", auth)
    if (isEmpty(auth) || !auth.uid) navigateAndSimpleReset('Auth', {screen: 'Home'})
  }, [auth])
  const ChangeView = (navigation) => {
    return (E) => {
      if (isAppView) navigation.navigate('User', {screen: E})
      else navigation.replace('User', {screen: E})
      //navigateBack()
      //navigation.navigate('User', {screen: E})
    }
  }

  const ContextFrame = useCallback((navigation, PageComponent, NoMenu=false) => {
    return(
      <View style={[Layout.fullSize, Layout.column, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'min-content', backgroundColor:'#f2f2f2'}]}>
        {!isOnline?(
          <View style={[Layout.fillCol,{backgroundColor:Colors.error, minHeight:22}]}>
            <Text style={{textAlign:'center'}}>{'Device is offline'}</Text>
          </View>
        ):(<></>)}
        <View style={[Layout.fullSize, Layout.row, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'min-content', backgroundColor:'#f2f2f2'}]}>
          {(!NoMenu&&!isAppView) || (!PageComponent && isAppView)?<MenuView setView={ChangeView(navigation)} /*nologo={true}*/ />:<></>}
          {PageComponent?
            <ScrollView style={[Layout.fillCol, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'min-content', backgroundColor:'#f2f2f2'}]} centerContent={true}  contentContainerStyle={[Layout.fillRow, {justifyContent: "center", minHeight:'max-content'}]}>
              {PageComponent}
            </ScrollView>:[]
          }
        </View>
      </View>
    )
  }, [isOnline, isAppView])

  const MenuLeftOptions = useCallback((props) => {
    return (
      <View style={{padding:6, paddingLeft: 15, zIndex:999}}>
        <TouchableOpacity style={[{}]}
          onPress={() => {
            var screen:{screen:string,params?:any} = {screen: 'Home'}
            if (IsQuickApp){
              screen = {screen: ContentPages.Operations}
            }
            
            if (props.label === "Admin" || (profile && profile.state && (profile.state === UserStates.ADMIN || profile.state === UserStates.TECH))) {
              dispatch({ type: actionTypes.CLEAR_DATA, preserve: { data: ['farms'], ordered: ['farms'] } })
              dispatch(ConsysApi.util.resetApiState())
              dispatch(resetState({state:"all"}))
              ClearData(dispatch)
              
              navigateAndSimpleReset('Admin', screen)
            } else {
              navigateAndSimpleReset('Main', screen)
            }
          }}
        >
          <DeleteAlt size={35} color="white" title={""}/>
        </TouchableOpacity>
      </View>
    )
  }, [profile])
  const GetOptionsHeader = useCallback((Title:string, headerLeft:boolean=true) => {
    var res:{title:string, headerLeft?:any} = { title: Title }
    if (!isAppView) {
      if (headerLeft) {
        res.headerLeft = (props) => MenuLeftOptions(props)
      }
    }
    return res
  }, [isAppView])

  if (!isEmpty(auth) && !auth.uid) return <LoadingSplash/>
  //NavigationColors.card => backgroundColor
  return (
    <SafeAreaView style={[Layout.fullFill, { backgroundColor: NavigationColors.card }]}>
      <UserStack.Navigator initialRouteName={"Home"}
        screenOptions={{
          title: 'AgrisysApp',
          headerTintColor: 'white',
          headerTitleAlign: 'center',
          presentation:"modal",
        }}
      >
        <UserStack.Group navigationKey={auth?'Home':undefined}
          screenOptions={({ navigation }) => ({
            headerStyle: { backgroundColor: NavigationColors.card },
            headerRight: (props) => (
              (<LanguageSelector/>)
            ),
          })}
        >
          <UserStack.Screen name={"Home"} key={'Home'}
            options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.NavTitle'))}
          >
            {(props) => ContextFrame(props.navigation, isAppView?null:<UserPanel.DashBoard />)}
          </UserStack.Screen>
          <UserStack.Screen name={ContentPages.SystemBoard}
            options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.SystemBoard'))}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.Systemboard />)}
          </UserStack.Screen>
          <UserStack.Screen name={ContentPages.DashBoard}
            options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.DashBoard'))}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.DashBoard />)}
          </UserStack.Screen>
          <UserStack.Screen name={ContentPages.Pens}
            options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.Pens'))}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.Pens />)}
          </UserStack.Screen>
          <UserStack.Screen name="Pen_Display"
            options={({ navigation }) => GetOptionsHeader(t('Pen_Display.Title'), false)}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.Pen_Display {...props}/>, true)}
          </UserStack.Screen>
          <UserStack.Group //Reports Pages
            screenOptions={({ navigation }) => ({
              headerStyle: { backgroundColor: NavigationColors.card },
            })}
          >
            <UserStack.Screen name={ContentPages.Reports}
              options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.Reports'))}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.Reports />)}
            </UserStack.Screen>
            <UserStack.Screen name="Reports_Editor"
              options={({ navigation }) => GetOptionsHeader(t('Reports_Editor.Title'), false)}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.Reports_Editor {...props}/>, true)}
            </UserStack.Screen>
            <UserStack.Screen name="Report_Display"
              options={({ navigation }) => GetOptionsHeader(t('Report_Display.Title'), false)}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.Report_Display />, true)}
            </UserStack.Screen>
          </UserStack.Group>
          <UserStack.Screen name={ContentPages.Growth}
            options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.Growth'))}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.GrowthCurves />)}
          </UserStack.Screen>
          <UserStack.Screen name="GrowthCurves_Editor"
            options={({ navigation }) => ({
              title: t('AgrisysMenu.Growth'),
            })}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.GrowthCurves_Editor {...props}/>, true)}
          </UserStack.Screen>

          <UserStack.Screen name={ContentPages.Operations}
            options={({ navigation, route }) => GetOptionsHeader(t('AgrisysMenu.Operations'))}
            >
            {(props) => ContextFrame(props.navigation, <UserPanel.Operations />, IsQuickApp)}
          </UserStack.Screen>
          {/*<UserStack.Screen name={ContentPages.Operations}
            options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.Operations'))}
            >
            {(props) => ContextFrame(props.navigation, <UserPanel.Operations ViewMode={ModuleForms.PAGE} />, props.route.params&&props.route.params.QuickMenu?true:false)}
          </UserStack.Screen>
          <UserStack.Screen name="WeighingOperations"
            options={({ navigation }) => ({
              title: t('Weighings.Title'),
            })}
            >
            {(props) => ContextFrame(props.navigation, 
              <UserPanel.Operations ViewMode={ModuleForms.WeightPage} 
                  Section={props.route.params?.section}
                  isDataLink={props.route.params?.isDataLink}  
                  Pen={props.route.params?.pen} 
                  />
            , true)}
            </UserStack.Screen>*/}
          {/*<UserStack.Screen name="WeighingOperations"
            options={({ navigation }) => ({
              title: t('Weighings.Title'),
            })}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.Weighing {...props}/>, true)}
          </UserStack.Screen>*/}
          <UserStack.Screen name={ContentPages.Autopigs}
            options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.Autofunctions'))}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.Autopigs />)}
          </UserStack.Screen>
          <UserStack.Screen name="Autopigs_Display"
            options={({ navigation }) => GetOptionsHeader(t('Autopigs_Display.Title'), false)}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.Autopig_Display {...props}/>, true)}
          </UserStack.Screen>
          <UserStack.Screen name="DataEditor"
            options={({ navigation }) => GetOptionsHeader(t('DataEditor.Title'), false)}
          >
            {(props) => ContextFrame(props.navigation, <UserPanel.DataEditor {...props}/>, true)}
          </UserStack.Screen>
          <UserStack.Group //Settings Pages
            screenOptions={({ navigation }) => ({
              headerStyle: { backgroundColor: NavigationColors.card }
            })}
          >
            <UserStack.Screen name={ContentPages.Settings}
              options={({ navigation }) => GetOptionsHeader(t('AgrisysMenu.Settings'))}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.Settings />)}
            </UserStack.Screen>
            {/*<UserStack.Screen name="FarmSettings"
              options={({ navigation }) => GetOptionsHeader(t('FarmSettings.Title'), false)}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.FarmSettings />, true)}
          </UserStack.Screen>*/}
            <UserStack.Screen name="FarmInformation"
              options={({ navigation }) => GetOptionsHeader(t('FarmInfo.Title'), false)}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.FarmInformation />, true)}
            </UserStack.Screen>
            <UserStack.Screen name="UserInformation" 
              options={({ navigation }) => GetOptionsHeader(t('UserInfo.Title'), false)}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.UserInformation {...props}/>, true)}
            </UserStack.Screen>
          </UserStack.Group>
          <UserStack.Group //Presentation Pages *** Single Page
            screenOptions={({ navigation }) => ({
              headerStyle: { backgroundColor: NavigationColors.card }
            })}
          >
            <UserStack.Screen name="Presentation"
              options={({navigation}) => GetOptionsHeader(t('AgrisysMenu.Presentation'), false)}
            >
              {(props) => ContextFrame(props.navigation, <UserPanel.Presentation {...props}/>, true)}
            </UserStack.Screen>
          </UserStack.Group>
        </UserStack.Group>
      </UserStack.Navigator>
    </SafeAreaView>
  )
}

export default UserNavigator