export default {
  Loading: require('./Loading.png'),
  Dot0: require('./Dot0.png'),
  Dot1: require('./Dot1.png'),
  Dot2: require('./Dot2.png'),
  Dot3: require('./Dot3.png'),
  Dot4: require('./Dot4.png'),
  Dot5: require('./Dot5.png'),
  Dot6: require('./Dot6.png'),
  Dot7: require('./Dot7.png'),
  Dot8: require('./Dot8.png'),
  Dot9: require('./Dot9.png'),
}