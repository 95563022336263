// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery, BaseQueryEnhancer } from '@reduxjs/toolkit/query/react'
import { RootState, useAppSelector } from '../../Store'
import { ConsysRefs, DataConsData, isEmpty, TimeSpan } from '../../Config/Types'
import { Config_Pri } from './Config'
import { ConsysApi, ConsysArgs, GetAutos, GetTimeSets, MultiLoop} from './Consys' 

export interface TemperatureInfo {
  dataid: number,
  valve?: number,
  timestamp: number,
  amount?: number,
  sumamount?: number,
  temperature?: number,
  count?:number,
}
export type TemperatureList = Record<string, Record<string, {amount:number, count:number, timestamp:number, objArr:TemperatureInfo[]}>>

export interface TemperatureProp extends ConsysArgs {
  FarmKey:string|undefined,
  FarmKeys?:string|string[]|undefined,
  systemids?:string|string[]|{id:string,XTern:string}|{id:string,XTern:string}[],
  Xsystemids?:string[][] | {id:string,XTern:string}[][],
  valve:string|number,
  ViewPeriods:TimeSpan[]|TimeSpan|undefined,
  interval?:number,
  timerange?:'last30d'|'currentmonth'|'lastmonth'
}

const _GetError = (dataText:string):any => {
  return {error: {
    status: 500,
    statusText: 'Token retrieve error',
    data: dataText,
  }}
}

const DoqueryFn = async (args:TemperatureProp, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (args.FarmKeys && typeof args.FarmKeys==="object" && !isEmpty(args.FarmKeys)) {
    var res = await MultiLoop(DoqueryFn, args, queryApi, extraOptions, fetchWithBQ)
    if (res && (!isEmpty(res.data) || !isEmpty(res.error))) return res
  }
  else {
    //const state = (queryApi.getState() as RootState)
    var Errors = null, Tempres:(TemperatureList | undefined)[] = []
    let valveId:string|null = args.valve!==undefined&&args.valve!==""?args.valve+'':null
    var getAutos = GetAutos(args, queryApi)

    //debugger
    Tempres = await Promise.all(getAutos.map(async ([key, AutoPig], index) => {
      if (AutoPig && AutoPig.temp && AutoPig.temp !== "") {
        var Temps = AutoPig.temp.split(',').reduce((a:object, v:string) => ({ ...a, [v]: (AutoPig.temp.includes('all,')&&v!=='all')?false:true}), {})
        if ((!valveId || valveId==="all") || (Temps['all']?!Temps[valveId]:Temps[valveId])) {
          //debugger
          var Times = GetTimeSets(args, queryApi, index)
          let Result = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.TEMPERATURE, key, /*valveId?valveId:*/'all', {interval:args.interval, starttime:Times.end, stoptime:Times.start}), params:args})
          if (Result) {
            if (Result.error || (!Result.data || !Result.data.consumptiondata)) {
              Errors = _GetError(Result.error?Result.error.error:"")
            } else if (Result.data && Result.data.consumptiondata) {
              var ResTemps = Result.data.consumptiondata as TemperatureInfo[]

              var dataMap:TemperatureList = {}
              /*if (!isEmpty(ResTemps) && ResTemps[0].sumamount) {
                return dataMap[name] = ResTemps
              }
              else {*/
                ResTemps.forEach(dataPoint => {
                  if (dataPoint) {
                    const Time = new Date(dataPoint.timestamp * 1000)
                    const TimeName:string = Time.getFullYear() + "_" + (Time.getMonth()+1) + "_"+ Time.getDate()

                    var _Temp = dataPoint.sumamount?dataPoint.sumamount:dataPoint.amount?dataPoint.amount:(dataPoint.temperature||0)
                    _Temp = (_Temp - 4000) * ((AutoPig.volt?parseInt(AutoPig.volt):50) /16000) //.volt not defined yet.. (could be removed in future)
                    _Temp = Math.round(_Temp * 10)/10
                    
                    var name = dataPoint.valve?dataPoint.valve:AutoPig.name?AutoPig.name:"SysId_" + key
                    if (!dataMap[name]) dataMap[name] = {}
                    if (!dataMap[name][TimeName]) dataMap[name][TimeName] = { amount: _Temp, count: 1, timestamp: (Time.setHours(23,59,59)), objArr:[] }
                    else {
                      //Navg = avg0 + (( NVal - acg0) / Ncount)
                      dataMap[name][TimeName].amount = Math.round((dataMap[name][TimeName].amount + ( (_Temp - dataMap[name][TimeName].amount) / (dataMap[name][TimeName].count +1) ) )*10)/10
                      dataMap[name][TimeName].count += 1
                    }
                    dataMap[name][TimeName].objArr.push({dataid:dataPoint.dataid, timestamp: dataPoint.timestamp, amount:_Temp})
                  }
                })
              //}
              return dataMap
            }
          } else Errors = _GetError("No Data Return")
        }
      }
    }))

    if (!Errors) {
      return {data: Tempres.filter(e => e)}
    } else {
      return Errors
    }
  }
  return _GetError("No Site")
}

// Define a service using a base URL and expected endpoints
const ConsysApi_WithTag = ConsysApi.enhanceEndpoints({
  addTagTypes: ['Temperature']
})
export const TempSlice = ConsysApi_WithTag.injectEndpoints({
  overrideExisting:false,
  endpoints: (builder) => ({
    getTempList: builder.query<TemperatureList[], TemperatureProp|void>({
      async queryFn(args:TemperatureProp, queryApi, extraOptions, fetchWithBQ) {
        return await DoqueryFn(args, queryApi, extraOptions, fetchWithBQ)
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Temperature', Key})
        })
      },
    }),
    getTemps: builder.query<TemperatureList[], TemperatureProp|void>({
      async queryFn(args:TemperatureProp, queryApi, extraOptions, fetchWithBQ) {
        var Data:TemperatureList[] = []

        if (!isEmpty(args.systemids)) {
          var Intern = await DoqueryFn({FarmKey:undefined, systemids:args.systemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          Data = Data.concat(Intern.data)
        }
        else if (!isEmpty(args.Xsystemids)) {
          var XTern = await DoqueryFn({FarmKey:undefined, FarmKeys:args.FarmKeys, Xsystemids:args.Xsystemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          Data = Data.concat(XTern.data)
        }

        //TODO: ERROR
        return {data:Data}
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Temperature', Key})
        })
      },
    }),
  }),
})
export const GetTempList = TempSlice.endpoints.getTempList.useQuery
export const GetTemps = TempSlice.endpoints.getTemps.useQuery