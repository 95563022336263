import React, { useEffect, useState } from "react";
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { animated, useSprings } from "react-spring";
import { TouchableOpacity, View, Text } from "react-native";
//import Svg, { Path } from "react-native-svg"

const Flikker = ({State, colorOn="#88C057", colorOff="#ED7161", ...props}:{State:boolean, colorOn?:string, colorOff?:string}) => {
  return <g {...props}><circle
    cx="13" cy="13" r="9" //cx={State?"45":"13"}
    fill={State?colorOn:colorOff}
    //stroke={State?"#659C35":"#D75A4A"}
    //strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1"
  ></circle></g>
}

const SwitchFrame = (props) => {
  /*return <Svg width={48} height={26.5} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path  fill="#E7ECED" stroke="#000" strokeWidth="0.25" d="M 35 26 H 13 C 5.85 26 0 20.15 0 13 S 5.85 0 13 0 h 22 c 7.15 0 13 5.85 13 13 s -5.85 13 -13 13 z"/>
  </Svg>*/
  return <g {...props}>
    <path
        fill="#E7ECED"
        stroke="#000" strokeWidth="0.25"
        d="M 35 26 H 13 C 5.85 26 0 20.15 0 13 S 5.85 0 13 0 h 22 c 7.15 0 13 5.85 13 13 s -5.85 13 -13 13 z"
      ></path>
  </g>
}

export interface SwitchToggel_Props {
  disabled?:boolean,
  type?:"Vertical_IO"|"Vertical_Select",
  label1?:string,
  label2?:string,
  colorOn?:string,
  colorOff?:string,
  value:boolean,
  onChange:(state:boolean) => void,
}
const SwitchToggel = ({disabled, type, label1, label2, colorOn="#88C057", colorOff="#ED7161", value=false, onChange}:SwitchToggel_Props) => {
  //const { t } = useTranslation()
  const { Layout } = useTheme()
  
  const [SwitchValue, setSwitchValue] = useState<boolean>(value)
  useEffect(() => {
    if (value != SwitchValue) setSwitchValue(value)
  }, [value])

  const Flikkers = [<Flikker key="FlikOff" colorOff={colorOff} State={false}/>, <Flikker key="FlikOn" colorOn={colorOn} State={true}/>]
  const springs = useSprings(Flikkers.length,Flikkers.map((item, index) => {
    return {
      //transform
      transform:SwitchValue
        ? "translate3d(22px, 0px, 0px)"
        : "translate3d(0px, 0px, 0px)",
      opacity: SwitchValue?(index?1:0):(index?0:1)
    }
  }))
  const animatedFlik = springs.map((animatedStyle, index) => 
    <animated.g key={index} style={animatedStyle}>{Flikkers[index]}</animated.g>
  )

  const isRotated = (type&&["Vertical_IO","Vertical_Select"].includes(type))
  return (
    <View style={[Layout.rowCenter, {minHeight:'max-content'}]}>
      {label1||(type&&type.startsWith('Vertical')&&label2)?
        <View key={"lable1"} style={[Layout.column, {marginRight:5, justifyContent:"center"}]}>
          {label1?<Text key={"_label1"} style={[{alignSelf:'flex-start', }]}>{label1}</Text>:(<></>)}
          {type?.startsWith('Vertical')&&
          label2?[<View key={"lable2"} style={{height:5}}/>,<Text key={"_label2"}style={[{alignSelf:'flex-start'}]}>{label2}</Text>]:(<></>)}
        </View>
      :(<></>)}
      <View key={"MainFrame"} style={[{
        width:`${isRotated?"40px":"49px"}`, height:`${isRotated?"49px":"27px"}`,
        justifyContent:'center', alignItems:'center', marginLeft:isRotated?-4:0,
      }]}>
        <TouchableOpacity disabled={false} activeOpacity={1} style={{transform:[{rotate:isRotated?'-90deg':'0deg'}]}}
        onPress={() => setSwitchValue(old => {
          if (onChange) onChange(!old)
          return !old
        })}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="-0.25 -0.5 49 27" width="49px" height="27px"
            //transform={`rotate(${isRotated?'-90':'0'})`}
          >
            <SwitchFrame/>
            {animatedFlik}
          </svg>
        </TouchableOpacity>
      </View>
      {(!type||!type.startsWith('Vertical'))&&label2?
        <View key={"label2"} style={[Layout.column, {marginLeft:5}]}>
          {label2?<Text style={[{alignSelf:'flex-start'}]}>{label2}</Text>:(<></>)}
        </View>
      :(<></>)}
    </View>
  )
}
export default SwitchToggel;
