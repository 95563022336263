import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  View, Text, StyleSheet
} from 'react-native'
import { Button, Datetime_Picker, Labelled_Input } from '../../../Components'
import { Select, TYPE } from "baseui/select"
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import SwitchSelector from '../../../Assets/actions/SwitchButton'

import { useTheme } from '../../../Theme'
import { useTranslation } from 'react-i18next'
//import { navigate, isApp } from '../../Navigators/Root'

import { useAppSelector } from '../../../Store'
//import { useFirebase } from 'react-redux-firebase'

import { UnixDayTime, Operations, MainOperationTypes, Treatment, PenData_Datas } from '../../../Config/Types'
import { Comments, DataCons, Weighings } from '../../../Services/Firebase'
import { OprerationsContext_Action, useOperations_Context } from './Operations_context'

//import OperationsFrame from './OperationFrame' // === The single state components 
//import { ModalStates, ModuleForms } from '.'
//import { NormSorter } from '../../../utils/formaters'
import { useisAppView } from '../../../Navigators/Root'
import { ModalStates } from '.'

interface SettingsProps {
  HaveDate:boolean,
  HaveNote:boolean,
  HaveCodeList:boolean,
  MainCodes:boolean
  HaveQuantity:boolean,
  HaveWeight:boolean,
  HaveAuto:boolean,
  //CanBeInternal:boolean,
  DoCheckZero:boolean,
}

interface FrameMenuProps {
  setFrameChosen:(state:Operations|undefined) => void,
  penData: PenData_Datas | undefined
}
const FrameMenu = ({setFrameChosen, penData}:FrameMenuProps) => {
  const { t } = useTranslation()
  const { Common, Gutters } = useTheme()

  return (
    <View style={[Common.border, Gutters.smallV_Padding, Gutters.tinyH_Padding, {width:'-webkit-fill-available'}]}>
      <Button Contexts={[t('Operations.Weight'),/* t('Operations.Note')*/]} EndEnhancers={undefined} Action={(ele)=>{
        switch(ele) {
          case 0:
            setFrameChosen(Operations.WEIGHNING)
            break
          case 1:
            //setFrameChosen(Operations.NOTE)
            break
        }
      }}/>
      <View style={{height:20}}/>
      <Button Contexts={[t('Operations.Treatment'),t('Operations.AddPig'),/*t('Operations.SoldPig'),*/(penData&&(!penData.count||penData.count<=0))?t('Operations.ClosePen'):t('Operations.MovePig')]} EndEnhancers={undefined} Action={(ele) => {
        switch(ele) {
          case 0:
            setFrameChosen(Operations.TREATMENT)
            break
          case 1:
            setFrameChosen(Operations.ADD)
            break
          case 2:
            if (penData&&(!penData.count||penData.count<=0)) { //CLOSE
              //setClose_ModalCheck(true)
            }
            else setFrameChosen(Operations.MOVE)
            break
          //case 3:
        }
      }}/>
    </View>
  )
}

const Operations_Component = (MainProps:any) => {
  const { t } = useTranslation()
  const { Common, Layout, Fonts, Colors } = useTheme()
  const isAppView = useisAppView()
  
  const { state: { Selection_Data, Operations_Data, Edit_Operations_Data, Selection_Pens, Modal_isDone, PenData:{SelectedPen, penData} }, dispatch } = useOperations_Context()

  //const AppDispatch = useAppDispatch()
  //const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  //const activefarm = useAppSelector(state => state.activeData.farm)
  const Treatments = useAppSelector(state => Comments.GetTreatments(state, undefined))

  //const _penData = useAppSelector(state => Selection_Data&&Selection_Data.pen?FBPens.GetPenData(state, Selection_Data.pen, Selection_Data.isShared):undefined)
  const DataConsData = useAppSelector(state => Selection_Data&&Selection_Data.pen?DataCons.GetDataCons_Pen(state, Selection_Data.pen, Selection_Data.isShared):undefined)
  //const DataConsData = useAppSelector(state => _penData&&_penData.datas&&_penData.datas.autopigs?DataCons.GetPenDataCon(state, Selection_Data.isShared, _penData.datas.autopigs):undefined)

  const [IsEdit, setIsEdit] = useState(false)
  //const [LastSelected, setLastSelected] = useState<{pen:number|undefined, section:string|undefined, isDataLink:string|undefined}|{id:string, isWeight?:true}>({pen:undefined, section:undefined, isDataLink:undefined})
  
  const {MainCodeListItem, CodeListItem} = useMemo(() => {
    var Code: string[]|undefined = Operations_Data.code&&Operations_Data.code?Operations_Data.code.split('_'):undefined
    var Main:Treatment = (Code?MainOperationTypes[Code[0]]:MainOperationTypes["-1"])
    
    var Sub:Treatment|undefined = undefined
    if (!Code || Code[1] === "-1") Sub = ({code:'-1', description:"---", death:false, used:true} as Treatment)
    else if (Code && Code[1] !== undefined) {
      Sub = Treatments.find(e => e.code === Code[1])
    }
    return {MainCodeListItem: Main, CodeListItem: Sub}
  }, [Operations_Data.code, Treatments])
  
  const WeightRef = useRef(null)
  const QuantRef = useRef(null)
  const NoteRef = useRef(null)

  /*const weighings = useAppSelector(state => {
    if (SelectedPen!==undefined) {
      return Weighings.GetPenWeights(state, Selection_Data.isShared&&Selection_Data.isShared!==""?Selection_Data.isShared:undefined, Selection_Data.pen, false)
    }
    return []
  })*/

  /*const WeighingsOptions = useMemo(() => weighings&&Array.isArray(weighings)?weighings.filter(e => !e.edited&&!e.deleted).map((data) => {
    if (!data.key) return null
    var time = new Date(data.time)
    return ({label:time.getFullYear() + '/' + (time.getMonth()+1) + '/' + time.getDate() + ' - ' + data.count +' * '+ data.weight, id:data.key,})
  }).filter(e => e):[],[weighings])*/

  const Settings = useMemo<SettingsProps|undefined>(() => {
    if (Operations_Data.action!==Operations.START && Operations_Data.action!==Operations.CLOSE) {
      console.log("Load", Operations_Data.action)
      if (Operations_Data.action===Operations.WEIGHNING) {
        if (!Edit_Operations_Data&&penData&&penData.count) {
          if (Operations_Data.quantity !== penData.count) dispatch({type:'set_Operations_Data', payload:{quantity:penData.count}})
        }
        dispatch({type:'set_Operations_Data', payload:{...Operations_Data, isTotal:true}})
      }
      else dispatch({type:'set_Operations_Data', payload:{...Operations_Data, isTotal:false}})
    }
    return {
      HaveDate: true, //Operations_Data.action!==Operations.WEIGHNING||(Operations_Data.action===Operations.WEIGHNING && !Edit_Operations_Data),
      HaveNote: Operations_Data.action!==Operations.WEIGHNING,
      MainCodes: Operations_Data.action===Operations.MOVE||Operations_Data.action===Operations.CLOSE,
      HaveCodeList: [Operations.TREATMENT, Operations.MOVE, Operations.CLOSE].some(e => e===Operations_Data.action),
      HaveQuantity: true, //Operations_Data.action!==Operations.NOTE,
      HaveWeight: [Operations.ADD, Operations.START, Operations.MOVE, Operations.CLOSE, Operations.WEIGHNING].some(e => e===Operations_Data.action),
      HaveAuto: [Operations.ADD, Operations.START, Operations.MOVE, Operations.CLOSE, Operations.WEIGHNING].some(e => e===Operations_Data.action) && ((DataConsData&&Object.values(DataConsData).some(e => e.isCorridor))||(penData&&penData.autopigs?true:false)),//!isEmpty(AutoPigs),
      DoCheckZero: [Operations.MOVE, Operations.CLOSE].some(e => e===Operations_Data.action),
      CanWeightEdit: Operations_Data.action===Operations.WEIGHNING,
    }
  }, [Operations_Data.action, penData])//[FrameChosen])

  useEffect(() => {
    if(NoteRef && NoteRef.current) NoteRef.current.focus()
    else if (QuantRef &&QuantRef.current) {
      QuantRef.current.focus()
    }
  }, [QuantRef.current, Settings])

  useEffect(() =>{
    dispatch({type:'set_Operations_Data', payload:{}})
    if(!Edit_Operations_Data) {
      setIsEdit(false)
      dispatch({type:'set_Operations_Data', payload:{
        quantity:Operations_Data.action===Operations.WEIGHNING&&penData&&penData.count?penData.count:1,
        time: Date.now()
      }})
    } 
    else {
      setIsEdit(true)
      //Edit_Operations_Data.action===Operations.START
      var action = Edit_Operations_Data.action&&Edit_Operations_Data.action!==true?Edit_Operations_Data.action:Operations.WEIGHNING
      dispatch({type:'set_Operations_Data', payload:{...Edit_Operations_Data, 
        quantity:Edit_Operations_Data.count?Edit_Operations_Data.count:Edit_Operations_Data.quantity,
        action:action
      }})
    }
  }, [Edit_Operations_Data])

  if (!Selection_Data || Selection_Data.section === undefined || Selection_Data.pen === undefined) {
    if (Selection_Data.section === undefined) return (<Text>{"Something is Wrong... no section selected"}</Text>)
    else if (Selection_Data.pen === undefined) return (<Text>{"Something is Wrong... no pen selected"}</Text>)
    return (<Text>{"Something is Wrong... no selected state"}</Text>)
  } else {
    if (!Operations_Data||!Operations_Data.action) {
      //if (!FrameChosen) { //PureFrame / Menu Selector
      return <FrameMenu setFrameChosen={(state) => dispatch({type:'set_Operations_Data', payload:{action:state}})} penData={penData}/>
    }
    
    function commentTitle () {
      if(Operations_Data.action===Operations.WEIGHNING)
        return t('Operations.Weight')
      else if(Operations_Data.action===Operations.TREATMENT)
        return t('Operations.Treatment')
      else if(Operations_Data.action===Operations.ADD||Operations_Data.action===Operations.START)
        return t('Operations.AddPig')
      else if(Operations_Data.action===Operations.MOVE||Operations_Data.action===Operations.CLOSE)
        return t('Operations.MovePig')
      else return t('Operations.OperationTitle')
    }
    //else { //Comment Window / Selected Operation
      return (
        <View style={[Common.border, {width:'-webkit-fill-available', paddingVertical:10, paddingHorizontal:5}]}>
          <View style={styles.textAreaContainer} >
            <View style={[Layout.row, {justifyContent:'space-between', flexWrap:'wrap'}]}>
              {/* Column 1: Title, Date/WeightSelector, Code */}
              <View style={[Layout.column, {marginBottom:5, width:'fit-content', height:'fit-content'}]}>
                {/*Selected_Datas&&Selected_Datas.OperationItem&&Selected_Datas.OperationItem.isDone!==undefined?
                  <Text style={[Fonts.textRegular, {height: 26, color:Selected_Datas.OperationItem.isDone?Colors.error:Colors.success}]}>{
                    Selected_Datas.OperationItem.isDone?Selected_Datas.OperationItem.isDone:t('Weighings.Committed')
                  }</Text>
                :<></>*/}
                <Text style={[Fonts.textRegular, {height: 26, color:Colors.input}]}>
                  {commentTitle()}
                </Text>

                {/*Operations_Data.action === Operations.WEIGHNING?
                  <Checkbox
                    labelPlacement={LABEL_PLACEMENT.left}
                    overrides={{
                      Root: { style: ({ $theme }) => ({
                        alignItems: "center",
                        minWidth:'min-content', marginLeft:'2px',marginRight:'20px',
                        minHeight:'48px'
                      })},
                      Label: { style: ({ $theme }) => ({
                        justifyContent:'flex-end', paddingBottom:'5px',
                        minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                      })}
                    }}
                    checked={IsEdit}
                    onChange={(e) => setIsEdit(old => !old)}
                  >
                    <Text style={[{}]}>{t('Weighings.EditWeights')}</Text>
                  </Checkbox>
                  :<></>*/}
                {/* Date element inputs display */}
                {Settings&&Settings.HaveDate?(
                  <View style={[Layout.fill, {alignItems:'flex-start', marginBottom:10}]}>
                    <Datetime_Picker value={{start:Operations_Data.time||Date.now()}} single={true}
                      onValueChange={(time) => {
                        if (time) {
                          dispatch({type:'set_Operations_Data', payload:{time:time.start}})  
                        }
                      }}
                    />
                  </View>
                ):<></>}
                {// # Treatment  - .quantity & .weight
                Settings&&Settings.HaveCodeList?(
                  <View style={[Layout.fill, {justifyContent:'space-between'}]}>
                    {Settings.MainCodes&&(
                    <Select clearable={false} required
                      options={Object.values(MainOperationTypes).map((Operation)=> ({
                          id:Operation.code, label: Operation.description, item:Operation
                      }) )}
                      value={[{ item:MainCodeListItem,
                        id:MainCodeListItem?MainCodeListItem.code:MainOperationTypes["0"].code,
                        label:MainCodeListItem?MainCodeListItem.description:MainOperationTypes["0"].description
                      }]}
                      type={TYPE.select}
                      placeholder="Main Code"
                      onChange={(params:any) => {
                        console.log("test", params.value)
                        if (params.value && params.value[0] && params.value[0].id) {
                          var Code = params.value[0].id + "_" + (CodeListItem?.code||"-1")
                          dispatch({type:'set_Operations_Data', payload:{code:Code}})
                        }
                      }}
                      maxDropdownHeight="300px"
                      overrides={{
                        Root: { style: ({ }) => ({
                          maxWidth:'606px', outline:'black 1px solid'
                        })},
                        ValueContainer: {style: ({ }) => ({
                          height: "30px",
                          paddingLeft:'2px', paddingRight:'2px', paddingTop:'2px', paddingBottom:'2px'
                        })}
                      }}
                    />)}
                    <Select clearable={false} required
                      options={[{code:'-1', description:"---", death:false, used:true} as Treatment].concat(Treatments).filter(A => A && A.used)
                        .map((Operation)=> ({
                          id: Operation.code,
                          label: Operation.code==='-1'?Operation.description : Operation.code + '# ' + Operation.description,
                          item: Operation
                        })
                      )}
                      value={[{
                        id:CodeListItem?CodeListItem.code:'-1',
                        label:CodeListItem? (CodeListItem.code==='-1'?CodeListItem.description : CodeListItem.code + '# ' + CodeListItem.description):'---',
                        item:CodeListItem
                      }]}
                      type={TYPE.select}
                      placeholder="Sub Code"
                      onChange={(params:any) => {
                        if (params.value && params.value[0] && params.value[0].id) {
                          var Code = (MainCodeListItem?.code||"-1") + "_" + (params.value[0].id)
                          dispatch({type:'set_Operations_Data', payload:{code:Code}})
                        }
                      }}
                      maxDropdownHeight="300px"
                      overrides={{
                        Root: { style: ({ }) => ({
                          maxWidth:'606px', outline:'black 1px solid'
                        })},
                        ValueContainer: {style: ({ }) => ({
                          height: "30px",
                          paddingLeft:'2px', paddingRight:'2px', paddingTop:'2px', paddingBottom:'2px'
                        })}
                      }}
                    />
                  </View>
                ):<></>}
              </View>
              
              <View style={[Layout.column, isAppView?Layout.fullWidth:{}, {}]}>
                {Operations_Data.action === Operations.WEIGHNING && IsEdit?
                  <View style={[Layout.fill, Layout.row, {maxWidth:'max-content', minWidth:'min-content',  alignSelf:'flex-end', maxHeight:55, marginBottom:5}]}>
                    {Edit_Operations_Data?
                      <Button Contexts={t('Weighings.DeleteWeights')} maxWidth={180} Action={() => {
                        if (Edit_Operations_Data && Edit_Operations_Data.key) {
                          dispatch({type:'set_Operations_Data', payload:{deleted:true}})

                          dispatch({type: 'set_Modal_isDone', payload:true})
                          dispatch({type: 'set_Operations_isDone', payload:true})
                          setIsEdit(false)
                        }
                      }}/>
                    :<></>}
                    {/*WeighingsOptions?
                      <Select clearable={false} type={TYPE.select}
                        options={WeighingsOptions}
                        value={
                          WeighingsOptions&&Edit_Operations_Data?
                          WeighingsOptions.find(item => item&&item.id===Edit_Operations_Data.key)?[WeighingsOptions.find(item => item&&item.id===Edit_Operations_Data.key)]:undefined:undefined
                        }
                        placeholder="---"
                        onChange={(params:any) => {
                          var value = params.value[0]
                          if (value && value.id) {
                            var item = weighings&&Array.isArray(weighings)?weighings.find(item => (item&&item.key===value.id)):undefined
                            if (item && item.key) {
                              dispatch({type:'set_Selected_Edit', payload:{...item}})
                              //delete item.key
                              //dispatch({type:'set_Operations_Data', payload:{...item, action:Operations.WEIGHNING}})
                            }
                          }
                        }}
                        maxDropdownHeight="300px"
                        overrides={{
                          Root: { style: ({ $theme }) => ({
                            maxWidth:'200px', minWidth:'150px', maxHeight:'46px',
                            outline:'black 1px solid'
                          })}
                        }}
                      />
                      :<></>*/}
                  </View>
                :<></>}
                {// # Quantity & Weight - .quantity & .weight
                Settings&&(Settings.HaveQuantity||Settings.HaveWeight)?(
                  <View style={[Layout.fill, {maxWidth:'max-content', minWidth:'min-content', maxHeight:'fit-content', justifyContent:'flex-end', alignSelf:'flex-end'}]}>
                    {Settings.HaveQuantity?(
                      <View style={[Layout.rowCenter, {marginBottom:5, width:'fit-content', height:'fit-content', justifyContent:'center', alignSelf:'flex-end'}]}>
                        <Text style={[Fonts.normal, {width:70, color:Colors.input, textAlignVertical:'center'}]}>{t('Operations.Quantity')}</Text>
                        <Labelled_Input maxWidth={105} minHeight={30} Ref={QuantRef}
                          type={"number"}
                          value={(Operations_Data.quantity||"")+''}
                          onChange={(text, num) => {
                            if (text === "") dispatch({type:'set_Operations_Data', payload:{quantity:undefined}})
                            else dispatch({type:'set_Operations_Data', payload:{quantity:num}})
                          }}
                        />
                      </View>
                    ):<></>}
                    {Settings.HaveWeight?(
                      <View style={[Layout.rowCenter]}>
                        {Settings.HaveAuto?
                          <Button maxWidth={90} Contexts={t('SystemBoard.fetchLabel')} Action={(e) => {
                            dispatch({type: 'set_Modal_isDone', payload:false})
                            dispatch({type: 'set_ModalForm', payload:ModalStates.FETCHWEIGHT})
                          }}/>
                        :<></>}
                        <View style={{width:10}}/>
                        <SwitchSelector type='Vertical_Select' value={Operations_Data.isTotal?Operations_Data.isTotal:false} 
                          label1={t('Weighings.Weight') + " " + t('DataTypes.W')} colorOn={Colors.AgriDarkblue} 
                          label2={t('Operations.AvgWeight')  + " " + t('DataTypes.W')} colorOff={Colors.AgriLightblue} 
                          onChange={(state) => {
                            dispatch({type:'set_Operations_Data', payload:{isTotal:state}})
                            //setisTotal(state)
                          }}
                        />
                        <Labelled_Input maxWidth={85} minHeight={30} Ref={WeightRef}
                          value={(Operations_Data.weight||"")+''}
                          onChange={(text, num) => {
                            if (text === "" || text.endsWith(',') || text.endsWith('.')) dispatch({type:'set_Operations_Data', payload:{weight:text.replace(',','.')}})
                            else dispatch({type:'set_Operations_Data', payload:{weight:num}})
                          }}
                        />
                      </View>
                    ):<></>}
                  </View>
                ):<></>}
              </View>
            </View>
            {Settings&&Settings.HaveNote?
              <Labelled_Input multiline minHeight={150} Ref={NoteRef}
                placeholder={t('CommentModule.NotePlaceholder')}
                onChange={text => {dispatch({type:'set_Operations_Data', payload:{data:text}})}}
                value={(Operations_Data.data||"")}
              />
            :<></>}
          </View>
          <Button Contexts={[t('ActionButtons.Done')].concat(/*ViewSettings.ModuleForm!==ModuleForms.WeightPage?*/[t('ActionButtons.Cancel')]/*:[]*/)} Frame Action={(e)=> {
            if (!e) {
              var Dount_ViewModal = false
              //console.log("Datas", penData)

              if((Operations_Data.action === Operations.MOVE||Operations_Data.action === Operations.CLOSE)&&Operations_Data.quantity&&penData&&
                  (!penData.count||Operations_Data.quantity>=penData.count)) {//IS CLOSE PEN
                dispatch({type:'set_Operations_Data', payload:{action:Operations.CLOSE}})
                dispatch({type:'set_ModalForm', payload:{state:ModalStates.CLOSEPEN,res:Operations_Data.isTotal}})
              } 
              else if((Operations_Data.action === Operations.ADD||Operations_Data.action === Operations.START)&&Operations_Data.quantity&&penData&&
                      (!penData.count||penData.count===0)) { //IS START PEN
                dispatch({type:'set_Operations_Data', payload:{action:Operations.START}})
                if (penData.autopigs) {
                  dispatch({type:'set_ModalForm', payload:{state:ModalStates.STARTPEN,res:Operations_Data.isTotal}})
                }
                else Dount_ViewModal = true
              } 
              else { //Is treatment or Weight
                Dount_ViewModal = true
              }
              dispatch({type: 'set_Modal_isDone', payload:Dount_ViewModal})
              dispatch({type: 'set_Operations_isDone', payload:true})
              setIsEdit(false)
            }
            else {
              setIsEdit(false)
              dispatch({type:'set_Operations_Data', payload:{}})
              if (Edit_Operations_Data) {
                dispatch({type:'set_Selected_Edit', payload:undefined})
              }
             // dispatch({type:'set_OperationItem', payload:{...Selected_Datas.OperationItem, Operation:Operations.EMPTY}})
            }
          }}/>
        </View>
      )
    //}
  }
}

const styles = StyleSheet.create({
  textAreaContainer: {
    borderColor: '#a6a6a6',
    borderWidth: 1,
    paddingLeft:'2.5px', paddingRight:'2.5px', paddingBottom:'2.5px',
    marginBottom:8
  },
  textArea: {
    height: 150,
    justifyContent: "flex-start",
    borderColor: "black",
    borderWidth: 0.5,
  }
})

export default Operations_Component