import { configureStore, EntityState } from '@reduxjs/toolkit'
import { combineReducers, Dispatch, EmptyObject } from 'redux'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
//import { actionTypes } from 'redux-firestore'
//import thunk from "redux-thunk" 

import {
  persistReducer,
  persistStore,
  //FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
} from 'redux-persist'

//import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import AsyncStorage from '@react-native-async-storage/async-storage'
import { actionTypes, FirebaseReducer, firebaseReducer } from 'react-redux-firebase'

//#region Stores
import InitReducer, { InitStates } from './StateHandlers/Init-slice'
import ActiveReducer, { ActiveStates } from './StateHandlers/Active-slice'
import { Firebase_Reducer, fbConfig, rrfConfig } from './StateHandlers/Firebase-slice'
//#endregion

//#region Consys Services
import * as ConsysApi from '../Services/Consys/Consys'
//import { ConsysAPI } from '../Services'
/*const ConsysReducer = combineReducers({
  [ConsysAPI.TokenApi.consysTokenApi.reducerPath]: ConsysAPI.TokenApi.consysTokenApi.reducer,
  [ConsysAPI.SystemsCall.systeminfo.reducerPath]: ConsysAPI.SystemsCall.systeminfo.reducer,
}ActiveReducer*/
//#endregion

//#region Firebase Imports and config
//import { createFirestoreInstance, firestoreReducer } from 'redux-firestore'; // <- needed if using firestore
/*import firebase from "@react-native-firebase/app"
import "@react-native-firebase/auth"
import "@react-native-firebase/firestore"
import "@react-native-firebase/database"*/

import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/app-check'
import 'firebase/compat/analytics'
//import 'firebase/compat/firestore' // make sure you add this for firestore
import 'firebase/compat/database'
import { PersistPartial } from 'redux-persist/lib/persistReducer'
//import { createContext } from 'react'

// Initialize firebase instance
try {
  if (!firebase.apps.length) {
    firebase.initializeApp(fbConfig)
    // Initialize other services on firebase instance
    //firebase.firestore()
    firebase.auth()
  }
}catch(err){

}

if (firebase.auth()) {
  firebase.auth().onAuthStateChanged((user) => {
    if (user && !user.emailVerified) {
      //user.sendEmailVerification()
    }
  })
  firebase.analytics()
  const appCheck = firebase.appCheck()
  appCheck.activate(new firebase.appCheck.ReCaptchaV3Provider(`${process.env.RECAPTCHA_SITE}`),true)
  appCheck.onTokenChanged((e) => {
    //if (e && e.token) console.debug("token: ", e.token)
    //firebase.appCheck().getToken().then((token) => {
    //  console.log("token: ", token.token)
    //}).catch(error => {
    //  console.log("token error", error)
    //})
  })
}
//#endregion

//#region Reducer setups
const rootPersistConfig = {
  key: 'root',
  storage: AsyncStorage,
  blacklist: ['startup', 'firebase', 'activeData', 'consysData', 'ConsysApi', 'XTernConsysApi'],
}
const InitPersistConfig = {
  key: 'startup',
  storage: AsyncStorage,
  blacklist: ['status', 'consysTok', 'XTernToken']
}

interface RootStates {
  startup: EntityState<unknown> & InitStates & PersistPartial,
  firebase: Firebase_Reducer,
  activeData: EntityState<unknown> & ActiveStates,
  [ConsysApi.ConsysApi.reducerPath]:any,
  //AppDatas:{Test: FBActivityS,Sections: FBSectionsS}
}

const appReducer = combineReducers<RootStates>({
  startup: persistReducer(InitPersistConfig, InitReducer),
  firebase: firebaseReducer,
  //firestore: firestoreReducer, // <- needed if using firestore
  activeData: ActiveReducer,
  [ConsysApi.ConsysApi.reducerPath]: ConsysApi.ConsysApi.reducer,
  /*AppDatas: combineReducers({
    Sections: FBSectionsR,
  })*/
})

const rootReducers = (state:any, action:any) => {
  if (action.type === '@@reactReduxFirebase/LOGOUT') {
    //Do nothing... it is clearing allready and .. this below is not working
    //if (state && state.firestore && state.firestore.data) state.firestore.data = {}
    //if (state && state.firebase && state.firebase.data) state.firebase.data = {}
  } else if (action.type === '@@reactReduxFirebase/CLEAR_DATA' || action.type === '@@reduxFirestore/CLEAR_DATA') {
    
  }
  return appReducer(state, action)
}
const persistedReducer = persistReducer(rootPersistConfig, rootReducers)
//#endregion

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      /*serializableCheck: {
        ignoredActions: [
          FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
        ]
      },*/
    })
    //Consys Specefic Api
    .concat(ConsysApi.ConsysApi.middleware)
})

// react-redux-firebase props
export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  //createFirestoreInstance, // <- needed if using firestore
}

export let persistor = persistStore(store)

export type StoreAppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type RootStates_T = (EmptyObject&RootState&PersistPartial)

export const useAppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const ClearData = (dispatch:Dispatch<any>) => {
  dispatch({ type: actionTypes.REMOVE, path:'pens' })
  dispatch({ type: actionTypes.REMOVE, path:'sections' })
  //dispatch({ type: actionTypes.REMOVE, path:'agri_graphs' })
  dispatch({ type: actionTypes.REMOVE, path:'local_graphs' })
  dispatch({ type: actionTypes.REMOVE, path:'xtern_graphs' })
  dispatch({ type: actionTypes.REMOVE, path:'periodes' })
  dispatch({ type: actionTypes.REMOVE, path:'system' })
  dispatch({ type: actionTypes.REMOVE, path:'DataCons' })
  dispatch({ type: actionTypes.REMOVE, path:'weighings' })
  dispatch({ type: actionTypes.REMOVE, path:'comments' })
  dispatch({ type: actionTypes.REMOVE, path:'DataLinks' })
  dispatch({ type: actionTypes.REMOVE, path:'LinkList' })
  dispatch({ type: actionTypes.REMOVE, path:'LinkListData' })
  dispatch({ type: actionTypes.REMOVE, path:'DataLinkList' })
}

export default store