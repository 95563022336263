import { useContext } from 'react'
import { isEmpty, LinkListData, SectionData } from '../../Config/Types'
import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { createSelector } from 'reselect'
import get from 'lodash/get'

export const fetchSections = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = []):any[] => {
  if (FarmId && FarmId !== "") {
    const path = `farms_data/${FarmId}/`
    Calls.push({type:'value', path:path + 'sections', queryParams: ['orderByKey'], storeAs: 'sections'})
    //Calls.push({type:'child_changed', path:path + 'sections', queryParams: ['orderByKey'], storeAs: 'sections'})
  }

  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

export interface SectionListProp {id:string, data:SectionData}
export const GetSectionsList = createSelector(
  [
    (state:any) => get(state.firebase.data, `sections`),
    (state:any) => get(state.firebase.data, `LinkListData`),
  ],
  (_LocalSections:Record<string,SectionData>, _ExternSections:Record<string,LinkListData>):SectionListProp[] => {
    var ExternSections:SectionListProp[] = _ExternSections?Object.entries(_ExternSections).reduce((res:SectionListProp[], [FarmKey, FarmData]) => {
      if (FarmData.sections) {
        var Sections:SectionListProp[] = Object.entries(FarmData.sections).map(([Key, Data]) => {
          return {id:Key, data:{pens:Data.pens, isDataLink:FarmKey} as SectionData}
        })
        return res.concat(Sections)
      }
      return res
    }, []):[]
    var LocalSections = _LocalSections?Object.entries(_LocalSections).map(([Key, Data]) => {
      return {id:Key, data:{pens:Data.pens, isDataLink:undefined} as SectionData}
    }):[]
    return ExternSections.concat(LocalSections)
  }
)

export const GetSections = createSelector(
  [
    (state:any, IsDataLink?:string) => get(state.firebase.data, IsDataLink?`LinkListData.${IsDataLink}.sections`:`sections`, undefined),
  ],
  (Sections:Record<string,SectionData>):Record<string,SectionData>|undefined => {
    return Sections
  }
)