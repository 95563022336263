import React, { useEffect, useState } from 'react'
import {
  View,
  Clipboard, 
} from 'react-native'
import { Button, LoadingSplash, Labelled_Input } from '../../Components'
//import Clipboard from '@react-native-clipboard/clipboard'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, isApp } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded, isEmpty, useFirebaseConnect } from 'react-redux-firebase'

import { FarmData, Treatment, OperationType, OperationTypes, MainOperationTypes, UserStates } from '../../Config/Types'
import { DataStringCheck } from '../../utils/formaters'

const FarmInformation = () => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  
  const firebase = useFirebase()

  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector(({firebase}) => firebase.profile)
  const SystemStatus = useAppSelector(({startup}) => startup)
  
  const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const [FarmName, setFarmName] = useState("")
  const [Address, setAddress] = useState("")
  const [Country, setCountry] = useState("")
  const [CVR, setCVR] = useState<number|string|"">("")
  const [Manager, setManager] = useState("")
  const [EMail, setEMail] = useState("")
  const [Phone, setPhone] = useState("")

  useEffect(() => {
    if (farmData.name) setFarmName(farmData.name)
    if (SystemStatus.CookieLevel) {
      if (farmData.address) setAddress(farmData.address)
      if (farmData.country) setCountry(farmData.country)
      if (farmData.cvr) setCVR(farmData.cvr)
      if (farmData.manager) setManager(farmData.manager)
      if (farmData.email) setEMail(farmData.email)
      if (farmData.phoneNumber) setPhone(farmData.phoneNumber)
    }
  }, [farmData])

  const Save_Settings = () => {
    if (profile.state===UserStates.WORKER) navigate('User', {screen:'Home'})
    else if (FarmName && FarmName !== "") {
      var info:FarmData
      if (SystemStatus.CookieLevel) {
        info = {name:FarmName, address:Address!==""?Address:null, country:Country!==""?Country:null, cvr:CVR!==""?CVR:null, manager:Manager!==""?Manager:null, email:EMail!==""?EMail:null, phoneNumber:Phone!==""?Phone:null}
      } else info = {name:FarmName}
      firebase.update(`farms/${activefarm}`, info).then(e => {
        navigate('User', {screen:'Home'})
      }, e2=>{})
    }
  }

  //Auth Check Handler
  useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms) || !activefarm) return <LoadingSplash/>
  else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[Layout.colVCenter, Layout.fullWidth]}>
          <Button Contexts={t('FarmInfo.Title') + activefarm} Title Action={() => {
            Clipboard.setString(activefarm)
          }}/>
          <View style={[Layout.col, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px'}]}>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Labelled_Input maxWidth={200} minWidth={120} disabled={profile.state===UserStates.WORKER}
                value={FarmName}
                label={t('FarmInfo.Prop1')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text})) {
                      setFarmName(text)
                    }
                  }else {
                    setFarmName(text)
                  }
                }}
              />
            </View>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Labelled_Input maxWidth={200} minWidth={120} disabled={!SystemStatus.CookieLevel||profile.state===UserStates.WORKER}
                value={Address}
                label={t('FarmInfo.Prop2')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text})) {
                      setAddress(text)
                    }
                  }else {
                    setAddress(text)
                  }
                }}
              />
              <Labelled_Input maxWidth={200} minWidth={120} disabled={!SystemStatus.CookieLevel||profile.state===UserStates.WORKER}
                value={Country}
                label={t('FarmInfo.Prop3')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text})) {
                      setCountry(text)
                    }
                  }else {
                    setCountry(text)
                  }
                }}
              />
            </View>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Labelled_Input maxWidth={200} minWidth={120} disabled={!SystemStatus.CookieLevel||profile.state===UserStates.WORKER}
                value={CVR +''}
                label={t('FarmInfo.Prop4')}
                placeholder={""}
                onChange={(text, num) => {
                  setCVR(num)
                }}
              />
              <Labelled_Input maxWidth={200} minWidth={120} disabled={!SystemStatus.CookieLevel||profile.state===UserStates.WORKER}
                value={Manager}
                label={t('FarmInfo.Prop5')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text})) {
                      setManager(text)
                    }
                  }else {
                    setManager(text)
                  }
                }}
              />
              <Labelled_Input maxWidth={200} minWidth={120} disabled={!SystemStatus.CookieLevel||profile.state===UserStates.WORKER}
                value={EMail}
                label={t('FarmInfo.Prop6')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text, AllowSpecial:true})) {
                      setEMail(text)
                    }
                  }else {
                    setEMail(text)
                  }
                }}
              />
              <Labelled_Input maxWidth={200} minWidth={120} disabled={!SystemStatus.CookieLevel||profile.state===UserStates.WORKER}
                value={Phone}
                label={t('FarmInfo.Prop8')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text, isNumbers:true, Check_firstnum:false})) {
                      setPhone(text)
                    }
                  }else {
                    setPhone(text)
                  }
                }}
              />
            </View>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Button Contexts={t("FarmInfo.Copy")} maxWidth={200} Title Action={() => {
                Clipboard.setString(activefarm)
              }}/>
              <Labelled_Input maxWidth={200} minWidth={120}
                value={activefarm}
                label={""}
                placeholder={"Something went Wrong."}
                onChange={(text) => { }}
              />
            </View>
          </View>
        </View>
        <Button Contexts={[t('ActionButtons.Save'),t('ActionButtons.Cancel')]} Frame Action={(e)=>!e?Save_Settings():navigate('User', {screen:'Home'})}/>
      </View>
    )
  }
}

export default FarmInformation