import React, { useMemo } from 'react'
import { View, Image, Text, TouchableOpacity } from 'react-native'
import { ArrowLeft, ArrowRight } from "baseui/icon";
import { useTheme } from '../../Theme'

export interface Button_Props {
  //children: React.ReactNode,

  Contexts: string|string[],
  StartEnhancers?: string|string[],
  EndEnhancers?: string|string[],

  Action?: (e:number) => void,

  maxWidth:number|string,
  Frame: boolean,
  Title: boolean,
  Arrows: boolean|{left:boolean, right:boolean},
  onPageSwipe?: (e:boolean) => void,
}

const Button_Icon = (src:string) => {
  return (
    <View style={[{height:50, width:'25%', alignItems:'center',justifyContent:'center', marginTop:'5px', marginBottom:'5px'}]}>
      {src&&src!==""?<Image style={[{height:'100%', width:'100%', maxWidth:55}]} source={{uri:src}} resizeMode={'contain'} />:<></>}
    </View>
  )
}

const Button = ({Contexts, StartEnhancers, EndEnhancers, Action, maxWidth, Frame, Title, Arrows, onPageSwipe, ...rest}: Button_Props) => {
  const { Common, Gutters, Layout, Fonts, Colors } = useTheme()
  var _Contexts = Contexts?Array.isArray(Contexts)?Contexts:[Contexts]:[""]
  const {LeftArrow, RightArrow} = useMemo(() => {
    var left = false, right = false
    if (Arrows) {
      if (typeof Arrows === "boolean") {
        left = true
        right = true
      }
      else {
        if (Arrows.left) left = Arrows.left
        if (Arrows.right) right = Arrows.right
      }
    }
    return {LeftArrow:left, RightArrow:right}
  }, [Arrows])
  return (
    <View style={[Layout.fullWidth, Layout.row, Gutters.tinyBMargin, {justifyContent:'center', maxWidth: maxWidth}, Frame?Gutters.smallV_Padding:{}]}>
      {Arrows?(
        <View style={[{padding:6, justifyContent:'center', width:'fit-content', minWidth:50}]}>
          {LeftArrow?(
            <TouchableOpacity style={[{padding:6, justifyContent:'center', width:'fit-content'}]} 
              onPress={() => onPageSwipe?onPageSwipe(false):{}}
            >
              <ArrowLeft size={35} color="black" title={""}/>
            </TouchableOpacity>
          ):(<></>)}
        </View>
      ):(<></>)}
        <View style={[Arrows?{width:'70%'}:{width:'100%'}, Frame?Common.border:{}, Frame?Gutters.tinyPadding:{}]}>
          {_Contexts&&Array.isArray(_Contexts)?_Contexts.map((text,index) => {
            var S_icon = StartEnhancers?((typeof StartEnhancers === 'string')?Button_Icon(StartEnhancers):Button_Icon(StartEnhancers[index])):null
            var E_icon = EndEnhancers?((typeof EndEnhancers === 'string')?Button_Icon(EndEnhancers):Button_Icon(EndEnhancers[index])):null
            return (
              <TouchableOpacity key={index} disabled={Action?false:true} style={[Layout.fill, Common.button.filledRounded, {marginHorizontal:2}, index>0?Gutters.smallTMargin:{}, E_icon?{minHeight:60}:{minHeight:50}, Title?{backgroundColor:Colors.AgriDarkblue, borderColor:Colors.AgriDarkblue}:{}]} 
                onPress={() => Action?Action(index):{}}
              >
                {E_icon||S_icon?(
                  <View style={[Layout.fill, Layout.rowCenter, {width:'100%'}]}>
                    {S_icon?S_icon:<></>}
                    <Text style={[Layout.fill, Fonts.textCenter, Fonts.textSmall, Fonts.textReverse]}>{text}</Text>
                    {E_icon?E_icon:<></>}
                  </View>
                ):(
                  <View style={[Layout.fill, Layout.rowCenter]}>
                    <Text style={[Layout.fill, Fonts.textCenter, Fonts.textSmall, Fonts.textReverse]}>{text}</Text>
                    {(StartEnhancers && StartEnhancers.length > 0) || (EndEnhancers && EndEnhancers.length > 0) && (
                      <View style={[Layout.colCenter, Layout.quarterWidth, {height:50}]}/>
                    )}
                  </View>
                )}
              </TouchableOpacity>
            )
          }):<></>}
        </View>
      {Arrows?(
        <View style={[{padding:6, justifyContent:'center', width:'fit-content', minWidth:50}]}>
          {RightArrow?(
            <TouchableOpacity style={[{width:'fit-content'}]}
              onPress={() => onPageSwipe?onPageSwipe(true):{}}
            >
              <ArrowRight size={35} color="black" title={""}/>
            </TouchableOpacity>
          ):(<></>)}
        </View>
      ):(<></>)}
    </View>
  )
}

Button.defaultProps = {
  Contexts: [""],

  maxWidth:600,
  Frame: false,
  Title: false,
  Arrows: false,
}

export default Button
