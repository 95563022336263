import React, { useEffect, useState, useRef, useMemo } from 'react'
import { View, Text, ActivityIndicator } from 'react-native'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import useDetectPrint from 'react-detect-print'

import { ContentProp } from '../'

import {
  StatefulDataTable,
  DatetimeColumn,
  CategoricalColumn,
  NumericalColumn,
  StringColumn,
  SORT_DIRECTIONS,
  NUMERICAL_FORMATS
} from "baseui/data-table"
import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic'
import { isEmpty } from '../../Config/Types'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import { FeedInfo } from '../../Services/Consys/Feed-Slice'

export interface FeedSiloList_Props {
  FeedsList: FeedInfo[],
  isFetching?: boolean,
  FixedDisplay?: boolean,
  GenerateCSV_Data?: () => void
}

const FeedSiloList = ({FeedsList, isFetching, FixedDisplay, GenerateCSV_Data, ...rest}: FeedSiloList_Props) => {
  const { t } = useTranslation()
  const { Layout } = useTheme()
  const isPrinting  = useDetectPrint()
  
  const [SortMap, setSortMap] = useState<Record<string, {
    range: Date[], lowerValue: number, upperValue:number, description:string, exclude:boolean, selection:Set<number>, comparisons:{operation: string, value: number}[]
  }>>({})
  const [DisplayLog, setDisplayLog] = useState(FixedDisplay===undefined?true:FixedDisplay)
  const [DisplayFeeds, setDisplayFeeds] = useState(FixedDisplay===undefined?true:FixedDisplay)
  const [DisplaySilo, setDisplaySilo] = useState(FixedDisplay===undefined?true:FixedDisplay)

  const FeedRef = useRef()
  const FeedSilosList = useMemo(() => {
    var DatasList:{id:string, data:any[]}[] = []
    if (DisplayLog && !isEmpty(FeedsList)) {
      FeedsList.forEach(FeedData => {
        if (FeedData.silolist && !isEmpty(FeedData.silolist)) {
          FeedData.silolist.forEach(Silo => {
            if (Silo.silono && Silo.amount) {
              //if (cData.timestamp >= ViewPeriod.start && (!ViewPeriod.end || dataPoint.timestamp <= ViewPeriod.end)) {
                DatasList.push({id: FeedData.valve +'', data:[FeedData.valve+'', 'Silo ' + Silo.silono, Silo.amount, FeedData.timestamp]})
              //}
            }
          })
        }
      })
    }
    return DatasList
  }, [DisplayLog, FeedsList])
  const LogDatas = useMemo(() => {
    var log = {FullData:0,SiloLog:{}}
    if (FeedSilosList && !isEmpty(FeedSilosList)) {
      log = FeedSilosList.reduce((pData:any,cData:any) => {
        var Silolog:Record<string,number> = pData.SiloLog

        var res = true
        if (SortMap && SortMap[t('FeedList.Col1')]) { //Sort on valve
          var val = SortMap[t('FeedList.Col1')].selection.has(cData.data[0])
          if (SortMap[t('FeedList.Col1')].exclude) res = !val
          else res = val
        }
        if (res && SortMap && SortMap[t('FeedList.Col2')]) { //Sort on silo
          var val = SortMap[t('FeedList.Col2')].selection.has(cData.data[1])
          if (SortMap[t('FeedList.Col2')].exclude) res = !val
          else res = val
        }
        if (res && SortMap && SortMap[t('FeedList.Col3')]) { //Sort on amount
          var lower = SortMap[t('FeedList.Col3')].lowerValue
          var upper = SortMap[t('FeedList.Col3')].upperValue
          var exclude = SortMap[t('FeedList.Col3')].exclude

          var Amount = cData.data[2] / 1000

          if (exclude && (Amount >= lower && Amount <= upper)) res = false
          if (!exclude && (Amount <= lower || Amount >= upper)) res = false
        }
        if (res && SortMap && SortMap[t('FeedList.Col4')]) { //Sort on Time
          var timerange = SortMap[t('FeedList.Col4')].range
          if (timerange && timerange.length && timerange.length === 2) {
            var lower = timerange[0].getTime()
            var upper = new Date(timerange[1]).setSeconds(59,999)
            var exclude = SortMap[t('FeedList.Col4')].exclude
            if (exclude && (cData.data[3] >= lower && cData.data[3] <= upper)) res = false
            if (!exclude && (cData.data[3] <= lower || cData.data[3] >= upper)) res = false
          }
        }
        var FullD = pData.FullData
        if (res) {
          var Amount = cData.data[2] / 1000
          Silolog[cData.data[1]] = Silolog[cData.data[1]]?(Silolog[cData.data[1]]+=Amount):Amount
          FullD+= Amount
        }
        return {FullData:FullD, SiloLog:Silolog}
      }, {FullData:0,SiloLog:{}})
    }
    return log
  }, [SortMap, FeedSilosList])
  
  useEffect(() => {
    //return () => setisView(false)
  })

  if (!isEmpty(FeedSilosList)) {
    return (
      <View style={[Layout.colVCenter, {marginTop:10, width: '-webkit-fill-available', height:'fit-content', marginHorizontal:10}]}>
        <View style={[Layout.row, Layout.fullWidth, {justifyContent:'center', alignItems:'center', maxWidth:600}]}>
          <ContentProp Columns={1} maxWidth={FixedDisplay===undefined?600-30:600} Datas={[[{value:t('Pen_Display.SiloLog_Title'), CSV_ABLE:GenerateCSV_Data!==undefined}]]} Backcolor={true} Actions={[() => {
            //if (FeedRef && FeedRef.current) console.log(FeedRef.current.getRows())
            if (GenerateCSV_Data) GenerateCSV_Data()
          }]}/>
          {FixedDisplay===undefined||!FixedDisplay?!isPrinting?(
            <Checkbox checked={DisplayLog} onChange={() => setDisplayLog(!DisplayLog)}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
            />
          ):!DisplayLog?(<Text>{`-Hidden`}</Text>):(<></>):(<></>)}
        </View>
        {DisplayLog?(
        <View style={[Layout.col, Layout.fullWidth, {justifyContent:'center', alignItems:'center', maxWidth:800}]}>
          <View style={[Layout.row, Layout.fullWidth, {justifyContent:'flex-end', maxWidth:800}]}>
            {DisplayFeeds?(
              <View style={[Layout.col, Layout.fullWidth, {flex:1, justifyContent:'center', alignItems:'center', maxWidth:770}]}>
                <View style={[Layout.fullWidth, {maxWidth:800, flex:1}, isPrinting?{maxHeight:0}:{minHeight:360}]} >
                  <StatefulDataTable controlRef={FeedRef}
                    columns={[
                      CategoricalColumn({
                        title: t('FeedList.Col1'),
                        mapDataToValue: (data:any[]) => data[0],
                        fillWidth: false,
                        width:40,
                      }),
                      CategoricalColumn({
                        title: t('FeedList.Col2'),
                        mapDataToValue: (data:any[]) => data[1],
                        fillWidth: false,
                        minWidth: 80
                      }),
                      NumericalColumn({
                        title: t('FeedList.Col3'),
                        precision: 2,
                        mapDataToValue: (data:any[]) => (data[2] / 1000),
                        fillWidth: true,
                        minWidth: 80
                      }),
                      DatetimeColumn({
                        title: t('FeedList.Col4'),
                        formatString: 'dd-MM-yyy HH:mm',
                        mapDataToValue:(data: any) => new Date(new Date(data[3]).setSeconds(0,0)),
                        fillWidth: false,
                        minWidth:200,
                      })
                    ]}
                    initialSortDirection={SORT_DIRECTIONS.DESC}
                    initialSortIndex={3}
                    initialFilters={new Map(Object.entries(SortMap))}
                    onFilterAdd={(te:string, te2:any) => {
                      setSortMap(old => {
                        old[te] = te2
                        return {...old}
                      })
                    }}
                    onFilterRemove={(te:string) => {
                      setSortMap(old => {
                        delete old[te]
                        return {...old}
                      })
                    }}
                    rows={FeedSilosList}
                  />
                </View>
                <View style={[Layout.fullWidth, {maxWidth:800}, isPrinting?{minHeight:360}:{maxHeight:0}]} >
                  <TableBuilder divider={"grid"} sortOrder={SORT_DIRECTIONS.DESC} sortColumn={"time"}
                    data={FeedRef&&FeedRef.current?FeedRef.current.getRows():[]}
                    overrides={{
                      TableBodyRow: {style: ({$theme, $rowIndex}) => ({
                        backgroundColor:$rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
                        ':hover': { backgroundColor: $theme.colors.backgroundTertiary}
                      })},
                      TableHeadCell: {style: ({ $theme }) => ({
                        paddingTop: "10px",
                        paddingBottom: "10px"
                      })},
                      TableBodyCell: {style: ({ $theme }) => ({
                        paddingTop: "8px",
                        paddingBottom: "8px"
                      })}
                    }}>
                    <TableBuilderColumn header={t('FeedList.Col1')}>
                      {data => data.data[0]}
                    </TableBuilderColumn>
                    <TableBuilderColumn header={t('FeedList.Col2')}>
                      {data => data.data[1]}
                    </TableBuilderColumn>
                    <TableBuilderColumn header={t('FeedList.Col3')}>
                      {data => data.data[2]}
                    </TableBuilderColumn>
                    <TableBuilderColumn sortable id='time' header={t('FeedList.Col4')}>
                      {data => {
                        var time = new Date(data.data[3])
                        return `${time.getDate()}-${time.getMonth()+1}-${time.getFullYear()} ${time.getHours()}:${time.getMinutes()}`
                      }}
                    </TableBuilderColumn>
                  </TableBuilder>
                </View>
              </View>
            ):(<></>)}
            {(FixedDisplay===undefined||!FixedDisplay)&&!isPrinting?(
              <Checkbox checked={DisplayFeeds} onChange={() => setDisplayFeeds(!DisplayFeeds)}
                labelPlacement={LABEL_PLACEMENT.left}
                overrides={{
                  Root: { style: ({ }) => ({
                    alignSelf: "flex-start", justifySelf: "flex-end", alignItems: "center",
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })},
                  Label: { style: ({ }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
              >{!DisplayFeeds?("Feedings:"):("")}</Checkbox>
            ):(<></>)}
          </View>
          <View style={[Layout.row, Layout.fullWidth, {justifyContent:'flex-end', maxWidth:800}]}>
            {DisplaySilo?(
              <View style={[Layout.col, Layout.fullWidth, {flex:1, justifyContent:'center', maxWidth:770}, isPrinting?{}:{maxHeight:200}]}>
                <TableBuilder divider={"grid"}
                  sortOrder={SORT_DIRECTIONS.DESC} sortColumn={"Silo"}
                  data={Object.entries(LogDatas.SiloLog).concat([[t('Report_Display.Total'), LogDatas.FullData]])}
                  overrides={{
                    TableBodyRow: {style: ({$theme, $rowIndex}) => ({
                      backgroundColor:$rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
                      ':hover': { backgroundColor: $theme.colors.backgroundTertiary}
                    })},
                    TableHeadCell: {style: ({ $theme }) => ({
                      paddingTop: "10px",
                      paddingBottom: "10px"
                    })},
                    TableBodyCell: {style: ({ $theme }) => ({
                      paddingTop: "8px",
                      paddingBottom: "8px"
                    })}
                  }}>
                  <TableBuilderColumn sortable id='Silo' header={t('FeedList.Col2')}>
                    {row => row[0]}
                  </TableBuilderColumn>
                  <TableBuilderColumn sortable numeric header={t('FeedList.Col3')}>
                    {row => (Math.round(row[1]*1000)/1000).toFixed(3)}
                  </TableBuilderColumn>
                </TableBuilder>
              </View>
            ):(<></>)}
            {(FixedDisplay===undefined||!FixedDisplay)&&!isPrinting?(
              <Checkbox checked={DisplaySilo} onChange={() => setDisplaySilo(!DisplaySilo)}
                labelPlacement={LABEL_PLACEMENT.left}
                overrides={{
                  Root: { style: ({ }) => ({
                    alignSelf: "flex-start", justifySelf: "flex-end", alignItems: "center",
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })},
                  Label: { style: ({ }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
              >{!DisplaySilo?("Silos:"):("")}</Checkbox>
            ):(<></>)}
          </View>
        </View>
        ):(<></>)}
      </View>
    )
  } else {
    return (
      <View style={[Layout.fullWidth, {maxWidth:800, minHeight:25, maxHeight:25}]}>
        {!isFetching?(
          <Text>{t('Report_Display.NoFeeding')}</Text>
        ):(
          <ActivityIndicator style={[{alignItems:'center', justifyContent:'center', backgroundColor: '#F5FCFF88'}]} size='large' />
        )}
      </View>
    )
  }
}

export default FeedSiloList