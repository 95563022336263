import React, { forwardRef, useEffect, useRef, useState, useMemo, useImperativeHandle } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
  Image
} from 'react-native'
import { Button, LoadingSplash, Labelled_Input, ContentProp, Datetime_Picker } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, isApp } from '../../Navigators/Root'
//import { useIsFocused } from '@react-navigation/native'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded, useFirebaseConnect } from 'react-redux-firebase'
import { DataStringCheck, sortAlphaNum, NormSorter } from '../../utils/formaters'
import { setStates } from '../../Store/StateHandlers/Active-slice'

import { FarmData, UserStates, isEmpty, DataConsData, TimeSpan, LinkList } from '../../Config/Types'
import { Select, TYPE } from 'baseui/select'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import DeleteIcon from 'baseui/icon/delete-alt'
import { CustomColumn, StatefulDataTable, StringColumn, CategoricalColumn } from "baseui/data-table"
import ShowIcon from 'baseui/icon/show'

enum ViewModes {
  AUTO = 0,
  FEED = 1,
  DATACON = 2,
  SELECTOR = 3,
}

//#region Front List Selector
interface FrontSelector_Props {
  SetView: (V:ViewModes, ID?:string) => void,
}
const FrontSelector = ({ SetView }: FrontSelector_Props) => {
  const { t } = useTranslation()
  const { Layout, Common, Gutters, Images, Colors } = useTheme()

  const dispatch = useAppDispatch()

  const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)

  const farms = useAppSelector<{[key:string]:FarmData}>(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector<string|undefined>(state => state.activeData.farm)
  const farmData = useMemo<FarmData|null>(() => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null, [farms,activefarm])

  const DataLinkList = useAppSelector(({firebase: { data }}) => data.LinkList)
  const XTern_Data = useAppSelector(({firebase: { data }}) => data.LinkListData)
  const XTern_Keys = useMemo<string[]>(() => XTern_Data?Object.keys(XTern_Data):[],[XTern_Data])

  const XTern_Autopigs = useMemo(() => XTern_Data?Object.entries(XTern_Data).filter(([key,val]) => val&&val.DataCons).map(([key,val]) => Object.fromEntries(Object.entries(val.DataCons).filter(([key,val])=> (key !=="update_time" && key!=="Sys_Config" && ((!val)||(!val.feed || val.feed !== "")))))):null, [XTern_Data])
  const Autopigs = useAppSelector(({firebase: { data }}) => data.DataCons?Object.fromEntries(Object.entries(data.DataCons).filter(([key,val])=> (key !=="update_time" && key!=="Sys_Config" && ((!val)||(!val.feed || val.feed !== ""))))):null)
  const XTern_FeedCons = useMemo(() => XTern_Data?Object.entries(XTern_Data).filter(([key,val]) => val&&val.DataCons).map(([key,val]) => Object.fromEntries(Object.entries(val.DataCons).filter(([key,val])=> (key !=="update_time" && key!=="Sys_Config" && (val&&(val.feed || val.feed === "")))))):null, [XTern_Data])
  const FeedCons = useAppSelector(({firebase: { data }}) => data.DataCons?Object.fromEntries(Object.entries(data.DataCons).filter(([key,val])=> (key !=="update_time" && key!=="Sys_Config" && (val&&(val.feed || val.feed === ""))))):null)

  const Sys_Config = useAppSelector(({firebase: { data }}) => data.DataCons && data.DataCons["Sys_Config"]?data.DataCons["Sys_Config"]:null)
  const [CorridorIds, setCorridorIds] = useState<string[]>(Sys_Config && Sys_Config["Corridors"]?Sys_Config["Corridors"].split(','):[])

  const AutoCatCols = [
    StringColumn({
      title: t('Autopigs.listcol1'),
      mapDataToValue: (data: any) => {
        return data.key
      },
      fillWidth: true, filterable: true
    }),
    StringColumn({
      title: t('Autopigs.listcol2'),
      mapDataToValue: (data: any) => {
        return data.name
      },
      fillWidth: true,
    }),
    CategoricalColumn({
      title: t('Autopigs.listcol3'),
      mapDataToValue: (data: any) => {
        if(data.isCorridor&&data.isCorridor===true) return 'corridor'
        else
        return data.type
      },
      fillWidth: true,
    }),
    CategoricalColumn({ //CHANGE TO BOOLEANCOLUMN?
      title: t('Autopigs.listcol4'),
      mapDataToValue: (data: any) => {
        if(data.xTern&&data.xTern!==undefined)
        return 'Shared'
        else
        return data.isShare
      },
      fillWidth: true,
    }),
    CustomColumn({
      title: t('Autopigs.listcol5'),
      mapDataToValue: (data: any) => data,
      renderCell: function Cell(props) {
        //CHANGE TO WORK WITH AUTOPIGS ETC. FROM REPORTS
        return (
          <View style={[Layout.row, {width:'-webkit-fill-available', justifyContent:'flex-end'}]}>
            <TouchableOpacity style={[Layout.fill,{width:28, height:28, maxWidth:28}]} onPress={() => {
              if (props.value.isShare === "Shared" && props.value.farm) {
                dispatch(setStates({state:"autopig", data:{systemId:props.value.key, FarmId:XTern_Keys[props.value.farm]}}))
              } else {
                dispatch(setStates({state:"autopig", data:{systemId:props.value.key}}))
              }
              //dispatch(setStates({state:"autopig", data:{systemId:props.value.key}}))
              navigate('User', {screen: 'Autopigs_Display', params:{SelectedAutopig:[props.value.key,props.value]}})
            }}>
              <ShowIcon size={28} color={"black"} title={""}/>
            </TouchableOpacity>
            <View style={{width:10}}/>
            {props.value.isShare!=="Shared"||props.value.xTern!==undefined?
            <TouchableOpacity style={[Layout.fill,{width:28, height:28, maxWidth:28}]} onPress={() => {
              if(props.value.type === 'Autopig')
                SetView(ViewModes.AUTO, props.value.key)
              else if(props.value.type === 'Feed')
                SetView(ViewModes.FEED, props.value.key)
            }}>
              <Image style={[Layout.fill, Layout.fullSize]} source={Images.Simple_Edit} resizeMode={'contain'} />
            </TouchableOpacity>:null
            }
          </View>
        )
      },
    })
  ]

  const DataConCatCols = [
    StringColumn({
      title: 'Id',//t('Autopigs.listcol1'),
      mapDataToValue: (data: any) => {
        return data.key
      },
      fillWidth: true, filterable: true
    }),
    StringColumn({
      title: 'Name',//t('Autopigs.listcol2'),
      mapDataToValue: (data: any) => {
        return data.name
      },
      fillWidth: true,
    }),
    CustomColumn({
      title: t('Autopigs.listcol5'),
      mapDataToValue: (data: any) => data,
      renderCell: function Cell(props) {
        //CHANGE TO WORK WITH AUTOPIGS ETC. FROM REPORTS
        return (
          <View style={[Layout.row, {width:'-webkit-fill-available', justifyContent:'flex-end'}]}>
            <TouchableOpacity style={[Layout.fill,{width:28, height:28, maxWidth:28}]} onPress={() => {
              SetView(ViewModes.DATACON, props.value.key)
            }}>
              <Image style={[Layout.fill, Layout.fullSize]} source={Images.Simple_Edit} resizeMode={'contain'} />
            </TouchableOpacity>
          </View>
        )
      },
    })
  ]

  const AutoCatArray = useMemo(() => {
    var arr:any = []

    const AutopigsRows = Autopigs&&!isEmpty(Autopigs)?Object.entries(Autopigs).filter(([key,val]) => val.name).map(([key,val], index) => 
      ({id:key, data:{...val, key:key, type:'Autopig', isShare:'Local'}})
    ).flat():[]
    if(AutopigsRows&&AutopigsRows.length>0)
      arr = arr.concat(AutopigsRows)

    if (XTern_Autopigs&&!isEmpty(XTern_Autopigs)) {
      Object.entries(XTern_Autopigs).forEach(([FarmId,farmCon]) => {
        const AutopigsXTernRows = farmCon&&!isEmpty(farmCon)?Object.entries(farmCon).filter(([key,val]) => val.name).map(([key,val], index) => 
          ({id:key, data:{...val, key:key, type:'Autopig', isShare:'Shared', farm:FarmId}})
        ).flat():[]
        if(AutopigsXTernRows&&AutopigsXTernRows.length>0) 
          arr = arr.concat(AutopigsXTernRows)
      })
    }
    /*if(FeedConsRows&&FeedConsRows.length>0) 
      arr = arr.concat(FeedConsRows); 
    if(XTernFeedConsRows&&XTernFeedConsRows.length>0) 
     arr = arr.concat(XTernFeedConsRows); */
    return arr
  }, [Autopigs,XTern_Autopigs])

  const DataConsCatArray = useMemo(() => {
    var arr:any = []

    const DataConRows = DataLinkList?Object.entries(DataLinkList).filter(([key,val]) => val.name).map(([key,val], index) =>
      ({id:key, data:{name:val.name, key:key}})
    ).flat():[]

    if(DataConRows&&DataConRows.length>0)
      arr = arr.concat(DataConRows)

    return arr
  }, [DataLinkList])

  //const XTern_sections = useMemo(() => XTern_Data?Object.entries(XTern_Data).filter(([key,val]) => val&&val.pens).map(([key,val]) => val.sections):null, [XTern_Data])
  return (
    <View style={[Layout.colVCenter, Layout.fullWidth]}>
      <View style={[Layout.fill, Layout.fullSize, Layout.colVCenter, {marginVertical:20}]}>
        <View style={[Common.border, Gutters.tinyPadding, {width:'100%', maxWidth:'600px', paddingBottom:'2px'}]}>
          <ContentProp Columns={1} Datas={[[t('Autopigs.Title')]]} Backcolor={true}/>
          <View style={{height:10}}/>
          <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, {maxWidth:800, minHeight:360, flex:1}]} >
            <View style={[{width:'99%', height:'99%'}]}>
              <StatefulDataTable 
                columns={AutoCatCols} 
                rows={AutoCatArray}                  
              />
            </View>
          </View>
          {/*{XTern_Autopigs?Object.entries(XTern_Autopigs).map(([XTernKey, AutopigData]) => {
            return (<FlatList key={"XTern_"+XTernKey} style={[Layout.fullWidth, {maxWidth:600, minHeight:0, maxHeight:300}]}
            data={Object.entries(AutopigData)}
            renderItem={({item, index}) => {
              return (
                <View key={item[0]} style={[Layout.fill, Layout.fullWidth, Layout.row, {height:'100%', minHeight:50}]}>
                  <TouchableOpacity style={[Layout.fill, Layout.fullWidth, Layout.colCenter, Common.border, {alignSelf:'stretch', backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                    dispatch(setStates({state:"autopig", data:{systemId:item[0], FarmId:XTern_Keys[XTernKey]}}))
                    navigate('User', {screen: 'Autopigs_Display', params:{SelectedAutopig:item}})
                  }}>
                    <View style={[Layout.row, Layout.fullWidth, {alignItems:'center', justifyContent:'center'}]}>
                      <Text style={{color:Colors.secountText}}>{item[1]&&item[1].name?item[1].name+' -#'+item[0]:"SysId -#" + item[0]}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              )
            }}
            keyExtractor={(item, index) => String(index)}
            />)
          }):(<></>)}
          {Autopigs&&
            <FlatList style={[Layout.fullWidth, {maxWidth:600, minHeight:80, maxHeight:300}]}
              data={Object.entries(Autopigs)}
              renderItem={({item, index}) => {
                return (
                  <View key={item[0]} style={[Layout.fill, Layout.fullWidth, Layout.row, {height:'100%', marginVertical:-4}]}>
                    <TouchableOpacity style={[Layout.fill, Layout.fullWidth, Layout.colCenter, Common.border, {alignSelf:'stretch', backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                      dispatch(setStates({state:"autopig", data:{systemId:item[0]}}))
                      navigate('User', {screen: 'Autopigs_Display', params:{SelectedAutopig:item}})
                    }}>
                      <View style={[Layout.row, Layout.fullWidth, {alignItems:'center', justifyContent:'center'}]}>
                        <Text style={{color:Colors.secountText}}>{item[1].name?item[1].name+' -#'+item[0]:"SysId -#" + item[0]}</Text>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={[Layout.colCenter, Common.border, {width:30, height:40, maxWidth:40, backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                      SetView(ViewModes.AUTO, item[0])
                    }}>
                      <Image style={[Layout.fill, Layout.fullSize]} source={Images.Simple_Edit} resizeMode={'contain'} />
                    </TouchableOpacity>
                  </View>
                )
              }}
              keyExtractor={(item, index) => String(index)}
            />
          }*/}
          {farmData&&farmData.site&&(
            <Button Contexts={t('Autopigs.New')} Frame Action={() => {
              SetView(ViewModes.AUTO)
            }} />
          )}
          </View>
        <View style={[Common.border, Gutters.tinyPadding, {width:'100%', maxWidth:'600px', paddingBottom:'2px'}]}>
          <ContentProp Columns={1} Datas={[[t('Autopigs.CorTitle')]]} Backcolor={true}/>
          <View style={{height:10}}/>
          {CorridorIds&&
            <View style={[Layout.fullWidth, {maxWidth:360, alignSelf:'center'}]}>
              <Labelled_Input disabled={false}
                value={CorridorIds.join(',')}
                label={t('Autopigs.CorridorId')}
                placeholder={"E.g. 16, 25"}
                onChange={(text) => {
                  if (text && text != "") {
                    var _text = text.trim().replaceAll(' ', '').replaceAll(',,',',')
                    if (DataStringCheck({data:_text.trim(), isNumbers:true, AllowComma:true})) {
                      setCorridorIds(_text.split(','))
                    }
                  }else {
                    setCorridorIds([])
                  }
                }}
              />
            </View>
          }
          {farmData&&farmData.site&&(
            <Button Contexts={t('ActionButtons.Save')} Frame Action={() => {
              var Arr_Corridor = CorridorIds.filter(String)
              firebase.update(`farms_data/${activefarm}/DataCons/Sys_Config/`, {
                Corridors: Arr_Corridor.filter((item, index) => Arr_Corridor.indexOf(item) === index).join(',')
              })
            }} />
          )}
        </View>
        <View style={[Common.border, Gutters.tinyPadding, {width:'100%', maxWidth:'600px', paddingBottom:'2px'}]}>
          <ContentProp Columns={1} Datas={[[t('Autopigs.FeedTitle')]]} Backcolor={true}/>
          <View style={{height:10}}/>
          {XTern_FeedCons?Object.entries(XTern_FeedCons).map(([XTernKey, FeedConData]) => {
            return (<FlatList key={"XTern_"+XTernKey} style={[Layout.fullWidth, {maxWidth:600, minHeight:0, maxHeight:300}]}
            data={Object.entries(FeedConData)}
            renderItem={({item, index}) => {
              return (
                <View key={item[0]} style={[Layout.fill, Layout.fullWidth, Layout.row, {height:'100%', minHeight:50, marginVertical:-4}]}>
                  <TouchableOpacity style={[Layout.fill, Layout.fullWidth, Layout.colCenter, Common.border, {alignSelf:'stretch', backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                    dispatch(setStates({state:"autopig", data:{systemId:item[0], FarmId:XTern_Keys[XTernKey]}}))
                    navigate('User', {screen: 'Autopigs_Display', params:{SelectedAutopig:item}})
                  }}>
                    <View style={[Layout.row, Layout.fullWidth, {alignItems:'center', justifyContent:'center'}]}>
                      <Text style={{color:Colors.secountText}}>{item[1].name?item[1].name+' -#'+item[0]:"SysId -#" + item[0]}</Text>
                    </View>
                  </TouchableOpacity>
                </View>
              )
            }}
            keyExtractor={(item, index) => String(index)}
            />)
          }):(<></>)}
          {FeedCons&&
            <FlatList style={[Layout.fullWidth, {maxWidth:600, minHeight:80, maxHeight:300}]}
              data={Object.entries(FeedCons)}
              renderItem={({item, index}) => {
                var Data = item[1] as DataConsData
                return (
                  <View key={item[0]} style={[Layout.fill, Layout.fullWidth, Layout.row, {height:'100%', marginVertical:-4}]}>
                    <TouchableOpacity style={[Layout.fill, Layout.fullWidth, Layout.colCenter, Common.border, {alignSelf:'stretch', backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                      dispatch(setStates({state:"autopig", data:{systemId:item[0]}}))
                      navigate('User', {screen: 'Autopigs_Display', params:{SelectedAutopig:item}})
                    }}>
                      <View style={[Layout.row, Layout.fullWidth, {alignItems:'center', justifyContent:'center'}]}>
                        <Text style={{color:Colors.secountText}}>{Data.name?Data.name+' -#'+item[0]:"SysId -#" + item[0]}</Text>
                      </View>
                    </TouchableOpacity>
                    <TouchableOpacity style={[Layout.colCenter, Common.border, {width:30, height:40, maxWidth:40, backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                      SetView(ViewModes.FEED, item[0])
                    }}>
                      <Image style={[Layout.fill, Layout.fullSize]} source={Images.Simple_Edit} resizeMode={'contain'} />
                    </TouchableOpacity>
                  </View>
                )
              }}
              keyExtractor={(item, index) => String(index)}
            />
          }
          {farmData&&farmData.site&&(
            <Button Contexts={t('Autopigs.NewConnection')} Frame Action={() => {
              SetView(ViewModes.FEED)
            }} />
          )}
        </View>
        <View style={[Common.border, Gutters.tinyPadding, {width:'100%', maxWidth:'600px', paddingBottom:'2px'}]}>
          <ContentProp Columns={1} Datas={[[t('Autopigs.DataTitle')]]} Backcolor={true}/>
          <View style={{height:10}}/>
          <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, {maxWidth:800, minHeight:360, flex:1}]} >
            <View style={[{width:'99%', height:'99%'}]}>
              <StatefulDataTable 
                columns={DataConCatCols} 
                rows={DataConsCatArray}
              />
            </View>
          </View>
          {/*<FlatList style={[Layout.fullWidth, {maxWidth:600, minHeight:80, maxHeight:300}]}
            data={DataLinkList?Object.entries(DataLinkList):null}
            renderItem={({item, index}) => {
              var Key = item[0], Data:any = item[1]
              return (
                <View key={Key} style={[Layout.fill, Layout.fullWidth, Layout.row, {height:'100%', marginVertical:-4}]}>
                  <TouchableOpacity style={[Layout.fill, Layout.fullWidth, Layout.colCenter, Common.border, {alignSelf:'stretch', backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                    
                  }}>
                    <View style={[Layout.row, Layout.fullWidth, {alignItems:'center', justifyContent:'center'}]}>
                      <Text style={{color:Colors.secountText}}>{Data.name?Data.name : "Id# " + Key}</Text>
                    </View>
                  </TouchableOpacity>
                  <TouchableOpacity style={[Layout.colCenter, Common.border, {width:30, height:40, maxWidth:40, backgroundColor:Colors.AgriLightblue}]} onPress={() => {
                    SetView(ViewModes.DATACON, item[0])
                  }}>
                    <Image style={[Layout.fill, Layout.fullSize]} source={Images.Simple_Edit} resizeMode={'contain'} />
                  </TouchableOpacity>
                </View>
              )
            }}
            keyExtractor={(item, index) => String(index)}
          />*/}
          <Button Contexts={t('Autopigs.NewConnection')} Frame Action={() => {
            SetView(ViewModes.DATACON)
          }} />
        </View>
      </View>
    </View>
  )
}
//#endregion

//#region Autopig Config View
//#region Autopig edditor pen settings props
interface PenProp_Props {
  Id: string|number,
  info: {scale:boolean, activity:boolean, temp:boolean, water:boolean}
}
interface PenPropProp_Props extends PenProp_Props {
  setData: (e:{scale:boolean, activity:boolean, temp:boolean, water:boolean}) => void,
  remove: () => void
}
const PenProp = ({Id, info, setData, remove}:PenPropProp_Props) => {
  const { t } = useTranslation()
  const { Layout, Common, Gutters } = useTheme()

  return (
    <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'300px', maxWidth:'-webkit-fill-available', flexWrap: "wrap", flexDirection: "row"}]}>
      <View style={[Layout.fill, Layout.column, {alignSelf:'center', maxWidth:'-webkit-fill-available'}]}>
        <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {width:'-webkit-fill-available', alignSelf:'center', minWidth:'100px', justifyContent:'space-between'}]}>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'60px', maxWidth:'max-content'}]}>
            <Text style={[{fontSize:28,fontWeight:'600'}]}>{Id}</Text>
          </View>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'min-content', maxWidth:'max-content'}]}>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.scale}
              onChange={(e:any) => {
                setData({...info, scale:e.target.checked})
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop3')}</Text></Checkbox>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.activity}
              onChange={(e:any) => {
                setData({...info, activity:e.target.checked})
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop4')}</Text></Checkbox>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.temp}
              onChange={(e:any) => {
                setData({...info, temp:e.target.checked})
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop5')}</Text></Checkbox>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.water}
              onChange={(e:any) => {
                setData({...info, water:e.target.checked})
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop6')}</Text></Checkbox>
          </View>
        </View>
      </View>
      <TouchableOpacity style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'50px', maxWidth:'max-content'}]} 
        onPress={() => {
          remove()
        }}>
          <DeleteIcon size={36} color={"black"} title={""}/>
      </TouchableOpacity>
    </View>
  )
}
//#endregion
//#region Autopig edditor setting props
interface AutopigProp_Props {
  AutopigId: string|number|undefined,
  data: {[key:string]: any},
}
type AutopigPropHandle = {
  onSave: () => {
    name: string|null,
    flow?: number|null,
    scale: string|null,
    activity: string|null,
    temp: string|null,
    water: string|null,
    oldPenList?: string[],
    newPenList?: string[]
  },
}
const AutopigProps: React.ForwardRefRenderFunction<AutopigPropHandle, AutopigProp_Props> = ({AutopigId, data}, ref) => {
  const { t } = useTranslation()
  const { Layout, Common, Gutters } = useTheme()

  const Pens = useAppSelector(({firebase: { data }}) => data.pens?Object.keys(data.pens).filter((key) => key!=="update_time"):[])
  //const Pens = useAppSelector(({firebase: { data }}) => data.pens?Object.keys(data.pens).filter(key => key != "update_time").reduce((obj:object, key:string) => {obj[key] = data.pens[key];return obj;}, {}):null)

  const [Original, setOriginal] = useState<string[]>([])
  const [PensList, setPensList] = useState<string[]>([])
  
  const [Scales, setScales] = useState({})
  const [Activitys, setActivitys] = useState({})
  const [Temps, setTemps] = useState({})
  const [Waters, setWaters] = useState({})

  useEffect(() => {
    var Pens:string[] = []
    var Scales = data&&data.scale?data.scale.split(',').reduce((a:object, v:string) => ({ ...a, [v]:true}), {}):{}
    if (Scales) {
      Object.keys(Scales).map((key) => {
        if (!Pens.includes(key)) Pens.push(key)
      })
    }
    setScales(Scales)

    var Activitys = data&&data.activity?data.activity.split(',').reduce((a:object, v:string) => ({ ...a, [v]:true}), {}):{}
    if (Activitys) {
      Object.keys(Activitys).map((key) => {
        if (!Pens.includes(key)) Pens.push(key)
      })
    }
    setActivitys(Activitys)

    var Temps = data&&data.temp?data.temp.split(',').reduce((a:object, v:string) => ({ ...a, [v]:true}), {}):{}
    if (Temps) {
      Object.keys(Temps).map((key) => {
        if (!Pens.includes(key)) Pens.push(key)
      })
    }
    setTemps(Temps)

    var Waters = data&&data.water?data.water.split(',').reduce((a:object, v:string) => ({ ...a, [v]:true}), {}):{}
    if (Waters) {
      Object.keys(Waters).map((key) => {
        if (!Pens.includes(key)) Pens.push(key)
      })
    }
    setWaters(Waters)

    setOriginal(Pens)
    setPensList(Pens)
  }, [data])

  useImperativeHandle(ref, ()=>({
    onSave() {
      var _Get = Object.entries(Scales).map(([key,value]) => value?key:"").filter(e=>e)
      var _scales:string|null = _Get.length?_Get.join(','):null

      _Get = Object.entries(Activitys).map(([key,value]) => value?key:"").filter(e=>e)
      var _activity:string|null = _Get.length?_Get.join(','):null

      _Get = Object.entries(Temps).map(([key,value]) => value?key:"").filter(e=>e)
      var _temp:string|null = _Get.length?_Get.join(','):null

      _Get = Object.entries(Waters).map(([key,value]) => value?key:"").filter(e=>e)
      var _water:string|null = _Get.length?_Get.join(','):null

      return {
        name: null,
        scale: _scales,
        activity: _activity,
        temp: _temp,
        water: _water,
        oldPenList: Original,
        newPenList: PensList
      }
    }
  }))

  const SetDatas = (value:boolean, setter:Function, Pens_Id: string) => {
    setter((old:any) => {
      var _new = { ...old }
      if ((!value && !_new['all']) || (value && _new['all'])) delete _new[Pens_Id]
      else _new[Pens_Id] = value
      return _new
    })
  }

  return (
    <View style={[Layout.fill, Layout.column, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', maxHeight:'350px'}]}>
      <View style={[Layout.row, Gutters.tinyV_Margin, {maxWidth:'-webkit-fill-available', minWidth:'-webkit-fill-available'}]}>
        <Select clearable={false}
          value={undefined}
          options={
            Pens.filter(Pen => !PensList.includes(Pen)).map((Pen, i) => ({id: Pen, index:i}))
          }
          labelKey="id"
          type={TYPE.select}
          placeholder={t('Autopigs.SelectPens')}
          onChange={(params:any) => {
            setPensList(old => {
              var _new = [...old]
              _new.push(params.value[0].id)
              return _new
            })
          }}
          maxDropdownHeight="300px"
          overrides={{
            Root: { style: ({ }) => ({
              minWidth:'300px', paddingTop:'1px', paddingBottom:'1px',
              outline:'black 1px solid'
            })},
            Tag: {props: {overrides: {Root: {style: ({ }) => ({
              minWidth: "55px", paddingTop:'1px', paddingBottom:'1px',
            })}}}}
          }}
        />
      </View>
      <FlatList style={[Layout.fullWidth, {maxWidth:600, minHeight:125, maxHeight:300}]}
        data={PensList}
        renderItem={({item, index}) => {
          return (
            <PenProp Id={item} info={{
              scale:Scales[item]!==undefined?Scales[item]:false,
              activity:Activitys[item]!==undefined?Activitys[item]:false,
              temp:Temps[item]!==undefined?Temps[item]:false,
              water:Waters[item]!==undefined?Waters[item]:false
            }} setData={(info) => {
              SetDatas(info.scale, setScales, item)
              SetDatas(info.activity, setActivitys, item)
              SetDatas(info.temp, setTemps, item)
              SetDatas(info.water, setWaters, item)
            }} remove={() => {
              SetDatas(false, setScales, item)
              SetDatas(false, setActivitys, item)
              SetDatas(false, setTemps, item)
              SetDatas(false, setWaters, item)
              var List = [...PensList]
              List.splice(index, 1)
              setPensList(List)
            }}/>
          )
        }}
        numColumns={1}
        keyExtractor={(item, index) => String(index)}
      />
    </View>
  )
}
const AutopigProps_Element = forwardRef(AutopigProps);
//#endregion
//#endregion

//#region New/Edit Autopig
export interface Autopig_Edit_Props {
  SetView: (V:ViewModes) => void,
  activeAutopig?: string
}
const Autopig_Edit = ({SetView, activeAutopig}:Autopig_Edit_Props) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()

  const firebase = useFirebase()

  const activefarm = useAppSelector(state => state.activeData.farm)

  const profile = useAppSelector(({firebase}) => firebase.profile)
  const [flowmeter, setflowmeter] = useState<number|undefined>(undefined)
  //const [voltdiff, setvoltdiff] = useState<number|undefined>(undefined)
  
  const Pens_Data = useAppSelector(({firebase: {data}}) => data.pens)
  const Autopigs = useAppSelector(({firebase: { data }}) => data.DataCons)
  const Autopig = useMemo(() => (Autopigs&&activeAutopig&&Autopigs[activeAutopig])?Autopigs[activeAutopig]:null, [Autopigs])

  const PropsRef = useRef<AutopigPropHandle>(null)
  const [AutoName, setAutoName] = useState('')
  const OnSave = () => {
    if (Autopig || ((NewId&&NewId!="") && !(Autopigs&&Autopigs[NewId]?true:false))) {
      if (PropsRef.current) {
        let data = PropsRef.current.onSave()
        data.name = AutoName!==""?AutoName:null

        data.flow = flowmeter?flowmeter:null
        //data.volt= voltdiff
        if (profile.state === UserStates.ADMIN || profile.state === UserStates.TECH) {
          if (flowmeter && !isNaN(flowmeter) && flowmeter > 0 && flowmeter !== 22000) data.flow = flowmeter
          else data.flow = null
          //if (voltdiff && voltdiff !== "" && voltdiff > 0) data.volt = voltdiff
        }

        const RemovedPens = data.oldPenList?data.oldPenList.filter(x => !data.newPenList||!data.newPenList.includes(x)):[]
        const NewPens = data.newPenList?data.newPenList.filter(x => !data.oldPenList||!data.oldPenList.includes(x)):[]
        //const SamePens = data.oldPenList.filter(x => !RemovedPens.includes(x) && !NewPens.includes(x))
        RemovedPens.forEach(Pen => {
          if (data.oldPenList&&data.oldPenList.includes(Pen)) {
            var Pen_data = Pens_Data[Pen]?{...Pens_Data[Pen].datas}:{autopigs:""}
            var _autopigs = Pen_data.autopigs?Pen_data.autopigs.split(','):[]
            var index = _autopigs.indexOf((activeAutopig||'-1'))
            if (index > -1) _autopigs.splice(index, 1)
            Pen_data.autopigs = _autopigs.length?_autopigs.join(','):undefined
            firebase.update(`farms_data/${activefarm}/pens/${Pen}/datas/`, {autopigs:(Pen_data.autopigs||null)})
          }
        })
        NewPens.forEach(Pen => {
          var Pen_data = Pens_Data[Pen]?{...Pens_Data[Pen].datas}:{autopigs:""}
          var _autopigs = Pen_data.autopigs?Pen_data.autopigs.split(','):[]
          if (_autopigs.indexOf((activeAutopig||NewId+'')) < 0) _autopigs.push((activeAutopig||NewId+''))
          Pen_data.autopigs = _autopigs.length?_autopigs.join(','):undefined
          firebase.update(`farms_data/${activefarm}/pens/${Pen}/datas/`, {autopigs:(Pen_data.autopigs||null)})
        })
        /*SamePens.forEach(Pen => {
        })*/

        delete data.oldPenList
        delete data.newPenList
        firebase.update(`farms_data/${activefarm}/DataCons/${Autopig?activeAutopig:NewId}/`, data)
      }
      SetView(ViewModes.SELECTOR)
    }
  }

  const [NewId,SetId] = useState<number>(Autopigs?(Object.keys(Autopigs).length):1)
  useEffect(() => {
    var Auto = Autopig as DataConsData
    if (Auto && Auto.name) setAutoName(Auto.name)
    if (Auto && Auto.flow) setflowmeter(Auto.flow)
  },[Autopig])

  return(
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <View style={[Layout.row, Common.backgroundPrimary, {justifyContent:'center', minHeight:'min-content', width:"-webkit-fill-available"}]}>
        {Autopig?(
          <Labelled_Input disabled={false}
            value={(activeAutopig||"")}
            label={t('Autopigs.AutopigId')}
            placeholder={""}
            onChange={(text) => { //Not change able !!
            }}
            minWidth={60} maxWidth={100}
          />
        ):(
          <Labelled_Input disabled={false}
            value={NewId+''}
            label={t('Autopigs.AutopigId')}
            placeholder={""}
            type='number'
            onChange={(text,num) => {
              SetId(num)
            }}
            minWidth={60} maxWidth={100}
            error={(Autopigs&&Autopigs[NewId]?true:false)}
          />
        )}
        <View style={{minWidth:20}} />
        <Labelled_Input
          value={AutoName}
          label={t('Autopigs.Prop1')}
          placeholder={""}
          onChange={(text) => {
            setAutoName(text)
          }}
          minWidth={60} maxWidth={160}
        />
      </View>
      <View style={{height:20}}/>
      <View style={[Layout.col, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', minWidth:'fit-content'}]}>
        {(profile.state === UserStates.ADMIN || profile.state === UserStates.TECH) && (
          <View style={[Layout.fullWidth]}>
            <Labelled_Input disabled={false}
              value={(flowmeter?flowmeter:(22000))+''}
              label={'Flowmeter_val'}
              type="number"
              onChange={(text, num) => {
                setflowmeter(num)
              }}
            />
            {<></>/*<Labelled_Input disabled={false}
              value={(voltdiff?voltdiff:16000)+''}
              label={"Volt diffrence"}
              type='number'
              onChange={(text, num) => {
                setvoltdiff(num)
              }}
            />*/}
          </View>
        )}
      </View>
      <View style={[Layout.fill, Layout.colVCenter, Common.backgroundPrimary, {minHeight:'max-content', width:"-webkit-fill-available"}]}>
        <AutopigProps_Element AutopigId={activeAutopig} data={Autopig} ref={PropsRef}/>
        <Button Contexts={[t('ActionButtons.Save'),t('ActionButtons.Cancel')]} Frame Action={(e)=> e?SetView(ViewModes.SELECTOR):OnSave()}/>
      </View>
    </View>
  )
}
//#endregion
//#endregion

//region Feed Config View
interface FeedConnection_Props {
  SetView: (V:ViewModes) => void,
  activeFeed?: string,
}
const FeedConnection = ({SetView, activeFeed}:FeedConnection_Props) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()

  const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)

  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const Pens = useAppSelector(({firebase: { data }}) => data.pens?Object.keys(data.pens).filter((key) => key!=="update_time"):[])
  const Pens_Data = useAppSelector(({firebase: {data}}) => data.pens)

  const Autopigs = useAppSelector(({firebase: { data }}) => data.DataCons)
  const FeedCon = useMemo(() => (Autopigs&&activeFeed&&Autopigs[activeFeed])?Autopigs[activeFeed]:{name:"",feed:""}, [Autopigs])
  
  const [FeedId, setFeedId] = useState<number|string>(Autopigs?(Object.keys(Autopigs).length):1)
  const [FeedName, setFeedName] = useState('')
  
  const [Original, setOriginal] = useState<string[]>([])
  const [PensList, setPensList] = useState<string[]>([])

  useEffect(() => {
    var Pens:string[] = []
    var Feeds = FeedCon&&FeedCon.feed?FeedCon.feed.split(',').reduce((a:object, v:string) => ({ ...a, [v]:true}), {}):{}
    if (Feeds) {
      Object.keys(Feeds).map((key) => {
        if (!Pens.includes(key)) Pens.push(key)
      })
    }

    if (FeedCon && FeedCon.name) setFeedName(FeedCon.name)
    setOriginal(Pens)
    setPensList(Pens)
  }, [FeedCon])

  const Save_New = () => {
    if (activeFeed || ((FeedId && FeedId != "") && !(Autopigs&&Autopigs[FeedId])?true:false)) {
      FeedCon.name = FeedName&&FeedName!==""?FeedName:null

      var _feeds = Object.values(PensList).join(',')
      FeedCon.feed = !FeedCon.name&&_feeds===""?null:_feeds

      const RemovedPens = Original.filter(x => !PensList.includes(x))
      const NewPens = PensList.filter(x => !Original.includes(x))
      //const SamePens = Original.filter(x => !RemovedPens.includes(x) && !NewPens.includes(x))
      RemovedPens.forEach(Pen => {
        if (Original.includes(Pen)) {
          var Pen_data = Pens_Data[Pen]?{...Pens_Data[Pen].datas}:{autopigs:""}
          var _autopigs = Pen_data.autopigs?Pen_data.autopigs.split(','):[]
          var index = _autopigs.indexOf(activeFeed)
          if (index > -1) _autopigs.splice(index, 1)
          Pen_data.autopigs = _autopigs.length?_autopigs.join(','):null
          firebase.update(`farms_data/${activefarm}/pens/${Pen}/datas/`, {autopigs:Pen_data.autopigs})
        }
      })
      NewPens.forEach(Pen => {
        var Pen_data = Pens_Data[Pen]?{...Pens_Data[Pen].datas}:{autopigs:""}
        var _autopigs = Pen_data.autopigs?Pen_data.autopigs.split(','):[]
        if (_autopigs.indexOf(activeFeed) < 0) _autopigs.push(activeFeed)
        Pen_data.autopigs = _autopigs.length?_autopigs.join(','):null
        firebase.update(`farms_data/${activefarm}/pens/${Pen}/datas/`, {autopigs:Pen_data.autopigs})
      })
      /*SamePens.forEach(Pen => {
      })*/

      firebase.update(`farms_data/${activefarm}/DataCons/${activeFeed?activeFeed:FeedId}/`, FeedCon)
      SetView(ViewModes.SELECTOR)
    }
  }

  return(
    <View style={[Layout.colVCenter, Layout.fullWidth]}>
      <Button Contexts={t('Autopigs.NewFeedCon')} Title/>
      <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'300px', maxWidth:'max-content', flexWrap: "wrap", flexDirection: "row"}]}>
        <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'100px', maxWidth:300}]}>
          {activeFeed?(
            <Labelled_Input disabled={false}
              value={activeFeed}
              label={t('Autopigs.FeedId')}
              placeholder={""}
              onChange={(text) => { //Not change able !!
              }}
              minWidth={60} maxWidth={100}
            />
          ):(
            <Labelled_Input disabled={false}
              value={FeedId+''}
              label={t('Autopigs.FeedId')}
              placeholder={""}
              type='number'
              onChange={(text,num) => {
                setFeedId(num)
              }}
              minWidth={60} maxWidth={100}
              error={(Autopigs&&Autopigs[FeedId])?true:false}
            />
          )}
          <Labelled_Input
            value={FeedName} // todo... option
            label={t('Autopigs.ConnectionName')}
            placeholder={""}
            onChange={(text) => {
              setFeedName(text)
            }}
            minWidth={90}
          />
        </View>
      </View>
      <View style={{height:20}}/>
      <View style={[Layout.fill, Layout.colVCenter, Common.backgroundPrimary, {minHeight:'max-content', width:"-webkit-fill-available"}]}>
        <View style={[Layout.fill, Layout.column, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px'}]}>
          <View style={[Layout.row, Gutters.tinyV_Margin, {maxWidth:'-webkit-fill-available', minWidth:'-webkit-fill-available'}]}>
            <Select clearable={false} multi closeOnSelect={false}
              options={[{id:'All',index:-1}].concat(Pens?Pens.map((Pen, i) => ({id: Pen, index:i}) ):[])}
              labelKey="id"
              value={PensList.map((Pen,i) => ({id: Pen, index:i}))}
              type={TYPE.select}
              placeholder={t('Autopigs.SelectPens')}
              onChange={(params:any) => {
                if (params.value.length && params.value.some(e => e.id === 'All')) setPensList(['All'])
                else setPensList(params.value.map(e => e.id))
              }}
              maxDropdownHeight="300px"
              overrides={{
                Root: { style: ({ }) => ({
                  minWidth:'200px', 
                  outline:'black 1px solid'
                })},
                Tag: {props: {overrides: {Root: {style: ({ }) => ({
                  minWidth: "55px"
                })}}}},
                
              }}
            />
          </View>
        </View>
        <Button Contexts={[t('ActionButtons.Save'),t('ActionButtons.Cancel')]} Frame Action={(e)=>!e?Save_New():SetView(ViewModes.SELECTOR)}/>
      </View>
    </View>
  )
}
//#endregion

//region DataCon Config View
interface DataCon_Props {
  SetView: (V:ViewModes) => void,
  activeDataCon?: string,
}
const DataConnections = ({SetView, activeDataCon}:DataCon_Props) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()

  const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)

  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  const Sections = useAppSelector(({firebase: { data }}) => data.sections?Object.keys(data.sections).filter((key) => key!=="update_time"):[])

  const LinkList = useAppSelector(({firebase: { data }}) => data.LinkList)
  const DataCon = useMemo<LinkList|null>(() => (LinkList&&activeDataCon&&LinkList[activeDataCon])?LinkList[activeDataCon]:null, [LinkList])

  const [DataConId, setDataConId] = useState<string>('')
  const [DataConName, setDataConName] = useState('')

  const [Original, setOriginal] = useState<string[]>([])
  const [SectionsList, setSectionsList] = useState<string[]>([])
  const [DateList, setDateList] = useState<Record<number,TimeSpan>>({})

  useEffect(() => {
    var Sections:string[] = []
    var DataTimes:Record<number,TimeSpan> = {}
    if (DataCon&&DataCon.sections) {
      Object.entries(DataCon.sections).map(([key,data], index) => {
        if (!Sections.includes(key)) Sections.push(key)
        if (!DataTimes[index]) DataTimes[index] = {}
        if (typeof data === "object") {
          if (data.starttime) DataTimes[index].start = data.starttime
          if (data.endtime) DataTimes[index].end = data.endtime
        }
      })
    }

    if (DataCon && DataCon.name) setDataConName(DataCon.name)
    setOriginal(Sections)
    setSectionsList(Sections)
    setDateList(DataTimes)
  }, [DataCon])

  const Save_New = () => {
    if (activeDataCon || ((DataConId && DataConId != "") && !(LinkList&&LinkList[DataConId]?true:false))) {
      var NewName = DataConName&&DataConName!==""?DataConName:null
      var NewDataCon:LinkList = DataCon?{...DataCon, name:NewName}:{name:NewName}
      if (NewDataCon.dataCon) delete NewDataCon.dataCon

      NewDataCon.sections = Object.values(SectionsList).reduce((a:object, v:string, index) => {
        if (DateList && DateList[index] && (DateList[index].start || DateList[index].end)) {
          var DataObj:{starttime?:number, endtime?:number} = {}
          if (DateList[index].start) DataObj.starttime = DateList[index].start
          if (DateList[index].end) DataObj.endtime = DateList[index].end
          return ({ ...a, [v]:DataObj})
        }
        return ({ ...a, [v]:true})
      }, {})
      
      
      //const RemovedSections = Original.filter(x => !SectionsList.includes(x))
      //const NewSections = SectionsList.filter(x => !SectionsList.includes(x))
      //const SameSections = Original.filter(x => !RemovedSections.includes(x) && !NewSections.includes(x))
      //RemovedSections.forEach(Section => {})
      //NewSections.forEach(Section => {})
      //SameSections.forEach(Section => {})
      if (isEmpty(NewDataCon.sections)) {
        NewDataCon.sections = null
        
        firebase.set(`farms_data/${activeDataCon?activeDataCon:DataConId}/DataLinks/${activefarm}/`, {})
        if (!NewDataCon.name || NewDataCon.name === "") firebase.set(`LinkList/${activefarm}/DataLinks/${activeDataCon?activeDataCon:DataConId}/`, {})
        else firebase.update(`LinkList/${activefarm}/DataLinks/${activeDataCon?activeDataCon:DataConId}/`, NewDataCon)
      } else {
        firebase.set(`farms_data/${activeDataCon?activeDataCon:DataConId}/DataLinks/${activefarm}/`, true)
        firebase.update(`LinkList/${activefarm}/DataLinks/${activeDataCon?activeDataCon:DataConId}/`, NewDataCon)
      }

      SetView(ViewModes.SELECTOR)
    }
  }

  return(
    <View style={[Layout.colVCenter, Layout.fullWidth]}>
      <Button Contexts={t('Autopigs.NewDataCon')} Title/>
      <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'300px', maxWidth:'max-content', flexWrap: "wrap", flexDirection: "row"}]}>
        <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'100px', maxWidth:300}]}>
          {activeDataCon?(
            <Labelled_Input disabled={false}
              value={activeDataCon}
              label={t('Autopigs.FarmId')}
              placeholder={""}
              onChange={(text) => { //Not change able !!
              }}
              minWidth={60} maxWidth={100}
            />
          ):(
            <Labelled_Input disabled={false}
              value={DataConId.trim()}
              label={t('Autopigs.FarmId')}
              placeholder={""}
              onChange={(text) => {
                setDataConId(text.trim())
              }}
              minWidth={60} maxWidth={100}
              error={(LinkList&&LinkList[DataConId]?true:false)}
            />
          )}
          <Labelled_Input
            value={DataConName} // todo... option
            label={t('Autopigs.ConnectionName')}
            placeholder={""}
            onChange={(text) => {
              setDataConName(text)
            }}
            minWidth={90}
          />
        </View>
      </View>
      <View style={{height:20}}/>
      <View style={[Layout.fill, Layout.colVCenter, Common.backgroundPrimary, {minHeight:'max-content', width:"-webkit-fill-available"}]}>
        <View style={[Layout.fill, Layout.column, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', maxHeight:'350px'}]}>
          <View style={[Layout.row, Gutters.tinyV_Margin, {maxWidth:'-webkit-fill-available', minWidth:'-webkit-fill-available'}]}>
            <Select clearable={false}
              options={
                Sections.filter(Section => !SectionsList.includes(Section)).map((Section, i) => ({id: Section, index:i})).sort((a,b) => NormSorter(a.id,b.id))
              }
              labelKey="id"
              type={TYPE.select}
              placeholder={t('Autopigs.SelectSections')}
              onChange={(params:any) => {
                setSectionsList(old => {
                  var _new = [...old]
                  _new.push(params.value[0].id)
                  return _new
                })
              }}
              maxDropdownHeight="300px"
              overrides={{
                Root: { style: ({ }) => ({
                  minWidth:'300px', paddingTop:'1px', paddingBottom:'1px',
                  outline:'black 1px solid'
                })},
                Tag: {props: {overrides: {Root: {style: ({ }) => ({
                  minWidth: "55px", paddingTop:'1px', paddingBottom:'1px',
                })}}}}
              }}
            />
          </View>
          <FlatList style={[Layout.fullWidth, {maxWidth:600, minHeight:125, maxHeight:300}]}
            data={SectionsList}
            renderItem={({item, index}) => {
              return (
                <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'300px', maxWidth:'-webkit-fill-available', flexWrap: "wrap", flexDirection: "row"}]}>
                  <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'100px', maxWidth:'max-content'}]}>
                    <Text style={[{fontSize:20, fontWeight:'600'}]}>{item}</Text>
                  </View>
                  <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {flexWrap:'wrap', minWidth:'min-content', maxWidth:'-webkit-fill-available', justifyContent:'space-evenly'}]}>
                    <Datetime_Picker Title={t('Autopigs.From')} single ClearAble
                      MoveArrows={false} value={{start:DateList[index]?DateList[index].start:undefined}}
                      onValueChange={(time) => {
                        if (time) {
                          setDateList(old => {
                            old[index] = {...old[index], start:time.start}
                            return old
                          })
                        }
                      }}
                    />
                    <Datetime_Picker Title={t('Autopigs.To')} single ClearAble
                      MoveArrows={false} value={{start:DateList[index]?DateList[index].end:undefined}}
                      onValueChange={(time) => {
                        if (time) {
                          setDateList(old => {
                            old[index] = {...old[index], end:time.start}
                            return old
                          })
                        }
                      }}
                    />
                  </View>
                  <TouchableOpacity style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'50px', maxWidth:'max-content'}]} 
                    onPress={() => {
                      setSectionsList(old => {
                        var _new = [...old]
                        return _new.splice(index, 1)
                      })
                      var itemRem = [...SectionsList]
                      itemRem.splice(index, 1)
                      setSectionsList(itemRem)
                      if (DateList[index]) setDateList(old => {
                        delete old[index]
                        return old
                      })
                      //remove()
                    }}>
                      <DeleteIcon size={36} color={"black"} title={""}/>
                  </TouchableOpacity>
                </View>
              )
            }}
            numColumns={1}
            keyExtractor={(item, index) => String(index)}
          />
        </View>
        <Button Contexts={[t('ActionButtons.Save'),t('ActionButtons.Cancel')]} Frame Action={(e)=>!e?Save_New():SetView(ViewModes.SELECTOR)}/>
      </View>
    </View>
  )
}
//#endregion

const Autopigs = () => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  
  //const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector(({firebase}) => firebase.profile)
  
  const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const [FrontView, setFrontView] = useState<{view:ViewModes, data?:string}>({view:ViewModes.SELECTOR})
  //const focus = useIsFocused()
  useFirebaseConnect(() => {
    let AutopicCall: any[] = []
    if (activefarm && activefarm !== "" && FrontView.view === ViewModes.SELECTOR) {
      const path = `farms_data/${activefarm}/`
      AutopicCall.push({path:path + 'DataCons', queryParams: ['orderByKey'], storeAs: 'DataCons'}) //Could Use Firebase-slice "Datacons"
      AutopicCall.push({path:`LinkList/${activefarm}/DataLinks`, queryParams: ['orderByKey'], storeAs: 'LinkList'})
    }
    return AutopicCall
  })
  
  //Auth Check Handler
  /*useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])*/

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms)) return <LoadingSplash/>
  else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        {FrontView.view === ViewModes.AUTO?(
          <Autopig_Edit activeAutopig={FrontView.data} SetView={(V) => setFrontView({view:V})}/>
        ):FrontView.view  === ViewModes.FEED?(
          <FeedConnection activeFeed={FrontView.data} SetView={(V) => setFrontView({view:V})}/>
        ):FrontView.view  === ViewModes.DATACON?(
          <DataConnections activeDataCon={FrontView.data} SetView={(V) => setFrontView({view:V})}/>
        ):(
          <FrontSelector SetView={(V,ID) => setFrontView({view:V, data:ID?ID:undefined})}/>
        )}
      </View>
    )
  }
}

export default Autopigs