import React, {useState, useEffect, useMemo} from 'react'
import { View, ActivityIndicator, Text } from 'react-native'
import { useTheme } from '../../../Theme'
import { useTranslation } from 'react-i18next'

import { OprerationsContext_Action, useOperations_Context } from './Operations_context'

import { useAppSelector, useAppDispatch } from '../../../Store'
import { DataCons, Pens as FBPens } from '../../../Services/Firebase'
import { ConsysApi } from '../../../Services'
import { Button, Labelled_Input, Datetime_Picker } from '../../../Components'
//import { Select, TYPE } from "baseui/select"

import {
  Modal,
  ModalHeader,ModalBody,ModalFooter,
  ModalButton,FocusOnce,
} from 'baseui/modal'
import { ModalStates, /*ModuleForms*/ } from '.'
import { Operations, isEmpty } from '../../../Config/Types'
import { Select, TYPE } from 'baseui/select'

const ModalRequester = () => {
  const { t } = useTranslation()
  const { Layout, Colors, Gutters, Common } = useTheme()

  const { state: { Selection_Data, Modal_Data, Operations_isDone, Modal_isDone, Operations_Data, PenData:{penData} }, dispatch } = useOperations_Context()
  
  const [ErrorTxt, setErrorTxt] = useState<string|undefined>(undefined)
  useEffect(() => {
    setErrorTxt(undefined)
  }, [Operations_Data, /*Modal_Data.ScaleId*/])
  
  const [ModalData, setModalData] = useState<any|null> ()
  const [ScaleWating, setScaleWating] = useState<boolean>(false)
  
  const DataConsData = useAppSelector(state => Selection_Data&&Selection_Data.pen?DataCons.GetDataCons_Pen(state, Selection_Data.pen, Selection_Data.isShared):undefined)
  const _DataConsData = useAppSelector((state) => penData&&penData.autopigs?DataCons.GetPenDataCon(state, Selection_Data.isShared, penData.autopigs):undefined)

  const [AutoSelected, setAutoSelect] = useState<{id:string, label:string}|null>()
  const AutoOptions = useMemo<{id:string, label:string}[]>(() => {
    var options:{id:string, label:string}[] = []
    var _options = penData&&penData.autopigs?penData.autopigs.split(','):[]
    if (DataConsData && !isEmpty(DataConsData)) {
      options = Object.entries(DataConsData).map(([SysId, Datas]) => {
        if (Datas.scale&&Datas.scale!==""&& (Datas.isCorridor || _options.includes(SysId))){
          return({id:SysId, label:Datas.name?Datas.name:'Sys ' + SysId})
        }
      })
    }
    /*if (penData && penData.autopigs) {
      var _options = penData.autopigs.split(',')
      if (_options.length && DataConsData) {
        options = _options.map(Con => {
          var DataCon = Object.entries(DataConsData).find(([key, values]) => key+'' === Con+'')
          if (DataCon && DataCon[1] && DataCon[1].scale) {
            return({id:Con, label:DataCon[1].name?DataCon[1].name:'Sys ' + Con})
          }
          return null
        }).filter(e => e)
      }
    }*/
    setAutoSelect(options.length?options[0]:null)
    return options
  },[penData, DataConsData])

  //const [ScaleResult, setScaleResult] = useState<any|null>() // test imp for call
  const [GetScale, ScaleResult, lastPromiseInfo] = /* test imp for call [
    () => {
      setScaleResult({
        isFetching: true,
        isError: undefined,
        isSuccess: undefined
      }) 
      setTimeout(() => {
        setScaleResult({
          isFetching: false,
          //isError: true, error:{data: "Test Error"},
          isError: true, error:{data: ErrorTxt},
         // isSuccess: true, data:{amount: 2000}, 
        }) 
      }, 2000)
    }, null, null
  ]*/ //Old consys api... //
  ConsysApi.Corridor.GetScale()
  
  useEffect(() => {
    if (ScaleResult && (ScaleResult.isError || ScaleResult.isSuccess)) {
      if (ScaleResult.data) {
        if (Modal_Data.ModalState === ModalStates.STARTPEN) { 
          if (Operations_Data.action === Operations.START /*Modal_Data.ModalState === ModalStates.FETCHWEIGHT MODAL RESET AUTOPIG*/ && ScaleWating) {
            var value = ScaleResult.data.amount
            console.log("Consys returned amount value", value)
            
            //Set results in context
            setModalData(value / 1000)
            setScaleWating(false)
            return
          }
        }
        else if (/*Modal_Data.ModalState === ModalStates.FETCHWEIGHT ||*/ ScaleWating) {
          var value = ScaleResult.data.amount
          console.log("Consys returned amount value", value)
          
          //Set results in context

          dispatch({type:'set_Operations_Data', payload:{weight:value / 1000}})
          //dispatch({type: 'set_ModalForm', payload: {state:ModalStates.NONE, res: value / 1000}})
          dispatch({type: 'set_ModalForm', payload: {state:ModalStates.NONE, res:value}})
          dispatch({type: 'set_Modal_isDone', payload:true})
          setScaleWating(false)
          return
        }
        setErrorTxt(undefined)
      }
      if(ScaleResult.isError&&Modal_Data.ModalState !== ModalStates.STARTPEN) {
        console.log("Consys returned error", ScaleResult.error.data)
        //Set results in context
        setErrorTxt('error in fecthing weight')
        dispatch({type: 'set_Modal_isDone', payload:false})
        dispatch({type: 'set_ModalForm', payload:ModalStates.FETCHWEIGHT})
       
        setScaleWating(false)
      } else if (ScaleResult.isError) {
        console.log("Consys returned error", ScaleResult.error.data)
        //Set results in context
        setErrorTxt('error in fecthing weight')
        setModalData(null)
        setScaleWating(false)
      }
    }
    //Error senario
    if ((!ScaleResult || !ScaleResult.isFetching) && ScaleWating) {
      console.log('Reached here: Error', (!ScaleResult || !ScaleResult.isFetching), ScaleWating)
      setScaleWating(false)
    }
    //Waiting for nothing... set not waiting
  }, [ScaleResult])

  //const [ResetResult, setResetResult] = useState<any|null>() // test imp for call
  const [ResetScale, ResetResult, Reset_lastPromiseInfo] = /* test imp for call [
    (AutopigId, WeightValue) => {
      setResetResult({
        isFetching: true,
        isError: undefined,
        isSuccess: undefined
      }) 
      setTimeout(() => {
        setResetResult({
          isFetching: false,
          //isError: true, error:{data: ErrorTxt},
          isSuccess: true, data:{result: true, AutoId:AutopigId}, 
        }) 
      })
    }, null, null
  ]*/ //Old consys api... //
  ConsysApi.Scale.ResetScaleWeight()

  useEffect(() => {
    if (ResetResult && (ResetResult.isError || ResetResult.isSuccess)) {
      //var AutoId = ResetResult.data.AutoId

      if (ResetResult.data/*&&ScaleResult&&ScaleResult.data*/) {
        if (Modal_Data.ModalState === ModalStates.STARTPEN) {
          //ResetResult.data = result for current reset request
          
          //ResetResult.data - contain if success...
          //ResetResult.data is array of autopigs called results
          console.log("Consys returned reset done", ResetResult, ResetResult.data.result)
          if (ResetResult.data[0]) //if is bool this will work 
          {
            //if all autopigs return true
            dispatch({type: 'set_ModalForm', payload: {state:ModalStates.NONE, res:undefined}})
            dispatch({type: 'set_Modal_isDone', payload:true})
            setScaleWating(false)
            //else wait for other autopigs response
            return
          }
          //if one off autopigs retrun false... display error
          //setErrorTxt
          //setScaleWating(false)
          //return
        }
        setErrorTxt(undefined)
      }
      if (ResetResult.isError) {
        console.log("Consys returned error1", ResetResult, ResetResult.error.data)
        var id = ResetResult.error&&ResetResult.error.statusText&&ResetResult.error.statusText.includes('#')?ResetResult.error.statusText.split('#')[0]:''
        setErrorTxt('error while reset Sys#' +id)
        setScaleWating(false)
      }
    }
    //Error senario -- Waiting for nothing... set not waiting
    if ((!ResetResult || !ResetResult.isFetching) && ScaleWating) {
      console.log('Reached here: Error', (!ResetResult || !ResetResult.isFetching), ScaleWating)
      setScaleWating(false)
    }
  }, [ResetResult])

  const DoModalAction = () => {
    var Auto = AutoSelected&&AutoSelected.id?AutoSelected.id:undefined
    
    if (Modal_Data.ModalState === ModalStates.FETCHWEIGHT) {
      if (!Auto) {
        setErrorTxt("Please select an Autopig")
        return
      }
      setScaleWating(true)
      setErrorTxt(undefined)
      console.log("Call consys - selected scale - for current weight", Selection_Data.isShared, Selection_Data.pen, 'ID: ' + Auto )

      //Calling Consys Fetch scale now...
      GetScale({FarmKey:Selection_Data.isShared?Selection_Data.isShared:undefined, systemids:Auto+'', valve:Selection_Data.pen+''})
      //GetScale() //--- test... now waiting for response
    }
    else if (Modal_Data.ModalState === ModalStates.STARTPEN) {
      /*if (!Auto) {
        setErrorTxt("Please select an Autopig")
        return
      }*/
      if (!penData ||!penData.autopigs) {
        setErrorTxt("Error pen does not contain data for autopig ?")
        return
      }
      setScaleWating(true)
      setErrorTxt(undefined)

      //TODO : Foreach ??
      // * //foreach avaible autopig
      ResetScale({FarmKey:Selection_Data.isShared?Selection_Data.isShared:undefined, systemids:penData.autopigs, weight:ModalData})
      //ResetScale(2, ModalData)
      //setModalData(null)  //REVIEW: REQUIRED?
    }
    else {
      var res = undefined
      if (Modal_Data.ModalState === ModalStates.CLOSEPEN) res = ModalData
      //console.log("Done", res, Modal_Data, ModalData)
      setScaleWating(false)
      setErrorTxt(undefined)
      dispatch({type: 'set_ModalForm', payload: {state:ModalStates.NONE, res:res}})
      dispatch({type: 'set_Modal_isDone', payload:true})
    }
  }

  useEffect(() => {
    if (Modal_Data.ModalState === ModalStates.STARTPEN){
      var weight = Operations_Data.weight?Operations_Data.weight:0
      console.log("Input display", Operations_Data, Modal_Data)
      if (Operations_Data.quantity && Modal_Data.ModalResult) {
        weight = weight / Operations_Data.quantity
      }
      if (weight) setModalData(weight * 1000)
      else setModalData("")
    }
  }, [Modal_Data.ModalState])

  if (ScaleWating && Modal_Data.ModalState !== ModalStates.STARTPEN) {
    return <ActivityIndicator style={[{position:'absolute', left:0, right:0,top:0,bottom:0, alignItems:'center', justifyContent:'center', backgroundColor: '#F5FCFF88'}]} size='large' />
  } 
  else if(!Modal_isDone) {
    if (Modal_Data.ModalState===ModalStates.FETCHWEIGHT && AutoOptions.length<=1 && !ErrorTxt) DoModalAction()
    return (
      <Modal onClose={() => {
        setScaleWating(false)
        setErrorTxt(undefined)
        dispatch({type: 'set_ModalForm', payload: ModalStates.NONE}) 
      }}
        isOpen={Modal_Data.ModalState !== ModalStates.NONE} unstable_ModalBackdropScroll={true}>
        <FocusOnce>
          <ModalHeader>{t(`Operations.ModalConf`)}</ModalHeader>
        </FocusOnce>
        <ModalBody>
        {Modal_Data.ModalState === ModalStates.FETCHWEIGHT && AutoOptions.length>1?
          <View>
            <Text>{t('SystemBoard.FetchTxt')}</Text>
            <Select clearable={false}
                options={AutoOptions}
                value={AutoSelected?[AutoSelected]:undefined}
                type={TYPE.select}
                placeholder={"---"}
                onChange={(params:any) => {
                  setAutoSelect(params.value[0])
                  /*if (params.value[0]) {
                    var id = params.value[0].id
                    var datas = AutoPigs&&AutoPigs[id]?AutoPigs[id]:undefined
                    dispatch({type:'set_Selected_Scale', payload:{id:id, datas:datas}})
                  } else dispatch({type:'set_Selected_Scale', payload:undefined})*/
                }}
                maxDropdownHeight="300px"
                overrides={{
                  Root: { style: ({ $theme }) => ({
                    maxWidth:'200px', minWidth:'120px', 
                    outline:'black 1px solid'
                  })}
                }}
              />
          </View>
        :<></>}
        {Modal_Data.ModalState === ModalStates.CLOSEPEN?
          <View>
          <Text>
            {t('Operations.ClearWarn')}
          </Text>
          <View style={[Layout.fill, Layout.row, {justifyContent:'space-between'}]}>
            <Datetime_Picker Title={t('Operations.CloseDate')} value={{start:Operations_Data.time}} single={true}
              onValueChange={(time) => {
                if (time && time.start) {
                  //var _Date = new Date(time.start).setHours(23,59,59)
                  setModalData(time.start + 3600)
                }
              }}
            />
          </View>
        </View>
        :<></>}
        {Modal_Data.ModalState === ModalStates.STARTPEN?
        <View>
          <Text>
            {t('SystemBoard.StartTxt') + "\n" + t('Operations.AutosResetTxt') + '\n' + AutoOptions.reduce((res, e) => res.length?(res + ', ' + e.label):e.label, '')}
          </Text>
          <View style={[Layout.row, {marginTop:25}]}>
            <Labelled_Input disabled={false}
              value={ModalData+''}
              label={t('SystemBoard.ResetLabelTxt') + t('DataTypes.W_small_T')}
              placeholder={""}
              type='number'
              onChange={(text,num) => {
                setModalData(num)
              }}
              minWidth={80} maxWidth={150}
              error={(!ModalData||isNaN(ModalData))} //ModalData < 2 ??
            />
            <View style={{width:100}}/>
            {AutoOptions.length>1?(
              <View style={[Layout.fill, {maxWidth: 150, minWidth:80, minHeight:22}]}>
                <View style={[Layout.fill, Gutters.tinyPadding, {paddingBottom:2, justifyContent:'flex-end'}]}>
                  <Text>Select 1 of avaible autopigs, for fetch requests</Text>
                </View>
                <View style={[Layout.fill, Common.border, {minHeight:'max-content'}]}>
                  <Select clearable={false}
                    options={AutoOptions}
                    value={AutoSelected?[AutoSelected]:undefined}
                    type={TYPE.select}
                    placeholder={"---"}
                    onChange={(params:any) => {
                      setAutoSelect(params.value[0])
                    }}
                    maxDropdownHeight="300px"
                    overrides={{
                      Root: { style: ({ $theme }) => ({
                        maxWidth:'200px', minWidth:'120px', 
                        outline:'black 1px solid'
                      })}
                    }}
                  />
                </View>
              </View>
            ):<></>}
            <View style={[{maxHeight:55, alignSelf:'flex-end'}]}>
              <Button maxWidth={90} Contexts={t('SystemBoard.fetchLabel')} Action={(e) => {
                setScaleWating(true)
                console.log("Call consys - selected scale - for current weight")
                var Auto = AutoSelected&&AutoSelected.id?AutoSelected.id:undefined
                if (!Auto) {
                  setErrorTxt("Please select an Autopig")
                  return
                }
                else {
                  GetScale({FarmKey:Selection_Data.isShared?Selection_Data.isShared:undefined, systemids:Auto+'', valve:Selection_Data.pen+''})
                  //GetScale()
                }
              }}/>
            </View>
          </View>
          {/*ScaleWating?<ActivityIndicator style={[{position:'absolute', left:0, right:0,top:0,bottom:0, alignItems:'center', justifyContent:'center', backgroundColor: '#F5FCFF88'}]} size='large' />:<></>*/}
        </View>
        :<></>}
        {ErrorTxt?(
            <Text style={{color:Colors.error}}>{ErrorTxt}</Text>
          ):<></>}
         </ModalBody>
         <ModalFooter>
          <ModalButton autoFocus onClick={() => {
            setScaleWating(false)
            setErrorTxt(undefined)
            dispatch({type: 'set_Operations_isDone', payload:false})
            dispatch({type: 'set_ModalForm', payload: {state:ModalStates.NONE, res:false}})
          }}>
            {t('ActionButtons.Cancel')}
          </ModalButton>
          {Modal_Data.ModalState === ModalStates.STARTPEN&&(!ModalData||isNaN(ModalData))?
          <></>:
            <ModalButton /*autoFocus*/ onClick={() => DoModalAction()
          }>
            {t('ActionButtons.Submit_btn')}
            {/* {ErrorTxt?t('ActionButtons.Retry_btn'):t('ActionButtons.Submit_btn')} */}
          </ModalButton>
        }
          {ScaleWating?<ActivityIndicator style={[{position:'absolute', left:0, right:0,top:0,bottom:0, alignItems:'center', justifyContent:'center', backgroundColor: '#F5FCFF88'}]} size='large' />:<></>}
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalRequester