import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet
} from 'react-native'
import { Checkbox, LABEL_PLACEMENT} from "baseui/checkbox";

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { UserStates } from '../../Config/Types'

export interface Subscription_Props {
  subscription:string
  
  onChange:(e:string) => void,
}

interface Subscription_Item_Props {
  CurrentSub:string
  onChange:(e:string) => void,
  setCurrentSub:(e:string) => void,

  Subscription: string, 
  Title: string,
  Price: string,
  Context: string
}

const Subscription_Item = ({CurrentSub, onChange, setCurrentSub, Subscription, Title, Price, Context}: Subscription_Item_Props) => {
  const { Common, Layout, Colors } = useTheme()
  return(
    <TouchableOpacity style={[Common.border, styles.card, CurrentSub===Subscription?{backgroundColor: Colors.AgriGreen}:{}]}
      onPress={() => {
        setCurrentSub(Subscription)
        onChange(Subscription)
      }}
    >
      <Checkbox diasbled
        labelPlacement={LABEL_PLACEMENT.left}
        overrides={{
          Root: { style: ({ $theme }) => ({
            alignSelf: "center",
            alignItems: "center"
          })},
          Label: { style: ({ $theme }) => ({
            fontSize: "20px",
            fontWeight: "bold"
          })}
        }}
        checked={CurrentSub === Subscription?true:false}
      >{Title}</Checkbox>
      <View style={[Common.border, styles.title]}>
        
      </View>
      <View style={[Common.border, styles.context]}>
        <Text style={{fontWeight:"bold"}} >{Price}</Text>
        <Text style={{flexWrap:'wrap'}}>{Context}</Text>
      </View>
    </TouchableOpacity>
  )
}

const Subscription = ({subscription, onChange, ...rest}: Subscription_Props) => { 
  const { t } = useTranslation()
  const { Layout } = useTheme()

  const [CurrentSub, setCurrentSub] = useState(subscription)
  return(
    <View style={[Layout.fill, Layout.fullSize, Layout.col]}>
      <View style={{flexWrap: "wrap", flexDirection: "row"}}>
        <Subscription_Item CurrentSub={CurrentSub} onChange={onChange} setCurrentSub={setCurrentSub} 
          Subscription={UserStates.WORKER} Title={t('Subscription.Staff')} Price={t('Subscription.Free')} Context={t('Subscription.StaffDesc')}
        />
        <Subscription_Item CurrentSub={CurrentSub} onChange={onChange} setCurrentSub={setCurrentSub} 
          Subscription={UserStates.FREE} Title={t('Subscription.Owner')} Price={t('Subscription.Free')} Context={t('Subscription.OwnerDesc')}
        />
        <Subscription_Item CurrentSub={CurrentSub} onChange={onChange} setCurrentSub={setCurrentSub} 
          Subscription={UserStates.CONTACT} Title={t('Subscription.Contact')} Price={t('Subscription.Free')} Context={t('Subscription.ContactDesc')}
        />
      </View>
    </View>
  )
}

Subscription.defaultProps = {
  subscription: UserStates.STARTER,
}

const styles = StyleSheet.create({
  card: {
    flex:1,
    minWidth:180, 
    maxWidth:250, 
    minHeight:132, 

    alignItems:"center", 
    justifyContent:'flex-start', 
    flexDirection:'column',
    flexGrow:1
  },
  title: {
    flex:1,
    minHeight:27, 
    maxHeight:27, 
    minWidth:"-webkit-fill-available"
  },
  context: {
    flex:1,
    minHeight:27, 
    maxHeight:'max-content',
    minWidth:"-webkit-fill-available"
  }
})

export default Subscription