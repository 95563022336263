import { useEffect, useState } from 'react'
import { useFirebaseConnect } from 'react-redux-firebase'
export * as Sections from './Sections-Slice'
export * as Pens from './Pens-Slice'
export * as Weighings from './Weighings-Slice'
export * as Comments from './Comments-Slice'
export * as Graphs from './Graphs-Slice'
export * as DataCons from './DataCon-Slice'

export const FirebaseCaller = (Calls:any[] = [], depends?: any[]) => {
  const [_Calls, setCalls] = useState(Calls)
  useEffect(() => depends?setCalls([...Calls]):undefined , depends)
  useFirebaseConnect(_Calls)
}