import React, {useState, useEffect, useRef} from 'react'
import { Platform, View, ActivityIndicator, Text, StyleSheet } from 'react-native'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'

import { Button, Labelled_Input, Datetime_Picker } from '../'
import { Select, TYPE } from "baseui/select"

import { useAppSelector, useAppDispatch } from '../../Store'
//import { useFirebase, isLoaded, isEmpty, useFirebaseConnect, firebaseConnect } from 'react-redux-firebase'

import { MainOperationTypes, OperationType, OperationTypes, Treatment } from '../../Config/Types'
import { Comments } from '../../Services/Firebase'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
} from 'baseui/modal';

export interface Response {
  res: boolean,
  data: string,
  quantity?: number,
  weight?: number,
  code?: string,
  date?: number,
  internal?: boolean,
  CloseIsOk?:boolean,
  reportCreate?: boolean
}

export interface Comment_Module_Props {
  //children: React.ReactNode,
  maxWidth:number,
  Title: string,

  Settings:{
    HaveDate?:number|boolean,
    HaveCodeList?:boolean,
    MainCodes?:boolean
    HaveQuantity?:boolean,
    HaveWeight?:boolean,
    HaveCorridor?:boolean,
    CanBeInternal?:boolean,
    DoCheckZero?:number,
    ReportCheck?:boolean
  },

  Data:{
    Code?: string,
    Quantity?: number,
    Weight?: number,
    Note?: string,
    Internal?: boolean
  },

  onDone: (R:Response) => void
}

//{HaveDate, HaveCodeList, HaveQuantity, HaveWeight, HaveCorridor, CanBeInternal}, {_Code, _Quantity, _Weight, _Note, _Internal},
const Comment_Module = ({ maxWidth, Title, Settings, Data, onDone }:Comment_Module_Props) => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout, Fonts, Colors } = useTheme()
  
  //const dispatch = useAppDispatch()
  //const firebase = useFirebase()

  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  //const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)
  //const activePen = useAppSelector(state => state.activeData.pen)

  const Treatments = useAppSelector(state => Comments.GetTreatments(state, undefined))
  const [ScaleWating, setScaleWating] = useState(false)

  const [InputText, setInputText] = useState(Data.Note?Data.Note:"")
  const [QuantityText, setQuantityText] = useState<number>(Settings.HaveQuantity&&Data.Quantity?Data.Quantity:1)
  const [WeightText, setWeightText] = useState<number|string>(Settings.HaveWeight&&Data.Weight&&Data.Weight>0?Data.Weight:"")
  const [MainCodeListItem, setMainCodeListItem] = useState<OperationType|undefined>(MainOperationTypes["-1"])
  const [CodeListItem, setCodeListItem] = useState<OperationType|undefined>(undefined)
  const [DateSet, setDateSet] = useState(Settings.HaveDate&&(Settings.HaveDate>1)?new Date(Settings.HaveDate as number):new Date(Date.now()))
  const [isInternal, setisInternal] = useState(Data.Internal?Data.Internal:false)
  
  const WeightRef = useRef(null)
  const QuantRef = useRef(null)
  
  const [ModalCheck, setModalCheck] = useState<undefined|boolean>(undefined)

  useEffect(() => {
    setQuantityText(Settings.HaveQuantity&&Data.Quantity?Data.Quantity:1)
    setWeightText(Settings.HaveWeight&&Data.Weight&&Data.Weight>0?Data.Weight:"")
    setInputText(Data.Note?Data.Note:"")
    setDateSet(Settings.HaveDate&&((typeof Settings.HaveDate==='number')&&Settings.HaveDate>1)?new Date(Settings.HaveDate):new Date(Date.now()))
  },[Data.Quantity, Data.Weight, Data.Note])

  useEffect(() => {
    if (Data.Code && Treatments) {
      var CODE = Data.Code
      //Get Main-code
      if (CODE.includes('_')) {
        var codes:string[] = CODE.split('_')
        if (codes[0] === "-1") setMainCodeListItem({code:'-1', description:"---", death:false, used:true})
        else {
          var te = Object.entries(MainOperationTypes).find(([key, e]) => e.code === codes[0])
          if (te) setMainCodeListItem(te[1])
        }
        CODE = codes[1]
      }
      //Set Treatment-code
      if (CODE === "-1") setCodeListItem({code:'-1', description:"---", death:false, used:true})
      else {
        var treatment = Treatments.find(e => e.code === CODE)
        if (treatment) setCodeListItem(treatment)
      }
    }
  },[Data.Code])

  return(
    <View style={[Common.border, {width:'-webkit-fill-available', maxWidth: maxWidth, paddingVertical:10, paddingHorizontal:5}]}>
      <View style={styles.textAreaContainer} >
        <View style={[Layout.row, {justifyContent:'space-between', flexWrap:'wrap'}]}>
          {((Title&&Title !=="")||Settings.HaveDate||Settings.HaveCodeList)&&(
            <View style={[Layout.column, {marginBottom:5, width:'fit-content', height:'fit-content'}]}>
              {(Title&&Title !=="")&&(<Text style={[Fonts.textRegular, {height: 26, color:Colors.input}]}>{Title}</Text>)}
              {Settings.HaveDate&&(
                <View style={[Layout.fill, {alignItems:'flex-start', marginBottom:10}]}>
                  <Datetime_Picker value={{start:DateSet.getTime()}} single={true}
                    onValueChange={(time) => {
                      if (time) setDateSet(new Date(time.start))
                    }}
                  />
                </View>
              )}
              {Settings.HaveCodeList&&(
                <View style={[Layout.fill, {height:71, minHeight:71, justifyContent:'space-between'}]}>
                  {Settings.MainCodes&&(
                  <Select clearable={false} required
                    options={[{code:'-1', description:"---", death:false, used:true} as Treatment].concat(Object.values(MainOperationTypes))
                      .map((Operation)=> ({
                        id:Operation.code, 
                        label: Operation.description, 
                        item:Operation
                      })
                    )}
                    value={[{
                      id:MainCodeListItem?MainCodeListItem.code:MainOperationTypes["0"].code,
                      label:MainCodeListItem?MainCodeListItem.description:MainOperationTypes["0"].description,
                      item:MainCodeListItem
                    }]}
                    type={TYPE.select}
                    placeholder="Main Code"
                    onChange={(params:any) => setMainCodeListItem(params.value[0].item)}
                    maxDropdownHeight="300px"
                    overrides={{
                      Root: { style: ({ }) => ({
                        maxWidth:'606px', outline:'black 1px solid'
                      })},
                      ValueContainer: {style: ({ }) => ({
                        height: "30px",
                        paddingLeft:'2px', paddingRight:'2px', paddingTop:'2px', paddingBottom:'2px'
                      })}
                    }}
                  />)}
                  <Select clearable={false} required
                    options={[{code:'-1', description:"---", death:false, used:true} as Treatment].concat(Treatments).filter(A => A && A.used)
                      .map((Operation)=> ({
                        id: Operation.code,
                        label: Operation.code==='-1'?Operation.description : Operation.code + '# ' + Operation.description,
                        item: Operation
                      })
                    )}
                    value={[{
                      id:CodeListItem?CodeListItem.code:'-1',
                      label:CodeListItem? (CodeListItem.code==='-1'?CodeListItem.description : CodeListItem.code + '# ' + CodeListItem.description):'---',
                      item:CodeListItem
                    }]}
                    type={TYPE.select}
                    placeholder="Main Code"
                    onChange={(params:any) => setCodeListItem(params.value[0].item)}
                    maxDropdownHeight="300px"
                    overrides={{
                      Root: { style: ({ }) => ({
                        maxWidth:'606px', outline:'black 1px solid'
                      })},
                      ValueContainer: {style: ({ }) => ({
                        height: "30px",
                        paddingLeft:'2px', paddingRight:'2px', paddingTop:'2px', paddingBottom:'2px'
                      })}
                    }}
                  />
                </View>
              )}
            </View>
          )}
          {Settings.CanBeInternal&&(
            <View style={[Layout.column, {justifyContent:'space-between', marginBottom:5}]}>
              <Checkbox
                labelPlacement={LABEL_PLACEMENT.top}
                overrides={{
                  Root: { style: ({ }) => ({
                    alignSelf: "center", alignItems: "center",
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })},
                  Label: { style: ({ }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
                checked={isInternal}
                onChange={(e:any) => {
                  setisInternal(e.target.checked)
                }}
              >
                <Text style={[{}]}>{t('Operations.Internal')}</Text>
              </Checkbox>
            </View>
          )}
          {(Settings.HaveQuantity||Settings.HaveWeight)&&(
            <View style={[Layout.column, {justifyContent:'space-between', marginBottom:5}]}>
              {Settings.HaveQuantity&&(
                <View style={[Layout.rowCenter, {marginBottom:5, width:'fit-content', height:'fit-content', justifyContent:'center'}]}>
                  <Text style={[Fonts.normal, {width:75, color:Colors.input, textAlignVertical:'center'}]}>{t('Operations.Quantity')}</Text>
                  <Labelled_Input maxWidth={55} minHeight={30} Ref={QuantRef}
                    type={"number"}
                    value={QuantityText+''}
                    onChange={(text, num) => {
                      setQuantityText(num)
                    }}
                  />
                </View>
              )}
              {Settings.HaveWeight&&(
                <View style={[Layout.rowCenter]}>
                  <Text style={[Fonts.normal, {width:75, color:Colors.input, textAlignVertical:'center'}]}>{t('Operations.AvgWeight')+" "+t('DataTypes.W')}</Text>
                  <Labelled_Input maxWidth={55} minHeight={30} Ref={WeightRef}
                    value={WeightText+''}
                    onChange={(text) => {
                      text = text.replace(',','.')
                      if ( text === "" || !isNaN(parseFloat(text)) || (text.endsWith('.') && !text.slice(0,text.length-1).includes('.'))) setWeightText(text)
                    }}
                  />
                </View>
              )}
            </View>
          )}
        </View>
        <Labelled_Input multiline minHeight={150}
          placeholder={t('CommentModule.NotePlaceholder')}
          onChange={text => {
            setInputText(text)
          }}
          value={InputText}
        />
      </View>
      <Button Contexts={[t('ActionButtons.Done'),t('ActionButtons.Cancel')]} Frame Action={(e)=> {
        if (!ScaleWating) {
          if (!e) {
            var weight = WeightText!==""?WeightText:undefined
            var quantity = !isNaN(QuantityText)?QuantityText:undefined
            var code = (MainCodeListItem&&MainCodeListItem.code?MainCodeListItem.code:'-1') + "_" + (CodeListItem&&CodeListItem?.code?CodeListItem.code:'-1')
            
            /*if (Settings.HaveQuantity && !quantity && QuantRef.current) QuantRef.current.focus()
            else */if (Settings.DoCheckZero && quantity && Settings.DoCheckZero - quantity <= 0) {
              setModalCheck(true)
            } else {
              //if (HaveWeight && !weight && WeightRef.current) WeightRef.current.focus()
              onDone({res:true, data: InputText, quantity:quantity, weight:weight, code:code, date:DateSet.getTime(), internal:isInternal, CloseIsOk:undefined})
            }
          }
          else onDone({res: false, data:InputText})
        }
      }}/>
      <CloseModal ReportCheck={Settings.ReportCheck} setModalCheck={(ModalCheck) => setModalCheck(ModalCheck)} ModalCheck={ModalCheck} onDone={(TimeRes, reportCreate) => {
        var weight = WeightText!==""?WeightText:undefined
        var quantity = !isNaN(QuantityText)?QuantityText:undefined
        var code = (MainCodeListItem&&MainCodeListItem.code?MainCodeListItem.code:'-1') + "_" + (CodeListItem&&CodeListItem?.code?CodeListItem.code:'-1')
        if (TimeRes) {
          onDone({res:true, data: InputText, quantity:quantity, weight:weight, code:code, date:DateSet.getTime(), internal:isInternal, CloseIsOk:true, reportCreate:reportCreate})
        } else {
          onDone({res:false, data: InputText, quantity:quantity, weight:weight, code:code, date:DateSet.getTime(), internal:isInternal, CloseIsOk:false, reportCreate:reportCreate})
        }
      }}/>
      {(ScaleWating) &&
        <ActivityIndicator style={[{position:'absolute', left:0, right:0,top:0,bottom:0, alignItems:'center', justifyContent:'center', backgroundColor: '#F5FCFF88'}]} size='large' />
      }
    </View>
  )
}

interface CloseModal_Props {
  setModalCheck:(ModalCheck:boolean) => void,
  ModalCheck:undefined|boolean,
  onDone: (TimeRes?:number, reportCreate?:boolean) => void,
  ReportCheck?:boolean
}
export const CloseModal = ({setModalCheck, ModalCheck, onDone, ReportCheck}:CloseModal_Props) => {
  const { t } = useTranslation()
  const { Layout } = useTheme()
  const [DateSet, setDateSet] = useState(Date.now())
  const [Report_Create_Check, setReport_Create_Check] = useState(false)

  return(
    <Modal onClose={() => setModalCheck(false)} isOpen={ModalCheck} unstable_ModalBackdropScroll={true}>
      <FocusOnce>
        <ModalHeader>{t(`Operations.ModalConf`)}</ModalHeader>
      </FocusOnce>
      <ModalBody>
        <Text>
          {t('Operations.ClearWarn')}
        </Text>
        <View style={[Layout.fill, Layout.row, {justifyContent:'space-between'}]}>
          <Datetime_Picker Title={t('Operations.CloseDate')} value={{start:DateSet}} single={true}
            onValueChange={(time) => {
              if (time) setDateSet(time.start)
            }}
          />
          {ReportCheck&&(
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={Report_Create_Check}
              onChange={(e:any) => {
                setReport_Create_Check(e.target.checked)
              }}
            ><Text style={[{}]}>{t('Operations.RepCreate')}</Text></Checkbox>
          )}
        </View>
      </ModalBody>
      <ModalFooter>
        <ModalButton autoFocus onClick={() => {
          setModalCheck(false)
          onDone(undefined, undefined)
        }}>
          {t('ActionButtons.Cancel')}
        </ModalButton>
        <ModalButton autoFocus onClick={() => {
          setModalCheck(false)
          onDone(DateSet, ReportCheck?Report_Create_Check:undefined)
        }}>
          {t('ActionButtons.Submit_btn')}
        </ModalButton>
      </ModalFooter>
    </Modal>
  )
}

const styles = StyleSheet.create({
  textAreaContainer: {
    borderColor: '#a6a6a6',
    borderWidth: 1,
    paddingLeft:'5px', paddingRight:'5px', paddingTop:'5px', paddingBottom:'5px',
    marginBottom:10
  },
  textArea: {
    height: 150,
    justifyContent: "flex-start",
    borderColor: "black",
    borderWidth: 0.5,
  }
})

Comment_Module.defaultProps = {
  maxWidth: 600,
  Title: "",
  
  Settings:{
    HaveDate: false,
    HaveCodeList: false,
    HaveQuantity: false,
    HaveWeight: false,
    HaveCorridor: false
  },
  Data:{
    Code:null,
    Weight: 0,
    Quantity: 0,
    Note:null,
  },
  onDone: () => {},
}

export default Comment_Module