import React from 'react'
import { SafeAreaView } from 'react-native'
import { useTheme } from '../Theme'

import { createNativeStackNavigator } from '@react-navigation/native-stack'

import { IndexAgrisysContainer } from '../Containers'

const MainStack = createNativeStackNavigator()

// @refresh reset
const MainNavigator = () => {
  const { Common, Layout } = useTheme()
  return (
    <SafeAreaView  style={[Layout.fullFill, { backgroundColor: "white" }]}>
      <MainStack.Navigator initialRouteName={"Home"}
        screenOptions={{
          title: 'AgrisysApp',
          headerTintColor: 'white',
          headerTitleAlign: 'center',
          presentation:"modal"
      }}>
        <MainStack.Screen name="Home" component={IndexAgrisysContainer} 
          options={{
            headerShown: false
          }}
        />
      </MainStack.Navigator>
    </SafeAreaView>
  )
}

export default MainNavigator