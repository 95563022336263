import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
//import { actionTypes } from 'react-redux-firebase'
//import { useAppSelector, useAppDispatch } from '../'

export interface ActiveStates {
  farm: string|undefined,
  section: string|undefined,
  pen: number|undefined,

  batch: string|undefined,
  report: string|undefined,
  autopig: string|{systemId:string, FarmId:string}|undefined,
  IsAppVersion: boolean,
  
  Token:string|undefined,
  ConsysState:"init"|"loading"|"error"|"ok",

  XTernalTokens: Record<string,string|undefined>,
  XTernalStates: "init"|"loading"|"error"|"ok",
}

const initialState = createEntityAdapter().getInitialState<ActiveStates>({
  farm: undefined,
  section: undefined,
  pen: undefined,

  batch: undefined,
  report: undefined,
  autopig: undefined,
  IsAppVersion: false,

  Token: undefined,
  ConsysState: "init",
  XTernalTokens: {},
  XTernalStates: "init"
})

// Thunk functions
export const getfarm = createAsyncThunk<string,void>('active/farm', async () => {
  //const profile = useAppSelector(({firebase}) => firebase.profile)

  /*switch(profile.state) {
    case UserStates.ADMIN:
    case UserStates.TECH:

      break;
    default:
      break;
  }*/

  return ""
})

const ActiveSlice = createSlice({
  name: 'active',
  initialState,
  reducers: {
    setStates: (state, action) => {
      switch(action.payload.state) {
        case "farm":
          return Object.assign({}, state, {farm: action.payload.data})
        case "section":
          return Object.assign({}, state, {section: action.payload.data})
        case "pen":
          return Object.assign({}, state, {pen: action.payload.data})
        case "batch":
          return Object.assign({}, state, {batch: action.payload.data})
        case "report":
          return Object.assign({}, state, {report: action.payload.data})
        case "autopig":
          return Object.assign({}, state, {autopig: action.payload.data})
        case "IsAppV":
          return Object.assign({}, state, {IsAppVersion: action.payload.data})
        default:
          break
      }
      return state
    },
    resetState: (state, action) => {
      switch(action.payload.state){
        case "farm":
          return Object.assign({}, state, {farm: undefined})
        case "section":
          return Object.assign({}, state, {section: undefined})
        case "pen":
          return Object.assign({}, state, {pen: undefined})
        case "batch":
          return Object.assign({}, state, {batch: undefined})
        case "report":
          return Object.assign({}, state, {report: undefined})
        case "autopig":
          return Object.assign({}, state, {autopig: undefined})
        case "IsAppV":
          return Object.assign({}, state, {IsAppVersion: false})
        case "all":
          return Object.assign({}, state, initialState)
        default:
          break
      }
    },
    setToken: (state, {payload}) => {
      var res:{Token?, XTernalTokens:Record<string,string|undefined>} = {XTernalTokens:{...state.XTernalTokens}}
      if (!payload.FarmKey) {
        res.Token = payload.token
      }else {
        res.XTernalTokens[payload.FarmKey] = payload.token
      }
      return Object.assign({}, state, res)
    }
  },
  /*extraReducers: builder => {
    builder
      .addCase(getfarm.fulfilled, (state, action) => {
        debugger
        //state.active_farm = 'idle'
      })
  }*/
})

const { actions, reducer } = ActiveSlice
export const { setStates, resetState, setToken } = actions
export default reducer