export default {
  SplashText: 'AgrisysApp, Tecnología de Automoción de Proteínas.',
  AgrisysFront:{
    Welcome: 'Bienvenido a Agrisys App',
    Menu1: 'Agrisys Nube',
    Menu2: 'Conectarse al dispositivo',
    Menu3: 'Versión móvil'
  },

  AgrisysConnect:{
    NavTitle: 'Inicio de sesión de Usuario',
    label1: 'Correo electrónico:',
    label2: 'Contraseña:',
    submit_btn: 'Iniciar sesión',

    reset: 'Enviar contraseña de restablecimiento.',
    create: 'Crear nuevo usuario'
  },

  AgrisysUser: {
    reVerify:'Reenviar verificación',
    Refresh:'Refresh',

    label1: 'Correo electrónico:',
    label2: 'Contraseña:',
    submit_btn: 'Crear usuario',

    Logout: 'Cerrar sesión'
  },

  AgrisysAdmin:{
    NavTitle: 'Panel de Administración',
    Welcome: 'Bienvenido: {{name}}',
    Userboard: 'Panel de usuario',
    Systems: 'Sistemas',
    Tools: 'Herramientas',
    Logout: 'Cerrar sesión'
  },

  AdminSystems:{
    Name:"Nombre",
    Manager:"Gerente",
    Email:"Correo electrónico",

    Selected:"Etiqueta de Granja",
    Consys:"Consys",
    Panel:"Informacion de la Granja"
  },
  
  AgrisysMenu:{
    NavTitle: 'Panel de Usuario',
    Welcome: 'Bienvenido: {{name}}',
    SystemBoard: 'Placa del sistema',
    DashBoard: 'Panel',
    Pens: 'Boligrafo',
    Batchs: 'Lote',
    Reports: 'Informes',
    Growth: 'Curvas de crecimiento',
    Operations: 'Operaciones',
    Autofunctions: 'AutoPigs',
    FeedingFunctions: 'Alimentación',
    Pigs: 'Cerdos',
    Settings: 'Ajustes',
    Logout: 'Cerrar Sesión'
  },

  SystemBoard: {
    StartTxt: `La Acción actual es una nueva marca de partida.\nQuieres resetear los datos de autopig para un nuevo peso medio?\nDe lo contrario, los datos futuros podrían distorsionarse, ya que el restablecimiento es incorrecto.`,
    ResetTxt: `La acción actual restablecerá los datos de este autopig,\ntodos los datos seguirán estando disponibles.\nAunque los datos futuros podrían distorsionarse si el reinicio es incorrecto.\nSeguro que quieres hacer esto ??`,
    
    ResetLabel: 'RRestablecer peso promedio al ',
    fetchLabel: 'Obtener peso',
    
    ResetErrorTxt: "No se pudo restablecer",
    ResetSusTxt: "Restablecimiento completo"
  },

  DashBoard:{
    Title: 'Descripción de la información',
    Prop1: 'Nombre da la Granja',
    Prop2: 'Gerente',
    Prop3: 'Correo electrónico',
    
    CapacityTxt: 'Capacidad de la granja, actual: {{current}}, completo: {{full}}',
    PerformanceTxt: 'Rendimiento de la granja',

    DistriTitle: 'Distribución:',
    BatchesTitle: 'Lotes:',
    BatchLabel: '{{name}} Fecha de Inicio: {{Sdate}} Previsión final: {{Edate}},\n{{pigs}} número de cerdos, peso: {{weight}}',

    FarmInfo_btn: 'información de la explotación agrícola',
    UserInfo_btn: 'información del ususario',
    FarmSettings_btn: 'Configuración de la granja',
    ClearData: 'Borrar los datos almacenados'
  },

  DataEditor:{
    Title: 'Editar',
    Options1_1:"Corrales",
    Options2_1:"Añadir",
    Options2_2:"Suprimir",
    New_Sec:"Nueva Sección",
    Multiple:'Múltiple: ',

    Prop1:'Número más bajo',
    Prop2:'Número más alto',
    Prop3:'Pseudónimo',
    Prop4:'Publicar nombre ',
    Prop5:'Nombre de Sección',
    Prop6:'Nombre de Corral',
    ValDirection: 'El número más alto tiene que ser un valor más alto que el número más bajo',
    CannotNon: 'No se puede quitar un corral que no existe',
    Pen_Size: 'Tamaño del corral',
    GrowthCurve:'Curva de crecimiento'
  },

  FarmInfo:{
    Title: 'Información de la granja',
    Prop1:"Nombre da la Granja",
    Prop2:"Dirección ",
    Prop3:"País",
    Prop4:"Cvr",
    Prop5:"Gerente",
    Prop6:"Correo electrónico",
    Prop7:"Tamaño diseño del corral",
    Copy:"Copie Granja-id al portapapeles",
    Create:"Crear granja",
    Save:"Salvar información",
  },

  UserInfo:{
    Title: 'Informacion del usuario',
    Prop1:"Primer Nombre",
    Prop2:"Apellidos",
    Prop3:"Correo electrónico",
    Prop4:"Teléfono",
    Prop5:"Dirección",
    Prop6:"País",
    Copy:"Copie User-id al portapapeles",
    Save:"Salvar información",
  },

  FarmSettings:{
    Title: 'Configuración de la granja',
    Prop1:"Tamaño del diseño de la corral",
    PensSettingsTitle: "Configuración de la corral",
    PenPrefix: "Prefijo de corral:",
    Pendefault: "Corral_",
    TreatmentsTitle: "Tratos",
    TreatmentProp1:"Código",
    TreatmentProp2:"Nombre",
    TreatmentProp3:"Muerte",
    TreatmentProp4:"Usar",
    WorkerTitle: "Trabajadores / Lista de usuarios",
    WorkerProp1:'Codigo de usuario',
    WorkerProp2:'Correo electrónico',
    WorkerProp3:'Ajustes',
    UserAllowTitle:"UserAllowList",
    SilonKompTitle:"Silo´s and Komponent´s",
    NedapTitle:"Conexión Nedap",
    NedapAdd:"Agregar Instalación",
  },

  Subscription:{
    plan:"Plan de suscripción",
    Staff:'Personal de la Granja',
    StaffDesc:'Accesso para asignar como personal de la granja existente',
    Owner:'Propietario de la Finca',
    OwnerDesc:'Acceso para administrar su propia granja',
    Manager:'Gerente de la Granja',
    ManagerDesc:'Acceso al administrador de la propia granja y personal autorizado',
    Contact:'Contactar',
    ContactDesc:'Nos pondremos en contacto con usted para contarle mas sobre nuestras ofertas',

    Free:'Libre',
    PriceManager:'9999.99€',

    submit_btn: 'Entregar',

    //Errors
    NoType:'Elija un tipo de suscripción',
    NotAvaible: 'Esta opción aún no está disponible',
  },

  Pens:{
    Options1:'Diseño como: ',
    Options1_1:'Secciones',
    Options1_2:'Lote',
    ViewDate:'Ver fecha',

    Options2:'Pantalla:',
    Options2_1:'Pesos',
    Options2_7:'Crecimiento',
    Options2_2:'Recuento de Animales',
    Options2_3:'Días de edad',
    Options2_4:'Consumo de Agua',
    Options2_5:'Consumo de Alimento',
    Options2_6:'Actividad',
  },

  Pen_Display:{
    Title: 'Pantalla del Corral',
    Prop1: 'Recuento de cerdos',
    Prop2: 'Lote',
    Prop3: 'Días de edad',
    Prop3_2: "Corral fecha de inicio",
    Prop4: 'Peso Medio',
    Prop5: 'Crecimiento medio del cerdo por día',
    Prop6: 'Curva de Crecimiento',
    Prop7: 'Alarma mínimo -máximo',
    Prop7Data: '{{min}}% - {{max}}%',
    Size: 'Corral Tamaño',
    
    Prop8: 'Hora de la última alimentación',
    Prop8Time: '{{date, DD-MM-YYYY HH:mm:ss}}',
    Prop9: 'Porción en kg.',

    Updateview_btn:'Actualización',

    SiloLog_Title:'Título del registro del Silo',
    DailyFeedLog_Title:'Título de registro de Alimentación diario',
    ControlWeights_Title: 'Control de pesaje.',
    WeightTime: '{{date, DD-MM-YYYY HH:mm:ss}}',
    WeightTimeAction: 'Es actividad: {{date, DD-MM-YYYY}}',
    WeightData: '{{weight}} kg : {{count}} peso',

    OperationLog_Title: 'Registro de actividades',
    OperationTime: '{{date, DD-MM-YYYY HH:mm:ss}}',
    NoteData: 'Anotación: \n{{note}}',
    OperationNote: '{{note}}',
    OperationData: '{{count}} * {{action}} of {{weight}} kg \n{{note}}',
    OperationDataCode: '#{{code}} - {{codename}}\n{{count}} * {{action}} of {{weight}} kg\n{{note}}',
  },

  Autopigs:{
    Title:'Autopigs',
    New:'Nueva AutoPig',
    AutopigId:'Autopig Id',
    SelectPens:'Disponible - Bolígrafos',
    SelectSections:'Disponible - Secciones',

    CorTitle:'Balanzas de corredor',
    CorridorId:'Id. de escala de corredor (separados por comas)',
    ConIdError:'Consys no reconoce {{ConsysId}} como una identificación válida.',
    AutoPigName: 'Autopig Nombre',

    FeedTitle:'Conexiones de alimentación',
    NewFeedCon:'Nueva conexión de alimentación',
    FeedId:'ID de sistema de alimentación',

    DataTitle:'El intercambio de datos de la granja',
    NewDataCon:'Nuevo intercambio de datos',
    FarmId:'Identificación de la granja',
    From:'Datos de',
    To:'Datos a',

    Edit:'Editar configuración',
    Prop1:'Nombre de Autopig',
    Prop2:'Consys-Id',
    Prop3:'Escala',
    Prop4:'Operaciones',
    Prop5:'Temperatura',
    Prop6:'Agua',
    Prop7:'Alimentación',
    Prop8:'Tewe-Feed \n(Válvula Id)',

    NewConnection:'Nueva conexión',
    ConnectionName:'Nombre de la conexión',

    listcol1:'ID',
    listcol2:'Nombre',
    listcol3:'Type',
    listcol4:'Compartido/Local',
    listcol5:'Comportamiento'
  },

  Autopigs_Display:{
    Title: "Autopig Pantalla",
    DaysLabel:'Días seleccionados',
    NewestLabel: "Peso más nuevo",
  },
  
  Reports: {
    New:'Nuevo reporte',
    Edit:'Editar informes',

    Deletelabel: 'Eliminar informe',
    Combined:'Abierto en vista combinada',
    listcol1: 'Nombre',
    listcol2: 'Fecha de inicio',
    listcol3: 'Fecha final',
    listcol4: 'Archivada',
    listcol5: '- Comportamiento -',
  },

  Reports_Editor: {
    Title: 'Editora de informes',

    Copylabel: 'Copiar informe',
    ReportName: 'Informes nombre',
    GrowthCurve:'Curva de Crecimiento:',
    SelectPens:'Disponible - Bolígrafos',
    SelectAutos:'Disponible - Autopigs',
    SelectReps:'Disponible - Informes',
  },

  Report_Display: {
    Title: "Visualización de informes",

    Added: 'Cerdos añadidos',
    Deaths: 'Fallecidas',
    Sold: 'Cerdos vendidos',

    NoWeight: 'No se han encontrado datos de pesajes de Control.',
    NoFeeding: 'No se han encontrado datos de alimentación.',
    Total: 'Total',

    StartD:'Fecha de incio:',
  },

  Graphs:{
    New:'Nueva Curva',

    CalcLabel: 'Calcular en base a:',
    Name:'Nombre de la Curva',
    Day:'Día :',
    Weight:'Peso:',
    Growth: 'Crecimiento',
    NewPoint:'Nuevo punto',
    EValue:'Error de Valor',
    NameError: 'La nueva curva necesita un nombre.',
    IncrementError: 'La plantilla de la curva debe ser incremental: Error en el día: {{I1}} - {{I2}}'
  },

  Operations:{
    Weight: 'Pesaje Manual',
    AddPen: 'Añadir corral',
    RemovePen: 'Quitar corral',
    EditPens: 'Añadir o quitar corral',
 
    Section:'Sección:',
    Pen:'Corral:',
    Data:'Datos de corral',
    Pigs:'Cerdas:',

    Note: 'Nota',
    AddPig: 'Añadir cerdo',
    MovePig: 'Mover cerdo',
    Treatment: 'Tratamiento',
    SoldPig: 'Cerdo vendido',
    ClosePen: 'Cerrar corral',

    NoteTitle: 'Nota en corral:',
    OperationTitle: 'Comentarios de operaciones:',
    Quantity:"Cantidad",
    AvgWeight:"Peso Medio",
    CorridorBtn:"Escala de corredor",
    CorridorRes:"Resultado del corredor",
    Internal:'Nota interna',
    PeriodeTitle: 'Ver Período:',
    day:'día',

    ModalConf:'Por favor, confirme la acción',
    CloseDate:'Fecha de cierre',
    ClearWarn:'La acción actual borrará los datos de esta pluma a históricos,\ntodos los datos seguirán estando disponibles en la pantalla Informe.\nSeguro que quieres hacer esto ??',
    RepCreate: `Crear informe desde la pluma`
  },

  CommentModule:{
    NotePlaceholder: 'Contexto de la nota..',
  },

  GraphViewer:{
    X_DateFormat_Time: '{{date, DD-MM-YYYY HH:mm}}',
    X_DateFormat: '{{date, DD-MM-YYYY}}',

    CountFormat:'Cerdo contar',
    WeightFormat:'Peso Kg.',
    CountToWeight:'Contar: {{count}}\nPeso: {{weight}}kg',

    InfoTitle:'Información de CurveView Detalles',
    InfoName:'Nombre de datos',
    InfoValue:'Valor: eje y',
    InfoAdd:'Extra',

    Weight:'Peso',
    ByTag:'PorEtiqueta',
    Growth:'Growth',
    Feed:'Alimento',
    Operation:'Operación',
    Water:'Agua',
    Temp:'Temperatura'
  },

  Weighings:{
    Title: 'Peso',
    EditWeights: 'Editar pesos:',
    DeleteWeights: 'Eliminar pesos:',
    WeightData: "Datos de peso:",
    Quantity: "Cantidad de animales",
    Weight:"Peso total",
    NoReply:'Consys no repondió a su peso',
    CorridorScale: "Balanzas de corredor",
    Fetch: "Obtener peso",
  },

  FeedList:{
    Col1:'Válvula nr.',
    Col2:'Silo',
    Col3:'Monto Kg',
    Col4:'Tiempo',
  },

  OperationList:{
    Col1:'Tiempo',
    Col2:'Corral nr.',
    Col3:'Operación principal',
    Col4:'Subactividad',
    Col5:'Datos',
    Action1:'Editar'
  },

  WeightList:{
    Col1_1:'Corral',
    Col1_2:'Días totales',
    Col1_3:'Peso de entrada',
    Col1_4:'Peso de salida',
    Col1_5:'Día de crecimiento',

    Col2_1:'Fecha',
    Col2_2:'Conteo de cerdos',
    Col2_3:'Peso Kg',
    Col2_4:'Kg pr. cerdo',
    Col2_5:'Crecimiento pr. cerdo, pr. peso',
    Col2_6:'Crecimiento medio pr. cerdo, un día',
  },

  DataTypes: {
    Degrees:"°C",
    W:"Kg.",
    W_T:"Kilo",
    W_small:"g.",
    W_small_T:"gram",
    F:"L.",
    F_T:"Liter",
    F_small:"ml.",
    F_small_T:'Milliliter',
  },

  ActionButtons:{
    Add:'Añadir',
    Remove:'Remover',
    Back:'Atrás',
    Save:'Salvar',
    SaveAll:'Salvar a todos',
    Done:'hecho',
    Cancel:'Cancelar',
    Logout: 'Cerrar sesión',
    Submit_btn:"Entregar",
    OK: 'OK'
  },
  
  Errors: {
    PropMissing:'{{Prop}} es necesario',
    PropInvalid:'{{Prop}} tiene datos invalidos',

    PropExists:'{{Prop}} allready exists',
    PropNon:'{{Prop}} does not exist',

    PropNan:'{{Prop}} cannot be or start with a number',
    PropNum:'{{Prop}} have to be a valid number',

    PassMissing:'Proporcione una  contraseña',
    EmailMissing:'Proporcione un correo electrónico',
  },

  Development: {
    NotDone:'Elemento no realizado',
  },
}
