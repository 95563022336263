import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  Text,
} from 'react-native'
import { Button, Labelled_Input, LoadingSplash } from '../../Components'
import { Select, TYPE } from "baseui/select"
import { Checkbox, LABEL_PLACEMENT} from "baseui/checkbox"
import {Input} from 'baseui/input'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, isApp } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded } from 'react-redux-firebase'
import { FarmData, isEmpty } from '../../Config/Types'
//import { Operations, FarmData } from '../../Config/Types'

enum DataTypes {
  PENS = "Pens"
}
enum ActionsTypes {
  ADD = "Add",
  REMOVE = "Remove"
}
interface PenType {
  penId: number,
}

interface Section_Picker_Props {
  Action: ActionsTypes,
  Selected: string,
  onSelected: (value:string, isNew:boolean) => void,
  isDisabled?: boolean
}
const Section_Picker = ({Action, Selected, onSelected, isDisabled=false}:Section_Picker_Props) => {
  const { t } = useTranslation()
  const { Layout, Gutters } = useTheme()

  const sections = useAppSelector(({firebase: { data }}) => data.sections?Object.keys(data.sections).filter((Id) => Id !== 'update_time'):[])
  const SectionsList = useMemo(() => {
    return (Action === ActionsTypes.ADD?sections.concat(["New Section"]):sections).map((section, i) => ({label: section, id: section, index:i}))
  }, [Action, sections])

  const [InputValue, setInputValue] = useState('')
  const [SelectValue, setSelectValue] = useState(SectionsList&&SectionsList[0]?[SectionsList[0]]:[])

  useEffect(() => {
    var value = sections&&sections.length?(Selected&&sections.includes(Selected)?Selected:sections[0]):null
    if (value) {
      setSelectValue([{label:value, id:value}])
      onSelected(value, false)
    }
  }, [])

  return(
    <View style={[Layout.fill, Layout.row, Gutters.tinyMargin, {maxWidth:'max-content', minWidth:'min-content', maxHeight:48}]}>
      <Select clearable={false}
        options={SectionsList}
        value={SelectValue}
        type={TYPE.select}
        placeholder="Section"
        onChange={(params:any) => {
          setSelectValue(params.value)
          onSelected(params.value[0].label, false)
        }}
        maxDropdownHeight="300px"
        overrides={{
          Root: { style: ({ $theme }) => ({
            maxWidth:'180px', minWidth:'120px', maxHeight:'48px',
            outline:'black 1px solid'
          })}
        }}
      />
      {Action === ActionsTypes.ADD && SelectValue && SelectValue[0] && SelectValue[0].id === "New Section"?(
        <Input
          placeholder={t('DataEditor.Prop5')}
          onChange={e => {
            var Value = e.currentTarget.value.replaceAll('/','')
            setInputValue(Value)
            onSelected(Value, true)
          }}
          value={InputValue}
          overrides={{
            Root: { style: ({ $theme }) => ({
              outline: `1px black solid`,
              paddingRight:0,
              maxWidth: '220px', minWidth:'120px'
            }) },
            InputContainer: { style: ({ $theme }) => ({
              backgroundColor: "#FFF"
            }) }
          }}
          error={sections.includes(InputValue)}
        />
      ):(<></>)}
    </View>
  )
}

interface Pen_Picker_Props {
  Action: ActionsTypes,
  Selected_Section: string,
  Selected: PenType[],
  Multiple: boolean
  onSelected: (value: PenType[], isNew:boolean) => void,
  isDisabled?: boolean
}
const Pen_Picker = ({Action, Selected_Section, Selected, Multiple, onSelected, isDisabled=false}:Pen_Picker_Props) => {
  const { t } = useTranslation()
  const { Layout, Gutters } = useTheme()

  const AllPens = useAppSelector(({firebase: { data }}) => data.pens)
  const sections = useAppSelector(({firebase: { data }}) => data.sections)
  const [pens, setPens] = useState<number[]|null>(null)
  
  const [InputValue, setInputValue] = useState<number|null>(null)
  const [InputValue2, setInputValue2] = useState<number|null>(null)
  const [SelectValue, setSelectValue] = useState<any[]>([])

  useEffect(() =>{
    var section = Selected_Section&&sections&&sections[Selected_Section]?sections[Selected_Section]:null
    var pens = section&&section.pens?Object.values(section.pens):null
    setPens(pens)

    var value = pens?(Selected&&Selected[0]&&pens.includes(Selected[0].penId)?Selected[0].penId:pens[pens.length-1]):null
    var value2 = pens?(Selected&&Selected[1]&&pens.includes(Selected[1].penId)?Selected[1].penId:pens[pens.length-1]):null

    onSelected([{penId:value}, {penId:value2}]as PenType[], Action === ActionsTypes.ADD)
    setSelectValue([{label:value, id:value}])
    setInputValue(value)
    setInputValue2(value2)
  }, [Selected_Section, sections, Action])

  return(
    <View style={[Layout.fill, Layout.row, Gutters.tinyMargin, {maxWidth:'max-content', minWidth:'min-content'}]}>
      {Action === ActionsTypes.REMOVE?(
        <Select clearable={false}
          options={pens?pens.map((Pen, i) => ({label: Pen, id: Pen, index:i}) ):[{}]}
          value={SelectValue}
          type={TYPE.select}
          placeholder="Pen"
          onChange={(params:any) => {
            setSelectValue(params.value)
            onSelected([{penId:parseInt(params.value[0].label)}], false)
          }}
          maxDropdownHeight="300px"
          overrides={{
            Root: { style: ({ $theme }) => ({
              maxWidth:'180px', minWidth:'120px', maxHeight:'48px',
              outline:'black 1px solid'
            })}
          }}
        />
      ):(
        <View style={[Layout.fill, Layout.row, Gutters.tinyMargin, {maxWidth:'max-content', minWidth:'min-content'}]}>
          {Multiple?(
            <View style={[Layout.fill, Layout.row, Gutters.tinyMargin, {maxWidth:'max-content', minWidth:'min-content'}]}>
              <Labelled_Input disabled={isDisabled}
                value={InputValue+''}
                type='number'
                label={t('DataEditor.Prop1')}
                placeholder={''}
                onChange={(text, num) => {
                  setInputValue(num)
                  onSelected([{penId:num},{penId:InputValue2}]as PenType[], true)
                }}
                error={AllPens&&Object.keys(AllPens).some(e => e === ''+InputValue)}
              />
              <Labelled_Input disabled={isDisabled}
                value={InputValue2+''}
                type='number'
                label={t('DataEditor.Prop2')}
                placeholder={''}
                onChange={(text, num) => {
                  setInputValue2(num)
                  onSelected([{penId:InputValue},{penId:num}]as PenType[], true)
                }}
                error={AllPens&&Object.keys(AllPens).some(e => e === ''+InputValue2)}
              />
            </View>
          ):(
            <View style={[Layout.fill, Layout.row, Gutters.tinyMargin, {maxWidth:'max-content', minWidth:'min-content'}]}>
              <Labelled_Input disabled={isDisabled}
                value={InputValue+''}
                type='number'
                label={t('DataEditor.Prop6')}
                placeholder={''}
                onChange={(text, num) => {
                  setInputValue(num)
                  onSelected([{penId:num}]as PenType[], true)
                }}
                error={AllPens&&Object.keys(AllPens).some(e => e === ''+InputValue)}
              />
            </View>
          )}
        </View>
      )}
    </View>
  )
}

const DataEditor = (MainProps:any) => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout, Fonts, Colors } = useTheme()
  
  const MainParams = MainProps.route.params?MainProps.route.params:undefined
  //const dispatch = useAppDispatch()
  const firebase = useFirebase()

  //const profile = useAppSelector(({firebase}) => firebase.profile)
  const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const Graphs = useAppSelector(({firebase: {data}}) => Object.assign({}, data.agri_graphs, data.local_graphs))
  const sections = useAppSelector(({firebase: { data }}) => data.sections)
  const AllPens = useAppSelector(({firebase: { data }}) => data.pens)
  //const activeSection = useAppSelector(state => state.activeData.section)
  //const activePen = useAppSelector(state => state.activeData.pen)

  //Pens, 
  const [SelectedDataType, setSelectedDataType] = useState(MainParams&&MainParams.DataType?MainParams.DataType:[{id:DataTypes.PENS}])
  //Add, Remove
  const [SelectedActionsType, setSelectedActionsType] = useState(MainParams&&MainParams.Action?MainParams.Action:[{id:ActionsTypes.ADD}])

  const [SelectedSection, setSelectedSection] = useState(MainParams&&MainParams.SelectedSection?MainParams.SelectedSection:"")
  const [SelectedPen, setSelectedPen] = useState<PenType[]>([{penId: MainParams&&MainParams.SelectedPen?MainParams.SelectedPen:""}])
  const [isMultiPen, setisMultiPen] = useState(MainParams&&MainParams.isMultiPen?MainParams.isMultiPen:false)
  const [Size, setSize] = useState<number|1>(1)
  const [SelectedCurve, setSelectedCurve] = useState([{label:'None', id:'-1'}])

  const [ErrOrConf, setErrOrConf] = useState<{text:string,error:boolean}|undefined>(undefined)

  const MaxPenLimit = 10
  const isLimited = useMemo(() => {
    if (SelectedActionsType[0].id === ActionsTypes.ADD) {
      const surPlus = (farmData&&farmData.penSurplus?farmData.penSurplus:0)
      if (isEmpty(AllPens) || Object.keys(AllPens).length < (MaxPenLimit + surPlus)) {
        return false
      }
      else return true
    }
    else return false
  }, [SelectedActionsType, AllPens, farmData])

  const submit_Action = () => {
    var section = SelectedSection&&(sections && Object.keys(sections).includes(SelectedSection))?sections[SelectedSection]:{pens:[]}
    var Original = [...section.pens]
    var pens = section.pens
    switch(SelectedActionsType[0].id) {
      case ActionsTypes.ADD:
        if (!isLimited) {
          if (SelectedPen && SelectedPen[0] && SelectedPen[0].penId) {
            const surPlus = (farmData&&farmData.penSurplus?farmData.penSurplus:0)
            for (let S_Pen = SelectedPen[0].penId; S_Pen <= ((SelectedPen[1] && SelectedPen[1].penId)?SelectedPen[1].penId:SelectedPen[0].penId); S_Pen++) {
              if (!(S_Pen && AllPens&&Object.keys(AllPens).some(e => e === ''+S_Pen))) {
                if ((MaxPenLimit + surPlus) > (AllPens?Object.keys(AllPens).length:0)+pens.length) {
                  pens.push(S_Pen)
                }
                else break;
              }
            }
            firebase.update(`farms_data/${activefarm}/sections/${SelectedSection}`, section, () => {
              var _Pens = pens.filter(x => !Original.includes(x))
              _Pens.forEach(element => {
                var pen = {datas: {count:0, pensize:Size, curve:SelectedCurve[0].label!=='None'?SelectedCurve[0].label:null}, section:SelectedSection}
                firebase.update(`farms_data/${activefarm}/pens/${element}`, pen, () => {
                  setSelectedPen([])
                  setErrOrConf({text:"Pens commited.",error:false})
                })
              })
            })
          }
        }
        break
      case ActionsTypes.REMOVE:
        if (SelectedSection && Object.keys(sections).includes(SelectedSection)) {
          if (SelectedPen && SelectedPen[0] && SelectedPen[0].penId && sections[SelectedSection].pens) {
            var i = sections[SelectedSection].pens.indexOf(SelectedPen[0].penId)
            if (i >= 0) {
              pens.splice(i, 1)
              firebase.update(`farms_data/${activefarm}/sections/${SelectedSection}`, section, () => {
                firebase.remove(`farms_data/${activefarm}/pens/${SelectedPen[0].penId}` ,() => {
                  setErrOrConf({text:"Pens removed.",error:false})
                })
              })
            }
          } else {
            //error to Pen_picker
          }
        }
        else {
          //error to Section_picker
        }
        break
      default:
        break
    }
  }

  return (
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <View style={[Layout.midWidth, Layout.row, Gutters.smallV_Margin, {justifyContent:'space-between', maxWidth:425}]}>
        <Select clearable={false}
          options={Object.values(DataTypes).map((DataType, i)=> ({label: t('DataEditor.Options1_' + (i+1)), id: DataType, index:i})) }
          value={SelectedDataType}
          type={TYPE.select}
          placeholder="Type"
          onChange={(params:any) => {
            if (ErrOrConf) setErrOrConf(undefined)
            setSelectedDataType(params.value)
          }}
          maxDropdownHeight="300px"
          overrides={{
            Root: { style: ({ $theme }) => ({
              maxHeight:'48px', maxWidth:'606px', outline:'black 1px solid',
            })}
          }}
        />
        <View style={{width:5}}/>
        <Select clearable={false}
          options={Object.values(ActionsTypes).map((Action, i)=> ({label: t('DataEditor.Options2_' + (i+1)), id: Action, index:i})) }
          value={SelectedActionsType}
          type={TYPE.select}
          placeholder="Action"
          onChange={(params:any) => {
            if (ErrOrConf) setErrOrConf(undefined)
            setSelectedActionsType(params.value)
          }}
          maxDropdownHeight="300px"
          overrides={{
            Root: { style: ({ $theme }) => ({
              maxHeight:'48px', maxWidth:'606px', outline:'black 1px solid'
            })}
          }}
        />
      </View>
      <View style={[Common.border, {padding:5, width:'-webkit-fill-available', maxWidth:660}]}>
        {ErrOrConf !== undefined?(
          <View style={[Layout.fill, Gutters.tinyPadding, {paddingBottom:2, justifyContent:'flex-end', maxHeight:'22px'}]}>
            <Text style={[{color:ErrOrConf.error?Colors.error:Colors.success}]}>{ErrOrConf.text}</Text>
          </View>
        ):(<></>)}
        <View style={[Layout.fill, Layout.row, {justifyContent:'space-between', marginBottom:4, flexWrap:'wrap'}]}>
          <View style={[Layout.fill, Layout.column, {minWidth:'fit-content', maxWidth:'fit-content'}]}>
            <Section_Picker isDisabled={isLimited}
              Action={SelectedActionsType[0].id} Selected={SelectedSection} onSelected={(value, isNew) => {
              setSelectedSection(value)
              if (ErrOrConf) setErrOrConf(undefined)
            }}/>
          </View>
          <View style={[Layout.fill, Layout.column, {maxWidth:'max-content', minWidth:'min-content', width:'-webkit-fill-available', alignItems:'flex-end', justifyContent:'center'}]}>
            {SelectedActionsType[0].id === ActionsTypes.ADD && (
              <Checkbox disabled={isLimited}
                labelPlacement={LABEL_PLACEMENT.left}
                overrides={{
                  Root: { style: ({ $theme }) => ({
                    alignItems: "center",
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })},
                  Label: { style: ({ $theme }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
                checked={isLimited?false:isMultiPen}
                onChange={(e) => {
                  setisMultiPen(old => !old)
                  if (ErrOrConf) setErrOrConf(undefined)
                }}
              ><Text style={[{}]}>{t('DataEditor.Multiple')}</Text></Checkbox>
            )}
          </View>
        </View>
        <Pen_Picker isDisabled={isLimited}
          Action={SelectedActionsType[0].id} Multiple={isMultiPen} Selected={SelectedPen} Selected_Section={SelectedSection} onSelected={(value, isNew) => {
          setSelectedPen(value)
          if (ErrOrConf) setErrOrConf(undefined)
        }}/>
        {SelectedActionsType[0].id === ActionsTypes.ADD && (
          <View style={[Layout.fill, Layout.row, {justifyContent:'space-between', marginBottom:4, flexWrap:'wrap'}]}>
            <Labelled_Input disabled={isLimited}
              maxWidth={200}
              value={Size + ''}
              type='number'
              label={t('DataEditor.Pen_Size')}
              placeholder={''}
              onChange={(text, num) => {
                setSize(num)
              }}
              error={Size<=0}
            />
            <View style={[Layout.column, Gutters.tinyV_Margin, {maxWidth:'fit-content', minWidth:'200px', alignItems:'flex-end'}]}>
              <Text style={[Fonts.textSmall]}>{t('DataEditor.GrowthCurve')}</Text>
              <Select clearable={false} disabled={isLimited}
                options={Graphs?['None'].concat(Object.keys(Graphs).filter(e => e !== 'update_time')).map((graph, i) => ({label: graph, id: graph, index:i})):[{label:'None', id:'-1'}]}
                value={SelectedCurve}
                type={TYPE.select}
                placeholder="Growth Curve"
                onChange={(params:any) => {
                  setSelectedCurve(params.value)
                }} 
                maxDropdownHeight="150px"
                overrides={{
                  Root: { style: ({ $theme }) => ({
                    maxWidth:'200px', minWidth:'100px', maxHeight:'48px',
                    outline:'black 1px solid'
                  })}
                }}
              />
            </View>
          </View>
        )}
      </View>
      <Button Contexts={[t('ActionButtons.Submit_btn'),t('ActionButtons.Cancel')]} Frame Action={(e)=> {e?navigate('User', {screen:'Home'}):submit_Action()}}/>
    </View>
  )
}

export default DataEditor