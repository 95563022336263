import React, {useMemo, useState} from 'react'
import { View, Text, Image } from 'react-native'
import { useTheme } from '../../Theme'
import i18n from 'i18next'
import { Combobox, SIZE } from "baseui/combobox"

import {
  AspectRatioBox,
  AspectRatioBoxBody,
} from 'baseui/aspect-ratio-box'

const LanguageNode = ({isSelected, option}) => {
  const { Layout, Images } = useTheme()
  return (
    <View style={[Layout.fill, Layout.row, {alignItems:'flex-start', height:36, justifyContent:'center'}]}>
      <Text style={{textAlign:'center', alignSelf:'center', marginRight:5}}>
        {option.label}
      </Text>
      <View style={[Layout.fill, {width:34,height:30, marginTop:5, alignItems:'flex-end'}]}>
        <AspectRatioBox aspectRatio={225 / 225} width={'30px'}>
          <AspectRatioBoxBody
            as="img"
            src={option.imgPath+'?width=30'}
          />
        </AspectRatioBox>
      </View>
    </View>
  )
}

const LanguageSelector = ({ }) => {
  const { Layout, Images } = useTheme()
  var ImageModul = useMemo(() => {
    switch(i18n.language) {
      case "dk":
        return Images.dk
      case "de":
        return Images.de
      case "es":
        return Images.es
      case "tw":
        return Images.tw
      case "zh":
        return Images.zh
      default:
        return Images.en
    }
  }, [i18n.language])

  var language = [{label:'English', id:'en', imgPath:Images.en}, {label:'Dansk', id:'dk', imgPath:Images.dk}, {label:'Español', id:'es', imgPath:Images.es}, 
                  {label:'Deutsch', id:'de', imgPath:Images.de}, {label:'繁體中文', id:'tw', imgPath:Images.tw}] //,{label:'Chinese', id:'zh'}]
  const [val, setVal] = useState(language.find(e => e.id === i18n.language)?.label||'English')
  return (
    <View style={[Layout.fullWidth, {minHeight:'max-content', alignSelf:'flex-end'}]}>
      <View style={[{justifyContent:'center', alignItems:'flex-end', minHeight:30}]}>
        <Combobox
          size={SIZE.mini}
          value={val}
          onChange={(nextValue, ele:any) => {
            setVal(nextValue)
            i18n.changeLanguage(ele.id)
          }}
          options={language}
          mapOptionToString={option => option.label}
          mapOptionToNode={LanguageNode}
          overrides={{
            Root: { style: ({ $theme }) => ({
              height: '30px', width: '110px',
              top:8, right:55, 
              position:'absolute'
            })},
            ListBox: {style: ({ $theme }) => ({
              overflow: "hidden"
            })},
          }}
        />
        <View style={[Layout.fill, {width:34,height:30, marginTop:5}]}>
          <AspectRatioBox aspectRatio={225 / 225} width={'30px'}>
            <AspectRatioBoxBody
              as="img"
              src={ImageModul+'?width=30'}
            />
          </AspectRatioBox>
        </View>
      </View>
    </View>
  )
}

LanguageSelector.propTypes = {
}

LanguageSelector.defaultProps = {
}

export default LanguageSelector
