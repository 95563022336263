import React, { useEffect, useState, useMemo, useRef } from 'react'
import {
  View, Text
} from 'react-native'
import { Button, LoadingSplash, CurveViewer, ContentProp, Brand, WeighingsList, OperationsList, FeedSilosList } from '../../Components'
//import OperationsView, { ModuleForms } from './Operations/'

import { useTheme, PrintContainer } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, useisAppView, navigateBack } from '../../Navigators/Root'
import { useIsFocused } from '@react-navigation/native'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase } from 'react-redux-firebase'
import { setStates } from '../../Store/StateHandlers/Active-slice'

import { GenerateCSV_Data, getUniqueColor} from '../../utils/formaters'
import { UnixDayTime, CommentData, isEmpty, isDemoFarm, ConsysRefs, SystemData, Periodes, TimeSpan, DataConsData, SectionData, GraphData, Operations as OTypes } from '../../Config/Types'
import { DataSet, PointSet, DataTemplate, CurveViewerPropHandle, ViewForms } from '../../Components/GeneralUse/CurveViewer'

import { AccumulateTime, WeightsCalc } from '../../utils/DataCalculator'
import { FirebaseCaller, Pens as FBPens, Weighings, Comments, Graphs, DataCons } from '../../Services/Firebase'
import { ConsysApi } from '../../Services/'
import { DataCaller, FeedData, ScaleData, WaterData, TempData } from '../../utils/DataCallers'

//import { useStyletron } from 'baseui'
//import useDetectPrint from 'react-detect-print'
import { ScaleList } from '../../Services/Consys/Scale-Slice'
import { WaterInfo, WaterList } from '../../Services/Consys/Water-Slice'
import { TemperatureList } from '../../Services/Consys/Temperature-Slice'
import OperationsView, { OperationsPropHandle } from '../../Components/GeneralUse/Operations'
import { FeedInfo } from '../../Services/Consys/Feed-Slice'
//import get from 'lodash/get'

const CalculateGrowthSet = (DataSet:DataTemplate) => {
  var TempVal:PointSet|undefined = undefined
  var Growths = DataSet.sort((a,b) => a.x === b.x?0:(a.x > b.x? 1 : -1)).reduce((res, cur:PointSet, index) => {
    if (TempVal) {
      var value = (cur.y - TempVal.y) *1000
      //if (value < 0) value = 0
      var days = (cur.x - TempVal.x) / 86400000
      var resVal = value / days
      if (!isNaN(resVal) && isFinite(resVal)) res.push({...cur, y:resVal})
    }
    TempVal = cur
    return res
  },[] as DataTemplate)
  return Growths
}

const Pen_Display = (MainProps:any) => {
  const { t } = useTranslation()
  //const [css] = useStyletron()
  const { Common, Gutters, Layout, Images, Colors } = useTheme()
  //const isPrinting  = useDetectPrint()
  const isAppView = useisAppView()
  
  //const navigation = MainProps.navigation
  //const MainParams = MainProps.route.params?MainProps.route.params:undefined
  const dispatch = useAppDispatch()
  const firebase = useFirebase()

  const activefarm = useAppSelector(state => state.activeData.farm)
  const Settings = useAppSelector<SystemData|undefined>(({firebase: {data}}) => data&&data.system?data.system:undefined)

  const activeData = useAppSelector(({activeData}) => {
    var Shared = MainProps.route.params&&MainProps.route.params.IsDataLink?MainProps.route.params.IsDataLink:undefined
    return {
      activefarm:activeData.farm, isShared:Shared, 
      farm:Shared?Shared:activeData.farm, 
      section:activeData.section, pen:activeData.pen, 
    }
  })
  //const activePen = useAppSelector<number>(state => state.activeData.pen?state.activeData.pen:-1)
  //const activeSection = useAppSelector<string|undefined>(state => state.activeData.section?state.activeData.section:undefined)
  
  const LinkList_Sections = useAppSelector(({firebase: {data}}) => activeData.isShared&&data.LinkList&&data.LinkList[activeData.isShared]&&data.LinkList[activeData.isShared].sections?data.LinkList[activeData.isShared].sections:null)
  const {sectionData, allSecData} = useAppSelector(({firebase: {data}}) => {
    var SharedData = activeData.isShared&&data.LinkListData&&data.LinkListData[activeData.isShared]&&data.LinkListData[activeData.isShared].sections?data.LinkListData[activeData.isShared].sections:null
    if (SharedData) return {sectionData:SharedData[activeData.section], allSecData:SharedData}
    return {sectionData:activeData.section&&data.sections&&data.sections[activeData.section]?data.sections[activeData.section]:undefined, allSecData:data.sections}
  })
  //const LinkListData_Sections = useAppSelector(({firebase: {data}}) => activeData.isShared&&data.LinkListData&&data.LinkListData[activeData.isShared]&&data.LinkListData[activeData.isShared].sections?data.LinkListData[activeData.isShared].sections:null)
  /*const {LinkListData_Sections, LinkList_Sections} = useAppSelector(({firebase: {data}}) => {
    var LinkListData_Sections:Record<string, SectionData>|null = null
    var LinkList_Sections:Record<string, boolean|{starttime: number;endtime: number;}>|undefined = undefined
    if (activeData.isShared) {
      if (data.LinkListData && data.LinkListData[activeData.isShared] && data.LinkListData[activeData.isShared].sections) {
        LinkListData_Sections = data.LinkListData[activeData.isShared].sections
      }
      if (data.LinkList && data.LinkList[activeData.isShared] && data.LinkList[activeData.isShared].sections) {
        LinkList_Sections = data.LinkList[activeData.isShared].sections
      }
    }
    return {LinkListData_Sections, LinkList_Sections}
  })*/
 
  const [ViewPeriod, updateViewPeriod] = useState<TimeSpan>({
    start:new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() -6),
    end:new Date(new Date().setHours(23,59,59,59)).setDate(new Date().getDate())
  })

  const { penData, PenTimes:{Periodes, startdate, age}, PenAutopigs, PenGraph, weighings, Operations, DataGraphs, LinkList_Section, DataConsData } = useAppSelector(state => {
    var penData = FBPens.GetPenData(state, activeData.pen, activeData.isShared)
    var Periodes = FBPens.GetPenPeriodes(state, activeData.isShared, activeData.pen)
    var weighings = Weighings.GetPenWeights(state, activeData.isShared, activeData.pen, false)
    var Operations = Comments.GetPenComments(state, activeData.isShared, activeData.pen, false)
    
    var startdate:number|undefined = undefined
    var age = 0

    var PenAutopigs:string|undefined = undefined
    var PenGraph:string|undefined = undefined

    var DataGraphs:GraphData | Record<string, GraphData> | undefined = Graphs.GetPenWeightGraph(state, activeData.isShared, undefined)
    var LinkList_Section:boolean | {starttime:number; endtime:number;} | undefined = undefined
    var DataConsData:Record<number, DataConsData> | undefined = undefined

    if (penData) {
      if (penData.datas) {
        if (penData.datas.autopigs) PenAutopigs = penData.datas.autopigs
        if (penData.datas.curve) PenGraph = penData.datas.curve

        if (penData.datas.activedate) {
          startdate = penData.datas.activedate
          if (isDemoFarm(activefarm)) startdate = Date.now() - (startdate||0 * UnixDayTime)
        }
      }
      /*if (penData.section) {
        if (activeData.isShared && state.firebase.data.LinkList) {
          if (state.firebase.data.LinkList[activeData.isShared]&&state.firebase.data.LinkList[activeData.isShared].sections) {
            if (state.firebase.data.LinkList[activeData.isShared].sections[penData.section]) {
              LinkList_Section = state.firebase.data.LinkList[activeData.isShared].sections[penData.section]
            }
          }
        }
      }*/
    }
    DataConsData = DataCons.GetPenDataCon(state, activeData.isShared, activeData.pen+'', true)
    if (isDemoFarm(activefarm) && Periodes && Periodes['0']) {
      startdate = (Periodes['0'] as Periodes).starttime
    }
    if (startdate) {
      //if (ViewPeriod.start !== startdate) updateViewPeriod({start:startdate, end:new Date(new Date().setHours(23,59,59,59)).setDate(new Date().getDate())})
      age = Math.floor(((Date.now()-startdate)/UnixDayTime))
    }
    
    return {penData, PenTimes:{Periodes, startdate, age}, PenAutopigs, PenGraph, weighings, Operations, DataGraphs, LinkList_Section, DataConsData}
  })

  useEffect(() => {
    if (startdate) {
      if (ViewPeriod.start !== startdate) updateViewPeriod({start:startdate, end:new Date(new Date().setHours(23,59,59,59)).setDate(new Date().getDate())})
    }
  }, [startdate])

  const {Weighings_Notes, PenActivities:{Operation_Notes, Deaths, Added, Sold}} = useAppSelector(state => {
    var Weighings_Notes = Weighings.GetFormatWeighings(state, activeData.pen, activeData.isShared, false, ViewPeriod).Weighings_Notes
    var {Operation_Notes, Deaths, Added, Sold} = Comments.GetFormatOperations(state, activeData.pen, activeData.isShared, false, ViewPeriod)
    return {Weighings_Notes, PenActivities:{Operation_Notes, Deaths, Added, Sold}}
  })

  const {data: ConSystemList, error: ConSystemListError, isUninitialized: NoConSystemList} = ConsysApi.System.GetSystemList({FarmKey:activefarm, FarmKeys:activeData.isShared?[activeData.isShared]:undefined, getInfos:true, limitIds:DataConsData?Object.keys(DataConsData):undefined}, {skip:(!PenAutopigs||PenAutopigs===""), pollingInterval:14000})
  useEffect(() => {
    if (ConSystemListError) { console.log(ConSystemListError) }
    else if (ConSystemList) {
      console.log(ConSystemList)
    }
  },[ConSystemListError, ConSystemList])

  // Get batch & system Data
  // Get pen - weighings & comments
  /*useEffect(() => {
    const focusHandler = navigation.addListener('focus', () => {
        console.log('Refreshed');
    })
    return focusHandler
  }, [navigation])*/
  const focus = useIsFocused()
  useEffect(() => {
    console.log("focus changed")
  }, [focus])

  FirebaseCaller(
    FBPens.fetchPenPeriodes(activefarm, false, [], activeData&&activeData.pen?activeData.pen:[], activeData.isShared).concat(
    Weighings.fetchWeighings(activefarm, false, [], activeData.isShared, undefined, false, activeData.isShared?allSecData:undefined, LinkList_Sections).concat(
    Comments.fetchComments(activefarm, false, [], activeData.isShared, undefined, false, activeData.isShared?allSecData:undefined, LinkList_Sections).concat(
    Graphs.fetchWeightGraphs(activefarm, false, [], activeData.isShared)
    //.concat(DataCons.fetchDataCons(activefarm, false, [], activeData.isShared))
    )))
  ,[penData, focus])
  
  /*const FetchLimiter = useMemo<TimeSpan>(() => {
    var res:TimeSpan = {start: ViewPeriod.start, end: ViewPeriod.end?ViewPeriod.end:new Date().getTime()}
    if (LinkList_Section && typeof LinkList_Section !== "boolean"){
      if (LinkList_Section.starttime && LinkList_Section.starttime > res.start) res.start = LinkList_Section.starttime
      if (LinkList_Section.endtime && LinkList_Section.endtime < res.start) res.end = LinkList_Section.endtime
    }
    return res
  }, [LinkList_Section, ViewPeriod])*/

  //#region Consys Data Handlers
  let ConFeedList:any[]|undefined = undefined
  let FeedsLists:Record<string, FeedInfo[]> = {}
  let ConFeedFetching:boolean|undefined = undefined
  let ConScaleList:any[]|undefined = undefined
  let ConTempList:TemperatureList[]|undefined = undefined
  let ConWaterList:WaterList[]|undefined = undefined

  if (DataConsData) {
    var start = ViewPeriod.start
    if (startdate && startdate>start) start = startdate
    
    if (Object.values(DataConsData).some(e => e.feed)) {

      var Keys = Object.entries(DataConsData).reduce((res, [key, val]) => {
        if (val.feed && (val.feed === 'All' || val.feed.split(',').includes(activeData.pen+''))) res.push(key+'')
        return res
      }, [] as string[])

      var datas = FeedData(false, activeData.isShared, Keys, {...ViewPeriod, start:start}, activeData.pen, undefined)
      ConFeedList = datas.AutoData
      FeedsLists = datas.DataList
      ConFeedFetching = datas.isFetching
    }
    if (PenAutopigs) {
      if (Object.values(DataConsData).some(e => e.scale)) {
        ConScaleList = ScaleData(false, activeData.isShared, PenAutopigs, {...ViewPeriod, start:start}, activeData.pen, undefined).AutoData
      }
      if (Object.values(DataConsData).some(e => e.temp)) {
        ConTempList = TempData(false, activeData.isShared, PenAutopigs, {...ViewPeriod, start:start}, activeData.pen, undefined).AutoData
      }
      if (Object.values(DataConsData).some(e => e.water)) {
        ConWaterList = WaterData(false, activeData.isShared, PenAutopigs, {...ViewPeriod, start:start}, activeData.pen, undefined).AutoData
      }
    }
  }

  const WaterListPrPig = useMemo(() => {
    var WaterListPrPig:Record<string, {Water:Record<string,{
      amount: number; count: number; timestamp: number;
      objArr: WaterInfo[];
    }>, Total:number}> = {}

    if (ConWaterList && !isEmpty(ConWaterList)) {
      const AllOpes = Operations.filter((e:CommentData) => (e.action === OTypes.SOLD || e.action === OTypes.MOVE || e.action === OTypes.ADD))
      Object.values(ConWaterList).forEach((WaterList_Data:WaterList) => {
        if (WaterList_Data && !isEmpty(WaterList_Data)) {
          Object.entries(WaterList_Data).forEach(([Id, dataPoint]) => {
            var TotalWaterPrPig = 0
            if (dataPoint && !isEmpty(dataPoint)) {
              var WaterDatas = Object.entries(dataPoint).reduce((res, cur) => {
                var e = cur[1]
                if (e.amount) {
                  var PigCount = (penData?.datas?.count||0)
                  if (AllOpes.length) {
                    AllOpes.forEach(OPE => {
                      if (e.timestamp < OPE.time) {
                        if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) {
                          PigCount += OPE.quantity
                        }
                        else if (OPE.action === OTypes.ADD) {
                          PigCount -= OPE.quantity
                        }
                      }
                    })
                  }
                  var amount = e.amount / PigCount
                  if (isNaN(amount) || !isFinite(amount)) amount = 0
                  TotalWaterPrPig+=amount
                  res[cur[0]] = {...e, amount:amount }
                }
                return res
              }, {})
              WaterListPrPig[Id] = {Water:WaterDatas, Total:Math.round(TotalWaterPrPig)}
            }
          })
        }
      })
    }
    return WaterListPrPig
  }, [Operations, ConWaterList]) 

  const FeedsListsPrPig = useMemo(() => {
    if (FeedsLists) {
      const AllOpes = Operations.filter((e:CommentData) => (e.action === OTypes.SOLD || e.action === OTypes.MOVE || e.action === OTypes.ADD))
      var FeedsListsPrPig = Object.entries(FeedsLists).reduce((res:Record<string, {Feeds:FeedInfo[],Total:number}>, cur) => {
        var TotalFeedPrPig = 0
        var FeedDatas = cur[1].map(e => {
          if (e.sumamount) {
            var PigCount = (penData?.datas?.count||0)
            if (AllOpes.length) {
              AllOpes.forEach(OPE => {
                if (e.timestamp < OPE.time) {
                  if (OPE.action === OTypes.SOLD || OPE.action === OTypes.MOVE) {
                    PigCount += OPE.quantity
                  }
                  else if (OPE.action === OTypes.ADD) {
                    PigCount -= OPE.quantity
                  }
                }
              })
            }
            if (PigCount) {
              var amount = e.sumamount / PigCount
              TotalFeedPrPig+=amount
              var silolist = e.silolist?.map(s => ({...s, amount:(s.amount/ PigCount)}))
              return ({...e, sumamount:amount, silolist:silolist})
            }
          }
          else return null
        }).filter(e => e)
        res[cur[0]] = {Feeds: FeedDatas, Total: Math.round(TotalFeedPrPig)}
        return res
      }, {})
      return FeedsListsPrPig
    }
  }, [Operations, FeedsLists])

  const OperationViewRef = useRef<OperationsPropHandle>(null)
  const CurveViewRef = useRef<CurveViewerPropHandle>(null)

  const ShiftPen = (dir:boolean) => {
    if (sectionData && sectionData.pens) {
      var PenIndex = sectionData.pens.indexOf(activeData.pen)
      if (PenIndex >= 0) {
        switch (dir) {
          case true: //forward
            PenIndex = PenIndex +1
            break;
          case false: //backward
            PenIndex = PenIndex -1
            break;
        }
        if (PenIndex < 0) PenIndex = sectionData.pens.length - 1
        else if (PenIndex >= sectionData.pens.length) PenIndex = 0

        navigateBack()
        //dispatch(setStates({state:"section", data:SectionId}))
        dispatch(setStates({state:"pen", data:sectionData.pens[PenIndex]}))
        navigate('User', {screen: 'Pen_Display', params:{IsDataLink:activeData.isShared}})
        //if (CurveViewRef.current) CurveViewRef.current.Reset()
        //dispatch(setStates({state:'pen', data:sectionData.pens[PenIndex]}))
      }
    }
  }

  const {DisplayCount, DisplayWeight, GrowthDisplay, WeightSets, PredictSets, TemplateSets, IsAutoData} = useMemo(() => {
    var DisplayCount = penData&&penData.datas&&penData.datas.count?penData.datas.count:0
    var DisplayWeight = 0
    var GrowthDisplay = 0
    var WeightSets:DataTemplate = []
    var TemplateSets:DataTemplate = []
    var PredictSets:DataTemplate = []
    var Weights: CommentData[] | undefined = undefined

    var PenActive = startdate?startdate:undefined
    var IsAutoData:Record<string,{amount:number,count:number, latest:number, key:string}> = {}
    if (Weighings_Notes && Array.isArray(Weighings_Notes)) {
      Weights = Weighings_Notes
      WeightSets = WeightSets.concat(Weighings_Notes.map(weight => ({x:weight.time, y:(weight.count?weight.weight/weight.count:weight.weight)} as PointSet)))
    }
    if (ConScaleList && !isEmpty(ConScaleList)) {
      //TODO: Multiple Autopigs calc ??
      var AutoWeights = Object.entries(ConScaleList[0]).map(([AutoKey, data]) => {
        return Object.values((data as ScaleList)).map((e:any) => {
          if (e.timestamp <= new Date().setHours(0,0,0,0)) {
            return {count:1, pen:activeData.pen, time: e.timestamp, penTime:`${activeData.pen}#${e.timestamp}`, weight:e.amount} as CommentData
          } else {
            var latest = Math.max(...e.objArr.map(o => o.timestamp))
            IsAutoData[AutoKey] = {amount: e.amount, count: e.count, latest:latest, key:AutoKey}
            return undefined
          }
        }).filter(e => e)
      })
      if (!isEmpty(AutoWeights)) {
        const _Weights = AutoWeights[0].filter(e => e)
        if (!isEmpty(_Weights)) Weights = _Weights as CommentData[]
      }
    }
    if (Weights && !isEmpty(Weights)) {
      var PenGraphData = DataGraphs&&PenGraph&&DataGraphs[PenGraph]?DataGraphs[PenGraph]:undefined
      var WeightData = WeightsCalc(activeData.pen+'', Weights, PenGraphData, true, {startdate:PenActive})
      
      //var penCifer = penData&&penData.datas&&penData.datas.count&&!IsAutoData?penData.datas.count:1
      if (WeightData.Weight) DisplayWeight = Math.round(WeightData.Weight * 100) / 100 //Math.round(((WeightData.Weight * WeightData.Count) / penCifer) * 100) / 100
      if (WeightData && WeightData.Predict) {
        if (WeightData.Predict.Now) DisplayWeight = Math.round(WeightData.Predict.Now * 1000) / 1000 // Math.round(((WeightData.Predict.Now * WeightData.Count) / penCifer) * 100) / 100
        
        if (!isEmpty(WeightData.Predict.data) && !isEmpty(WeightData.Predict.time)) {
          PredictSets = PredictSets.concat(WeightData.Predict.data.map((data:number, index:number) => (({x:WeightData.Predict.time[index], y: data}) as PointSet)))
        }
      }
      if (PenGraphData && PenGraphData.data) {
        var GraphDatas = PenGraphData.data
        var tempStartTime = (PenActive?PenActive:ViewPeriod.start)
        tempStartTime = new Date(tempStartTime).getTime()//.setHours(23,59,0,0)

        //get correction for weight.. to time..
        //rem weight time can be later than starttime... so correct weight backwards to

        var firstWeight:number = GraphDatas[0]
        if (WeightData.FirstWeight) {
          let GraphFirstIndex = GraphDatas.findIndex((Data, index) => {
            return (Data && index !== 0)
          })
          let SWDelta = ((WeightData.FirstWeight.time - tempStartTime) / UnixDayTime)
          let TtDelta = GraphFirstIndex - 0
          let TwDelta = GraphDatas[GraphFirstIndex] - firstWeight
          let difGrowth = (TwDelta / TtDelta) * SWDelta

          firstWeight = (WeightData.FirstWeight.weight / (WeightData.FirstWeight.count?WeightData.FirstWeight.count:1)) - difGrowth
        }
        
        var TempWeight:number = firstWeight
        var tempindex = 0
        Object.entries(GraphDatas).forEach(([_index,Data]) => {
          var index = parseInt(_index)
          var time = new Date(AccumulateTime(new Date(tempStartTime), index)).getTime()
          if (Data && (!ViewPeriod.end || time <= ViewPeriod.end + (2*UnixDayTime))) {
            let newGain = index===0?0:GraphDatas[index]-GraphDatas[tempindex]
            var newWeight = TempWeight + newGain
            TemplateSets.push({
              x:time,
              y:newWeight
            } as PointSet)

            TempWeight = newWeight
            tempindex = index
          }
        })
      }
      if (WeightData.Growth && Weights.length > 1) GrowthDisplay = Math.round(WeightData.Growth * 1000) / 1000
    }
    //if (CurveViewRef.current && (WeightSets && WeightSets.length != Sets?.length || TemplateSets && TemplateSets.length != TemplateData.length)) CurveViewRef.current.Reset()
    return {DisplayCount, DisplayWeight, GrowthDisplay, WeightSets, PredictSets, TemplateSets, IsAutoData}
  },[penData, Weighings_Notes, ConScaleList, ViewPeriod, PenGraph])

  /*useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])*/
  //const Treatments = useAppSelector(state => Comments.GetTreatments(state, undefined))
  if (/*!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms) ||*/ isEmpty(penData)) return <LoadingSplash/>
  else {
    const DataConstruckt = useMemo(() => {
      var SetLists:DataSet[] = []
      var Growths:PointSet[]|undefined = undefined
      var Weights:PointSet[]|undefined = undefined

      if (WeightSets&&WeightSets.length) {
        Growths = CalculateGrowthSet(WeightSets)
        Weights = WeightSets
        SetLists = SetLists.concat([{
          color:"#00ebef",//batchDatas?getUniqueColor(Object.keys(batchDatas.pens).indexOf(activeData.pen+'')+2):// //getUniqueColor(2),
          name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+activeData.pen+'',
          form:'Weight', type:ViewForms.LINE,
          data:WeightSets
        }as DataSet, {
          color:"#00ebef",//batchDatas?getUniqueColor(Object.keys(batchDatas.pens).indexOf(activeData.pen+'')+2):// //getUniqueColor(2),
          name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+activeData.pen+'_'+t('GraphViewer.Growth'),
          form:'Growth', type:ViewForms.LINE,
          data: Growths
        }as DataSet])
      }
      if (TemplateSets&&TemplateSets.length) {
        SetLists = SetLists.concat([{
          color:'#000',
          name:PenGraph,
          form:'Weight', type:ViewForms.LINE,
          data:TemplateSets
        }as DataSet,{
          color:'#000',
          name:PenGraph+'_'+t('GraphViewer.Growth'),
          form:'Growth', type:ViewForms.LINE,
          data:CalculateGrowthSet(TemplateSets)
        }as DataSet])
      }
      if (PredictSets&&PredictSets.length) {
        SetLists = SetLists.concat([{
          color:getUniqueColor(2),///batchDatas?getUniqueColor(Object.keys(batchDatas.pens).indexOf(activeData.pen+'')+2):// //getUniqueColor(2),
          name:t('GraphViewer.PredictionTxt'),
          form:'Weight', type:ViewForms.LINE, dotted:true,
          data:PredictSets
        }])
      }
      if (Operations) {
        SetLists = SetLists.concat([{
          color:'#000',
          name:t('GraphViewer.NoteLabel'),
          form:'Notes', type:ViewForms.SCATTER,
          data: Operations.filter(comment => !comment.deleted && !comment.edited).map(comment => ({x:comment.time, y:'min', objData:comment}as PointSet))
        }as DataSet])
      }

      if (ConScaleList) {
        SetLists = SetLists.concat(
          Object.values(ConScaleList).map((ScaleList) => Object.entries(ScaleList).map(([pen, data], index) => {
            var ScaleDatas:PointSet[]|undefined = undefined
            var Activities:PointSet[]|undefined = undefined
            if (data) {
              var Datas = Object.entries(data).reduce((Res:{ScaleDatas:PointSet[], Activities:PointSet[]}, cur) => {
                var TimeName = cur[0]
                var TimeData = cur[1]
                var Sum = TimeData.sumamount?TimeData.sumamount:TimeData.amount
                var Count = TimeData.count?TimeData.count:0

                Res.ScaleDatas.push({
                  x:TimeData.timestamp, y:Sum, 
                  objData:TimeData.objArr,
                  dotted: TimeData.timestamp>=new Date().setHours(0,0,0,0)
                } as PointSet)
                Res.Activities.push({
                  x:TimeData.timestamp, y:Count, 
                  objData:TimeData.objArr,
                  dotted: TimeData.timestamp>=new Date().setHours(0,0,0,0)
                } as PointSet)

                return Res
              },{ScaleDatas:[], Activities:[]})

              if (Datas.Activities.length) Activities= Datas.Activities
              if (Datas.ScaleDatas.length) ScaleDatas = Datas.ScaleDatas
            }

            var Curves = [{
              color:getUniqueColor(index+2),
              name: //(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+
                    pen + '_' + t('GraphViewer.Scale'),
              form: 'Weight', type:ViewForms.LINE,
              data: ScaleDatas
            } as DataSet,{
              color:getUniqueColor(index+54),
              name: //(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+
                    pen + '_' + t('GraphViewer.Activity'),
              form: 'Activity', type:ViewForms.LINE,
              data: Activities
            } as DataSet]
            if (ScaleDatas) {
              //TODO: what if multiple Scales ???
              Weights = ScaleDatas
              Growths = CalculateGrowthSet(ScaleDatas)
              Curves = Curves.concat([{
                color:getUniqueColor(index+55),
                name: //(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+
                      pen + '_' + t('GraphViewer.Growth'),
                form: 'Growth', type:ViewForms.LINE,
                data: Growths
              }as DataSet])
            }
            return Curves
          }).flat()).flat()
        )
      }

      if (FeedsLists) {
        SetLists = SetLists.concat(
          Object.entries(FeedsLists).map(([valve, data],index) => ({
            color:getUniqueColor(index+11),
            name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ valve + '_' + t('GraphViewer.Feed'),
            form: 'Feed', type:ViewForms.BAR,
            data: data?data.map(Feed => ({x:Feed.timestamp, y:((Feed.sumamount?Feed.sumamount/1000:0)), objData:Feed} as PointSet)):undefined
          } as DataSet))
        )
      }
      if (FeedsListsPrPig && Weights) { //Growths) { // Weight set or Scale ???
        var FCRDatas = Object.entries(FeedsListsPrPig).map(([valve, data],index) => {
          if (data && data.Feeds && data.Feeds.length) {
            var PointDatas = data.Feeds.reduce((res:PointSet[], cur) => {
              var surrond = Weights?.reduce((sur:{low:undefined|PointSet,high:undefined|PointSet}, weight) => {
                if (cur.timestamp <= weight.x && (!sur.high || weight.x < sur.high.x)) sur.high = weight
                if (cur.timestamp > weight.x && (!sur.low  || weight.x > sur.low.x)) sur.low = weight
                return sur
              }, {low:undefined,high:undefined})
              if (surrond && surrond.low && surrond.high) {
                var value = (surrond.high.y - surrond.low.y) * 1000
                //if (value < 0) value = 0
                var days = (surrond.high.x - surrond.low.x) / 86400000

                var GrowthVal = value / days
                res.push({
                  x: cur.timestamp, y:cur.sumamount / GrowthVal,
                  objData:{surrond:surrond, feed: cur}
                })
              }
              return res
            }, [])
            return ({
              color:getUniqueColor(index+12),
              name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ valve + '_' + t('GraphViewer.FCR'),
              form: 'FCR', type:ViewForms.LINE,
              data: PointDatas
            } as DataSet)
          }
          else return null
        }).filter(e => e)
        if (FCRDatas && FCRDatas.length) SetLists = SetLists.concat(FCRDatas)
      }
      if (WaterListPrPig) {
        var WaterDatas = Object.entries(WaterListPrPig).map(([valve, data],index) => {
          if (data && data.Water && !isEmpty(data.Water)) {
            var PointDatas = Object.entries(data.Water).map(([TimeName, TimeData]) => ({x:TimeData.timestamp, y:TimeData.amount, objData:TimeData.objArr} as PointSet))
            return ({
              color:getUniqueColor(index+11),
              name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ valve + '_' + t('GraphViewer.Water'),
              form: 'WaterPrPig', type:ViewForms.BAR,
              data: PointDatas
            } as DataSet)
          }
          else return null
        }).filter(e => e)
        if (WaterDatas && WaterDatas.length) SetLists = SetLists.concat(WaterDatas)
      }
      if (ConWaterList) {
        SetLists = SetLists.concat(
          Object.values(ConWaterList).map((WaterList) => Object.entries(WaterList).map(([pen, data], index) => {
            return {
              color:getUniqueColor(index+11),
              name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ pen + '_' + t('GraphViewer.Water'),
              form: 'Water', type:ViewForms.BAR,
              data: data?Object.entries(data).map(([TimeName, TimeData]) => ({x:TimeData.timestamp, y:TimeData.amount, objData:TimeData.objArr} as PointSet)):undefined
            } as DataSet
          })).flat()
        )
      }
      if (ConTempList) {
        SetLists = SetLists.concat(
          Object.values(ConTempList).map((TempList) => Object.entries(TempList).map(([pen, data], index) => {
            return {
              color:getUniqueColor(index+38),
              name: (Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault'))+ pen + '_' + t('GraphViewer.Temp'),
              form: 'Temp', type:ViewForms.LINE,
              data: data?Object.entries(data).map(([TimeName, TimeData]) => ({x:TimeData.timestamp, y:TimeData.amount, objData:TimeData.objArr} as PointSet)):undefined
            } as DataSet
          })).flat()
        )
      }
      //console.log("SetLists", SetLists)
      return SetLists
    }, [WeightSets, TemplateSets, PredictSets, Operations, FeedsLists, FeedsListsPrPig, WaterListPrPig/*ConWaterList*/, ConScaleList, ConTempList])
    
    const Header = useMemo(() => {
      if (sectionData && sectionData.pens) {
        var PenIndex = sectionData.pens.indexOf(activeData.pen)
        return {
          left:PenIndex>=1,
          right:PenIndex<sectionData.pens.length-1
        }
      }
      return false
    }, [sectionData, activeData.pen])
    
    //console.log("view")
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[{ position:'absolute', top:"70px", right:"2%", width:"300px", minHeight:'max-content'}, isAppView?{display:'none'}:{display:'block'}]}>
          <Brand width={'100%'}/>
        </View>
        <View style={{height:20}}/>
        <Button Contexts={[activeData.pen+'']} Title Arrows={Header} onPageSwipe={(dir) => ShiftPen(dir)}/>

        <View style={{height:20}}/>
        <ContentProp Columns={2} Datas={[[t('Pen_Display.Prop1'), ''+DisplayCount]]}/>
        <ContentProp Columns={2} Datas={[[t('Pen_Display.Prop4')+' '+t('DataTypes.W'), ''+DisplayWeight+''], [t('Pen_Display.Prop5')+' '+t('DataTypes.W_small'), (GrowthDisplay*1000)+'']]}/>
        
        {GrowthDisplay&&age&&FeedsListsPrPig&&Object.keys(FeedsListsPrPig).length //TODO: What if more feed systems ???
          ?<ContentProp Columns={2} Datas={[['Daily FCR', ''+
            (Math.round(((FeedsListsPrPig[Object.keys(FeedsListsPrPig)[0]].Total / age) / (GrowthDisplay * 1000)) *100)/100)
          ]]}/>
        :<></>}

        <ContentProp Columns={2} Datas={[[t('Pen_Display.Prop6'), {value:PenGraph?PenGraph:"---", values:DataGraphs?['---'].concat(Object.keys(DataGraphs)).filter(e => e !== 'update_time'):[], edit:2}], ]}
          Actions={[(ele:any) => {
            if (ele.row === 0) {
              if (ele.data === '---' /*|| (batchDatas && batchDatas.gcurve && batchDatas.gcurve === ele.data)*/ ) {
                firebase.update(`farms_data/${activeData.isShared?activeData.isShared:activefarm}/pens/${activeData.pen}/datas`, {curve: null})
              } else firebase.update(`farms_data/${activeData.isShared?activeData.isShared:activefarm}/pens/${activeData.pen}/datas`, {curve: ele.data})
            }
          }]} 
        />
        <ContentProp Columns={2} Datas={[[t('Pen_Display.Prop3'), age?age+'':'-']]}/>
        <ContentProp Columns={2} Datas={[[t('Pen_Display.Size'), {value:''+(penData&&penData.datas&&penData.datas.pensize?penData.datas.pensize:1), edit:3}]]}
          Actions={[(ele:any) => {
            if (ele.row === 0) {
              if (ele.data !== "" && !isNaN(ele.data)) {
                firebase.update(`farms_data/${activeData.isShared?activeData.isShared:activefarm}/pens/${activeData.pen}/datas`, {pensize: parseInt(ele.data)})
              }
            }
          }]} 
        />

        <View style={[ Layout.fillRow, Layout.fullWidth, {maxWidth:600, marginTop:5, justifyContent:'space-between'}]}>
          <View style={[{maxWidth:300, minWidth:120, width:'50%', alignItems:'center'}]}>
            {isAppView&&(<Brand width={'120px'}/>)}
          </View>
          <View style={{width: "fit-content"}}>
            <Button Contexts={['CSV   Data .']} maxWidth={'200px'} EndEnhancers={Images.Save} Action={() => {
              var TagScales = CurveViewRef.current?.SelectedTags()
              var Scales = TagScales&&TagScales.length?(ConScaleList?ConScaleList:[]).concat(TagScales):ConScaleList
              console.log("data", Scales)
              GenerateCSV_Data(activeData.pen, ConFeedList, Scales, ConTempList, ConWaterList, weighings, Operations)
            }}/>
          </View>
        </View>

        {DataConsData?(
        <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, {minHeight:'max-content', minWidth:'300px', marginTop:15}]}>
          {ConSystemList&&ConSystemList.length?(ConSystemList[0].SystemIndex?ConSystemList:ConSystemList[0]).map((Con, index) => {
            var DataCon = Object.entries(DataConsData).find(([key, values]) => key+'' === (Con.SystemIndex?Con.SystemIndex:0)+'')
            if (DataCon) {
              var ConDatas = DataCon[1]
              var SysId = Con.SystemIndex?Con.SystemIndex : 0
              var Name = ConDatas&&ConDatas.name?ConDatas.name : Con.SystemName

              var _Datas = [`System ${SysId}#: ${Name}`, Con.Online?'Online':'Error']
              var _Colors = [null, Con.Online?Colors.AgriGreen:Colors.AgriRed]
              
              if (ConDatas.scale) {
                var CalcDatas = IsAutoData[Name]
                var CalcCount = CalcDatas&&CalcDatas.count?CalcDatas.count:'---'
                var CalcLatest = CalcDatas&&CalcDatas.latest?CalcDatas.lastest:undefined

                _Datas.push(`Registred Weights: ${CalcCount}`)
                if (CalcCount&&CalcCount!=='---') {
                  if (CalcLatest && CalcLatest>(Date.now()-345.600)) _Colors.push(null)
                  else _Colors.push(Colors.BUMBLEBEE)
                }
                else _Colors.push(Colors.BUMBLEBEE)
              }

              return (<ContentProp key={`ConSys_${SysId}_${index}`} 
                  Columns={_Datas.length} Datas={[_Datas]} colorStamp={_Colors}
              />)
            }
            return (null)
          }):(<></>)}
        </View>
        ):(<></>)}

        <FeedSilosList FeedsList={FeedsLists?Object.values(FeedsLists).flat(1):[]} FixedDisplay={true} GenerateCSV_Data={() => GenerateCSV_Data(activeData.pen, ConFeedList)} isFetching={ConFeedFetching} />
        <View style={{height:30}}/>

        <CurveViewer ref={CurveViewRef} yShrink StartTimes={ViewPeriod} DatePicker DateArrows canMultiSplit
          MarkDates={Periodes} Primary={{left:'Weight', right:'Feed'}} LimitedDate={{start:startdate}as TimeSpan}
          OnTimesChanged={(start, end) => {
            updateViewPeriod({start:start,end:(end?end:ViewPeriod.end)})
          }}
          DataView={DataConstruckt}
        />
        
        <View style={{height:20}}/>
        <WeighingsList setEditSelect={(data) => OperationViewRef.current?OperationViewRef.current.setEdit(data):{}} FixedDisplay={true} WeighingsList={Weighings_Notes?Weighings_Notes:[]} GenerateCSV_Data={() => GenerateCSV_Data(activeData.pen, undefined, undefined, undefined, undefined, weighings, undefined)}/>
        <OperationsList setEditSelect={(data) => OperationViewRef.current?OperationViewRef.current.setEdit(data):{}} FixedDisplay={true} OperationsList={Operation_Notes?Operation_Notes:[]} GenerateCSV_Data={() => GenerateCSV_Data(activeData.pen, undefined, undefined, undefined, undefined, undefined, Operations)}/>

        {/*<WeighingsList FixedDisplay={true} WeighingsList={Weighings_Notes} GenerateCSV_Data={() => GenerateCSV_Data(activeData.pen, undefined, undefined, undefined, undefined, weighings, undefined)}/>
        <OperationsList setEditSelect={setEditSelect} FixedDisplay={true} OperationsList={Operation_Notes?Operation_Notes:[]} GenerateCSV_Data={() => GenerateCSV_Data(activeData.pen, undefined, undefined, undefined, undefined, undefined, Operations)}/>
        */}

        <PrintContainer $NoPrint>
          <OperationsView ref={OperationViewRef} Section={activeData.section} Pen={activeData.pen} isShared={activeData.isShared} />
          {/*<OperationsView ViewMode={ModuleForms.NoteFrame} Section={activeData.section} isDataLink={activeData.isShared} Pen={activeData.pen} EditItem={{id:EditSelect&&EditSelect.key?EditSelect.key:undefined, 
            set:(value) => {
              setEditSelect(value)
              navigate('User', {screen: 'Pen_Display', params:{IsDataLink:activeData.isShared}})
            }}} 
          />*/}
        </PrintContainer>
      </View>
    )
  }
}

export default Pen_Display