import React, { useEffect, useMemo, useState } from 'react'
import { View, Text } from 'react-native'
import { useTheme } from '../../Theme'

import { createSelector } from '@reduxjs/toolkit'
//import { createSelector } from 'reselect'
import { useAppSelector, useAppDispatch } from '../../Store'

import { Select, TYPE } from "baseui/select";
import { isEmpty } from '../../Config/Types'
import { get } from 'lodash'

export interface ListItem {label:string, id:string, IsDataLink?:string}
export const GetSectionsList = createSelector(
  [
    (state:any) => get(state.firebase.data, `sections`),
    (state:any) => get(state.firebase.data, `LinkList`),
  ],
  (Local:any, Extern:any):ListItem[]|undefined => {
    var LocalSections = Local?Object.entries(Local).map(([Key, Data]) => {
      return {label: Key, id: Key }
    }):[]

    var ExternSections = Extern?Object.entries(Extern).map(([FarmKey, FarmData]) => {
      if (FarmData.sections) {
        return Object.entries(FarmData.sections).map(([Key, Data]) => {
          return {label: Key, id: Key + "@" + FarmKey, IsDataLink: FarmKey} as ListItem
        })
      }
      return undefined
    }).filter(e => e).flat(1) as ListItem[]:[]

    return ExternSections.concat(LocalSections)
  }
)

const GetPenList = createSelector(
  [
    (state:any, Selected:{section:string, IsDataLink?:string}) => get(state.firebase.data, `sections`),
    (state:any, Selected:{section:string, IsDataLink?:string}) => get(state.firebase.data, `LinkList`),
    (state:any, Selected:{section:string, IsDataLink?:string}) => Selected,
  ],
  (Local:any, Extern:any, Selected):ListItem[]|undefined => {
    if (Selected && Selected.section) {
      if (Selected.IsDataLink) {
        if (Extern && Extern[Selected.IsDataLink] && Extern[Selected.IsDataLink].sections && Extern[Selected.IsDataLink].sections[Selected.section]) {
          return Extern[Selected.IsDataLink].sections[Selected.section].pens.map((Id:number) => {
            return {label: Id, id: Id}
          })
        }
      }
      else {
        if (Local && Local[Selected.section]) {
          return Local[Selected.section].pens.map((Id:number) => {
            return {label: Id, id: Id}
          })
        }
      }
    }
    return undefined
  }
)

const GetWeightList = createSelector(
  [
    (state:any, SelectedPen:string|undefined /*SelectedSection:{section:string, IsDataLink?:string}*/) => get(state.firebase.data, `Weights`),
    (state:any, SelectedPen:string) => SelectedPen,
    //(state:any, SelectedPen:string|undefined, SelectedSection:{section:string, IsDataLink?:string}) => SelectedSection,
  ],
  (Weights:any, SelectedPen):ListItem[]|undefined => {
    if (Weights && !isEmpty(Weights)) {
      return Object.entries(Weights).map(([Key, data]) => {
        if (data.pen === SelectedPen && !data.edited && !data.deleted) {
          var time = new Date(data.time)
          return {label: time.getFullYear() + '/' + (time.getMonth()+1) + '/' + time.getDate() + ' - ' + data.count +' * '+ data.weight, id: Key} as ListItem
        }
        return undefined
      }).filter(e => e) as ListItem[]
    }
    return undefined
  }
)

interface Section_Picker_Props {
  Selected: {section:string, IsDataLink?:string}|undefined,
  LabelText?: string,
  onSelected: (value:{section:string, IsDataLink?:string}) => void
}
export const Section_Picker = ({LabelText, Selected, onSelected}:Section_Picker_Props) => {
  //const { t } = useTranslation()
  const { Layout } = useTheme()

  const SectionList = useAppSelector(state => GetSectionsList(state))
  const [SelectValue, setSelectValue] = useState<ListItem>({label:'---', id:'none'})

  useEffect(() => {
    if (!Selected || Selected.section != SelectValue.id) onSelected({section: SelectValue.label, IsDataLink:SelectValue.IsDataLink})
  }, [SelectValue])

  useEffect(() => {
    var value = SectionList&&SectionList.length?SectionList[0]:undefined
    if (SectionList&&Selected&&Selected.section&&Selected.section!=='---') value = SectionList.find(e => e?(e.id===Selected.section && e.IsDataLink===Selected.IsDataLink):false)
    if (!value) value = {label:'---', id:"none"}
    setSelectValue(value)
  }, [])

  return(
    <View style={[Layout.fill, {maxWidth:'max-content', minWidth:'min-content', maxHeight:'fit-content'}]}>
      {LabelText?<Text style={[{}]}>{LabelText}</Text>:<></>}
      <Select clearable={false}
        options={SectionList}
        value={[SelectValue]}
        type={TYPE.select}
        placeholder="Section"
        onChange={(params:any) => {
          setSelectValue(params.value[0])
        }}
        maxDropdownHeight="300px"
        overrides={{
          Root: { style: ({ $theme }) => ({
            maxWidth:'200px', minWidth:'150px', 
            outline:'black 1px solid'
          })}
        }}
      />
    </View>
  )
}

interface Pen_Picker_Props {
  Selected: string|undefined,
  LabelText?: string,
  SelectedSection: {section:string, IsDataLink?:string}|undefined,
  onSelected: (value:string|undefined) => void
}
export const Pen_Picker = ({LabelText, Selected, SelectedSection, onSelected}:Pen_Picker_Props) => {
  //const { t } = useTranslation()
  const { Layout } = useTheme()

  const PenList = useAppSelector(state => SelectedSection?GetPenList(state, SelectedSection):undefined)
  const SelectedItem = useMemo(() => {
    var item:ListItem|undefined = undefined
    if (PenList&&PenList.length) {
      if (Selected&&Selected!=='') item = PenList.find(e => e.id===Selected)
      else item = PenList[0]
      if (!item) item = PenList[0]
    }
    if (item) {
      onSelected(item.id)
      return item
    }
    return {label:'---',id:"none"}
  }, [Selected, PenList])

  return(
    <View style={[Layout.fill, {maxWidth:'max-content', minWidth:'min-content'}]}>
      {LabelText?<Text style={[{}]}>{LabelText}</Text>:<></>}
      <Select clearable={false}
        options={PenList}
        value={[SelectedItem]}
        type={TYPE.select}
        placeholder="Pen"
        onChange={(params:any) => {
          if (params.value[0].id === "none") onSelected(undefined)
          else onSelected(params.value[0].id)
        }}
        maxDropdownHeight="300px"
        overrides={{
          Root: { style: ({ $theme }) => ({
            maxWidth:'200px', minWidth:'150px', 
            outline:'black 1px solid'
          })}
        }}
      />
    </View>
  )
}

interface Weight_Picker_Props {
  Selected: string|undefined,
  LabelText?: string,
  SelectedSection: {section:string, IsDataLink?:string}|undefined,
  SelectedPen: string|undefined,
  onSelected: (value:string) => void
}
export const Weight_Picker = ({LabelText, Selected, SelectedSection, SelectedPen, onSelected}:Weight_Picker_Props) => {
  //const { t } = useTranslation()
  const { Layout } = useTheme()

  const WeightList = useAppSelector(state => SelectedPen?GetWeightList(state, SelectedPen):undefined)
  const [SelectValue, setSelectValue] = useState<ListItem>({label:'---', id:'none'})

  useEffect(() => {
    if (!Selected || (Selected != SelectValue.id && SelectValue.id !== "none")) onSelected(SelectValue.id)
  }, [SelectValue])

  useEffect(() => {
    var value = undefined
    if (WeightList&&WeightList.length) {
      if (Selected&&Selected!=='---') value = WeightList.find(e => e?e.id===Selected:false)
      else value = WeightList[0]
    }
    if (!value) value = {label:'---', id:"none"}
    setSelectValue(value)
  }, [SelectedPen, WeightList])

  //const activefarm = useAppSelector(state => state.activeData.farm)
  //TODO: create fetch and selector for Selectedpen and isEdit
  /*useFirebaseConnect(() => {
    let Calls: any[] = []
    if ((activefarm && activefarm !== "") || (SelectedSection && SelectedSection.IsDataLink)) {
      const path = `farms_data/${SelectedSection&&SelectedSection.IsDataLink?SelectedSection.IsDataLink:activefarm}/`
      Calls.push({path:path + 'weighings', queryParams: ['orderByChild=pen', `equalTo=${SelectedPen}`], storeAs: 'Weights'})
    }
    return Calls
  })*/

  return(
    <View style={[Layout.fill, Layout.row, {maxWidth:'max-content', minWidth:'min-content', maxHeight:'48px'}]}>
      {LabelText?<Text style={[{}]}>{LabelText}</Text>:<></>}
      <Select clearable={false}
        options={WeightList}
        value={[SelectValue]}
        type={TYPE.select}
        placeholder="Weights"
        onChange={(params:any) => {
          setSelectValue(params.value[0])
        }}
        maxDropdownHeight="300px"
        overrides={{
          Root: { style: ({ $theme }) => ({
            maxWidth:'200px', minWidth:'120px', 
            outline:'black 1px solid'
          })}
        }}
      />
    </View>
  )
}
