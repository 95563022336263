import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { View } from 'react-native'

import { useTheme } from '../../../Theme'
import { UnixDayTime, ConsysRefs, Periodes, TimeSpan, CommentData } from '../../../Config/Types'

//import { ConsysApi } from '../../../Services'
//import {  } from '../../../Services/Consys'
import { WeightInfo, ScaleList } from '../../../Services/Consys/Scale-Slice'
import { ActivityInfo } from '../../../Services/Consys/Activity-Slice'
import { TemperatureInfo } from '../../../Services/Consys/Temperature-Slice'
import { WaterInfo } from '../../../Services/Consys/Water-Slice'
import { FeedInfo } from '../../../Services/Consys/Feed-Slice'

import { CurveView_Provider } from './CurveViewer_context'
import CurveView_SelectEditor from './CurveView_SelectEditor'
import { default as CurveViewer_Display, ViewForms} from './CurveViewer'
import Label_Display from './Label_Display'
import { getUniqueColor } from '../../../utils/formaters'

export { ViewForms } //?? circular dependency ... works like this. ?
export interface Point { x:number, y:number|'min' } //TODO: clean string in y
export interface PointRange {
  x?:{min?:number, max?:number},
  y?:{min?:number, max?:number}
}
export interface Domain extends PointRange {
  y2?: {min?:number, max?:number}
}

export interface PointSet extends Point {
  objData?:WaterInfo[]|TemperatureInfo[]|ActivityInfo[]|WeightInfo[]|CommentData|FeedInfo,
  predict?:boolean
}
export type DataTemplate = PointSet[]

export type DataForm = 'Weight'|'WeightTaged'|ConsysRefs.SCALE|'Growth'|'Activity'|ConsysRefs.ACTIVITY|'Feed'|ConsysRefs.VALVEFEED|'FCR'|'Temp'|ConsysRefs.TEMPERATURE|'WaterPrPig'|'Water'|ConsysRefs.WATERCOUNT|'Notes'|'All'
export interface DataSet {
  type:ViewForms,
  prefix?:string, color:string|number,
  prename?:string, name:string,

  data:DataTemplate|undefined,

  startPoint?:number, // + Extra days before start... 
  form:DataForm,
  dotted?:boolean,

  deactive?:boolean
}
export type DataSets = DataSet[]

const Get_Domains = (Settings:Settings_Props, ActiveDataSets: DataSets, DeactiveSets: string[], Selected_DataOptions:SelectorContainer) => {
  var MinMax:Domain = {y:{max:1}, x: {max:1}, y2:{max:1}}
  if (ActiveDataSets && ActiveDataSets.length) {
    ActiveDataSets.forEach(Set => {
      if (!Set.deactive&&(Set.data && Set.data.length)&&!DeactiveSets.some(e => e===Set.name)) {
        var XArray = Set.data.map((o) => o.x).filter(a => a)
        var ExTime = (Set.startPoint?Set.startPoint:0)
        var Xmin = Math.min.apply(Math, XArray)
        var Xmax = Math.max.apply(Math, XArray) + ExTime
        if (Settings.zeroIndex) {
          Xmax = Math.floor((Xmax - Xmin)/UnixDayTime)
          Xmin = 0
        }
        if (MinMax.x) {
          if (MinMax.x.min === undefined || Xmin < MinMax.x.min) MinMax.x.min = Xmin
          if (MinMax.x.max === undefined || Xmax > MinMax.x.max) MinMax.x.max = Xmax
        }
        
        var Ydata = Set.data.filter(o => o.y!=='min')
        if(Ydata && Ydata.length) {
          var Ymin = Math.min.apply(Math, Set.data.filter(o => o.y!=='min').map((o) => (o.y==='min'?0:o.y)))
          var Ymax = Math.max.apply(Math, Set.data.filter(o => o.y!=='min').map((o) => (o.y==='min'?0:o.y)))

          if (Set.form === ConsysRefs.VALVEFEED || Set.form === 'Feed') {
            //var diff = Ymax - Ymin
            Ymin = 0
            //Ymin = Ymin<1000?0:diff<1000?Ymin-100:diff<2500?Ymin-1000:diff<5000?Ymin-2500:diff<7500?Ymin-4000:Ymin
          }

          if (Selected_DataOptions.left === Set.form || Set.form === 'WeightTaged') {
            if (MinMax.y) {
              if (MinMax.y.min === undefined || Ymin < MinMax.y.min) MinMax.y.min = Ymin
              if (MinMax.y.max === undefined || Ymax > MinMax.y.max) MinMax.y.max = Ymax
            }
          } else if (Selected_DataOptions.right === Set.form) {
            if (MinMax.y2) {
              if (MinMax.y2.min === undefined || Ymin < MinMax.y2.min) MinMax.y2.min = Ymin
              if (MinMax.y2.max === undefined || Ymax > MinMax.y2.max) MinMax.y2.max = Ymax
            }
          }
        }
      }
    })
  }

  //if (typeof MinMax.y[0] === "string") MinMax.y[0] = parseFloat(MinMax.y[0])
  //if (typeof MinMax.y[1] === "string") MinMax.y[1] = parseFloat(MinMax.y[1])
  //if (typeof MinMax.y2[1] === "string") MinMax.y2[1] = parseFloat(MinMax.y2[1])
  if (MinMax.y) {
    if (MinMax.y.min === undefined) MinMax.y.min = 0
    if (MinMax.y.min === MinMax.y.max) MinMax.y.max = MinMax.y.min +1
  }

  if (MinMax.y2) {
    if (MinMax.y2.min === undefined) MinMax.y2.min = 0
    if (MinMax.y2.min === MinMax.y2.max) MinMax.y2.max = MinMax.y2.min +1
  }

  if (MinMax.x) {
    if (MinMax.x.min === undefined) MinMax.x.min = 0
    if (MinMax.x.min === MinMax.x.max) MinMax.x.max = MinMax.x.min +1
  }
  return MinMax
}

const CheckSetNew = (old:DataSets|null, DataSet:DataSet, append:boolean=false):DataSets => {
  if (old) {
    var _new = [...old]
    var index = _new.findIndex((e:DataSet) => e.name === DataSet.name)
    if (!append && index >= 0) {
      _new[index] = DataSet
    }
    else _new.push(DataSet)
    return _new
  } 
  else return [DataSet]
}
const Get_DataAtForm = (Settings:Settings_Props, DataView:DataSets|undefined, form: DataForm[], DataTimes?:TimeSpan, PreData?:DataSets, noOverWrite:boolean=false, ByTag?:string[]) => {
  var ResData:DataSets = PreData?PreData:[]
  //Fetch form type
  if (DataView && DataView.length) {
    DataView.forEach((DataSet, dataindex) => {
      if (DataSet && DataSet.data && DataSet.data.length) {
        var name = DataSet.name
        var color = DataSet.color
        var _DataFrom = DataSet.form?DataSet.form:'Weight'

        if (form.includes(_DataFrom)) {
          var DataSetDatas:DataTemplate|undefined = {...DataSet.data}
          
          //Filter by Settings and Time
          if (DataTimes || Settings.zeroIndex) {
            var StartTime = DataTimes&&DataTimes.start?DataTimes.start:undefined
            var EndTime = DataTimes?(!DataTimes.end?undefined:(DataTimes.end + UnixDayTime)):undefined
            
            DataSetDatas = DataSet.data?!Settings.zeroIndex&&(DataTimes&&DataTimes.start&&DataTimes.end)?DataSet.data.filter(data => {
              if (StartTime && data.x >= StartTime) {
                if (!EndTime) return true
                else if (data.x <= EndTime + (DataSet.color&&DataSet.color==="#000"?UnixDayTime:0)) return true
              }
              return false
            }):DataSet.data:undefined
          }

          //Filter by tag
          if (ByTag && ByTag.length && DataSetDatas && DataSetDatas.length) {
            _DataFrom = 'WeightTaged'

            ByTag.forEach((RfidTag, TagIndex) => {
              //name += "_ByTag" + (TagIndex + 1)
              name= RfidTag
              color = getUniqueColor(dataindex+3 + TagIndex)

              var _DataSetDatas = DataSetDatas?DataSetDatas.reduce((res, cur) => {
                if (cur.objData && cur.objData) {
                  var DataFiltered = cur.objData.filter((e:WeightInfo) => {
                    if (e.rfids && e.rfids.length === 1) {
                      var Rfid:string = e.rfids[0] //8067533342617245696 104799208
                      if (Rfid.length > 9) Rfid = Rfid.slice(Rfid.length -9, Rfid.length)
                      if (Rfid === RfidTag) return true
                    }
                  })

                  if (DataFiltered.length) {
                    var amount = DataFiltered.reduce((res, cValue) => res+cValue.amount, 0)
                    if (amount) amount = (amount / DataFiltered.length) / 1000
                    
                    res.push({
                      ...cur, y:amount,
                      objData: DataFiltered
                    } as PointSet)
                  }
                }
                return res
              },[] as DataTemplate):[]

              var NewDataSet = {...DataSet, name,color, form:_DataFrom, data:_DataSetDatas}
              NewDataSet.Domain = Get_Domains(Settings, [NewDataSet], [], {left:_DataFrom })
              if (_DataSetDatas && _DataSetDatas.length) ResData = CheckSetNew(ResData, NewDataSet, noOverWrite)
            })
          } else if (DataSetDatas && DataSetDatas.length) {
            //Overwrite if exists
            var NewDataSet = {...DataSet, name,color, form:_DataFrom, type:DataSet.type, data:DataSetDatas}
            NewDataSet.Domain = Get_Domains(Settings, [NewDataSet], [], {left:_DataFrom })
            ResData = CheckSetNew(ResData, NewDataSet, noOverWrite)
          }
        }
      }
    })
  }
  return ResData
}
const Get_ActiveData = (Settings:Settings_Props, ActiveOptions:AOptions, DataView:DataSets|undefined, Selected_DataOptions:SelectorContainer, DataTimes?:TimeSpan):DataSets => {
  var Contained_Sets:DataSets = []
  var Selected = [Selected_DataOptions.left, Selected_DataOptions.right]
  if (Settings.canMultiSplit.state) {
    Selected = ['Weight', 'WeightTaged', 'Growth', 'Activity', 'FCR', 'Feed', 'Temp', 'Water', 'WaterPrPig' ]
  }

  //Sort by form selectors
  for (let index = 0; index < Selected.length; index++) {
    var Res_Set:DataSets = []
    //switch(index===0?Selected_DataOptions.left:Selected_DataOptions.right) {
    switch(Selected[index]) {
      case '---':
      case 'Weight':
      case ConsysRefs.SCALE:
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='Weight'||e.id==="---")) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['Weight', ConsysRefs.SCALE], DataTimes, Contained_Sets)
        }
        break
      case 'WeightTaged':
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='WeightTaged')) {
          if (Selected_DataOptions && Selected_DataOptions.args && Selected_DataOptions.args.tags) { // && Selected_DataOptions.args) {
            //var Ids:string[] = Selected_DataOptions.args.tags
            //if (Selected_DataOptions.right === 'WeightTaged') {
              //Ids = Ids.concat(Selected_DataOptions.args.tags)
            //}
            if (Selected_DataOptions.args.tags.length) {
              Res_Set = Get_DataAtForm(Settings, DataView, ['Weight', ConsysRefs.SCALE], DataTimes, Contained_Sets, false, Selected_DataOptions.args.tags)
            }
          }
        }
        break
      case 'Growth':
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='Growth')) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['Growth'], DataTimes, [...Contained_Sets], false)
        }
        break;
      case 'Activity':
      case ConsysRefs.ACTIVITY:
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='Activity')) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['Activity', ConsysRefs.ACTIVITY], DataTimes, Contained_Sets)
        }
        break
      case 'Feed':
      case ConsysRefs.VALVEFEED:
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='Feed')) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['Feed', ConsysRefs.VALVEFEED], DataTimes, Contained_Sets)
        }
        break
      case 'FCR':
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='FCR')) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['FCR'], DataTimes, [...Contained_Sets], false)
        }
        break
      case 'Temp':
      case ConsysRefs.TEMPERATURE:
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='Temp')) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['Temp', ConsysRefs.TEMPERATURE], DataTimes, Contained_Sets)
        }
        break
      case 'WaterPrPig':
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='WaterPrPig')) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['WaterPrPig'], DataTimes, Contained_Sets)
        }
        break
      case 'Water':
      case ConsysRefs.WATERCOUNT:
        if (ActiveOptions&&ActiveOptions.some(e => e.id==='Water')) {
          Res_Set = Get_DataAtForm(Settings, DataView, ['Water', ConsysRefs.WATERCOUNT], DataTimes, Contained_Sets)
        }
        break
      default:
        break
    }
    if (Res_Set && Res_Set.length) Contained_Sets = Res_Set //Contained_Sets.concat(Res_Set)
  }

  //special extra loads
  if (ActiveOptions&&ActiveOptions.some(e => e.id==='Notes')) {
    var Notes = Get_DataAtForm(Settings, DataView, ['Notes'], DataTimes, Contained_Sets, true)
    if (Notes && Notes.length) Contained_Sets = Notes //Contained_Sets.concat(Notes)
  }
  //console.log("Active", ActiveOptions, Settings, DataTimes, DataView, Contained_Sets)
  return Contained_Sets
}
const Get_ViewLoad = (Settings:Settings_Props, ActiveOptions:AOptions, DataView:DataSets|undefined, Selected_DataOptions:SelectorContainer, DeactiveSets: string[], DataTimes?:TimeSpan):{ActiveData:DataSets, entireDomain:Domain, zoomedXDomain:{min?: number | undefined;max?: number | undefined}|undefined } => {
  const ActiveData = Get_ActiveData(Settings, ActiveOptions, DataView, Selected_DataOptions, DataTimes)
  const Domain = Get_Domains(Settings, ActiveData, DeactiveSets, Selected_DataOptions)
  //console.log("ViewLoad", ActiveData, Settings, Selected_DataOptions)
  return {entireDomain:Domain, zoomedXDomain:Domain.x, ActiveData:ActiveData}
}

export interface Settings_Props {
  DatePicker: boolean, DateArrows: boolean, LimitedDate?: TimeSpan,
  canMultiSplit: {Active:boolean, state?:boolean},
  maxheight?: string|number, zeroIndex: boolean, NoLabels: boolean, yShrink: boolean,
  HideTip?: boolean
}

interface _AOptions {label:string, id:DataForm|"---"}
export interface AOptions extends Array<_AOptions>{}
export type SelectorContainer = {left?:DataForm|'---', right?:DataForm|'---', args?:{tags?:string[]}}

export interface CurveViewer_Props {
  //Data Selectors
  Disables?: DataForm[],
  Primary?:SelectorContainer,

  //Data Time Selector
  DatePicker?: boolean, DateArrows?: boolean, LimitedDate?: TimeSpan,
  StartTimes?: TimeSpan, MarkDates?: Periodes[],

  //Curve View
  maxheight?: string|number,
  zeroIndex?: boolean, 
  canMultiSplit?: boolean,
  yShrink?: boolean, NoLabels?: boolean,

  DataView?: DataSets,
  OnTimesChanged?: (start:number,end:number|undefined) => void
}
export type CurveViewerPropHandle = {
  Reset: () => void,
  GetViewDays: () => [number|undefined,number|undefined]|undefined,
  GetValues: (e:DataForm, name?:string) => [number|undefined,number|undefined]|undefined,
  SelectedTags: () => ScaleList[] | undefined
}
const CurveViewer:React.ForwardRefRenderFunction<CurveViewerPropHandle, CurveViewer_Props> = 
  ({Disables, Primary={left:ConsysRefs.SCALE}, 
    DatePicker=true, DateArrows=false, LimitedDate, StartTimes, MarkDates, 
    maxheight='580px', zeroIndex=false, canMultiSplit=false, yShrink=false, 
    NoLabels=false, DataView, OnTimesChanged }, ref) => {  
  //const { t } = useTranslation()
  const { Layout } = useTheme()

  const CurveViewRef = useRef<CurveViewerPropHandle>(null)
  useImperativeHandle(ref, ()=>({
    Reset() {
      if (CurveViewRef.current) CurveViewRef.current.Reset()
    },
    GetViewDays() {
      var data:[number|undefined,number|undefined]|undefined = undefined
      if (CurveViewRef.current) data = CurveViewRef.current.GetViewDays()
      return data
    },
    GetValues(e, name) {
      var data:[number|undefined,number|undefined]|undefined = undefined
      if (CurveViewRef.current) data = CurveViewRef.current.GetValues(e, name)
      return data
    },
    SelectedTags() {
      var data:ScaleList[] | undefined = undefined
      if (CurveViewRef.current) data = CurveViewRef.current.SelectedTags()
      return data
    }
  }))

  const CalcViewData = useCallback<(Settings: any, ActiveOptions: any, Selected_DataOptions: any, DeactiveSets: any, DataTimes: any) => {
    entireDomain: Domain|undefined; zoomedXDomain: {min?:number, max?:number}|undefined; ActiveData: DataSets|undefined;}>
    ((Settings, ActiveOptions, Selected_DataOptions, DeactiveSets, DataTimes) =>{
      return Get_ViewLoad(Settings, ActiveOptions, DataView, Selected_DataOptions, DeactiveSets, StartTimes)
    }
  ,[DataView, StartTimes])
  const CalcActiveData = useCallback<(Settings: any, ActiveOptions: any, Selected_DataOptions: any, DataTimes: any) => DataSets|undefined>
    ((Settings, ActiveOptions, Selected_DataOptions, DataTimes) => Get_ActiveData(Settings, ActiveOptions, DataView, Selected_DataOptions, StartTimes)
  , [DataView, StartTimes])

  const DataTimes:TimeSpan|undefined = useMemo(() => StartTimes&&StartTimes.start?{
    start: StartTimes.start,
    end:  StartTimes.end?StartTimes.end:Date.now()
  }:undefined, [StartTimes])

  const RFIDTags:string[] = useMemo(() => {
    var Rfids:string[] = []
    if (DataView && DataView.length) {
      DataView.forEach((DataSet) => {
        if (DataSet && (!DataSet.form || DataSet.form === "Weight")) {
          if (DataSet.data && DataSet.data.length) {
            DataSet.data.forEach((DataArr) => {
              if (DataArr && DataArr.objData) {
                DataArr.objData.forEach(e => {
                  if (e.rfids && e.rfids.length === 1) {
                    var Rfid:string = e.rfids[0] //8067533342617245696 104799208
                    if (Rfid.length > 9) Rfid = Rfid.slice(Rfid.length -9, Rfid.length)
                    if (!Rfids.includes(Rfid)) Rfids.push(Rfid)
                  }
                })
              }
            })
          }
        }
      })
    }
    return Rfids.sort()
  } ,[DataView])

  return (
    <CurveView_Provider InitialData={{
        Settings: {maxheight, zeroIndex, DatePicker, DateArrows, LimitedDate, canMultiSplit:{Active:canMultiSplit}, NoLabels, yShrink},
        Disables:Disables, StartTimes:StartTimes, DataView:DataView, Selected_DataOptions:{left:Primary.left, right:Primary.right},
      }}>
      <View style={[Layout.fill, Layout.colVCenter, Layout.fullWidth, {minHeight:'max-content', minWidth:'300px'}]}>
        <CurveView_SelectEditor DataViewTimes={StartTimes} MarkDates={MarkDates} OnTimesChanged={OnTimesChanged} CalcViewData={CalcViewData} RFIDTags={RFIDTags} />
        <CurveViewer_Display DataViewTimes={StartTimes} CalcViewData={CalcViewData} Get_Domains={Get_Domains} ref={CurveViewRef} />
        {!NoLabels?(
          <Label_Display DataTimes={DataTimes} CalcActiveData={CalcActiveData}/>
        ):(<></>)}
      </View>
    </CurveView_Provider>
  )
}

export default forwardRef(CurveViewer) 
