import React, { useEffect, useMemo, useState } from 'react'
import { View, Text, TouchableOpacity, ActivityIndicator } from 'react-native'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { Button, Labelled_Input } from '../../Components'
import PlayPause from '../../Assets/actions/PlayPause'

import { SystemTypes, SystemStates } from '../../Config/Types'
import { SystemInfo, SystemList } from '../../Services/Consys/Systems-slice'

import { ConsysApi } from '../../Services';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
} from 'baseui/modal';

export interface System_Module_Props {
  System: SystemInfo,
  Update?: (data:{System:SystemInfo}) => void,
  IsDataLink?: string
}

export enum AutopigModal_Types {
  TARA = "Tara",
  CALIBRATE = "Calibrate",
  RESET = "Reset",
  START = "Start",
}
interface AutopigFunction_Modal_Props {
  setModalCheck:(ModalCheck:AutopigModal_Types|undefined) => void,
  ModalCheck:AutopigModal_Types|undefined,
  onDone: (TimeRes?:number|"") => void,
  activeAutopig: string|string[],
  IsDataLink?: string,
  Data?: {NewAvg:number},
  Error_Success?:boolean
}
export const AutopigFunction_Modal = ({setModalCheck, ModalCheck, onDone, activeAutopig, IsDataLink, Data, Error_Success}:AutopigFunction_Modal_Props) => {
  const { t } = useTranslation()
  const [NewAVG, SetNewAVG] = useState<number>(Data&&Data.NewAvg?Data.NewAvg:5000)
  const { Layout, Colors } = useTheme()

  //const activefarm = useAppSelector(state => state.activeData.farm)
  const [ScaleWating, setScaleWating] = useState(false)
  const [GetScale, ScaleResult, lastPromiseInfo] = ConsysApi.Corridor.GetScale()
  useEffect(() => {
    setScaleWating(false)
  }, [ModalCheck])

  useEffect(() => {
    var ScaleState = ScaleResult
    if (ScaleState.isError || ScaleState.isSuccess) {
      if (ScaleState.isError) {
        console.log(ScaleState.error)
      }
      if (ScaleState.data) {
        SetNewAVG(ScaleState.data.amount)
      }
      setScaleWating(false)
    }
  }, [ScaleResult])

  useEffect(() => {
    if (Error_Success !== undefined) {
      setScaleWating(false)
      if (Error_Success) {
        setModalCheck(undefined)
      } else {
        //Error msg
      }
    }
  }, [Error_Success])

  return(
    <Modal onClose={() => setModalCheck(undefined)} isOpen={ModalCheck?true:false} unstable_ModalBackdropScroll={true}>
      <FocusOnce>
        <ModalHeader>{t('Operations.ModalConf')}</ModalHeader>
      </FocusOnce>
      <ModalBody>
        <Text>
          {ModalCheck===AutopigModal_Types.START?t('SystemBoard.StartTxt'):
          ModalCheck===AutopigModal_Types.RESET?t('SystemBoard.ResetTxt'):
          t('Development.NotDone')}
        </Text>
        {ModalCheck===AutopigModal_Types.START||ModalCheck===AutopigModal_Types.RESET?(
          <View>
            <View style={[Layout.row, {}]}>
              <Labelled_Input disabled={false}
                value={NewAVG+''}
                label={t('SystemBoard.ResetLabelTxt') + t('DataTypes.W_small_T')}
                placeholder={""}
                type='number'
                onChange={(text,num) => {
                  SetNewAVG(num)
                }}
                minWidth={80} maxWidth={150}
                error={(!NewAVG||isNaN(NewAVG)||NewAVG<2000)}
              />
              <View style={[{marginTop:25}]}>
                {!Array.isArray(activeAutopig)||activeAutopig.length===1?(
                <Button maxWidth={90} Contexts={t('SystemBoard.fetchLabel')} Action={(e) => {
                  setScaleWating(true)
                  GetScale({FarmKey:IsDataLink?IsDataLink:undefined, systemids:(Array.isArray(activeAutopig)?activeAutopig[0]:activeAutopig), valve:undefined, isAuto:true})
                }}/>):(<></>)}
              </View>
              {Error_Success!==undefined?(
                <View style={{marginTop:5, marginLeft:10, alignItems:'center', alignSelf:'center', minWidth:80, maxWidth:'max-content'}}>
                  {Error_Success?<Text style={{color:Colors.success}}>{t('SystemBoard.ResetSusTxt')}</Text>:
                  <Text style={{color:Colors.error}}>{t('SystemBoard.ResetErrorTxt')}</Text>}
                </View>
              ):(<></>)}
            </View>
            {ScaleWating&&
              <ActivityIndicator style={[{alignItems:'center', justifyContent:'center', backgroundColor: '#F5FCFF88'}]} size='large' />
            }
          </View>
        ):(<></>)}
      </ModalBody>
      <ModalFooter>
        <ModalButton autoFocus onClick={() => setModalCheck(undefined)}>
          {t('ActionButtons.Cancel')}
        </ModalButton>
        {ModalCheck===AutopigModal_Types.START||ModalCheck===AutopigModal_Types.RESET?(
          <ModalButton autoFocus onClick={() => {
            if (NewAVG&&!isNaN(NewAVG)&&NewAVG>2000) {
              setScaleWating(true)
              onDone(NewAVG)
            }
          }}>
            {t('ActionButtons.Submit_btn')}
          </ModalButton>
        ):(<></>)}
      </ModalFooter>
    </Modal>
  )
}

const System_Module = ({System, Update, IsDataLink, ...rest}: System_Module_Props) => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout, Fonts, Colors } = useTheme()

  const [ModuleStateColor, setModuleStateColor] = useState(Colors.gray)
  const [ModuleStateText, setModuleStateText] = useState(SystemStates.UNKNOWN)
  const [ModuleText, setModuleText] = useState("")
  
  const [isDisablet, setisDisablet] = useState(!System.Online)
  const [PlayorPause, setPlayorPause] = useState(true)

  //#region Autopig Fucntions controls
  const [AutopigFuncPass,setAutopigFuncPass] = useState<string|undefined>(undefined)
  const [AutopigFuncPassErr,setAutopigFuncPassErr] = useState<string|undefined>(undefined)
  const [ModalCheck, setModalCheck] = useState<AutopigModal_Types|undefined>(undefined)
  
  const [ResetScale, ResetResult, lastPromiseInfo] = ConsysApi.Scale.ResetScaleWeight()
  useEffect(() => {
    var ScaleState = ResetResult
    if (ScaleState.isError || ScaleState.isSuccess) {
      if (ScaleState.data) {
        setAutopigFuncPass(t('SystemBoard.ResetSusTxt'))
        console.log("Success", ScaleState.data)
      } else {
        if (ScaleState.isError) {
          setAutopigFuncPassErr(t('SystemBoard.ResetErrorTxt'))
        }
      }
    }
  }, [ResetResult])
  //#endregion

  useEffect(() => {
    if (System.Online) {
      //Could be moved to ex. Config or GetSystemInfo - ConsysModule Translate ??
      let _ModuleText = ""
      switch(System.SystemType) {
        case SystemTypes.BATCHSYS:
          _ModuleText += SystemTypes.BATCHSYS.charAt(0).toUpperCase() + SystemTypes.BATCHSYS.slice(1) + "\n"
          switch(System.SystemMainState) {
            case -1:
              setModuleStateText(SystemStates.FETCHING)
              setModuleStateColor(Colors.gray)

              break
            case 0:
              setModuleStateText(SystemStates.STOPPED)
              setModuleStateColor(Colors.error)

              break
            case 1:
              setModuleStateText(SystemStates.INITIALIZING)
              setModuleStateColor(Colors.IdleBlue)

              break
            case 2:
              setModuleStateText(SystemStates.WORKING)
              setModuleStateColor(Colors.success)
              
              break
            case 11:
            case 12:
              setModuleStateText(SystemStates.ERROR)
              setModuleStateColor(Colors.error)

              break
            default:
              break
          }
          break
        case SystemTypes.AIRSYS:
          _ModuleText += SystemTypes.AIRSYS.charAt(0).toUpperCase() + SystemTypes.AIRSYS.slice(1) + "\n"
          switch(System.SystemMainState) {
            
            default:
              break
          }
          break
        case SystemTypes.AUTOPIG:
          _ModuleText += System.SystemName?System.SystemName:SystemTypes.AIRSYS.charAt(0).toUpperCase() + SystemTypes.AIRSYS.slice(1) + " #" + System.SystemIndex + "\n"
          switch(System.SystemMainState) {
            case -1:
              setModuleStateText(SystemStates.UNKNOWN)
              setModuleStateColor(Colors.gray)

              break
            default:
              break
          }
          break
      }
      _ModuleText += " - Current state id : " + System.SystemMainState
      setModuleText(_ModuleText)
    }
  }, [System])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  var {Id, Name} = useMemo(() => {
    var Id = System.SystemIndex?System.SystemIndex:'-'
    var Name = System.SystemName?System.SystemName:"-"
    return {Id: Id, Name:Name}
  }, [System])

  //console.log("Module", Id, IsDataLink)
  return (
    <View style={[Common.border, {width:'80%', minHeight:80, minWidth:280}]} key={"Module#" + Id}>
      {/* Title and Online state */}
      <View style={[Layout.fullWidth, Layout.row]} key={"StateModule#" + Id}>
        <View style={[Layout.midWidth]}>
          <Button Contexts={`Sys-${Id} ${IsDataLink?"@"+IsDataLink:""}: ${Name}`} Title/>
        </View>
        <TouchableOpacity style={[Layout.quarterWidth,{paddingLeft:10, height:50}]} disabled={true}
          onPress={() => {
            //dispatch(ConsysSystem.fetchinfo({sysId:SysId}))
          }
        }>
          <View style={[Layout.fullWidth, {backgroundColor:System.Online?Colors.success:Colors.error, height:50, borderRadius: 25/2, justifyContent:'center', alignItems:'center'}]}>
            <Text>{System.Online?t('SystemBoard.OnlineLabel'):t('SystemBoard.OfflineLabel')}</Text>
          </View>
        </TouchableOpacity>
      </View>
      {/* Functions and state display */}
      {System.SystemType===SystemTypes.AUTOPIG?(
        <View style={[Layout.fullWidth, Layout.row, {}]} key={"FillModule#" + Id}>
          <View style={[Gutters.smallMargin, {marginLeft:5, width: 80, height: 80, borderRadius: 80/2, justifyContent:'center', alignItems: 'center', boxShadow: "0 0 4px 2px rgba(0,0,0,.2)"}, {backgroundColor:ModuleStateColor}]}>
            <Text style={{}}>{ModuleStateText}</Text>
          </View>
          <View style={[Layout.fill, Gutters.smallH_Padding, {minWidth:80, justifyContent:'flex-start',}]}>
            <Text style={[Gutters.tinyPadding, {height:'-webkit-fill-available', flexWrap:true, outline:`1px #000 solid`, borderRadius:10, boxShadow: "0 0 4px 2px rgba(0,0,0,.2)"}]}>{ModuleText}</Text>
          </View>
          
          <View style={[Layout.fill, Layout.column, {maxWidth:'max-content'}]}>
            <View style={{marginTop:5, marginLeft:10, alignItems:'center', alignSelf:'center', minWidth:80, maxWidth:'max-content'}}>
              {AutopigFuncPass&&<Text style={{color:Colors.success}}>{AutopigFuncPass}</Text>}
              {AutopigFuncPassErr&&<Text style={{color:Colors.error}}>{AutopigFuncPassErr}</Text>}
            </View>
            <View style={[Layout.fill, Layout.row, Gutters.smallMargin, {marginRight:0, minWidth:80, maxHeight:'max-content', flexWrap:'wrap', justifyContent:'flex-end', alignItems:'flex-end'}]}>
              <View style={[Layout.fill, {minWidth:80, maxWidth:'max-content', marginRight:5}]}>
                <Button Contexts={t('SystemBoard.TaraLabel')} Action={() => {
                  setAutopigFuncPass(undefined)
                  setAutopigFuncPassErr(undefined)
                  setModalCheck(AutopigModal_Types.TARA)
                }}/>
              </View>
              <View style={[Layout.fill, {minWidth:80, maxWidth:'max-content', marginRight:5}]}>
                <Button Contexts={t('SystemBoard.CalibrateLable')} Action={() => {
                  setAutopigFuncPass(undefined)
                  setAutopigFuncPassErr(undefined)
                  setModalCheck(AutopigModal_Types.CALIBRATE)
                }}/>
              </View>
              <View style={[Layout.fill, {minWidth:80 , maxWidth:'max-content', marginRight:5}]}>
                <Button Contexts={t('SystemBoard.ResetLabel')} Action={() => {
                  setAutopigFuncPass(undefined)
                  setAutopigFuncPassErr(undefined)
                  setModalCheck(AutopigModal_Types.RESET)
                }}/>
              </View>
            </View>
          </View>
        </View>
      ):(
        <View style={[Layout.fullWidth, Layout.row, {}]} key={"FillModule#" + Id}>
          <View style={[Gutters.smallMargin, {marginLeft:5, width: 80, height: 80, borderRadius: 80/2, justifyContent:'center', alignItems: 'center', boxShadow: "0 0 4px 2px rgba(0,0,0,.2)"}, {backgroundColor:ModuleStateColor}]}>
            <Text style={{}}>{ModuleStateText}</Text>
          </View>
          <View style={[Layout.fill, Gutters.smallH_Padding, {justifyContent:'flex-start',}]}>
            <Text style={[Gutters.tinyPadding, {height:'-webkit-fill-available', flexWrap:true, outline:`1px #000 solid`, borderRadius:10, boxShadow: "0 0 4px 2px rgba(0,0,0,.2)"}]}>{ModuleText}</Text>
          </View>
          <View style={[Layout.row, Gutters.smallMargin, {minWidth:'max-content', justifyContent:'flex-start', alignItems:'flex-end'}]}>
            <TouchableOpacity disabled={isDisablet}
              onPress={() => {
                setPlayorPause(old => !old )
              }}
              style={[{
                backgroundColor: "black", 
                borderColor: 'black', borderRadius: 20, borderStyle: 'solid', borderWidth: 0,
                shadowColor: 'black', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 2,
                alignItems:'center', justifyContent:'center',
                width: 40, height: 40,
              }]}
            >
              <PlayPause Play={PlayorPause} />
            </TouchableOpacity>
            <View style={{width:5}}/>
            <TouchableOpacity disabled={isDisablet}
              onPress={() => {
                //setPlayorPause(old => !old )
              }}
              style={[{
                backgroundColor: "black", 
                borderColor: 'black', borderRadius: 20, borderStyle: 'solid', borderWidth: 0,
                shadowColor: 'black', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 2,
                alignItems:'center', justifyContent:'center',
                width: 40, height: 40,
              }]}
            >
              <Text style={{color:"white"}}>Reset</Text>
            </TouchableOpacity>
          </View>
        </View>
      )}
      {System.SystemType===SystemTypes.AUTOPIG?(
        <AutopigFunction_Modal setModalCheck={(ModalCheck) => setModalCheck(ModalCheck)} ModalCheck={ModalCheck} activeAutopig={Id+''} IsDataLink={IsDataLink} onDone={(NewAVG) => {
          if (ModalCheck === AutopigModal_Types.RESET) {
            if (NewAVG&&!isNaN(NewAVG)&&NewAVG>2000) ResetScale({FarmKey:IsDataLink, systemids:Id+'', weight:NewAVG})
          }
          setModalCheck(undefined)
        }}/>
      ):(<></>)}
    </View>
  )
}

System_Module.defaultProps = {
}

export default System_Module