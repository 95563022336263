import React, { Component, useState } from 'react'
import { Platform, View, Text } from 'react-native'
import { Input, MaskedInput, SIZE } from "baseui/input";
import { Textarea } from "baseui/textarea";
import { useTheme } from '../../Theme'

export interface LabelledInput_Props {
  //children: React.ReactNode,
  Ref?: React.MutableRefObject<null>,

  disabled?:boolean,
  error?:boolean,
  maxWidth?:number,
  minWidth?:number,
  minHeight?:number,

  type?:string,
  endEnhancer?:string

  label?:string, 
  placeholder?:string, 
  mask?:string,
  multiline?:boolean,
  value:string,

  ChangeOnBlur?:boolean,
  onChange:(e:string, num:number) => void,
  onConfirm?:() => void
}

const Labelled_Input = ({ Ref, disabled, error, maxWidth, minWidth, minHeight, type, endEnhancer, label, placeholder, mask, multiline, value, ChangeOnBlur, onChange, onConfirm, ...rest}: LabelledInput_Props) => {
  const { Layout, Gutters, Common, Fonts } = useTheme()
  const [Value, setValue] = useState(value)
  const [UseValue, setUseValue] = useState(false)
  return (
    <View style={[Layout.fill, {maxWidth: maxWidth, minWidth:minWidth, minHeight:minHeight}]}>
      {label&&(
        <View style={[Layout.fill, Gutters.tinyPadding, {paddingBottom:2, justifyContent:'flex-end'}]}>
          <Text style={[{}]}>{label}</Text>
        </View>
      )}
      <View style={[Layout.fill, Common.border, {minHeight:'max-content'}]}>
        {multiline?(
          <Textarea inputRef={Ref?Ref:undefined}
            pattern={mask?mask:undefined}
            value={value}
            placeholder={placeholder?placeholder:""}
            onChange={(e) => {
              error = false
              const text = e.target.value
              onChange(text, NaN)
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && onConfirm) {
                onConfirm()
              }
            }}
            type={type?type:undefined}
            disabled={disabled}
            error={error}
            clearOnEscape
            overrides={{
              Input: {style: ({ }) => ({
                minHeight:minHeight+'px',
                outline: `0px black solid`,
                backgroundColor: "white",
                paddingLeft:'5px', paddingRight:'5px', paddingTop:'5px', paddingBottom:'5px',
              })}
            }}
          />
        ):mask?(
          <MaskedInput inputRef={Ref?Ref:undefined}
            size={SIZE.mini}
            mask={mask}
            value={value}
            placeholder={placeholder?placeholder:""}
            onChange={(e) => {
              error = false
              const text = e.target.value
              onChange(text, parseFloat(text))
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && onConfirm) {
                onConfirm()
              }
            }}
            type={type?type:""}
            endEnhancer={endEnhancer?endEnhancer:""}
            disabled={disabled}
            error={error}
            overrides={{
              Root: { style: ({ }) => ({
                minHeight:minHeight+'px',
                outline: `0px black solid`,
                paddingLeft:'0px', paddingRight:'0px', paddingBottom: "0px", paddingTop: "0px",
                margin:0
              }) },
              InputContainer: { style: ({ }) => ({
                backgroundColor: "#FFF"
              }) }
            }}
          />
        ):(
          <Input inputRef={Ref?Ref:undefined}
            size={SIZE.mini}
            value={UseValue?Value:value}
            placeholder={placeholder?placeholder:""}
            onFocus={(e) => {
              if (ChangeOnBlur) {
                setUseValue(true)
                setValue(value)
              }
            }}
            onBlur={(e) => {
              if (ChangeOnBlur) {
                error = false
                const text = e.target.value
                if (value !== text) {
                  setUseValue(false)
                  onChange(text, parseFloat(text))
                }
              }
            }}
            onChange={(e) => {
              error = false
              const text = e.target.value
              if ((UseValue?Value:value) !== text) {
                setValue(text)
                if (!ChangeOnBlur) onChange(text, parseFloat(text))
              }
            }}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && onConfirm) {
                onConfirm()
              }
            }}
            type={type?type:""}
            endEnhancer={endEnhancer?endEnhancer:""}
            disabled={disabled}
            error={error}
            autoComplete={(type==="password"?"current-password":"")}
            clearOnEscape
            overrides={{
              Root: { style: ({ }) => ({
                outline: `0px black solid`,
                minHeight:minHeight+'px',
                paddingLeft:'0px', paddingRight:'0px', paddingBottom: "0px", paddingTop: "0px"
              }) },
              InputContainer: { style: ({ }) => ({
                //backgroundColor: "#FFF",
                paddingLeft:'0px', paddingRight:'0px', paddingBottom: "0px", paddingTop: "0px"
              })},
              Input: {style: ({ $theme }) => ({ 
                paddingLeft:'5px', paddingRight:'5px', paddingBottom: "5px", paddingTop: "5px"
              })}
            }}
          />
        )}
      </View>
    </View>
  )
}

Labelled_Input.defaultProps = {
  disabled: false,
  error: false,
  maxWidth: 600,
  minWidth: 30,
  minHeight:22,
  multiline:false
}

export default Labelled_Input
