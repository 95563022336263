import { useColorScheme } from 'react-native'
import { useSelector } from 'react-redux'
//import { DarkTheme, DefaultTheme } from '@react-navigation/native'
import Fonts from '../Fonts'
import Gutters from '../Gutters'
import Images from '../Images'
import Layout from '../Layout'
import Common from '../Common'
import * as DefaultVariables from '../Variables'
import themes from '../themes'

interface ThemeContainer {
  Colors?:any, Fonts?:any, FontSize?:any, Gutters?:any, Images?:any, Layout?:any, Common?:any, NavigationColors?:any, NavigationTheme?:any, [arg:string]:any
}
export default function (): ThemeContainer {
  // Get the scheme device
  const colorScheme = useColorScheme()
  // Get current theme from the store
  const currentTheme = useSelector((state:any) => state.theme || 'default')

  //Select the right theme light theme ({} if not exist)
  const { Variables: themeConfigVars = {}, ...themeConfig } = themes[currentTheme] || themes['default'] || {}

  const themeVariables = mergeVariables(
    DefaultVariables,
    themeConfigVars
  )

  // Build the default theme
  var _Layout = Layout()
  var _Gutters = Gutters(themeVariables)
  const baseTheme = {
    Fonts: Fonts(themeVariables),
    Gutters: _Gutters,
    Images: Images(themeVariables),
    Layout: _Layout,
    Common: Common({
      ...themeVariables,
      Layout: _Layout,
      Gutters: _Gutters,
    }),
    ...themeVariables,
  }

  // Merge and return the current Theme
  var ThemeObj = buildTheme(
    baseTheme,
    formatTheme(themeVariables, themeConfig || {})
  )
  //console.log("res", ThemeObj)
  return ThemeObj
}

/**
 * Generate Theme with theme variables
 *
 * @param variables
 * @param theme
 * @return {{}|{[p: string]: *}}
 */
const formatTheme = (variables, theme): {} | { [p: string]: any } => {
  return Object.entries(theme).reduce((acc, [name, generate]:[string,any]) => {
    return {
      ...acc,
      [name]: generate(variables),
    }
  }, {})
}

/**
 * Merge all variables for building the theme
 * baseTheme <- currentTheme <- currentDarkTheme
 *
 * @param variables : {MetricsSizes?: {small: number, large: number, tiny: number, regular: number}, NavigationColors?: {primary: string}, FontSize?: {small: number, large: number, regular: number}, Colors?: {white: string, success: string, text: string, error: string, transparent: string, primary: string}} variables from @Theme/Variables
 * @param themeConfig : currentTheme form @Theme/themes
 * @return {{}|{[p: string]: *}}
 */
const mergeVariables = (variables, themeConfig): {} | { [p: string]: any } =>
  Object.entries(variables).reduce((acc, [group, vars]:[string, any]) => {
    return {
      ...acc,
      [group]: {
        ...vars,
        ...(themeConfig[group] || {})
      },
    }
  }, {})

/**
 * Provide all the theme exposed with useTheme()
 *
 * @param baseTheme
 * @param themeConfig
 * @return {{[p: string]: *, NavigationTheme: {colors}}}
 */
const buildTheme = (baseTheme, themeConfig):{[p: string]: any, NavigationTheme: {colors}} => {
  return {
    ...mergeTheme(baseTheme, themeConfig),
    NavigationTheme: mergeNavigationTheme(
      baseTheme.NavigationColors,
      {}
    )
  }
}

/**
 * Merge theme from baseTheme <- currentTheme
 *
 * @param baseTheme
 * @param theme
 * @return {{[p: string]: *}}
 */
const mergeTheme = (baseTheme, theme): { [p: string]: any } => ({
  ...Object.entries(baseTheme).reduce(
    (acc, [key, value]:[string, any]) => ({
      ...acc,
      [key]: {
        ...value,
        ...(theme[key] || {})
      },
    }),
    {},
  ),
})

/**
 * Merge the React Navigation Theme
 *
 * @param reactNavigationTheme
 * @param overrideColors
 * @return {{colors}}
 */
const mergeNavigationTheme = (reactNavigationTheme, overrideColors):{ [p: string]: any, colors:any} => ({
  ...reactNavigationTheme,
  colors: {
    ...reactNavigationTheme.colors,
    ...overrideColors,
  },
})
