/**
 *
 * @param Theme can be spread like {Colors, NavigationColors, Gutters, Layout, Common, ...args}
 * @return {*}
 */
export default function (args?:any) {
  return {
    Haxa_globe: require('../Assets/Hexagon-globe.png'),
    Hexa_icon: require('../Assets/Hexa-icon.png'),
    Loading: require('../Assets/Loading/Loading_Colour.png'),

    Login_bottom: require('../Assets/frames/login-bottom.png'),
    Login_protein: require('../Assets/frames/login-protein.png'),

    Logo: require('../Assets/Logo.png'),
    
    Home: require('../Assets/menu/Home.png'),
    DashBoard: require('../Assets/menu/DashBoard.png'),
    System: require('../Assets/menu/System.png'),
    Curves: require('../Assets/menu/Feed-Curves.png'),
    GCurves: require('../Assets/menu/Growth-curve.png'),
    Tasks: require('../Assets/menu/Task_Colour.png'),
    Logbook: require('../Assets/menu/Logbook.png'),

    User: require('../Assets/menu/User.png'),
    Sow: require('../Assets/menu/Sow.png'),
    Section: require('../Assets/menu/Section.png'),
    Pens: require('../Assets/menu/Area_Colour.png'),
    AutoPig: require('../Assets/menu/AutoPig.png'),
    Feed: require('../Assets/menu/Feed-Components.png'),
    Calendar: require('../Assets/menu/Calendar.png'),

    Simple_Edit: require('../Assets/actions/Simple-edit.png'),
    Simple_Save: require('../Assets/actions/Simple-save.png'),
    Save: require('../Assets/actions/Save.png'),
    DownLoad_Cloud: require('../Assets/actions/Download_cloud.png'),
    UpLoad_Cloud: require('../Assets/actions/Upload_cloud.png'),

    AlarmBell: require('../Assets/menu/Alarm_bell.png'),
    AlarmRed: require('../Assets/menu/Alarm_bell_red.png'),
    AlarmGreen: require('../Assets/menu/Alarm_bell_green.png'),

    en:require("../Assets/lang/en.png"),
    dk:require("../Assets/lang/dk.png"),
    de:require("../Assets/lang/de.png"),
    es:require("../Assets/lang/es.png"),
    tw:require("../Assets/lang/tw.png"),
    zh:require("../Assets/lang/zh.png"),

    P_P1: require('../Assets/presentation/Picture1.png'),
  }
}
