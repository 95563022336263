import { isDemoFarm, isEmpty, TimeSpan, UserData, CommentData } from '../../Config/Types'
import { useAppSelector, useAppDispatch, RootStates_T } from '../../Store'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { createSelector } from 'reselect'
import get from 'lodash/get'

export const fetchWeighings = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = [], IsDataLink:string|undefined, Pen_Times?:{Pens:number|string[], Times:number[]}, ClosedData?:boolean, LinkListData_Sections?:any, LinkList_Sections?:any):any[] => {
  if (FarmId && FarmId !== "") {
    var path = `farms_data/${IsDataLink?IsDataLink:FarmId}/`
    if (isDemoFarm(FarmId) && !IsDataLink) path = `DemoData/${FarmId}/`
    if (IsDataLink) {
      var _LinkListData_Sections = LinkListData_Sections?LinkListData_Sections:useAppSelector(({firebase: {data}}) => data&&data.LinkListData&&data.LinkListData[IsDataLink]&&data.LinkListData[IsDataLink].sections?data.LinkListData[IsDataLink].sections:null)
      if (_LinkListData_Sections) {
        const _LinkList_Sections = LinkList_Sections?LinkList_Sections:useAppSelector(({firebase: {data}}) => data&&data.LinkList&&data.LinkList[IsDataLink]&&data.LinkList[IsDataLink].sections?data.LinkList[IsDataLink].sections:null)
        if (_LinkList_Sections) {
          Object.entries(_LinkListData_Sections).forEach(([Id, Data]) => {
            if (Data && _LinkList_Sections[Id] && Data.pens) {
              var LinkData_Section = _LinkList_Sections[Id]
              var startTime:undefined|number = undefined
              var endTime:undefined|number= undefined
              if (typeof LinkData_Section !== "boolean") {
                startTime = LinkData_Section.starttime
                endTime = LinkData_Section.endtime
              }

              Data.pens.forEach((PenId:number) => {
                Calls.push({path:path + 'weighings', queryParams: ['orderByChild=penTime', `startAt=${PenId}#${(startTime||0)}`, `endAt=${PenId}#${(endTime||9999999999999)}`], 
                  storeAs: `LinkListData/${(IsDataLink)}/weighings/${PenId}`})
                if (ClosedData) {
                  Calls.push({path:path + 'datas/closed_weighings', queryParams: ['orderByChild=penTime', `startAt=${PenId}#${(startTime||0)}`, `endAt=${PenId}#${(endTime||9999999999999)}`], 
                  storeAs: `LinkListData/${(IsDataLink)}/closed_weighings/${PenId}`})
                }
              })
            }
          })
        }
      }
      //Calls.push({path:path + 'weighings', queryParams: ['orderByChild=time'], storeAs: `LinkListData/${(IsDataLink)}/weighings`})
    }
    else {
      //TODO: each sections pen ... storeas weighings/PenId/
      if (Pen_Times && Pen_Times.Pens && Pen_Times.Times && Pen_Times.Times.length >= 2) {
        var Pens = typeof Pen_Times.Pens === "number"?[Pen_Times.Pens+'']:Pen_Times.Pens
        Pens.forEach(PenId => {
          var startTime = Pen_Times.Times[0]
          var endTime = Pen_Times.Times[1]

          Calls.push({path:path + 'weighings', queryParams: !isDemoFarm(FarmId)?['orderByChild=penTime', `startAt=${PenId}#${Pen_Times.Times[0]}`, `endAt=${PenId}#${Pen_Times.Times[1]}`]:['orderByChild=time'], 
                      storeAs: `weighings/${PenId}`})
                      //storeAs: `weighings`})
          if (ClosedData) {
            Calls.push({path:path + 'datas/closed_weighings', queryParams: !isDemoFarm(FarmId)?['orderByChild=penTime', `startAt=${PenId}#${Pen_Times.Times[0]}`, `endAt=${PenId}#${Pen_Times.Times[1]}`]:['orderByChild=time'], 
                      storeAs: `closed_weighings/${PenId}`})
                      //storeAs: `closed_weighings`})
          }
        })
      }
      else {
        Calls.push({path:path + 'weighings', queryParams: ['orderByChild=time'], storeAs: 'weighings'})
        if (ClosedData) {
          Calls.push({path:path + 'datas/closed_weighings', queryParams: ['orderByChild=time'], storeAs: `closed_weighings`})
        }
      }
    }
  }
  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

export const GetPenWeights = createSelector(
  [
    (state:RootStates_T, IsDataLink:string|undefined, PenId?:number, ClosedData?:boolean) => get(state.activeData, 'farm', undefined),
    (state:RootStates_T, IsDataLink:string|undefined, PenId?:number, ClosedData?:boolean) => ClosedData?get(state.firebase.data, `${IsDataLink?'LinkListData.'+IsDataLink+'.':''}closed_weighings`, undefined):
                                                                                              get(state.firebase.data, `${IsDataLink?'LinkListData.'+IsDataLink+'.':''}weighings`, undefined),
    (state:RootStates_T, IsDataLink:string|undefined, PenId?:number, ClosedData?:boolean) => PenId
  ],
  (FarmId:string|undefined,Weights:Record<string, CommentData[]>|CommentData[], PenId?:number):Record<number, CommentData[]>|CommentData[] => {
    var Results:Record<number, CommentData[]>|CommentData[] = PenId?[]:{}
    if (Weights && !isEmpty(Weights)) {
      let Datas:CommentData[]
      if (isNaN(Object.keys(Weights)[0]) && Weights[Object.keys(Weights)[0]].pen) Datas = Object.entries(Weights).filter(([key,data]) => data && (!PenId||data.pen===PenId)).map(([key,data]) => ({...data, key:key} as CommentData))
      else Datas = Object.entries(Weights).filter(([PenKey,PenWeights]) => PenKey&&!isEmpty(PenWeights)&&(!PenId||PenKey===PenId+'')).reduce((res, WeightDatas) => {
        return res.concat(Object.entries(WeightDatas[1]).map(([key,data]) => ({...data, key:key} as CommentData)))
      }, [] as CommentData[]).flat(1)

      Datas.forEach(element => {
        var _data = element
        if (FarmId && isDemoFarm(FarmId)) {
          if (element && element.penTime && element.penTime.includes('$')) {
            var TimeDatas = element.penTime.split('#')[1].split('$')
            var time = new Date()

            time.setDate(time.getDate()-parseInt(TimeDatas[0]))
            time.setHours(parseInt(TimeDatas[1]))
            time.setMinutes(parseInt(TimeDatas[2]))

            var data = {...element}
            data.time =  time.getTime()
            data.penTime = `${element.pen}#${data.time}`
            _data = data
          }
        }
        if (PenId) Results.push(_data)
        else if (_data.pen) {
          if (!Results[_data.pen]) Results[_data.pen] = []
          Results[_data.pen].push(_data)
        }
      })
    }
    return Results
  }
)

export interface GetFormatWeighings_Props {Weighings_Notes:CommentData[]}
export const GetFormatWeighings = createSelector(
  [
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => get(state.activeData, 'farm', undefined),
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => GetPenWeights(state, IsDataLink, (typeof PenId==="number"?PenId:undefined), false),
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => ClosedData?GetPenWeights(state, IsDataLink, (typeof PenId==="number"?PenId:undefined), true):[],
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => PenId,
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => ClosedData,
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => ViewPeriod
  ],
  (FarmId:string|undefined, Weighings, Closed_Weighings, PenId:number|string[], ClosedData?:boolean, ViewPeriod?:TimeSpan):GetFormatWeighings_Props => {
    var PenWeighings:CommentData[] = []
    if (Weighings || Closed_Weighings) {
      var Pens = typeof PenId === "number"?[PenId+'']:PenId
      PenWeighings = PenWeighings.concat(Pens.map((PenData, Index) => {
        var Combined:CommentData[] = []
        if (Weighings) {
          if (typeof PenId==="number" && Array.isArray(Weighings)) Combined = Combined.concat(Weighings)
          else if (Weighings[PenData]) Combined = Combined.concat(Weighings[PenData])
        }
        if (Closed_Weighings) {
          if (typeof PenId==="number" && Array.isArray(Closed_Weighings)) Combined = Combined.concat(Closed_Weighings)
          else if (Closed_Weighings[PenData]) Combined = Combined.concat(Closed_Weighings[PenData])
        }
        Combined = Combined.filter(Weighing => Weighing && (!ViewPeriod || 
          (ViewPeriod.start && ViewPeriod.start <= Weighing.time) &&
          (!ViewPeriod.end || ViewPeriod.end >= Weighing.time)) &&
          (!Weighing.edited && !Weighing.deleted)
        )
        return Combined
      }).flat(1))
    }
    return {Weighings_Notes:PenWeighings }
  }
)