import React, { useEffect, useMemo, useRef } from 'react'
import { View, Text, StyleSheet, Animated } from 'react-native'
import { useTheme } from '../../Theme'

export interface Progressbar_Props {
  Texts: string,
  Color: string,
  Progress: number
}

const Progressbar = ({Texts="", Color="#8BED4F", Progress=0, ...rest}: Progressbar_Props) => {
  const { Common, Gutters, Layout } = useTheme()

  if (!Color) Color = "#8BED4F"
  let animation = useRef(new Animated.Value(0));

  useEffect(() => {
    Animated.timing(animation.current, {
      toValue: Math.round(!isNaN(Progress)?Progress:0),
      duration: 100,
      useNativeDriver: true
    }).start();
  },[Progress])

  return (
    <View style={[Layout.fullWidth, Common.border, Gutters.tinyBMargin, {borderWidth:0, minHeight:45, maxWidth:600}]}>
      <View style={[Layout.fill, Layout.fullWidth, Layout.colHCenter, {alignContent:'center'}]}>
        {Texts&&Texts!==""&&(
        <Text style={{alignSelf:'flex-start', minHeight:20}}>
          {Texts}
        </Text>
        )}
        <View style={styles.progressBar}>
          <Animated.View style={[StyleSheet.absoluteFill, {backgroundColor: Color, width:animation.current.interpolate({
            inputRange: [0, 100],
            outputRange: ["0%", "100%"],
            extrapolate: "clamp"
          }), borderRadius:5, marginBottom:1, marginLeft:1}]}/>
        </View>
      </View>
    </View>
  )
}

Progressbar.defaultProps = {
  Contexts: [""],

  maxWidth:600,
  Frame: false,
  Title: false,
  Arrows: false,
}

export default Progressbar

const styles = StyleSheet.create({
  progressBar: {
    flexDirection: 'row',
    height: 22,
    width: '100%',
    borderColor: '#000',
    borderWidth: 2,
    borderRadius: 5,
    overflow: 'hidden'
  }
});