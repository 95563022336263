import React, { useEffect, useState } from 'react'
//import { useDispatch, useSelector } from 'react-redux'
import {
  SafeAreaView,
  ScrollView,
  View,
  Text,
} from 'react-native'
import { Frame, Button, Labelled_Input } from '../../Components'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
//import { navigate } from '../../Navigators/Root'

import { useAppSelector } from '../../Store'
import { useFirebase, isLoaded } from 'react-redux-firebase'

const LoginContainer = () => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout, Images, Colors } = useTheme()

  const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector(({firebase}) => firebase.profile)
  
  const EmailRef = React.useRef(null)
  const [Email, setEmail] = useState({text:(profile&&profile.email?profile.email:""),error:false})
  const PassRef = React.useRef(null)
  const [Pass, setPass] = useState({text:"",error:false})
  const [LoginError, setLoginError] = useState("")
  
  const login_Handle = () => firebase.login({email: Email.text.trim(), password: Pass.text.trim()}).then(value => {}, reason =>
    setLoginError(reason.message.replace("Firebase:","").replace((reason.code?"("+reason.code+").":""),"").trim()))
  const resetPass = () => {
    firebase.resetPassword(Email.text.trim())
  }
  //const logout = () => firebase.logout()

  const createNewUser = async () => {
    firebase.createUser({email:Email.text.trim(), password:Pass.text.trim(), signIn:true}, {email:Email.text.trim(), state:"starter"}).then(value => {}, reason => {
      if (reason.code.includes('password')) setPass((old:any) => {return {...old, error:true}})
      else if (reason.code.includes('email'))  setEmail((old:any) => {return {...old, error:true}})
      setLoginError(reason.message.replace("Firebase:","").replace((reason.code?"("+reason.code+").":""),"").trim())
    })
  }
  
  const SubmitAction = () => {
    if (Email && Email.text && Email.text !== "") {
      if (Pass && Pass.text && Pass.text !== "") {
        login_Handle()
      } else if (PassRef && PassRef.current) {
        PassRef.current?.focus()
        setPass((old:any) => {return {...old, error:true}})
      }
    } else if (EmailRef && EmailRef.current) {
      EmailRef.current?.focus()
      setEmail((old:any) => {return {...old, error:true}})
    }
  }

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  return (
    <View style={[Layout.fullFill, Layout.fullSize, Layout.colCenter, Gutters.tinyPadding, Common.bagroundPrimary, { minWidth:300, backgroundColor:'#f2f2f2', zIndex:-999}]}>
      <Frame>
        <ScrollView style={[Layout.fill, Layout.fullSize, {zIndex:2}]} centerContent={true} contentContainerStyle={[Layout.fill, {justifyContent: "center", alignItems: "center", minHeight:'max-content'}]}>
          <View style={[Common.border, Layout.midWidth, Layout.colCenter, Gutters.regularBMargin, Gutters.smallVPadding, {backgroundColor:"white", maxWidth:650}] }>
            <View style={[
              Layout.fill, Gutters.smallVMargin, {width:'90%'}
            ]}>
              {LoginError && LoginError !== ""?(
                <Text style={[{color:Colors.error}]}>{LoginError}</Text>
              ):(<></>)}
              <Labelled_Input Ref={EmailRef}
                label={t('AgrisysConnect.label1')}
                onChange={(text) => {
                  setEmail({text:text,error:false})
                }}
                onConfirm={() => PassRef.current?.focus()}
                disabled={!isLoaded(auth)}
                error={Email.error}
                value={Email.text}
              />
              <Labelled_Input Ref={PassRef}
                label={t('AgrisysConnect.label2')}
                onChange={(text) => {
                  setPass({text:text,error:false})
                }}
                onConfirm={() => SubmitAction()}
                type={"password"}
                disabled={!isLoaded(auth)}
                error={Pass.error}
                value={Pass.text}
              />
            </View>
            <Button Contexts={[t('AgrisysConnect.submit_btn')]} maxWidth={600} EndEnhancers={[Images.User]}
              Action={ele => SubmitAction()}
            />
          </View>
          <View style={[Layout.midWidth, Layout.colCenter, {maxWidth:650}]}>
            <Button Contexts={[t('AgrisysConnect.reset'), t('AgrisysConnect.create')]} 
              Action={ele => {
                switch(ele) {
                  case 0:
                    if (Email && Email.text && Email.text !== "") {
                      resetPass()
                    } else if (EmailRef && EmailRef.current) {
                      EmailRef.current?.focus()
                      setEmail((old:any) => {return {...old, error:true}})
                    }
                    break
                  case 1:
                    if (Email && Email.text && Email.text !== "") {
                      if (Pass && Pass.text && Pass.text !== "") {
                        createNewUser()
                      } else if (PassRef && PassRef.current) {
                        PassRef.current?.focus()
                        setPass((old:any) => {return {...old, error:true}})
                      }
                    } else if (EmailRef && EmailRef.current) {
                      EmailRef.current?.focus()
                      setEmail((old:any) => {return {...old, error:true}})
                    }
                    break
                }
              }} 
            />
          </View>
        </ScrollView>
      </Frame>
    </View>
  )
}

export default LoginContainer
