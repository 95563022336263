import React, { useEffect, useState } from 'react'
//import { useDispatch, useSelector } from 'react-redux'
import {
  View,
} from 'react-native'
import { LoadingSplash } from '../../Components'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded, isEmpty as FBEmpty, useFirebaseConnect } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'
import { setStates } from '../../Store/StateHandlers/Active-slice'

import { UserStates, UserData, isEmpty } from '../../Config/Types'
import LoginContainer from './UserLogin'
import UserInformation from '../UserPanel/UserInformation'
import { ConsysApi } from '../../Services/Consys/Consys'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
} from 'baseui/modal';

const IndexAuthContainer = (MainProps:any) => {
  const MainParams = MainProps.route.params?MainProps.route.params:undefined
  const { Common, Gutters, Layout } = useTheme()
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector<UserData>(({firebase}) => firebase.profile)

  //const activeData = useAppSelector(state => state.activeData)
  const activefarm = useAppSelector<string|undefined>(state => state.activeData.farm)
  const WorkersList = useAppSelector<string|null>(({firebase: {data}}) => data.WorkersList)

  //Get user farms
  useFirebaseConnect(() => {
    let FarmCalls: any[] = []
    if (isLoaded(profile) && !FBEmpty(profile)) {
      var path = `farms/`
      var farm = undefined
      if (profile.state === UserStates.ADMIN || profile.state === UserStates.TECH) {
        //FarmCalls.push({path:path, storeAs: 'WorkerFarms'})
      }
      else {
        if (profile.state === UserStates.WORKER) {
          FarmCalls.push({path:`WorkersList/${auth.uid}`, storeAs: 'WorkersList'})
        }
        if (!isEmpty(profile.farms)) {
          farm = Object.keys(profile.farms)[0]
          /*Object.keys(profile.farms).forEach((farm: string, index: number) => {
            FarmCalls.push({path:path + `${farm}`, storeAs: 'WorkerFarms'})
          })*/
        } else if (!isEmpty(WorkersList)) {
          farm = Object.keys(WorkersList)[0]
        }
        if (farm) { 
          dispatch(setStates({state:"farm", data:farm}))
        }
      }
    }
    return FarmCalls
  })

  const [ModalText, setModalText] = React.useState<null|{header:string, text:string}>(null)

  const logout_Handle = () => {
    firebase.logout()
    dispatch({type: actionTypes.CLEAR_DATA})
    dispatch(ConsysApi.util.resetApiState())
    //preserve: { data: ['todos'], ordered: ['todos'] }
  }

  useEffect(() => {
    if (!FBEmpty(auth) && FBEmpty(profile)) {
      if (isLoaded(profile)) navigate('Auth', {screen: 'Registration'})
    } else if (!FBEmpty(auth) && !FBEmpty(profile)) {
      if (profile.state) {
        switch(profile.state) {
          case UserStates.ADMIN:
          case UserStates.TECH:
            if (profile && (activefarm || !isEmpty(profile.farms))) {
              navigate('User', {screen: 'Home'})
            } 
            else navigate('Admin', {screen: 'Home'})
            break
          case UserStates.WORKER:
          case UserStates.HORNSYLD:
          case UserStates.OWNER:
          case UserStates.FREE:
            if (profile && (activefarm || !isEmpty(profile.farms))) {
              setModalText(null)
              navigate('User', {screen: 'Home'})
            } else {
              if (profile.state === UserStates.WORKER) setModalText({text:'Please ask a farm owner to assign you', header:'Not assigned, or no data'})
              else {
                //User not created farm yet
                navigate('Auth', {screen: 'Registration'})
              }
            }
            break
          case UserStates.STARTER:
          case UserStates.CONTACT:
            navigate('Auth', {screen: 'Registration'})
            break
          default:
            logout_Handle()
            break
        }
      } 
      else logout_Handle()
    }
  }, [auth, profile, activefarm])
  
  useEffect(() => {
    if (MainParams?.options && MainParams.options === "Mobile") dispatch(setStates({state:"IsAppV", data:true}))
    return () => {
      // clean up
    }
  },[])

  if (!isLoaded(auth) || !isLoaded(profile)) return <LoadingSplash/>
  else {
    console.log("authindex", MainProps)
    return (
    <View style={[Layout.fullFill, Layout.fullSize, Layout.colCenter, Gutters.tinyPadding, Common.bagroundPrimary, { minWidth:300, backgroundColor:'#f2f2f2', zIndex:-999}]}>
      {profile && profile.state === UserStates.WORKER?(
        <UserInformation {...Object.assign(MainProps, { MainNavi: true })}/>
      ):(
        <LoginContainer/>
      )}
      <Modal onClose={() => setModalText(null)} isOpen={ModalText?true:false} unstable_ModalBackdropScroll={true}>
        <FocusOnce>
          <ModalHeader>{ModalText?.header}</ModalHeader>
        </FocusOnce>
        <ModalBody>
          {ModalText?.text}
        </ModalBody>
        <ModalFooter>
          <ModalButton autoFocus onClick={() => setModalText(null)}>
            {t('ActionButtons.Submit_btn')}
          </ModalButton>
        </ModalFooter>
      </Modal>
    </View>
    )
  }
}

export default IndexAuthContainer
