import React, { useEffect, useRef } from 'react'
import { View, } from 'react-native'
import { Operations as Operations_Component } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'

//import { useAppSelector } from '../../Store'
//import { FarmData, PenData, WeighingData, isEmpty, ConsysRefs } from '../../Config/Types'
//import { FirebaseCaller, Pens as FBPens, Weighings, Graphs } from '../../Services/Firebase'

//import * as Appdata from '../../Services/Firebase/'
//import { DataCaller } from '../../utils/DataCallers'
//import { useAppSelector } from '../../Store'
//import { Sections } from '../../Services/Firebase'
import { OperationsPropHandle } from '../../Components/GeneralUse/Operations'

const Operations = () => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout } = useTheme()
  //const dispatch = useDispatch()
  //const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  //const farms = useAppSelector<{[key:string]:FarmData}>(({firebase: { data }}) => data?.farms)
  //const activefarm = useAppSelector<string|undefined>(({activeData}) => activeData?.farm)
  //const farmData = useMemo<FarmData|undefined>(() => farms&&activefarm&&farms[activefarm]?farms[activefarm]:undefined, [farms,activefarm])
  
  const OpRef = useRef<OperationsPropHandle>(null)

  /*useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])*/

  return(
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <Operations_Component ref={OpRef} />
    </View>
  )
}

export default Operations