export default {
  SplashText: "AgrisysApp,蛋白質生產自動化",
  AgrisysFront:{
    Welcome: '歡迎到Agrisys App',
    Menu1: 'Agrisys雲端',
    Menu2: '連接到裝置',
    Menu3: '版本'
  },

  AgrisysConnect: {
    NavTitle: '使用者登入',
    label1: '電子郵件信箱:',
    label2: '密碼:',
    submit_btn: '登入',

    reset: '送出密碼重設',
    create: '創建新使用者'
  },

  AgrisysUser: {
    reVerify:'重送驗證',
    Refresh:'刷新',

    label1: '電子信箱:',
    label2: '密碼:',
    submit_btn: '創建使用者',

    Logout: '登出'
  },

  AgrisysAdmin: {
    NavTitle: '管理者控制台',
    Welcome: '歡迎: {{name}}',
    Userboard: '使用指白板',
    Systems: '系統',
    Tools: '工具',
    Logout: '登出'
  },

  AdminSystems:{
    Name:"名稱",
    Manager:"經理",
    Email:"電子信箱",

    Selected:"牧場-標籤",
    Consys:"Consys",
    Panel:"牧場控制台"
  },
  
  AgrisysMenu:{
    NavTitle: '使用者控制台',
    Welcome: '歡迎: {{name}}',
    SystemBoard: '系統白板',
    DashBoard: '儀表板',
    Pens: '欄',
    Batchs: '批次',
    Reports: '報告',
    Growth: '生長曲線',
    Operations: '操作',
    Autofunctions: '資料連結',
    FeedingFunctions: '餵飼',
    Pigs: '豬',
    Settings: '設定',
    Logout: '登出'
  },

  SystemBoard: {
    StartTxt: `目前的動作是一個新的開始記號.\n你要重設Autopig資料並重新開始平均重量嗎?\n如果不是,未來的資料將失真,因為這裡沒有正確重設.`,
    ResetTxt: `目前動作將重設Autopig資料,\n所有資料仍將可被取得.\n雖然若是沒有正確重設,未來資料將會失真.\n你確定你要這樣做嗎?`,
    
    ResetLabel: '重設平均重量 ',
    fetchLabel: '擷取重量',
    
    ResetErrorTxt: "無法重設",
    ResetSusTxt: "完成重設"
  },

  DashBoard:{
    Title: '牧場描述',
    Prop1: '牧場名稱',
    Prop2: '經理',
    Prop3: '電子信箱',
    Prop4: '電話',
    
    CapacityTxt: '牧場容量 {{current}} of {{full}}',
    PerformanceTxt: '牧場表現',

    DistriTitle: '分佈:',
    BatchesTitle: '批次:',
    BatchLabel: '{{name}} 起始日期: {{Sdate}} 終點預測: {{Edate}},\n{{pigs}} 豬重: {{weight}}',

    FarmInfo_btn: '牧場資訊',
    UserInfo_btn: '使用者資訊',
    FarmSettings_btn: '牧場設定',
    ClearData: '清除本機儲存資料'
  },

  DataEditor:{
    Title: '編輯',
    Options1_1: "欄",
    Options2_1: "新增",
    Options2_2: "刪除",
    New_Sec: "新區間",
    Multiple:'多重: ',

    Prop1:'最低數字',
    Prop2:'最高數字',
    Prop3:'欄前名',
    Prop4:'欄後名',
    Prop5:'區間名',
    Prop6:'新欄名',
    ValDirection: '最高數字必須高於最低數字',
    CannotNon: '不能移除不存在欄',
    Pen_Size: '欄大小',
    GrowthCurve:'成長曲線:'
  },

  FarmInfo:{
    Title: '牧場資訊',
    Prop1:"牧場名稱",
    Prop2:"地址",
    Prop3:"國家",
    Prop4:"統一編號",
    Prop5:"經理",
    Prop6:"電子信箱",
    Prop7:"欄位設計大小",
    Prop8:"電話",
    Copy:"拷貝牧場識別碼到剪貼板",
    Create:"創建牧場",
    Save:"儲存資訊",
  },

  UserInfo:{
    Title: '使用者資訊',
    Prop1:"名",
    Prop2:"姓",
    Prop3:"電子信箱",
    Prop4:"電話",
    Prop5:"地址",
    Prop6:"國家",
    Copy:"拷貝使用者識別碼到剪貼板",
    Save:"儲存資訊",
  },

  FarmSettings:{
    Title: '牧場設定',
    Prop1:"欄設計大小",
    PensSettingsTitle: "欄設定",
    PenPrefix: "欄字首",
    Pendefault: "欄＿",
    TreatmentsTitle: "處置",
    TreatmentProp1:"碼",
    TreatmentProp2:"名稱",
    TreatmentProp3:"死亡",
    TreatmentProp4:"使用",
    WorkerTitle: "作業人員 / 使用者清單",
    WorkerProp1:'使用者代碼',
    WorkerProp2:'電子信箱',
    WorkerProp3:'設定',
    UserAllowTitle:"允許使用者清單",
    SilonKompTitle:"散裝桶與零件",
    NedapTitle:"Nedap連接",
    NedapAdd:"增加安裝",
  },

  Subscription:{
    Plan:"訂閱計畫",
    Staff:'牧場職員',
    StaffDesc:'允許設定現有牧場職員存取',
    Owner:'牧場場主',
    OwnerDesc:'允許管理自有牧場',
    Manager:'牧場經理',
    ManagerDesc:'允許管理自有牧場',
    Contact:'取得聯絡者',
    ContactDesc:'我們將會聯絡你,提供你報價.',

    Free:'免費',
    PriceManager:'9999.99€',

    submit_btn: '送出',

    //Errors
    NoType:'請選擇訂閱型態',
    NotAvaible: '這個選項尚無法選擇',
  },

  Pens:{
    Options1:'格局 ',
    Options1_1:'區段',
    Options1_2:'批次',
    ViewDate:'檢視日期',

    Options2:'顯示:',
    Options2_1:'重量',
    Options2_7:'成長',
    Options2_2:'動物計數',
    Options2_3:'日齡',
    Options2_4:'飲水量',
    Options2_5:'採食量',
    Options2_6:'處理紀錄',
  },

  Pen_Display:{
    Title: '欄顯示',
    Prop1: '豬隻計數',
    Prop2: '批次',
    Prop3: '批次日齡',
    Prop3_2: '欄起始日',
    Prop4: '均重',
    Prop5: '每日每頭豬平均成長',
    Prop6: '成長曲線',
    Prop7: '警告 最小-最大',
    Prop7Data: '{{min}}% - {{max}}%',
    Size: '欄大小',
    
    Prop8: '最後餵飼時間',
    Prop8Time: '{{date, DD-MM-YYYY HH:mm}}',
    Prop9: '一份飼料(公斤)',

    Updateview_btn:'更新視圖',

    SiloLog_Title:'散裝桶日誌',
    DailyFeedLog_Title:'每日餵飼日誌',
    ControlWeights_Title: '重量控制',
    WeightTime: '{{date, DD-MM-YYYY HH:mm}}',
    WeightTimeAction: '有處置: {{date, DD-MM-YYYY}}',
    WeightData: '{{weight}} kg : {{count}} 豬頭數',

    OperationLog_Title: '處置日誌',
    OperationTime: '{{date, DD-MM-YYYY HH:mm}}',
    NoteData: '欄位紀錄: \n{{note}}',
    OperationNote: '{{note}}',
    OperationData: '{{count}} * {{action}} of {{weight}} kg \n{{note}}',
    OperationDataCode: '#{{code}} - {{codename}}\n{{count}} * {{action}} of {{weight}} kg\n{{note}}',
  },

  Autopigs:{
    Title:'Autopigs',
    New:'新 AutoPig',
    AutopigId:'Autopig 系統識別號',
    SelectPens:'可用-欄',
    SelectSections:'可用-區間',
    
    CorTitle:'走道秤',
    CorridorId:'走道秤識別號(以逗號分開)',
    ConIdError:'Consys 無法識別 {{ConsysId}} 為一個有效地識別碼.',
    AutoPigName: 'Autopig 代號名稱',

    FeedTitle:'餵飼系統連接',
    NewFeedCon:'新餵飼系統',
    FeedId:'餵飼系統識別碼',

    DataTitle:'牧場資料分享',
    NewDataCon:'新資料分享',
    FarmId:'牧場識別碼',
    From:'自',
    To:'到',

    Edit:'編輯',
    Prop1:'Autopig 代號名稱',
    Prop2:'Consys識別代號',
    Prop3:'秤',
    Prop4:'處置紀錄',
    Prop5:'溫度',
    Prop6:'水',
    Prop7:'餵飼',
    Prop8:'餵飼(閥門識別碼)',

    NewConnection:'新連接',
    ConnectionName:'連接名稱',

    listcol1:'ID',
    listcol2:'姓名',
    listcol3:'類型',
    listcol4:'共享/當地的',
    listcol5:'行動'
  },

  Autopigs_Display:{
    Title: "Autopig顯示",
    DaysLabel:'顯示日齡',
    NewestLabel: "最新的標籤",
  },

  Reports: {
    New:'新報告',
    Edit:'編輯報告',
    Combined:'合併',
    listcol1: '名稱',
    listcol2: '開始日期',
    listcol3: '結束日期',
    listcol4: '存檔的',
    listcol5: '- 動作 -',
  },

  Reports_Editor: {
    Title: '標題',

    Deletelabel: '刪除報告',
    Copylabel: '複製報告',
    ReportName: '報告名稱',
    GrowthCurve:'成長曲線:',
    SelectPens:'可選擇欄',
    SelectAutos:'可選擇 - Autopig\'s', //雲端智能豬秤',
    SelectReps:'可選擇報告',
  },

  Report_Display: {
    Title: "顯示報告",

    Added: '添加豬',
    Deaths: '死亡',
    Sold: '賣出豬',

    NoWeight: '沒有找到控制豬重資料',
    NoFeeding: '沒有找到餵飼資料',
    Total: '總和',

    StartD:'開始日期:',
  },

  Graphs:{
    New: '新曲線',

    CalcLabel: '計算基於:',
    Name: '曲線名稱',
    Day: '日數',
    Weight: '重量',
    Growth: '成長',
    NewPoint: '新數值',
    EValue: '數值錯誤',
    NameError: '曲線模板需要名稱',
    IncrementError: '曲線模板需要遞增: 日齡錯誤: {{I1}} - {{I2}}'
  },

  Operations:{
    Weight: '重量',
    AddPen: '增加欄',
    RemovePen: '移除欄',
    EditPens: '增加或移除欄',

    Section:'區段:',
    Pen:'欄:',
    Data:'欄資料',
    Pigs:'豬:',

    Note: '註記',
    AddPig: '添加豬',
    MovePig: '移動豬',
    Treatment: '處置',
    SoldPig: '售出豬',
    ClosePen: '關閉欄',

    NoteTitle: '欄註記:',
    OperationTitle: '處理說明:',
    Quantity: "數量:",
    AvgWeight: "平均重量:",
    CorridorBtn: "走道秤",
    CorridorRes: "走道秤重結果",
    Internal: '內部註記',
    PeriodeTitle: '觀察週期:',
    day:'天數',

    ModalConf:'請確認動作',
    CloseDate:'結束日期',
    ClearWarn:'目前動作將清除資料到歷史紀錄.\n所有資料仍然能在報告中看到,\n你確定要清除資料嗎?',
    RepCreate:'由欄創建報告'
  },

  CommentModule:{
    NotePlaceholder: '註記內容..',
  },

  GraphViewer:{
    X_DateFormat_Time: '{{date, DD-MM-YYYY HH:mm}}',
    X_DateFormat: '{{date, DD-MM-YYYY}}',

    CountFormat:'豬計數',
    WeightFormat:'豬重(Kg)',
    CountToWeight:'計數: {{count}}\n重量: {{weight}}kg',

    InfoTitle:'曲線視圖詳細資訊',
    InfoName:'資料名稱',
    InfoValue:'數值: y軸',
    InfoAdd:'附錄',

    Weight:'重量',
    ByTag:'按标签',
    Growth:'成長',
    Feed:'餵飼',
    Operation:'處置紀錄',
    Water:'飲水',
    Temp:'溫度'
  },

  Weighings:{
    Title: '秤重',
    EditWeights: '編輯重量',
    DeleteWeights: '刪除重量',
    WeightData: "重量日期",
    Quantity: "動物數量",
    Weight: "總重",
    NoReply:'無重量回覆',
    CorridorScale: "走道秤",
    Fetch: "讀取重量",
    ModalConf:'請確認操作',
    AddOperations:'你想為這個重量動作做一個筆開始操作嗎？\n這將除了重量之外,還提交一個添加豬,對於給定的盒子和數量',
  },

  FeedList:{
    Col1:'閥門號碼.',
    Col2:'散裝桶',
    Col3:'總重Kg',
    Col4:'時間',
  },

  OperationList:{
    Col1:'時間',
    Col2:'欄位號',
    Col3:'主要處置',
    Col4:'次要處置',
    Col5:'資料',
    Action1:'編輯'
  },

  WeightList:{
    Col1_1:'欄',
    Col1_2:'總天數',
    Col1_3:'輸入重量',
    Col1_4:'輸出重量',
    Col1_5:'每日成長',

    Col2_1:'日期',
    Col2_2:'豬數',
    Col2_3:'重量Kg',
    Col2_4:'每頭豬重量',
    Col2_5:'每頭豬單位重量成長',
    Col2_6:'每頭豬每日平均增重',
  },

  DataTypes: {
    Degrees:"°C",
    W:"Kg.",
    W_T:"公斤",
    W_small:"g.",
    W_small_T:"公克",
    F:"L.",
    F_T:"公升",
    F_small:"ml.",
    F_small_T:'毫升',
  },

  ActionButtons:{
    Add:'添加',
    Remove:'移除',
    Back:'退回',
    Save:'儲存',
    SaveAll:'保留全部',
    Done:'完成',
    Cancel:'取消',
    Logout: '登出',
    Submit_btn: '送出',
    OK: 'OK'
  },

  Errors: {
    PropMissing:'需要 {{Prop}}',
    PropInvalid:'{{Prop}} 包含不合法字元',

    PropExists:'{{Prop}} 已經存在',
    PropNon:'{{Prop}} 不存在',

    PropNan:'{{Prop}} 不能由數字開頭',
    PropNum:'{{Prop}} 必須是有效數字',

    PassMissing:'請輸入密碼',
    EmailMissing:'請輸入電子信箱',
  },

  Development: {
    NotDone:'元件未完成',
  },

  example: {
    helloUser: 'I am a fake user, my name is {{name}}',
    labels: {
      userId: 'Enter a user id',
    },
  }
}
