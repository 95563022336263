import React, { useEffect, useState, useMemo } from 'react'
import {
  View,
  SafeAreaView,
  Text
} from 'react-native'
import { Button, CurveViewer, Labelled_Input } from '../../Components'
import { Input } from "baseui/input";

import { DataSet, DataTemplate, PointSet } from '../../Components/GeneralUse/CurveViewer'
import { UnixDayTime, CurvePoint } from '../../Config/Types';

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { FirebaseCaller, Graphs} from '../../Services/Firebase'
import { useFirebase } from 'react-redux-firebase'

import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import {
  TableBuilder,
  TableBuilderColumn
} from 'baseui/table-semantic'
import { SORT_DIRECTIONS } from 'baseui/data-table';

interface CurveEditor_Props {
  New?: boolean,
  Selected?: string
}
enum UpdatingType {
  DAY = 'Day',
  WEIGHT = 'Weight',
  GROWTH = 'Growth',
}
interface UpdatingInput_Prop {
  maxWidth?:number, 
  index:number, 
  Err?:boolean, 
  placeholder:UpdatingType, 
  data:string, 
  update?:(name: string, val:string) => void,
  step?:number
}
const UpdatingInput = ({maxWidth=80, index, Err=false, placeholder, data, update, step=1}:UpdatingInput_Prop) => {
  //const { t } = useTranslation()
  //const { Common, Layout, Gutters } = useTheme()
  const [Value, setValue] = useState('')
  useEffect(() => {
    setValue(data)
  },[data])

  return(
    <Input error={Err} readOnly={!update||((placeholder===UpdatingType.DAY||placeholder===UpdatingType.GROWTH)&&index===0)}
      value={Value}
      onChange={(e:any) => {
        setValue(e.target.value +'')
      }}
      placeholder={placeholder}
      type="number"
      step={step}
      onBlur={(e: any) => {
        if (update) update(data, Value)
      }}
      overrides={{
        Root: {style: ({}) => ({
          outline: `0px black solid`,
          minHeight: "15px",
          maxWidth: maxWidth+'px',
          minWidth: '43px',
          paddingLeft:'0px', paddingRight:'0px', paddingBottom: "0px", paddingTop: "0px"
        })},
        InputContainer: {style: ({}) => ({
          backgroundColor: "#FFF",
          paddingLeft:'0px', paddingRight:'0px', paddingBottom: "0px", paddingTop: "0px"
        })},
        Input: {style: ({ $theme }) => ({
          paddingLeft:'5px', paddingRight:'5px', paddingBottom: "2px", paddingTop: "2px"
        })}
      }}
    />
  )
}

const GrowthCurves_Editor = (MainProps:any) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  const MainParams:CurveEditor_Props = MainProps.route.params?MainProps.route.params:undefined

  const firebase = useFirebase()
  const activefarm = useAppSelector(state => state.activeData.farm)

  const [_GraphId, setGraphId] = useState(MainParams.Selected?MainParams.Selected:"") 
  const GraphsDatas = useAppSelector(state => Graphs.GetPenWeightGraph(state, undefined, undefined), undefined)

  const [DataPoints, setDataPoints] = useState<(CurvePoint)[]>([{ 
    day: 0, weight: 6
    },{
    day: 1, weight: 18
  }])
  const [GrowthBased, setGrowthBased] = useState(true)
  const CalcValue_Changes = (name:string, val:string, index:number, isGrowthVal=false) => {
    if (name !== val) {
      var newVal:number = val!==""?parseFloat(val):0
      if (isGrowthVal) newVal = newVal / 1000
      if (GrowthBased) {
        var newDiff = 0
        if (isGrowthVal) {
          setDataPoints(data => data.map((row, i) => {
            var res = ({...row})
            if (i===index) {
              newDiff =  ((DataPoints[i-1].weight||0) + newVal) - (DataPoints[i].weight||0)
              res.weight = Math.round(((DataPoints[i].weight||0) + newDiff)*1000)/1000
            }
            else if (i > index) {
              res.weight = Math.round(((row.weight||0) + newDiff)*1000)/1000
            }
            return res
          }))
        } else {
          setDataPoints(data => data.map((row, i) => {
            var res = ({...row})
            if (i===index) newDiff = newVal - row.weight
            if (i >= index) res.weight = Math.round(((row.weight||0) + newDiff)*1000)/1000
            return res
          }))
        }
      } else {
        setDataPoints(data => data.map((row, i) => {
          if (isGrowthVal) {
            return ({...row,
              weight: i===index?(DataPoints[i-1].weight||0)+ newVal:(row.weight||0)
            })
          } else {
            return ({...row,
              weight: i===index?newVal:(row.weight||0)
            })
          }
        }))
      }
    }
  }

  const Sorter = (DataValues:any) => {
    return DataValues.slice().sort((left:any, right:any) => {
      const leftValue = String(left['day'])
      const rightValue = String(right['day'])
      return leftValue.localeCompare(rightValue, "en", {
        numeric: true,
        sensitivity: "base"
      })
    })
  }
  const sortedData = useMemo(() => Sorter(DataPoints), [DataPoints])
  const [Errors, setErrors] = useState<number[]>([])

  const NameRef = React.useRef(null)
  const [NameError, setNameError] = useState<boolean>(false)

  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    if (!isLoaded && !MainParams.New && _GraphId && GraphsDatas && GraphsDatas[_GraphId]) {
      var Curve = GraphsDatas[_GraphId]
      if (Curve) {
        var data = Object.entries(Curve.data).map(([day, weight]) => ({day:parseInt(day),weight:parseFloat(weight)} as CurvePoint))
        setDataPoints(data)
        setIsLoaded(true)
      }
    }
  }, [_GraphId, GraphsDatas])

  const Save_New = () => {
    if (!Errors.length && _GraphId != "" ) {
      if (MainParams.New && GraphsDatas && GraphsDatas[_GraphId]) {
        setNameError(true)
        if (NameRef && NameRef.current) NameRef.current?.focus()
        //debugger
      }else {
        var Datas = sortedData.reduce((ini,datas)=>(ini[datas.day]=datas.weight,ini), [])
        firebase.set(`farms_data/${activefarm}/graph/${_GraphId}`, {data: Datas})
        navigate('User', {screen:'Home'})
      }
    } else {
      setNameError(true)
      if (NameRef && NameRef.current) NameRef.current?.focus()
    }
  }

  return(
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <View style={[Layout.colVCenter, Layout.fullWidth]}>
        {MainParams.New?(
          <Labelled_Input Ref={NameRef}
            value={_GraphId} // todo... option
            label={t('Graphs.Name')}
            placeholder={t('Graphs.Name')}
            onChange={(text) => {
              setNameError(false)
              setGraphId(text)
            }}
            minWidth={250}
            error={NameError}
          />
        ):(<Button Contexts={_GraphId} Title/>)}
        <CurveViewer zeroIndex={true} DatePicker={false} NoLabels maxheight={500} Primary={{left:'Weight', right:undefined}}  Disables={['All'] } 
          DataView={[{color:'#000', name:MainParams.New?'DataView':_GraphId, type:'Line', form:'Weight', data:sortedData.map((data:any) => ({x: data.day * UnixDayTime, y:data.weight} as PointSet)) as DataTemplate} as DataSet]}
        />
        <View style={[Common.border, Gutters.smallH_Margin, Gutters.regularV_Margin, {minWidth:'min-content', maxWidth:'602px', minHeight:330, maxHeight:430}]}>
          <View style={[Layout.fullWidth, Layout.row, {}]}>
            <Text style={[{width:'25%', alignSelf:'center', fontSize:16, fontWeight:'500'}]}>{t('Graphs.CalcLabel')}</Text>
            <Checkbox labelPlacement={LABEL_PLACEMENT.left}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center", justifyContent:'center',
                  width:'37%', minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={!GrowthBased}
              onChange={(e:any) => setGrowthBased(false)}
            ><Text style={[{fontSize:16, fontWeight:'500'}]}>{t('Graphs.Weight')}</Text></Checkbox>
            <Checkbox labelPlacement={LABEL_PLACEMENT.left}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center", justifyContent:'center',
                  width:'37%', minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={GrowthBased}
              onChange={(e:any) => setGrowthBased(true)}
            ><Text style={[{fontSize:16, fontWeight:'500'}]}>{t('Graphs.Growth')}</Text></Checkbox>
          </View>
          <SafeAreaView style={[Layout.fullWidth, {margin:0, minWidth:'min-content', maxWidth:'600px', minHeight:300, maxHeight:400, justifyContent:'space-between'}]}>
            <TableBuilder divider={"grid"}
              sortOrder={SORT_DIRECTIONS.DESC} sortColumn={"id"}
              data={sortedData}
              overrides={{
                TableBodyRow: {style: ({$theme, $rowIndex}) => ({
                  backgroundColor:$rowIndex % 2 ? $theme.colors.backgroundPrimary : $theme.colors.backgroundSecondary,
                  ':hover': { backgroundColor: $theme.colors.backgroundTertiary}
                })},
                TableHeadCell: {style: ({ $theme }) => ({
                  paddingTop: "10px", paddingBottom: "10px"
                })},
                TableBodyCell: {style: ({ $theme }) => ({
                  paddingTop: "8px", paddingBottom: "8px"
                })}
              }}>
              <TableBuilderColumn sortable id='Day' numeric header={t('Graphs.Day')}>
                {(item:CurvePoint, index) => index!==undefined?(
                  <UpdatingInput index={index} Err={Errors.includes(index)} placeholder={UpdatingType.DAY} data={item.day +''} update={(name, val) => {
                    if (name !== val) {
                      if (!DataPoints.some(e => (e.day+''===val))) {
                        if (Errors.includes(index))setErrors(old => old.filter(e => e!==index))
                        setDataPoints(data => {
                          return Sorter(data.map(row => ({
                            ...row,
                            day: String(row.day) === name ? parseFloat(val) : row.day
                          })))
                        })
                      } else {
                        setErrors(old => {
                          let _new = [...old]
                          _new.push(index)
                          return _new
                        })
                      }
                    } else setErrors(old => old.filter(e => e!==index))
                  }} />
                ):(<></>)
                }
              </TableBuilderColumn>
              <TableBuilderColumn sortable id='Weight' numeric header={t('Graphs.Weight') + " " + t('DataTypes.W')}>
                {(item:CurvePoint, index) => index!==undefined?(
                  <UpdatingInput maxWidth={160} index={index} placeholder={UpdatingType.WEIGHT} data={item.weight +''} update={(name, val) => CalcValue_Changes(name, val, index)} />
                ):(<></>)}
              </TableBuilderColumn>
              <TableBuilderColumn sortable id='Growth' numeric header={t('Graphs.Growth') + " " + t('DataTypes.W_small')}>
                {(item, index)=> index!==undefined?(
                  <UpdatingInput maxWidth={160} index={index} placeholder={UpdatingType.GROWTH} step={100} data={(index>0?(
                    (Math.round((sortedData[index].weight-sortedData[index-1].weight)*1000))
                  ):0)+''} update={(name, val) => CalcValue_Changes(name, val, index, true)}/>
                ):(<></>)}
              </TableBuilderColumn>
            </TableBuilder>
            <Button Contexts={t('Graphs.NewPoint')} Action={()=>{
              var _DataPoints = [ ...sortedData ];
              var value = sortedData[sortedData.length-1]
              _DataPoints.push({day: parseInt(value.day) +1, weight: value.weight +1})
              setDataPoints(_DataPoints)
            }}/>
          </SafeAreaView>
        </View>
        <Button Contexts={[t('ActionButtons.Save')]} Frame Action={(e)=>Save_New()}/>
      </View>
    </View>
  )
}

export default GrowthCurves_Editor