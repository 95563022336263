export default {
  SplashText: 'AgrisysApp, protein automation technology.',
  AgrisysFront:{
    Welcome: 'Welcome to Agrisys App',
    Menu1: 'Agrisys Cloud',
    Menu2: 'Connect to Device',
    Menu3: 'Mobil Version'
  },

  AgrisysConnect: {
    NavTitle: 'User Login',
    label1: 'Email:',
    label2: 'Password:',
    submit_btn: 'Login',

    reset: 'Send reset password',
    create: 'Create New User'
  },

  AgrisysUser: {
    reVerify:'Resend verification',
    Refresh:'Refresh',

    label1: 'Email:',
    label2: 'Password:',
    submit_btn: 'Create User',

    Logout: 'Log Out'
  },

  AgrisysAdmin: {
    NavTitle: 'Admin Panel',
    Welcome: 'Welcome: {{name}}',
    Userboard: 'Userboard',
    Systems: 'Systems',
    Tools: 'Tools',
    Logout: 'Log Out'
  },

  AdminSystems:{
    Name:"Name",
    Manager:"Manager",
    Email:"E-mail",

    Selected:"Farm-Tag",
    Consys:"Consys",
    Panel:"Farm Panel"
  },
  
  AgrisysMenu:{
    NavTitle: 'User Panel',
    Welcome: 'Welcome: {{name}}',
    SystemBoard: 'Systemboard',
    DashBoard: 'Dashboard',
    Pens: 'Pens',
    Batchs: 'Batchs',
    Reports: 'Reports',
    Growth: 'Growth curves',
    Operations: 'Operations',
    Autofunctions: 'Data Connections',
    FeedingFunctions: 'Feeding',
    Pigs: 'Pigs',
    Settings: 'Settings',
    Presentation:'Consys - Demo',
    Logout: 'Log Out'
  },

  SystemBoard: {
    StartTxt: `The current Action is a new starting mark.\nDo you want to reset autopig's data for a new average weight ??\nIf not, furture data could be distorded, as reset is incorrect.`,
    ResetTxt: `The current Action will reset this autopig's data,\nall data will still be avaible.\nThough furture data could be distorded if reset is incorrect.\nAre you sure you want to do this ??`,
    FetchTxt: `More than one scale is available please confirm the wanted scale`,
    ResetLabelTxt: 'Reset to average weight ',
    
    ResetLabel: 'Reset',
    fetchLabel: 'Fetch weight',
    TaraLabel: 'Tara',
    CalibrateLable: 'Clibrate',
    OnlineLabel: 'Online',
    OfflineLabel: 'Offline',

    ResetErrorTxt: "Could not reset.",
    ResetSusTxt: "Reset complete"
  },

  DashBoard:{
    Title: 'Farm description',
    Prop1: 'Farm Name',
    Prop2: 'Manager',
    Prop3: 'E-Mail',
    Prop4: 'Phone',
    
    CapacityTxt: 'Farm Capacity {{current}} of {{full}}',
    PerformanceTxt: 'Farm performance',

    DistriTitle: 'Distribution:',
    BatchesTitle: 'Batches:',
    BatchLabel: '{{name}} Startdate: {{Sdate}} End forecast: {{Edate}},\n{{pigs}} pigs, weight: {{weight}}',

    FarmInfo_btn: 'Farm Information',
    UserInfo_btn: 'User Information',
    FarmSettings_btn: 'Farm Settings',
    ClearData: 'Clear local datastore'
  },

  DataEditor:{
    Title: 'Editor',
    Options1_1: "Pens",
    Options2_1: "Add",
    Options2_2: "Delete",
    New_Sec: "New Section",
    Multiple:'Multiple: ',

    Prop1:'Lowest number',
    Prop2:'Higest number',
    Prop3:'Pre Pen name',
    Prop4:'Post Pen name',
    Prop5:'Section Name',
    Prop6:'New pen name',
    ValDirection: 'Higest number have to be a higher value than Lowest number',
    CannotNon: 'Cannot remove non-existing pen',
    Pen_Size: 'Pen Size',
    GrowthCurve:'Growth curve:'
  },

  FarmInfo:{
    Title: 'Farm Information',
    Prop1:"Farm name",
    Prop2:"Address",
    Prop3:"Country",
    Prop4:"Vat-number",
    Prop5:"Manager",
    Prop6:"E-mail",
    Prop7:"Pen design size",
    Prop8:"Phone",
    Copy:"Copy Farm-id to clipboard",
    Create:"Create Farm",
    Save:"Save Information",
  },

  UserInfo:{
    Title: 'User Information',
    Prop1:"First Name",
    Prop2:"Last Name",
    Prop3:"E-Mail",
    Prop4:"Phone",
    Prop5:"Address",
    Prop6:"Country",
    Copy:"Copy User-id to clipboard",
    Save:"Save Information",
  },

  FarmSettings:{
    Title: 'Farm Settings',
    Prop1:"Pen design size",
    PensSettingsTitle: "Pen Settings",
    PenPrefix: "Pen-prefix:",
    Pendefault: "Pen_",
    TreatmentsTitle: "Treatments",
    TreatmentProp1:"Code",
    TreatmentProp2:"Name",
    TreatmentProp3:"Death",
    TreatmentProp4:"Use",
    WorkerTitle: "Workers / User List",
    WorkerProp1:'User Code',
    WorkerProp2:'Email',
    WorkerProp3:'Settings',
    UserAllowTitle:"UserAllowList",
    SilonKompTitle:"Silo´s and Komponent´s",
    NedapTitle:"Nedap Connect",
    NedapAdd:"Add Installation",
  },

  Subscription:{
    Plan:"Subscription plan",
    Staff:'Farm Staff',
    StaffDesc:'Access to assign as staff of an existing farm',
    Owner:'Farm Owner',
    OwnerDesc:'Access to manage own farm',
    Manager:'Farm Manager',
    ManagerDesc:'Access to manage own farm, and assign staff',
    Contact:'Get contacted',
    ContactDesc:'We will contact you, and tell more about our offers',

    Free:'Free',
    PriceManager:'9999.99€',

    submit_btn: 'Submit',

    //Errors
    NoType:'Please choose a subscription type.',
    NotAvaible: 'This option is not available yet.',
  },

  Pens:{
    Options1:'Layout as: ',
    Options1_1:'Sections',
    Options1_2:'Batch',
    ViewDate:'View Date',

    Options2:'Display:',
    Options2_1:'Weights',
    Options2_7:'Growth',
    Options2_2:'Animal Counts',
    Options2_3:'Age days',
    Options2_4:'Water Consumption',
    Options2_5:'Feed Consumption',
    Options2_6:'Activity',
  },

  Pen_Display:{
    Title: 'Pen Display',
    Prop1: 'Pig count',
    Prop2: 'Batch',
    Prop3: 'Batch Age Days',
    Prop3_2: 'Pen start day',
    Prop4: 'Average weight',
    Prop5: 'Average growth pr pig a day',
    Prop6: 'Growth Curve',
    Prop7: 'Alarm Min-Max',
    Prop7Data: '{{min}}% - {{max}}%',
    Size: 'Pen Size',
    
    Prop8: 'Last feed time',
    Prop8Time: '{{date, DD-MM-YYYY HH:mm}}',
    Prop9: 'Portion in kg.',

    Updateview_btn:'Update View',

    SiloLog_Title:'Silo Log',
    DailyFeedLog_Title:'Daily Feed Log',
    ControlWeights_Title: 'Control weighings',
    WeightTime: '{{date, DD-MM-YYYY HH:mm}}',
    WeightTimeAction: 'Is Operation: {{date, DD-MM-YYYY}}',
    WeightData: '{{weight}} kg : {{count}} pigs',

    OperationLog_Title: 'Operation Log',
    OperationTime: '{{date, DD-MM-YYYY HH:mm}}',
    NoteData: 'Pen Note: \n{{note}}',
    OperationNote: '{{note}}',
    OperationData: '{{count}} * {{action}} of {{weight}} kg \n{{note}}',
    OperationDataCode: '#{{code}} - {{codename}}\n{{count}} * {{action}} of {{weight}} kg\n{{note}}',
  },

  Autopigs:{
    Title:'Autopigs',
    New:'New AutoPig',
    AutopigId:'Autopig SysId',
    SelectPens:'Available - Pens',
    SelectSections:'Available - Sections',
    
    CorTitle:'Corridor scales',
    CorridorId:'Corridor Scale Id\'s (Separated by comma)',
    ConIdError:'Consys does not recognize {{ConsysId}} as a valid Id.',
    AutoPigName: 'Autopig Name',

    FeedTitle:'Feed Connections',
    NewFeedCon:'New Feed Connection',
    FeedId:'Feed SysId',

    DataTitle:'The farm\'s data sharing',
    NewDataCon:'New data sharing',
    FarmId:'Farm id',
    From:'Data from',
    To:'Data to',

    Edit:'Edit Settings',
    Prop1:'Autopig Name',
    Prop2:'Consys-Id',
    Prop3:'Scale',
    Prop4:'Activity',
    Prop5:'Temp',
    Prop6:'Water',
    Prop7:'Feed',
    Prop8:'Tewe-Feed \n(Valve Id)',

    NewConnection:'New Connection',
    ConnectionName:'Connection name',

    listcol1:'ID',
    listcol2:'Name',
    listcol3:'Type',
    listcol4:'Shared/Local',
    listcol5:'Actions'
  },

  Autopigs_Display:{
    Title: "Autopig Display",
    DaysLabel:'Days in View',
    NewestLabel: "Newest Weight",
  },

  Reports: {
    New:'New Report',
    Edit:'Edit Reports',
    Combined:'Open in Combined view',
    listcol1: 'Name',
    listcol2: 'Start-Date',
    listcol3: 'End-Date',
    listcol4: 'Archived',
    listcol5: '- Actions -',
  },

  Reports_Editor: {
    Title: 'Report Editor',

    Deletelabel: 'Delete Report',
    Copylabel: 'Copy Report',
    ReportName: 'Report Name',
    GrowthCurve:'Growth curve:',
    SelectPens:'Available - Pens',
    SelectAutos:'Available - Autopigs',
    SelectReps:'Available - Reports',
  },

  Report_Display: {
    Title: "Report Display",

    Added: 'Added Pigs',
    Deaths: 'Deaths',
    Sold: 'Sold Pigs',

    NoWeight: 'No Control weighings data have been found.',
    NoFeeding: 'No feeding data have been found.',
    Total: 'Total',

    StartD:'Start date:',
  },

  Graphs:{
    New: 'New Curve',

    CalcLabel: 'Calculate based on:',
    Name: 'Curve Name',
    Day: 'Day',
    Weight: 'Weight',
    Growth: 'Growth',
    NewPoint: 'New Point',
    EValue: 'Value error',
    NameError: 'Curve template need a name.',
    IncrementError: 'Curve template needs to be incremental: error at day: {{I1}} - {{I2}}'
  },

  Operations:{
    Weight: 'Manual Weighing',
    AddPen: 'Add Pen',
    RemovePen: 'Remove Pen',
    EditPens: 'Add or Remove Pen',

    Section:'Section:',
    Pen:'Pen:',
    Data:'PenData',
    Pigs:'Pigs:',

    Note: 'Note',
    AddPig: 'Add Pig',
    MovePig: 'Move Pig',
    Treatment: 'Treatment',
    SoldPig: 'Sold Pig',
    ClosePen: 'Close Pen',

    NoteTitle: 'Pen Note:',
    OperationTitle: 'Operations comment:',
    Quantity: "Quantity:",
    AvgWeight: "Average weight:",
    CorridorBtn: "Corridor Scale",
    CorridorRes: "Corridor Result",
    Internal: 'Internal note',
    PeriodeTitle: 'View Periode:',
    day:'day',

    ModalConf:'Please confirm action',
    AutosResetTxt: 'The autopigs effected are: ',
    StartDate:'Start Date',
    CloseDate:'Close Date',
    ClearWarn:'The current Action will clear this pen\'s data to historic.\nAll data will still be available in Report display,\nare you sure you want to do this ??',
    RepCreate:'Create Report from pen'
  },

  CommentModule:{
    NotePlaceholder: 'Note context..',
  },

  GraphViewer:{
    X_DateFormat_Time: '{{date, DD-MM-YYYY HH:mm}}',
    X_DateFormat: '{{date, DD-MM-YYYY}}',

    CountFormat:'Pig count',
    WeightFormat:'Weight Kg.',
    CountToWeight:'Count: {{count}}\nWeight: {{weight}}kg',

    InfoTitle:'CurveView info Details',
    InfoName:'Data name',
    InfoValue:'Value: y-axis',
    InfoAdd:'Appendix',
    ShowTip:'Show Tooltip',

    PredictionTxt: 'Prediction',
    NoteLabel:'Note/Operation',
    Weight:'Weight',
    ByTag:'ByTag',
    Growth:'Growth',
    Scale:'Scale',
    Feed:'Feed',
    FCR:'FCR',
    Activity:'Activity',
    WaterPrPig:'WaterPrPig',
    Water:'Water',
    Temp:'Temp'
  },

  Weighings:{
    Title: 'Weighing',
    EditWeights: 'Edit Weights:',
    DeleteWeights: 'Delete Weights:',
    WeightData: "Weight data:",
    Quantity: "Animal quantity",
    Weight: "Total weight:",
    Committed: "Weight have been Committed",
    NoReply:'Consys did not reply with a weight',
    CorridorScale: "Corridor Scales",
    Fetch: "Fetch Weight",
    ModalConf:'Please confirm action',
    AddOperations:'Do you wanna make a pen start operation for this weight action ?\nThis will besides the weight, also commit a Add pigs, for the given box and quantity.',
  },

  FeedList:{
    Col1:'Valve nr.',
    Col2:'Silo',
    Col3:'Amount Kg',
    Col4:'Time',
  },

  OperationList:{
    Col1:'Time',
    Col2:'Pen nr.',
    Col3:'Main Operation',
    Col4:'Sub Operation',
    Col5:'Data',
    Action1:'Edit'
  },

  WeightList:{
    Col1_1:'Pen',
    Col1_2:'Total Days',
    Col1_3:'Input weight',
    Col1_4:'Output weight',
    Col1_5:'Growth pr Day',

    Col2_1:'Date',
    Col2_2:'Pig count',
    Col2_3:'Weight Kg',
    Col2_4:'Kg pr. pig',
    Col2_5:'Growth pr. pig, pr. weight',
    Col2_6:'Average Growth pr. pig, a day',
  },

  DataTypes: {
    Degrees:"°C",
    W:"Kg.",
    W_T:"Kilo",
    W_small:"g.",
    W_small_T:"gram",
    F:"L.",
    F_T:"Liter",
    F_small:"ml.",
    F_small_T:'Milliliter',
  },

  ActionButtons:{
    Add:'Add',
    Remove:'Remove',
    Back:'Back',
    Save:'Save',
    SaveAll:'Save All',
    Done:'Done',
    Cancel:'Cancel',
    Logout: 'Log Out',
    Submit_btn: 'Submit',
    Retry_btn: 'Retry',
    OK: 'OK'
  },

  Errors: {
    PropMissing:'{{Prop}} is needed',
    PropInvalid:'{{Prop}} contains invalid characters',

    PropExists:'{{Prop}} already exists',
    PropNon:'{{Prop}} does not exist',

    PropNan:'{{Prop}} cannot be or start with a number',
    PropNum:'{{Prop}} have to be a valid number',

    PassMissing:'Please provide a Password',
    EmailMissing:'Please provide an Email',
  },

  Development: {
    NotDone:'Element not done',
  },

  example: {
    helloUser: 'I am a fake user, my name is {{name}}',
    labels: {
      userId: 'Enter a user id',
    },
  }
}
