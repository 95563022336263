import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  Text,
  ScrollView,
  Dimensions,
  Image
} from 'react-native'
import { Brand, Button, ContentProp, LoadingSplash, Progressbar } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../../Store'

import {
  AspectRatioBox,
  AspectRatioBoxBody,
} from 'baseui/aspect-ratio-box';

import {
  Tabs,
  Tab,
  StyledTabList,
  StyledTabPanel,
  ORIENTATION
} from "baseui/tabs-motion";
import {Grid, Cell} from 'baseui/layout-grid';

const TabsOverrides = {
  TabList: {
    component: function TabsListOverride(props) {
      return (
        <View style={[{width:200, }]}>
          <StyledTabList style={{width:195}}  {...props} />
        </View>
      );
    },
  },
};

const TabOverrides = {
  TabPanel: {
    component: function TabPanelOverride(props) {
      return (
        <View style={[{width:0}]}>
          <StyledTabPanel {...props} />
        </View>
      );
    },
  },
};

const Presentation = () => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout, Colors, Images } = useTheme()
  //const dispatch = useDispatch()
  //const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  //const farms = useAppSelector<{[key:string]:FarmData}>(({firebase: { data }}) => data?.farms)
  //const activefarm = useAppSelector<string|undefined>(({activeData}) => activeData?.farm)
  //const farmData = useMemo<FarmData|undefined>(() => farms&&activefarm&&farms[activefarm]?farms[activefarm]:undefined, [farms,activefarm])

  const [MainKey, setMainKey] = useState<string|number>(0);
  const [SubKey, setSubKey] = useState<string|number>(0);

  const [WindowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWindowWidth(window.width)
    });
    return () => subscription?.remove()
  })

  useEffect(() => {
    //dispatch(Appdata.Sections.fetchSections({Type:'ALL'}))
    return () => {
      // Clean-Up
    }
  },[])

  const Context = useMemo(() => {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
        {MainKey+''==='0'?
          <View style={[Layout.fill, Layout.colVCenter, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
            <Image style={{width:'100%', height: 680, resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture1.png')}
            />
          </View>
        :MainKey+''==='1'?
          <View style={[Layout.fill, Layout.colVCenter, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
            <Image style={{width:'100%', height: 327, resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture2.png')}
            />
            <View style={{height:20}} />
            <Image style={{width:'100%', height: 150, maxWidth:'95%', resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture2_Text.png')}
            />
          </View>
        :MainKey+''==='2'?
          <View style={[Layout.fill, Layout.colVCenter, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
            <Image style={{width:'100%', height: 620, resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture3.png')}
            />
          </View>
        :MainKey+''==='3'?
          <View style={[Layout.fill, Layout.colVCenter, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
            <Image style={{width:'100%', height: 325, resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture4.png')}
            />
          </View>
        :MainKey+''==='4'?
          <View style={[Layout.fill, Layout.colVCenter, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
            <Image style={{width:'100%', height: 327, resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture5.png')}
            />
          </View>
        :MainKey+''==='5'?
          <View style={[Layout.fill, Layout.colVCenter, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
            <Image style={{width:'100%', height: 520, resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture6.png')}
            />
          </View>
        :MainKey+''==='6'?
          <View style={[Layout.fill, Layout.colVCenter, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
            <Image style={{width:'100%', height: 420, resizeMode:'center'}}
              source={require('../../Assets/presentation/Picture7.png')}
            />
          </View>
        :<></>}
      </View>
    )
  }, [MainKey])

  if (!auth || !auth.uid) return <LoadingSplash/>
  return(
    <View style={[Layout.fill, Layout.row, Common.backgroundPrimary, {minHeight:'max-content', width:'-webkit-fill-available'}]}>
      <Tabs overrides={TabsOverrides} orientation={ORIENTATION.vertical}
          activeKey={MainKey}
          onChange={({activeKey}) => {
            setMainKey(activeKey)
            setSubKey(0)
          }}
        >
          <Tab overrides={TabOverrides} title="Farm Overview"></Tab>
          <Tab overrides={TabOverrides} title="Valve info"></Tab>
          <Tab overrides={TabOverrides} title="Feeding Information"></Tab>
          <Tab overrides={TabOverrides} title="Feeding Overview"></Tab>
          <Tab overrides={TabOverrides} title="Alarm handling"></Tab>
          <Tab overrides={TabOverrides} title="Silo empty handling"></Tab>
          <Tab overrides={TabOverrides} title="Soft Error"></Tab>
        </Tabs>
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        {Context}
      </View>
      {/*<View style={[{ position:'absolute', top:"70px", right:"2%", width:"300px", minHeight:'max-content', display:'block'}]}>
        <Brand width={'100%'}/>
      </View>*/}
      <View style={[Common.backgroundPrimary, { position:'absolute', bottom:"2%", left:"2%", width:"300px", minHeight:'max-content', display:'block'}]}>
        <Brand width={'100%'}/>
      </View>
    </View>
  )
}

export default Presentation