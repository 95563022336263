import React, { useState } from 'react'
import { View, Image, Text, TouchableOpacity } from 'react-native'
import { Combobox, SIZE } from "baseui/combobox"
import { useTheme } from '../../Theme'

import { Labelled_Input } from '../'

export interface ContentProp_Props {
  //children: React.ReactNode,
  Ref?: React.MutableRefObject<null>,
  
  Columns: number,
  Datas: string[]|any[],
  Actions: Function[],

  Title: string,
  Frame: boolean,
  Backcolor: boolean,
  colorStamp: (string|null)[],
  maxWidth: number,
}

const ContentProp = ({ Columns, Datas, Actions, Title, maxWidth, Frame, colorStamp, Backcolor, ...rest}: ContentProp_Props) => {
  const { Common, Layout, Images, Colors } = useTheme()
  
  const [EditView, setEditView] = useState<any>({})
  const [selectstates, setselectstates] = useState<any>({})
  
  return (
    <View style={[Layout.fullWidth, { maxWidth: maxWidth }]}>
      {/* Title element */}
      <View style={[Layout.fullWidth, Frame?Common.border:{}]}>
        {Datas.map((Data, row) => {
          var _Columns = []
          for (let column = 0; column < Columns; column++) {
            //Get Action & Context for specified element [row][column]
            var Action = Actions[column]?Actions[column]:Actions[0]
            var Context = (typeof Data[column] === "object" && Data[column].value)?Data[column].value:Data[column]

            // If Edit is active and element is editable before save (Editable precentation)
            if (EditView[row+"_"+column] && ((Data[column].values && Data[column].edit === 2) || Data[column].edit === 3)) {
              if (!selectstates[row +"_"+ column] && selectstates[row +"_"+ column] !== "") {
                setselectstates((oldData:any) => {
                  var _new = {...oldData}
                  _new[row+"_"+column] = Context
                  return _new
                })
              }
              //Editable precentation
              _Columns[column] = (
                <View key={"data_" + row + "_" + column} style={[Layout.fill, Layout.fullWidth, Layout.row]}>
                  {Data[column].edit === 2?(
                    <View style={[Layout.fill, Common.border, {minHeight:'max-content', backgroundColor:Colors.backgray}]}>
                      <Combobox
                        size={SIZE.mini}
                        value={selectstates[row +"_"+ column]}
                        onChange={(nextValue:string)  => {
                          setselectstates((oldData:any) => {
                            var _new = {...oldData}
                            _new[row+"_"+column] = nextValue
                            return _new
                          })
                        }}
                        options={
                          Data[column].values.map((Value:string) => ({label:Value, id:row +"_"+ column}) )
                        }
                        mapOptionToString={option => option.label}
                        overrides={{
                          Root: {style: ({ $theme }) => ({
                            height:25,
                            width: 120
                          })}
                        }}
                      />
                    </View>
                  ):Data[column].edit === 3?(
                    <Labelled_Input
                      onChange={text => {
                        if (text) {
                          if (parseInt(text).toString() === text) {
                            setselectstates((oldData:any) => {
                              var _new = {...oldData}
                              _new[row+"_"+column] = text
                              return _new
                            })
                          }
                        } else {
                          setselectstates((oldData:any) => {
                            var _new = {...oldData}
                            _new[row+"_"+column] = text
                            return _new
                          })
                        }
                      }}
                      value={selectstates[row +"_"+ column]}
                    />
                  ):(<></>)}
                  <TouchableOpacity style={[Layout.fill, Layout.colCenter, Common.border, {width:28, height:28, maxWidth:28, alignSelf:'center'}, Backcolor?{backgroundColor:Colors.AgriLightblue}:{backgroundColor:Colors.backgray}]} onPress={() => {
                    setEditView((oldData:any) => {
                      var _new = {...oldData}
                      _new[row+"_"+column] = false
                      return _new
                    })

                    if (Action) Action({data: selectstates[row+"_"+column], row:row, column:column})
                  }}>
                    <Image style={[Layout.fill, Layout.fullSize]} source={Images.UpLoad_Cloud} resizeMode={'contain'} />
                  </TouchableOpacity>
                </View>
              )
            } else {
              // Normal precentation
              _Columns[column] = (
                <View key={"data_" + row + "_" + column} style={[Layout.fill, Layout.fullWidth, Layout.row, {height:'100%'}]}>
                  <TouchableOpacity style={[Layout.fill, Layout.fullWidth, Layout.colCenter, Common.border, {alignSelf:'stretch'}, Backcolor?{backgroundColor:Colors.AgriDarkblue}:{backgroundColor:Colors.backgray}]} onLongPress={() => {
                    if (Action && typeof Data[column] !== "object") {
                      Action({data: Data, row:row, column:column})
                    }
                  }}>
                    <View style={[Layout.row, Layout.fullWidth, {alignItems:'center', justifyContent:'center'}]}>
                      {colorStamp&&colorStamp[column]&&
                      <View style={[{width:15, alignItems:'center', marginRight:8}]}>
                        <View style={[{width: 15, height: 15, borderRadius: 15/2, margin:5, justifyContent:'center', alignItems: 'center'}, {backgroundColor:colorStamp[column]}]}>
                        </View>
                      </View>}
                      <Text style={Backcolor?{color:Colors.secountText}:{}} selectable>{Context}</Text>
                    </View>
                  </TouchableOpacity>
                  {typeof Data[column] === "object" && Data[column].edit 
                  /* Append if editable */ &&(
                    <TouchableOpacity style={[Layout.fill, Layout.colCenter, Common.border, {width:28, height:28, maxWidth:28}, Backcolor?{backgroundColor:Colors.AgriLightblue}:{backgroundColor:Colors.backgray}]} onPress={() => {
                      //if Element contains Editable precentation set view
                      if (Data[column].values && Data[column].edit === 2 || Data[column].edit === 3) {
                        setEditView((oldData:any) => {
                          var _new = {...oldData}
                          _new[row+"_"+column] = true
                          return _new
                        })
                      }
                      else if (Action) {
                        //else do action.
                        Action({data: Data[column].value, row:row, column:column})
                      }
                    }}>
                      <Image style={[Layout.fill, Layout.fullSize]} source={Images.Simple_Edit} resizeMode={'contain'} />
                    </TouchableOpacity>
                  )}
                  {typeof Data[column] === "object" && Data[column].CSV_ABLE
                  /* Append if CSV Download */ &&(
                    <TouchableOpacity style={[Layout.fill, Layout.colCenter, Common.border, {width:28, height:28, maxWidth:28}, Backcolor?{backgroundColor:Colors.AgriLightblue}:{backgroundColor:Colors.backgray}]} onPress={() => {
                      //if Element contains Editable precentation set view
                      if (Action) {
                        //else do action.
                        Action({data: Data[column].value, row:row, column:column})
                      }
                    }}>
                      <Image style={[Layout.fill, Layout.fullSize]} source={Data[column].CSV_ABLE?Images.Save:Images.Simple_Edit} resizeMode={'contain'} />
                    </TouchableOpacity>
                  )}
                </View>
              )
            }
          }
          return (
            <View key={"row"+row} style={[Layout.fill, Layout.row, {alignItems:'center'}]}>
              { _Columns }
            </View>
          )
        })}
      </View>
    </View>
  )
}

ContentProp.defaultProps = {
  Columns: 2,
  Datas: [['','']],
  Actions: [()=>{}],
  Title: null,
  maxWidth: 600,
  Frame: false,
  colorStamp: [],
  Backcolor: false
}

export default ContentProp
