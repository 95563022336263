import React, { useEffect } from 'react'
import { SafeAreaView, View, TouchableOpacity } from 'react-native'
import { useTheme } from '../Theme'
import { UserStates, UserData, isEmpty } from '../Config/Types'
import { RegistrationContainer } from '../Containers'
import LoginContainer from '../Containers/Auth/UserLogin'
import UserInformation from '../Containers/UserPanel/UserInformation'

import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useTranslation } from 'react-i18next'
import { ArrowLeft } from "baseui/icon";

import { navigate, navigateAndSimpleReset, navigateBack, useisAppView, useNavigatorOnLine } from '../Navigators/Root'
import { useFirebase, isLoaded, isEmpty as FBEmpty, useFirebaseConnect } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'

import { useAppSelector, useAppDispatch } from '../Store'
import { setStates } from '../Store/StateHandlers/Active-slice'
import { ConsysApi } from '../Services/Consys/Consys'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  FocusOnce,
} from 'baseui/modal';

const AuthStack = createNativeStackNavigator()

// @refresh reset
const AuthNavigator = (MainProps) => {
  const MainParams = MainProps.route.params?MainProps.route.params:undefined
  const { t } = useTranslation()
  const { Common, Gutters, Layout, NavigationColors } = useTheme()
  const isAppView = useisAppView()
  //const isOnline = useNavigatorOnLine()
  const IsQuickApp = useAppSelector(({startup}) => startup.IsQuickApp)

  const dispatch = useAppDispatch()
  const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector<UserData>(({firebase}) => firebase.profile)

  //const activeData = useAppSelector(state => state.activeData)
  const activefarm = useAppSelector<string|undefined>(({activeData}) => activeData?.farm)
  const WorkersList = useAppSelector<any|undefined>(({firebase: {data}}) => data?.WorkersList)

  //Get user farms
  useFirebaseConnect(() => {
    let FarmCalls: any[] = []
    if (isLoaded(profile) && !FBEmpty(profile)) {
      //var path = `farms/`
      var farm:string|undefined = undefined
      if (profile.state === UserStates.ADMIN || profile.state === UserStates.TECH) {
        //FarmCalls.push({path:path, storeAs: 'WorkerFarms'})
      }
      else {
        if (profile.state === UserStates.WORKER) {
          FarmCalls.push({path:`WorkersList/${auth.uid}`, storeAs: 'WorkersList'})
        }
        if (profile.farms && !isEmpty(profile.farms)) {
          farm = Object.keys(profile.farms)[0]
          /*Object.keys(profile.farms).forEach((farm: string, index: number) => {
            FarmCalls.push({path:path + `${farm}`, storeAs: 'WorkerFarms'})
          })*/
        } else if (WorkersList && !isEmpty(WorkersList)) {
          farm = Object.keys(WorkersList)[0]
        }
        if (farm) { 
          dispatch(setStates({state:"farm", data:farm}))
        }
      }
    }
    return FarmCalls
  })

  const [ModalText, setModalText] = React.useState<null|{header:string, text:string}>(null)
  const logout_Handle = () => {
    firebase.logout()
    dispatch({type: actionTypes.CLEAR_DATA})
    dispatch(ConsysApi.util.resetApiState())
    //preserve: { data: ['todos'], ordered: ['todos'] }
  }

  useEffect(() => {
    if (!FBEmpty(auth) && FBEmpty(profile)) {
      if (isLoaded(profile)) navigate('Auth', {screen: 'Registration'})
    } else if (!FBEmpty(auth) && !FBEmpty(profile)) {
      if (profile.state) {
        switch(profile.state) {
          case UserStates.ADMIN:
          case UserStates.TECH:
            if (profile && (activefarm || !isEmpty(profile.farms))) {
              navigateBack()
              if (IsQuickApp) navigate('User', {screen: 'Operations'})
              else navigate('User', {screen: 'Home'})
            } 
            else navigate('Admin', {screen: 'Home'})
            break
          case UserStates.WORKER:
          case UserStates.HORNSYLD:
          case UserStates.OWNER:
          case UserStates.FREE:
            if (profile && (activefarm || !isEmpty(profile.farms))) {
              setModalText(null)
              navigateBack()
              if (IsQuickApp) navigate('User', {screen: 'Operations'})
              else navigate('User', {screen: 'Home'})
            } else {
              if (profile.state === UserStates.WORKER) setModalText({text:'Please ask a farm owner to assign you', header:'Not assigned, or no data'})
              else {
                //User not created farm yet
                navigate('Auth', {screen: 'Registration'})
              }
            }
            break
          case UserStates.STARTER:
          case UserStates.CONTACT:
            navigate('Auth', {screen: 'Registration'})
            break
          default:
            logout_Handle()
            break
        }
      } 
      else logout_Handle()
    }
  }, [auth, profile, activefarm])
  
  useEffect(() => {
    if (isAppView) dispatch(setStates({state:"IsAppV", data:true}))
    return () => {
      // clean up
    }
  },[])

  //NavigationColors.card => backgroundColor
  return (
    <SafeAreaView style={[Layout.fullFill, { backgroundColor: NavigationColors.card }]}>
      <AuthStack.Navigator initialRouteName={"Home"}
        screenOptions={{
          title: 'AgrisysApp',
          headerTintColor: 'white',
          headerTitleAlign: 'center',
          presentation:"modal"
      }}>
        <AuthStack.Group navigationKey={auth?'Home':undefined}
          screenOptions={({ navigation }) => ({
            headerStyle: { backgroundColor: NavigationColors.card },
          })}
        >
          <AuthStack.Screen name="Home" key={'Home'}
            options={({navigation}) => ({
              title: t('AgrisysConnect.NavTitle'),
              headerLeft: (props) => (
                <View style={{padding:6, paddingLeft: 15, zIndex:999}}>
                  <TouchableOpacity style={[{}]} 
                    onPress={() => {
                      navigateAndSimpleReset('Main', {screen: 'Home'})
                    }}
                  >
                    <ArrowLeft size={35} color="white" title={""}/>
                  </TouchableOpacity>
                </View>
              ),
            })}
          >
            {(props) => 
              <View style={[Layout.fullFill, Layout.fullSize, Layout.colCenter, Gutters.tinyPadding, Common.bagroundPrimary, { minWidth:300, backgroundColor:'#f2f2f2', zIndex:-999}]}>
                {profile && profile.state === UserStates.WORKER?(
                  <UserInformation {...Object.assign(props, { MainNavi: true })}/>
                ):(
                  <LoginContainer/>
                )}
                <Modal onClose={() => setModalText(null)} isOpen={ModalText?true:false} unstable_ModalBackdropScroll={true}>
                  <FocusOnce>
                    <ModalHeader>{ModalText?.header}</ModalHeader>
                  </FocusOnce>
                  <ModalBody>
                    {ModalText?.text}
                  </ModalBody>
                  <ModalFooter>
                    <ModalButton autoFocus onClick={() => setModalText(null)}>
                      {t('ActionButtons.Submit_btn')}
                    </ModalButton>
                  </ModalFooter>
                </Modal>
              </View>
            }
          </AuthStack.Screen>
          <AuthStack.Screen name="Registration" component={RegistrationContainer} 
            options={({navigation}) => ({
              title: t('AgrisysConnect.NavTitle'),
              headerShown: false
            })}
          />
        </AuthStack.Group>
      </AuthStack.Navigator>
    </SafeAreaView>
  )
}

export default AuthNavigator