import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
} from 'react-native'
import { Button } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, isApp } from '../../Navigators/Root'
import { useIsFocused } from '@react-navigation/native'

import { useAppSelector, useAppDispatch } from '../../Store'
import { FirebaseCaller, Graphs} from '../../Services/Firebase'
import { isEmpty } from '../../Config/Types'

export interface CurvePoint {
  day: number, 
  weight: number
}
const GrowthCurves = () => {
  const { t } = useTranslation()
  const { Common, Gutters, Layout } = useTheme()
  //const dispatch = useAppDispatch()

  //const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const LinkList = useAppSelector(({firebase: { data }}) => data.LinkList)
  
  //Data Call Refresher
  const focus = useIsFocused()
  FirebaseCaller(Graphs.fetchWeightGraphs(activefarm, false, [], undefined).concat(
    !isEmpty(LinkList)?Object.entries(LinkList).map(([Current_Farm, Data]) => 
      Graphs.fetchWeightGraphs(activefarm, false, [], Current_Farm)
    ).flat(1):[]
  ), [focus])
  const GraphsDatas = useAppSelector(state => Graphs.GetPenWeightGraph(state, undefined, undefined), undefined)

  /*useEffect(() => {
    if (focus) {
      console.log("Graphs fetch", GraphsDatas)
    }
  }, [focus])*/
  
  //const GraphsDatas = useAppSelector(({firebase: {data}}) => Object.assign({}, data.agri_graphs, data.local_graphs))

  //Auth Check Handler
  /*useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])*/

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  //if (!isLoaded(auth) || !isLoaded(profile) || !isLoaded(farms)) return <LoadingSplash/>
  //else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[Layout.colVCenter, Layout.fullWidth]}>
          <View style={[Layout.fill, Layout.fullSize, Layout.colVCenter, {marginVertical:20}]}>
            {GraphsDatas?Object.entries(GraphsDatas).map(([key,val]) => {
              if (key === "update_time") return null
              return (
                <Button Contexts={key} key={key} Action={(ele) => {
                  navigate('User', {screen: 'GrowthCurves_Editor', params:{New:false, Selected:key}})
                }}/>
              )
            }):(<></>)}
          </View>
          <Button Contexts={t('Graphs.New')} Frame Action={() => {
            navigate('User', {screen: 'GrowthCurves_Editor', params:{New:true}})
          }} />
        </View>
      </View>
    )
  //}
}

export default GrowthCurves