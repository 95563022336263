import { UnixDayTime, Operations, CommentData, isEmpty } from "../Config/Types"
import { DataForm } from "../Components/GeneralUse/CurveViewer"
import XLSX from 'xlsx'
import { FeedList } from "../Services/Consys/Feed-Slice"
import { ScaleList } from "../Services/Consys/Scale-Slice"
import { TemperatureList } from "../Services/Consys/Temperature-Slice"
import { WaterList } from "../Services/Consys/Water-Slice"
//import { type } from "os"

//#region Extractors
function GenerateCSV(Data:string[][], Sheet_name:string, File_name:string){
  var workbook = XLSX.utils.book_new()
  var worksheet = XLSX.utils.aoa_to_sheet(Data)
  XLSX.utils.book_append_sheet(workbook, worksheet, Sheet_name + '')
  XLSX.writeFile(workbook, File_name + '.csv')
}

export const GenerateCSV_Data = (reportName:string|number, Feeds?:FeedList[], AutoWeights?:ScaleList[], AutoTemps?:TemperatureList[], AutoWater?:WaterList[], Weights?:any[], Operations?:CommentData[], FullAutoWeightPlot:boolean=false) => {
  var CSVData = [
    ['Valve', 'Date', 'Count', 'Weight', 'Data', 'Prop1', 'Prop2']
  ]
  if (Feeds) {
    var Silolog:Record<string,number> = {}
    CSVData = CSVData.concat(Object.values(Feeds).map(FeedList => {
      var Feedings = Object.entries(FeedList).map(([pen, data], index) => {
        var date = new Date(data.timestamp)
        var SummedAmmount = data.sumamount?(Math.round(data.sumamount*1000)/1000):0
        if (SummedAmmount) SummedAmmount = SummedAmmount/1000 
        var Summed:string[] = [
          data.valve+'', date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), 
          (data.silolist?.length+''||''), SummedAmmount+'', 
          'Day-Sum',
          (data.wateramount+''||''), 
          ''
        ]
        if (!data.silolist) return [Summed]
        var Datas = [Summed].concat(Object.values(data.silolist).map((Feeding) => {
          var Ammount = Feeding.amount?(Math.round(Feeding.amount*1000)/1000):0
          if (Ammount) Ammount = Ammount/1000 
          Silolog[Feeding.silono] = Silolog[Feeding.silono]?(Silolog[Feeding.silono]+=Ammount):Ammount
          return ['', '', '', Ammount+'', '', Feeding.silono + '', '']
        }))
        return Datas
      })
      return Feedings
    }).flat(2))
  }

  if (AutoWeights) {
    //console.log("Auto", AutoWeights)
    CSVData = CSVData.concat(Object.values(AutoWeights).map(ScaleList => {
      return Object.entries(ScaleList).map(([pen, data], index) => {
        var DATACOLLECT = Object.values(data).map((weight:any) => {
          var _Datas:string[] = []
          if (FullAutoWeightPlot) {
            if (weight.objArr && weight.objArr.length) {
              return Object.values(weight.objArr).map((objweight:any) => {
                var date = new Date(objweight.timestamp * 1000)
                return ([pen, date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), 1, objweight.amount/1000, 'Auto-Weight', '', ''])
              })
            }
          } else {
            var date = new Date(weight.timestamp)
            return ([pen, date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), weight.count, (Math.round(weight.amount*100) /100), 'Avg-Weight', '', ''])
          }
        })
        if (FullAutoWeightPlot) return DATACOLLECT.flat(1)
        else return DATACOLLECT
      })
    }).flat(2))
  }
  if (AutoTemps) {
    CSVData = CSVData.concat(Object.values(AutoTemps).map(TempList => {
      return Object.entries(TempList).map(([pen, data], index) => {
        return Object.values(data).map((weight:any) => {
          var date = new Date(weight.timestamp)
          return ([pen, date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), weight.count, (Math.round(weight.amount*100) /100), 'Temps', '', ''])
        })
      })
    }).flat(2))
  }
  if (AutoWater) {
    CSVData = CSVData.concat(Object.values(AutoWater).map(WaterList => {
      return Object.entries(WaterList).map(([pen, data], index) => {
        return Object.values(data).map((weight:any) => {
          var date = new Date(weight.timestamp)
          return ([pen, date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), weight.count, (Math.round(weight.amount*100) /100), 'Water', '', ''])
        })
      })
    }).flat(2))
  }

  if (Weights && !isEmpty(Weights)) {
    CSVData = CSVData.concat(Weights.filter(e => (!e.edited&&!e.deleted)).map(weight => {
      if (weight.data && weight.data.ind !== undefined && weight.data.out !== undefined) {
        var pen = weight.pen
        var Datas = weight.data
        var Summed:string[] = [
          pen+'', '', (Datas.data?.length+''||''), 
          Datas.ind+'', Datas.out+'',
          Datas.days+'', Datas.growth+''
        ]
        if (!Datas.data) return [Summed]
        var _Datas = [Summed].concat(Datas.data.map((WeighingDatas) => {
          var date = new Date(WeighingDatas[0])
          return [
            '', date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), 
            WeighingDatas[1]+'', WeighingDatas[2]+'', WeighingDatas[3]+'', WeighingDatas[4]+'', WeighingDatas[5]+'']
        }))
        return _Datas
      }
      else {
        var date = new Date(weight.time)
        return [[weight.pen, date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), weight.count, weight.weight, '', '', '']]
      }
    }).flat(1))
  }

  if (Operations && !isEmpty(Operations)) {
    CSVData = CSVData.concat(
      Operations.filter(e => (!e.edited&&!e.deleted)).map((Operation) => {
        var date = new Date(Operation.time)
        return ([Operation.pen+'', date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear(), Operation.quantity?Operation.quantity+'':'', Operation.weight?Operation.weight+'':'', Operation.data?Operation.data.replaceAll('\n',' ').replaceAll(',','.').replaceAll(';','.'):'', Operation.code?Operation.code.split('_')[0]+'':Operation.action?Operation.action:'', Operation.code?Operation.code.split('_')[1]+'':''])
      })
    )
  }

  var Name = (reportName+'').replaceAll(/(\\|\/|\?|\*|\[|\])/gi, "_")
  GenerateCSV(CSVData, Name, Name + '_Data')
  //XLSX.writeFile(workbook, 'Pen_' + reportName + '.csv', {bookType: 'csv', bookSST:true, Props:{Title:'Agrisys Pen-report', Author:"AgrisysApp", Company:"Agrisys A/s"} });
  //ConScaleList?Object.values(ConScaleList).map((ScaleList) => Object.entries(ScaleList).map(([pen, data], index) => {
}
//#endregion

//#region not used ???
export function ObjectsEqual(first:{[key:string]: {}}, second:{[key:string]: {}}):boolean {
  if (!first) {
    if (second) return false
    else return true
  }
  if (!second) return false
  const al = Object.getOwnPropertyNames(first);
  const bl = Object.getOwnPropertyNames(second);

  // Check if the two list of keys are the same
  // length. If they are not, we know the objects
  // are not equal.
  if (al.length !== bl.length) return false;

  // Check that all keys from both objects match
  // are present on both objects.
  const hasAllKeys = al.every(value => !!bl.find(v => v === value));

  // If not all the keys match, we know the
  // objects are not equal.
  if (!hasAllKeys) return false;

  // We can now check that the value of each
  // key matches its corresponding key in the
  // other object.
  for (const key of al) if (first[key] !== second[key]) {
    //If value is key reloop with value as object.. check results
    if (typeof first[key] !== 'object' || typeof second[key] !== 'object' || !ObjectsEqual(first[key], second[key])) return false
  }

  // If the object hasn't return yet, at this
  // point we know that the objects are the
  // same
  return true;
}

var reA = /[^a-zA-Z]/g;
var reN = /[^0-9]/g;
export function sortAlphaNum(a:string, b:string):number {
  var aA = a.replace(reA, "");
  var bA = b.replace(reA, "");
  if (aA === bA) {
    var aN = parseInt(a.replace(reN, ""), 10);
    var bN = parseInt(b.replace(reN, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
}
export function sortNumAlpha(a:string, b:string):number {
  var aA = a.replace(reN, "");
  var bA = b.replace(reN, "");
  if (aA === bA) {
    var aN = parseInt(a.replace(reA, ""), 10);
    var bN = parseInt(b.replace(reA, ""), 10);
    return aN === bN ? 0 : aN > bN ? 1 : -1;
  } else {
    return aA > bA ? 1 : -1;
  }
}

export function NormSorter(a:string, b:string):number {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base'
  })
}

export function sortObject(o:{[key:string]: {}}):object {
  var sorted:{[key:string]: object} = {},
  a:string[] = []

  for (var _key in Object.keys(o)) {
    if (o.hasOwnProperty(_key) && _key !== "__jsogObjectId" && _key !== "update_time") {
      a.push(_key);
    }
  }
  //var ab = [...a]
  //var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'})
  //ab.sort(collator.compare)
  a.sort(NormSorter)

  for (var index = 0; index < a.length; index++) {
    sorted[a[index]] = o[a[index]];
  }
  return sorted;
}

export function arraySort(a:[]) {
  var collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
  return a.sort(collator.compare)
}

export function NearestHalfTime(time:string|number|Date):number {
  var _Date:Date|undefined
  debugger //Check if typecheck works
  if (typeof time === 'object') {
    _Date = new Date(time)
  }
  if (_Date) {
    var res = _Date.setMinutes((_Date.getMinutes()>=30?30:0) ,0,0)
    return res
  }
  return -1 //fail
}

export function NearestTime(time:string|number|Date):Date|undefined {
  var _Date:Date|undefined
  debugger //Check if typecheck works
  if (typeof time === 'object') {
    _Date = new Date(time)
  }
  if (_Date) {
    _Date.setHours(_Date.getHours() + Math.round(_Date.getMinutes()/60))
    _Date.setMinutes(0, 0, 0) // Resets also seconds and milliseconds
    return _Date
  }
  return undefined //fail
}
//#endregion

interface CheckProps {
  data:string|any,
  isNumbers?:boolean,
  Check_firstnum?:boolean,
  AllowSpecial?:boolean,
  AllowComma?:boolean
}
export function DataStringCheck({data, isNumbers=false, Check_firstnum = true, AllowSpecial = false, AllowComma = false}:CheckProps):boolean {
  if (!data || data === "") {
    return false
  }
  if (typeof data !== 'string') {
    return false
  } else {
    if (!isNumbers && Check_firstnum) {
      var first = data[0]
      var numbers = '0123456789';
      for(var i = 0; i < numbers.length;i++) {
        if(first === numbers[i]) {
          return false
        }
      }
    }
    
    if (isNumbers) {
      return data.split(',').some((_text) => {
        if (!isNaN(+_text)) return true
      })
    }

    var specialChars = '/[`!#$%^&*()+\-=\[\]{};\':"\\|<>\/?~]/¨´';
    if (!AllowSpecial) specialChars +='@.'
    if (!AllowComma) specialChars +=','
    for(var i = 0; i < specialChars.length;i++) {
      if(data.indexOf(specialChars[i]) > -1){
        return false
      }
    }
  }
  return true
}

export function getRandomNumber(low:number, high:number):number {
  var r = Math.floor(Math.random() * (high - low + 1)) + low;
  return r;
}
export function getRandomColor():{r:number,g:number,b:number,a:number,rgbaValue:string} {
  var red_range = [15, 230];
  var green_range = [15, 230];
  var blue_range = [15, 230];
  var a_range = [0.3, 1];

  var red = getRandomNumber(red_range[0], red_range[1]);
  var green = getRandomNumber(green_range[0], green_range[1]);
  var blue = getRandomNumber(blue_range[0], blue_range[1]);

  var alpha = getRandomNumber(a_range[0] * 100, a_range[1] * 100) / 100;

  return {
    r: red,
    g: green,
    b: blue,
    a: alpha,
    rgbaValue: 'rgba(' + red + ',' + green + ',' + blue + ',' + alpha + ')'
  }
}

//Blue-ish
type WaterColors = [1, 9, 11, 17, 25, 27, 7]
//Green -ish
type WeightColors = [2, 5, 12, 16, 18, 19, 22, 24, 29, 35, 37, 39, 42, 44, 45, 48, 50 ]
//Brown -ish
type FeedColors = []
//Orange -ish
type TempColors = []
export function getUniqueColor(n:number, form?:DataForm):string {
	const rgb = [0, 0, 0];
  for (let i = 0; i < 24; i++) {
  	rgb[i%3] <<= 1;
    rgb[i%3] |= n & 0x01;
    n >>= 1;
  }
  var string = '#' + rgb.reduce((a, c) => (c > 0x0f ? c.toString(16) : '0' + c.toString(16)) + a, '')
  return string
}

export interface Calc_Weights_Tabel_Props {
  Weights:CommentData[],
  Comments:CommentData[]|null,
  startTime?:number,
  enddate?:number
}
export interface Weights_To_Table_Props {
  Weights:CommentData[],
  Comments:CommentData[]|null,
  Return_View:boolean,
  startTime?:number,
  enddate?:number
}

export function Calc_Weights_Tabel({Weights, Comments=null, startTime, enddate}:Calc_Weights_Tabel_Props) {
  var Data:any[] = []
  var ZeroIndex = 0

  var Age = 0, Out = 0, Ind = 0, count = 0
  var lastTime:number|undefined = undefined
  var GrowthCalc:number|null = null

  //Test
  if (Comments && Weights && Object.keys(Weights).length) {
    Object.entries(Comments).map(([Id, Comment]) => {
      if (!Comment.edited && !Comment.deleted) {
        var ComWeight:number = 0
        if (Comment.weight) ComWeight = parseFloat(Comment.weight +'')
        else if (Weights) {
          for (let [Id, Weight] of Object.entries(Weights)) {
            if (!Weight.edited && !Weight.deleted) {
              // weight near comment time +- 3/4 day
              if (!Comment.weight && Comment.time >= (Weight.time - (UnixDayTime * 0.75)) && Comment.time <= (Weight.time + (UnixDayTime *0.75)) ) {
                ComWeight = Weight.count?Weight.weight/Weight.count:Weight.weight
                break;
              }
            }
          }
        }
        if (Comment.action === Operations.ADD || Comment.action === Operations.START) {
          if (count > 0 && lastTime) {
            Age += (( Comment.time -lastTime ) / UnixDayTime) * count
          }

          Ind += ComWeight * (Comment.quantity || 0 )
          count += (Comment.quantity || 0)
          lastTime = Comment.time
        }
        else if (Comment.weight && (Comment.action === Operations.SOLD || Comment.action === Operations.MOVE || Comment.action === Operations.CLOSE)) {
          if (count >= 0 && lastTime) {
            var Days = (( Comment.time -lastTime ) / UnixDayTime)
            Age += Days * count
          }
          count -= (Comment.quantity || 0)
          Out += (Comment.quantity || 0) * ComWeight
          lastTime = Comment.time
        }
      }
    })
    /*if (!Age && lastTime) {
      var time = enddate&&enddate<Date.now()?enddate:Date.now()
      Age = Math.floor((( time -lastTime ) / UnixDayTime)) * count
    }*/
    GrowthCalc = Math.round(((Out?(Out-Ind):Ind) / (Age?Age:1)) *1000) /1000
    Ind = Math.round(Ind *1000) /1000
    Out = Math.round(Out *1000) /1000
  }
  //---

  Object.entries(Weights).map(([Id, Weight], index) => {
    var dodata = true
    if (Weight.edited || Weight.deleted || (startTime && Weight.time < startTime)) {
      ZeroIndex++
      dodata = false;
    }

    if (dodata && (!enddate || Weight.time <= enddate)) {
      if (!startTime) startTime = Weight.time
      var GrowthPrWeight:number = 0
      var GrowthBatch:number = 0

      var CurrentWeight = Weight.count&&Weight.count>0?(Weight.weight/Weight.count):Weight.weight
      
      if (index > ZeroIndex) {
        var First = Weights[ZeroIndex]
        var Prev = Weights[index-1]

        var FirstWeight = First.count&&First.count>0?First.weight/First.count:First.weight
        var PrevWeight = Prev.count&&Prev.count>0?Prev.weight/Prev.count:Prev.weight

        var DifDays_Batch = Math.ceil((Weight.time - First.time) / (1000 * 3600 * 24))
        var DifDays_Prev = Math.ceil((Weight.time - Prev.time) / (1000 * 3600 * 24))

        if (DifDays_Prev>0)GrowthPrWeight = (CurrentWeight - PrevWeight) / DifDays_Prev
        else GrowthPrWeight = 0
        if (DifDays_Batch>0)GrowthBatch = (CurrentWeight - FirstWeight) / DifDays_Batch
        else GrowthBatch = 0
      }

      Data.push([Weight.time, Weight.count, 
        (Math.round(Weight.weight * 100) / 100), 
        (Math.round(CurrentWeight * 100) / 100), 
        index>ZeroIndex&&!isNaN(GrowthPrWeight)?(Math.round(GrowthPrWeight * 1000)):GrowthPrWeight, 
        index>ZeroIndex&&!isNaN(GrowthBatch)?(Math.round(GrowthBatch * 1000)):GrowthBatch
      ])
    }
  })

  return {days:(Math.floor(Age*100)/100), ind:Ind, out:Out, growth:(GrowthCalc?GrowthCalc:0), data:Data}
}