import { Platform, View } from 'react-native'
import { useTheme } from '../../Theme'

import {
  AspectRatioBox,
  AspectRatioBoxBody,
} from 'baseui/aspect-ratio-box';

export interface Brand_Props {
  width: string|number,
}

const Brand = ({ width }: Brand_Props) => {
  const { Layout, Images } = useTheme()

  return (
    <AspectRatioBox aspectRatio={500 / 227} maxWidth={(typeof width === "string")?width:width+'px'} width={'100%'}>
      <AspectRatioBoxBody
        as="img"
        src={Images.Logo+'?width=500'}
      />
    </AspectRatioBox>
  )
}

Brand.defaultProps = {
  width: 595
}

export default Brand
