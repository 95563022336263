export enum ContentPages {
  SystemBoard = "Systemboard",
  DashBoard = "DashBoard",
  Pens = "Pens",
  Reports = "Reports",
  Growth = "Growth",
  Operations = "Operations",
  Autopigs = "Autopigs",
  Settings = "Settings",
}

//Front Sites
export { default as IndexStartupContainer } from './Startup'
export { default as IndexAgrisysContainer } from './Front'

//Auth
export { default as IndexAuthContainer } from './Auth/Index'              //To Remove
export { default as RegistrationContainer } from './Auth/Registration'

//Admin
//export { default as IndexAdminContainer } from './Admin/Index'            //To Remove
export { default as SystemTable } from './Admin/SystemTable'
export { default as UserTable } from './Admin/UserTable'
export { default as ToolsView } from './Admin/Tools/ToolsMain'
/*export { default as ToolsTable } from './Cloud/AdminPanel/ToolsTable'
//Tools
export { default as AdminSystemBoard } from './Cloud/AdminPanel/Tools/SystemBoard'
export { default as AdminValveView } from './Cloud/AdminPanel/Tools/ValveView'
*/
export * as UserPanel from './UserPanel' 