import React, { useMemo, useState } from 'react'
import { View, Image, Text } from 'react-native'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import useDetectPrint from 'react-detect-print'

import { ContentProp } from '../'
//import { useAppSelector } from '../../Store'
import { CommentData, isEmpty } from '../../Config/Types'
//import { Weighings } from '../../Services/Firebase'

import {
  StatefulDataTable,
  DatetimeColumn,
  CategoricalColumn,
  //NumericalColumn,
  StringColumn,
  SORT_DIRECTIONS
} from "baseui/data-table"
/*import {
  TableBuilder,
  TableBuilderColumn,
} from 'baseui/table-semantic'*/
import { Checkbox } from 'baseui/checkbox'

export interface WeighingsList_Props {
  WeighingsList: any[],
  setEditSelect?: (data:CommentData|null) => void, //React.Dispatch<React.SetStateAction<ActivitiesData|null>>,
  FixedDisplay?: boolean,
  GenerateCSV_Data?: () => void,
}

const WeighingsList = ({WeighingsList, setEditSelect, FixedDisplay, GenerateCSV_Data, ...rest}: WeighingsList_Props) => {
  const { t } = useTranslation()
  const { Layout, Images } = useTheme()
  const isPrinting  = useDetectPrint()

  //const [SortMap, setSortMap] = useState<Record<string, {description:string, exclude:boolean, selection:Set<number>, comparisons:{operation: string, value: number}[]}>>({})
  const [DisplayOperation, setDisplayOperation] = useState(FixedDisplay===undefined?true:FixedDisplay)

  const DataRows = useMemo(() => WeighingsList.map((data) => ({id:data.key, pen:data.pen, data:data})).flat(), [WeighingsList])

  return (
    <View style={[Layout.colVCenter, {marginVertical:20, width: '-webkit-fill-available', height:'fit-content', marginHorizontal:10}]}>
      <View style={[Layout.row, Layout.fullWidth, {justifyContent:'center', alignItems:'center', maxWidth:600}]}>
        <ContentProp Columns={1} maxWidth={FixedDisplay===undefined?600-30:600} Datas={[[{value:t('Pen_Display.ControlWeights_Title'), CSV_ABLE:GenerateCSV_Data!==undefined}]]} Backcolor={true} Actions={[() => {
          if (GenerateCSV_Data) GenerateCSV_Data()
        }]}/>
        {FixedDisplay===undefined||!FixedDisplay?!isPrinting?(
        <Checkbox checked={DisplayOperation} onChange={() => setDisplayOperation(!DisplayOperation)}
            overrides={{
              Root: { style: ({ }) => ({
                alignSelf: "center", alignItems: "center",
                minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
              })},
              Label: { style: ({ }) => ({
                justifyContent:'flex-end', paddingBottom:'5px',
                minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
              })}
            }}
          />):!DisplayOperation?(<Text>{`-Hidden`}</Text>):(<></>):(<></>)}
      </View>
      {DisplayOperation?(
      <View style={[Layout.fullWidth, {maxWidth:800, minHeight:300, flex:1}]} >
      {!isPrinting?(
        <StatefulDataTable filterable={false} searchable={false}
          columns={[
            DatetimeColumn({
              title: t('WeightList.Col2_1'),
              formatString: 'dd-MM-yyy HH:mm',
              mapDataToValue:(data: any) => new Date(new Date(data.time).setSeconds(0,0)),
              fillWidth: false,
              minWidth:160,
            }),
            CategoricalColumn({
              title: t('WeightList.Col2_2'),
              mapDataToValue: (data: any) => ""+data.count,
              fillWidth: true,
              minWidth:60,
            }),
            StringColumn({
              title: t('WeightList.Col2_3'),
              mapDataToValue: (data:any) => ""+data.weight,
              fillWidth: true,
              minWidth:60,
            }),
            StringColumn({
              title: t('WeightList.Col2_4'),
              mapDataToValue: (data:any) => ""+(Math.round((data.weight / data.count)*1000) / 1000),
              fillWidth: true,
              minWidth:60,
            })
          ]}
          initialSortDirection={SORT_DIRECTIONS.DESC}
          initialSortIndex={0}
          //initialFilters={new Map(Object.entries(SortMap))}
          onFilterAdd={(te:string, te2:any) => {
            /*setSortMap(old => {
              old[te] = te2
              return old
            })*/
          }}
          onFilterRemove={(te:string) => {
            /*setSortMap(old => {
              delete old[te]
              return old
            })*/
          }}
          rowActions={setEditSelect?[{
            label: t('OperationList.Action1'),
            onClick: ({row}) => {
              if (row && row.data !== "") {
                if (setEditSelect) setEditSelect({...row.data})
              }
            },
            renderIcon: () => <Image style={[Layout.fill, Layout.fullSize, {width:28, height:28}]} source={Images.Simple_Edit} resizeMode={'contain'} />,
          }]:[]}
          rows={DataRows}
        />
      ):(
        <></>
      )}
      </View>
      ):(<></>)}
    </View>
  )
}

export default WeighingsList


/*<TableBuilder sortOrder={SORT_DIRECTIONS.DESC} sortColumn={"time"}
          data={DataRows.filter(e => {
            var res = true
            if (res && SortMap[t('OperationList.Col2')]) {
              var val = SortMap[t('OperationList.Col2')].selection.has(e.data.pen)
              if (SortMap[t('OperationList.Col2')].exclude) res = !val
              else res = val
            }
            if (res && SortMap[t('OperationList.Col3')]) {
              var MainCODE = null
              var CODE = e.data.code
              if (CODE && CODE.includes("_")) {
                var _code = CODE.split("_")
                if (MainOperationTypes[_code[0]]) MainCODE = MainOperationTypes[_code[0]].description
                CODE = _code[1]
              }
              
              var _Val = MainCODE?MainCODE:e.data.action
              var val = SortMap[t('OperationList.Col3')].selection.has(_Val)
              if (SortMap[t('OperationList.Col3')].exclude) res = !val
              else res = val
            }
            if (res && SortMap[t('OperationList.Col4')]) {
              var _Val = "None"
              var CODE = e.data.code
              if (CODE && CODE.includes("_")) {
                var _code = CODE.split("_")
                CODE = _code[1]
              }
              if (CODE && Treatments?.find(e => e.code === CODE)) {
                var treatment = Treatments.find(e => e.code === CODE)
                if (treatment) _Val = "#" + CODE + " : " + treatment.description
              }

              var val = SortMap[t('OperationList.Col4')].selection.has(_Val)
              if (SortMap[t('OperationList.Col4')].exclude) res = !val
              else res = val
            }
            return res
          }).sort((a,b) => b.data.time-a.data.time)}>
          <TableBuilderColumn sortable id='time' header={t('OperationList.Col1')}>
            {data => {
              //var time = new Date(data.data.time)
              var TimeString = t('Pen_Display.WeightTime',{date:new Date(data.data.time)})
              return (<Text>{TimeString}</Text>)
            }}
          </TableBuilderColumn>
          <TableBuilderColumn header={t('OperationList.Col2')}>
            {data => <Text>{data.pen}</Text>}
          </TableBuilderColumn>
          <TableBuilderColumn header={t('OperationList.Col3')}>
            {data => {
              var MainCODE = null
              var CODE = data.data.code
              if (CODE && CODE.includes("_")) {
                var _code = CODE.split("_")
                if (MainOperationTypes[_code[0]]) MainCODE = MainOperationTypes[_code[0]].description
                CODE = _code[1]
              }
              return (<Text>{MainCODE?MainCODE:data.data.action}</Text>)
            }}
          </TableBuilderColumn>
          <TableBuilderColumn header={t('OperationList.Col4')}>
            {data => {
              var MainCODE = null
              var CODE = data.data.code
              if (CODE && CODE.includes("_")) {
                var _code = CODE.split("_")
                if (MainOperationTypes[_code[0]]) MainCODE = MainOperationTypes[_code[0]].description
                CODE = _code[1]
              }
              if (CODE && Treatments?.find(e => e.code === CODE)) {
                var treatment = Treatments.find(e => e.code === CODE)
                if (treatment) return (<Text>{"#" + CODE + " : " + treatment.description}</Text>)
              }
              return (<Text>{"None"}</Text>)
            }}
          </TableBuilderColumn>
          <TableBuilderColumn header={t('OperationList.Col5')}>
            {row => {
              var data = row.data
              var weight = data.weight?Math.round(data.weight*1000)/1000+'':'-'
              var DataString = ""
              if (data.quantity) DataString = t('Pen_Display.OperationData', {count: data.quantity, action: "", weight:weight, note:data.data})
              else DataString = data.data
              return (<Text>{DataString}</Text>)
            }}
          </TableBuilderColumn>
          </TableBuilder>*/