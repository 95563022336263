import React, { useCallback, useEffect, useState } from 'react'
import { SafeAreaView, ScrollView, View, Text, TouchableOpacity } from 'react-native'
import { useTheme } from '../Theme'
import { isEmpty } from '../Config/Types'
import { SystemTable, UserTable, ToolsView } from '../Containers'
import MenuView from '../Containers/Admin/MenuView'

import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useTranslation } from 'react-i18next'
import { ArrowLeft, DeleteAlt } from "baseui/icon"

import { navigateAndSimpleReset, useisAppView, useNavigatorOnLine } from '../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../Store'
import { LanguageSelector } from '../Components'

const AdminStack = createNativeStackNavigator()

// @refresh reset
const AdminNavigator = () => {
  const { t } = useTranslation()
  const { Common, Layout, Colors, NavigationColors } = useTheme()

  const auth = useAppSelector(({firebase}) => firebase.auth)

  //Auth Check Handler
  useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigateAndSimpleReset('Auth', {screen: 'Home'})
  }, [auth])

  const isOnline = useNavigatorOnLine()
  const isAppView = useisAppView()

  const ChangeView = (navigation, isMobilFront=false) => {
    return (E) => {
      if (isMobilFront) navigation.navigate('Admin', {screen: E})
      else navigation.replace('Admin', {screen: E})
      //navigateBack()
      //navigation.navigate('User', {screen: E})
    }
  }

  const ContextFrame = useCallback((navigation, PageComponent, NoMenu=false) => {
    return(
      <View style={[Layout.fullSize, Layout.column, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'min-content', backgroundColor:'#f2f2f2'}]}>
        {!isOnline?(
          <View style={[Layout.fillCol,{backgroundColor:Colors.error, minHeight:22}]}>
            <Text style={{textAlign:'center'}}>{'Device is offline'}</Text>
          </View>
        ):(<></>)}
        <View style={[Layout.fullSize, Layout.row, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'min-content', backgroundColor:'#f2f2f2'}]}>
          {(!NoMenu&&!isAppView) || (!PageComponent && isAppView)?(<MenuView setView={ChangeView(navigation, (!PageComponent && isAppView))} nologo={false} />):(<></>)}
          {PageComponent?
            <ScrollView style={[Layout.fillCol, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'min-content', backgroundColor:'#f2f2f2'}]} centerContent={true}  contentContainerStyle={[Layout.fillRow, {justifyContent: "center", minHeight:'max-content'}]}>
              {PageComponent}
            </ScrollView>:[]
          }
        </View>
      </View>
    )
  }, [isOnline, isAppView])

  const MenuLeftOptions = useCallback(() => (
    <View style={{padding:6, paddingLeft: 15, zIndex:999}}>
      <TouchableOpacity style={[{}]}
        onPress={() => {
          navigateAndSimpleReset('Main', {screen: 'Home'})
        }}
      >
        <DeleteAlt size={35} color="white" title={""}/>
      </TouchableOpacity>
    </View>
  ), [])

  return (
    <SafeAreaView style={[Layout.fullFill, { backgroundColor: "white" }]}>
      <AdminStack.Navigator initialRouteName={"Home"}
        screenOptions={{
          title: 'AgrisysApp',
          headerTintColor: 'white',
          headerTitleAlign: 'center',
          presentation:"modal",
      }}>
        <AdminStack.Group navigationKey={auth?'signed':'unknown'}
          screenOptions={({ navigation }) => ({
            headerStyle: { backgroundColor: NavigationColors.card },
            headerRight: (props) => (
              (<LanguageSelector/>)
            ),
          })}
        >
          <AdminStack.Screen name={"Home"}
            options={({ navigation }) => ({
              title: t('AgrisysMenu.NavTitle'),
              headerLeft: (props) => MenuLeftOptions()
            })}
          >
            {(props) => ContextFrame(props.navigation, isAppView?null:<SystemTable />)}
          </AdminStack.Screen>
          <AdminStack.Screen name="Userboard"
            options={({ navigation }) => ({
              title: 'User Table',
              headerLeft: (props) => MenuLeftOptions()
            })}
          >
            {(props) => ContextFrame(props.navigation, <UserTable />)}
          </AdminStack.Screen>
          <AdminStack.Screen name="System"
            options={({ navigation }) => ({
              title: 'System Table',
              headerLeft: (props) => MenuLeftOptions()
            })}
          >
            {(props) => ContextFrame(props.navigation, <SystemTable />)}
          </AdminStack.Screen>
          <AdminStack.Screen name="Tools"
            options={({ navigation }) => ({
              title: 'Tools',
              headerLeft: (props) => MenuLeftOptions()
            })}
            >
              {(props) => ContextFrame(props.navigation, <ToolsView />)}
            </AdminStack.Screen>
        </AdminStack.Group>
      </AdminStack.Navigator>
    </SafeAreaView>
  )
}

export default AdminNavigator