import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  View,
  Clipboard
} from 'react-native'
import { LoadingSplash, Brand, Frame, Button } from '../../Components'
import {
  StatefulDataTable,
  CategoricalColumn,
  CustomColumn,
  NumericalColumn,
  StringColumn,
  COLUMNS,
  NUMERICAL_FORMATS,
} from 'baseui/data-table'
import { Check, Upload as ConsysIcon } from "baseui/icon";

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigationRef } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded, isEmpty, useFirebaseConnect, firebaseConnect } from 'react-redux-firebase'


const DataTableColumns = [
  StringColumn({
    title: 'Id',
    mapDataToValue: (data:any) => data[0]?data[0]:"",
    fillWidth: true
  }),
  CategoricalColumn({
    title: 'State',
    mapDataToValue: (data:any) => data[1]?data[1]:"??--",
    fillWidth: false,
    minWidth: 80,
    maxWidth: 90
  }),
  CategoricalColumn({
    title: 'Tag',
    mapDataToValue: (data:any) => data[2]?data[2]:"",
    fillWidth: false,
    minWidth: 80,
    maxWidth: 90
  }),
  CategoricalColumn({
    title: 'Name',
    mapDataToValue: (data:any) => data[3]?data[3]:"??--",
    minWidth: 100,
    maxWidth: 100
  }),
  StringColumn({
    title: 'E-Mail',
    mapDataToValue: (data:any) => data[4]?data[4]:"",
    fillWidth: true
  }),
  StringColumn({
    title: 'Farms',
    mapDataToValue: (data:any) => data[5]?data[5]:"",
    fillWidth: false,
    minWidth: 100,
    maxWidth: 240
  }),
]

const UserTable = () => {
  const { t } = useTranslation()
  const { Common, Fonts, Gutters, Layout, Images } = useTheme()

  useFirebaseConnect(() => {
    return {path:'users/', storeAs: 'users'}
  })

  const users = useAppSelector(({firebase: { data }}) => data.users)
  const [DataRows, setDataRows] = useState([])
  const activefarm = useAppSelector(({activeData}) => activeData.farm)

  useEffect(() => {
    if (users) {
      var Data = Object.entries(users).map(([Id, Data]) => {
        var Farms = Data.farms?Object.keys(Data.farms):[]
        return {id: Id, data: [Id+'', (Data.state?Data.state:'') +'', (Data.CatTag?Data.CatTag:'') +'', (Data.name||"") + " " + (Data.lastN||"") +'', Data.email +"", Farms +'']}
      })
      setDataRows(Data)
    }
  }, [users])
  
  useEffect(() => {
    if (activefarm) {
      //navigationRef.current?.navigate('User', {screen: 'Home'})
    }
  }, [activefarm])

  useEffect(() => {
  },[])

  if (isLoaded(users) && !isEmpty(users)) {
    return(
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[{width:'90%', height:'90%'}]}>
          <StatefulDataTable columns={DataTableColumns}
            rows={DataRows} 
            emptyMessage="No Data"
            rowActions={[{
              label: 'UserId',
              onClick: ({row}) => {
                if (row.id !== "") {
                  Clipboard.setString(row.id)
                }
              },
              renderIcon: ({size}) => <Check title='View' size={size} />,
            }]}
            overrides={{
              Root: {style: ({ $theme }) => ({
                outline: `1px #000 solid`,
                height:'100%',
              })}
            }}
          />
        </View>
      </View>
    )
  } else {
    return (<LoadingSplash />) 
  }
}

export default UserTable
