import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ConsysRefs, TimeSpan } from '../../../Config/Types'
import { DataForm, SelectorContainer, PointRange, Domain, DataSet, DataSets, DataTemplate, Settings_Props, AOptions } from './index'

interface ICurveViewContext {
  Settings: Settings_Props
  ActiveOptions: AOptions,
  Selected_DataOptions: SelectorContainer,
  
  DeactiveSets: string[],
  DataTimes?: TimeSpan,
}
export type CurveViewContext_Action =
  | { type: 'set_ActiveOptions', payload: AOptions}
  | { type: 'set_Selected_DataLeft', payload: DataForm|"---"|{tag:string[]}}
  | { type: 'set_Selected_DataRight', payload: DataForm|"---"|{tag:string[]}}
  | { type: 'set_MultiSplit', payload: boolean}

  | { type: 'set_DataForms', payload: Record<DataForm, {Domain:Domain, Datas:DataSets}>}

  | { type: 'set_DataTimes', payload: TimeSpan}
  
  | { type: 'set_HideTip', payload: boolean}
  | { type: 'set_Deactive', payload: {name: string}}

interface ICurveViewContext_Init {
  Settings: Settings_Props
  Disables?: DataForm[],
  Selected_DataOptions?: SelectorContainer,
  StartTimes?: TimeSpan,
  DataView?: DataSets,
}

const CurveViewContext = React.createContext<{ state: ICurveViewContext, dispatch: React.Dispatch<CurveViewContext_Action> }|null>(null)
function CurveViewReducer(state:ICurveViewContext, action:CurveViewContext_Action) {
  switch (action.type) {
    case 'set_ActiveOptions':
      return Object.assign({}, state, {ActiveOptions:action.payload,})
    case 'set_Selected_DataLeft': 
      var right = state.Selected_DataOptions.right?state.Selected_DataOptions.right:undefined
      var args = state.Selected_DataOptions.args?state.Selected_DataOptions.args:undefined
      var Select:SelectorContainer = {left:action.payload, right, args}
      //var ViewData = Get_ViewLoad(state.Settings, state.ActiveOptions, state.MainDatas, Select, state.DeactiveSets, state.DataTimes)
      return Object.assign({}, state, {Selected_DataOptions:Select,
        //Displayed_Data: {entireDomain:ViewData.Domain,zoomedXDomain:ViewData.Domain.x,ActiveData:ViewData.Datas}
      })
    case 'set_Selected_DataRight':
      if (action.payload.tag) {
        var args2 = state.Selected_DataOptions.args?state.Selected_DataOptions.args:{}
        args2.tags = action.payload.tag
        return Object.assign({}, state, {Selected_DataOptions:{...state.Selected_DataOptions, args:args2}})
      } else {
        var left = state.Selected_DataOptions.left?state.Selected_DataOptions.left:undefined
        var Select:SelectorContainer = {left, right:action.payload}
        //var ViewData = Get_ViewLoad(state.Settings, state.ActiveOptions, state.MainDatas, Select, state.DeactiveSets, state.DataTimes)
        return Object.assign({}, state, {Selected_DataOptions:Select,
          //Displayed_Data: {entireDomain:ViewData.Domain,zoomedXDomain:ViewData.Domain.x,ActiveData:ViewData.Datas}
        })
      }
    case 'set_DataTimes':
      return Object.assign({}, state, {DataTimes:action.payload})
    case 'set_HideTip':
      var newSettings = Object.assign({}, state.Settings, {HideTip: action.payload})
      return Object.assign({}, state, {Settings:newSettings})
    case 'set_MultiSplit':
      var NewMultiState = Object.assign({}, state.Settings.canMultiSplit, {state:action.payload})
      var NewMultiStateSettings = Object.assign({}, state.Settings, {canMultiSplit:NewMultiState})
      return Object.assign({}, state, {Settings:NewMultiStateSettings})
    case 'set_Deactive':
      var Deactive:string[] = [...state.DeactiveSets]

      var index = state.DeactiveSets.indexOf(action.payload.name)
      if (index >= 0) Deactive.splice(index,1)
      else Deactive.push(action.payload.name)

      //var ViewData = Get_ViewLoad(state.Settings, state.ActiveOptions, state.MainDatas, state.Selected_DataOptions, Deactive, state.DataTimes)
      return Object.assign({}, state, {DeactiveSets:Deactive,
        //Displayed_Data: {entireDomain:ViewData.Domain,zoomedXDomain:ViewData.Domain.x,ActiveData:ViewData.Datas}
      })
    default: 
      console.error(`Unhandled action type: ${action.type}`)
      break
  }
  return state
}

interface CurveView_Provider_Props {children:any, InitialData:ICurveViewContext_Init}
function CurveView_Provider({children, InitialData}:CurveView_Provider_Props) {
  const { t } = useTranslation()

  const ActiveOptions = useMemo<AOptions>(() => {
    var res:AOptions = [{label:"---", id:'---'}]
    if (InitialData.DataView && InitialData.DataView.length) {
      if (InitialData.DataView.some(e => ['Weight',ConsysRefs.SCALE].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("Weight")) res.push({label: t('GraphViewer.Weight'), id: "Weight"})
        if (!InitialData.Disables || !InitialData.Disables.includes("WeightTaged")) res.push({label: t('GraphViewer.Weight') + t('GraphViewer.ByTag'), id: "WeightTaged"})
      }
      if (InitialData.DataView.some(e => ['Activity',ConsysRefs.ACTIVITY].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("Activity")) res.push({label: t('GraphViewer.Activity'), id: "Activity"})
      }
      if (InitialData.DataView.some(e => ['Growth'].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("Growth")) res.push({label: t('GraphViewer.Growth'), id: "Growth"})
      }
      if (InitialData.DataView.some(e => ['Feed', ConsysRefs.VALVEFEED].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("Feed")) res.push({label: t('GraphViewer.Feed'), id: "Feed"})
      }
      if (InitialData.DataView.some(e => ['FCR'].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("FCR")) res.push({label: t('GraphViewer.FCR'), id: "FCR"})
      }
      if (InitialData.DataView.some(e => ['WaterPrPig'].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("WaterPrPig")) res.push({label: t('GraphViewer.WaterPrPig'), id: "WaterPrPig"})
      }
      if (InitialData.DataView.some(e => ['Water', ConsysRefs.WATERCOUNT].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("Water")) res.push({label: t('GraphViewer.Water'), id: "Water"})
      }
      if (InitialData.DataView.some(e => ['Temp', ConsysRefs.TEMPERATURE].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("Temp")) res.push({label: t('GraphViewer.Temp'), id: "Temp"})
      }
      if (InitialData.DataView.some(e => ['Notes'].includes(e.form))) {
        if (!InitialData.Disables || !InitialData.Disables.includes("Notes")) res.push({label: t('GraphViewer.Notes'), id: "Notes"})
      }
    }
    return res
  }, [InitialData.Disables, InitialData.DataView])

  useEffect(() => {
    dispatch({type:'set_ActiveOptions', payload:ActiveOptions})
  }, [ActiveOptions])

  const Selected_DataOptions = InitialData.Selected_DataOptions&&(InitialData.Selected_DataOptions.left||InitialData.Selected_DataOptions.right)?{
    left:InitialData.Selected_DataOptions.left?InitialData.Selected_DataOptions.left:ActiveOptions[1].id,
    right:InitialData.Selected_DataOptions.right?InitialData.Selected_DataOptions.right:ActiveOptions[0].id
  }:{left:ActiveOptions.length>1?ActiveOptions[1].id:ActiveOptions[0].id,right:ActiveOptions[0].id}

  const [state, dispatch] = React.useReducer<React.Reducer<ICurveViewContext, CurveViewContext_Action>>(CurveViewReducer, {
    Settings:InitialData.Settings,
    ActiveOptions: ActiveOptions,
    Selected_DataOptions: Selected_DataOptions,
    
    DataTimes:InitialData.StartTimes,
    DeactiveSets: []
  }as ICurveViewContext)

  return (
    <CurveViewContext.Provider value={{state, dispatch}}>
      {children}
    </CurveViewContext.Provider>
  )
}

function useCurveView_Context():{state: ICurveViewContext, dispatch: React.Dispatch<CurveViewContext_Action>} {
  const context = React.useContext(CurveViewContext)
  if (context === undefined || context === null) {
    throw new Error('useCurveView_Context must be used within a CurveView_Provider')
  }
  return context
}

export {CurveView_Provider, useCurveView_Context}