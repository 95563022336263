//var fs = require("fs");
import React, { useEffect, useState, useRef } from 'react'
//import { useDispatch, useSelector } from 'react-redux'
import { View, Text } from 'react-native'
import { Button, LoadingSplash } from '../../../Components'

import { useTheme } from '../../../Theme'
//import { useTranslation } from 'react-i18next'
import { FileUploader } from "baseui/file-uploader";
//import XLSX from 'xlsx'
import XLSX from 'sheetjs-style'
import { NormSorter } from '../../../utils/formaters';
enum states {
  normal=0,
  Warning=1,
  Error=2
}
interface stateMessage {txt:string, state?:states}

interface SectionValve_Def {secvalve:string, mdi:number, output:number, name?:string}
interface Valve_Def {valve:string, mdi:number, output:number, line?:number, sectionvalves:number[], mainvalves:number[], feedtype?:string, history_section?:string, distincm?:number}
interface Sensor_Def {valve:string, mdi:number, input:number}
interface Mdi_Def {mdi?:number, input:string[][], output:string[][], sectionvalves:string[][], feedtype:string[][], history_section:string[][]}
interface Sys_Def {Section_Valves:SectionValve_Def[], Valves: Valve_Def[], Sensors: Sensor_Def[], Mdis?:Record<number,Mdi_Def>}

interface Checks_ResProps {Warnings_Txt:stateMessage[], Status_Txt:stateMessage[]}
const ConsysSectionChecks = (Sections:SectionValve_Def[]):Checks_ResProps => {
  var Warnings_Txt:stateMessage[] = []
  var DoubleDefs:string[] = []
  for (let x = 0; x < Sections.length; x++) {
    if (!Sections[x].secvalve || DoubleDefs.includes(Sections[x].secvalve)) continue
    for (let i = x + 1; i < Sections.length; i++) {
      if (DoubleDefs.includes(Sections[x].secvalve)) continue
      if (Sections[x].secvalve === Sections[i].secvalve) {
        DoubleDefs.push(Sections[x].secvalve)
      }
    }
  }
  if (DoubleDefs.length) Warnings_Txt.push({txt:`Warning: Section: ${DoubleDefs} is defined more than once`, state:states.Warning})
  if (Sections.some(e => e.mdi===undefined)) Warnings_Txt.push({txt:`Warning: Section: ${Sections.reduce((res:number[], e) => e.mdi===undefined?[...res, e.secvalve]:res, [])} is missing mdi`, state:states.Warning})

  var Status_Txt:stateMessage[] = [{txt:`${Sections.length} Section Valves, on Mdi: ${Sections.reduce((res:number[], e) => ((e.mdi!==undefined && !res.includes(e.mdi))?[...res,e.mdi]:res),[])}` }]
  return {Warnings_Txt:Warnings_Txt, Status_Txt:Status_Txt}
}
const ConsysValveChecks = (Valves:Valve_Def[], Sections:SectionValve_Def[]):Checks_ResProps => {
  var Warnings_Txt:stateMessage[] = []
  var DoubleDefs:string[] = []
  for (let x = 0; x < Valves.length; x++) {
    if (DoubleDefs.includes(Valves[x].valve)) continue
    for (let i = x + 1; i < Valves.length; i++) {
      if (DoubleDefs.includes(Valves[x].valve)) continue
      if (Valves[x].valve === Valves[i].valve) {
        DoubleDefs.push(Valves[x].valve)
      }
    }
  }
  if (DoubleDefs.length) Warnings_Txt.push({txt:`Warning: Valve: ${DoubleDefs} is defined more than once`, state:states.Warning})
  if (Valves.some(e => e.mdi===undefined)) Warnings_Txt.push({txt:`Warning: Valve: ${Valves.reduce((res:number[], e) => e.mdi===undefined?[...res, e.valve]:res, [])} is missing mdi`, state:states.Warning})
  //Mabye check if Sections, Main exists
  //Mabye check if feedtype, history_section and distincm exists
  
  var Status_Txt:stateMessage[] = [{txt:`${Valves.length} Valves, on ${Valves.reduce((res:number[], e) => ((e.mdi && !res.includes(e.mdi))?[...res,e.mdi]:res),[]).length} diffrent Mdi's`}]
  var Dummie = Valves.filter(e => e.mdi === 0)
  if (Dummie.length) Status_Txt.push({txt:`${Dummie.length} Valves was found to be dummies` })
  return {Warnings_Txt:Warnings_Txt, Status_Txt:Status_Txt}
}
const ConsysSensorChecks = (Sensors:Sensor_Def[], Valves:Valve_Def[]):Checks_ResProps => {
  var Warnings_Txt:stateMessage[] = []
  var DoubleDefs:string[] = []
  var Missings:string[] = []
  for (let x = 0; x < Sensors.length; x++) {
    if (!Sensors[x].mdi || !Sensors[x].input) Missings.push(Sensors[x].valve)
    if (DoubleDefs.includes(Sensors[x].valve)) continue
    for (let i = x + 1; i < Sensors.length; i++) {
      if (DoubleDefs.includes(Sensors[x].valve)) continue
      if (Sensors[x].valve === Sensors[i].valve) {
        DoubleDefs.push(Sensors[x].valve)
      }
    }
  }
  if (DoubleDefs.length) Warnings_Txt.push({txt:`Warning: Sensor for valve: ${DoubleDefs} is defined more than once`, state:states.Warning})
  if (Missings.length) Warnings_Txt.push({txt:`Warning: Sensor for valve: ${Missings} is missing define for mdi or input`, state:states.Warning})
  
  var Status_Txt:stateMessage[] = [{txt:`${Sensors.length} Sensors, on ${Sensors.reduce((res:number[], e) => ((e.mdi!==undefined && !res.includes(e.mdi))?[...res,e.mdi]:res),[]).length} diffrent Mdi's`}]
  return {Warnings_Txt:Warnings_Txt, Status_Txt:Status_Txt}
}

interface MdiChecks_ResProps extends Checks_ResProps {Mdis:Record<number,Mdi_Def>}
const ConsysMdiChecks = (Sections:SectionValve_Def[], Valves:Valve_Def[], Sensors:Sensor_Def[]):MdiChecks_ResProps => {
  var Status_Txt:stateMessage[] = []
  var Warnings_Txt:stateMessage[] = []
  var Mdis:Record<number,Mdi_Def> = {}
  
  var MultiOut: Record<number, number[]> = {}
  var MultiIn: Record<number, number[]> = {}
  Valves.forEach(valve => {
    if (valve.mdi !== undefined && valve.output !== undefined) {
      var mdi = valve.mdi
      var output = valve.output

      var feedtype = valve.feedtype
      var history_section = valve.history_section
      var sectionvalves = [...valve.mainvalves, ...valve.sectionvalves].join('/')

      if (output && (Mdis[mdi] && Mdis[mdi].output && Mdis[mdi].output[output])) {
        if (!MultiOut[mdi]) MultiOut[mdi] = []
        if (!MultiOut[mdi].includes(output)) MultiOut[mdi].push(output)
      }

      if (!Mdis[mdi]) Mdis[mdi] = {output:[],input:[], sectionvalves:[], mdi:mdi, feedtype:[], history_section:[]}
      if (!Mdis[mdi].output[output]) Mdis[mdi].output[output] = []
      if (!Mdis[mdi].history_section[output]) Mdis[mdi].history_section[output] = []
      if (!Mdis[mdi].feedtype[output]) Mdis[mdi].feedtype[output] = []
      if (!Mdis[mdi].sectionvalves[output]) Mdis[mdi].sectionvalves[output] = []

      Mdis[mdi].output[output].push(valve.valve)
      if (history_section && (mdi===0 || !Mdis[mdi].history_section[output].includes(history_section))) Mdis[mdi].history_section[output].push(history_section)
      if (feedtype && (mdi===0 || !Mdis[mdi].feedtype[output].includes(feedtype))) Mdis[mdi].feedtype[output].push(feedtype)
      if ((sectionvalves||mdi===0) && (mdi===0 || !Mdis[mdi].sectionvalves[output].includes(sectionvalves))) {
        if (mdi===0) console.log("data", output, valve.valve, sectionvalves)
        Mdis[mdi].sectionvalves[output].push(sectionvalves?sectionvalves:"")
      }
      //if (Mdis[mdi].output[output]) Mdis[mdi].output[output].push(valve.valve)
      //else Mdis[mdi].output[output] = [valve.valve]
    }
  })
  Sections.forEach(section => {
    if (section.mdi !== undefined && section.output !== undefined) {
      var mdi = section.mdi
      var output = section.output
      var ValveName = section.name?section.name:section.secvalve
      if (output && (Mdis[mdi] && Mdis[mdi].output && Mdis[mdi].output[output])) {
        if (!MultiOut[mdi]) MultiOut[mdi] = []
        if (!MultiOut[mdi].includes(output)) MultiOut[mdi].push(output)
      }
      if (!Mdis[mdi]) Mdis[mdi] = {output:[],input:[],mdi:mdi}
      if (Mdis[mdi].output[output]) Mdis[mdi].output[output].push(ValveName)
      else Mdis[mdi].output[output] = [ValveName]
    }
  })
  Sensors.forEach(sensor => {
    if (sensor.mdi !== undefined && sensor.input !== undefined) {
      var mdi = sensor.mdi
      var input = sensor.input
      if (input && (Mdis[mdi] && Mdis[mdi].input && Mdis[mdi].input[input])) {
        if (!MultiIn[mdi]) MultiIn[mdi] = []
        if (!MultiIn[mdi].includes(input)) MultiIn[mdi].push(input)
      }
      if (!Mdis[mdi]) Mdis[mdi] = {output:[],input:[],mdi:mdi}
      if (Mdis[mdi].input[input]) Mdis[mdi].input[input].push(sensor.valve)
      else Mdis[mdi].input[input] = [sensor.valve]
    }
  })
  /*if (Object.keys(MultiOut).length) {
    Object.entries(MultiOut).forEach(([Mdi, Outputs]) => {
      Warnings_Txt.push({txt:`Mdi: ${Mdi} contains multiple defines for output: ${Outputs}`, state:states.Warning})
    })
  }
  if (Object.keys(MultiIn).length) {
    Object.entries(MultiIn).forEach(([Mdi, Inputs]) => {
      Warnings_Txt.push({txt:`Mdi: ${Mdi} contains multiple defines for input: ${Inputs}`, state:states.Warning})
    })
  }*/
  return {Warnings_Txt:Warnings_Txt, Status_Txt:Status_Txt, Mdis:Mdis}
}

function StyledDataRow({mdi, out, valve, sections, history, inp, sensor, last}:{mdi?:number, out?:string|number, valve?:string|number, sections?:string, history?:string, inp?:string|number, sensor?:string|number, last?:boolean}){
  var _fill = parseInt(out+'')%2===0?{fill:{fgColor:{rgb:'d8d8e0'}}}:{}
  var _last = last?{style:'medium'}:{}
  return [{},
    {v: mdi!==undefined?'mdi: '+mdi:'',   t:'s', s:mdi!==undefined?{fill: {fgColor:{rgb:'FFFF00'}}}:{}},
    {v: out!==undefined?out:'',           t:'s', s:{ border: { left:{style:'medium'}, bottom:_last }, ..._fill }},
    {v: valve!==undefined?valve:'',       t:'s', s:{ border: { right:{style:'medium'}, bottom:_last }, ..._fill}},
    {v: sections!==undefined?sections:'', t:'s', s:{ border: {                         bottom:_last}, ..._fill}},
    {v: history!==undefined?history:'',   t:'s', s:{ border: { right:{style:'medium'}, bottom:_last }, ..._fill}},
    {v: inp!==undefined?inp:'',           t:'s', s:{ border: { left:{style:'medium'}, bottom:_last }, ..._fill}},
    {v: sensor!==undefined?sensor:'',     t:'s', s:{ border: { right:{style:'medium'}, bottom:_last }, ..._fill}},
  ]
}
function GenerateMdiBox(MdiData:Mdi_Def) {
  var Max_Outputs:number = 3
  var Size = {outputs:80, inputs:80, history:80}
  var Sheet_Datas:any[][] = []

  var Header:any[] = [{},
    {v: 'ConSys ID',          t:'s', s:{ border: { top:{style:'medium'}, right:{style:'medium'}, bottom:{style:'medium'}, left:{style:'medium'} }}},
    {v: 'Output',             t:'s', s:{ border: { top:{style:'medium'}, left:{style:'medium'}, bottom:{style:'medium'}}}},
    {v: 'Valve no.',          t:'s', s:{ border: { top:{style:'medium'}, bottom:{style:'medium'}}}},
    {v: 'Section valve no.',  t:'s', s:{ border: { top:{style:'medium'}, bottom:{style:'medium'}}}},
    {v: 'Room name',          t:'s', s:{ border: { top:{style:'medium'}, bottom:{style:'medium'}}}},
    {v: 'Input',              t:'s', s:{ border: { top:{style:'medium'}, bottom:{style:'medium'}}}},
    {v: 'Sensor no.',         t:'s', s:{ border: { top:{style:'medium'}, right:{style:'medium'}, bottom:{style:'medium'}}}},
  ]

  if (MdiData && (MdiData.output||MdiData.input)) {
    if (MdiData.mdi === 0) {
      var Data:any[][] = [[{},
        {v: 'ConSys ID',          t:'s', s:{ border: { top:{style:'medium'}, right:{style:'medium'}, bottom:{style:'medium'}, left:{style:'medium'} }}},
        {v: 'Output',             t:'s', s:{ border: { top:{style:'medium'}, left:{style:'medium'}, bottom:{style:'medium'}}}},
        {v: 'Valve no.',          t:'s', s:{ border: { top:{style:'medium'}, bottom:{style:'medium'}}}},
        {v: 'Section valve no.',  t:'s', s:{ border: { top:{style:'medium'}, bottom:{style:'medium'}}}},
        {v: 'Room name',          t:'s', s:{ border: { top:{style:'medium'}, right:{style:'medium'}, bottom:{style:'medium'}}}},
      ]]
      for (let i = 0; i < MdiData.output[0].length; i++) {
        var last = i===MdiData.output[0].length-1
        var _fill = parseInt((i+1)+'')%2===0?{fill:{fgColor:{rgb:'d8d8e0'}}}:{}
        var _last = last?{style:'medium'}:{}

        Data.push([{},
          {v: i===0?'Dummy-Valves':'',        t:'s', s:i===0?{fill: {fgColor:{rgb:'FFFF00'}}}:{} },
          {v: 0,                              t:'s', s:{...{ border: { left:{style:'medium'}, bottom:_last }}, ..._fill}},
          {v: MdiData.output[0]&&MdiData.output[0][i]?MdiData.output[0][i]:'',           t:'s', s:{ border: { right:{style:'medium'}, bottom:_last }, ..._fill}},
          {v: MdiData.sectionvalves[0]&&MdiData.sectionvalves[0][i]?MdiData.sectionvalves[0][i]:'',        t:'s', s:{ border: { right:{style:'medium'}, bottom:_last }, ..._fill}},
          {v: MdiData.history_section[0]&&MdiData.history_section[0][i]?MdiData.history_section[0][i]:'',  t:'s', s:{ border: { right:{style:'medium'}, bottom:_last }, ..._fill}},
        ])
      }
      if (Data.length) Sheet_Datas = Sheet_Datas.concat(Data)
    }
    else {
      var Data:any[][] = []
      for (let i = 1; i <= 10; i++) {
        var Outputs = ''
        if (MdiData.output&&MdiData.output[i]&&MdiData.output[i].length) {
          Outputs = MdiData.output[i].join(' / ')
          if (Size.outputs < (MdiData.output[i].length * (80/3))) Size.outputs = MdiData.output[i].length * (80/3)
        }
        var Inputs = ''
        if (MdiData.input&&MdiData.input[i]&&MdiData.input[i].length) {
          Inputs = MdiData.input[i].join(' / ')
          if (Size.inputs < (MdiData.input[i].length * (80/3))) Size.outputs = MdiData.input[i].length * (80/3)
        }
        var History = ''
        if (MdiData.history_section&&MdiData.history_section[i]&&MdiData.history_section[i].length) {
          History = MdiData.history_section[i].join(' / ')
          if (Size.history < (MdiData.history_section[i].length * (80/3))) Size.history = MdiData.history_section[i].length * (80/3)
        }
        var Sections = ''
        if (MdiData.sectionvalves&&MdiData.sectionvalves[i]&&MdiData.sectionvalves[i].length) {
          Sections = MdiData.sectionvalves[i].join(' / ')
          //if (Size.sections < (MdiData.sectionvalves[i].length * (80/3))) Size.sections = MdiData.sectionvalves[i].length * (80/3)
        }

        Data.push(StyledDataRow({
          mdi:i===1?MdiData.mdi:undefined, 
          out:i, valve: Outputs&&!isNaN(Outputs)?parseInt(Outputs):Outputs, 
          sections:Sections, history:History,
          inp:i, sensor: Inputs&&!isNaN(Inputs)?parseInt(Inputs):Inputs,
          last:i===10
        }))
      }
      Data.splice(0, 0, Header)
      if (Data.length) Sheet_Datas = Sheet_Datas.concat(Data)
    }
  }
  return {Data:Sheet_Datas, Size:Size}
}
const GenerateConsysConfigExcel = (SysConfig:Record<string, Sys_Def>, File_name:string) => {
  var workbook = XLSX.utils.book_new()
  /* Initial row */
  var Sheet_Datas:any[][] = [[{}],[{}]]
  var ColSize = {outputs:80, inputs:80, history:80}
  Object.entries(SysConfig).forEach(([SysKey, Datas]) => {
    if (Datas && Datas.Mdis) {
      var MdiBoxes = Object.keys(Datas.Mdis).sort(NormSorter)
      MdiBoxes.forEach(MdiN => {
        var MdiNr = parseInt(MdiN)
        if (MdiNr) {
          var {Data, Size} = GenerateMdiBox(Datas.Mdis[MdiNr])
          if (Data.length) {
            if (Size.outputs > ColSize.outputs) ColSize.outputs = Size.outputs 
            if (Size.inputs > ColSize.inputs) ColSize.inputs = Size.inputs 
            if (Size.history > ColSize.history) ColSize.history = Size.history 
            Sheet_Datas = Sheet_Datas.concat(Data)
            Sheet_Datas = Sheet_Datas.concat([[{}],[{}]])
          }
        }
      })
      if (Datas.Mdis[0]) {
        var {Data, Size} = GenerateMdiBox(Datas.Mdis[0])
        if (Data.length) {
          if (Size.outputs > ColSize.outputs) ColSize.outputs = Size.outputs 
          if (Size.inputs > ColSize.inputs) ColSize.inputs = Size.inputs 
          if (Size.history > ColSize.history) ColSize.history = Size.history 
          Sheet_Datas = Sheet_Datas.concat(Data)
          Sheet_Datas = Sheet_Datas.concat([[{}],[{}]])
        }
      }
    }
  })
  
  const worksheet = XLSX.utils.aoa_to_sheet(Sheet_Datas)

  var wscols = [{wpx:20},{wpx:80},{wpx:60},{wpx:80},{wpx:90},{wpx:90},{wpx:60},{wpx:80}]
  worksheet['!cols'] = wscols
  /*var wsrows = []
  for (let index = 0; index < Sheet_Datas.length; index++) {
    wsrows.push({hpx: 20})
  }
  worksheet['!rows'] = wsrows*/

  XLSX.utils.book_append_sheet(workbook, worksheet, "Config")
  XLSX.writeFile(workbook, "xlsx-js-style-demo.xlsx")
}

const GetNameArr = (_FileDataText:string) => {
  var NameArr:string[] = []
  //var VariableFind = _FileDataText.matchAll(new RegExp(`(var|let|const)\\s(\\w+)`,'gm'))
  var VariableFind = _FileDataText.matchAll(new RegExp(`(\\/{0,2}\\s*)(var|let|const)\\s(\\w+)`,'gm'))

  for (const Variable of VariableFind) {
    if (Variable && Variable.length >= 3 && Variable[3] && Variable[1] !== "//") NameArr.push(Variable[3])
  }
  return NameArr
}

const ConsysConfigTool = (_FileDataText:string) => {
  var ProcessRes:stateMessage[] = []
  var Sys_Config:Record<string, Sys_Def> = {}

  var FileDataText = _FileDataText
  var NameArr:string[] = GetNameArr(_FileDataText)
  //Reload check
  if (!NameArr.includes('SYS_XY')) FileDataText = `var SYS_XY = {}\n` + FileDataText
  if (!NameArr.includes('STRAW')) FileDataText = `var STRAW = {}\n` + FileDataText
  if (!NameArr.includes('DRYFEED')) FileDataText = `var DRYFEED = {}\n` + FileDataText
  NameArr = GetNameArr(FileDataText)
  
  try {
    eval(FileDataText)
    var SYS_Def:Record<string,any> = {}

    for (const sysCheck of NameArr) {
      if (sysCheck.startsWith("SYS")) {
        SYS_Def[sysCheck] = eval(sysCheck)
      }
    }
    var SYSLength = Object.keys(SYS_Def).length
    if (!SYSLength) ProcessRes.push({txt:'Evaluation could not find any SYS definitions in config-file', state:states.Error})
    else {
      Object.entries(SYS_Def).forEach(([SYSKey, Datas]) => {
        var SYSNameArr:string[] = []
        var SYSVariableFind = FileDataText.matchAll(new RegExp(SYSKey+`.(\\w+)`,'gm'))
        for (const Variable of SYSVariableFind) {
          if (Variable && Variable.length >= 2 && Variable[1] && !SYSNameArr.includes(Variable[1])) SYSNameArr.push(Variable[1])
        }

        var Sections:SectionValve_Def[]|undefined
        var Valves:Valve_Def[]|undefined
        var Sensors:Sensor_Def[]|undefined
        var Status:stateMessage[] = [{txt:`Found Sys: ${SYSKey}`}]

        //#region Load Data Defs
        //Finding Section Defs
        var SectionValveDef_Name:string|undefined = Object.keys(Datas).find((e) => e.includes('Section_Valve_Definition'))
        if (!SectionValveDef_Name) {
          if (SYSLength === 1) {
            SectionValveDef_Name = NameArr.find(e => e.includes('Section_Valve_Definition'))
            if (SectionValveDef_Name) Sections = eval(SectionValveDef_Name)
          }
        }
        else Sections = Datas[SectionValveDef_Name]
        if (!Sections) Status.push({txt:`Could not find Section_Valve_Definition : ${SYSKey}`, state:states.Error})
        else {
          for (let x = 0; x < Sections.length; x++) {
            var SecVal = Sections[x].secvalve
            if (SecVal) {
              for (let find1 = 0; find1 < SYSNameArr.length; find1++) {
                var Name = SYSNameArr[find1]
                var val = eval(SYSKey +'.'+ Name)
                if (val === SecVal) {
                  Sections[x].name = Name
                  break
                }
              }
            }
            else {
              Status.push({txt:`Errpr definition: { secvalve: ${Sections[x].secvalve} mdi: ${Sections[x].mdi} output: ${Sections[x].output} }`, state:states.Error})
            }
          }
        }
        
        //Finding Valve Defs
        var ValveDef_Name:string|undefined = Object.keys(Datas).find((e) => e.includes('Valve_Definition') && !e.includes('Section'))
        if (!ValveDef_Name) {
          if (SYSLength === 1) {
            ValveDef_Name = NameArr.find(e => e.includes('Valve_Definition') && !e.includes('Section'))
            if (ValveDef_Name) Valves = eval(ValveDef_Name)
          }
        }
        else Valves = Datas[ValveDef_Name]
        if (!Valves) Status.push({txt:`Could not find Valve_Definition : ${SYSKey}`, state:states.Error}) 

        //Finding Sensor Defs
        var SensorDef_Name:string|undefined = Object.keys(Datas).find((e) => e.includes('Sensor_Definition'))
        if (!SensorDef_Name) {
          if (SYSLength === 1) {
            SensorDef_Name = NameArr.find(e => e.includes('Sensor_Definition'))
            if (SensorDef_Name) Sensors = eval(SensorDef_Name)
          }
        }
        else Sensors = Datas[SensorDef_Name]
        if (!Sensors) Status.push({txt:`Could not find Sensor_Definition : ${SYSKey}`, state:states.Error}) 
        //#endregion

        if (Sections && Valves && Sensors) {
          Sys_Config[SYSKey] = {
            Section_Valves: Sections,
            Valves: Valves,
            Sensors: Sensors
          }
          //#region Res Data Checks
          var Res_Text = "" 
          //Sections
          var Results:stateMessage[] = []
          var {Warnings_Txt, Status_Txt} = ConsysSectionChecks(Sections)
          if (Warnings_Txt) Status = Status.concat(Warnings_Txt)
          Results = Results.concat(Status_Txt)
          //Valves
          var {Warnings_Txt, Status_Txt} = ConsysValveChecks(Valves, Sections)
          if (Warnings_Txt) Status = Status.concat(Warnings_Txt)
          Results = Results.concat(Status_Txt)
          //Sensors
          var {Warnings_Txt, Status_Txt} = ConsysSensorChecks(Sensors, Valves)
          if (Warnings_Txt) Status = Status.concat(Warnings_Txt)
          Results = Results.concat(Status_Txt)
          //Mdi
          var {Warnings_Txt, Status_Txt, Mdis} = ConsysMdiChecks(Sections, Valves, Sensors)
          if (Warnings_Txt) Status = Status.concat(Warnings_Txt)
          Results = Results.concat(Status_Txt)

          Sys_Config[SYSKey].Mdis = Mdis
          //Result
          Results.splice(0,0,{txt:`System accepted containing: `})
          Status = Status.concat(Results)
          //#endregion
        }
        else {
          Status.push({txt:`System failed process`,state:states.Error})
        }
        ProcessRes = ProcessRes.concat(Status)
      })
    }
  } catch (e) {
    if (e instanceof SyntaxError || e instanceof ReferenceError) {
      console.error("Eval Error:", e, e.message)
      ProcessRes.push({txt:e.message, state:states.Error})
    } else {
      console.error("Eval Error:", e)
      if (e && e.message) ProcessRes.push({txt:e.message, state:states.Error})
    }
  }
  return {ProcessRes, Sys_Config}
}

const ToolsMain = () => {
  //const { t } = useTranslation()
  const { Common, Gutters, Layout, Colors } = useTheme()
  //const dispatch = useDispatch()
  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  //const activefarm = useAppSelector(({activeData}) => activeData.farm)

  const Reader = new FileReader()
  const [File, setFile] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [ProcessText, setProcessText] = useState<stateMessage[]>([])
  const [LoadedSysconfig, setLoadedSysconfig] = useState<Record<string, Sys_Def>|undefined>(undefined)

  const [isUploading, setIsUploading] = useState(false)
  const timeoutId = useRef<any|null>(null)
  function reset() {
    setIsUploading(false)
    clearTimeout(timeoutId.current)
    setFile("")
    setErrorMessage("")
    setProcessText([])
    setLoadedSysconfig(undefined)
  }
  function startProgress(acceptedFiles:File[]) {
    reset()
    if (acceptedFiles && acceptedFiles.length) {
      setFile(acceptedFiles[0].name)
      setIsUploading(true)
      Reader.readAsText(acceptedFiles[0])
      timeoutId.current = setTimeout(reset, 3000)
    }
    else {
      setErrorMessage("No accepted Files found on selected path.")
    }
  }

  function handleEvent(event, e) {
    console.log(`${event.type}: ${event.loaded} bytes transferred\n`)
    if (event.type === 'loadstart') {
      clearTimeout(timeoutId.current)
    }
    if (event.type === 'loadend') {
      setIsUploading(false)
      if (Reader.result) {
        var FileDataText:string = Reader.result
        if (["firebase", "Firebase", "React", "redux", "dispatch", "console", "Reader.", "eval"].some(e => FileDataText.includes(e))) {
          setErrorMessage("File is detected to contain illigal data calls,\nand can by sucurity means not be allow to validatet.")
        }
        else {
          var {ProcessRes, Sys_Config} = ConsysConfigTool(FileDataText)
          if (Sys_Config && Object.keys(Sys_Config).length) {
            setLoadedSysconfig(Sys_Config)
          }
          setProcessText(ProcessRes)
        }
      }
    }
  }
  function addListeners(reader) {
    reader.addEventListener('loadstart', handleEvent);
    reader.addEventListener('load', handleEvent);
    reader.addEventListener('loadend', handleEvent);
    reader.addEventListener('progress', handleEvent);
    reader.addEventListener('error', handleEvent);
    reader.addEventListener('abort', handleEvent);
  }
  useEffect(() => {
    if (Reader) addListeners(Reader)
  }, [Reader])

  useEffect(() => {
  },[])

  return(
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <View style={{marginTop:100}}/>
      <FileUploader
        errorMessage={errorMessage}
        onCancel={reset}
        onRetry={reset}
        onDrop={(acceptedFiles, rejectedFiles) => {
          //console.log("Files Droped", acceptedFiles, rejectedFiles)
          startProgress(acceptedFiles)
        }}
        accept={[".js"]}
        progressMessage={isUploading?`Reading File... Please wait.`:''}
      />
      {LoadedSysconfig?
        <View style={[Layout.fill, {marginTop:15, maxHeight:55}]}>
          <Button maxWidth={200} Contexts={"Create Excel sheet from config"} Action={() => GenerateConsysConfigExcel(LoadedSysconfig, File)}/>
        </View>
      :<></>}
      {ProcessText&&ProcessText.length?
        <View style={[Layout.fill, {marginVertical:15}]}>
          {ProcessText.map((process, index) => 
            <Text key={'msg_'+index} style={[process.state?process.state===states.Warning?{color:/*Colors.BUMBLEBEE*/'orange'}:process.state===states.Error?{color:Colors.error}:{}:{}]}>
              {process.txt}
            </Text>
          )}
        </View>
      :<></>} 
    </View>
  )
}

export default ToolsMain
