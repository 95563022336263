import React, { useEffect, useState } from 'react'
import {
  View,
  Clipboard
} from 'react-native'
import { Button, LoadingSplash, Labelled_Input } from '../../Components'
//import Clipboard from '@react-native-clipboard/clipboard'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, isApp } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch, ClearData } from '../../Store'
import { useFirebase, isLoaded, isEmpty, useFirebaseConnect } from 'react-redux-firebase'

import { UserData } from '../../Config/Types'
import { DataStringCheck } from '../../utils/formaters'
import { resetState } from '../../Store/StateHandlers/Active-slice'
import { ConsysApi } from '../../Services/Consys/Consys'
import { actionTypes } from 'redux-firestore'

const UserInformation = (MainProps:any) => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()

  //const MainParams = MainProps.route.params?MainProps.route.params:undefined
  const firebase = useFirebase()

  const SystemStatus = useAppSelector(({startup}) => startup)
  const auth = useAppSelector(({firebase}) => firebase.auth)
  const profile = useAppSelector<UserData>(({firebase}) => firebase.profile)

  const [Name, setName] = useState("")
  const [LName, setLName] = useState("")
  const [EMail, setEMail] = useState("")

  const [Phone, setPhone] = useState<string|number>("")
  const [Address, setAddress] = useState("")
  const [Country, setCountry] = useState("")

  useEffect(() => {
    if (SystemStatus.CookieLevel) {
      if (profile.name) setName(profile.name)
      if (profile.lastN) setLName(profile.lastN)
      if (profile.email) setEMail(profile.email)
      if (profile.address) setAddress(profile.address)
      if (profile.country) setCountry(profile.country)
      if (profile.phoneNumber) setPhone(profile.phoneNumber)
    }
  }, [profile])

  const dispatch = useAppDispatch()
  const logout_Handle = () => {
    firebase.logout()
    dispatch(resetState({state:"all"}))
    dispatch({type: actionTypes.CLEAR_DATA})
    dispatch(ConsysApi.util.resetApiState())
    ClearData(dispatch)
    //preserve: { data: ['todos'], ordered: ['todos'] }
  }

  const Save_Settings = () => {
    if (SystemStatus.CookieLevel) {
      if ((Name && Name !== "") && (LName && LName !== "")) {
        var info = {name:Name, lastN:LName, email:EMail!==""?EMail:null, address:Address!==""?Address:null, country:Country!==""?Country:null, phoneNumber:Phone!==""?Phone:null}
        firebase.updateProfile(info).then(e => {
          if (!MainProps.MainNavi) navigate('User', {screen:'Home'})
        }, e2=>{})
      }
    }
  }

  //Auth Check Handler
  useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth) || !isLoaded(profile)) return <LoadingSplash/>
  else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={[Layout.colVCenter, Layout.fullWidth]}>
          <Button Contexts={t('UserInfo.Title') + ": " + SystemStatus.CookieLevel?profile.name:""} Title/>
          <View style={[Layout.col, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px'}]}>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Labelled_Input disabled={!SystemStatus.CookieLevel} maxWidth={200} minWidth={120}
                value={Name}
                label={t('UserInfo.Prop1')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== "") {
                    if (DataStringCheck({data:text})) {
                      setName(text)
                    }
                  }else {
                    setName(text)
                  }
                }}
              />
              <Labelled_Input disabled={!SystemStatus.CookieLevel} maxWidth={200} minWidth={120}
                value={LName}
                label={t('UserInfo.Prop2')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text})) {
                      setLName(text)
                    }
                  }else {
                    setLName(text)
                  }
                }}
              />
            </View>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Labelled_Input disabled={!SystemStatus.CookieLevel} maxWidth={200} minWidth={120}
                value={Address}
                label={t('UserInfo.Prop5')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== "") {
                    if (DataStringCheck({data:text})) {
                      setAddress(text)
                    }
                  } else {
                    setAddress(text)
                  }
                }}
              />
              <Labelled_Input disabled={!SystemStatus.CookieLevel} maxWidth={200} minWidth={120}
                value={Country}
                label={t('UserInfo.Prop6')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text})) {
                      setCountry(text)
                    }
                  } else {
                    setCountry(text)
                  }
                }}
              />
            </View>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Labelled_Input disabled={!SystemStatus.CookieLevel} maxWidth={200} minWidth={120}
                value={EMail}
                label={t('UserInfo.Prop3')}
                placeholder={""}
                onChange={(text) => {
                  if (text && text !== ""){
                    if (DataStringCheck({data:text, AllowSpecial:true})) {
                      setEMail(text)
                    }
                  }else {
                    setEMail(text)
                  }
                }}
              />
              <Labelled_Input disabled={!SystemStatus.CookieLevel} maxWidth={200} minWidth={120}
                value={Phone +''}
                label={t('UserInfo.Prop4')}
                placeholder={""}
                onChange={(text, num) => {
                  setPhone(num)
                }}
              />
            </View>
            <View style={[Layout.havlWidth, {flexWrap:'wrap', flexDirection:'row', margin:10}]}>
              <Button Contexts={t("UserInfo.Copy")} maxWidth={200} Title Action={() => {
                Clipboard.setString(auth.uid)
              }}/>
            </View>
          </View>
        </View>
        <Button Contexts={[t('ActionButtons.Save'),MainProps.MainNavi?t('AgrisysMenu.Logout'):t('ActionButtons.Cancel')]} Frame Action={(e)=>!e?Save_Settings():MainProps.MainNavi?logout_Handle():navigate('User', {screen:'Home'})}/>
      </View>
    )
  }
}

export default UserInformation