// Need to use the React-specific entry point to import createApi
//import { createApi, fetchBaseQuery, BaseQueryEnhancer } from '@reduxjs/toolkit/query/react'
import { RootState, useAppSelector } from '../../Store'
import { ConsysRefs, DataConsData, isEmpty, TimeSpan } from '../../Config/Types'
import { Config_Pri } from './Config'
import { ConsysApi, ConsysArgs, GetAutos, GetTimeSets, MultiLoop} from './Consys' 
import { SystemInfo, SystemSlice } from './Systems-slice'

export interface WeightInfo {
  dataid: number,
  timestamp: number,
  valve?: number,
  amount: number,
  rfids?: string[]
}

export interface ScaleInfo extends WeightInfo {
  sumamount?:number,
  weightlist?: WeightInfo[],
  count?:number,
  subid?: number
}

export type ScaleList = Record<string, Record<string, {amount:number, count:number, timestamp:number, objArr:ScaleInfo[]}>>
//export type ScaleList = Record<string, Record<string, ScaleInfo>>
export interface ScaleProp extends ConsysArgs {
  FarmKey:string|undefined,
  FarmKeys?:string|string[],
  systemids?:string|string[]|{id:string,XTern:string}|{id:string,XTern:string}[],
  Xsystemids?:string[][] | {id:string,XTern:string}[][],
  valve:string|number,
  ViewPeriods:TimeSpan[]|TimeSpan|undefined,
  interval?:number,
  timerange?:'last30d'|'currentmonth'|'lastmonth'
}

export interface ResetProp extends ConsysArgs {
  FarmKey:string|undefined,
  systemids:string
  weight:number,
}

const _GetError = (dataText:string, statusText:string='Token retrieve error'):any => {
  return {error: {
    status: 500, statusText,
    data: dataText,
  }}
}

const DoqueryFn = async (args:ScaleProp, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (args.FarmKeys && typeof args.FarmKeys==="object" && !isEmpty(args.FarmKeys)) {
    var res = await MultiLoop(DoqueryFn, args, queryApi, extraOptions, fetchWithBQ)
    if (res && (!isEmpty(res.data) || !isEmpty(res.error))) return res
  }
  else {
    //const state = (queryApi.getState() as RootState)
    var Errors = null, Scaleres:(ScaleList | undefined)[] = []
    let valveId:string|null = args.valve!==undefined&&args.valve!==""?args.valve+'':null
    var getAutos = GetAutos(args, queryApi)

    //debugger
    Scaleres = await Promise.all(getAutos.map(async ([key, AutoPig], index) => {
      if (AutoPig && AutoPig.scale && AutoPig.scale !== "") {
        var Scales = AutoPig.scale.split(',').reduce((a:object, v:string) => ({ ...a, [v]: (AutoPig.scale.includes('all,')&&v!=='all')?false:true}), {})
        if ((!valveId || valveId === "all") || (Scales['all']?!Scales[valveId]:Scales[valveId])) {
          //debugger
          var Times = GetTimeSets(args, queryApi, index)
          //console.log("Name", key, "Times", Times.start, Times.end, args)
          let Result = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.SCALE, key, /*valveId?valveId:*/'all', {interval:args.interval, starttime:Times.end, stoptime:Times.start}), params:args})
          
          if (Result) {
            //console.log(Result)
            if (Result.error || (!Result.data || !Result.data.consumptiondata)) {
              Errors = _GetError(Result.error?Result.error.error:"")
            } else if (Result.data && Result.data.consumptiondata) {
              var ResScales = Result.data.consumptiondata as ScaleInfo[]

              var dataMap:ScaleList = {}
              var name = AutoPig.name?AutoPig.name:"SysId_" + key
              /*if (!isEmpty(ResScales) && ResScales[0].sumamount) {
                return dataMap[name] = ResScales
              }
              else {*/
                console.log("???", AutoPig, Times)
                ResScales.forEach((dataPoint) => {
                  if (dataPoint) {
                    const Time = new Date(dataPoint.timestamp * 1000)
                    if ((!Times.start || Time.getTime() > Times.start) && (!Times.end || Time.getTime() < Times.end)) {
                      const TimeName:string = Time.getFullYear() + "_" + (Time.getMonth()+1) + "_"+ Time.getDate()
                      const weight = (dataPoint.sumamount||dataPoint.amount) /1000
                      
                      //var name = dataPoint.pen?dataPoint.pen:AutoPig.name?AutoPig.name:"SysId_" + key
                      if (!dataMap[name]) dataMap[name] = {}
                      if (!dataMap[name][TimeName]) dataMap[name][TimeName] = { amount: weight, count: 1, timestamp: (Time.setHours(23,59,59)), objArr:[] }
                      else {
                        //Navg = avg0 + (( NVal - acg0) / Ncount)
                        dataMap[name][TimeName].amount = (dataMap[name][TimeName].amount + ( (weight - dataMap[name][TimeName].amount) / (dataMap[name][TimeName].count +1) ) )
                        dataMap[name][TimeName].count += 1
                      }
                      if (dataPoint.rfids) {
                        var Rfid:string[] = []
                        var ids:string[] = JSON.parse(dataPoint.rfids)
                        if (ids.length > 1) {
                          ids.forEach((tag:string, index) => {
                            if (index != 0) {
                              if (isNaN(tag)) {
                                //30161436 9c000000 063f1c6a
                                var Tag1 = Number("0x" + tag.substring(0,8))
                                var Tag2 = Number("0x" + tag.substring(8,16))
                                var Tag3 = Number("0x" + tag.substring(16,24))
                                Rfid.push(Tag1+""+Tag2+""+Tag3)
                              } else Rfid.push(tag)
                            }
                          })
                        }
                        dataPoint.rfids = Rfid
                      }
                      dataMap[name][TimeName].objArr.push(dataPoint)
                    }
                  }
                })
              //}
              return dataMap
            }
          } else Errors = _GetError("No Data Return")
        }
      }
    }))

    if (!Errors) {
      return {data: Scaleres.filter(e => e)}
    } else {
      return Errors
    }
  }
  return _GetError("No Site")
}

// Define a service using a base URL and expected endpoints
const ConsysApi_WithTag = ConsysApi.enhanceEndpoints({
  addTagTypes: ['Scale', 'ResetScale']
})
export const ScaleSlice = ConsysApi_WithTag.injectEndpoints({
  overrideExisting:true,
  endpoints: (builder) => ({
    getScaleList: builder.query<ScaleList[], ScaleProp|void>({
      async queryFn(args:ScaleProp, queryApi, extraOptions, fetchWithBQ) {
        var Data = await DoqueryFn(args, queryApi, extraOptions, fetchWithBQ)
        return Data
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Scale', Key})
        })
      },
    }),
    getScales: builder.query<ScaleList[], ScaleProp|void>({
      async queryFn(args:ScaleProp, queryApi, extraOptions, fetchWithBQ) {
        var Data:ScaleList[] = []
        if (!isEmpty(args.systemids)) {
          var Intern = await DoqueryFn({FarmKey:undefined, systemids:args.systemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          Data = Data.concat(Intern.data)
        }
        else if (!isEmpty(args.Xsystemids)) {
          var XTern = {}
          if (typeof args.FarmKeys==="object") XTern = await DoqueryFn({FarmKey:undefined, FarmKeys:args.FarmKeys, Xsystemids:args.Xsystemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          else XTern = await DoqueryFn({FarmKey:args.FarmKeys, Xsystemids:args.Xsystemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          if (XTern.data) Data = Data.concat(XTern.data)
        }
        
        //TODO: ERROR
        return {data:Data}
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Scale', Key})
        })
      },
    }),
    resetWeight: builder.query<any, ResetProp|void>({
      async queryFn(args:ResetProp, queryApi, extraOptions, fetchWithBQ) {
        //const state = (queryApi.getState() as RootState)
        var Errors = null, Scaleres:(true | undefined)[]|null = null
        var getAutos = GetAutos(args, queryApi)

        Scaleres = await Promise.all(getAutos.map(async ([key, AutoPig], index) => {
          console.log("getting", AutoPig, "status")
          let SysStatus = await fetchWithBQ({url:Config_Pri.GetSystem_URL(parseInt(key)), params:args})
          if (SysStatus && SysStatus.data) {
            var data = SysStatus.data as SystemInfo
            console.log("Status:", data.Online)
            if (data.Online) {
              console.log("sending", key, AutoPig)
              //if (AutoPig && AutoPig.scale && AutoPig.scale !== "") {
                let Result = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.RESETWEIGHT, key, /*valveId?valveId:*/'all', {interval:undefined, starttime:undefined, stoptime:undefined, weight:args.weight}), params:args})
                if (Result) {
                  if (Result && Result.data && Result.data.result && Result.data.result ==='OK') {
                    return true
                  }
                  else {
                    var Error = ''
                    if (Result) {
                      if (Result.error) Error = Result.error.error?Result.error.error:Result.error
                      else if (Result.data && Result.data.result) {
                        Error = Result.data.result
                      }
                    }
                    Errors = _GetError(Error, key + '# Have returned')
                  }
                } else Errors = _GetError("No Data Return")
              //}
            }
            else {
              Errors = _GetError("Not online", key + '#')
            }
          }
        }))
        if (!Errors) {
          return {data: Scaleres.filter(e => e)}
        } else {
          return Errors
        }
      },
      providesTags: (result, error, arg) => {
        return [({type: 'ResetScale', result})]
      },
    }),
  }),
})

export const GetScaleList = ScaleSlice.endpoints.getScaleList.useQuery
export const GetScales = ScaleSlice.endpoints.getScales.useQuery
export const ResetScaleWeight = ScaleSlice.endpoints.resetWeight.useLazyQuery