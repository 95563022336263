import { UnixDayTime, WeighingData, isEmpty, GraphData } from '../Config/Types';

export function AccumulateTime(Time:number|Date, Times:number = 1):Date {
  //86.400.000 ms = 1 day
  var NewTime = typeof Time === "object"?new Date(Time.getTime()):new Date(Time)
  NewTime.setDate(NewTime.getDate() + Times)
  return NewTime
}

function getLatestWeight(Weighings:any, AfterTime:number|null = null, BeforeTime:number|null = null):any {
  var Weighing:any = null;
  if (Weighings) {
    Object.entries(Weighings).forEach(async ([WeightId, WeightData]:[string,any]) => {
      if (WeightData && !WeightData.deleted && !WeightData.edited && (!Weighing || Weighing.time < WeightData.time)) {
        if ((!AfterTime || WeightData.time >= AfterTime) &&
          (!BeforeTime || WeightData.time <= BeforeTime)) {
          Weighing = WeightData
        }
      }
    })
  }
  return Weighing
}

function findSuroundingIndex(Curve:any, Point:number, atindex:boolean = false, debug:boolean =false):{pre:number, post:number} {
  var lastIndex:number|null = null
  for (let [_index, Data] of Object.entries(Curve)) {
    var index = parseInt(_index)
    if (lastIndex !== null) {
      if (atindex && index > Point) {
        return {pre:lastIndex, post:index}
      }
      else if (parseFloat(Data as string) > Point) {
        if (debug) console.log("res", Data, Point, lastIndex, index)
        return {pre:lastIndex, post:index}
      }
    }
    lastIndex = index
  }

  if (debug) console.log("no res")
  if (atindex) {
    if (lastIndex && Point > lastIndex) return {pre:parseInt(Object.keys(Curve)[Object.keys(Curve).length-2]), post:lastIndex}
    else return {pre:0, post:parseFloat(Object.keys(Curve)[1])}
  } else {
    if (lastIndex && Point > Curve[lastIndex]) return {pre:parseInt(Object.keys(Curve)[Object.keys(Curve).length-2]), post:lastIndex}
    else return {pre:0, post:parseFloat(Object.keys(Curve)[1])}
  }
}

//Get Slope Between two points
function GetSlope_Between(MinMax_Time:[number,number], MinMax_Data:[number,number]) {
  //Slope = DeltaY / DeltaX
  var slope = (MinMax_Data[1] - MinMax_Data[0]) / (MinMax_Time[1] - MinMax_Time[0])
  if (MinMax_Data[0] > MinMax_Time[0]) slope = -slope;
  return slope
}

//Get point expected data and virtual simulation from data.
//ExValue = Expected value for AtTime, if slope continues, for spots given (or inbetween).
//ExTime = Expected epoch time, there slope for given spots, will be ForData
export function get_DataSpot(MinMax_Time:[number,number], MinMax_Data:[number,number], AtTime?:number|Date, StartVal?:number, ForData?:number) {
  var ExValue:number|null = null
  var ExTime = 0
  if (!StartVal || StartVal === null) StartVal = MinMax_Data[0]

  var slope = GetSlope_Between(MinMax_Time, MinMax_Data)
  if (AtTime) {
    var time = typeof AtTime === "object"?AtTime.getTime():AtTime
    const FindX = time- MinMax_Time[0]
    // ExValue = (FindX * Slope) + MinY               // Setting FindTime, due b then = Min data.. (b is y = 0)
    ExValue = (FindX * slope) + StartVal          // ( X * a ) + b = Expeted data for spot on CalcTime
  }
  if (ForData) {
    ExTime = (ForData - StartVal) / slope          // (y-b)/a = Expeted time for data
    
    ExTime = MinMax_Time[0] + ExTime
    ExTime = Math.floor(ExTime)
  }

  return {ExValue, ExTime}
}

function ReCalcGCurve(GCurve:any):number[] {
  var Res = [0]
  if (GCurve && GCurve.length) {
    let CurveLenght = parseInt(Object.keys(GCurve)[Object.keys(GCurve).length-1])
    let initW = GCurve[0]
    for (let index = 1; index <= CurveLenght; index++) {
      var Points = findSuroundingIndex(GCurve, index, true)
      Res[index] = (
        (GCurve[Points.post] - GCurve[Points.pre]) / (Points.post - Points.pre)
      )
    }
  }
  return Res
}

export interface PredictRes {
  data:number[],
  time:number[],

  Now:number|null,
  TempEx:number|null,
  TargetForcast:number|null
}

//TODO: Needs Tests
function CurvePredict2(PenId:string, Weights:any, Curve:any, ExtraData?:{startdate?:number, _now?:number}):PredictRes|null {
  var GCurve = Curve?Curve.data?Curve.data:Curve:null 
  if (GCurve) {
    var GDeltaCurve = ReCalcGCurve(GCurve)
    var NowVal = ExtraData&&ExtraData._now?ExtraData._now:Date.now()

    var Target = GCurve[GDeltaCurve.length]
    if (!Target || Target <= 0) Target = 50

    var StartDate:number = Weights[0].time
    var EndDate:number = new Date().getTime() + UnixDayTime
    if (ExtraData && ExtraData.startdate) {
      StartDate = ExtraData.startdate
      EndDate = new Date(AccumulateTime(StartDate, GDeltaCurve.length+1)).getTime()
    }

    var PredictCurve:PredictRes = {
      data: [],                   //Curve for perdict TODO:transform [time:data]
      time: [],
      
      Now: null,                  //Current weight
      TempEx: null,               //Expected current value read from template
      TargetForcast: null,        
    }
    const CurrentDay = Math.floor(((NowVal - (StartDate?StartDate:0)) / UnixDayTime))

    PredictCurve.TempEx = GDeltaCurve.reduce((res:number, number, index) => {
      if (index < CurrentDay) return res += number
      else return res
    }, Weights[0].weight)

    const CurrentWeightDay =  Math.floor(((Weights[Weights.length-1].time - (StartDate?StartDate:0)) / UnixDayTime))
    PredictCurve.Now = GDeltaCurve.reduce((res:number, number, index) => {
      if (index > CurrentWeightDay && index < CurrentDay) return res += number
      else return res
    }, Weights[Weights.length-1].weight)

    var lastWeightTime = StartDate
    GDeltaCurve.forEach((Delta, index) => {
      var NewCurrentWeight = PredictCurve.data.length?PredictCurve.data[PredictCurve.data.length]+Delta:Delta
      var NewDate = AccumulateTime(lastWeightTime)

      PredictCurve.data.push(NewCurrentWeight) // data
      PredictCurve.time.push(NewDate.getTime()) //time

      lastWeightTime = NewDate.getTime()
    })
  }
  return null
}

function CurvePredict(PenId:string, Weights:any, Curve:any, ExtraData?:{startdate?:number, _now?:number}):PredictRes|null {
  var GCurve = Curve?Curve.data?Curve.data:Curve:null
  if (GCurve && Weights.length) {
    var NowVal = ExtraData&&ExtraData._now?ExtraData._now:Date.now()
    //--- Set Data for specefic pen
    var CurveLenght = parseInt(Object.keys(GCurve)[Object.keys(GCurve).length-1])

    //var Target = (BatchDatas&&BatchDatas.datas&&BatchDatas.datas.target)?BatchDatas.datas.target:GCurve[CurveLenght]
    var Target = GCurve[CurveLenght]
    if (!Target || Target <= 0) Target = 50

    var StartDate:number = Weights[0].time
    var EndDate:number = new Date().getTime() + UnixDayTime
    if (ExtraData && ExtraData.startdate) {
      StartDate = ExtraData.startdate
      EndDate = new Date(AccumulateTime(StartDate, CurveLenght+1)).getTime()
      //if (PenId && BatchDatas.pens && BatchDatas.pens[PenId] && BatchDatas.pens[PenId].startday) StartDate += BatchDatas.pens[PenId].startday * UnixDayTime
    }
    //---

    var PredictCurve:PredictRes = { 
      data: [],                   //Curve for perdict TODO:transform [time:data]
      time: [],
      
      Now: null,                  //Current weight
      TempEx: null,               //Expected current value read from template
      TargetForcast: null,        
    }

    //find TempEx
    const CurrentDay = Math.floor(((NowVal - (StartDate?StartDate:0)) / UnixDayTime))
    
    var Points = findSuroundingIndex(GCurve, CurrentDay, true)
    var data = get_DataSpot([AccumulateTime(StartDate, Points.pre).getTime(), AccumulateTime(StartDate, Points.post).getTime()], [GCurve[Points.pre], GCurve[Points.post]], NowVal)
    PredictCurve.TempEx = data.ExValue

    //starting point. aka newest weight point - S1
    var CurrentWeight = parseFloat(Weights[0].weight)
    var lastWeightTime = StartDate
    var LatestWeighing = getLatestWeight(Weights, StartDate, EndDate)
    if (LatestWeighing && LatestWeighing.weight) {
      CurrentWeight = parseFloat(LatestWeighing.weight)
      if (LatestWeighing.count) CurrentWeight = CurrentWeight / parseInt(LatestWeighing.count)
      lastWeightTime = LatestWeighing.time
    }
    
    //loop curve..
    var loopIndex = 1 // preventing ever loop... "dont know why or there ?"
    do {
      var Points = findSuroundingIndex(GCurve, CurrentWeight, false)
      var _date_ = AccumulateTime(lastWeightTime)
      var _Growth = (GCurve[Points.post] - GCurve[Points.pre]) / (Points.post - Points.pre)
      var GrowTime =  (_date_.getTime() - lastWeightTime) / UnixDayTime
      var WeightGrowth = _Growth * GrowTime
      var NewCurrentWeight = WeightGrowth + CurrentWeight

      PredictCurve.data.push(NewCurrentWeight) // data
      PredictCurve.time.push(_date_.getTime()) //time
      if (NewCurrentWeight <= Target) {
        PredictCurve.TargetForcast = AccumulateTime(_date_).getTime()
      }
      if (_date_.getTime() >= NowVal && !PredictCurve.Now) {
        if (PredictCurve.data.length > 1) {
          var Data = get_DataSpot([PredictCurve.time[PredictCurve.time.length-2], PredictCurve.time[PredictCurve.time.length-1]], [PredictCurve.data[PredictCurve.data.length-2], PredictCurve.data[PredictCurve.data.length-1]], new Date(NowVal))
          PredictCurve.Now = Data.ExValue
        }
      }

      if (CurrentWeight === NewCurrentWeight) break;
      CurrentWeight = NewCurrentWeight
      lastWeightTime = _date_.getTime()
      loopIndex++
    } while(loopIndex <= 500 && (EndDate && lastWeightTime <= EndDate)) //&& (CurrentWeight <= Target))
    return PredictCurve
  }
  return null
}

export interface CalcResult {
  Count: number,
  Weight: number,
  Growth: number,
  FirstWeight: WeighingData|undefined,
  LastWeightTime: number|undefined,
  Predict: PredictRes|null,
  Target: any|undefined
}
export function WeightsCalc(PenId: string, Weights:WeighingData[], GCurve:GraphData|undefined, DoPredict:boolean, ExtraData?:{startdate?:number,_now?:number}):CalcResult {
  var StartDate:number|undefined = ExtraData?.startdate, EndDate:number|null = null
  var PenCalc:CalcResult = {
    Count: 0,
    Weight: 0,
    Growth: 0,
    FirstWeight: undefined,
    LastWeightTime: undefined,
    Predict: null,
    Target: 30
  }

  var LastWeight:any|undefined = undefined
  var GrowthCount = 0
  if (!isEmpty(Weights)) {
    let Weighings = Weights.sort((a,b) => a.time<b.time?-1:1)
    Weighings.filter(e => !e.deleted && ! e.edited && ((!StartDate || !EndDate) || (e.time >= StartDate && e.time <= EndDate))).forEach(Weighing=> {
      var weight = parseFloat(Weighing.weight+'')
      if (Weighing.count) {
        PenCalc.Count = parseInt(Weighing.count+'')
        weight = weight / PenCalc.Count
      }
      PenCalc.Weight = weight
      if (LastWeight !== undefined) {
        weight = PenCalc.Weight - LastWeight
        PenCalc.Growth += weight
        GrowthCount++
      }
      LastWeight = PenCalc.Weight
      if (!PenCalc.LastWeightTime || Weighing.time > PenCalc.LastWeightTime) PenCalc.LastWeightTime = Weighing.time
      if (!PenCalc.FirstWeight || Weighing.time < PenCalc.FirstWeight.time) PenCalc.FirstWeight = Weighing
      //return [weight, Weighing.time]
    })

    if (DoPredict && GCurve) { //&& Weights.length > 1) {
      var Predict = CurvePredict(PenId, Weights, GCurve, ExtraData)
      if (Predict) {
        var NowVal = ExtraData&&ExtraData._now?ExtraData._now:Date.now()
        PenCalc.Predict = Predict
        //If Predice.now time newer than lastWeightDay ... do calc add for growth and add newest weight
        if (PenCalc.Predict && PenCalc.Predict.Now && (PenCalc.LastWeightTime && NowVal >= new Date(PenCalc.LastWeightTime).getDate())) {
          var PenWeight = PenCalc.Predict.Now
          if (LastWeight !== undefined) {
            PenCalc.Growth += PenWeight - LastWeight
            GrowthCount++;
          }
          LastWeight = PenWeight
          PenCalc.Weight = PenWeight
          PenCalc.LastWeightTime = NowVal
        }
      }
    }

    if (GrowthCount > 0 && PenCalc.LastWeightTime && PenCalc.FirstWeight) {
      const days = (PenCalc.LastWeightTime - PenCalc.FirstWeight.time) / UnixDayTime
      PenCalc.Growth = PenCalc.Growth/days
    }
  }

  return PenCalc
}