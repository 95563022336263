import React, { useEffect, useMemo, useState } from 'react'
import { View, Text, TouchableOpacity, Image } from 'react-native'
import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'

//import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import { useAppSelector, useAppDispatch } from '../../Store'
import { FarmData, SystemState, SystemStates } from '../../Config/Types'
import { Button } from '../../Components'

import { SystemInfo, SystemList } from '../../Services/Consys/Systems-slice'
import { ConsysApi } from '../../Services/'
import { isEmpty } from 'lodash'
import { ContentPages } from '../../Containers'

export interface Consys_System_Props {
  setView: React.Dispatch<React.SetStateAction<ContentPages | null>>
  System?: SystemInfo,
  Update?: (data:{System:SystemInfo}) => void,
}

const Consys_System = ({setView, System, Update, ...rest}: Consys_System_Props) => {
  const { t } = useTranslation()
  const { Layout, Colors, Images, Common, Fonts } = useTheme()

  //const firebase = useFirebase()
  //const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  const farms = useAppSelector(({firebase: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  const farmData = useAppSelector<FarmData|null>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  //const [SystemState, setSystemState] = useState(SystemStates.UNKNOWN)
  /*const [GetSystemInfo, {SystemInfoLoading}, lastPromiseInfo] = ConsysApi.System.GetSystemInfo({selectFromResult:({ error, data, isUninitialized, isSuccess, isError, originalArgs, isLoading }) => {
    if (!isUninitialized && (isSuccess||isError)) {
      if (isError) console.log("Con isError:", error)
      else if (isSuccess) console.log("Con Success:", data)
    }
    return {SystemInfoLoading: isLoading}
  }})*/
  
  const [SystemState, setSystemState] = useState(SystemStates.UNKNOWN)
  const {data: ConSystemList, error: ConSystemListError, isUninitialized: NoConSystemList} = ConsysApi.System.GetSystemList({FarmKey:undefined, getInfos:false}, {skip:((!farmData||!farmData.site||!farmData.refToken)||SystemState===SystemStates.ERROR), pollingInterval:30 *1000})
  useEffect(() => {
    if (ConSystemListError) setSystemState(SystemStates.ERROR)
    return () => {
      // Clean-Up
    }
  },[ConSystemListError])
  useEffect(() => {
    if (!NoConSystemList && ConSystemList && !isEmpty(ConSystemList)) {
      setSystemState(SystemStates.WORKING)
    } 
    else setSystemState(SystemStates.UNKNOWN)
  },[ConSystemList])
  
  //const Settings = useAppSelector(({firebase: {data}}) => data.system?data.system:undefined)
  /*const newSystemState:SystemState = useMemo(() => {
    if (!NoConSystemList && ConSystemList) {
      //console.log("Called", ConSystemList)
      let State:SystemStates = ConSystemList.length?SystemStates.WORKING:SystemStates.UNKNOWN

      //Check if Error
      console.log(ConSystemList)
      if (ConSystemList.some((Info:SystemInfo) => Info.SystemMainState&&[0,11,12].includes(Info.SystemMainState))) State = SystemStates.ERROR
      //If Unknown
      //else if (ConSystemList.some((Info:SystemInfo) => Info.SystemMainState&&[-1].includes(Info.SystemMainState))) State = SystemStates.UNKNOWN

      setSystemState(State)
      return State
    }
    return SystemStates.UNKNOWN
  }, [ConSystemList])*/

  useEffect(() => {
    //GetSystemInfo({id:1})
    //if (!System) GetCheckToken() // could add farmdata
    return () => {
      // Clean-Up
    }
  },[])

  return(
    <View style={[Layout.fullSize, Layout.rowCenter, {maxHeight:70} ]}>
      <TouchableOpacity style={[Layout.fill, Common.button.filledRounded, {minHeight:60, marginLeft:2}]} 
        onPress={() => {
          if (SystemState === SystemStates.ERROR) setSystemState(SystemStates.UNKNOWN)
          setView(ContentPages.SystemBoard)
        }}
      >
        <View style={[Layout.fill, Layout.rowCenter]}>
          <Text style={[Layout.fill, Fonts.textCenter, Fonts.textSmall, Fonts.textReverse, {textAlign:'center', textAlignVertical:'center'}]}>{t('AgrisysMenu.SystemBoard')}</Text>
        </View>
      </TouchableOpacity>
      <TouchableOpacity style={[Layout.fill, Common.button.filledRounded, {minHeight:60, paddingHorizontal:5, marginRight:2}]} 
        onPress={() => {
          if (SystemState === SystemStates.ERROR) setSystemState(SystemStates.UNKNOWN)
          setView(ContentPages.SystemBoard)
        }}
      >
        <View style={[Layout.fill, Layout.fullWidth, {height:50, alignItems:'center',justifyContent:'center', marginTop:'5px', marginBottom:'5px'}]}>
          <Image style={[{height:'100%', width:'100%', maxWidth:55}]} resizeMode={'contain'}
            source={{uri:(
              SystemState===SystemStates.WORKING?Images.AlarmGreen:
              SystemState===SystemStates.ERROR?Images.AlarmRed:
              Images.AlarmBell
            )}}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}

export default Consys_System