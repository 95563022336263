import { UnixDayTime, isEmpty, DataConsData, isDemoFarm, ConsysRefs } from '../../Config/Types'
import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import { ScaleInfo, ScaleList } from '../Consys/Scale-Slice'
import { FeedInfo, SiloInfo } from '../Consys/Feed-Slice'
import { TemperatureInfo } from '../Consys/Temperature-Slice'
import { WaterInfo } from '../Consys/Water-Slice'

export const fetchDataCons = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = [], IsDataLink:string|undefined):any[] => {
  //const activefarm = useAppSelector(state => state.activeData.farm)
  if (FarmId && FarmId !== "") {
    const path = `farms_data/${IsDataLink?IsDataLink:FarmId}/`
    if (IsDataLink) {
      // TODO: .... datacons not weight ??

      /*const LinkListData = useAppSelector(({firebase: {data}}) => data.LinkListData[IsDataLink]?data.LinkListData[IsDataLink].sections:null)
      const LinkList = useAppSelector(({firebase: {data}}) => data.LinkList[IsDataLink]?data.LinkList[IsDataLink].sections:null)
      Object.entries(LinkListData).forEach(([Id, Data]) => {
        if (LinkList[Id] && LinkList[Id].pens) {
            LinkList[Id].pens.forEach((PenId:number) => {Calls.push({path:path + 'weighings', queryParams: ['orderByChild=penTime', `startAt=${PenId}#${(LinkListData.starttime||0)}`, `endAt=${PenId}#${(LinkListData.endtime||9999999999999)}`], storeAs: `LinkListData/${(IsDataLink)}/weighings/${PenId}`})
          })
        }
      })*/
      //Calls.push({path:path + 'weighings', queryParams: ['orderByChild=time'], storeAs: `LinkListData/${(IsDataLink)}/weighings`})
    }
    else {
      Calls.push({path:path + 'DataCons', queryParams: ['orderByKey'], storeAs: 'DataCons'})
    }
    if (isDemoFarm(FarmId)) {
      Calls.push({path:`DemoData/Autopigs/`,   queryParams: [], type: 'once', storeAs: `DemoData/Autopigs/`})
    }
  }

  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

const Sort_Config = (Data:Record<number, DataConsData>) => {
  return Object.fromEntries(Object.entries(Data).filter(([Key, Datas]) => Key && Key !== "Sys_Config"))
}
export const GetInternDataCons = createSelector(
  [
    (state:any, AutopigId?:number) => get(state.activeData, 'farm', undefined),
    (state:any, AutopigId?:number) => get(state.firebase.data, `DataCons`, undefined),
    (state:any, AutopigId?:number) => AutopigId,
  ],
  (FarmId:string|undefined, DataCons:Record<number,DataConsData>, AutopigId:number|undefined) => {
    if (DataCons) {
      if (AutopigId) {
        if (DataCons[AutopigId]) return DataCons[AutopigId]
      }
      else return Sort_Config(DataCons)
    }

    return undefined
  }
)

export const GetExternDataCons = createSelector(
  [
    (state:any, IsDataLink?:string, AutopigId?:number, FullData?:boolean) => get(state.activeData, 'farm', undefined),
    (state:any, IsDataLink?:string, AutopigId?:number, FullData?:boolean) => get(state.firebase.data, `LinkListData`, undefined),
    (state:any, IsDataLink?:string, AutopigId?:number, FullData?:boolean) => IsDataLink,
    (state:any, IsDataLink?:string, AutopigId?:number, FullData?:boolean) => AutopigId,
    (state:any, IsDataLink?:string, AutopigId?:number, FullData?:boolean) => FullData,
  ],
  (FarmId:string|undefined, LinkList:Record<string, any>, IsDataLink:string|undefined, AutopigId:number|undefined, FullData:boolean = true):Record<string, DataConsData>|DataConsData|undefined => {
    if (LinkList && !isEmpty(LinkList)) {
      if (IsDataLink) {
        if (!FullData && LinkList[IsDataLink] && LinkList[IsDataLink]["DataCons"]) {
          if (AutopigId) {
            if (LinkList[IsDataLink]["DataCons"][AutopigId]) return LinkList[IsDataLink]["DataCons"][AutopigId]
          }
          else return Sort_Config(LinkList[IsDataLink]["DataCons"])
        } else if (LinkList[IsDataLink]) {
          return LinkList[IsDataLink]
        }
      }
      else if (!FullData) {
        var DataCons:Record<string, DataConsData> = {}
        Object.entries(LinkList).forEach(([Key, Datas]) =>{
          if (Datas["DataCons"]) {
            DataCons[Key] = Datas["DataCons"]
          }
        })
        if (!isEmpty(DataCons)) return Sort_Config(DataCons)
      } else {
        return LinkList
      }
    }

    return undefined
  }
)

export const GetDataCons = createSelector(
  [
    (state:any, IsDataLink?:string, AutopigId?:number) => get(state.activeData, 'farm', undefined),
    (state:any, IsDataLink?:string, AutopigId?:number) => IsDataLink?GetExternDataCons(state, IsDataLink, AutopigId, false):get(state.firebase.data, `DataCons`, {}),
    (state:any, IsDataLink?:string, AutopigId?:number) => get(state.firebase.data, `DemoData.Autopigs`, {}),
    (state:any, IsDataLink?:string, AutopigId?:number) => IsDataLink,
    (state:any, IsDataLink?:string, AutopigId?:number) => AutopigId,
  ],
  (FarmId:string|undefined, DataCons, Demo:Record<number,number[]>, IsDataLink:string|undefined, AutopigId:number|undefined) => {
    if (DataCons && FarmId) {
      if (IsDataLink || AutopigId) {
        if (IsDataLink) {
          if (AutopigId) {
            if (DataCons[AutopigId]) return DataCons[AutopigId]
            else return DataCons
          }
        }
        else if (AutopigId) {
          if (DataCons[AutopigId]) return DataCons[AutopigId]
        }
        else {
          return DataCons
        }
      }
    }
    return undefined
  }
)

export const GetDataCons_Pen = createSelector(
  [
    (state:any, PenId:number, IsDataLink?:string, AllowTypes:ConsysRefs[]=[ConsysRefs.SCALE,ConsysRefs.CORRIDOR]) => get(state.activeData, 'farm', undefined),
    (state:any, PenId:number, IsDataLink?:string, AllowTypes:ConsysRefs[]=[ConsysRefs.SCALE,ConsysRefs.CORRIDOR]) => get(state.firebase.data, IsDataLink?`LinkListData.${IsDataLink}.DataCons`:`DataCons`, {}),
    (state:any, PenId:number, IsDataLink?:string, AllowTypes:ConsysRefs[]=[ConsysRefs.SCALE,ConsysRefs.CORRIDOR]) => get(state.firebase.data, IsDataLink?`LinkListData.${IsDataLink}.DataCons.Sys_Config`:`DataCons.Sys_Config`, {}),
    (state:any, PenId:number, IsDataLink?:string, AllowTypes:ConsysRefs[]=[ConsysRefs.SCALE,ConsysRefs.CORRIDOR]) => get(state.firebase.data, `DemoData.Autopigs`, {}),
    (state:any, PenId:number, IsDataLink?:string, AllowTypes:ConsysRefs[]=[ConsysRefs.SCALE,ConsysRefs.CORRIDOR]) => PenId,
    (state:any, PenId:number, IsDataLink?:string, AllowTypes:ConsysRefs[]=[ConsysRefs.SCALE,ConsysRefs.CORRIDOR]) => IsDataLink,
    (state:any, PenId:number, IsDataLink?:string, AllowTypes:ConsysRefs[]=[ConsysRefs.SCALE,ConsysRefs.CORRIDOR]) => AllowTypes
  ],
  (FarmId:string|undefined, DataCons:Record<number,DataConsData>, SysConfigs:any, Demo:Record<number,number[]>, PenId, IsDataLink, AllowTypes):Record<number, DataConsData>|undefined => {
    var res:Record<number, DataConsData>|undefined = undefined
    if (AllowTypes && AllowTypes.length) {
      if (DataCons){
        res = Object.entries(DataCons).reduce((res: Record<number, DataConsData>, [key, Data]) => {
          var append = false
          if (AllowTypes.includes(ConsysRefs.SCALE) && Data.scale && (Data.scale==='All' || Data.scale.split(',').includes(PenId+''))) append = true
          else if (AllowTypes.includes(ConsysRefs.TEMPERATURE) && Data.temp && (Data.temp==='All' || Data.temp.split(',').includes(PenId+''))) append = true
          else if (AllowTypes.includes(ConsysRefs.WATERCOUNT) && Data.water && (Data.water==='All' || Data.water.split(',').includes(PenId+''))) append = true
          else if (AllowTypes.includes(ConsysRefs.VALVEFEED) && Data.feed && (Data.feed==='All' || Data.feed.split(',').includes(PenId+''))) append = true
          //... MoreOptions
          if (append) res[key] = {...DataCons[key], xTern:IsDataLink}
          return res
        },{})
      }
      if (AllowTypes && AllowTypes.includes(ConsysRefs.CORRIDOR) && SysConfigs && SysConfigs.Corridors) {
        //var Corridors = Object.entries(DataCons).reduce((res: Record<number, DataConsData>, [key, Data]) => (), {})
        //res = Object.assign({}, Corridors, res)
        if (!res) res = {}
        var Corridors = SysConfigs.Corridors.split(',')
        if (Corridors) {
          Corridors.forEach(element => {
            var key = parseInt(element)
            if (res[key]) {
              res[key].scale="All"
              res[key].isCorridor=true
            }
            else res[key] = {name:'Corridor#'+key, scale:'All', isCorridor:true, xTern:undefined} as DataConsData
          })
        }
      }
    }
    return res
  }
)

export const GetPenDataCon = createSelector(
  [
    (state:any, IsDataLink?:string, PenAutopigString?:string) => get(state.activeData, 'farm', undefined),
    (state:any, IsDataLink?:string, PenAutopigString?:string) => get(state.firebase.data, IsDataLink?`LinkListData.${IsDataLink}.DataCons`:`DataCons`, {}),
    (state:any, IsDataLink?:string, PenAutopigString?:string) => get(state.firebase.data, `DemoData.Autopigs`, {}),
    (state:any, IsDataLink?:string, PenAutopigString?:string) => IsDataLink,
    (state:any, IsDataLink?:string, PenAutopigString?:string) => PenAutopigString,
    (state:any, IsDataLink?:string, PenAutopigString?:string, isPenId?:boolean) => isPenId
  ],
  (FarmId:string|undefined, DataCons:Record<number,DataConsData>, Demo:Record<number,number[]>, IsDataLink, AutopigString, isPenId=false):Record<number, DataConsData>|undefined => {
    var res:Record<number, DataConsData> | undefined = {}
    if (DataCons) {
      if (isPenId) {
        res = Object.entries(DataCons).filter(([key, datas]) =>
          datas&&AutopigString&&AutopigString!==""&& ((datas.feed&&(datas.feed.split(',').includes(AutopigString)||datas.feed==="All")) || (
            (datas.scale&&datas.scale.split(',').includes(AutopigString)) || (datas.activity&&datas.activity.split(',').includes(AutopigString)) || 
            (datas.temp&&datas.temp.split(',').includes(AutopigString)) || (datas.water&&datas.water.split(',').includes(AutopigString))
          ))
        ).reduce((res:Record<number, DataConsData>, key:any) => (res[key[0]] = {...DataCons[key[0]], xTern:IsDataLink}, res), {})
      }
      else {
        res = AutopigString&&!isEmpty(DataCons)?
          Object.keys(DataCons).filter((key) => AutopigString==="All"||AutopigString.split(',').includes(key))
          .reduce((res:Record<number, DataConsData>, key:any) => (res[key] = {...DataCons[key], xTern:IsDataLink}, res), {})
        :undefined
      }
    }
    return res
  }
)

export type DemoDataConData = {
  feed?:string[],
  scale?:number[],
  temp?:number[],
  activity?:number[],
  water?:number[]
}[]

export const GetDemoData = createSelector(
  [
    (state:any, DataType:ConsysRefs, AutopigString?:string|string[]) => get(state.activeData, 'farm', undefined),
    (state:any, DataType:ConsysRefs, AutopigString?:string|string[]) => get(state.firebase.data, `DemoData.Autopigs`, []),
    (state:any, DataType:ConsysRefs, AutopigString?:string|string[]) => DataType,
    (state:any, DataType:ConsysRefs, AutopigString?:string|string[]) => AutopigString
  ],
  (FarmId:string|undefined, Demo:DemoDataConData, DataType:ConsysRefs, AutopigString?:string|string[]) => {
    if (FarmId && isDemoFarm(FarmId)) {
      var APString = AutopigString?typeof AutopigString==="string"?AutopigString.split(','):AutopigString:undefined
      var dataMap:any
      switch(DataType) {
        case ConsysRefs.VALVEFEED:
          dataMap = []
          Demo.forEach((Data, index) => {
            if (Data && Data.feed && (APString && ((APString.length === 1 && APString[0]==='all')||APString.includes(index+'')))) {
              //var name = index+""
              Object.entries(Data.feed).forEach(([Key, _Datas]) => {
                const Time = new Date(Date.now() - (parseInt(Key) * UnixDayTime))
                //const TimeName:string = Time.getFullYear() + "_" + (Time.getMonth()+1) + "_"+ Time.getDate()

                var Datas = _Datas.split('$')
                var Silolist:SiloInfo[] = []
                var SumA:number = 0
                if (Datas[2]) {
                  Datas[2].split('~').forEach((Silo:string) => {
                    var SiloData = Silo.split('#')
                    var amount = parseFloat(SiloData[1])
                    SumA += amount
                    Silolist.push({siloname:SiloData[0], silono:parseInt(SiloData[0]), amount:amount} as SiloInfo)
                  })
                }

                var FeedData:FeedInfo = {dataid:parseInt(Key), valve:parseInt(Datas[0]), sumamount:SumA, wateramount:parseFloat(Datas[1]), silolist:Silolist, timestamp:Time.setHours(23,59,59)}
                dataMap.push(FeedData)
              })
            }
          })
          break;
        case ConsysRefs.SCALE:
          dataMap = {}
          Demo.forEach((Data, index) => {
            if (Data && Data.scale && (APString && ((APString.length === 1 && APString[0]==='all')||APString.includes(index+'')))) {
              var name = "SysId_" + index
              Object.entries(Data.scale).forEach(([Key, weight]) => {
                const Time = new Date(Date.now() - (parseInt(Key) * UnixDayTime))
                const TimeName:string = Time.getFullYear() + "_" + (Time.getMonth()+1) + "_"+ Time.getDate()
                
                var ScaleData:ScaleInfo = {dataid:parseInt(Key), amount: weight, count: 1, timestamp: (Time.setHours(23,59,59)) }

                if (!dataMap[name]) dataMap[name] = {}
                if (!dataMap[name][TimeName]) dataMap[name][TimeName] = ScaleData
                else {
                  //Navg = avg0 + (( NVal - acg0) / Ncount)
                  dataMap[name][TimeName].amount = (dataMap[name][TimeName].amount + ( (weight - dataMap[name][TimeName].amount) / (dataMap[name][TimeName].count +1) ) )
                  dataMap[name][TimeName].count += 1
                }
                //dataMap[name][TimeName].objArr.push(dataPoint)
              })
            }
          })
          break;
        case ConsysRefs.TEMPERATURE:
          dataMap = {}
          Demo.forEach((Data, index) => {
            if (Data && Data.temp && (APString && ((APString.length === 1 && APString[0]==='all')||APString.includes(index+'')))) {
              var name = "SysId_" + index
              Object.entries(Data.temp).forEach(([Key, temp]) => {
                const Time = new Date(Date.now() - (parseInt(Key) * UnixDayTime))
                const TimeName:string = Time.getFullYear() + "_" + (Time.getMonth()+1) + "_"+ Time.getDate()
                
                var TempData:TemperatureInfo = {dataid:parseInt(Key), amount: temp, count: 1, timestamp: (Time.setHours(23,59,59))}

                if (!dataMap[name]) dataMap[name] = {}
                if (!dataMap[name][TimeName]) dataMap[name][TimeName] = TempData
                else {
                  //Navg = avg0 + (( NVal - acg0) / Ncount)
                  dataMap[name][TimeName].amount = (dataMap[name][TimeName].amount + ( (temp - dataMap[name][TimeName].amount) / (dataMap[name][TimeName].count +1) ) )
                  dataMap[name][TimeName].count += 1
                }
                //dataMap[name][TimeName].objArr.push(dataPoint)
              })
            }
          })
          break;
        case ConsysRefs.WATERCOUNT:
          dataMap = {}
          Demo.forEach((Data, index) => {
            if (Data && Data.water && (APString && ((APString.length === 1 && APString[0]==='all')||APString.includes(index+'')))) {
              var name = "SysId_" + index
              Object.entries(Data.water).forEach(([Key, water]) => {
                const Time = new Date(Date.now() - (parseInt(Key) * UnixDayTime))
                const TimeName:string = Time.getFullYear() + "_" + (Time.getMonth()+1) + "_"+ Time.getDate()
                
                var WaterData:WaterInfo = {dataid:parseInt(Key), amount: water, count: 1, timestamp: (Time.setHours(23,59,59))}

                if (!dataMap[name]) dataMap[name] = {}
                if (!dataMap[name][TimeName]) dataMap[name][TimeName] = WaterData
                else {
                  //Is not average, is full consumption
                  dataMap[name][TimeName].amount += water
                  dataMap[name][TimeName].count += 1
                }
                //dataMap[name][TimeName].objArr.push(dataPoint)
              })
            }
          })
          break;
        case ConsysRefs.ACTIVITY:
          break;
      }
    }
    return {data:[dataMap].filter(e => e)}
  }
)