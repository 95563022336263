import React, { useEffect, useState } from 'react'
import {
  View,
  Dimensions,
  ScrollView
} from 'react-native'
import { Brand, Button, LoadingSplash, Consys_System } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, navigateAndSimpleReset, isApp } from '../../Navigators/Root'

import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'

import { useAppSelector, useAppDispatch, ClearData } from '../../Store'
//import { FarmData } from '../../Config/Types'
import { resetState } from '../../Store/StateHandlers/Active-slice'
import { isDemoFarm, UserData, UserStates } from '../../Config/Types'
import { ConsysApi } from '../../Services/Consys/Consys'
import { ContentPages } from '../'

interface MenuView_Props {
  nologo?:boolean,
  setView: React.Dispatch<React.SetStateAction<ContentPages | "Presentation" | null>>
}
const MenuView = (props:MenuView_Props) => {
  const { t } = useTranslation()
  const { Layout, Images, Gutters, Common } = useTheme()
  
  const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)

  const profile = useAppSelector<UserData>(({firebase}) => firebase.profile)
  //const farms = useAppSelector(({firestore: { data }}) => data.farms)
  const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const dispatch = useAppDispatch()
  const logout_Handle = () => {
    ClearData(dispatch)
    dispatch(resetState({state:"all"}))
    dispatch({type: actionTypes.CLEAR_DATA})
    dispatch(ConsysApi.util.resetApiState())
    //preserve: { data: ['todos'], ordered: ['todos'] }
    firebase.logout()
  }

  const [WindowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWindowWidth(window.width)
    });
    return () => subscription?.remove()
  })

  //Auth Check Handler
  useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth) /*|| !isLoaded(profile) || !isLoaded(farms)*/) return <LoadingSplash/>
  else {
    let MenuTexts = [t('AgrisysMenu.DashBoard'), t('AgrisysMenu.Pens'), t('AgrisysMenu.Reports'), t('AgrisysMenu.Growth'), t('AgrisysMenu.Operations'), t('AgrisysMenu.Autofunctions'), t('AgrisysMenu.Settings')]//, t('AgrisysMenu.FeedingFunctions'), t('AgrisysMenu.Pigs'),]
    let MenuIcons = [Images.DashBoard, Images.Pens, /*Images.Section,*/ Images.Logbook, Images.GCurves, Images.Tasks, Images.AutoPig, Images.System] //, Images.Feed, Images.Sow]
    let Pages:ContentPages[] = [ContentPages.DashBoard,ContentPages.Pens,/*"Batchs",*/ContentPages.Reports,ContentPages.Growth,ContentPages.Operations,ContentPages.Autopigs,ContentPages.Settings] //,"Feeding","Pigs"]

    if (isLoaded(profile) && !isEmpty(profile)) {
      if (profile.state && profile.state === UserStates.HORNSYLD) {
        //MenuTexts.splice(0, 1)
        //Pages.splice(0, 1)  //Dashboard 'Data law and Workers-list ??'
        
        //MenuTexts.splice(2, 1)
        //Pages.splice(2, 1) //Growth
        //MenuTexts.splice(4, MenuTexts.length -4)
        //Pages.splice(4, Pages.length -4) //
      }
    }

    return(
      <View style={[Layout.fillCol, Layout.fullSize, Gutters.smallV_Margin, {zIndex:2, minHeight:'max-content', minWidth:(WindowWidth>980?'240px':'260px'), maxWidth:(WindowWidth>980?240:350)}]}>
        {props.nologo?(<View style={{minHeight: 10}}/>):(
          <View style={[{ marginVertical:15, marginHorizontal: 15, minHeight:'max-content'}]}>
            <Brand width={'100%'}/>
          </View>
        )}
        <ScrollView style={[Layout.fillCol, Layout.fullSize, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'max-content', backgroundColor:'#f2f2f2'}]} contentContainerStyle={[Layout.fillCol, {justifyContent:'flex-start', minHeight:'max-content', minWidth:'max-content'}]}>
          <View style={[Layout.fullWidth, {minHeight:'max-content', maxWidth:(WindowWidth>980?240:350), marginVertical:10, alignSelf:'center', justifyContent:'flex-end'}]}>
            <Consys_System setView={props.setView}/>
          </View>

          {/* <Text style={[Fonts.textRegular, {paddingVertical:35, alignSelf:'center'}]}>{t('AgrisysMenu.Welcome', {name:Username})}</Text> */}
          <View style={[Layout.fullWidth, {minHeight:'max-content', maxWidth:(WindowWidth>980?240:350), alignSelf:'center', justifyContent:'flex-end'}]}>
            <Button Contexts={MenuTexts} EndEnhancers={MenuIcons} Action={ele => props.setView(Pages[ele])} />
            <View style={{minHeight: 30}}/>
            {isDemoFarm(activefarm) && auth.uid === "aRh5SuPNyeXyhGjksnrgj1ET6el2"?
              <Button key={"Præsentation"} Contexts={[t('AgrisysMenu.Presentation')]} Action={ele => navigate('User', {screen:"Presentation"})} />
              :
              <Button key={"LogOut"} Contexts={[t('AgrisysMenu.Logout')]} Action={ele => logout_Handle()} />
            }
          </View>
        </ScrollView>
      </View>
    )
  }
}

export default MenuView