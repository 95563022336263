import { useContext } from 'react'
import { isEmpty, GraphData } from '../../Config/Types'
//import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { createSelector } from 'reselect'
import get from 'lodash/get'

export const fetchWeightGraphs = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = [], IsDataLink:string|undefined):any[] => {
  if (FarmId && FarmId !== "") {
    Calls.push({path:'farms_data/Agrisys/graph', queryParams: ['notParsed', 'orderByKey'], storeAs: 'agri_graphs'})
    
    var path = `farms_data/${IsDataLink?IsDataLink:FarmId}/`
    if (IsDataLink) {
      Calls.push({path:path + 'graph', queryParams: ['orderByKey'], storeAs: `xtern_graphs/${(IsDataLink)}`})
    }
    else {
      Calls.push({path:path + 'graph', queryParams: ['orderByKey'], storeAs: 'local_graphs'})
    }
  }

  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

export const GetPenWeightGraph = createSelector(
  [
    (state:any, IsDataLink?:string, PenCurve?:string ) => get(state.firebase.data, `agri_graphs`, {}),
    (state:any, IsDataLink?:string, PenCurve?:string) => get(state.firebase.data, `local_graphs`, {}),
    (state:any, IsDataLink?:string, PenCurve?:string) => IsDataLink?get(state.firebase.data, `xtern_graphs.${IsDataLink}`, {}):{},
    (state:any, IsDataLink?:string, PenCurve?:string) => IsDataLink,
    (state:any, IsDataLink?:string, PenCurve?:string) => PenCurve,
  ],
  (AgriCurves:Record<string, GraphData>, LocalCurves:Record<string, GraphData>, XternCurves:Record<string, GraphData>, IsDataLink?:string, PenCurve?:string):Record<string, GraphData>|GraphData|undefined => {
    var Graphs:Record<string, GraphData> = Object.assign({}, AgriCurves, LocalCurves, XternCurves)

    Graphs = Object.entries(Graphs).reduce<Record<string, GraphData>>((ini,[Name, Data]) => {
      var Datas:number[] = [0,1]
      if (Data.data) {
        if (Array.isArray(Data.data)) Datas = Data.data
        else {
          Datas = Object.entries(Data.data).reduce((ini,[k,v])=>(ini[k]=v,ini),[])
        }
      }
      
      return Object.assign(ini, { [Name]: {data: Datas} })
    }, {})
    return PenCurve?!isEmpty(Graphs)&&Graphs[PenCurve]?Graphs[PenCurve]:undefined:Graphs
  }
)