/**
 * This file defines the base application styles.
 *
 * Use it to define generic component styles (e.g. the default text styles, default button styles...).
 */
import { StyleSheet } from 'react-native'
import buttonStyles from './components/Buttons'
/**
 *
 * @param Theme can be spread like {Colors, NavigationColors, Gutters, Layout, Common, ...args}
 * @return {*}
 */
export default function ({ Colors, ...args}:{Colors?:any, [args:string]:any}) {
  return {
    button: buttonStyles({ Colors, ...args }),
    ...StyleSheet.create({
      backgroundPrimary: {
        backgroundColor: Colors.backgray,
      },
      backgroundSecondary:{
        backgroundColor: Colors.primary,
      },
      backgroundReset: {
        backgroundColor: Colors.transparent,
      },
      textInput: {
        borderWidth: 0,
        borderColor: Colors.text,
        backgroundColor: Colors.inputBackground,
        color: Colors.text,
        minHeight: 35,
        textAlign: 'left',
      },
      border: {
        borderWidth: 2,
        borderRadius: 10,
        borderColor: Colors.text,
        color: Colors.text,
        minHeight: 30,
        marginVertical:5,
        marginHorizontal:2,
        padding:2.5,
      },
    }),
  }
}
