// Need to use the React-specific entry point to import createApi
//import { createApi, fetchBaseQuery, BaseQueryEnhancer } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../Store'
import { ConsysRefs, DataConsData, isEmpty, TimeSpan } from '../../Config/Types'
import { Config_Pri } from './Config'
import { ConsysApi, ConsysArgs, GetAutos, GetTimeSets, MultiLoop } from './Consys'
import { timeStamp } from 'console'

export interface SiloInfo {
  amount?: number,
  silono: number
}

export interface FeedInfo {
  dataid: number,
  timestamp: number,
  valve: number,
  unit?: number,
  amount?: number,
  sumamount?: number,
  wateramount?: number,
  silolist?: SiloInfo[]
}
export type FeedList = FeedInfo[]//Record<string, FeedInfo[]>
export interface FeedProp extends ConsysArgs {
  FarmKey:string|undefined,
  FarmKeys?:string|string[]|undefined,
  systemids?:string|string[]|{id:string,XTern:string}|{id:string,XTern:string}[],
  Xsystemids?:string[][] | {id:string,XTern:string}[][],
  valve:string|number,
  ViewPeriods:TimeSpan[]|TimeSpan|undefined,
  interval?:number,
  timerange?:'last30d'|'currentmonth'|'lastmonth'
}

const _GetError = (dataText:string):any => {
  return {error: {
    status: 500,
    statusText: 'Token retrieve error',
    data: dataText,
  }}
}

const DoqueryFn = async (args:FeedProp, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (args.FarmKeys && typeof args.FarmKeys==="object" && !isEmpty(args.FarmKeys)) {
    var res = await MultiLoop(DoqueryFn, args, queryApi, extraOptions, fetchWithBQ)
    if (res && (!isEmpty(res.data) || !isEmpty(res.error))) return res
  }
  else {
    //const state = (queryApi.getState() as RootState)
    var Errors = null, Feedres:(FeedList | undefined)[] = []
    let valveId:string|null = args.valve!==undefined&&args.valve!==""?args.valve+'':null
    var getAutos = GetAutos(args, queryApi)
    
    //debugger
    Feedres = await Promise.all(getAutos.map(async ([key, DataCon], index) => {
      if (DataCon && DataCon.feed && DataCon.feed !== "") {
        //var Feeds = DataCon.feed.split(',').reduce((a:object, v:string) => ({ ...a, [v]: (DataCon.feed.includes('all,')&&v!=='all')?false:true}), {})
        //if ((!valveId || valveId === "all") || (Feeds['all']?!Feeds[valveId]:Feeds[valveId])) {
          //debugger
          var Times = GetTimeSets(args, queryApi, index)
          let Result:any = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.VALVEFEED, key, valveId?valveId:'all', {interval:args.interval, starttime:Times.end, stoptime:Times.start}), params:args})
          
          if (Result) {
            if (Result.error || (!Result.data || !Result.data.consumptiondata)) {
              Errors = _GetError(Result.error?Result.error.error:"")
            } else if (Result.data && Result.data.consumptiondata) {
              return (Result.data.consumptiondata as FeedList).map(datapoint => ({...datapoint, timestamp:(datapoint.timestamp*1000)}))
            }
          } else Errors = _GetError("No Data Return")
        //}
      }
    }))
    if (!Errors) {
      return {data: Feedres.filter(e => e)}
    } else {
      return Errors
    }
  }
  return _GetError("No Site")
}

// Define a service using a base URL and expected endpoints
const ConsysApi_WithTag = ConsysApi.enhanceEndpoints({
  addTagTypes: ['Feed']
})
export const FeedSlice = ConsysApi_WithTag.injectEndpoints({
  overrideExisting:false,
  endpoints: (builder) => ({
    getFeedList: builder.query<FeedList[], FeedProp|void>({
      async queryFn(args:FeedProp, queryApi, extraOptions, fetchWithBQ) {
        return await DoqueryFn(args, queryApi, extraOptions, fetchWithBQ)
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Feed', Key})
        })
      },
    }),
    getFeeds: builder.query<FeedList[], FeedProp|void>({
      async queryFn(args:FeedProp, queryApi, extraOptions, fetchWithBQ) {
        var Data:FeedList[] = []

        if (!isEmpty(args.systemids)) {
          var Intern = await DoqueryFn({FarmKey:undefined, systemids:args.systemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          Data = Data.concat(Intern.data)
        }
        else if (!isEmpty(args.Xsystemids)) {
          var XTern = await DoqueryFn({FarmKey:undefined, FarmKeys:args.FarmKeys, Xsystemids:args.Xsystemids, valve: args.valve, ViewPeriods:args.ViewPeriods}, queryApi, extraOptions, fetchWithBQ)
          Data = Data.concat(XTern.data)
        }

        //TODO: ERROR
        return {data:Data}
      },
      providesTags: (result = [], error, arg) => {
        return Object.entries(result).map(([Key, Data]) => {
          return ({ type: 'Feed', Key})
        })
      },
    }),
  }),
})
export const GetFeedList = FeedSlice.endpoints.getFeedList.useQuery
export const GetFeeds = FeedSlice.endpoints.getFeeds.useQuery