export default {
  SplashText: 'AgrisysApp, Technologie zur Proteinautomatisierung.',
  AgrisysFront:{
    Welcome: 'Willkommen bei der Agrisys App',
    Menu1: 'Agrisys Cloud',
    Menu2: 'Mit Gerät verbinden',
    Menu3: 'Mobile version'
  },

  AgrisysConnect: {
    NavTitle: 'Benutzeranmeldung',
    label1: 'E-mail:',
    label2: 'Passwort:',
    submit_btn: 'Anmelden',

    reset: 'Reset-Passwort senden',
    create: 'Neuen Benutzer erstellen'
  },

  AgrisysUser: {
    reVerify:'Bestätigung erneut senden',
    Refresh:'Aktualisieren',

    label1: 'E-mail:',
    label2: 'Passwort:',
    submit_btn: 'Benutzer erstellen',

    Logout: 'Abmelden'
  },

  AgrisysAdmin: {
    NavTitle: 'Admin Panel',
    Welcome: 'Willkommen: {{name}}',
    Userboard: 'Benutzerboard',
    Systems: 'Systeme',
    Tools: 'Werkzeuge',
    Logout: 'Abmelden'
  },

  AdminSystems:{
    Name:"Name",
    Manager:"Manager",
    Email:"E-mail",

    Selected:"Farm-Tag",
    Consys:"Consys",
    Panel:"Farm Panel"
  },
  
  AgrisysMenu:{
    NavTitle: 'Benutzerpanel',
    Welcome: 'Willkommen: {{name}}',
    SystemBoard: 'Systemboard',
    DashBoard: 'Armaturenbrett',
    Pens: 'Stifte',
    Batchs: 'Chargen',
    Reports: 'Berichte',
    Growth: 'Wachstumskurven',
    Operations: 'Operationen',
    Autofunctions: 'Datenverbindungen',
    FeedingFunctions: 'Fütterung',
    Pigs: 'Schweine',
    Settings: 'Einstellungen',
    Logout: 'Abmelden'
  },

  SystemBoard: {
    StartTxt: `Die aktuelle Aktion ist eine neue Startmarke.\nMöchten Sie die Daten von Autopig für ein neues Durchschnittsgewicht zurücksetzen?\nAndernfalls könnten zukünftige Daten verzerrt sein, da das Zurücksetzen nicht korrekt ist.`,
    ResetTxt: `Die aktuelle Aktion setzt die Daten dieses Autopigs zurück,\nalle Daten sind weiterhin verfügbar.\nZukünftige Daten könnten jedoch verzerrt sein, wenn das Zurücksetzen nicht korrekt ist.\nBist du sicher, dass du das machen willst??`,
    
    ResetLabel: 'Zurücksetzen Durchschnittsgewicht auf ',
    fetchLabel: 'Gewicht holen',

    ResetErrorTxt: "Konnte nicht zurückgesetzt werden",
    ResetSusTxt: "Zurücksetzen abgeschlossen"
  },

  DashBoard:{
    Title: 'Betriebsbeschreibung',
    Prop1: 'Farmname',
    Prop2: 'Manager',
    Prop3: 'E-Mail',
    
    CapacityTxt: 'Farmkapazität {{current}} von {{full}}',
    PerformanceTxt: 'Farmleistung',

    DistriTitle: 'Verteilung:',
    BatchesTitle: 'Chargen:',
    BatchLabel: '{{name}} Startdate: {{Sdate}} Prognose beenden: {{Edate}},\n{{pigs}} Schweine, gewicht: {{weight}}',

    FarmInfo_btn: 'Farminformationen',
    UserInfo_btn: 'Benutzerinformationen',
    FarmSettings_btn: 'Farmeinstellungen',
    ClearData: 'Lokalen Datenspeicher löschen'
  },

  DataEditor:{
    Title: 'Herausgeber',
    Options1_1: "Stifte",
    Options2_1: "Hinzufügen",
    Options2_2: "Löschen",
    New_Sec: "Neuer Abschnitt",
    Multiple:'Mehrere: ',

    Prop1:'Niedrigste Zahl',
    Prop2:'Höchste Zahl',
    Prop3:'Pre Pseudonym',
    Prop4:'Pseudonym posten',
    Prop5:'Abschnittsname',
    Prop6:'Pseudonym',
    ValDirection: 'Höchste Zahl muss höher sein als Niedrigste Zahl',
    CannotNon: 'Nicht vorhandener Stift kann nicht entfernt werden',
    Pen_Size: 'Stiftgröße',
    GrowthCurve:'Wachstumskurve'
  },

  FarmInfo:{
    Title:'Betriebsinformationen',
    Prop1:"Farmname",
    Prop2:"Adresse",
    Prop3:"Land",
    Prop4:"Cvr",
    Prop5:"Manager",
    Prop6:"E-mail",
    Prop7:"Größe des Stiftdesigns",
    Copy:"Farm-ID in die Zwischenablage kopieren",
    Create:"Farm erstellen",
    Save:"Informationen speichern",
  },

  UserInfo:{
    Title: 'Benutzerinformationen',
    Prop1:"Vorname",
    Prop2:"Nachname",
    Prop3:"E-Mail",
    Prop4:"Telefon",
    Prop5:"Adresse",
    Prop6:"Land",
    Copy:"Benutzer-ID in die Zwischenablage kopieren",
    Save:"Informationen speichern",
  },

  FarmSettings:{
    Title: 'Farmeinstellungen',
    Prop1:"Größe des Stiftdesigns",
    PensSettingsTitle: "Stifteinstellungen",
    PenPrefix: "Stift-Präfix:",
    Pendefault: "Stift_",
    TreatmentsTitle: "Behandlungen",
    TreatmentProp1:"Code",
    TreatmentProp2:"Name",
    TreatmentProp3:"Tod",
    TreatmentProp4:"Verwendung",
    WorkerTitle: "Arbeiter / Benutzerliste",
    WorkerProp1:'Benutzercode',
    WorkerProp2:'E-mail',
    WorkerProp3:'Einstellungen',
    UserAllowTitle:"Zulassungsliste für Benutzer",
    SilonKompTitle:"Silos und Komponenten",
    NedapTitle:"Nedap Connect",
    NedapAdd:"Installation hinzufügen",
  },

  Subscription:{
    Plan:"Abonnementplan",
    Staff:'Farmpersonal',
    StaffDesc:'ugriff zum Zuweisen als Mitarbeiter einer bestehenden Farm',
    Owner:'Farmbesitzer',
    OwnerDesc:'Zugriff zur Verwaltung der eigenen Farm',
    Manager:'Farm-Manager',
    ManagerDesc:'Zugriff zur Verwaltung der eigenen Farm und Zuweisung von Personal',
    Contact:'Kontaktieren',
    ContactDesc:'Wir werden Sie kontaktieren und mehr über unsere Angebote erzählen',

    Free:'frei',
    PriceManager:'9999.99€',

    submit_btn: 'Senden',

    //Errors
    NoType:'Bitte wählen Sie einen Abonnementtyp.',
    NotAvaible: 'Diese Option ist noch nicht verfügbar.',
  },

  Pens:{
    Options1:'Layout als: ',
    Options1_1:'Abschnitte',
    Options1_2:'Charge',
    ViewDate:'Ansichtsdatum',

    Options2:'Anzeige:',
    Options2_1:'Gewichte',
    Options2_7:'Wachstum',
    Options2_2:'Tierzählungen',
    Options2_3:'Alterstage',
    Options2_4:'Wasserverbrauch',
    Options2_5:'Futterverbrauch',
    Options2_6:'Aktivität',
  },

  Pen_Display:{
    Title: 'Stift-Display',
    Prop1: 'Schweinezählung',
    Prop2: 'Charge',
    Prop3: 'Charge alter',
    Prop3_2: 'Pen-Starttag',
    Prop4: 'Durchschnittsgewicht',
    Prop5: 'Durchschnittliches Wachstum pro Schwein und Tag',
    Prop6: 'Wachstumskurve',
    Prop7: 'Alarm Min-Max',
    Prop7Data: '{{min}}% - {{max}}%',
    Size: 'Stift Größe',
    
    Prop8: 'Letzte Futterzeit',
    Prop8Time: '{{date, DD-MM-YYYY HH:mm:ss}}',
    Prop9: 'Portion in kg.',

    Updateview_btn:'Ansicht aktualisieren',

    SiloLog_Title:'Silo-Protokoll',
    DailyFeedLog_Title:'Tägliches Feed-Protokoll',
    ControlWeights_Title: 'Wägungen kontrollieren',
    WeightTime: '{{date, DD-MM-YYYY HH:mm:ss}}',
    WeightTimeAction: 'Ist aktivität: {{date, DD-MM-YYYY}}',
    WeightData: '{{weight}} kg : {{count}} schweine',

    OperationLog_Title: 'Operationsprotokoll',
    OperationTime: '{{date, DD-MM-YYYY HH:mm:ss}}',
    NoteData: 'Stift-Notiz: \n{{note}}',
    OperationNote: '{{note}}',
    OperationData: '{{count}} * {{action}} um {{weight}} kg \n{{note}}',
    OperationDataCode: '#{{code}} - {{codename}}\n{{count}} * {{action}} um {{weight}} kg\n{{note}}',
  },

  Autopigs:{
    Title:'Autopigs',
    New:'Neu AutoPig',
    AutopigId:'Autopig SysId',
    SelectPens:'Verfügbar - Stifte',
    SelectSections:'Available - Abschnitte',
    
    CorTitle:'Korridorwaagen',
    CorridorId:'Korridorwaagen Id\'s (Durch Komma getrennt)',
    ConIdError:'Consys erkennt {{ConsysId}} nicht als gültige ID.',
    AutoPigName: 'Autopig Name',

    FeedTitle:'Feed-Verbindungen',
    NewFeedCon:'Neue Feed-Verbindung',
    FeedId:'Feed SysId',

    DataTitle:'Die Datenfreigabe der Farm',
    NewDataCon:'Neue Datenfreigabe',
    FarmId:'Farm-Id',
    From:'Daten von',
    To:'Data zu',

    Edit:'Einstellungen bearbeiten',
    Prop1:'Autopig name',
    Prop2:'Consys-Id',
    Prop3:'Skala',
    Prop4:'Betrieb',
    Prop5:'Temp',
    Prop6:'Wasser',
    Prop7:'Einspeisung',
    Prop8:'Tewe-Einspeisung \n(Valve-ID)',

    NewConnection:'Neue Verbindung',
    ConnectionName:'Verbindungsname',

    listcol1:'ID',
    listcol2:'Name',
    listcol3:'Typ',
    listcol4:'Geteilt/Lokal',
    listcol5:'Aktionen'
  },

  Autopigs_Display:{
    Title: "Autopig-Anzeige",
    DaysLabel:'Ausgewählte Tage',
    NewestLabel: "Neuestes Gewicht",
  },

  Reports: {
    New:'Neuer Bericht',
    Edit:'Berichte bearbeiten',

    Combined:'In kombinierter Ansicht öffnen',
    listcol1: 'Name',
    listcol2: 'Anfangsdatum',
    listcol3: 'Endtermin',
    listcol4: 'Archiviert',
    listcol5: '- Aktionen -',
  },

  Reports_Editor: {
    Title: 'Berichtseditor',

    Deletelabel: 'Bericht löschen',
    Copylabel: 'Bericht kopieren',
    ReportName: 'Berichtsname',
    GrowthCurve:'Wachstumskurve:',
    SelectPens:'Verfügbar - Stifte',
    SelectAutos:'Available - Autopigs',
    SelectReps:'Available - Berichte',
  },

  Report_Display: {
    Title: "Berichtsanzeige",

    Added: 'Schweine hinzugefügt',
    Deaths: 'Todesfälle',
    Sold: 'Schweine verkauft',

    NoWeight: 'Es wurden keine Kontrollwägungsdaten gefunden.',
    NoFeeding: 'Es wurden keine Fütterungsdaten gefunden.',
    Total: 'Gesamt',

    StartD:'Anfangsdatum:',
  },

  Graphs:{
    New: 'Neue Kurve',

    CalcLabel: 'Berechnen Sie basierend auf:',
    Name: 'Kurvenname',
    Day: 'Tag:',
    Weight: 'Gewicht:',
    Growth:'Wachstum',
    NewPoint: 'Neuer Punkt',
    EValue: 'Wertfehler',
    NameError: 'Kurvenvorlagen benötigen einen Namen.',
    IncrementError: 'Kurvenvorlage muss inkrementell sein: Fehler am Tag: {{I1}} - {{I2}}'
  },

  Operations:{
    Weight: 'Manuelles Wiegen',
    AddPen: 'Stift hinzufügen',
    RemovePen: 'Stift entfernen',
    EditPens: 'Stift hinzufügen oder entfernen',

    Section:'Abschnitt:',
    Pen:'Stift:',
    Data:'Stiftdaten',
    Pigs:'Schweine:',

    Note: 'Notiz',
    AddPig: 'Schwein hinzufügen',
    MovePig: 'Schwein bewegen',
    Treatment: 'Behandlung',
    SoldPig: 'Schwein verkauft',
    ClosePen: 'Stift schließen',

    NoteTitle: 'Stift-Notiz:',
    OperationTitle: 'Kommentar zu Operationen:',
    Quantity: "Menge:",
    AvgWeight: "Durchschnittsgewicht:",
    CorridorBtn: "Korridor-Skala",
    CorridorRes: "Korridor-Ergebnis",
    Internal: 'Interner Hinweis',
    PeriodeTitle: 'Zeitraum anzeigen:',
    day:'tag',

    ModalConf:'Bitte Aktion bestätigen',
    CloseDate:'Abschlussdatum',
    ClearWarn:'Die aktuelle Aktion löscht diese Pen-Daten auf historische Daten,\nalle Daten sind weiterhin in der Berichtsanzeige verfügbar.\nSind Sie sicher, dass Sie dies tun möchten?',
    RepCreate:'Bericht aus Stift erstellen'
  },

  CommentModule:{
    NotePlaceholder: 'Kontext beachten..',
  },

  GraphViewer:{
    X_DateFormat_Time: '{{date, DD-MM-YYYY HH:mm}}',
    X_DateFormat: '{{date, DD-MM-YYYY}}',

    CountFormat:'Schweine zählen',
    WeightFormat:'Gewicht Kg.',
    CountToWeight:'Zählen: {{count}}\nGewicht: {{weight}}kg',

    InfoTitle:'CurveView-Info Details',
    InfoName:'Datenname',
    InfoValue:'Wert: y-Achse',
    InfoAdd:'Extra',

    Weight:'Gewicht',
    ByTag:'PerTag',
    Feed:'Einspeisung',
    Growth:'Wachstum',
    Operation:'Betrieb',
    Water:'Wasser',
    Temp:'Temp'
  },

  Weighings:{
    Title: 'Wiegen',
    EditWeights: 'Gewichte bearbeiten:',
    DeleteWeights: 'Gewichte löschen:',
    WeightData: "Gewichtsdaten:",
    Quantity: "Tierische Menge",
    Weight: "Gesamtgewicht",
    NoReply:'Consys antwortete nicht mit einem Gewicht',
    CorridorScale: "Korridorwaage",
    Fetch: "Gewicht abrufen",
  },

  FeedList:{
    Col1:'Ventil-Nr.',
    Col2:'Silo',
    Col3:'Menge Kg',
    Col4:'Zeit',
  },

  OperationList:{
    Col1:'Zeit',
    Col2:'Ventil-Nr.',
    Col3:'Hauptaktivität',
    Col4:'Unteraktivität',
    Col5:'Daten',
    Action1:'Bearbeiten'
  },

  WeightList:{
    Col1_1:'Stift',
    Col1_2:'Die Summe an Tagen',
    Col1_3:'Gewicht eingeben',
    Col1_4:'Gewicht ausgeben',
    Col1_5:'Wachstum pro Tag',

    Col2_1:'Datum',
    Col2_2:'Schweine zählen',
    Col2_3:'Gewicht Kg',
    Col2_4:'Kg pro. Schwein',
    Col2_5:'Wachstum pro. Schwein, pro. Gewicht',
    Col2_6:'Durchschnittliches Wachstum pro. Schwein, ein Tag',
  },

  DataTypes: {
    Degrees:"°C",
    W:"Kg.",
    W_T:"Kilo",
    W_small:"g.",
    W_small_T:"gram",
    F:"L.",
    F_T:"Liter",
    F_small:"ml.",
    F_small_T:'Milliliter',
  },

  ActionButtons:{
    Add:'Hinzufügen',
    Remove:'Entfernen',
    Back:'Der Rücken',
    Save:'Speichern',
    SaveAll:'Alles speichern',
    Done:'Fertig',
    Cancel:'Absagen',
    Logout: 'Ausloggen',
    Submit_btn: 'Einreichen',
    OK: 'OK'
  },

  Errors: {
    PropMissing:'{{Prop}} wird gebraucht',
    PropInvalid:'{{Prop}} Enthält ungültige Zeichen',

    PropExists:'{{Prop}} ist bereits vorhanden',
    PropNon:'{{Prop}} ist nicht vorhanden',

    PropNan:'{{Prop}} kann nicht mit einer Zahl beginnen oder sein',
    PropNum:'{{Prop}} muss eine gültige Nummer sein',

    PassMissing:'Bitte geben Sie ein Passwort ein',
    EmailMissing:'Bitte geben Sie eine E-Mail an',
  },

  Development: {
    NotDone:'Element nicht fertig',
  }
}
