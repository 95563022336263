export { default as useTheme } from './hooks/useTheme'

import { styled } from "styletron-react"
export const PrintContainer = styled("div", (props:{$NoPrint?:any, $FullSize?:any}) => {
  var Style = {
    width: '-webkit-fill-available',
    textAlign: '-webkit-center'
  }
  if (props.$NoPrint) {
    if (props.$NoPrint !== undefined && typeof props.$NoPrint === "object") Style = {...Style, ...props.$NoPrint}
    return {
      ...Style,
      "@media print": {
        display: "none"
      }
    }
  }
  if (props.$FullSize) {
    if (props.$FullSize !== undefined && typeof props.$FullSize === "object") Style = {...Style, ...props.$FullSize}
    return {
      ...Style,
      "@media print": {
        
      }
    }
  }
})