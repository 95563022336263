import React, { useEffect, useState } from 'react'
import {
  View,
  Text,
  Dimensions,
  ScrollView
} from 'react-native'
import { Brand, Button, LoadingSplash, Consys_System } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, navigateAndSimpleReset, isApp } from '../../Navigators/Root'

import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'

import { useAppSelector, useAppDispatch, ClearData } from '../../Store'
//import { FarmData } from '../../Config/Types'
import { resetState } from '../../Store/StateHandlers/Active-slice'
import { UserData, UserStates } from '../../Config/Types'
import { ConsysApi } from '../../Services/Consys/Consys'

interface MenuView_Props {
  nologo?:boolean,
  setView: React.Dispatch<React.SetStateAction<string | null>>
}
const MenuView = (props:MenuView_Props) => {
  const { t } = useTranslation()
  const { Layout, Images, Fonts, Gutters, Common } = useTheme()
  
  const firebase = useFirebase()
  const auth = useAppSelector(({firebase}) => firebase.auth)

  const profile = useAppSelector<UserData>(({firebase}) => firebase.profile)
  //const farms = useAppSelector(({firestore: { data }}) => data.farms)
  //const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)

  const dispatch = useAppDispatch()
  const logout_Handle = () => {
    firebase.logout()
    dispatch(resetState({state:"all"}))
    dispatch({type: actionTypes.CLEAR_DATA})
    dispatch(ConsysApi.util.resetApiState())
    ClearData(dispatch)
    //preserve: { data: ['todos'], ordered: ['todos'] }
  }

  const [WindowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setWindowWidth(window.width)
    });
    return () => subscription?.remove()
  })

  //Auth Check Handler
  useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth) /*|| !isLoaded(profile) || !isLoaded(farms)*/) return <LoadingSplash/>
  else {
    let MenuTexts = [t('AgrisysAdmin.Userboard'), t('AgrisysAdmin.Systems'), t('AgrisysAdmin.Tools')]
    let MenuIcons = [Images.DashBoard, Images.System, '']
    let Pages = ["Userboard","System","Tools"]

    return(
      <View style={[Layout.fillCol, Layout.fullSize, Gutters.smallV_Margin, {zIndex:2, minHeight:'max-content', minWidth:(WindowWidth>980?'240px':'260px'), maxWidth:(WindowWidth>980?240:350)}]}>
        {props.nologo?(<View style={{minHeight: 10}}/>):(
          <View style={[{ marginVertical:15, marginHorizontal: 15, minHeight:'max-content'}]}>
            <Brand width={'100%'}/>
          </View>
        )}
        <Text style={[Fonts.textRegular, {paddingBottom:15, alignSelf:'center'}]}>{t('AgrisysAdmin.Welcome', {name:profile.name})}</Text>
        <ScrollView style={[Layout.fillCol, Layout.fullSize, Common.bagroundPrimary, {minHeight:'max-content', minWidth:'max-content', backgroundColor:'#f2f2f2'}]} contentContainerStyle={[Layout.fillCol, {justifyContent:'flex-start', minHeight:'max-content', minWidth:'max-content'}]}>
          <View style={[Layout.fullWidth, {minHeight:'max-content', maxWidth:(WindowWidth>980?240:350), alignSelf:'center', justifyContent:'flex-end'}]}>
            <Button Contexts={MenuTexts} EndEnhancers={MenuIcons} 
              Action={ele => {
                props.setView(Pages[ele])
              }} />
            <View style={{minHeight: 30}}/>
            <Button key={"LogOut"} Contexts={[t('AgrisysMenu.Logout')]} 
              Action={ele => {
                logout_Handle()
              }} />
          </View>
        </ScrollView>
      </View>
    )
  }
}

export default MenuView