import React, { useState, useMemo } from 'react'
import {
  View,
  Text,
  TouchableOpacity
} from 'react-native'

import { useTheme } from '../../../Theme'
//import { useTranslation } from 'react-i18next'
import { useCurveView_Context } from './CurveViewer_context'
import { getUniqueColor } from '../../../utils/formaters'
import { DataSet } from '.'

interface LabelContectProp {
  name: string,
  deactivate: boolean|undefined,
  symbol: any
}
const Label_Display = ({ DataTimes, CalcActiveData }) => {
  //const { t } = useTranslation()
  const { Common, Layout } = useTheme()

  const { state: { Settings, DeactiveSets, ActiveOptions, Selected_DataOptions }, dispatch } = useCurveView_Context()
  const ActiveData = CalcActiveData(Settings, ActiveOptions, Selected_DataOptions, DataTimes)
  const [CategoriesData] = useMemo(() => {
    var _CategoriesData:LabelContectProp[] = []
    if (ActiveData && ActiveData.length) {
      ActiveData.forEach((Set:DataSet, index) => {
        if (Set && Set.name && !Set.name.endsWith('#')) {
          var Name = Set.prefix?Set.prefix+Set.name:Set.name
          var Prev = _CategoriesData.findIndex(e => e.name === Name)
          if (Prev>=0) {
            if (!_CategoriesData[Prev].deactivate && Set.deactive) {
              var empty = {stroke: "#000", strokeWidth: 0.5, fill:'#fff'}
              _CategoriesData[Prev] = {
                name: Name,
                deactivate: true,
                symbol: empty
              }
            }
          } else {
            var _Color = Set.form==="Notes"?'#000':Set.color?Set.color:getUniqueColor(2 + index)
            var style = Set.deactive||DeactiveSets?.some(e => e===Set.name)?{stroke: "#000", strokeWidth: 0.5, fill:'#fff'}:{fill:_Color}
            var _Data:LabelContectProp = {
              name: Name, 
              deactivate: Set.deactive,
              symbol: style
            }
            _CategoriesData.push(_Data)
          }
        }
      })
    }
    return [_CategoriesData]
  }, [ActiveData, DeactiveSets])

  return (
    <View style={[{height:"fit-content", alignItems:'center', width:'-webkit-fill-available'}]}>
      {CategoriesData && CategoriesData.length > 1 &&(
        <View style={[Layout.row, Common.border, {flexWrap:'wrap', height:'min-content', maxWidth:'1000px', width:'-webkit-fill-available', flexDirection:'row', paddingLeft:5}]}>
          {CategoriesData.map((Content_Label:LabelContectProp, index) => {
            if (Content_Label.deactivate) return null
            return(
              <TouchableOpacity key={Content_Label.name+'_'+index} style={[Layout.row, {minWidth:'fit-content', minHeight:'fit-content', maxHeight:'min-content', marginTop:2.5, marginBottom:2.5, marginRight:5}]} 
                onPress={(e) => {
                  if (ActiveData && ActiveData.length && Content_Label) {
                    //var _dataSet = ActiveData.filter(e => e.name === Content_Label.name)
                    //if (_dataSet && _dataSet.length) {
                    dispatch({type:'set_Deactive', payload:{name:Content_Label.name}})
                    //}
                  }
                }}
              >
                <View style={{width:15, height:15, borderRadius:"50%", outline: `2px #000 solid`, marginRight:3, marginTop:2, backgroundColor:Content_Label.symbol.fill}}>
                  <View style={{width:14, height:14, borderRadius:"100%", borderColor:Content_Label.symbol.fill, borderWidth:'7px'}}/>
                </View>
                <Text>{Content_Label.name}</Text>
              </TouchableOpacity>
            )
          })}
        </View>
      )}
    </View>
  )
}

export default Label_Display