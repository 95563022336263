// Need to use the React-specific entry point to import createApi
//import { createApi, fetchBaseQuery, BaseQueryEnhancer } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../Store'
import { ConsysRefs, DataConsData, isEmpty } from '../../Config/Types'
import { Config_Pri } from './Config'
import { ConsysApi, ConsysArgs, GetAutos, MultiLoop} from './Consys'

export interface WeightInfo {
  dataid: number,
  timestamp: number,
  valve?: number,
  amount: number,
}

export interface ScaleInfo extends WeightInfo {
  amount: number,
  sumamount?:number,
  objArr?: WeightInfo[],
  count?:number,
  subid?: number
}
export interface ScaleProp extends ConsysArgs {
  FarmKeys?:string,
  systemids?:string|{id:string,XTern:string},
  valve?:string,
  isAuto?:boolean
}

const _GetError = (dataText:string):any => {
  return {error: {
    status: 500,
    statusText: 'Token retrieve error',
    data: dataText,
  }}
}

function sleep(ms:number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const DoqueryFn = async (args:ScaleProp, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (args.FarmKeys && typeof args.FarmKeys==="object" && !isEmpty(args.FarmKeys)) {
    var res = await MultiLoop(DoqueryFn, args, queryApi, extraOptions, fetchWithBQ)
    if (res && (!isEmpty(res.data) || !isEmpty(res.error))) return res
  }
  else {
    const state = (queryApi.getState() as RootState)
    var Autopigs:DataConsData|undefined = undefined
    if (args.FarmKey && state.firebase.data && state.firebase.data.LinkListData && state.firebase.data.LinkListData[args.FarmKey]) Autopigs = state.firebase.data.LinkListData[args.FarmKey].DataCons
    else if (state.firebase.data && state.firebase.data.DataCons) Autopigs = state.firebase.data.DataCons

    var Errors = null, Scaleres = null
    
    var Corridors:string[]|undefined = undefined
    if (Autopigs) {
      if (Autopigs["Sys_Config"] && Autopigs["Sys_Config"]["Corridors"]) {
        Corridors = Autopigs["Sys_Config"]["Corridors"].split(',')
      }
      var getAutos = GetAutos(args, queryApi)
      console.log("Call", getAutos)
      if (getAutos.length) {
        var autos = getAutos.filter(([key, AutoPig], index) => (AutoPig && AutoPig.scale && AutoPig.scale !== "")).map(([key, AutoPig], index) => key)
        if (Corridors) Corridors = Corridors.concat(autos)
        else Corridors = autos
      }
    }
    var Farmid = state.activeData.farm
    //debugger
    if (args.systemids && args.systemids !== "") {
      var SYSID = typeof args.systemids === "string"?args.systemids:args.systemids.id
      console.log("Doing call", args.systemids, SYSID, Corridors)
      if (SYSID && Corridors && !isEmpty(Corridors) && Corridors.some(e => e === SYSID)) {
        //debugger
        /*var STime = typeof args.starttime === "object"?args.starttime[index]:args.starttime
        var ETime = typeof args.stoptime === "object"?args.stoptime[index]:args.stoptime
        if (args.FarmKey) {
          if (state.firebase.data.LinkList[args.FarmKey].starttime) {
            if (STime < state.firebase.data.LinkList[args.FarmKey].starttime) STime = state.firebase.data.LinkList[args.FarmKey].starttime
          }
          if (state.firebase.data.LinkList[args.FarmKey].endtime) {
            if (ETime > state.firebase.data.LinkList[args.FarmKey].endtime) ETime = state.firebase.data.LinkList[args.FarmKey].endtime
          }
        }*/
        
        let Result = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.SCALE, SYSID, 'all', {Limit:1}), params:args})
        if (Result) {
          console.log("call return", SYSID, Result)
          if (Result.error || (!Result.data || !Result.data.consumptiondata)) {
            Errors = _GetError(Result.error?Result.error.error:"")
            return Errors
          } else if (Result.data && Result.data.consumptiondata) {
            var ResScales = Result.data.consumptiondata as WeightInfo[]
            
            var LastId = ResScales&&ResScales[0]?ResScales[0].dataid:null
            var newestId:WeightInfo|null = null

            let ScaleRes = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.CORRIDOR, SYSID, args.valve), params:args})
            if (ScaleRes && ScaleRes.data && ScaleRes.data.result ==='OK') {
              var DataDone = 10
              do
              {
                console.log("Corridor test:", DataDone)
                await sleep(2000)
                //TODO: Rem implemented for demo test and præsentation
                if (DataDone === 7 && Farmid === "-Demo1") return {data: { amount: 8000, dataid:302, timestamp: 1664262989} as WeightInfo}

                let _newestId = await fetchWithBQ({url:Config_Pri.GetConsumtionData_Var(ConsysRefs.SCALE, SYSID, 'all', {Limit:1, Lastid:LastId}), params:args})
                if (_newestId.error || (!_newestId.data || !_newestId.data.consumptiondata)) {
                  Errors = _GetError(_newestId.error?_newestId.error.error:"")
                } else if (_newestId.data && _newestId.data.consumptiondata && _newestId.data.consumptiondata.length) {
                  newestId = _newestId.data.consumptiondata[0] as WeightInfo
                }
                DataDone--
                if (newestId && (!LastId || newestId.dataid !== LastId)) {
                  DataDone = 0
                  return {data: newestId}
                }
              } 
              while (DataDone > 0)
            }
            return _GetError("No connection could be made or no Data was returned for request.")
          }
        } 
      }
    }
  }
  return _GetError("Sry, something went wrong, please reload and try again")
}

// Define a service using a base URL and expected endpoints
const ConsysApi_WithTag = ConsysApi.enhanceEndpoints({
  addTagTypes: ['Corridor']
})
export const CorridorSlice = ConsysApi_WithTag.injectEndpoints({
  overrideExisting:false,
  endpoints: (builder) => ({
    getScale: builder.query<ScaleInfo, ScaleProp|void>({
      async queryFn(args:ScaleProp, queryApi, extraOptions, fetchWithBQ) {
        var Data:ScaleInfo|null = null
        if (!isEmpty(args.systemids)) {
          var Intern = await DoqueryFn(args, queryApi, extraOptions, fetchWithBQ)
          return Intern
        }
        return _GetError("Error, no consysid provided for call")
      }
    }),
  }),
})

export const GetScale = CorridorSlice.endpoints.getScale.useLazyQuery