import React, { useState, useMemo, useImperativeHandle, forwardRef, useEffect } from 'react'
import { View, Text } from 'react-native'

import { CurveViewerPropHandle, DataForm, DataSet, DataSets, DataTemplate, PointSet, SelectorContainer, PointRange, AOptions, Domain} from './index'
import { useTheme } from '../../../Theme'
import { useTranslation } from 'react-i18next'

import { VictoryBar, VictoryLine, VictoryScatter, VictoryChart, VictoryGroup, VictoryTheme, VictoryVoronoiContainer, VictoryTooltip, VictoryAxis, VictoryStack } from "victory-native"
import { UnixDayTime, ConsysRefs, TimeSpan, isEmpty } from '../../../Config/Types'
import { getUniqueColor } from '../../../utils/formaters'
import { CurveViewContext_Action, useCurveView_Context } from './CurveViewer_context'

import { ConsysApi } from '../../../Services'
import { ScaleInfo } from '../../../Services/Consys/Scale-Slice'

export enum ViewForms {
  LINE = 'Line',
  SCATTER = 'Scatter',
  DIST = 'Distribution',
  BAR = 'Bar',
}

const maxPoints = 365;
const ViewPriorities = {
  [ViewForms.LINE] : 0,
  [ViewForms.SCATTER] : 1,
  [ViewForms.DIST] : 2,
  [ViewForms.BAR] : 3, 
}

const formatXAxis = (Xpoint:number|string, entireDomainX1:number|undefined):number => {
  var XVal = parseInt(Xpoint+'')
  if (XVal < 9999 && (!entireDomainX1 || entireDomainX1 < 9999)) {
    const date = new Date()
    date.setDate(date.getDate() + XVal)
    XVal = date.getTime()
  }
  return XVal
}

const CheckForm = (Dataform:DataForm, Check:'LEFT'|'RIGHT'|'ALL'='ALL', Selected_DataOptions:SelectorContainer) => {
  //if (Primary.includes(Dataform)) return true
  switch (Dataform) {
    case "Weight":
    case "WeightTaged":
    case ConsysRefs.SCALE:
      return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "Weight" || Selected_DataOptions.left === ConsysRefs.SCALE)) ||
              ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "Weight" || Selected_DataOptions.right === ConsysRefs.SCALE)))
    case "Growth":
      return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "Growth")) ||
              ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "Growth")))
    case "Feed":
    case ConsysRefs.VALVEFEED:
      return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "Feed" || Selected_DataOptions.left === ConsysRefs.VALVEFEED)) ||
              ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "Feed" || Selected_DataOptions.right === ConsysRefs.VALVEFEED)))
    case "FCR":
      return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "FCR")) ||
              ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "FCR")))
    case "Activity":
    case ConsysRefs.ACTIVITY:
      return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "Activity" || Selected_DataOptions.left === ConsysRefs.ACTIVITY)) ||
              ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "Activity" || Selected_DataOptions.right === ConsysRefs.ACTIVITY)))
    case "Water":
    case ConsysRefs.WATERCOUNT:
      return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "Water" || Selected_DataOptions.left === ConsysRefs.WATERCOUNT)) ||
              ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "Water" || Selected_DataOptions.right === ConsysRefs.WATERCOUNT)))
    case "WaterPrPig":
      case ConsysRefs.WATERCOUNT:
        return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "WaterPrPig")) ||
                ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "WaterPrPig")))
    case "Temp":
    case ConsysRefs.TEMPERATURE:
      return (((Check==='ALL'||Check==='LEFT') && (Selected_DataOptions.left === "Temp" || Selected_DataOptions.left === ConsysRefs.TEMPERATURE)) ||
              ((Check==='ALL'||Check==='RIGHT') && (Selected_DataOptions.right === "Temp" || Selected_DataOptions.right === ConsysRefs.TEMPERATURE)))
    case "Notes":
      return true
  }
  return false
}

const CreateAxis = (t: any, entireDomain:Domain, orientation:"LEFT"|"RIGHT",_Selected_DataOptions:SelectorContainer) => {
  const _ori = orientation==="LEFT"?"left":"right"
  //var {entireDomain, zoomedXDomain, ActiveData} = CalcViewData(Settings, ActiveOptions, _Selected_DataOptions, DeactiveSets, ViewTime)
  const _Domain = entireDomain?(orientation==="LEFT"?entireDomain.y:entireDomain.y2):undefined
  return (
    <VictoryAxis dependentAxis crossAxis={false} orientation={_ori}
      label={() => {
        var res = ''
        if (CheckForm(ConsysRefs.SCALE, orientation, _Selected_DataOptions) ||
            CheckForm(ConsysRefs.VALVEFEED, orientation, _Selected_DataOptions)) {
          res = t('DataTypes.W')
        } else if (CheckForm('Growth', orientation, _Selected_DataOptions)) {
          res = t('DataTypes.W_small_T')
        } else if (CheckForm(ConsysRefs.WATERCOUNT, orientation, _Selected_DataOptions) ||
                   CheckForm('WaterPrPig', orientation, _Selected_DataOptions)) {
          res = t('DataTypes.F_T')
        } else if (CheckForm(ConsysRefs.TEMPERATURE, orientation, _Selected_DataOptions)) {
          res = t('DataTypes.Degrees')
        }
        return res
      }}
      maxDomain={{y:1}} minDomain={{y:0}}
      tickValues={[0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1]}
      tickFormat={(tick) => {
        var Tick = (_Domain&&_Domain.min?_Domain.min:0) + (tick *  ((_Domain&&_Domain.max?_Domain.max:1)-(_Domain&&_Domain.min?_Domain.min:0)))
        if (CheckForm('WaterPrPig', orientation, _Selected_DataOptions) ||
            CheckForm(ConsysRefs.WATERCOUNT, orientation, _Selected_DataOptions) ||
            CheckForm('Water', orientation, _Selected_DataOptions)) return Math.round(Tick* 1000) / 1000
        else return Math.round(Tick* 100) / 100
      }}
      theme={VictoryTheme.material} standalone={false}
      style={{
        tickLabels: {fontSize: 8, padding: 3},
        grid: { 
          stroke: ({ text }) => {
            var val = parseFloat(text)
            return (!isNaN(val) && val < 0)?'red':"#818e99"
          },
          strokeWidth: ({ text }) => {
            var val = parseFloat(text)
            return (!isNaN(val) && val < 0)?1:0.5
          },
        },
      }}
    />
  )
}

//Is extracted for the purpose of multi split view
const CreateChart = (t:any, Settings, entireDomain:PointRange, ActiveOptions: AOptions, Legends: number[], _ViewElements:JSX.Element[], _Selected_DataOptions:SelectorContainer) => {
  //if (!_ViewElements||!_ViewElements.length) return (<></>)
  return (
    <VictoryChart
      name='Plate' scale="time"
      padding={{top:10, bottom: 15, right: 20, left: 30}}
      domain={{
        y:[0,1], //x: [entireDomain.x[0]!==undefined?entireDomain.x[0]:0, entireDomain.x[1]!==undefined?entireDomain.x[1]:1],
      }}
      containerComponent={<VictoryVoronoiContainer
        voronoiDimension="x"
        labelComponent={!Settings.HideTip?<VictoryTooltip center={{ x: 30, y: 25 }} constrainToVisibleArea style={{fontSize:8}}/>:undefined}
        labels={Settings.HideTip?undefined:({ datum }) => {
          if (datum.childName.startsWith("none")) return;
          if (datum.obj) return;
          var name = datum.prefix?datum.prefix+datum.childName:datum.childName

          var value:number|string = Math.round((datum.amount?datum.amount:datum.y) * 1000) / 1000
          var add = ""
          if (isNaN(value) && datum.objData && datum.objData.action) {
            name = name.split('_')[0]
            value = ""+datum.objData.action + (datum.objData.data?"\n"+datum.objData.data:"")
            if (!value) return;
          }
          else {
            if (datum.y0 && typeof value === "number") {
              value = value - datum.y0
            }
            else if (datum.siloname || datum.unit) {
              /*name = format
              format = ""*/
              value = value +"kg"
              if (datum.siloname) add += 'Silo: ' + datum.siloname + ' '
              if (datum.unit) add += 'Komp: ' + datum.unit + ' '
            }
            else if (datum.avgWeight) {
              add += 'Avg Weight: ' + datum.avgWeight + ' '
            }
          }
          return /*(format!=""?format+"\n":"") + */name + ': ' + value + (add!==""?"\n"+add:"")
        }}
      />}
    >
      <VictoryAxis crossAxis={false} //Time Axis
        //minDomain={{ x: entireDomain.x[0]!==undefined?entireDomain.x[0]:0 , y: minY}}
        tickFormat={(tick, index) => {
          if (Legends.length > 126 && index %28 !== 0) return null
          else if (Legends.length > 63 && index %14 !== 0) return null
          else if (Legends.length > 7 && index % 7 !== 0) return null
          if (Settings.zeroIndex) return tick
          var XVal = formatXAxis(tick, (entireDomain&&entireDomain.x&&entireDomain.x.max?entireDomain.x.max:undefined))
          var format = t('GraphViewer.X_DateFormat', {date:new Date(XVal)})
          return format
        }}
        theme={VictoryTheme.material}
        standalone={false}
        //fixLabelOverlap
        tickValues={Legends}
        style={{
          tickLabels: {fontSize: 8, padding: 2.5},
          grid: {
            stroke: (e:any) => (Legends.length > 7 && e.index % 7 !== 0? "#818e99" : "black"),
            strokeWidth: (e:any) => (Legends.length > 7 && e.index % 7 !== 0? 0.5 : 1)
          }
        }}
      />
      {//Left Axis Setup
        CreateAxis(t, entireDomain, 'LEFT', _Selected_DataOptions)}
      {//Right Axis Setup
      !Settings.canMultiSplit.state && ActiveOptions.length>2&&_Selected_DataOptions.right&&!['---', 'WeightTaged'].includes(_Selected_DataOptions.right)
      /*&&renderedData.some(Set => Set.form === _Selected_DataOptions.right)*/?(
        CreateAxis(t, entireDomain, 'RIGHT', _Selected_DataOptions)
      ):(<></>)}
      {_ViewElements}
    </VictoryChart>
  )
}

const Legends = (Settings, zoomedXDomain, ViewTime):number[] => { 
  let _Legends = Settings.zeroIndex?[0]:zoomedXDomain&&zoomedXDomain.min?[zoomedXDomain.min]:[]
  if (Settings.zeroIndex) {
    if (zoomedXDomain && zoomedXDomain.min !== undefined && zoomedXDomain.max !== undefined) {
      //var days = Math.ceil((zoomedXDomain[1] - zoomedXDomain[0]) / UnixDayTime)
      var count = zoomedXDomain.max
      if (ViewTime && typeof ViewTime.start === "number" && typeof ViewTime.end === "number") count = count - (ViewTime.start + (zoomedXDomain.max - ViewTime.end))
      var te = Array(count).fill().map((_, idx) => idx + (ViewTime&&ViewTime.start&&typeof ViewTime.start==="number"?ViewTime.start:0))
      return te
    }
  }
  else {
    var days:undefined|number = undefined
    if (zoomedXDomain && zoomedXDomain.min !== undefined && zoomedXDomain.max !== undefined) {
      days = Math.ceil((zoomedXDomain[1] - zoomedXDomain[0]) / UnixDayTime)
    }
    var lastTime = _Legends[_Legends.length-1]
    while((!days || days >= 0) && lastTime && (zoomedXDomain && zoomedXDomain.max) && lastTime <= zoomedXDomain.max) {
      var time = new Date(lastTime)
      time = new Date(time.setDate(time.getDate() +1))
      lastTime=time.setHours(0,0,0,1)
      _Legends.push(lastTime)
      if (days) days--
    }
  }
  return _Legends
}

interface CurveViewer_Props {
  DataViewTimes: TimeSpan|undefined,
  CalcViewData: any,
  Get_Domains: any
}
const CurveViewer:React.ForwardRefRenderFunction<CurveViewerPropHandle, CurveViewer_Props> = ({DataViewTimes, CalcViewData, Get_Domains}, ref) => {
  const { t } = useTranslation()
  const { Layout } = useTheme()

  const { state: { Settings, DeactiveSets, ActiveOptions, Selected_DataOptions, DataTimes }, dispatch } = useCurveView_Context()
  const ViewTime = useMemo(() => {
    var Time = DataViewTimes?DataViewTimes:DataTimes
    return Time
  }, [DataViewTimes, DataTimes, ActiveOptions])
  var {entireDomain, zoomedXDomain, ActiveData} = CalcViewData(Settings, ActiveOptions, Selected_DataOptions, DeactiveSets, ViewTime)

  const renderedData = useMemo(() => {
    var _renderedData:DataSets = []
    var Domain = {x:zoomedXDomain,y:{min:undefined,max:undefined}}
    
    if (ActiveData && ActiveData.length) {
      ActiveData.forEach((Set:DataSet) => {
        if ((Settings.canMultiSplit.state || CheckForm(Set.form, 'ALL', Selected_DataOptions) || Set.form === 'Notes') && !Set.deactive && !DeactiveSets?.some(e => e===Set.name) && (Set.data && Set.data.length)) {
          var filtered:DataTemplate = []
          if (Set.type === 'Line' || Set.type === 'Scatter') {
            var last:PointSet|null = null;
            Set.data.forEach(data => {
              if (Settings.zeroIndex) {
                filtered.push(data)
              }
              else if (zoomedXDomain&&zoomedXDomain.min&&zoomedXDomain.min!==undefined && data.x >= zoomedXDomain.min) {
                if (zoomedXDomain&&zoomedXDomain.max&&zoomedXDomain.max!==undefined) {
                  if (data.x <= zoomedXDomain.max) {
                    if (filtered.length === 0 && last !== null) filtered.push(last)
                    filtered.push(data)
                  }
                  else if (last && last.x <= zoomedXDomain.max) filtered.push(data)
                }
              }
              last = data
            })
          } else if (Domain&&Domain.x&&Domain.x.min!==undefined&&Domain.x.max) {
            if (Set.data&&Set.data.length) {
              if (Settings.zeroIndex) filtered = Set.data
              else filtered = Set.data.filter((d) => (d.x >= Domain.x.min && d.x <= Domain.x.max))
            }
          }
          if (filtered.length > maxPoints ) {
            const k = Math.ceil(filtered.length / maxPoints)
            filtered = filtered.filter(
              (d, i) => (i!==0 && i!==filtered.length-1 && (i % k) === 0)
            )
          }
          if (filtered && filtered.length) _renderedData.push({...Set, data:filtered})
        }
      })
    }
    return _renderedData
  },[zoomedXDomain, ActiveData, ViewTime ])

  useImperativeHandle(ref, ()=>({
    Reset() {
      /*setNote_DataSets(null)
      setWeight_DataSets(null)
      setActivity_DataSets(null)
      setFeed_DataSets(null)
      setTemp_DataSets(null)
      setWater_DataSets(null)*/
      //setDeactive([])
    },
    GetViewDays() {
      return entireDomain.x
    },
    GetValues(e, name) {
      var data:(number | "min")[]|undefined = undefined
      renderedData.forEach(Set => {
        if (Set.data && (!name || Set.name === name)) {
          if ((e === ConsysRefs.SCALE || e === 'Weight' || e === 'WeightTaged')&&(Set.form === ConsysRefs.SCALE || Set.form === 'Weight' || e === 'WeightTaged')) {
            if (!Set.deactive&&!DeactiveSets?.some(e => e===Set.name)) {
              //var YArray = Set.data.map((o) => o.y).filter(a => a)
              //var Ymin = Math.min.apply(Math, YArray)
              //var Ymax = Math.max.apply(Math, YArray)
              
              data = [Set.data[Set.data.length-1].y, Set.data[0].y]
            }
          }
        }
      })
      return data
    },
    SelectedTags() {
      var data:ConsysApi.Scale.ScaleList[] | undefined = undefined
      renderedData.forEach(Set => {
        if (Set.data && Set.form === 'WeightTaged') {
          if (!Set.deactive&&!DeactiveSets?.some(e => e===Set.name)) {
            var Datas:Record<string, {amount:number, count:number, timestamp:number, objArr:ScaleInfo[]}> = {}

            Set.data.reverse().forEach(Scale => {
              if (Scale && Scale.objData) {
                var DateT = new Date(Scale.x)
                var DateTxT = `${DateT.getFullYear()}_${DateT.getMonth()+1}_${DateT.getDate()}`
                Datas[DateTxT] = {amount:Scale.y==='min'?entireDomain.y.min:Scale.y, count: Scale.objData.length, timestamp:Scale.x, objArr:Scale.objData}
              }
            })

            if (!data) data = [{[Set.name]:Datas}]
            else data.push({[Set.name]:Datas})
          }
        }
      })
      return data
    }
  }))

  const ViewElements = useMemo(() => {
    //return renderedData.sort((a,b) => ViewPriorities[b.type] - ViewPriorities[a.type]).map((Set, index) => {
      return renderedData.sort((a,b) => ViewPriorities[b.type] - ViewPriorities[a.type]).reduce((Elements:{}|Record<DataForm,JSX.Element[]>, Set, index) => {
        var _View:JSX.Element|undefined = undefined
        var _SelectedOptions = Settings.canMultiSplit.state?({left:Set.form, args:Selected_DataOptions.args}):Selected_DataOptions
        var DataDomain = Settings.canMultiSplit.state?Get_Domains(Settings, renderedData, DeactiveSets, _SelectedOptions):entireDomain
        
        if (!Set.deactive&&!DeactiveSets?.some(e => e===Set.name)) {
          var ExTime = (Set.startPoint?Set.startPoint:0)/(Settings.zeroIndex?UnixDayTime:1)
          var Datas = Set.data?.sort((a,b) => a.x - b.x).map((data, index) => ({...data, x:Settings.zeroIndex?(Math.round((data.x - (Set.data&&Set.data[0]?Set.data[0].x:0)) / UnixDayTime)+ExTime):data.x+ExTime})).filter(data=>{
            if (!ViewTime) return true
            if (data.x >= ViewTime.start) {
              if (!ViewTime.end) return true
              if (data.x <= ViewTime.end) return true
              //if (data.x <= ViewTime.end + (Set.color&&Set.color==="#000"?UnixDayTime*2:0)) return true //ERROR Does not delete notes ... made for adding days to template..??
            }
            return false
          })
          switch(Set.type) {
            case ViewForms.LINE:
              var _dataIndex = ActiveData.findIndex(e => e.name === Set.name)
              var _Color = Set.color?Set.color:getUniqueColor(2 + _dataIndex)
              _View = (
                <VictoryGroup key={"Line_" + Set.name + "_" + index}
                  data={Datas}
                  // normalize data
                  y={(datum:any) => {
                    var Y = _SelectedOptions.left===Set.form||Set.form==='WeightTaged'?DataDomain.y:DataDomain.y2
                    var Val = (datum.y-Y.min) / (Y.max-Y.min)
                    //if (Set.form === 'Growth')console.log("daumt",Val, datum, Y)
                    return Val //Math.round(Val * 100) / 100
                  }}
                >
                  <VictoryLine name={Set.name+''}
                    style={{
                      data: {
                        stroke: _Color, strokeWidth: 3,//({active}) => active?1.5:3,
                        strokeDasharray: Set.dotted?"3,3":""
                      }
                    }}
                  />
                  <VictoryScatter name={"none_"+Set.name+''} size={2}
                    style={{data: { 
                      stroke: ({datum}) =>{ 
                        if (datum.y < 0) return 'red'
                        else return "#000"
                      }, 
                      strokeWidth: ({datum}) =>{ 
                        if (datum.y < 0) return 1
                        else return 0.5
                      }, 
                      fill: _Color/*({active}) => active?'red':_Color*/
                    }}}
                  />
                </VictoryGroup>
              )
              break;
            case ViewForms.BAR:
              if (Set.form === ConsysRefs.VALVEFEED || Set.form === "Feed") {
                _View = (
                  <VictoryGroup key={"Bar_" + Set.name + "_" + index} name={Set.name+''}>
                    {Datas?.map(FeedData => {
                      if (FeedData.objData && FeedData.objData.silolist && FeedData.objData.silolist.length >= 1) {
                        return(
                          <VictoryStack key={"Stack_"+Set.name+'_'+index} style={{data: { 
                              stroke: "#f2f2f2", strokeWidth: 1
                          }}}>
                            {
                              FeedData.objData.silolist.map((SiloData,index) => {
                                return(
                                  <VictoryBar key={"Bar_"+Set.name+'_'+index} name={Set.name+'_'+(SiloData.siloname?SiloData.siloname:SiloData.silono)} //name={Set.name+''+'_'+index}
                                    barWidth={ 6 } style={{data: { fill: getUniqueColor((SiloData.silono||index)+11)} }}
                                    data={ [{x: FeedData.x, y: (SiloData.amount/1000), siloname:SiloData.siloname, silono:SiloData.silono}] }
                                    y={(datum) => {
                                      var Y = _SelectedOptions.left===Set.form?DataDomain.y:DataDomain.y2
                                      var res = (datum.y-Y.min) / (Y.max-Y.min)
                                      return res //Math.round(res* 100) / 100
                                    }}
                                  />
                                )
                              })
                            }
                          </VictoryStack>
                        )
                      }
                      else {
                        return(
                          <VictoryBar key={"Bar_" + Set.name + "_" + index} name={Set.name+''}
                            barWidth={ 6 } style={{data: { fill: Set.color, stroke: "#f2f2f2", strokeWidth: 1 }}}
                            data={ [FeedData] }
                            y={(datum:any) => {
                              var Y = _SelectedOptions.left === Set.form?DataDomain.y:DataDomain.y2
                              var res = (datum.y-Y.min) / (Y.max-Y.min)
                              return res //Math.round(res* 100) / 100
                            }}
                          />
                        )
                      }
                    })}
                  </VictoryGroup>
                )
              }
              else {
                _View = (
                  <VictoryBar key={"Bar_" + Set.name + "_" + index} name={Set.name+''}
                    barWidth={ 6 } style={{data: { fill: Set.color, stroke: "#f2f2f2", strokeWidth: 2 }}}
                    data={Datas}
                    y={(datum:any) => {
                      var Y = _SelectedOptions.left === Set.form?DataDomain.y:DataDomain.y2
                      var res = (datum.y-Y.min) / (Y.max-Y.min)
                      return res //Math.round(res* 100) / 100
                    }}
                  />
                )
              }
              break
            case ViewForms.SCATTER:
              var ExtraComponent:JSX.Element[] = []
              if (Set.form === 'Notes' && Datas && Datas.some(e => e.objData && e.objData.action && e.objData.action === "Treatment")) {
                ExtraComponent = Datas?.reduce((res:JSX.Element[], datum, index) => {
                  if (datum.objData && datum.objData.action && datum.objData.action === "Treatment") {
                    //TODO: fits FeedShift code
                    if (datum.objData.code && datum.objData.code.endsWith('_2000')) {
                      res.push(
                        <VictoryLine name={"none_"+Set.name+'_Treat_'+index}
                          data={[{x: datum.x, y: 0},{x: datum.x, y: 1}]}
                          style={{data: {
                            stroke: "#737373", strokeWidth: 2, strokeDasharray: "5,3"
                          }}}
                        />
                      )
                    }
                  }
                  return res
                }, [])
                console.log("extra", ExtraComponent)
              }
              _View = (
                <VictoryGroup key={"Scatter_" + Set.name + "_" + index}
                >
                  <VictoryScatter name={"none_"+Set.name} size={2}
                    data={Datas}
                    y={(datum:any) => {
                      return datum.y==='min'?0:datum.y
                    }}
                  />
                  {ExtraComponent}
                  <VictoryScatter name={Set.name+'_'+index} key={Set.form + "_" + Set.name + "_" + index} size={2}
                    style={{data: { stroke: ({datum}) =>{ 
                      if (datum.y < 0) return 'red'
                      else return "#000"
                    }, strokeWidth: ({datum}) =>{ 
                      if (datum.y < 0) return 1.5
                      else return 1
                    }, fill: Set.color?Set.color:getUniqueColor(2 + _dataIndex)/*({active}) => active?'red':Set.color?Set.color:getUniqueColor(2 + _dataIndex)*/}}}
                    data={Datas}
                    y={(datum:any) => {
                      return datum.y==='min'?0:datum.y
                    }}
                  />
                </VictoryGroup>
              )
              break;
            case ViewForms.DIST:
              //see Dasboard .... better
              /*return (
                null
              )*/
              break;
          }
        }
        if (_View){
          if (!Elements[Set.form]) Elements[Set.form] = [_View]
          else Elements[Set.form].push(_View)
        }
        return Elements
      },{})
    //}).filter(e => e)
  }, [renderedData, DeactiveSets, ViewTime])

  if (!Settings.canMultiSplit.state) {
    return (
      <View style={[Layout.fill, {maxHeight:typeof Settings.maxheight==='string'?Settings.maxheight:Settings.maxheight+'px', width:'-webkit-fill-available', marginVertical:5, marginHorizontal:5, padding:2.5}]}>
        {CreateChart(t, Settings, entireDomain, ActiveOptions, Legends(Settings, zoomedXDomain, ViewTime), Object.values(ViewElements).flat(1), Selected_DataOptions)}
      </View>
    )
  }
  else {
    var Notes = ViewElements['Notes']?ViewElements['Notes']:[]
    delete ViewElements['Notes']
    return (
      <View style={[Layout.fill, {width:'-webkit-fill-available',}]}>
        {Object.entries(ViewElements).map(([Form,Elements],index) => {
          var DataDomain = Get_Domains(Settings, renderedData, DeactiveSets, {left:Form, args:Selected_DataOptions.args})
          return (
            <View key={"GraphFrams_"+index} style={[Layout.fill, {maxHeight:typeof Settings.maxheight==='string'?Settings.maxheight:Settings.maxheight+'px', width:'-webkit-fill-available', marginVertical:5, marginHorizontal:5, padding:2.5}]}>
              {CreateChart(t, Settings, DataDomain, ActiveOptions, Legends(Settings, DataDomain.x, ViewTime), Elements.concat(Notes), {left:Form, args:Selected_DataOptions.args})}
            </View>
          )
        })}
      </View>
    )
  }
}

export default forwardRef(CurveViewer) 
//export default(CurveViewer)