// Need to use the React-specific entry point to import createApi
import { createApi, fetchBaseQuery, BaseQueryFn, FetchArgs, FetchBaseQueryError, retry, FetchBaseQueryMeta } from '@reduxjs/toolkit/query/react'
import { DataConsData, FarmData, isEmpty, SysConfig, SystemSet, TimeSpan } from '../../Config/Types'
import { RootState } from '../../Store'
import { Config_Pri } from './Config'
import { setToken } from '../../Store/StateHandlers/Active-slice'
import { ResetProp, ScaleProp } from './Scale-Slice'
import { ScaleProp as CorridorProp } from './Corridor-Scale'
import { FeedProp } from './Feed-Slice'
import { TemperatureProp } from './Temperature-Slice'
import { WaterProp } from './Water-Slice'
import { SystemInfoArgs } from './Systems-slice'
import { Mutex } from 'async-mutex'

//#region Internal Gets
const getFarmKey = (api:any, args:FetchArgs):string => {
  const State = (api.getState() as RootState)
  return args.params&&args.params.FarmKey?args.params.FarmKey:State.activeData.farm
}

const getFarmAccess = (api:any, FarmKey:any):SystemSet|undefined => {
  const State = (api.getState() as RootState)
  var Datas:SystemSet|undefined = undefined
  if (State.firebase.data.farms&&State.firebase.data.farms[FarmKey]) {
    var FarmData:FarmData = State.firebase.data.farms[FarmKey]
    Datas = {site: FarmData.site, refToken: FarmData.refToken}
  } else if (State.firebase.data.LinkList&&State.firebase.data.LinkList[FarmKey]) {
    Datas = State.firebase.data.LinkList[FarmKey].System
  }
  return Datas
}

const getAccToken = (api:any, FarmKey:any):string|undefined => {
  const State = (api.getState() as RootState)
  return FarmKey===State.activeData.farm?State.activeData.Token:State.activeData.XTernalTokens[FarmKey]
}
//#endregion

const _GetError = (dataText?:string):any => {
  return {error: {
    status: 400,
    statusText: 'Bad Request',
    data: dataText?dataText:'No Consys FarmId found',
  }}
}
const rawBaseQuery = (farmSite:string, accessToken?: string) => retry(async (args: string|FetchArgs, api, extraOptions) => {
  const result = await fetchBaseQuery({
    baseUrl: 'https://' + farmSite +'/',
    method: 'GET',
    //mode:'cors',
    prepareHeaders: (headers, { getState }) => {
      //const token = (getState() as RootState).startup.consysTok
      // If we have a token set in state, let's assume that we should be passing it.
      if (accessToken) {
        //headers.set('Access-Control-Allow-Origin', null)
        //headers.set('Access-Control-Allow-Credentials', true)
        headers.set('Accept', 'application/json')
        headers.set('Content-Type', 'application/json')
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
      return headers
    }
  })(args,api,extraOptions)
  // bail out of re-tries immediately if unauthorized,
  // because we know successive re-retries would be redundant
  if (result.error?.status === "FETCH_ERROR" || result.error?.status === 401) {
    retry.fail(result.error)
  }
  return result
}, { maxRetries: 3 })

const ReAccessMutex = new Mutex()
const ReAccessFetch = (api:any, extraOptions:any, FarmKey:string): Promise<string> => new Promise((FetchResolve, FetchReject) => {
  // Check fetch locks (could updating token)
  var TempAcc = getAccToken(api, FarmKey)
  ReAccessMutex.runExclusive(() => new Promise<string>(async (getResolve, getReject) => {
    // Check if token reloaded while lock = true
    var TempAcc2 = getAccToken(api, FarmKey) 
    if (TempAcc2 && (!TempAcc || TempAcc !== TempAcc2)) getResolve(TempAcc2)
    // Aquire new accesstoken .. web
    const farmData = getFarmAccess(api, FarmKey) // will not be null as pre-tested in dynamicBaseQuery 
    let result = farmData?await rawBaseQuery(farmData.site + Config_Pri.CONSYS_AUTH)({ url: `${Config_Pri.GetToken_URL(farmData.refToken)}` }, api, extraOptions):null
    if (result && result.data && result.data.access_token) {
      // set and return new token
      var accessToken = result.data.access_token
      api.dispatch(setToken({FarmKey:FarmKey===(api.getState() as RootState).activeData.farm?null:FarmKey, token: accessToken}))
      getResolve(accessToken)
    } else {
      // Token rejected - all fail
      ReAccessMutex.cancel()
      getReject('Token retrieve error - ConsysGet')
    }
  }))
  // Handle Result state
  .then(
    result => FetchResolve(result),
    error => FetchReject(error)
  ).catch(e => FetchReject("Canceled"))
})

const AccessMutex = new Mutex()
const AccessFetchCheck = (api:any, extraOptions:any, FarmKey:string, reFetch:boolean = false): Promise<string> => new Promise((MainResolve, MainReject) => {
  // Check fetch locks (could updating token)
  AccessMutex.runExclusive(() => new Promise<string>(async (MemResolve, MemReject) => {
    await ReAccessMutex.waitForUnlock()
    // Aquire accesstoken mem
    var FetchResults = getAccToken(api, FarmKey)
    if (!FetchResults || reFetch) {
      // Aquire accesstoken web
      return ReAccessFetch(api, extraOptions, FarmKey).then(
        result => MemResolve(result),
        error => {
          console.error("ReAccess err:", error)
          MemResolve('Token fetch failed')
        }
      ).catch(e => MemReject("Canceled"))
    }
    else {
      // Return access mem
      if (FetchResults) MemResolve(FetchResults)
      else MemReject('Token not found')
    }
  }))
  // Handle Result state
  .then(
    result => MainResolve(result),
    error => MainReject(error)
  ).catch(e => MainReject("Canceled"))
})

const TryGetCall = (args:FetchArgs, api:any, extraOptions:any, FarmKey:any, accessToken:string) => new Promise(async (resolve, reject) => {
  var url = args.url
  const farmData:SystemSet = getFarmAccess(api, FarmKey) // will not be null as pre-tested in dynamicBaseQuery
  //if (farmData.site === "hk-hornsyld") url = url.replace('&systemindex=', '&systemid=')
  //TODO: ... Config_Pri replacements ...args will set args in url call
  let result = await rawBaseQuery(farmData.site + Config_Pri.CONSYS_API, accessToken)({ url: `${url}` }, api, extraOptions)
  if (result.error) {
    //console.log(result, result.error.status )
    //Error: Token / Access
    if (result.error.status === "FETCH_ERROR" || result.error.status === 403 || result.error.status === 401) {
      await ReAccessFetch(api, extraOptions, FarmKey).then(
        newAccess => {
          TryGetCall(args, api, extraOptions, FarmKey, newAccess).then(
            ReTryData => resolve(ReTryData),
            ReTryErr => reject(ReTryErr)
          )
        },
        err => {
          console.error("Re_TrygetCall Err:", err)
          reject("No Access")
        }
      )
    }
    else reject("DataFetch error")
  }
  else resolve(result)
})

const GetCall = async (args, api, extraOptions, FarmKey) => {
  return await AccessFetchCheck(api, extraOptions, FarmKey).then(async memToken => {
    return await TryGetCall(args, api, extraOptions, FarmKey, memToken).then(DataRes => {
      return DataRes
    }, error => {
        console.error("TryGetCall: ", error)
        return _GetError("no2")
    })
  }, error => {
    console.error("AccessFetchCheck: ", error)
    return _GetError("no1")
  })
}

const APIMutex = new Mutex()
export interface ConsysArgs { FarmKey: string|undefined }
const dynamicBaseQuery: BaseQueryFn<FetchArgs, unknown, FetchBaseQueryError, {}, FetchBaseQueryMeta> = async (args:FetchArgs, api, extraOptions) => {
  const FarmKey = getFarmKey(api, args)
  if (!FarmKey) return _GetError('No present FarmKey')
  const farmData = getFarmAccess(api, FarmKey)
  if (!farmData || !farmData.site || !farmData.refToken) return _GetError('No FarmData found')

  if (FarmKey === '-EasyGain'){
    return await GetCall(args, api, extraOptions, FarmKey)
  } else {
    //console.log("Api Mutex")
    return await APIMutex.runExclusive(async () => {
      //console.log("Api Mutex Lock")
      return await GetCall(args, api, extraOptions, FarmKey)
    })
    .then((res) => {
      //console.log("Api Mutex releash", res)
      return res
    })
    .catch(e => {
      //console.log("Api Mutex error", e)
    })
  }
}

export const ConsysApi = createApi({
  reducerPath: 'ConsysApi',
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
  }),
})

//#region Collected External uses
export const MultiLoop = async (CallBackFN:any, args:SystemInfoArgs|ScaleProp|CorridorProp|FeedProp|TemperatureProp|WaterProp, queryApi:any, extraOptions:any, fetchWithBQ:any) => {
  if (args && args.FarmKeys) {
    var Keys = Array.isArray(args.FarmKeys)?args.FarmKeys:[args.FarmKeys]
    var resData = await Promise.all(Keys.map((DataLink, index) => {
      var CallArgs = {...args, FarmKey:DataLink}
      delete CallArgs.FarmKeys

      var Ids = []
      if (DataLink&&args.Xsystemids&&args.Xsystemids.length) {
        var IdsIndex = args.Xsystemids.findIndex(e =>  e.some(x => x.XTern === DataLink))
        if(IdsIndex!==-1) CallArgs.systemids = args.Xsystemids[IdsIndex]
      }
      //if (DataLink&&args.Xsystemids&&args.Xsystemids.length&&args.Xsystemids[index]) CallArgs.systemids = args.Xsystemids[index]
      //else if (args.systemids) CallArgs.systemids = args.systemids
      //console.log("this is it", args, CallArgs)

      return CallBackFN(CallArgs, queryApi, extraOptions, fetchWithBQ)
    }))

    var res:{data:any[][], error:any[]} = {data:[], error:[]}
    resData.forEach((val, index) => {
      if (val && val.data) {
        res.data.push(val.data)
      }
      if (val && val.error) {
        res.error.push(val.error)
      }
    })
    //res.data = res.data.filter(e => e)
    //res.error = res.error.filter(e => e)
    if (!isEmpty(res.error)) {
      return {error: res.error[0]}
    }
    else if (res.data) return {data: res.data}
  }
  return undefined
}

export const GetAutos = (args:SystemInfoArgs|ScaleProp|FeedProp|TemperatureProp|WaterProp , queryApi:any) => {
  const state = (queryApi.getState() as RootState)
    
  var Autopigs:Record<string, DataConsData|SysConfig>|null = null 
  if (args.FarmKey && state.firebase.data && state.firebase.data.LinkListData && state.firebase.data.LinkListData[args.FarmKey]) Autopigs = state.firebase.data.LinkListData[args.FarmKey].DataCons
  else Autopigs = state.firebase.data.DataCons

  var sysIDs = args.systemids
  //console.log("state", args, args.FarmKey, Autopigs, sysIDs)
  if (sysIDs && typeof sysIDs === "string" && sysIDs.includes(',')) sysIDs = sysIDs.split(',') 
  var getAutos = Autopigs?Object.entries(Autopigs).filter(([key, data]) => (key!=="Sys_Config"&&key!=="update_time"&&(!sysIDs||(
    typeof sysIDs==="string"?sysIDs==="all"||key===sysIDs:
    typeof sysIDs==='object'?Object.entries(sysIDs).some(([keys, data]) => (data&&typeof data==="string"&&data===key)||(data&&data.id===key))
    :false
  )))):[]

  return getAutos
}

export const GetTimeSets = (args:ScaleProp|ResetProp|FeedProp|TemperatureProp|WaterProp , queryApi:any, ArgsIndex?:number):TimeSpan => {
  const state = (queryApi.getState() as RootState)
  var Times:TimeSpan = {start:undefined, end:undefined}
  if (args) {
    //Manuel start stop, set...
    if (args.ViewPeriods) {
      if (Array.isArray(args.ViewPeriods)) {
        if (args.ViewPeriods.length>1) {
          var val:number|undefined = undefined
          args.ViewPeriods.forEach(element => {
            if (!val || val > element.start) val = element.start
          })
          Times.start = val
        }
        else Times.start = args.ViewPeriods[0].start
      }
      else Times.start = args.ViewPeriods.start
    }
    if (args.ViewPeriods) {
      if (Array.isArray(args.ViewPeriods)) {
        if (args.ViewPeriods.length>1) {
          var val:number|undefined = undefined
          args.ViewPeriods.forEach(element => {
            if (!val || val < element.end) val = element.end
          })
          Times.end = val
        }
        else Times.end = args.ViewPeriods[0].end
      }
      else Times.end = args.ViewPeriods.end
    }
    //console.log("times res", Times, args, ArgsIndex)
    //Shared start stop, set...
    /*if (args.FarmKey && args.FarmKey != state.activeData.farm) {
      if (state.firebase.data.LinkList && state.firebase.data.LinkList[args.FarmKey]) {
        if (state.firebase.data.LinkListData && state.firebase.data.LinkListData[args.FarmKey]) {
          var Infos = state.firebase.data.LinkList[args.FarmKey]
          var Datas = state.firebase.data.LinkListData[args.FarmKey]
          var getAutos = GetAutos(args, queryApi)

          getAutos.forEach(([key, AutoPig], index) => {
            
          })
        }
      }
      if (state.firebase.data.LinkList[args.FarmKey].starttime) {
        //if (!STime || STime < state.firebase.data.LinkList[args.FarmKey].starttime) STime = state.firebase.data.LinkList[args.FarmKey].starttime
      }
      if (state.firebase.data.LinkList[args.FarmKey].endtime) {
        //if (!ETime || ETime > state.firebase.data.LinkList[args.FarmKey].endtime) ETime = state.firebase.data.LinkList[args.FarmKey].endtime
      }
    }*/
  }
  return Times
}
//#endregion