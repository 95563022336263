import React, { useEffect, useMemo, useState } from 'react'
import {
  View,
  Text,
  TouchableOpacity,
  FlatList,
} from 'react-native'
import { Button, Datetime_Picker, Labelled_Input } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate, isApp } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, isLoaded, useFirebaseConnect } from 'react-redux-firebase'

import { Select, TYPE } from 'baseui/select'
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox'
import DeleteIcon from 'baseui/icon/delete-alt'
import { DataConsData, isDemoFarm, isEmpty, FormatTimes, UserStates, SystemData, TimeSpan } from '../../Config/Types'
import { FirebaseCaller, Pens as FBPens, Graphs, DataCons } from '../../Services/Firebase'

const StartPeriodes:TimeSpan = {start:(new Date(new Date().setHours(0,0,0,0)).setDate(new Date().getDate() - 7)), end:(new Date().setHours(23,59,59,59))}
//#region Pen edditor setting props
interface Pen_Props {
  Id: string,
  data: {penstart:number|"", periode:[number|undefined, number|undefined]},
  XTern?: string
}
interface PenProp_Props extends Pen_Props {
  setData: (e:{penstart:number|"",periode:[number|undefined, number|undefined]}) => void,
  remove: () => void
}
const PenProp = ({Id, data, XTern, setData, remove}:PenProp_Props) => {
  const { t } = useTranslation()
  const { Layout, Common, Gutters } = useTheme()

  const activefarm = useAppSelector(state => state.activeData.farm)
  const Settings = useAppSelector<SystemData|undefined>(({firebase: {data}}) => data&&data.system?data.system:undefined)
  //const penData = useAppSelector(state => FBPens.GetPenData(state, parseInt(Id), XTern))
  
  FBPens.fetchPenPeriodes(activefarm, true, [], parseInt(Id), XTern)
  const Periodes = useAppSelector(state => FBPens.GetPenPeriodes(state, XTern, parseInt(Id)))

  const PeriodeOptions = useMemo<{label:string, start:number, end:number, pen?:string}[]>(() => {
    var Reslist = !isEmpty(Periodes)?Object.values(Periodes).flat(1).map((Values, i) => {
      if (Values.starttime && Values.endtime) {
        var S_Date = new Date(Values.starttime)
        var E_Date = new Date(Values.endtime)
        let Label_S = `${S_Date.getDate()}/${S_Date.getMonth()+1}/${S_Date.getFullYear()}`
        let Label_E = `${E_Date.getDate()}/${E_Date.getMonth()+1}/${E_Date.getFullYear()}`
        return {label: `${Label_S} - ${Label_E}`, start:Values.starttime, end:Values.endtime}
      } else if (Values.starttime) {
        var S_Date = new Date(Values.starttime)
        let now = new Date()
        let Label_S = `${S_Date.getDate()}/${S_Date.getMonth()+1}/${S_Date.getFullYear()}`
        let Label_E = `${now.getDate()}/${now.getMonth()+1}/${now.getFullYear()}`
        return {label: `${Label_S} - ${Label_E}`, start:Values.starttime, end:now.getTime()}
      }
      return null
    }).filter(e => e).reverse():[]
    //??? only latest 10 Reslist.slice(0, 10)
    return Reslist
  }, [Periodes])

  const [DateTimePar, setDateTimePar] = useState<TimeSpan>({start:data.periode[0]?data.periode[0]:StartPeriodes.start,end:data.periode[1]?data.periode[1]:StartPeriodes.end})

  return (
    <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'300px', maxWidth:'-webkit-fill-available', flexWrap: "wrap", flexDirection: "row"}]}>
      <View style={[Layout.fill, Layout.column, {alignSelf:'center', maxWidth:'-webkit-fill-available'}]}>
        <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {flexWrap:'wrap', width:'-webkit-fill-available', alignSelf:'center', minWidth:'100px', justifyContent:'space-between'}]}>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'100px', maxWidth:'max-content'}]}>
            <Text style={{fontSize:'28px',fontWeight:600}}>{(Settings&&Settings.penPrefix?Settings.penPrefix:t('FarmSettings.Pendefault')) + Id +(XTern?' @':'')}</Text>
          </View>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'min-content', maxWidth:'max-content'}]}>
            <Labelled_Input
              value={data.penstart+''} // todo... option
              label={"Pen start:"}
              placeholder={""}
              type={"number"}
              onChange={(text, num) => {
                setData({...data, penstart:num})
              }}
              maxWidth={75}
              minWidth={75}
            />
          </View>
        </View>
        <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {flexWrap:'wrap', minWidth:'min-content', maxWidth:'-webkit-fill-available', justifyContent:'flex-end'}]}>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'min-content', maxWidth:'max-content'}]}>
            <Datetime_Picker MarkDates={!isEmpty(Periodes)?Object.values(Periodes).flat(1):undefined} MoveArrows={false} 
              value={DateTimePar} 
              onValueChange={(time) => {
                if (time) {
                  setDateTimePar(time)
                  setData({...data, periode:[time.start,time.end]})
                }
              }}
            />
            <View style={[{width:5}]} />
            <Select clearable={false}
              options={PeriodeOptions}
              value={[]}
              type={TYPE.select}
              placeholder=""
              onChange={(params:any) => {
                var value = params.value[0]
                setDateTimePar({start:value.start,end:value.end})
                setData({...data, periode:[value.start,value.end]})
              }}
              maxDropdownHeight="150px"
              overrides={{
                Root: { style: ({ $theme }) => ({
                  alignSelf:'flex-end',
                  width:'40px',
                  height:'20',
                  outline:'black 1px solid'
                })},
                DropdownContainer: { style: ({ $theme }) => ({
                  width:'220px',
                })}
              }}
            />
          </View>
        </View>
      </View>
      <TouchableOpacity style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'40px', maxWidth:'max-content'}]} 
        onPress={() => {
          remove()
        }}>
          <DeleteIcon size={36} color={"black"} title={""}/>
      </TouchableOpacity>
    </View>
  )
}
//#endregion

//#region Autopig edditor setting props
interface Autopig_Props {
  Id: string|number,
  info: {name:string, scale:boolean, activity:boolean, temp:boolean, water:boolean, feed:boolean},
  data: {penstart:number|"", periode:[number|undefined, number|undefined]},
  XTern?: string
}
interface AutopigProp_Props extends Autopig_Props {
  setData: (e:{name:string, scale:boolean, activity:boolean, temp:boolean, water:boolean, feed:boolean}, d:{penstart:number|"",periode:[number|undefined, number|undefined]}) => void,
  remove: () => void
}
const AutopigProp = ({Id, info, data, XTern, setData, remove}:AutopigProp_Props) => {
  const { t } = useTranslation()
  const { Layout, Common, Gutters } = useTheme()

  const activefarm = useAppSelector(state => state.activeData.farm)
  const AutopigData = useAppSelector(state => DataCons.GetDataCons(state, XTern, parseInt(Id+'')))

  const AutoPens = useMemo(() => {
    var Pens:string[] = []
    if (AutopigData) {
      if (AutopigData.feed) {
        Pens = Pens.concat(AutopigData.feed.split(','))
      }
      if (AutopigData.scale) {
        Pens = Pens.concat(AutopigData.scale.split(',').filter((e: string) => !Pens.includes(e)))
      }
      if (AutopigData.activity) {
        Pens = Pens.concat(AutopigData.activity.split(',').filter((e: string) => !Pens.includes(e)))
      }
      if (AutopigData.temp) {
        Pens = Pens.concat(AutopigData.temp.split(',').filter((e: string) => !Pens.includes(e)))
      }
      if (AutopigData.water) {
        Pens = Pens.concat(AutopigData.water.split(',').filter((e: string) => !Pens.includes(e)))
      }
    }
    return Pens
  }, [AutopigData])
  FBPens.fetchPenPeriodes(activefarm, true, [], AutoPens, XTern)

  const Periodes = useAppSelector(state => FBPens.GetPenPeriodes(state, XTern, AutoPens))
  const PeriodeOptions = useMemo<{label:string, start:number, end:number, pen?:string}[]>(() => {
    return Periodes&&!isEmpty(Periodes)?Object.values(Periodes).flat(1).reduce((obj:any[], Values:any) => {
      if (Values.starttime && Values.endtime) {
        var S_Date = new Date(Values.starttime)
        var E_Date = new Date(Values.endtime)
        let Label_S = `${S_Date.getDate()}/${S_Date.getMonth()+1}/${S_Date.getFullYear()}`
        let Label_E = `${E_Date.getDate()}/${E_Date.getMonth()+1}/${E_Date.getFullYear()}`
        obj.push({label: `${Label_S} - ${Label_E}`, start:Values.starttime, end:Values.endtime})
      } else if (Values.starttime) {
        var S_Date = new Date(Values.starttime)
        let now = new Date()
        let Label_S = `${S_Date.getDate()}/${S_Date.getMonth()+1}/${S_Date.getFullYear()}`
        let Label_E = `${now.getDate()}/${now.getMonth()+1}/${now.getFullYear()}`
        obj.push({label: `${Label_S} - ${Label_E}`, start:Values.starttime, end:Values.endtime})
      }
      return obj
    }, []).reverse():[]
  }, [Periodes])
  const [DateTimePar, setDateTimePar] = useState<TimeSpan>(
    data&&data.periode?{start:data.periode[0]?data.periode[0]:StartPeriodes.start,end:data.periode[1]?data.periode[1]:StartPeriodes.end}:
    {start:StartPeriodes.start,end:StartPeriodes.end}
  )

  return (
    <View style={[Layout.fill, Layout.row, Common.border, {minWidth:'300px', maxWidth:'-webkit-fill-available', flexWrap: "wrap", flexDirection: "row"}]}>
      <View style={[Layout.fill, Layout.column, {alignSelf:'center', maxWidth:'-webkit-fill-available'}]}>
        <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {flexWrap:'wrap', width:'-webkit-fill-available', alignSelf:'center', minWidth:'100px', justifyContent:'space-between'}]}>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'100px', maxWidth:'max-content'}]}>
            <Text style={[{fontSize:28,fontWeight:'600'}]}>{`${info.name?info.name:Id} ${XTern?" @":""}`}</Text>
          </View>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'min-content', maxWidth:'max-content'}]}>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.scale}
              onChange={(e:any) => {
                setData({...info, scale:e.target.checked}, data)
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop3')}</Text></Checkbox>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.activity}
              onChange={(e:any) => {
                setData({...info, activity:e.target.checked}, data)
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop4')}</Text></Checkbox>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.temp}
              onChange={(e:any) => {
                setData({...info, temp:e.target.checked}, data)
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop5')}</Text></Checkbox>
            <Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.water}
              onChange={(e:any) => {
                setData({...info, water:e.target.checked}, data)
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop6')}</Text></Checkbox>
            {/*<Checkbox
              labelPlacement={LABEL_PLACEMENT.top}
              overrides={{
                Root: { style: ({ }) => ({
                  alignSelf: "center", alignItems: "center",
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })},
                Label: { style: ({ }) => ({
                  justifyContent:'flex-end', paddingBottom:'5px',
                  minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                })}
              }}
              checked={info.feed}
              onChange={(e:any) => {
                setData({...info, feed:e.target.checked}, data)
              }}
            ><Text style={[{}]}>{t('Autopigs.Prop7')}</Text></Checkbox>*/}
          </View>
          <Labelled_Input
            value={data&&data.penstart?data.penstart+'':""} // todo... option
            label={"Data start:"}
            placeholder={""}
            type={"number"}
            onChange={(text, num) => {
              setData(info, {...data, penstart:num})
            }}
            maxWidth={75}
            minWidth={75}
          />
        </View>
        
        <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {flexWrap:'wrap', minWidth:'min-content', maxWidth:'-webkit-fill-available', justifyContent:'flex-end'}]}>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'min-content', maxWidth:'max-content'}]}>
            <Datetime_Picker MarkDates={!isEmpty(Periodes)?Object.values(Periodes).flat(1):undefined} MoveArrows={false} 
              value={DateTimePar} 
              onValueChange={(time) => {
                if (time) {
                  setDateTimePar(time)
                  setData(info, {...data, periode:[time.start,time.end]})
                }
              }}
            />
            <View style={[{width:5}]}/>
            <Select clearable={false}
              options={PeriodeOptions}
              value={[]}
              type={TYPE.select}
              placeholder=""
              onChange={(params:any) => {
                var value = params.value[0]
                setDateTimePar({start:value.start,end:value.end})
                setData(info, {...data, periode:[value.start,value.end]})
              }}
              maxDropdownHeight="150px"
              overrides={{
                Root: { style: ({ $theme }) => ({
                  alignSelf:'flex-end',
                  width:'40px',
                  height:'20',
                  outline:'black 1px solid'
                })},
                DropdownContainer: { style: ({ $theme }) => ({
                  width:'220px',
                })}
              }}
            />
          </View>
        </View>
      </View>
      <TouchableOpacity style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {alignSelf:'center', minWidth:'40px', maxWidth:'max-content'}]} 
        onPress={() => {
          remove()
        }}>
          <DeleteIcon size={36} color={"black"} title={""}/>
      </TouchableOpacity>
    </View>
  )
}
//#endregion

interface ConfigView_Props {
  New?: boolean,
  Selected?: string,
}
const Reports_Editor = (MainProps:any) => {
  const { t } = useTranslation()
  const { Layout, Common, Gutters, Fonts } = useTheme()
  const MainParams:ConfigView_Props = MainProps.route.params?MainProps.route.params:undefined

  //const dispatch = useAppDispatch()
  const firebase = useFirebase()
  const activefarm = useAppSelector(state => state.activeData.farm)
  const profile = useAppSelector(({firebase}) => firebase.profile)
  
  const XTern_Data = useAppSelector(state => DataCons.GetExternDataCons(state, undefined, undefined))
  const XTern_Autopigs = useAppSelector(state => DataCons.GetExternDataCons(state, undefined, undefined, false))
  
  const Autopigs = useAppSelector(state => DataCons.GetInternDataCons(state))
  const AutoOptions = useMemo(() => {
    var AutosList:({label:string, id:string, index:number, index2?:number, XTern?:string}|null)[] = (
      Autopigs?Object.entries(Autopigs).map(([key, Values], i) => Values.feed?null:({label: Values.name?Values.name:key, id: key, index:i})):[]
    ).concat(
      XTern_Autopigs?Object.entries(XTern_Autopigs).map(([XTernId, Autos], i) => Object.entries(Autos as Record<number, DataConsData>).map(([AutoId, Datas],i2) => 
        Datas.feed?null:({label: `${Datas.name?Datas.name:AutoId} @`, id:AutoId, index:i, index2:i2, XTern:XTernId})
      )).flat(1):[]
    ).filter(e=>e)
    return AutosList
  }, [Autopigs, XTern_Autopigs])

  const XTern_pens = useMemo(() => XTern_Data?Object.entries(XTern_Data).filter(([key,val]) => val&&val.pens).map(([key,val]) => Object.keys(val.pens).filter(([key,val])=> (key !=="update_time"))):null, [XTern_Data])
  const Pens = useAppSelector(({firebase: { data }}) => data.pens?Object.keys(data.pens).filter((key) => key!=="update_time"):[])

  const Reports = useAppSelector(({firebase: { data }}) => data.reports?Object.fromEntries(Object.entries(data.reports).filter(([key,val])=> (key !=="update_time"))):null)
  const DataGraphs = useAppSelector(state => Graphs.GetPenWeightGraph(state))

  const [SelectedReport, setSelectedReport] = useState<string|null>(MainParams.Selected?MainParams.Selected:null)
  const [ReportName, setReportName] = useState<string>("")
  const [SelectedCurve, setSelectedCurve] = useState([{label:'None', id:'-1'}])
  const [PensList, setPensList] = useState<Pen_Props[]>([])
  //const [SelectedPen, setSelectedPen] = useState<string>("")
  const [AutoList, setAutoList] = useState<Autopig_Props[]>([])
  //const [SelectedAuto, setSelectedAuto] = useState<string>("")
  const [isArchived, setisArchived] = useState<boolean>(false)

  useEffect(() => {
    if (!MainParams.New) {
      var value = Reports?Object.keys(Reports).filter(e => e !== 'update_time')[0]:null
      if (value && !SelectedReport) {
        setSelectedReport(value)
      }
    }
  }, [Reports])

  useEffect(() => {
    if (!MainParams.New && SelectedReport && SelectedReport !== "") {
      var data = Reports?Reports[SelectedReport]:null
      if (data) {
        if (data.Archived) setisArchived(true)
        if (data.name) setReportName(data.name)
        else setReportName("")
        if (data.gcurve) setSelectedCurve([{label:data.gcurve.name, id:data.gcurve.name}])
        else setSelectedCurve([{label:'None', id:'-1'}])

        if (data.pens) {
          var Pens: Pen_Props[] = data.pens.map((Pen) => ({
            Id: Pen.Id,
            data: {
              penstart: Pen.startday?Pen.startday:"",
              periode: [Pen.startdate?FormatTimes(Pen.startdate,activefarm):undefined, Pen.enddate?FormatTimes(Pen.enddate,activefarm):undefined]
            },
            XTern:Pen.XTern
          } as PenProp_Props))
          setPensList(Pens)
        } else setPensList([])
        if (data.autopigs) {
          var Autos: Autopig_Props[] = data.autopigs.map((Auto) => {
            if (Auto.feed) return null
            else return ({
              Id: Auto.Id,
              info: {
                name: Auto.name?Auto.name:"Sysid: " + Auto.Id,
                scale: Auto.scale?Auto.scale:false,
                activity: Auto.activity?Auto.activity:false,
                temp: Auto.temp?Auto.temp:false,
                water: Auto.water?Auto.water:false,
              },
              data: {
                penstart: Auto.startday?Auto.startday:"",
                periode: [Auto.startdate?FormatTimes(Auto.startdate,activefarm):undefined, Auto.enddate?FormatTimes(Auto.enddate,activefarm):undefined]
              },
              XTern:Auto.XTern
            } as Autopig_Props)
          }).filter(e => e)
          setAutoList(Autos)
        } else setAutoList([])
      }
    }
  }, [SelectedReport])

  const Delete = () => {
    if (isDemoFarm(activefarm) && profile.state !== UserStates.ADMIN) navigate('User', {screen:'Home'})

    firebase.remove(`farms_data/${activefarm}/reports/${SelectedReport}`).then(e => {
      navigate('User', {screen:'Home'})
    }, e => {})
  }
  const Save_New = (asNew?:boolean) => {
    var IsNew = asNew!==undefined?asNew:MainParams.New
    if (isDemoFarm(activefarm) && profile.state !== UserStates.ADMIN) navigate('User', {screen:'Home'})
    else if ((IsNew && ((ReportName && ReportName !== "") || asNew)) || 
        (SelectedReport && SelectedReport !== "") && 
        ((!IsNew && (Reports && Reports[SelectedReport]))) &&
        ((ReportName && ReportName!== "") || (SelectedCurve[0].label&&SelectedCurve[0].label!==""&&SelectedCurve[0].label!=="None") || 
        (PensList && PensList.length) /*|| (AutoList && AutoList.length) || (SelectReports && SelectReports.length)*/)
      ) {
      // Pre-create report (ReportData)
      var report:{
        Archived:boolean,
        name:string,
        gcurve:any|null,
        pens:any[]|null,
        autopigs:any[]|null,
        images:any[]|null
      } = {
        Archived:isArchived,
        name:`${ReportName}${asNew?"_Copy":""}`,
        gcurve:SelectedCurve[0].label&&SelectedCurve[0].label!==""&&SelectedCurve[0].label!=="None"&&DataGraphs&&DataGraphs[SelectedCurve[0].label]?
              {name:SelectedCurve[0].label,data:DataGraphs[SelectedCurve[0].label].data}:null,
        pens: null,
        autopigs: null,
        images: null
      }

      // Save Pens
      if (PensList && PensList.length) {
        report.pens = PensList.map((Pen, index) => {
          var start = Pen.data.penstart?Pen.data.penstart:0, startdate:number|null = null, enddate:number|null = null 
          if (Pen.data.periode[0]) startdate = Pen.data.periode[0]
          if (Pen.data.periode[1]) enddate = Pen.data.periode[1]
          return {Id:Pen.Id, startdate:startdate, enddate:enddate, startday:start, XTern:Pen.XTern?Pen.XTern:null}
        })
      }

      // Save Autos
      if (AutoList && AutoList.length) {
        report.autopigs = AutoList.map((Auto, index) => {
          var start = Auto.data.penstart?Auto.data.penstart:0, startdate = StartPeriodes.start, enddate = StartPeriodes.end
          if (Auto.data.periode[0]) startdate = Auto.data.periode[0]
          if (Auto.data.periode[1]) enddate = Auto.data.periode[1]
          return {
            Id:Auto.Id, name:Auto.info.name?Auto.info.name:null,
            scale:Auto.info.scale?Auto.info.scale:null,
            activity:Auto.info.activity?Auto.info.activity:null,
            temp:Auto.info.temp?Auto.info.temp:null,
            water:Auto.info.water?Auto.info.water:null,

            startdate:startdate, enddate:enddate, startday:start,
            XTern:Auto.XTern?Auto.XTern:null
          }
        })
      }
      
      if (!IsNew) {
        firebase.update(`farms_data/${activefarm}/reports/${SelectedReport}`, report).then(e => {
          navigate('User', {screen:'Home'})
        }, e => {})
      } else {
        firebase.push(`farms_data/${activefarm}/reports`, report).then(e => {
          navigate('User', {screen:'Home'})
        })
      }
    }
  }

  return(
    <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
      <View style={[Layout.colVCenter, Layout.fullWidth]}>
        {!MainParams.New&&Reports&&Object.entries(Reports).some(([key,val]) => !val.name)?(<View style={[Layout.row, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', minWidth:'fit-content'}]}>
          <Text style={[Fonts.textRegular, {alignSelf:'center', minWidth:'110px'}]}>{"Edit Report: "}</Text>
          <Select clearable={false}
            options={Reports?Object.keys(Reports).filter(e => e !== 'update_time').map((report, i) => ({label: report, id: report, index:i})):([])}
            value={SelectedReport?[{label:SelectedReport, id:SelectedReport}]:[]}
            type={TYPE.select}
            placeholder=""
            onChange={(params:any) => {
              setSelectedReport(params.value[0].label)
            }}
            maxDropdownHeight="150px"
            overrides={{
              Root: { style: ({ $theme }) => ({
                width: '-webkit-fill-available',
                outline:'black 1px solid'
              })}
            }}
          />
        </View>):(<></>)}

        <View style={[Layout.fill, Layout.column, Common.border, Gutters.tinyV_Margin, {width:'-webkit-fill-available', maxWidth:'600px', maxHeight:'fit-content'}]}>
          <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {minWidth:'fit-content', flexWrap: "wrap", minHeight:'fit-content', justifyContent:'space-between'}]}>
            <View style={[Layout.fill, Layout.row, {minWidth:'fit-content', flexWrap: "wrap", flexDirection: "row", minHeight:'fit-content'}]}>
                <Labelled_Input error={(!ReportName||ReportName==="")}
                  value={ReportName} // todo... option
                  label={t('Reports_Editor.ReportName')}
                  placeholder={""}
                  onChange={(text) => {
                    setReportName(text)
                  }}
                  minWidth={200}
                />
              </View>
            <View style={[Layout.column, Gutters.tinyV_Margin, {maxWidth:'fit-content', minWidth:'200px', alignItems:'flex-end'}]}>
              <Text style={[Fonts.textSmall]}>{t('Batchs.GrowthCurve')}</Text>
              <Select clearable={false}
                options={DataGraphs?['None'].concat(Object.keys(DataGraphs).filter(e => e !== 'update_time')).map((graph, i) => ({label: graph, id: graph, index:i})):[{label:'None', id:'-1'}]}
                value={SelectedCurve}
                type={TYPE.select}
                placeholder={t('Reports_Editor.GrowthCurve')}
                onChange={(params:any) => {
                  setSelectedCurve(params.value)
                }}
                maxDropdownHeight="150px"
                overrides={{
                  Root: { style: ({ $theme }) => ({
                    maxWidth:'200px', minWidth:'100px',
                    outline:'black 1px solid'
                  })}
                }}
              />
            </View>
          </View>
          <View style={[Layout.fill, Layout.row, Gutters.tinyH_Margin, {minWidth:'fit-content', flexWrap: "wrap", minHeight:'fit-content'}]}>
            <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {maxWidth:'-webkit-fill-available', minWidth:'-webkit-fill-available'}]}>
              <Select clearable={false}
                value={undefined}
                options={
                  (Pens.map((Pen, i) => ({label: Pen, id: Pen, index:i}))).concat(
                  XTern_pens?XTern_pens.map((Pens, i) => Pens.map((Pen, i2) => ({label: Pen +' @', id: Pen, index:i, index2:i2, XTern:true}))).flat(1):[])
                }
                type={TYPE.select}
                placeholder={t('Reports_Editor.SelectPens')}
                onChange={(params:any) => {
                  setPensList(old => {
                    var Values = params.value[0]
                    var _new = [...old]
                    var prev = undefined
                    if (_new.length >= 1) {
                      prev = _new[_new.length-1].data
                    }
                    _new.push({
                      Id:Values.id,
                      data:{penstart:"",periode:prev&&prev.periode?prev.periode:[undefined,undefined]},
                      XTern: Values.XTern&&XTern_Data?Object.keys(XTern_Data)[Values.index]:null
                    }as Pen_Props)
                    //setSelectedPen(undefined)
                    return _new
                  })
                }}
                maxDropdownHeight="300px"
                overrides={{
                  Root: { style: ({ }) => ({
                    minWidth:'200px', paddingTop:'1px', paddingBottom:'1px',
                    outline:'black 1px solid'
                  })},
                  Tag: {props: {overrides: {Root: {style: ({ }) => ({
                    minWidth: "55px", paddingTop:'1px', paddingBottom:'1px',
                  })}}}}
                }}
              />
            </View>
            <FlatList style={[Layout.fullWidth, {maxWidth:600, minHeight:125, maxHeight:300}]}
              data={PensList}
              renderItem={({item, index}) => {
                return (
                  <PenProp Id={item.Id} data={item.data} XTern={item.XTern} setData={(data) => {
                    setPensList(old => {
                      var _new = [...old]
                      _new[index].data = data
                      return _new
                    })
                  }} remove={() => {
                    var List = [...PensList]
                    List.splice(index, 1)
                    setPensList(List)
                  }}/>
                )
              }}
              numColumns={1}
              keyExtractor={(item, index) => String(index)}
            />
          </View>
          <View style={[Layout.fill, Layout.row, Gutters.tinyH_Margin, {minWidth:'fit-content', flexWrap: "wrap", minHeight:'fit-content'}]}>
            <View style={[Layout.fill, Layout.row, Gutters.tinyV_Margin, {maxWidth:'-webkit-fill-available', minWidth:'-webkit-fill-available'}]}>
              <Select clearable={false}
                options={
                  AutoOptions
                }
                value={undefined}
                type={TYPE.select}
                placeholder={t('Reports_Editor.SelectAutos')}
                onChange={(params:any) => {
                  setAutoList(old => {
                    var _new = [...old]
                    var Data = params.value[0]
                    var ID = Data.id
                    if ((!Data.XTern && Autopigs && Autopigs[ID]) || 
                        (Data.XTern && XTern_Autopigs && XTern_Autopigs[Data.XTern][ID])) {
                      var _Data = Data.XTern?XTern_Autopigs[Data.XTern][ID]:Autopigs[ID]
                      _new.push({
                        Id:ID,
                        info: {name:_Data.name?_Data.name:"Sysid: " + ID, 
                        scale: (_Data.scale&&_Data.scale!==""), activity: (_Data.activity&&_Data.activity!==""),  
                        temp: (_Data.temp&&_Data.temp!==""), water: (_Data.water&&_Data.water!==""), feed: (_Data.feed&&_Data.feed!=="")},
                        data:{penstart:"",periode:[undefined,undefined]},
                        XTern: Data.XTern
                      }as Autopig_Props)
                      //setSelectedAuto("")
                    }
                    return _new
                  })
                }}
                maxDropdownHeight="300px"
                overrides={{
                  Root: { style: ({ }) => ({
                    minWidth:'200px', paddingTop:'1px', paddingBottom:'1px',
                    outline:'black 1px solid'
                  })},
                  Tag: {props: {overrides: {Root: {style: ({ }) => ({
                    minWidth: "55px", paddingTop:'1px', paddingBottom:'1px',
                  })}}}}
                }}
              />
            </View>
            <FlatList style={[Layout.fullWidth, {maxWidth:600, minHeight:125, maxHeight:300}]}
              data={AutoList}
              renderItem={({item, index}) => {
                return (
                  <AutopigProp Id={item.Id} info={item.info} data={item.data} XTern={item.XTern} setData={(info, data) => {
                    setAutoList(old => {
                      var _new = [...old]
                      _new[index].info = info
                      _new[index].data = data
                      return _new
                    })
                  }} remove={() => {
                    var List = [...AutoList]
                    List.splice(index, 1)
                    setAutoList(List)
                  }}/>
                )
              }}
              numColumns={1}
              keyExtractor={(item, index) => String(index)}
            />
          </View>
          {!MainParams.New?<View style={[Layout.fill, Layout.row, {width:'-webkit-fill-available', flexWrap: "wrap", minHeight:'fit-content', justifyContent:'center'}]}>
            <Button Contexts={[t('Reports_Editor.Deletelabel')]} maxWidth={150} Action={(e)=>{
              Delete()
            }}/>
            <Button Contexts={[t('Reports_Editor.Copylabel')]} maxWidth={150} Action={(e)=>{
              Save_New(true)
            }}/>
            <View style={[{width:25}]}/>
            <Checkbox
                labelPlacement={LABEL_PLACEMENT.left}
                overrides={{
                  Root: { style: ({ }) => ({
                    alignSelf: "center", alignItems: "center",
                    minWidth:'min-content', marginLeft:'15px',marginRight:'2px'
                  })},
                  Label: { style: ({ }) => ({
                    justifyContent:'flex-end', paddingBottom:'5px',
                    minWidth:'min-content', marginLeft:'2px',marginRight:'2px'
                  })}
                }}
                checked={isArchived}
                onChange={(e:any) => {
                  setisArchived(e.target.checked)
                }}
              ><Text style={[{}]}>{t('Reports.listcol4') + ': '}</Text></Checkbox>
          </View>:(<></>)}
        </View>
        <Button Contexts={[t('ActionButtons.Save')]} Frame Action={(e)=>Save_New()}/>
      </View>
    </View>
  )
}

export default Reports_Editor