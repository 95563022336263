import { isEmpty, isDemoFarm, CommentData, Treatment, MainOperationTypes, UserStates, TimeSpan, UserData } from '../../Config/Types'
import { useAppSelector, useAppDispatch, RootStates_T } from '../../Store'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { createSelector } from 'reselect'
import get from 'lodash/get'

export const fetchComments = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = [], IsDataLink:string|undefined, Pen_Times?:{Pens:number|string[], Times:number[]}, ClosedData?:boolean, LinkListData_Sections?:any, LinkList_Sections?:any):any[] => {
  if (FarmId && FarmId !== "") {
    var path = `farms_data/${IsDataLink?IsDataLink:FarmId}/`
    if (isDemoFarm(FarmId) && !IsDataLink) path = `DemoData/${FarmId}/`
    if (IsDataLink) {
      var _LinkListData_Sections = LinkListData_Sections?LinkListData_Sections:useAppSelector(({firebase: {data}}) => data&&data.LinkListData&&data.LinkListData[IsDataLink]&&data.LinkListData[IsDataLink].sections?data.LinkListData[IsDataLink].sections:null)
      if (_LinkListData_Sections) {
        const _LinkList_Sections = LinkList_Sections?LinkList_Sections:useAppSelector(({firebase: {data}}) => data&&data.LinkList&&data.LinkList[IsDataLink]&&data.LinkList[IsDataLink].sections?data.LinkList[IsDataLink].sections:null)
        if (_LinkList_Sections) {
          Object.entries(_LinkListData_Sections).forEach(([Id, Data]) => {
            if (Data && _LinkList_Sections[Id] && Data.pens) {
              var LinkData_Section = _LinkList_Sections[Id]
              var startTime:undefined|number = undefined
              var endTime:undefined|number= undefined
              if (typeof LinkData_Section !== "boolean") {
                startTime = LinkData_Section.starttime
                endTime = LinkData_Section.endtime
              }

              Data.pens.forEach((PenId:number) => {
                Calls.push({path:path + 'comments', queryParams: ['orderByChild=penTime', `startAt=${PenId}#${(startTime||0)}`, `endAt=${PenId}#${(endTime||9999999999999)}`], 
                  storeAs: `LinkListData/${(IsDataLink)}/comments/${PenId}`})
                if (ClosedData) {
                  Calls.push({path:path + 'datas/closed_comments', queryParams: ['orderByChild=penTime', `startAt=${PenId}#${(startTime||0)}`, `endAt=${PenId}#${(endTime||9999999999999)}`], 
                  storeAs: `LinkListData/${(IsDataLink)}/closed_comments/${PenId}`})
                }
              })
            }
          })
        }
      }
      //Calls.push({path:path + 'comments', queryParams: ['orderByChild=time'], storeAs: `LinkListData/${(IsDataLink)}/comments`})
    }
    else {
      //TODO: each sections pen ... storeas comments/PenId/
      if (Pen_Times && Pen_Times.Pens && Pen_Times.Times && Pen_Times.Times.length >= 2) {
        var Pens = typeof Pen_Times.Pens === "number"?[Pen_Times.Pens+'']:Pen_Times.Pens
        Pens.forEach(PenId => {
          var startTime = Pen_Times.Times[0]
          var endTime = Pen_Times.Times[1]

          Calls.push({type:'value', path:path + 'comments', queryParams: !isDemoFarm(FarmId)?['orderByChild=penTime', `startAt=${PenId}#${Pen_Times.Times[0]}`, `endAt=${PenId}#${Pen_Times.Times[1]}`]:['orderByChild=time'], 
                      storeAs: `comments/${PenId}`})
                      //storeAs: `comments`})
          if (ClosedData) {
            Calls.push({type:'value', path:path + 'datas/closed_comments', queryParams: !isDemoFarm(FarmId)?['orderByChild=penTime', `startAt=${PenId}#${Pen_Times.Times[0]}`, `endAt=${PenId}#${Pen_Times.Times[1]}`]:['orderByChild=time'], 
                      storeAs: `closed_comments/${PenId}`})
                      //storeAs: `closed_comments`})
          }
        })
      }
      else {
        Calls.push({type:'value', path:path + 'comments', queryParams: ['orderByChild=time'], storeAs: 'comments'})
        if (ClosedData) {
          Calls.push({type:'value', path:path + 'datas/closed_comments', queryParams: ['orderByChild=time'], storeAs: `closed_comments`})
        }
      }
    }
  }
  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

export const GetPenComments = createSelector(
  [
    (state:RootStates_T, IsDataLink:string|undefined, PenId?:number, ClosedData?:boolean) => get(state.activeData, 'farm', undefined),
    (state:RootStates_T, IsDataLink:string|undefined, PenId?:number, ClosedData?:boolean) => ClosedData?get(state.firebase.data, `${IsDataLink?'LinkListData.'+IsDataLink+'.':''}closed_comments`, undefined):
                                                                                             get(state.firebase.data, `${IsDataLink?'LinkListData.'+IsDataLink+'.':''}comments`, undefined),
    (state:RootStates_T, IsDataLink:string|undefined, PenId?:number, ClosedData?:boolean) => PenId
  ],
  (FarmId:string|undefined, Comments:Record<string, CommentData>|CommentData[], PenId?:number):Record<string, CommentData[]>|CommentData[] => {
    var Results:Record<string, CommentData[]>|CommentData[] = PenId?[]:{}
    if (Comments && !isEmpty(Comments)) {
      let Datas:CommentData[]
      if (Array.isArray(Comments)) {
        Datas = Object.entries(Comments).filter(([key,data]) => data && (!PenId||data.pen===PenId)).map(([key,data]) => ({...data, key:key} as CommentData))
      }
      else {
        var _Comments = {...Comments} 
        if (Comments[PenId]) _Comments = Comments[PenId]
        Datas = Object.entries(_Comments).filter(([CommentKey, CommentsData]) => {
          return CommentsData&&CommentsData.pen&&(!PenId||CommentsData.pen===PenId)
        }).reduce((res, [CommentKey, Datas]) => {
          return res.concat([{...Datas, key:CommentKey} as CommentData])
        }, [] as CommentData[]).flat(1)
      }

      Datas.forEach(element => {
        var _data = element
        if (FarmId && isDemoFarm(FarmId)) {
          if (element && element.penTime && element.penTime.includes('$')) {
            var TimeDatas = element.penTime.split('#')[1].split('$')
            var time = new Date()

            time.setDate(time.getDate()-parseInt(TimeDatas[0]))
            time.setHours(parseInt(TimeDatas[1]))
            time.setMinutes(parseInt(TimeDatas[2]))

            var data = {...element}
            data.time =  time.getTime()
            data.penTime = `${element.pen}#${data.time}`
            _data = data
          }
        }
        if (Array.isArray(Results)) Results.push(_data)
        else if (_data.pen) {
          if (!Results[_data.pen]) Results[_data.pen] = []
          Results[_data.pen].push(_data)
        }
      })
    }
    return Results
  }
)

export const fetchTreatments = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = []):any[] => {
  if (FarmId && FarmId !== "") {
    Calls.push({path:'farms_data/Agrisys/treatments', queryParams: ['notParsed', 'orderByKey'], storeAs: 'agri_treats'})
    
    //const path = `farms_data/${IsDataLink?IsDataLink:activefarm}/`
    const path = `farms_data/${FarmId}/`
    Calls.push({path:path + 'system/treatments', queryParams: ['notParsed', 'orderByKey'], storeAs: 'system/treatments'})
  }

  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

export const GetTreatments = createSelector(
  [
    (state:any, IsDataLink:string|undefined) => get(state.firebase.data, `agri_treats`, undefined),
    (state:any, IsDataLink:string|undefined) => get(state.activeData, 'farm', undefined),
    (state:any, IsDataLink:string|undefined) => get(state.firebase.data.system, 'treatments', undefined)
  ],
  (AgriTreats:Record<number, Treatment>, FarmId:string|undefined, Treatments:Record<number,Treatment>| undefined):Treatment[] => {
    var _AgriTreats = AgriTreats?Object.entries(AgriTreats).reduce((a:object, v:any[]) => {
      return ({...a, [v[0]]:{...v[1], isAgri:true}})
    }, {}):{}
    var AllTreatments = Object.assign({}, _AgriTreats, Treatments)
    if (AllTreatments && !isEmpty(AllTreatments)) {
      return Object.entries(AllTreatments).map(([key,data]) => ({code:key, ...data} as Treatment)) 
    }
    return []
  }
)

export interface GetFormatOperations_Props {Operation_Notes:CommentData[], Deaths:number, Added:number, Sold:number}
export const GetFormatOperations = createSelector(
  [
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => get(state.activeData, 'farm', undefined),
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => get(state.firebase, 'profile', undefined),
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => GetPenComments(state, IsDataLink, (typeof PenId==="number"?PenId:undefined), false),
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => ClosedData?GetPenComments(state, IsDataLink, (typeof PenId==="number"?PenId:undefined), true):[],
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => GetTreatments(state, IsDataLink),
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => PenId,
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => ClosedData,
    (state:RootStates_T, PenId:number|string[], IsDataLink?:string, ClosedData?:boolean, ViewPeriod?:TimeSpan) => ViewPeriod
  ],
  (FarmId:string|undefined, profile:UserData|undefined, Operations, Closed_Operations, Treatments:Treatment[], PenId:number|string[], ClosedData?:boolean, ViewPeriod?:TimeSpan):GetFormatOperations_Props => {
    var deathCount = 0, addedCount = 0, soldeCount = 0
    var PenOperations:CommentData[] = []
    if (Operations || Closed_Operations) {
      var Pens = typeof PenId === "number"?[PenId+'']:PenId
      PenOperations = PenOperations.concat(Pens.map((PenData, Index) => {
        var Combined:CommentData[] = []
        if (Operations) {
          if (typeof PenId==="number" && Array.isArray(Operations)) Combined = Combined.concat(Operations)
          else if (Operations[PenData]) Combined = Combined.concat(Operations[PenData])
        }
        if (Closed_Operations) {
          if (typeof PenId==="number" && Array.isArray(Closed_Operations)) Combined = Combined.concat(Closed_Operations)
          else if (Closed_Operations[PenData]) Combined = Combined.concat(Closed_Operations[PenData])
        }
        Combined = Combined.filter(Operation => Operation && (!ViewPeriod || 
          (ViewPeriod.start && ViewPeriod.start <= Operation.time) &&
          (!ViewPeriod.end || ViewPeriod.end >= Operation.time)) &&
          (!Operation.edited && !Operation.deleted) && 
          (!Operation.internal || (FarmId === Operation.internal && ((profile && profile.state !== UserStates.WORKER) || FarmId === "-Hornsyld" || FarmId === "-EasyGain")))
        )
        if (!isEmpty(Combined)) {
          addedCount += Combined.reduce((res, Operation) => (Operation.action === "Add pig"&&Operation.quantity)?(res+Operation.quantity):res, 0)
          soldeCount += Combined.reduce((res, Operation) => (Operation.action === "Sold pig"&&Operation.quantity)?(res+Operation.quantity):res, 0)
          deathCount +=  Combined.reduce((res, Operation) => {
            var num = 0
            var CODE = Operation.code
            if (CODE && CODE.includes("_")) {
              var _code = CODE.split("_")
              if (MainOperationTypes[_code[0]] && MainOperationTypes[_code[0]].death) num += (Operation.quantity||0)
            } else if (CODE && Treatments.find(e => e.code === CODE)?.death) num += (Operation.quantity||0)
            return res + num
          },0)
        }
        return Combined
      }).flat(1))
    }
    return {Operation_Notes:PenOperations, Deaths:deathCount, Added:addedCount, Sold:soldeCount}
  }
)
