export const isEmpty = (obj:any) => !obj || ( [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length )
export const isDemoFarm = (activeFarmId:string|undefined) => activeFarmId?activeFarmId.startsWith('-Demo'):false
export const FormatTimes = (Data: (number|Date|undefined)|(number|Date|undefined)[], FarmId?:string|undefined):(number|undefined)|(number|undefined)[] => {
  var Datas = Array.isArray(Data)?Data:[Data]
  var Res:(number|undefined)[] = Datas.map(_date => {
    if (!_date) return _date
    var _Date:number = (typeof _date === "object")?_date.getTime():_date
    if (FarmId && isDemoFarm(FarmId) && _Date < 9999) {
      var Time = new Date()
      return Time.setDate(Time.getDate() - _Date)
    }
    else return _Date
  })
  if (!Array.isArray(Data)) return Res[0]
  else return Res
}
export const UnixDayTime = 86400000

//#region Data defines
//export type DateSpan = {start:Date, end:Date|undefined}|{start:number, end:number|undefined}
export type TimeSpan = {start:number, end?:number}

//#endregion
//#region App-User defines
export enum UserStates {
  STARTER = 'starter',
  FREE = 'free',
  WORKER = 'worker',
  OWNER = 'owner',
  HORNSYLD = 'Hornsyld',
  TECH = 'tech',
  ADMIN = 'admin',
  CONTACT = 'contact'
}
export type UserState = UserStates
export interface UserData {
  name: string,
  lastN: string,
  phoneNumber?: string,
  address?: string,
  country?: string,
  email?: string,
  farms?: {[key:string]: boolean},
  works?: {[key:string]: boolean},
  state?: UserState,
  CatTag?: string
}
//#endregion
//#region App-System defines
export enum SystemTypes {
  BATCHSYS = "batchsys",
  AIRSYS = "airsys",
  AUTOPIG = "autopig"
}
export type SystemType = SystemTypes
export type DataType = "ALL"|"LOCAL"|"EXTERN"
export enum SystemStates {
  FETCHING = "Fetching",
  UNKNOWN = "Unknown",
  INITIALIZING = "Initializing",
  WORKING = "Working", 
  IDLE = "Idle",
  STOPPED = "Stopped",
  ERROR = "Error"
}
export type SystemState = SystemStates
//#endregion
//#region Consys defines
export enum ConsysRefs {
  VALVEID = 'id',
  //VALVEFEED = '100',
  VALVEFEED = '110',
  SCALE = '700',
  TEMPERATURE = '702',
  ACTIVITY = '703',
  WATERCOUNT = '704',
  CORRIDOR = 'scalenow',
  RESETWEIGHT = 'resetpigscaleweight'
}
export type ConsysRef = ConsysRefs
//#endregion
//#region Operations defines
export enum Operations {
  EMPTY = 0,
  ADD = 'Add pig',
  MOVE = 'Move pig',
  TREATMENT = 'Treatment',
  SOLD = 'Sold pig', //OutDated
  NOTE = 'Pen Note', //OutDated
  START = 'Start pen',
  CLOSE = 'Close pen',
  WEIGHNING = 'Weighning'
}
export type Operation = Operations
export interface Treatment {
  code: string,
  description: string,
  death: boolean,
  used: boolean,
  isAgri?: boolean
}
export const MainOperationTypes:Record<string,Treatment> = {   //TODO: Move code reference... in database to ??
  '0': {code:"0", description:'---', death:false, used:false},
  '1': {code:"1", description:"Moved", death:false, used:true},
  '2': {code:"2", description:"Sold", death:false, used:true},
  '3': {code:"3", description:"Killed", death:true, used:true},
  '4': {code:"4", description:"Death", death:true, used:true}
}
//OLD 
/*export const MainOperationTypes:Record<string,Treatment> = {   //TODO: Move code reference... in database to ??
  '0': {code:"-1", description:'---', death:false, used:false},
  '1': {code:"0", description:"Moved", death:false, used:true},
  '2': {code:"3", description:"Sold", death:false, used:true},
  '3': {code:"1", description:"Killed", death:true, used:true},
  '4': {code:"2", description:"Death", death:true, used:true}
}*/
export type OperationType = Treatment
export type OperationTypes = OperationType[]
//#endregion
//#region FB_DataStrycktures
export interface FarmData {
  name: string,
  address?: string,
  country?: string,
  cvr?: string,
  email?: string,
  phoneNumber?: string,
  manager?: string,

  CatTag?: string,
  site?: string,
  refToken?: string,
  penSurplus?: number,
}
export interface SystemSet { site?: string, refToken?: string}

//List of get shared
export type DataLinks = {
  DataLinks?:Record<string, {
    name:string,
    sections?:Record<string,boolean|{starttime:number, endtime:number}>
  }>,
  System?:SystemSet,
  Workers?:Record<string,boolean>
}
//Avaible Opset list
export type LinkList = {
  name:string|null,
  sections?: Record<string, boolean|{starttime:number, endtime:number}>,
  
  System?:SystemSet,
}
//Shared Data
export type LinkListData = {
  System?:SystemSet,
  sections:Record<string,SectionData>|null,
  pens:Record<string, PenData>|null,
  DataCons:Record<string, DataConsData>|null
}
//List of set Shared
export type DataLinkList = {
  DataLinks?:Record<string, {
    name:string,
    sections?:Record<string,boolean|{starttime:number, endtime:number}>
  }>,
  System?:SystemSet,
  Workers?:Record<string,boolean>
}

export interface SystemData {
  penPrefix?:string,
  treatments?:{
    [key:number]:Treatment
  }
}
export interface DataConsData {
  name?:string,

  isCorridor?:true
  flow?:number,
  feed?:string,
  scale?:string,
  temp?:string,
  activity?:string,
  water?:string,
  xTern?:string
}
export interface SysConfig {
  Corridors?:string,
}
export interface CurvePoint {
  day: number, 
  weight: number
}
export interface GraphData {
  data: CurvePoint
}
export interface SectionData {
  pens: number[],
  isDataLink?:string
}
export interface PenData_Datas {
  pensize?:number,
  count?: number,
  autopigs?: string,
  curve?: string,
  activedate?: number
}
export interface PenData {
  section: string,
  datas?: PenData_Datas
}
export interface CommentData {
  user: string,
  pen: number,
  penIndex?:number,
  time: number|null,
  penTime: string,
  data?: string|null
  quantity?: number|null,
  weight?: number|null,
  action: Operations,
  code?: string|null,

  edited?:string|null,
  deleted?:boolean|null,
  key?: string|null
}
/*export interface WeighingData {
  user?: string,
  action?: boolean,
  pen: number,
  time: number,
  penTime: string,
  weight: number,
  count: number,
  edited?:string,
  deleted?:string,
  key?: string
}*/

export interface Report_PensData {Id:string, XTern:string, startday:number, enddate?:number, startdate:number}
export interface Report_AutosData {Id:string, XTern:string, name:string, startday:number, enddate?:number, startdate:number, scale?:boolean, temp?:boolean, water?:boolean, activity?:boolean}
export interface ReportData {
  name:string,
  time?:number,         //OLD
  closedTime?: number,  //OLD
  gcurve:{
    name: string,
    data: number[]
  },
  pens:{
    [key:number]:Report_PensData
  },
  autopigs:{
    [key:number]:Report_AutosData
  },
  images:{
    [key:number]:{
      Id?:string,
      startday:number,
      enddate:number,
      startdate:number
    }
  },
  Archived:boolean
}
export interface Periodes {
  starttime?:number, 
  endtime?:number,
  timestamp?:number, 
  color?:string
}
export interface DatasData {
  display_periodes: Record<string, Periodes>,
  display_comments: Record<string, CommentData>,
  display_weighings: Record<string, CommentData>,
  closed_comments: {[key:string]: CommentData}[],
  closed_weighings: {[key:string]: CommentData}[]
} 
//#endregion