import { useContext } from 'react'
import { FormatTimes, isDemoFarm, isEmpty, PenData, Periodes } from '../../Config/Types'
import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase'
import { createSelector } from 'reselect'
import get from 'lodash/get'

export const fetchPens = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = []):any[] => {
  if (FarmId && FarmId !== "") {
    const path = `farms_data/${FarmId}/`
    Calls.push({type:'value', path:path + 'pens', queryParams: ['orderByKey'], storeAs: 'pens'})
    //Calls.push({type:'child_changed', path:path + 'pens', queryParams: ['orderByKey'], storeAs: 'pens'})
  }

  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

export const GetPenData = createSelector(
  [
    //(state:any, PenId:number, IsDataLink?:string) => get(state.firebase.data, `${IsDataLink?'LinkListData.'+IsDataLink+'.':''}pens.${PenId}`, undefined),
    (state:any, PenId:number|string[], IsDataLink?:string) => get(state.firebase.data, `${IsDataLink?'LinkListData.'+IsDataLink+'.':''}pens`, undefined),
    (state:any, PenId:number|string[], IsDataLink?:string) => PenId
  ],
  //(PenDatas:PenData):PenData|undefined => {
  (PenDatas:any, PenId:number|string[]):(PenData|PenData[]|undefined) => {
    var PensActive:Record<string, Periodes[]> = {}
    var Pens = typeof PenId === "number"?[PenId+'']:PenId
    Pens.forEach(_PenId => {
      if (!PensActive[_PenId]) PensActive[_PenId] = []
      if (PenDatas && PenDatas[_PenId]) {
        PensActive[_PenId].push(PenDatas[_PenId])
      }
    })
    if (PenDatas && typeof PenId === "number") return PenDatas[PenId]
    else return PenDatas
    //return PenDatas
  }
)

export const GetPens = createSelector(
  [
    (state:any, IsDataLink?:string) => get(state.firebase.data, `${IsDataLink?'LinkListData.'+IsDataLink+'.':''}pens`, undefined),
  ],
  (Pens:Record<string,PenData>):Record<string,PenData>|undefined => {
    return Pens
  }
)

export const fetchPenPeriodes = (FarmId:string|undefined, Call:boolean = true, Calls:any[] = [], PenId:number|string[], IsDataLink:string|undefined):any[] => {
  if (FarmId && FarmId !== "") {
    var path = `farms_data/${IsDataLink?IsDataLink:FarmId}/`
    if (FarmId.startsWith("-Demo") && !IsDataLink) path = `DemoData/${FarmId}/`
    if (typeof PenId === "number") Calls.push({type:'value', path:path + `datas/periodeList/${PenId}/`, storeAs: `periodes/${(IsDataLink?IsDataLink+'/':'')}${PenId}`})
    else {
      PenId.forEach(ID => {
        Calls.push({type:'value', path:path + `datas/periodeList/${ID}/`, storeAs: `periodes/${(IsDataLink?IsDataLink+'/':'')}${PenId}`})
      });
    }
  }

  if (Call) {
    useFirebaseConnect(() => {
      return Calls
    })
  }
  return Calls
}

export const GetPenPeriodes = createSelector(
  [
    (state:any, IsDataLink:string|undefined, PenId:number|string[]) => get(state.activeData, 'farm', undefined),
    (state:any, IsDataLink:string|undefined, PenId:number|string[]) => get(state.firebase.data, `${(IsDataLink?'LinkListData.'+IsDataLink+'.':'')}pens`),
    (state:any, IsDataLink:string|undefined, PenId:number|string[]) => get(state.firebase.data, `periodes${(IsDataLink?'.'+IsDataLink:'')}`, undefined),
    (state:any, IsDataLink:string|undefined, PenId:number|string[]) => PenId,
  ],
  (FarmId:string|undefined, pensData, PensPeriodes:Record<string, Periodes>|undefined, PenId:number|string[]):Record<string, Periodes[]>|Periodes[]|undefined => {
    var PensActive:Record<string, Periodes[]> = {}
    var Pens = typeof PenId === "number"?[PenId+'']:PenId
    Pens.forEach(_PenId => {
      if (!PensActive[_PenId]) PensActive[_PenId] = []
      if (PensPeriodes && PensPeriodes[_PenId]) {
        Object.values(PensPeriodes[_PenId]).forEach(per => {
          var Times = FormatTimes([per.starttime, per.endtime], FarmId)
          if (Times) {
            PensActive[_PenId].push({starttime:Times[0]?Times[0]:undefined, endtime:Times[1]?Times[1]:undefined})
          }
        })
      }
      if (pensData && pensData[_PenId] && pensData[_PenId].datas && pensData[_PenId].datas.activedate) {
        var time:number|undefined = FormatTimes(pensData[_PenId].datas.activedate, FarmId)
        PensActive[_PenId].push({starttime:time, color:'lightgreen'})
      }
    })
    if (typeof PenId === "number") return PensActive[PenId]
    else return PensActive
  }
)