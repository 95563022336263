import React, { useEffect } from 'react'
import {
  View
} from 'react-native'
import { Button, LoadingSplash } from '../../Components'

import { useTheme } from '../../Theme'
import { useTranslation } from 'react-i18next'
import { navigate } from '../../Navigators/Root'

import { useAppSelector, useAppDispatch } from '../../Store'
import { useFirestoreConnect, useFirebase, isLoaded, isEmpty, useFirebaseConnect } from 'react-redux-firebase'
import FarmSettings from './FarmSettings'

const Settings = () => {
  const { t } = useTranslation()
  const { Common, Layout, Gutters } = useTheme()
  
  //const firebase = useFirebase()

  const auth = useAppSelector(({firebase}) => firebase.auth)
  //const profile = useAppSelector(({firebase}) => firebase.profile)
  
  //const farms = useAppSelector(({firebase: { data }}) => data.farms)
  //const activefarm = useAppSelector(state => state.activeData.farm)
  //const farmData = useAppSelector<FarmData>(state => farms&&activefarm&&farms[activefarm]?farms[activefarm]:null)
  
  //Auth Check Handler
  useEffect(() => {
    if (isEmpty(auth) || !auth.uid) navigate('Auth', {screen: 'Home'})
  }, [auth])

  useEffect(() => {
    return () => {
      // Clean-Up
    }
  },[])

  if (!isLoaded(auth)) return <LoadingSplash/>
  else {
    return (
      <View style={[Layout.fill, Layout.colVCenter, Gutters.smallH_Padding, Common.backgroundPrimary, {minHeight:'max-content', minWidth:'300px'}]}>
        <View style={{height:10, minWidth:300}}/>
        <Button Contexts={[t('DashBoard.FarmInfo_btn'), t('DashBoard.UserInfo_btn'), /*t('DashBoard.FarmSettings_btn')*/]}
          Action={ele => {
            if (!ele) navigate('User', {screen: 'FarmInformation'})
            else if (ele === 1) navigate('User', {screen: 'UserInformation'})
            /*else {
              navigate('User', {screen: 'FarmSettings'})
            }*/
          }}
        />
        <FarmSettings/>
      </View>
    )
  }
}

export default Settings